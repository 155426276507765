import _ from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ArticleCommissionStockRowDisplayMode,
  formatArticleUnit,
  getFullArticleName,
  InternalArticle,
  InternalArticleExtended,
} from "../../../utils/productArticleUtils";
import { CommissionStock, CommissionStockNew } from "../../../model/commonTypes";
import ContactPersonWidget from "../../common/ContactPersonWidget";
import SimpleConfirmationModal from "../../common/SimpleConfirmationModal";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Input } from "../../common/Input";
import userService from "../../../services/userService";
import { INTERNAL } from "../../../utils/userUtils";
import { DataContextInternal } from "../../../context/dataContext";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { extendSupplier } from "../../../utils/dataTransformationUtils";
import {
  getFinishedProductTimelineEntry,
  isFinishedProduct,
  T_FINISHEDPRODUCTCOMMISIONSTOCKCREATED,
  T_FINISHEDPRODUCTCOMMISIONSTOCKDELETED,
  T_FINISHEDPRODUCTCOMMISIONSTOCKEDITED,
} from "../../../utils/finishedProductUtils";
import { SUPPORTED_CURRENCIES } from "../../../utils/currencyUtils";
import { COMMODITY, FINISHEDPRODUCT, transaction, UpdateAction } from "../../../services/dbService";
import {
  getCommodityTimelineEntry,
  T_COMMISIONSTOCKCREATED,
  T_COMMISIONSTOCKDELETED,
  T_COMMISIONSTOCKEDITED,
} from "../../../utils/commodityUtils";
import { getOrderNumber } from "../../../utils/orderUtils";

interface ArticleCommissionStockRowProps {
  article: InternalArticleExtended | InternalArticle;
  commissionStock: CommissionStock | CommissionStockNew;
  displayMode: ArticleCommissionStockRowDisplayMode;
  readonly?: boolean;
  onChangeInput: (id: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCurrency: (id: string, e: React.ChangeEvent<HTMLSelectElement>) => void;
  onRemoveCommissionStock: (id: string) => void;
}

const ArticleCommissionStockRow: React.FC<ArticleCommissionStockRowProps> = ({
  article,
  commissionStock,
  displayMode,
  readonly,
  onChangeInput,
  onChangeCurrency,
  onRemoveCommissionStock,
}) => {
  const context = useContext(DataContextInternal);

  const [saving, setSaving] = useState<boolean>(false);

  const handleChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeInput(commissionStock._id.toString(), e);
    },
    [commissionStock, onChangeInput]
  );

  const handleChangeCurrency = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeCurrency(commissionStock._id.toString(), e);
    },
    [commissionStock, onChangeCurrency]
  );

  const handleRemoveCommissionStock = useCallback(() => {
    onRemoveCommissionStock(commissionStock._id.toString());
  }, [commissionStock, onRemoveCommissionStock]);

  const handleDeleteCommissionStock = useCallback(async () => {
    setSaving(true);
    try {
      const action: UpdateAction = {
        collection: isFP ? FINISHEDPRODUCT : COMMODITY,
        filter: { _id: article._id },
        pull: { commissionStocks: { _id: commissionStock._id } },
        push: {
          timeline: isFP
            ? getFinishedProductTimelineEntry(T_FINISHEDPRODUCTCOMMISIONSTOCKDELETED)
            : getCommodityTimelineEntry(T_COMMISIONSTOCKDELETED),
        },
      };
      const res = await transaction([action]);
      if (res) {
        toast.success("Commission Stock successfully deleted");
      } else {
        toast.error("Error deleting commission stock");
      }
    } catch (e) {
      console.error(e);
      toast.error("Error deleting commission stock");
    } finally {
      setSaving(false);
    }
  }, [article, commissionStock]);

  const handleSaveCommissionStock = useCallback(async () => {
    setSaving(true);
    try {
      const actions: Array<UpdateAction> = [];
      let cS;
      if (isNew) {
        // In case of a new stock we need to remove the new flag before saving
        cS = _.omit(commissionStock, "new");
        cS.remainingAmount = cS.totalAmount;
        actions.push({
          collection: isFP ? FINISHEDPRODUCT : COMMODITY,
          filter: { _id: article._id },
          push: {
            commissionStocks: cS,
            timeline: isFP
              ? getFinishedProductTimelineEntry(T_FINISHEDPRODUCTCOMMISIONSTOCKCREATED)
              : getCommodityTimelineEntry(T_COMMISIONSTOCKCREATED),
          },
        });
      } else {
        cS = commissionStock;
        // Specifically only update the price
        actions.push({
          collection: isFP ? FINISHEDPRODUCT : COMMODITY,
          filter: { _id: article._id },
          update: { "commissionStocks.$[cS].price": cS.price },
          push: {
            timeline: isFP
              ? getFinishedProductTimelineEntry(T_FINISHEDPRODUCTCOMMISIONSTOCKEDITED)
              : getCommodityTimelineEntry(T_COMMISIONSTOCKEDITED),
          },
          arrayFilters: [{ "cS._id": cS._id }],
        });
      }
      const res = await transaction(actions);
      if (res) {
        toast.success("Commission Stock successfully saved");
      } else {
        toast.error("Error saving commission stock");
      }
    } finally {
      setSaving(false);
    }
  }, [article, commissionStock]);

  const isFP = useMemo(() => isFinishedProduct(article), [article]);
  const isNew = useMemo(() => "new" in commissionStock, [commissionStock]);
  const isCompleted = useMemo(
    () => commissionStock.ordered && commissionStock.remainingAmount === 0,
    [commissionStock]
  );

  const sup = useMemo(() => {
    if (displayMode === ArticleCommissionStockRowDisplayMode.ARTICLE) return;
    const supplier = getDocFromCollection(context.supplier, commissionStock.supplier);
    if (supplier) return extendSupplier(supplier, context);
  }, [commissionStock, context.supplier, displayMode]);

  const sO = useMemo(
    () =>
      commissionStock.ordered
        ? context.supplierOrder.find((sO) => sO.commissionData?.commissionStockId === commissionStock._id.toString())
        : undefined,
    [commissionStock._id, commissionStock.ordered, context.supplierOrder]
  );

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (commissionStock.price.price <= 0) errors.push("Price has to be above 0");
    if (commissionStock.totalAmount <= 0) errors.push("Total amount has to be positive");
    return errors;
  }, [commissionStock]);

  return (
    <div className="bg-light2 rounded p-5 mb-7">
      <div className="d-flex align-items-center ">
        <div className="flex-grow-1 me-2">
          {sup ? (
            <>
              <Link
                to={`/supplier/${sup._id.toString()}`}
                onClick={(e) => e.stopPropagation()}
                className="text-white fs-5 custom-link"
              >
                {sup.name}
              </Link>
              <ContactPersonWidget person={sup.primaryPerson} spanClasses="text-muted fw-bold d-block" />
            </>
          ) : (
            <>
              <Link
                to={`/${isFP ? "finishedProduct" : "commodity"}/${article._id.toString()}`}
                onClick={(e) => e.stopPropagation()}
                className="text-white fs-5 custom-link"
              >
                {article.title.en}
              </Link>
              <br />
              <span className="text-muted">{article.subtitle.en}</span>
            </>
          )}
        </div>
        {!isCompleted && (
          <div style={{ textAlign: "right", marginRight: 10, width: 160 }}>
            <div className="d-flex">
              {commissionStock.ordered ? (
                <ErrorOverlayButton
                  errors={commissionStock.ordered ? ["Stock was already ordered"] : []}
                  className="btn btn-outline btn-outline-light btn-sm w-100 mr-1"
                  buttonText="Order"
                  disabled={commissionStock.ordered}
                  saving={saving}
                  onClick={undefined}
                />
              ) : (
                <Link
                  className="mr-1"
                  to={`/createCommissionStockOrder/${
                    isFP ? FINISHEDPRODUCT : COMMODITY
                  }/${commissionStock._id.toString()}`}
                >
                  <button className="btn btn-outline btn-outline-light btn-sm w-100">Order</button>
                </Link>
              )}
              <ErrorOverlayButton
                errors={errors}
                className="btn btn-success btn-sm w-100"
                buttonText="Save"
                saving={saving}
                onClick={handleSaveCommissionStock}
              />
            </div>
          </div>
        )}
      </div>
      <div className="table-responsive mt-5">
        <table className="table align-middle gs-0 gy-1 ">
          <thead>
            <tr className="fw-bolder text-muted">
              <th className="border-bottom-0">Total Amount</th>
              <th className="border-bottom-0">{isCompleted ? "Supplier Order" : "Remaining Amount"}</th>
              <th className="border-bottom-0">Price per {article.unit}</th>
              {!isCompleted && <th className="border-bottom-0">Status</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="align-middle">
                <div className="input-group">
                  <Input
                    type="number"
                    value={commissionStock.totalAmount}
                    className="form-control custom-form-control pt-0 pb-0 bg-dark"
                    name="totalAmount"
                    disabled={!isNew || readonly}
                    onChange={isNew ? handleChangeInput : undefined}
                  />
                  <div className="input-group-append rounded-end">
                    <div
                      className="form-control custom-form-control bg-dark"
                      style={{ padding: ".375rem .75rem", opacity: isNew ? 1 : 0.7 }}
                    >
                      {formatArticleUnit(article.unit)}
                    </div>
                  </div>
                </div>
              </td>
              <td className="align-middle">
                {isCompleted ? (
                  sO ? (
                    <Link to={`/supplierOrder/${sO._id.toString()}`} className="text-muted custom-link ">
                      {getOrderNumber(sO)}
                    </Link>
                  ) : (
                    <div>Unknown</div>
                  )
                ) : (
                  <div className="input-group">
                    <Input
                      type="number"
                      value={isNew ? commissionStock.totalAmount : commissionStock.remainingAmount}
                      className="form-control custom-form-control pt-0 pb-0 bg-dark disabled"
                      disabled={true}
                    />
                    <div className="input-group-append rounded-end">
                      <div
                        className="form-control custom-form-control bg-dark"
                        style={{ padding: ".375rem .75rem", opacity: 0.7 }}
                      >
                        {formatArticleUnit(article.unit)}
                      </div>
                    </div>
                  </div>
                )}
              </td>
              <td className="align-middle">
                <div className="input-group">
                  <Input
                    type="number"
                    value={commissionStock.price.price}
                    className="form-control custom-form-control py-0 bg-dark"
                    name="price.price"
                    onChange={readonly ? undefined : handleChangeInput}
                    disabled={readonly}
                  />
                  <div className="input-group-append rounded-end">
                    <select
                      className="form-control custom-form-control bg-dark pl-1 py-0"
                      name="currency"
                      value={commissionStock.price.currency}
                      onChange={readonly ? undefined : handleChangeCurrency}
                      disabled={readonly}
                    >
                      {SUPPORTED_CURRENCIES.map((c) => (
                        <option key={c} value={c}>
                          {c}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </td>
              {!isCompleted && (
                <td className="align-middle">
                  <span className="text-white">{isNew ? "New" : commissionStock.ordered ? "Ordered" : "Open"}</span>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      {!isCompleted && (
        <div className="row">
          <div className="col-12 align-self-center">
            {commissionStock.ordered ? (
              sO && (
                <Link
                  to={`/supplierOrder/${sO._id.toString()}`}
                  className="float-right text-muted fs-8 custom-link mr-3"
                >
                  {getOrderNumber(sO)}
                </Link>
              )
            ) : isNew ? (
              <button
                className="btn btn-text btn-text-muted btn-sm float-right pr-0"
                onClick={handleRemoveCommissionStock}
              >
                Delete
              </button>
            ) : (
              userService.getUserType() === INTERNAL && (
                <SimpleConfirmationModal.SimpleConfirmationModalButton
                  modalTitle="Delete Commission Stock"
                  modalDescription={
                    <span className="text-white">
                      <div>Do you really want to delete the Commission Stock for {getFullArticleName(article)}?</div>
                    </span>
                  }
                  buttonText="Delete"
                  buttonClasses="btn btn-text btn-text-muted btn-sm float-right"
                  cancelButtonText="Cancel"
                  confirmButtonText="Confirm"
                  disabled={readonly}
                  onConfirm={handleDeleteCommissionStock}
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleCommissionStockRow;
