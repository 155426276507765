import countryList from "i18n-iso-countries";
import _ from "lodash";
import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import validator from "validator";
import { Input } from "../../common/Input";
import { UserData } from "../../../model/userData.types";
import { CUSTOMER, getDefaultUser } from "../../../utils/userUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Company } from "../../../model/company.types";
import { getDefaultCompany } from "../../../utils/companyUtils";
import { getDefaultAddress } from "../../../utils/addressUtils";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { getCountryNameForCode } from "../../../utils/baseUtils";
import {
  checkConfirmationCodeAndCreateCompany,
  generateAndSendEmailConfirmation,
} from "../../../utils/anonymousViewUtils";
import { DataContextAnonymousType } from "../../../context/dataContext";

interface CreateCustomerAccountModalProps {
  context: DataContextAnonymousType;
}

interface CreateCustomerAccountModalState {
  user: UserData;
  company: Company;
  confirmationCode: string;
  show: boolean;
  step: number;
  saving: boolean;
}

const COMPONENT_NAME = "CreateCustomerAccountModal";

class CreateCustomerAccountModal extends PureComponent<
  CreateCustomerAccountModalProps,
  CreateCustomerAccountModalState
> {
  constructor(props: CreateCustomerAccountModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  componentDidMount() {
    this.props.context.saveRef(COMPONENT_NAME, this);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });
  handleBack = () => this.setState({ step: this.state.step - 1 });

  handleNext = async () => {
    const { step, user, company, confirmationCode } = this.state;
    // Next step will be email confirmation
    if (step === 2) {
      this.setState({ saving: true });
      try {
        const res = await generateAndSendEmailConfirmation(user);
        if (res) {
          toast.success("Email was send. Please check your mails!");
        } else {
          toast.error("Error sending email, please try again");
          return;
        }
      } finally {
        this.setState({ saving: false });
      }
      // Now we can create data
    } else if (step === 3) {
      this.setState({ saving: true });
      try {
        const res = await checkConfirmationCodeAndCreateCompany(user, company, confirmationCode);
        if (res && res.result) {
          toast.success("Email confirmed");
        } else {
          toast.error(`Error while processing data: ${res.error || "Unknown Error"}. Please try again.`);
          return;
        }
      } finally {
        this.setState({ saving: false });
      }
    }
    this.setState({ step: step + 1 });
  };

  handleUserDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const user = _.cloneDeep(this.state.user);
    const { name, value } = e.target;
    _.set(user, name, value);
    this.setState({ user });
  };

  handleCompanyDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const company = _.cloneDeep(this.state.company);
    const { name, value } = e.target;
    _.set(company, name, value);
    this.setState({ company });
  };

  handleCompanyCountryChange = (e: SelectOption) => {
    const company = _.cloneDeep(this.state.company);
    company.address[0].country = e.value;
    this.setState({ company });
  };

  handleConfirmationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmationCode: e.target.value });
  };

  getDefaultState = (show: boolean): CreateCustomerAccountModalState => {
    const user = getDefaultUser(undefined, undefined, undefined, undefined, CUSTOMER);
    user.phones.push({ value: "", description: "" });
    user.emails.push({ value: "", description: "" });
    const company = getDefaultCompany();
    company.address.push(getDefaultAddress("DE"));
    company.primaryPerson = user._id.toString();
    company.activated = false;
    return { show, step: 0, user, company, confirmationCode: "", saving: false };
  };

  validateData = () => {
    const { step, user, company, confirmationCode } = this.state;
    const errors: Array<string> = [];
    if (step === 1) {
      if (user.prename.trim().length < 2) errors.push("Please enter a prename");
      if (user.surname.trim().length < 2) errors.push("Please enter a surname");
      if (!validator.isEmail(user.emails[0].value)) errors.push("Please enter a valid email");
    } else if (step === 2) {
      if (company.name.trim().length === 0) errors.push("Please enter a company name"); // Since X is a valid company we need to accept 1 character
      if (!validator.isEmail(company.mail)) errors.push("Please enter a valid email");
      if (company.address[0].street.trim().length < 2) errors.push("Please enter a street name");
      if (company.address[0].houseNo.trim().length === 0) errors.push("Please enter a house number");
      if (company.address[0].postalCode.trim().length < 3) errors.push("Please enter a postal code");
      if (company.address[0].city.trim().length < 2) errors.push("Please enter a city name");
    } else if (step === 3) {
      if (confirmationCode.trim().length !== 5) errors.push("Confirmation code contains 5 digits");
    }
    return errors;
  };

  render() {
    const { show, step, user, company, confirmationCode, saving } = this.state;
    const errors = this.validateData();

    return (
      <>
        <button className="btn btn-success" onClick={this.handleShow}>
          Create Account
        </button>
        <Modal contentClassName="bg-dark" show={show} size="lg" centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Create Customer Access</h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            {step === 0 ? (
              <RegistrationStart />
            ) : step === 1 ? (
              <UserInformation user={user} onUserDataChange={this.handleUserDataChange} />
            ) : step === 2 ? (
              <CompanyInformation
                company={company}
                onCompanyDataChange={this.handleCompanyDataChange}
                onCompanyCountryChange={this.handleCompanyCountryChange}
              />
            ) : step === 3 ? (
              <EmailConfirmation
                user={user}
                confirmationCode={confirmationCode}
                onConfirmationCodeChange={this.handleConfirmationCodeChange}
              />
            ) : (
              step === 4 && <RegistrationDone />
            )}
          </Modal.Body>
          <Modal.Footer>
            {step !== 4 && (
              <ErrorOverlayButton
                errors={[]}
                saving={saving}
                className="btn btn-sm btn-outline btn-text-danger"
                buttonText="Cancel"
                onClick={this.handleHide}
              />
            )}
            <ErrorOverlayButton
              errors={[]}
              saving={saving}
              disabled={step === 0 || step === 4}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Back"
              onClick={this.handleBack}
            />
            <ErrorOverlayButton
              errors={errors}
              saving={saving}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText={step === 4 ? "Close" : "Next"}
              onClick={step === 4 ? this.handleHide : this.handleNext}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class RegistrationStart extends PureComponent {
  render() {
    return (
      <span className="fw-bolder text-white text-center">
        Thank you for your interest in accessing Rawbids. We need a few more details from you so that we can create your
        account.
        <br />
        <br />
        Please click on "Next" to start the registration process.
      </span>
    );
  }
}

interface UserInformationProps {
  user: UserData;
  onUserDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class UserInformation extends PureComponent<UserInformationProps> {
  render() {
    const { user, onUserDataChange } = this.props;
    return (
      <>
        <span className="d-block text-center text-muted mb-5">
          Please insert some information about you. All details can be changed later in settings.
        </span>
        <div className="mb-5">
          <div className="row">
            <div className="col-12 col-sm-6">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Prename</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="prename"
                value={user.prename}
                placeholder="Prename"
                autoComplete="off"
                onChange={onUserDataChange}
              />
            </div>
            <div className="col-12 col-sm-6">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Surname</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="surname"
                value={user.surname}
                placeholder="Surname"
                autoComplete="off"
                onChange={onUserDataChange}
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
            <span>Position</span>
          </label>
          <Input
            className="form-control custom-form-control"
            type="text"
            name="position"
            value={user.position}
            placeholder="Position"
            autoComplete="off"
            onChange={onUserDataChange}
          />
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-12 col-sm-6">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span>Phone Number</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="phones[0].value"
                value={user.phones[0].value}
                placeholder="Phone"
                autoComplete="off"
                onChange={onUserDataChange}
              />
            </div>
            <div className="col-12 col-sm-6">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Email</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="emails[0].value"
                value={user.emails[0].value}
                placeholder="Email"
                autoComplete="off"
                onChange={onUserDataChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

interface CompanyInformationProps {
  company: Company;
  onCompanyDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCompanyCountryChange: (e: SelectOption) => void;
}

class CompanyInformation extends PureComponent<CompanyInformationProps> {
  render() {
    const { company, onCompanyDataChange, onCompanyCountryChange } = this.props;

    return (
      <>
        <span className="d-block text-center text-muted mb-5">
          Please insert some information about your company. All details can be changed later in settings.
        </span>
        <div className="mb-5">
          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
            <span className="required">Company Name</span>
          </label>
          <Input
            className="form-control custom-form-control"
            type="text"
            name="name"
            value={company.name}
            placeholder="Company Name"
            autoComplete="off"
            onChange={onCompanyDataChange}
          />
        </div>
        <div className="row">
          <div className="col-8">
            <div className="mb-5">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Street</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="address[0].street"
                value={company.address[0].street}
                placeholder="Street"
                autoComplete="off"
                onChange={onCompanyDataChange}
              />
            </div>
          </div>
          <div className="col-4">
            <label className="d-flex align-items-center fs-6 fw-bold mb-2">
              <span className="required">House No.</span>
            </label>
            <div className="mb-5">
              <Input
                className="form-control custom-form-control"
                type="text"
                name="address[0].houseNo"
                value={company.address[0].houseNo}
                placeholder="House No."
                autoComplete="off"
                onChange={onCompanyDataChange}
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-3">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Postal Code (ZIP)</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="address[0].postalCode"
                value={company.address[0].postalCode}
                placeholder="ZIP"
                autoComplete="off"
                onChange={onCompanyDataChange}
              />
            </div>
            <div className="col-5">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">City</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="address[0].city"
                value={company.address[0].city}
                placeholder="City"
                autoComplete="off"
                onChange={onCompanyDataChange}
              />
            </div>
            <div className="col-4">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Country</span>
              </label>
              <CustomSelect
                options={Object.values(countryList.getNames("en", { select: "alias" })).map((item: string) => {
                  return {
                    value: countryList.getAlpha2Code(item, "en"),
                    label: item,
                  };
                })}
                value={{ value: company.address[0].country, label: getCountryNameForCode(company.address[0].country) }}
                placeholder="Select Country..."
                matchFormControl={true}
                onChange={onCompanyCountryChange}
              />
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-12 col-sm-6">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span>VAT-ID</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="vat"
                value={company.vat}
                placeholder="VAT-ID"
                autoComplete="off"
                onChange={onCompanyDataChange}
              />
            </div>
            <div className="col-12 col-sm-6">
              <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                <span className="required">Company Mail</span>
              </label>
              <Input
                className="form-control custom-form-control"
                type="text"
                name="mail"
                value={company.mail}
                placeholder="info@company.com"
                autoComplete="off"
                onChange={onCompanyDataChange}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

interface EmailConfirmationProps {
  user: UserData;
  confirmationCode: string;
  onConfirmationCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class EmailConfirmation extends PureComponent<EmailConfirmationProps> {
  handleResendCode = () => {
    // Fire and forget
    generateAndSendEmailConfirmation(this.props.user, true);
  };

  render() {
    const { confirmationCode, onConfirmationCodeChange } = this.props;
    return (
      <>
        <span className="d-block text-center text-muted mb-5">
          To ensure that your email really exists we need to confirm it. We have send a 5-digit code to the email you
          inserted in step 1. It might take some seconds until the email arrives. Please enter the code below.
        </span>
        <div className="mb-5">
          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
            <span>Confirmation Code</span>
          </label>
          <Input
            className="form-control custom-form-control"
            type="text"
            name="confirmationCode"
            value={confirmationCode}
            placeholder="Confirmation Code"
            autoComplete="off"
            onChange={onConfirmationCodeChange}
          />
        </div>
        <span className="d-block text-center text-muted mb-5">
          If you have not received an email, please check your spam folder.
        </span>
        <div className="d-block text-center link-primary fs-8 cursor-pointer" onClick={this.handleResendCode}>
          Resend Code
        </div>
      </>
    );
  }
}

class RegistrationDone extends PureComponent {
  render() {
    return (
      <span className="d-block text-center text-muted mb-5">
        Thank you for your interest in RAWBIDS. Our staff will check your information and activate your account soon.
        You will receive an email from us as soon as this process is completed.
        <br />
        <br />
        You can now close this dialog.
      </span>
    );
  }
}

export default CreateCustomerAccountModal;
