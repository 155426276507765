import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { PriceTickerData } from "../../../model/commonTypes";
import { formatCurrency } from "../../../utils/baseUtils";
import userService from "../../../services/userService";
import { ANONYMOUS } from "../../../utils/userUtils";
import AnonymousBlurText from "../../common/anonymous/AnonymousBlurText";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import { hasPricesInAnonymousView } from "../../../utils/anonymousViewUtils";
import { DataContextAnonymous } from "../../../context/dataContext";

interface PriceTickerPriceProps {
  tickerData: PriceTickerData;
}

const PriceTickerPrice: React.FC<PriceTickerPriceProps> = ({ tickerData }) => {
  const context = useContext(DataContextAnonymous);
  const { article, price, priceDevelopment, priceGraph, priceCurrency } = tickerData;

  // User Type will never change thus we do not need to add this to dependencies
  const isAU = useMemo(() => userService.getUserType() === ANONYMOUS, []);
  const showPrice = useMemo(
    () => !isAU || hasPricesInAnonymousView(article._id.toString(), context.configuration),
    [context.configuration, article._id]
  );

  return (
    <div style={{ paddingLeft: "25px" }} className="d-inline-flex text-center background-dark">
      <div>
        <div className={"d-inline " + (isAU && "text-right")} style={{ lineHeight: "12px" }}>
          <Link
            to={"/commodity/" + article._id.toString()}
            className="custom-link text-white fw-bolder text-ellipsis"
            style={{ fontSize: 11, width: "110px" }}
          >
            {article.title.en}
          </Link>
          <div>
            {showPrice ? (
              <span className="text-white fw-bolder" style={{ fontSize: 15 }}>
                {price && price > 0 ? (
                  <span className="fs-6 priceticker-price">{formatCurrency(price, priceCurrency)}</span>
                ) : (
                  <small className="fs-8">No price available</small>
                )}
              </span>
            ) : (
              <span className="text-white fw-bolder" style={{ fontSize: 15 }}>
                <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
              </span>
            )}
            {priceDevelopment?.percentage !== undefined ? (
              <span
                className={`ml-2 float-right priceticker-percentage 
                    ${
                      priceDevelopment.percentage > 0
                        ? "text-danger"
                        : priceDevelopment.percentage < 0
                        ? "text-success"
                        : "text-muted"
                    }
                  `}
                style={{ fontSize: 12 }}
              >
                {priceDevelopment.percentage > 0 ? "+" : ""}
                {priceDevelopment.percentage}%
              </span>
            ) : (
              <span className={"ml-1 text-muted"} style={{ fontSize: 12 }}>
                -%
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        <div id="chart" style={{ marginLeft: 7 }}>
          {priceGraph && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(priceGraph)}`} alt="priceGraph" />}
        </div>
      </div>
    </div>
  );
};

export default PriceTickerPrice;
