import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import userService from "../../../services/userService";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { SettingEntry } from "../../../utils/settingsUtils";

interface SettingProps {
  type: SettingEntry;
  requiresAdmin?: boolean;
  requiredRole?: Array<string>;
}

class Setting extends PureComponent<SettingProps> {
  /**
   * Resolves the header of the setting.
   * @returns { string } Header text
   */
  resolveHeader = (): string => {
    const { type } = this.props;
    switch (type) {
      case SettingEntry.PROPERTIES:
        return "Properties";
      case SettingEntry.ACTIVE_SUBSTANCES:
        return "Active Substances";
      case SettingEntry.SERVICES:
        return "Services";
      case SettingEntry.GENERAL_VALUES:
        return "General Values";
      case SettingEntry.VERSION_HISTORY:
        return "Version History";
      case SettingEntry.USERDATA:
        return "User Profile";
      case SettingEntry.SEAPORTS:
        return "Seaports";
      case SettingEntry.AIRPORTS:
        return "Airports";
      case SettingEntry.CALCULATION_VALUES:
        return "Price Calculation Values";
      case SettingEntry.NOTIFIES:
        return "Notifies";
      case SettingEntry.SYSTEM_NOTIFICATION:
        return "System Notification Configuration";
      case SettingEntry.ANONYMOUS_VIEW_ARTICLE_PRICES:
        return "Anonymous View Article Prices";
      case SettingEntry.PRICE_GRADUATION:
        return "Price Graduation Configuration";
      case SettingEntry.RESPONSIBLE:
        return "Responsible for Dashboard Steps";
      case SettingEntry.DELIVERY_TIMES:
        return "Delivery Time Values";
      case SettingEntry.EXPENSE_VALUES:
        return "Expenses for Cashflow Card";
      case SettingEntry.WHITELIST_PRICE_REQUEST:
        return "Whitelist Configuration for automated Price Requests";
      default:
        return "Unknown";
    }
  };

  /**
   * Resolves the description of the setting.
   * @returns { string } Description text
   */
  resolveBody = (): string => {
    const { type } = this.props;
    switch (type) {
      case SettingEntry.PROPERTIES:
        return (
          "Properties are attributes like tags that can be added to commodities. " +
          "Different categories or compositions are also handled via properties."
        );
      case SettingEntry.ACTIVE_SUBSTANCES:
        return (
          "Active substances are ingredients like vitamins that are part of a commodity. " +
          "Many of those substances also got a nutrient reference value which indicates how much of the " +
          "substance should be taken daily."
        );
      case SettingEntry.SERVICES:
        return (
          "Services are additional tasks that are performed after commodities have arrived at the warehouse. " +
          "Those includes analyses, special labeling etc."
        );
      case SettingEntry.GENERAL_VALUES:
        return "Adjust general values like delivery times for specific transport ways of costs for customs.";
      case SettingEntry.VERSION_HISTORY:
        return "Create change logs for updates and adjust them.";
      case SettingEntry.USERDATA:
        return "Adjust personal account settings like name, phone numbers, emails or change your password.";
      case SettingEntry.SEAPORTS:
        return "Create and adjust seaports and their relevant data. These are required as start locations for supplier orders.";
      case SettingEntry.AIRPORTS:
        return "Create and adjust airports and their relevant data. These are required as start locations for supplier orders.";
      case SettingEntry.CALCULATION_VALUES:
        return "Adjust the base values, e.g. palette data, sea or air freight cost for price calculation.";
      case SettingEntry.NOTIFIES:
        return "Adjust notifies at sea- and airports.";
      case SettingEntry.SYSTEM_NOTIFICATION:
        return "Create and manage System Notifications";
      case SettingEntry.ANONYMOUS_VIEW_ARTICLE_PRICES:
        return "Adjust which articles are shown with prices in the anonymous view.";
      case SettingEntry.PRICE_GRADUATION:
        return "Adjust global and category price graduations for articles.";
      case SettingEntry.RESPONSIBLE:
        return (
          "Configure who is responsible for which step on the dashboard. Users will see steps they are not assigned to slightly greyed out. " +
          "Also the portraits of the responsible users will be shown at the steps."
        );
      case SettingEntry.DELIVERY_TIMES:
        return "Configure the duration of different transportation methods.";
      case SettingEntry.EXPENSE_VALUES:
        return "Configure the static expenses that should be added to the cashflow card inside the controlling dashboard";
      case SettingEntry.WHITELIST_PRICE_REQUEST:
        return "Whitelist e-mail addresses that should be able to use the RAWBIDS-BOT.";
      default:
        return "Unknown";
    }
  };

  /**
   * Resolves the link to configure the setting.
   * @returns { string } Link to setting configuration
   */
  resolveLink = (): string => {
    const { type } = this.props;
    switch (type) {
      case SettingEntry.PROPERTIES:
        return "/properties";
      case SettingEntry.ACTIVE_SUBSTANCES:
        return "/activeSubstances";
      case SettingEntry.SERVICES:
        return "/services";
      case SettingEntry.GENERAL_VALUES:
        return "/generalValuesSetting";
      case SettingEntry.VERSION_HISTORY:
        return "/versionHistorySetting";
      case SettingEntry.USERDATA:
        return "/profile";
      case SettingEntry.SEAPORTS:
        return "/seaports";
      case SettingEntry.AIRPORTS:
        return "/airports";
      case SettingEntry.CALCULATION_VALUES:
        return "/priceCalculationValues";
      case SettingEntry.NOTIFIES:
        return "/notifies";
      case SettingEntry.SYSTEM_NOTIFICATION:
        return "/systemNotificationConfiguration";
      case SettingEntry.ANONYMOUS_VIEW_ARTICLE_PRICES:
        return "/anonymousViewArticlePrices";
      case SettingEntry.PRICE_GRADUATION:
        return "/priceGraduationConfiguration";
      case SettingEntry.RESPONSIBLE:
        return "/responsibleConfiguration";
      case SettingEntry.DELIVERY_TIMES:
        return "/deliveryTimesSetting";
      case SettingEntry.EXPENSE_VALUES:
        return "/expenseValues";
      case SettingEntry.WHITELIST_PRICE_REQUEST:
        return "/priceRequestWhitelistConfiguration";
      default:
        return "/";
    }
  };

  render() {
    const { requiresAdmin, requiredRole } = this.props;
    const roles = requiredRole ? userService.getRoles() : [];
    const disabled =
      (requiresAdmin && !userService.isAdmin()) || (requiredRole && !requiredRole.some((r) => roles.includes(r)));

    return (
      <>
        <div className="card card-view card-container h-100">
          <div className="card-body">
            <h1>{this.resolveHeader()}</h1>
            <p className={"fs-5 text-white"}>{this.resolveBody()}</p>
          </div>
          <div className="card-footer pt-0" style={{ border: "none" }}>
            {disabled ? (
              <ErrorOverlayButton
                errors={["Admin privileges required."]}
                className="btn btn-outline btn-outline-light float-right"
                buttonText="Configure"
                onClick={() => true}
              />
            ) : (
              <Link className="btn btn-outline btn-outline-light float-right" to={this.resolveLink()}>
                Configure
              </Link>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Setting;
