import _ from "lodash";
import React, { PureComponent } from "react";
import ArticleEUStockEntry from "../common/ArticleEUStockEntry";
import { SupplierEUStockPrices } from "../../../model/commodity.types";
import { DataContextInternalType } from "../../../context/dataContext";
import AddEUStockModal from "./modals/AddEUStockModal";
import { SupplierExtended } from "../../../model/supplier.types";
import { getDefaultEUStock } from "../../../utils/commodityUtils";
import { InternalArticleExtended } from "../../../utils/productArticleUtils";

import { extendSupplier } from "../../../utils/dataTransformationUtils";

interface ArticleEUStockProps {
  context: DataContextInternalType;
  commodity: InternalArticleExtended;
}

interface ArticleEUStockState {
  euStocks: Array<SupplierEUStockPrices>;
}

class ArticleEUStock extends PureComponent<ArticleEUStockProps, ArticleEUStockState> {
  constructor(props: ArticleEUStockProps) {
    super(props);
    this.state = { euStocks: _.cloneDeep(props.commodity.supplierEUStocks) || [] };
  }

  componentDidUpdate(prevProps: Readonly<ArticleEUStockProps>) {
    if (!_.isEqual(prevProps.commodity, this.props.commodity)) {
      this.setState({ euStocks: _.cloneDeep(this.props.commodity.supplierEUStocks) || [] });
    }
  }

  handleAddEUStock = (supplier: SupplierExtended) => {
    const euStocks = _.cloneDeep(this.state.euStocks);
    euStocks.push(getDefaultEUStock(supplier._id.toString()));
    this.setState({ euStocks });
  };

  handleResetStock = () => {
    this.setState({ euStocks: _.cloneDeep(this.props.commodity.supplierEUStocks) || [] });
  };

  render() {
    const { commodity, context } = this.props;
    const { euStocks } = this.state;

    return (
      <div id="euStock" className="card bg-white mt-5">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">EU Stock</span>
            <span className="text-muted fw-bold fs-7">{commodity.supplierEUStocks?.length || 0} Entries</span>
          </h3>
        </div>
        <div className="card-body p-4 pt-4">
          {euStocks.map((esp) => {
            const sup = context.supplier.find((s) => s._id.toString() === esp.supplier);
            return (
              <div key={esp._id.toString()}>
                <ArticleEUStockEntry
                  article={commodity}
                  euStockPrice={esp}
                  supplier={sup ? extendSupplier(sup, context) : undefined}
                />
              </div>
            );
          })}
          <div className="pt-2">
            <AddEUStockModal onAddEUStock={this.handleAddEUStock} article={commodity} context={context} />
            <button type="button" className="btn btn-light btn-sm float-right mr-2" onClick={this.handleResetStock}>
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleEUStock;
