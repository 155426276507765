import React from "react";
import { resolveFilePath } from "../../../utils/fileUtils";
import { formatDate, toAbsoluteUrl } from "../../../utils/baseUtils";
import { D_MASTERSPECIFICATION } from "../../../utils/commodityUtils";
import { ArticleExtended, getSupplierSpecs } from "../../../utils/productArticleUtils";

interface CommoditySpecificationsProps {
  article: ArticleExtended;
}

const CommoditySpecifications: React.FunctionComponent<CommoditySpecificationsProps> = ({ article }) => {
  const specs = getSupplierSpecs(article);
  if (specs.length === 0) {
    const masterSpec = article.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    if (masterSpec) specs.push(masterSpec);
  }
  return (
    <div className="py-0">
      {!article.hiddenSupplier && (
        <>
          <div className="d-flex py-6 align-items-center w-100">
            <div className="me-3">
              <div className="text-white fs-5 fw-bolder">Specifications</div>
              <div className="text-muted">Specifications from suppliers determining target values</div>
            </div>
          </div>
          <div className="table-responsive mt-5 pt-2 bg-light2">
            <table className="table align-middle gs-0 gy-1">
              <thead className="border-bottom-dark-gray">
                <tr className="fw-bolder text-muted">
                  <th>Type</th>
                  <th>File</th>
                  <th>Valid til</th>
                </tr>
              </thead>
              <tbody>
                {specs && specs.length > 0 ? (
                  specs.map((spec) => (
                    <tr key={spec._id.toString()}>
                      <td className="align-middle" style={{ width: "45%" }}>
                        <a href={resolveFilePath(spec.path)} target="_blank" rel="noopener noreferrer">
                          <span className="text-white fw-bolder">
                            {spec.type === D_MASTERSPECIFICATION
                              ? "Master Specification"
                              : `Specification ${spec.supplier?.name || ""}`}
                          </span>
                        </a>
                      </td>
                      <td className="align-middle" style={{ width: "35%" }}>
                        <a
                          href={resolveFilePath(spec.path)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-nowrap"
                        >
                          <img
                            alt="pdf"
                            className="w-30px mr-2"
                            src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                          />
                          <span className="text-white fw-bold align-middle">
                            <span className="d-inline-block text-ellipsis" style={{ maxWidth: "150px" }}>
                              {spec.name}
                            </span>
                            {spec.size && <small className="text-muted ml-2">{Math.round(spec.size / 1024)} KB</small>}
                          </span>
                        </a>
                      </td>
                      <td className="align-middle" style={{ width: "20%" }}>
                        {spec.validUntil ? (
                          <span className={spec.validUntil > new Date() ? "text-success" : "text-danger"}>
                            {formatDate(spec.validUntil)}
                          </span>
                        ) : (
                          <span className="text-white">-</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="align-middle text-center py-5 cursor-default" colSpan={4}>
                      <span className="text-white fw-bolder">No specifications currently available yet</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default CommoditySpecifications;
