import React from "react";
import LoginForm from "./LoginForm";
import LoginInformation from "./LoginInformation";
import { toAbsoluteUrl } from "../../utils/baseUtils";

interface RawbidsLoginProps {
  email: string;
  error: string;
  fromLogout: boolean;
  loggingIn: boolean;
  password: string;
  resetSuccess: boolean;
  onEmailChange: (email: string) => void;
  onPasswordChange: (password: string) => void;
  onSubmit: (e: React.FormEvent) => void;
}

const RawbidsLogin: React.FC<RawbidsLoginProps> = ({
  email,
  error,
  fromLogout,
  loggingIn,
  password,
  resetSuccess,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}) => (
  <div
    className="d-flex flex-column rawbids-login"
    style={{
      backgroundImage: `url(${toAbsoluteUrl("/assets/media/misc/bg-1.jpg")})`,
      height: "100%",
      backgroundSize: "cover",
      justifyContent: "center",
    }}
  >
    <div className="d-flex flex-column flex-column flex-lg-row">
      <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
        <div className="d-flex flex-center flex-lg-start flex-column">
          <img alt="Logo" src={toAbsoluteUrl("/assets/media/logo.png")} className="mb-7 h-80px h-80px" />
          <h2 className="text-white fw-bold m-0">The biggest virtual marketplace for premium ingredients.</h2>
        </div>
      </div>
      <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
        <div
          className="bg-body d-flex flex-column align-items-stretch flex-center w-md-600px p-20 border-none"
          style={{ borderRadius: "1.5rem" }}
        >
          <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
            <LoginForm
              email={email}
              password={password}
              error={error}
              loggingIn={loggingIn}
              fromLogout={fromLogout}
              resetSuccess={resetSuccess}
              onEmailChange={onEmailChange}
              onPasswordChange={onPasswordChange}
              onSubmit={onSubmit}
            />
          </div>
          <LoginInformation />
        </div>
      </div>
    </div>
  </div>
);

export default RawbidsLogin;
