import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType, DataContextType } from "../../context/dataContext";
import CustomerCommodityCardElement from "../commodities/customer/CustomerCommodityCardElement";
import Pagination from "./Pagination";
import CustomerFinishedProductCardElement from "../finishedProducts/customer/CustomerFinishedProductCardElement";
import OrderCardElement from "../orders/customer/OrderCardElement";
import CustomerRequestCardElement from "../request/internal/customerRequests/CustomerRequestCardElement";
import CustomerContractCardElement from "../customerContract/customer/CustomerContractCardElement";
import { Commodity } from "../../model/commodity.types";
import { CustomerCommodity } from "../../model/customer/customerCommodity.types";
import { FinishedProduct } from "../../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../../model/customer/customerFinishedProduct.types";
import { CustomerCustomerOrder } from "../../model/customer/customerCustomerOrder.types";
import { CustomerSampleOrder } from "../../model/customer/customerSampleOrder.types";
import { CustomerRequest } from "../../model/customerRequest.types";
import { CustomerCustomerContract } from "../../model/customer/customerCustomerContract.types";
import InvoiceCardElement from "../finance/customer/InvoiceCardElement";
import { Invoice } from "../../model/invoice.types";
import { CARD_LISTING_TWO_COLUMN_BREAKPOINT } from "../../utils/baseUtils";

export enum CardListingWrapperComponent {
  CustomerCommodityCard,
  CustomerFinishedProductCard,
  CustomerOrderCard,
  CustomerRequest,
  CustomerContract,
  CustomerFinance,
}

interface CardListingProps extends RouteComponentProps {
  paginatedDocuments: Array<unknown>;
  context: DataContextType;
  wrapperComponent: CardListingWrapperComponent;
  documents?: Array<unknown>;
  currentPage?: number;
  pageSize?: number;
  baseSize?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  financeComponentDetails?: {
    onClickCheckbox?: (_id: string) => void;
    isCreditNote: boolean;
    selectedElements: Array<string>;
  };
}

const CardListing: React.FunctionComponent<CardListingProps> = ({
  paginatedDocuments,
  wrapperComponent,
  context,
  documents,
  pageSize,
  currentPage,
  onPageSizeChange,
  onPageChange,
  baseSize,
  financeComponentDetails,
}) => {
  const resolveWrapperComponent = (document: unknown) => {
    try {
      switch (wrapperComponent) {
        case CardListingWrapperComponent.CustomerCommodityCard:
          return (
            <CustomerCommodityCardElement
              commodity={document as Commodity | CustomerCommodity}
              context={context as DataContextCustomerType | DataContextAnonymousType}
            />
          );

        case CardListingWrapperComponent.CustomerFinishedProductCard:
          return (
            <CustomerFinishedProductCardElement
              finishedProduct={document as FinishedProduct | CustomerFinishedProduct}
              context={context as DataContextCustomerType | DataContextAnonymousType}
            />
          );
        case CardListingWrapperComponent.CustomerOrderCard:
          return <OrderCardElement order={document as CustomerCustomerOrder | CustomerSampleOrder} />;
        case CardListingWrapperComponent.CustomerRequest:
          return <CustomerRequestCardElement customerRequest={document as CustomerRequest} />;
        case CardListingWrapperComponent.CustomerContract:
          return (
            <CustomerContractCardElement
              contract={document as CustomerCustomerContract}
              context={context as DataContextCustomerType}
            />
          );
        case CardListingWrapperComponent.CustomerFinance:
          if (financeComponentDetails) {
            return (
              <InvoiceCardElement
                invoice={document as Invoice}
                context={context as DataContextCustomerType}
                checked={financeComponentDetails.selectedElements.some(
                  (eS) => eS === (document as Invoice)._id.toString()
                )}
                isCreditNote={financeComponentDetails.isCreditNote}
                onClickCheckbox={financeComponentDetails.onClickCheckbox}
              />
            );
          }
          return <></>;
        default:
          return <></>;
      }
    } catch (e) {
      return <></>;
    }
  };

  const useTwoColumns = window.innerWidth >= CARD_LISTING_TWO_COLUMN_BREAKPOINT;

  return (
    <>
      {paginatedDocuments.map((document, idx) => {
        const secondDocument = paginatedDocuments[idx + 1];

        if (useTwoColumns) {
          if (idx % 2 === 0) {
            return (
              <div className="d-flex justify-content-between mb-2" key={`card-listing-row-${idx}`}>
                <div className="card bg-light p-0" style={{ width: "48%" }}>
                  <div className="card-body">
                    <div className="row">{resolveWrapperComponent(document)}</div>
                  </div>
                </div>
                {secondDocument !== undefined && (
                  <div className="card bg-light p-0" style={{ width: "48%" }}>
                    <div className="card-body">
                      <div className="row">{resolveWrapperComponent(secondDocument)}</div>
                    </div>
                  </div>
                )}
              </div>
            );
          }
        } else {
          return (
            <div key={`card-listing-${idx}`} className="card bg-light mb-3 p-2">
              <div className="card-body">
                <div className="row">{resolveWrapperComponent(document)}</div>
              </div>
            </div>
          );
        }
      })}
      {documents && pageSize && documents.length > pageSize && currentPage && onPageSizeChange && onPageChange && (
        <Pagination
          itemsCount={documents.length}
          pageSize={pageSize}
          baseSize={baseSize}
          onPageChange={onPageChange}
          currentPage={currentPage}
          onPageSizeChange={onPageSizeChange}
        />
      )}
    </>
  );
};

export default withRouter(CardListing);
