import _ from "lodash";
import React, { PureComponent } from "react";
import ServicesTask from "../../../supplierOrder/ServicesTask";
import CustomerSpecificTask from "../../../supplierOrder/CustomerSpecificTask";
import {
  CO_ARCHIVED,
  CO_CANCELED,
  CO_SHIPPEDTOCUSTOMER,
  CustomerOrderExtended,
} from "../../../../../../model/customerOrder.types";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { getProcessingTasksChecklist } from "../../../../../../utils/customerOrderUtils";
import { Batch } from "../../../../../../model/batch.types";
import { SelectOption } from "../../../../../common/CustomSelect";
import { getBatchAmount } from "../../../../../../utils/batchUtils";

interface WorkflowProcessingStockProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

interface WorkflowProcessingStockState {
  saving: boolean;
  processingTasks: Array<{ task: SelectOption; done: boolean }>;
  relatedBatches?: Array<Batch>;
}

class WorkflowProcessingStockCard extends PureComponent<WorkflowProcessingStockProps, WorkflowProcessingStockState> {
  constructor(props: WorkflowProcessingStockProps) {
    super(props);
    this.state = {
      saving: false,
      processingTasks: getProcessingTasksChecklist(props.order),
      relatedBatches: this.getRelatedBatches(props),
    };
  }

  componentDidUpdate(prevProps: Readonly<WorkflowProcessingStockProps>) {
    if (!_.isEqual(prevProps.order, this.props.order))
      this.setState({
        processingTasks: getProcessingTasksChecklist(this.props.order),
        relatedBatches: this.getRelatedBatches(this.props),
      });
  }

  getRelatedBatches = (props: WorkflowProcessingStockProps) => {
    const { order, context } = props;
    const { batch } = context;
    if (!order.contractInformation) return undefined;
    return batch.filter(
      (b) =>
        !b.disabled &&
        b.customerContracts?.some((cC) => cC === order.contractInformation?._id.toString()) &&
        getBatchAmount(b) > 0
    );
  };

  render() {
    const { order, context } = this.props;
    const { processingTasks, relatedBatches } = this.state;

    const editable = order.state !== CO_CANCELED;
    const servicesDone = order.services.every((s) => s.performed);

    return (
      <>
        <div
          className="opacity-100-hover"
          style={{ opacity: order.state === CO_ARCHIVED || order.state === CO_CANCELED ? 0.3 : 1 }}
        >
          <div className="fw-bolder text-white fs-3 my-5">
            Processing Tasks{" "}
            {order.state === CO_ARCHIVED || order.state === CO_SHIPPEDTOCUSTOMER ? (
              <i className="h2 fas fa-check-circle text-success" />
            ) : order.state !== CO_CANCELED ? (
              <span className="text-warning">[Current Task]</span>
            ) : null}
          </div>
          <div className="row py-2">
            <div className="bg-light2 rounded p-5 mb-7">
              {processingTasks.map((pT) => (
                <div key={pT.task.value} className="form-check my-3">
                  <input className="form-check-input" checked={pT.done} type="checkbox" disabled={true} />
                  <label className="text-white my-0 pl-1">{pT.task.label}</label>
                </div>
              ))}
            </div>
          </div>
        </div>
        {order.services.length > 0 && (
          <>
            <div className="border-bottom-dark-gray pt-5" />
            <div
              className="opacity-100-hover"
              style={{ opacity: servicesDone || order.state === CO_CANCELED ? 0.3 : 1 }}
            >
              <div className="fw-bolder text-white fs-3 my-5">
                Services{" "}
                {servicesDone ? (
                  <i className="h2 fas fa-check-circle text-success" />
                ) : order.state !== CO_CANCELED ? (
                  <span className="text-warning">[Current Task]</span>
                ) : null}
              </div>
              {order.services.map((s) => (
                <ServicesTask
                  key={s.service._id.toString()}
                  order={order}
                  service={s}
                  editable={editable}
                  context={context}
                />
              ))}
            </div>
          </>
        )}
        <div className="border-bottom-dark-gray pt-5" />
        <div
          className="opacity-100-hover"
          style={{ opacity: order.state === CO_ARCHIVED || order.state === CO_CANCELED ? 0.3 : 1 }}
        >
          <div className="fw-bolder text-white fs-3 my-5">
            Tasks{" "}
            {order.state === CO_ARCHIVED || order.state === CO_SHIPPEDTOCUSTOMER ? (
              <i className="h2 fas fa-check-circle text-success" />
            ) : order.state !== CO_CANCELED ? (
              <span className="text-warning">[Current Task]</span>
            ) : null}
          </div>
          <CustomerSpecificTask order={order} editable={editable} context={context} batches={relatedBatches} />
        </div>
      </>
    );
  }
}

export default WorkflowProcessingStockCard;
