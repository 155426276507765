import _ from "lodash";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { DataContextInternal } from "../../../context/dataContext";
import BaseListing from "../../common/BaseListing";
import { paginate, PaginationState } from "../../common/Pagination";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import ForwardingOrderRow from "./forwardingOrder/ForwardingOrderRow";
import { FORWARDINGORDER, STORAGEORDER } from "../../../services/dbService";
import StorageOrderRow from "./storageOrder/StorageOrderRow";
import userService from "../../../services/userService";
import { ADMIN, SCM } from "../../../utils/userUtils";
import { ForwardingOrder, FWO_STATES } from "../../../model/forwardingOrder.types";
import { STO_STATES, StorageOrder } from "../../../model/storageOrder.types";
import LogisticsListingFilter from "./LogisticsListingFilter";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { SelectOption } from "../../common/CustomSelect";
import { FW_SORTOPTIONS, LOGISTICSFILTER, ST_SORTOPTIONS } from "../../../utils/logisticsUtils";

interface LogisticsListingProps {
  context: React.ContextType<typeof DataContextInternal>;
}

interface LogisticsListingState extends PaginationState {
  search: string;
  type: typeof FORWARDINGORDER | typeof STORAGEORDER;
  filterBy: SelectOption;
  sortBy: SelectOption;
  sortOrder: { value: "asc" | "desc"; label: string };
}

const COMPONENT_NAME = "LogisticsListing";

class LogisticsListing extends PureComponent<LogisticsListingProps, LogisticsListingState> {
  constructor(props: LogisticsListingProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ search: e.target.value, currentPage: 1 });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleCurrentPageChange = (currentPage: number) => this.setState({ currentPage });

  handleChangeType = (e: typeof FORWARDINGORDER | typeof STORAGEORDER) => {
    this.setState({ type: e, sortBy: e === FORWARDINGORDER ? FW_SORTOPTIONS[0] : ST_SORTOPTIONS[0], currentPage: 1 });
  };

  handleChangeFilterBy = (e: SelectOption) => {
    this.setState({ filterBy: e, currentPage: 1 });
  };

  handleChangeSortBy = (sortBy: SelectOption) => this.setState({ sortBy, currentPage: 1 });

  handleChangeSortOrder = (sortOrder: { value: "asc" | "desc"; label: string }) =>
    this.setState({ sortOrder, currentPage: 1 });

  /**
   * Filters the forwarding or storage order based on search value
   * @returns { Array<ForwardingOrder | StorageOrder> } Filtered Forwarding or Storage orders
   */
  getFilteredLogisticsOrders = (): Array<ForwardingOrder | StorageOrder> => {
    const { forwardingOrder, storageOrder } = this.props.context;
    const { search, sortBy, sortOrder, filterBy, type } = this.state;
    const isFW = type === FORWARDINGORDER;
    let ordersFiltered: Array<ForwardingOrder | StorageOrder> = isFW
      ? _.cloneDeep(forwardingOrder)
      : _.cloneDeep(storageOrder);

    switch (filterBy.value) {
      case "active":
        ordersFiltered = ordersFiltered.filter(
          (o) => o.state === undefined || (o.state !== FWO_STATES.FWO_CANCELED && o.state !== STO_STATES.STO_CANCELED)
        );
        break;
      case "canceled":
        ordersFiltered = ordersFiltered.filter(
          (o) => o.state !== undefined && (o.state === FWO_STATES.FWO_CANCELED || o.state === STO_STATES.STO_CANCELED)
        );
        break;
    }

    // Perform search
    if (search)
      ordersFiltered = isFW
        ? doFuseSearch(ordersFiltered, search, ["forwardingOrderNo", "orderInformation.orderNo"])
        : doFuseSearch(ordersFiltered, search, ["storageOrderNo", "orderInformation.orderNo"]);

    // Handle sorting for search
    if (!sortBy.value && search.trim()) {
      if (sortOrder.value === "desc") return ordersFiltered.reverse();
      return ordersFiltered;
    }

    ordersFiltered = isFW
      ? _.orderBy(ordersFiltered, sortBy.value, sortOrder.value)
      : _.orderBy(ordersFiltered, sortBy.value, sortOrder.value);

    return ordersFiltered;
  };

  getDefaultState = (): LogisticsListingState => {
    return {
      search: "",
      currentPage: 1,
      pageSize: 25,
      type: FORWARDINGORDER,
      sortBy: FW_SORTOPTIONS[0],
      filterBy: LOGISTICSFILTER[0],
      sortOrder: SORTORDEROPTIONS[1],
    };
  };

  render() {
    const { context } = this.props;
    const { pageSize, currentPage, type, sortOrder, sortBy, filterBy } = this.state;
    const isForwardingOrder = type === FORWARDINGORDER;
    const ordersFiltered = this.getFilteredLogisticsOrders();
    const ordersPaginated = paginate(ordersFiltered, currentPage, pageSize);

    const headerDefinitionForwardingOrder = [
      { title: "Forwarding Order", style: { width: "20%" } },
      { title: "Related Orders", style: { width: "20%" } },
      { title: "Type", style: { width: "15%" } },
      { title: "Delivery", style: { width: "15%" } },
      { title: "Return", style: { width: "10%" } },
      { title: "File", style: { width: "10%" } },
      { title: "Action", className: "text-right", style: { width: "10%" } },
    ];
    const headerDefinitionStorageOrder = [
      { title: "Storage Order", style: { width: "30%" } },
      { title: "Related Orders", style: { width: "30%" } },
      { title: "Delivery", style: { width: "20%" } },
      { title: "File", style: { width: "10%" } },
      { title: "Action", className: "text-right", style: { width: "10%" } },
    ];
    const roles = userService.getRoles();
    const isSCMOrAdmin = roles.includes(ADMIN) || roles.includes(SCM);

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        {isSCMOrAdmin ? (
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="card bg-white h-100">
                <div className="card-body">
                  <h3 className="card-title align-items-start flex-column mb-15">
                    <span className="card-label fw-bolder mb-3 fs-3rem">Logistics</span>
                    {isForwardingOrder ? (
                      <Link className="btn btn-outline btn-outline-light float-right" to="/createForwardingOrder">
                        New Forwarding Order
                      </Link>
                    ) : (
                      <Link className="btn btn-outline btn-outline-light float-right" to="/createStorageOrder">
                        New Storage Order
                      </Link>
                    )}
                  </h3>
                  <div className="row">
                    <div className="col-12">
                      <div className="btn-group mt-4 w-100">
                        <div
                          className={"w-50 btn btn-light " + (type === FORWARDINGORDER && "active")}
                          onClick={() => this.handleChangeType(FORWARDINGORDER)}
                        >
                          Forwarding Order
                        </div>
                        <div
                          className={"w-50 btn btn-light " + (type === STORAGEORDER && "active")}
                          onClick={() => this.handleChangeType(STORAGEORDER)}
                        >
                          Storage Order
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <LogisticsListingFilter
                      type={type}
                      sortBy={sortBy}
                      filterBy={filterBy}
                      sortOrder={sortOrder}
                      onChangeFilterBy={this.handleChangeFilterBy}
                      onChangeSortBy={this.handleChangeSortBy}
                      onChangeSortOrder={this.handleChangeSortOrder}
                      onChangeSearch={this.handleChangeSearch}
                    />
                  </div>
                  {isForwardingOrder ? (
                    <BaseListing
                      headerDefinition={headerDefinitionForwardingOrder}
                      bodyContent={
                        <>
                          {ordersPaginated.length > 0 ? (
                            ordersPaginated.map((fO) => (
                              <ForwardingOrderRow
                                key={fO._id.toString()}
                                context={context}
                                forwardingOrder={fO as ForwardingOrder}
                              />
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={10}>
                                No forwarding orders found
                              </td>
                            </tr>
                          )}
                        </>
                      }
                      baseSize={25}
                      noHover={true}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      documents={ordersFiltered}
                      onPageChange={this.handleCurrentPageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                    />
                  ) : (
                    <BaseListing
                      headerDefinition={headerDefinitionStorageOrder}
                      bodyContent={
                        <>
                          {ordersPaginated.length > 0 ? (
                            ordersPaginated.map((sO) => (
                              <StorageOrderRow
                                key={sO._id.toString()}
                                context={context}
                                storageOrder={sO as StorageOrder}
                              />
                            ))
                          ) : (
                            <tr>
                              <td className="text-center" colSpan={10}>
                                No storage orders found
                              </td>
                            </tr>
                          )}
                        </>
                      }
                      baseSize={25}
                      noHover={true}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      documents={ordersFiltered}
                      onPageChange={this.handleCurrentPageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-label fw-bolder mb-3 fs-3rem">Logistics</h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LogisticsListing;
