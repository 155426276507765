import _ from "lodash";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { DataContextInternal } from "../../../context/dataContext";
import InvoiceSettings from "./InvoiceSettings";
import InvoicePositions from "./InvoicePositions";
import InvoiceFooter from "./InvoiceFooter";
import { SelectOption } from "../../common/CustomSelect";
import { createPDF } from "../../../utils/pdfUtils";
import {
  extendInvoice,
  getDefaultPosition,
  getPositionsFromOrder,
  getTotalOpenSumToPay,
  I_CURRENCIES,
  I_INTEREST,
  I_P_DUNNING,
  I_P_PAYMENT,
  I_PAYMENTTARGETS,
  insertInvoice,
  negateInvoice,
  negatePositions,
  updateInvoice,
} from "../../../utils/invoiceUtils";
import {
  Cancelation,
  FIRSTDUNNING,
  I_CREDIT_NOTE,
  I_CREDITNOTECUSTOMER,
  I_CREDITNOTESAMPLE,
  I_CUSTOMERINVOICE,
  I_INVOICE,
  I_SAMPLEINVOICE,
  I_STATE,
  Invoice,
  InvoiceType,
  Position,
  REMINDER,
  Reminder,
  SECONDDUNNING,
  VATPosition,
} from "../../../model/invoice.types";
import { Company } from "../../../model/company.types";
import { CustomerOrder, CustomerOrderExtended } from "../../../model/customerOrder.types";
import { resolveFilePath } from "../../../utils/fileUtils";
import {
  cancelationText,
  createInvoiceHTML,
  creditNoteText,
  nonEURegex,
  nonEUText,
  reminderText,
  reverseChargeRegex,
  reverseChargeText,
  standardText,
  standardTextShort,
} from "../../../utils/pdf/invoiceGenerationUtils";
import { Supplier } from "../../../model/supplier.types";
import { callFunction, INVOICE, transaction } from "../../../services/dbService";
import {
  callPushToArray,
  ceil,
  countDecimals,
  formatCurrency,
  formatDate,
  getDocFromCollection,
} from "../../../utils/baseUtils";
import { getDaysBetween } from "../../../utils/dateUtils";
import { BASE_CURRENCY, convertCurrency } from "../../../utils/currencyUtils";
import { EXTENDED_ORDER_TYPES, isSampleOrder } from "../../../utils/orderUtils";
import { SampleOrder, SampleOrderExtended } from "../../../model/sampleOrder.types";
import { formatAddress, getAddressByType } from "../../../utils/addressUtils";
import { AddressType } from "../../../model/commonTypes";
import InvoiceHeader from "./InvoiceHeader";
import { getCompanySnapshot, getCustomerPaymentTarget, isCompany } from "../../../utils/companyUtils";
import { extendSampleOrder } from "../../../utils/sampleOrderUtils";
import { extendCustomerOrder } from "../../../utils/dataTransformationUtils";
import { getSupplierSnapshot } from "../../../utils/supplierUtils";

interface InvoiceParams {
  id?: string;
  type?: string;
}

interface CreateInvoiceProps extends RouteComponentProps<InvoiceParams> {
  reminder?: boolean;
  cancelation?: boolean;
  creditNote?: boolean;
}

interface CreateInvoiceState {
  type: SelectOption;
  currency: SelectOption;
  company?: Company | Supplier;
  order?: EXTENDED_ORDER_TYPES;
  paymentTarget: SelectOption;
  reverseCharge: boolean;
  nonEU: boolean;
  companyName: string;
  companyAddress: string;
  companyVAT: string;
  invoiceNumber: string;
  deliveryDate: Date;
  invoiceDate: Date;
  positions: Array<Position>;
  vatPositions: Array<VATPosition>;
  subtotal: number;
  total: number;
  notes: EditorState; // Type of the draft js WYSIWYG editor
  reminderText?: EditorState;
  cancelationText?: EditorState;
  creditNoteText?: EditorState;
  generating: "" | "draft" | "invoice";
  invoice?: Invoice;
  dunningFee: number;
  sendMailToCustomer: boolean;
}

class CreateInvoice extends PureComponent<CreateInvoiceProps, CreateInvoiceState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  constructor(props: CreateInvoiceProps) {
    super(props);
    this.state = {
      type: props.cancelation
        ? { value: "cancelation", label: "Cancelation" }
        : props.reminder
        ? { value: "reminder", label: "Reminder" }
        : props.creditNote
        ? { value: I_CREDIT_NOTE, label: "Credit Note" }
        : { value: I_INVOICE, label: "Invoice" },
      currency: I_CURRENCIES.find((c) => c.value === BASE_CURRENCY) || I_CURRENCIES[1],
      paymentTarget: I_PAYMENTTARGETS.find((pt) => pt.value === "14") || I_PAYMENTTARGETS[0],
      reverseCharge: false,
      nonEU: false,
      companyName: "",
      companyAddress: "",
      companyVAT: "",
      invoiceNumber: "",
      deliveryDate: new Date(),
      invoiceDate: new Date(),
      positions: [],
      vatPositions: [],
      subtotal: 0,
      total: 0,
      notes: this.getNote(),
      creditNoteText: props.creditNote ? this.getCreditNoteText() : undefined,
      generating: "",
      dunningFee: 0,
      sendMailToCustomer: false,
    };
  }

  componentDidMount() {
    const { match, reminder, cancelation, creditNote, history } = this.props;
    const type = match.params.type;
    if (type && type === "customer") {
      const customerId = match.params.id;
      if (!customerId) return;
      const company = getDocFromCollection(this.context.company, customerId);
      if (company) {
        const paymentTarget = getCustomerPaymentTarget(company);
        this.setState(
          {
            company: company,
            companyName: company.name,
            companyAddress: this.getCompanyAddress(company),
            companyVAT: company.vat,
            paymentTarget: I_PAYMENTTARGETS.find((pt) => pt.value === paymentTarget?.toString()) || I_PAYMENTTARGETS[0],
            positions: [getDefaultPosition()],
          },
          () => this.updateNumbersAndNote(true)
        );
      } else {
        this.handleAddInvoicePosition();
      }
    } else if (reminder || cancelation) {
      const invoiceId = match.params.id;
      const invoice = _.cloneDeep(this.context.invoice.find((i) => i._id.toString() === invoiceId));
      if (invoice) {
        const invoiceExtended = extendInvoice(invoice, this.context);
        const com = invoiceExtended.company;
        const positions = invoice.positions;
        let dunningFee = 0;
        if (invoice.payments.length > 0) {
          for (let i = 0; i < invoice.payments.length; i++) {
            const p = invoice.payments[i];
            positions.push({
              _id: new BSON.ObjectId(),
              price: -p.amount,
              total: -p.amount,
              vatPercentage: 0,
              quantity: 1,
              unit: I_P_PAYMENT,
              title: "Payment from " + formatDate(p.date),
              description: "Amount that was already paid",
            });
          }
        }
        if (reminder) {
          const dueDate = new Date(invoice.invoiceDate);
          dueDate.setDate(dueDate.getDate() + invoice.paymentTarget);
          const reminderInterest = +(
            ((getTotalOpenSumToPay(invoice, true) * (I_INTEREST / 100)) / 365) *
            Math.abs(getDaysBetween(dueDate, new Date(), true))
          ).toFixed(2);
          const staticDunningFee = convertCurrency(40, BASE_CURRENCY, invoice.currency, this.context.currencies);
          const reminderType = invoice.reminders.length === 1 ? FIRSTDUNNING : SECONDDUNNING;
          dunningFee = reminderInterest + (reminderType === FIRSTDUNNING ? 0 : staticDunningFee);
          if (invoice.reminders.length > 0) {
            positions.push({
              _id: new BSON.ObjectId(),
              price: dunningFee,
              total: dunningFee,
              vatPercentage: 0,
              quantity: 1,
              unit: I_P_DUNNING,
              title: "Dunning fees",
              description:
                reminderType === FIRSTDUNNING
                  ? I_INTEREST + "% interest for late payment"
                  : formatCurrency(staticDunningFee, invoice.currency) +
                    " + " +
                    I_INTEREST +
                    "% interest for late payment",
            });
          }
          invoice.reminders.push({
            _id: new BSON.ObjectId(),
            type:
              invoice.reminders.length === 2 ? SECONDDUNNING : invoice.reminders.length === 1 ? FIRSTDUNNING : REMINDER,
            date: new Date(),
            dunningFee,
            reminderFile: "",
          });
        }
        const paymentTarget = invoice
          ? I_PAYMENTTARGETS.find((pt) => pt.value === invoice.paymentTarget.toString())
          : undefined;
        this.setState(
          {
            paymentTarget: paymentTarget ?? this.state.paymentTarget,
            invoice: cancelation ? negateInvoice(_.cloneDeep(invoice)) : invoice,
            invoiceDate: invoice.invoiceDate,
            positions: cancelation ? negatePositions(positions) : positions,
            currency: I_CURRENCIES.find((c) => c.value === invoice.currency) ?? I_CURRENCIES[0],
            deliveryDate: invoiceExtended.relatedOrder?.deliveryDate ?? this.state.deliveryDate,
            company: com,
            companyName: com.name,
            companyAddress: this.getCompanyAddress(com),
            companyVAT: com.vat,
            order: invoiceExtended.relatedOrder,
            reminderText: reminder ? this.getReminderNote(invoice) : undefined,
            cancelationText: cancelation ? this.getCancelationNote(invoice) : undefined,
            creditNoteText: creditNote ? this.getCreditNoteText() : undefined,
            dunningFee,
          },
          () => this.updateNumbersAndNote()
        );
      } else {
        history.push("/createInvoice"); // Reminder or cancelation are not possible without related invoice
      }
    } else {
      const orderId = match.params.id;
      if (orderId) {
        let orderExtended: CustomerOrderExtended | SampleOrderExtended | undefined;
        let order: CustomerOrder | SampleOrder | undefined = this.context.customerOrder.find(
          (cO) => cO._id.toString() === orderId
        );
        if (order) orderExtended = extendCustomerOrder(order, this.context);
        if (!order) {
          order = this.context.sampleOrder.find((sO) => sO._id.toString() === orderId);
          if (order) orderExtended = extendSampleOrder(order, this.context);
        }
        if (order && orderExtended) {
          const com = orderExtended.company;
          this.setState(
            {
              company: com,
              companyName: com.name,
              companyAddress: this.getCompanyAddress(com),
              companyVAT: com.vat,
              order: orderExtended,
              paymentTarget:
                I_PAYMENTTARGETS.find((pt) => pt.value === com.paymentTarget?.toString()) || I_PAYMENTTARGETS[0],
              positions: getPositionsFromOrder(orderExtended, 19),
              currency: { value: order.currency, label: order.currency },
            },
            () => this.updateNumbersAndNote(true)
          );
        } else {
          this.handleAddInvoicePosition();
        }
      } else {
        this.handleAddInvoicePosition();
      }
    }
  }

  handleReverseChargeChange = () => {
    const reverseCharge = !this.state.reverseCharge;
    this.setState({ reverseCharge }, () => this.updateNumbersAndNote(true));
  };

  handleNonEUChange = () => {
    const nonEU = !this.state.nonEU;
    this.setState({ nonEU }, () => this.updateNumbersAndNote(true));
  };

  handleCurrencyChange = (currency: SelectOption) => this.setState({ currency });
  handlePaymentTargetChange = (paymentTarget: SelectOption) => {
    this.setState({ paymentTarget, notes: this.getNote(paymentTarget) });
  };

  handleCompanyChange = (company: SelectOption<Company> | undefined) => {
    if (!company?.object) {
      this.setState({
        company: undefined,
        companyName: "",
        companyAddress: "",
        companyVAT: "",
        order: undefined,
      });
    } else {
      const companyObj = company.object;
      const companyPaymentTarget = getCustomerPaymentTarget(companyObj);
      const paymentTarget = I_PAYMENTTARGETS.find((pT) => pT.value === companyPaymentTarget?.toString());
      this.setState({
        company: companyObj,
        companyName: companyObj.name,
        companyAddress: this.getCompanyAddress(companyObj),
        companyVAT: companyObj.vat,
        paymentTarget: paymentTarget ?? this.state.paymentTarget,
        sendMailToCustomer: false,
      });
    }
  };

  handleOrderChange = (order: SelectOption<EXTENDED_ORDER_TYPES> | undefined) => {
    if (!order?.object) {
      this.setState({ order: undefined });
    } else {
      const { reverseCharge, nonEU } = this.state;
      const orderObj = order.object;
      const positions = getPositionsFromOrder(orderObj, reverseCharge || nonEU ? 0 : 19);
      this.setState({ order: orderObj, positions, deliveryDate: orderObj.deliveryDate ?? new Date() }, () =>
        this.updateNumbersAndNote()
      );
    }
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });

  handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value);
    if (isNaN(date.getTime())) return;
    // @ts-ignore
    this.setState({ [e.target.name]: date });
  };

  handleAddInvoicePosition = () => {
    const positions = [...this.state.positions, getDefaultPosition()];
    this.setState({ positions }, () => this.updateNumbersAndNote());
  };
  handleRemoveInvoicePosition = (id: BSON.ObjectId) => {
    this.setState({ positions: this.state.positions.filter((p) => p._id.toString() !== id.toString()) }, () =>
      this.updateNumbersAndNote()
    );
  };
  handleInvoicePositionChange = (id: BSON.ObjectId, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const positions = _.cloneDeep(this.state.positions);
    const position = positions.find((obj) => obj._id.toString() === id.toString());
    if (!position) return;
    const { name, type, value } = e.target;
    // If more than 2 decimals are entered ceil the value
    if (name === "price" && countDecimals(+value) > 2) _.set(position, name, ceil(+value, 2));
    else _.set(position, name, type === "number" ? +value : value);
    this.setState(
      { positions },
      ["quantity", "unit", "price", "discount", "vatPercentage"].includes(name)
        ? () => this.updateNumbersAndNote()
        : undefined
    );
  };

  handleNotesChange = (notes: EditorState) => this.setState({ notes });
  handleReminderChange = (reminderText: EditorState) => this.setState({ reminderText });
  handleCancelationChange = (cancelationText: EditorState) => this.setState({ cancelationText });
  handleCreditNoteChange = (creditNoteText: EditorState) => this.setState({ creditNoteText });
  handleTypeChange = (type: SelectOption) =>
    this.setState({ type: type }, () =>
      this.setState({
        notes: this.getNote(),
        creditNoteText: type.value === I_CREDIT_NOTE ? this.getCreditNoteText() : undefined,
      })
    );
  handleToggleSendMail = () => {
    this.setState({ sendMailToCustomer: !this.state.sendMailToCustomer });
  };

  /**
   * Creates the invoice PDF
   * @param draft a boolean value if only a draft or an invoice should be created
   */
  handleCreateInvoice = async (draft: boolean) => {
    const {
      type,
      vatPositions,
      companyName,
      companyAddress,
      companyVAT,
      deliveryDate,
      invoiceDate,
      reverseCharge,
      nonEU,
      paymentTarget,
      company,
      order,
      subtotal,
      total,
      positions,
      currency,
      notes,
      reminderText,
      cancelationText,
      creditNoteText,
      dunningFee,
      sendMailToCustomer,
    } = this.state;
    if (!company) return;
    this.setState({ generating: draft ? "draft" : "invoice" });

    const isCreditNote = type.value === I_CREDIT_NOTE;
    let invoiceType: InvoiceType;
    if (order && isSampleOrder(order) && !isCreditNote) {
      invoiceType = I_SAMPLEINVOICE;
    } else if (order && isSampleOrder(order) && isCreditNote) {
      invoiceType = I_CREDITNOTESAMPLE;
    } else if (isCreditNote) {
      invoiceType = I_CREDITNOTECUSTOMER;
    } else {
      invoiceType = I_CUSTOMERINVOICE;
    }

    const invoice: Invoice = this.state.invoice ?? {
      _id: new BSON.ObjectId(),
      invoiceNumber: "",
      invoiceDate,
      paymentTarget: parseInt(paymentTarget.value),
      type: invoiceType,
      state: I_STATE.OPEN,
      company: isCompany(company) ? getCompanySnapshot(company) : getSupplierSnapshot(company),
      subtotal,
      total,
      currency: currency.value,
      positions: [],
      reminders: [],
      payments: [],
      file: "",
      reverseCharge,
      nonEU,
    };
    invoice.positions = positions;

    if (order) invoice.relatedOrder = order._id.toString();

    let note: string = draftToHtml(convertToRaw(notes.getCurrentContent()));
    note = note.replace(/<p/g, "<span");
    note = note.replace(/p>/g, "span><br>");
    let reminder = "";
    if (reminderText) {
      reminder = draftToHtml(convertToRaw(reminderText.getCurrentContent()));
      reminder = reminder.replace(/<p/g, "<span");
      reminder = reminder.replace(/p>/g, "span><br>");
    }
    let cancelation = "";
    if (cancelationText) {
      cancelation = draftToHtml(convertToRaw(cancelationText.getCurrentContent()));
      cancelation = cancelation.replace(/<p/g, "<span");
      cancelation = cancelation.replace(/p>/g, "span><br>");
    }
    let creditNoteInvoiceText = "";
    if (creditNoteText) {
      creditNoteInvoiceText = draftToHtml(convertToRaw(creditNoteText.getCurrentContent()));
      creditNoteInvoiceText = creditNoteInvoiceText.replace(/<p/g, "<span");
      creditNoteInvoiceText = creditNoteInvoiceText.replace(/p>/g, "span><br>");
    }

    let inv = null;
    try {
      if (!draft) {
        if (cancelationText) {
          const path = await createPDF(
            createInvoiceHTML(
              false,
              invoice,
              vatPositions,
              companyName,
              companyAddress,
              companyVAT,
              deliveryDate,
              invoiceDate,
              note,
              reminder,
              cancelation,
              reverseCharge,
              nonEU,
              this.context
            ),
            "Cancelation_Invoice-" + invoice.invoiceNumber,
            company._id.toString(),
            { marginLeft: "2cm" }
          );
          const cancelationObj: Cancelation = {
            _id: new BSON.ObjectId(),
            date: new Date(),
            note: cancelation,
            cancelationFile: path || "",
          };
          const res = await updateInvoice({ cancelation: cancelationObj, state: I_STATE.CANCELED }, invoice._id);
          if (res && res.res.modifiedCount > 0) {
            toast.success("Invoice canceled successfully");
            this.props.history.push("/customerInvoices");
          } else {
            toast.error("Error canceling invoice");
          }
        } else if (reminderText) {
          const path = await createPDF(
            createInvoiceHTML(
              false,
              invoice,
              vatPositions,
              companyName,
              companyAddress,
              companyVAT,
              deliveryDate,
              invoiceDate,
              note,
              reminder,
              cancelation,
              reverseCharge,
              nonEU,
              this.context
            ),
            "Reminder_Invoice-" + invoice.invoiceNumber,
            company._id.toString(),
            { marginLeft: "2cm" }
          );
          const reminderObj: Reminder = {
            _id: new BSON.ObjectId(),
            type:
              invoice.reminders.length === 2 ? SECONDDUNNING : invoice.reminders.length === 1 ? FIRSTDUNNING : REMINDER,
            date: new Date(),
            dunningFee,
            reminderFile: path || "",
          };
          const res = await callPushToArray(INVOICE, invoice._id, "reminders", reminderObj);
          if (res && res.modifiedCount > 0) {
            toast.success("Reminder added successfully");
            this.props.history.push("/customerInvoices");
          } else {
            toast.error("Error adding reminder");
          }
        } else {
          inv = await insertInvoice(invoice); // insert invoice into DB
          if (inv) {
            invoice.invoiceNumber = inv.invoiceNumber; // Create PDF with invoice number generated by backend
            const fileName = isCreditNote
              ? "CreditNote-" + invoice.invoiceNumber
              : "Invoice-" + (invoice.paymentTarget === -1 ? "A" : "") + invoice.invoiceNumber;

            const path = await createPDF(
              createInvoiceHTML(
                false,
                invoice,
                vatPositions,
                companyName,
                companyAddress,
                companyVAT,
                deliveryDate,
                invoiceDate,
                note,
                reminder,
                cancelation,
                reverseCharge,
                nonEU,
                this.context,
                isCreditNote,
                creditNoteInvoiceText
              ),
              fileName,
              company._id.toString(),
              { marginLeft: "2cm" }
            );
            const res = await updateInvoice({ file: path }, inv.res.insertedId);
            let mailRes;
            let invoiceUpdateRes;
            if (res && res.res.modifiedCount) {
              toast.success(isCreditNote ? "Credit note created successfully" : "Invoice created successfully");
              const query = new URLSearchParams(this.props.location.search);
              if (query.has("redirect")) {
                // Go back to page before
                this.props.history.goBack();
              } else if (isCreditNote) {
                this.props.history.push("/customerInvoices?creditNotes=true");
              } else {
                this.props.history.push("/customerInvoices");
              }
              if (sendMailToCustomer && isCompany(company) && !isCreditNote && company.mails?.invoice) {
                mailRes = await callFunction("sendInvoice", [
                  invoice._id,
                  company.mails.invoice,
                  process.env.REACT_APP_MEDIAHUB_FILE_BASE,
                ]);
                if (mailRes) {
                  toast.success("Invoice is sent to the customers invoice mail address");
                  const today = new Date();
                  invoiceUpdateRes = await transaction([
                    {
                      collection: INVOICE,
                      filter: { _id: invoice._id },
                      push: { sentToCustomer: today },
                    },
                  ]);
                  if (!invoiceUpdateRes) toast.error("Failed adding sending date to invoice");
                } else {
                  toast.error("Sending invoice to customer failed");
                }
              }
            } else {
              toast.error(`Error creating ${isCreditNote ? "credit note" : "invoice"} PDF`);
              // maybe we should delete the inserted invoice from the DB if PDF creation failed, and also reduce invoice numbers by 1
            }
          } else {
            toast.error(`Error creating ${isCreditNote ? "credit note" : "invoice"} PDF`);
          }
        }
      } else {
        const draftTitle = isCreditNote ? "DRAFT-CreditNote" : "DRAFT-Invoice";
        const path = await createPDF(
          createInvoiceHTML(
            true,
            invoice,
            vatPositions,
            companyName,
            companyAddress,
            companyVAT,
            deliveryDate,
            invoiceDate,
            note,
            reminder,
            cancelation,
            reverseCharge,
            nonEU,
            this.context,
            isCreditNote,
            creditNoteInvoiceText
          ),
          draftTitle,
          company._id.toString(),
          { marginLeft: "2cm" }
        );
        if (path) {
          window.open(resolveFilePath(path));
        } else {
          toast.error(`Error creating ${isCreditNote ? "credit note" : "invoice"} PDF`);
        }
      }
    } finally {
      this.setState({ generating: "" });
    }
  };

  getCompanyAddress = (company: Company | Supplier) => {
    return formatAddress(getAddressByType(company.address, AddressType.A_INVOICE) || company.address[0]);
  };

  /**
   * Generates the standard invoice note when called without parameters
   * Replaces payment terms without touching manually added changes to the note
   * Toggles reverse-charge and non-EU notes, with non-EU always replacing reverse-charge note
   * Fallback if user removed payment term: generating standard invoice note
   * @param paymentTarget optional, paymentTarget object as SelectOption
   * @param reverseCharge optional, boolean value if reverse-charge is applied
   * @param nonEU optional, boolean value if nonEU is applied
   * @returns {EditorState} state of the wysiwyg editor
   */
  getNote = (paymentTarget?: SelectOption, reverseCharge?: boolean, nonEU?: boolean): EditorState => {
    if (this.state) {
      if (paymentTarget === undefined) paymentTarget = this.state.paymentTarget;
      if (reverseCharge === undefined) reverseCharge = this.state.reverseCharge;
      if (nonEU === undefined) nonEU = this.state.nonEU;
    }
    const paymentRegex = /(Payable within \d+ days from)|(Payable immediately after)|(Payable upfront)/gm; // matches until "delivery"

    const paymentTerms = (paymentTarget: SelectOption) => {
      // replaces until "delivery"
      return parseFloat(paymentTarget.value) > 0
        ? "Payable within " + paymentTarget.value + " days from"
        : parseFloat(paymentTarget.value) === 0
        ? "Payable immediately after"
        : parseFloat(paymentTarget.value) === -1
        ? "Payable upfront"
        : "";
    };
    const isCreditNote =
      (this.state && this.state.type.value === I_CREDIT_NOTE) || (!this.state && this.props.creditNote);
    let html;
    if (this.props.cancelation || isCreditNote) {
      html = standardTextShort;
    } else {
      if (!paymentTarget && !reverseCharge && !nonEU) {
        html = paymentTerms({ label: "14", value: "14" }) + standardText; // initializing if no parameters are given
      } else {
        html = draftToHtml(convertToRaw(this.state.notes.getCurrentContent()));
        if (paymentTarget) {
          if (html.match(paymentRegex)) {
            html = html.replace(
              paymentRegex,
              paymentTerms(paymentTarget) // replace only payment term without touching remaining text
            );
          } else {
            html = paymentTerms(paymentTarget) + standardText; // replace everything, no payment term found
          }
        }
        if (reverseCharge && !nonEU && !html.match(reverseChargeRegex)) {
          html = reverseChargeText + html;
        } else if (!reverseCharge && html.match(reverseChargeRegex)) {
          html = html.replace(reverseChargeRegex, "");
        }
        if (nonEU && !html.match(nonEURegex)) {
          html = nonEUText + html.replace(reverseChargeRegex, "");
        } else if (!nonEU && html.match(nonEURegex)) {
          html = html.replace(nonEURegex, "");
        }
      }
    }
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  };

  getReminderNote = (invoice: Invoice) => {
    if (!invoice) return;
    const html = reminderText(invoice.invoiceNumber, invoice.invoiceDate);
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  };

  getCancelationNote = (invoice: Invoice) => {
    if (!invoice) return;
    const html = cancelationText((invoice.paymentTarget === -1 ? "A" : "") + invoice.invoiceNumber);
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  };

  getCreditNoteText = () => {
    const html = creditNoteText();
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return EditorState.createWithContent(state);
  };

  /**
   * (Re-)Calculates Total, Subtotal, and creates a VATPositions-Array with a list of different VAT percentages and its subtotals. Updates note if necessary
   * @param updateNote optional, update Note if set
   */
  updateNumbersAndNote = (updateNote?: boolean) => {
    const { positions, nonEU, reverseCharge } = this.state;
    const noVat = nonEU || reverseCharge;
    const vatPositions: Array<VATPosition> = [];
    for (let i = 0; i < positions.length; i++) {
      const p = positions[i];
      if ([I_P_DUNNING, I_P_PAYMENT].includes(p.unit)) continue;
      p.total = Math.round((p.quantity || 0) * (p.price * (1 - (p.discount || 0) / 100) || 0) * 100) / 100;
      if (!p.vatPercentage) {
        p.vatPercentage = 0;
      }
      const vatPosition = vatPositions.find((obj) => obj.percentage === p.vatPercentage);
      if (vatPosition) {
        vatPosition.netValue += p.total;
        vatPosition.vatAmount += (p.total * (!noVat ? p.vatPercentage : 0)) / 100;
      } else {
        vatPositions.push({
          netValue: p.total,
          percentage: p.vatPercentage,
          vatAmount: (p.total * (!noVat ? p.vatPercentage : 0)) / 100,
        });
      }
    }
    /* In case of reverse-charge, the vat percentage will be kept in the object to reset the position to the initial state again if reverse-charge is toggled again.
    For this reason, vatAmount has to be calculated here.*/
    const subtotal = _.sumBy(vatPositions, "netValue");
    const total =
      subtotal +
      _.sumBy(vatPositions, "vatAmount") +
      positions.reduce((sum, p) => sum + (p.unit === I_P_DUNNING ? p.total : 0), 0) +
      positions.reduce((sum, p) => sum + (p.unit === I_P_PAYMENT ? p.total : 0), 0);
    const newState: Partial<CreateInvoiceState> = {
      vatPositions: _.sortBy(vatPositions, (obj) => obj.percentage),
      positions,
      subtotal,
      total,
    };
    if (updateNote) {
      newState.notes = this.getNote();
    }
    this.setState(newState as Pick<CreateInvoiceState, "vatPositions" | "positions" | "subtotal" | "total" | "notes">);
  };

  render() {
    const { reminder, cancelation } = this.props;
    const {
      type,
      companyName,
      companyAddress,
      companyVAT,
      invoiceNumber,
      deliveryDate,
      invoiceDate,
      positions,
      subtotal,
      total,
      vatPositions,
      currency,
      reverseCharge,
      nonEU,
      company,
      order,
      paymentTarget,
      notes,
      generating,
      invoice,
      reminderText,
      cancelationText,
      creditNoteText,
      sendMailToCustomer,
    } = this.state;
    const prefix = invoice?.paymentTarget === -1 ? "A" : "";
    const isCreditNote = type.value === I_CREDIT_NOTE;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="d-flex flex-column flex-lg-row">
              <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                <div className="card bg-white">
                  <div className="card-body p-12">
                    <h3 className="card-title align-items-start flex-column mb-15">
                      <span className="card-label fw-bolder mb-3 fs-3rem">
                        {invoice && reminder
                          ? `Reminder for INV-${prefix}${invoice.invoiceNumber}`
                          : invoice && cancelation
                          ? `Cancelation for INV-${prefix}${invoice.invoiceNumber}`
                          : isCreditNote
                          ? "New Credit Note"
                          : "New Invoice"}
                      </span>
                    </h3>
                    <div className="mb-0">
                      <InvoiceHeader
                        type={type}
                        companyName={companyName}
                        companyAddress={companyAddress}
                        companyVAT={companyVAT}
                        reminderOrCancelation={!!(reminder || cancelation)}
                        invoiceNumber={invoiceNumber}
                        invoiceDate={invoiceDate}
                        deliveryDate={deliveryDate}
                        onInputChange={this.handleInputChange}
                        onDateChange={this.handleDateChange}
                      />
                      <InvoicePositions
                        positions={positions}
                        subtotal={subtotal}
                        total={total}
                        vatPositions={vatPositions}
                        currency={currency}
                        reverseCharge={reverseCharge}
                        nonEU={nonEU}
                        onAddPosition={this.handleAddInvoicePosition}
                        onRemovePosition={this.handleRemoveInvoicePosition}
                        onPositionChange={this.handleInvoicePositionChange}
                      />
                      <InvoiceFooter
                        notes={notes}
                        reminderText={reminderText}
                        cancelationText={cancelationText}
                        creditNoteText={creditNoteText}
                        isCreditNote={isCreditNote}
                        onNotesChange={this.handleNotesChange}
                        onReminderChange={this.handleReminderChange}
                        onCancelationChange={this.handleCancelationChange}
                        onCreditNoteChange={this.handleCreditNoteChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <InvoiceSettings
                context={this.context}
                invoice={invoice}
                generating={generating}
                type={type}
                company={company}
                order={order}
                currency={currency}
                paymentTarget={paymentTarget}
                reverseCharge={reverseCharge}
                nonEU={nonEU}
                companyName={companyName}
                companyAddress={companyAddress}
                companyVAT={companyVAT}
                positions={positions}
                notes={notes}
                subtotal={subtotal}
                sendMailToCustomer={sendMailToCustomer}
                onTypeChange={this.handleTypeChange}
                onCompanyChange={this.handleCompanyChange}
                onOrderChange={this.handleOrderChange}
                onCurrencyChange={this.handleCurrencyChange}
                onPaymentTargetChange={this.handlePaymentTargetChange}
                onReverseChargeChange={this.handleReverseChargeChange}
                onNonEUChange={this.handleNonEUChange}
                onCreateDocument={this.handleCreateInvoice}
                onChangeSendMail={this.handleToggleSendMail}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CreateInvoice);
