import React, { PureComponent } from "react";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import Search from "../../common/Search";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { F_SORTOPTIONS } from "../../../utils/forwarderUtils";

interface ForwarderListingFilterProps {
  search: string;
  sortBy: SelectOption;
  sortOrder: SelectOption;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSortByChange: (sortBy: SelectOption) => void;
  onSortOrderChange: (sortOrder: SelectOption) => void;
}

class ForwarderListingFilter extends PureComponent<ForwarderListingFilterProps> {
  render() {
    const { search, sortBy, sortOrder, onSearch, onSortByChange, onSortOrderChange } = this.props;
    const sortOptions = F_SORTOPTIONS.slice();
    if (search.trim()) sortOptions.unshift({ value: "", label: "Best Match" });
    return (
      <div className="card mb-10 bg-white border-none">
        <div className="row g-8 mb-4">
          <div className="col-12 col-lg-6">
            <Search value={search} onSearch={onSearch} placeholder={"Search for forwarders..."} />
          </div>
        </div>
        <div className="row g-8">
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Sort By</label>
            <CustomSelect options={sortOptions} value={sortBy} onChange={(e: SelectOption) => onSortByChange(e)} />
          </div>
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Sort Order</label>
            <CustomSelect
              options={SORTORDEROPTIONS}
              value={sortOrder}
              onChange={(e: SelectOption) => onSortOrderChange(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ForwarderListingFilter;
