import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import userService from "../../services/userService";
import { getAndRemoveQuery } from "../../utils/queryUtils";
import authService from "../../services/authService";
import { Input } from "../common/Input";

interface ChangePasswordProps extends RouteComponentProps {}

interface ChangePasswordState {
  data: {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
  };
  errors: {
    password?: string;
    oldPassword?: string;
    newPassword?: string;
    newPasswordRepeat?: string;
  };
  changeSuccess: boolean;
  saving: boolean;
}

class ChangePassword extends PureComponent<ChangePasswordProps, ChangePasswordState> {
  constructor(props: ChangePasswordProps) {
    super(props);
    this.state = {
      data: { oldPassword: "", newPassword: "", newPasswordRepeat: "" },
      errors: {},
      changeSuccess: false,
      saving: false,
    };
  }

  componentDidMount() {
    const { location, history } = this.props;
    const success = !!getAndRemoveQuery(location, history, "success");
    this.setState({ changeSuccess: success });
  }

  handleSubmit = async (e: React.FormEvent) => {
    // Disable default behavior
    e.preventDefault();
    const errors = { ...this.state.errors };
    const { data } = this.state;
    let error = false;
    const email = userService.getUserMail();
    if (!email) {
      console.error("No email found for user", userService.getUserData());
      return;
    }
    this.setState({ saving: true });
    try {
      // Check credentials
      await authService.checkCredentials(email, data.oldPassword);
      if (data.newPassword.length < 10) {
        errors.newPassword = "Password is too short. At least 10 characters required";
        error = true;
      } else if (data.newPassword !== data.newPasswordRepeat) {
        errors.newPasswordRepeat = "Passwords do not match";
        error = true;
      }

      if (!error) {
        await userService.getUser()?.callFunction("setPasswordChangeDate");
        await authService.changePassword(email, data.newPassword);
        this.setState({
          data: { oldPassword: "", newPassword: "", newPasswordRepeat: "" },
          errors: {},
          changeSuccess: true,
        });
      }
    } catch (ex) {
      errors.oldPassword = "The entered password is invalid";
      error = true;
    } finally {
      this.setState({ saving: false });
    }
    if (error) {
      this.setState({
        data: { oldPassword: "", newPassword: "", newPasswordRepeat: "" },
        errors: errors,
        changeSuccess: false,
        saving: false,
      });
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...this.state.data };
    _.set(data, e.target.name, e.target.value);
    this.setState({ data });
  };

  render() {
    const { data, errors, changeSuccess, saving } = this.state;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white">
              <form onSubmit={saving ? undefined : this.handleSubmit}>
                <div className="card-body p-9">
                  <h3 className="card-title align-items-start flex-column mb-15">
                    <span className="card-label fw-bolder mb-3 fs-3rem">Change Password</span>
                  </h3>
                  <div className="row mb-1 mb-sm-6">
                    <label className="col-12 col-sm-4 col-form-label required fw-bold fs-6">Old Password</label>
                    <div className="col-12 col-sm-8 fv-row fv-plugins-icon-container">
                      <Input
                        className="form-control custom-form-control"
                        type="password"
                        name="oldPassword"
                        value={data.oldPassword}
                        onChange={this.handleChange}
                      />
                      {changeSuccess ? (
                        <div className="fv-plugins-message-container valid-feedback">Password changed successfully</div>
                      ) : (
                        <div className="fv-plugins-message-container invalid-feedback">
                          {errors.oldPassword ? errors.oldPassword : <span>&nbsp;</span>}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-1 mb-sm-6">
                    <label className="col-12 col-sm-4 col-form-label required fw-bold fs-6">New Password</label>
                    <div className="col-12 col-sm-8 fv-row fv-plugins-icon-container">
                      <input
                        className="form-control custom-form-control"
                        type="password"
                        name="newPassword"
                        value={data.newPassword}
                        onChange={this.handleChange}
                      />
                      <div className="fv-plugins-message-container invalid-feedback ">
                        {errors.newPassword ? errors.newPassword : <span>&nbsp;</span>}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-1 mb-sm-6">
                    <label className="col-12 col-sm-4 col-form-label required fw-bold fs-6">Repeat New Password</label>
                    <div className="col-12 col-sm-8 fv-row fv-plugins-icon-container">
                      <input
                        className="form-control custom-form-control"
                        type="password"
                        name="newPasswordRepeat"
                        value={data.newPasswordRepeat}
                        onChange={this.handleChange}
                      />
                      <div className="fv-plugins-message-container invalid-feedback">
                        {errors.newPasswordRepeat ? errors.newPasswordRepeat : <span>&nbsp;</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0 text-right">
                  <button
                    className={"btn btn-sm btn-outline btn-outline-light" + (saving ? " disabled" : "")}
                    type={"submit"}
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
