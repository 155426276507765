import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { Input } from "../../../../common/Input";
import { formatCurrency } from "../../../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../../../utils/currencyUtils";
import { Incoterm } from "../../../../../utils/commodityUtils";

interface PriceGraduationProps {
  show?: boolean;
  unit: string;
  incoterm?: string;
  currency?: string;
  onClose: () => void;
  onSave: (results: Array<{ amount: number; price: number }>) => void;
}

interface PriceGraduationState {
  minAmount: number;
  maxAmount: number;
  lowAmountPrice: number;
  highAmountPrice: number;
  prices: number;
  interpolation: number;
  results: Array<{ amount: number; price: number }>;
}

class PriceGraduationModal extends PureComponent<PriceGraduationProps, PriceGraduationState> {
  constructor(props: PriceGraduationProps) {
    super(props);
    this.state = {
      minAmount: 0,
      maxAmount: 0,
      lowAmountPrice: 0,
      highAmountPrice: 0,
      prices: 10,
      interpolation: 1,
      results: [],
    };
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value,
      },
      () => {
        this.interpolate();
      }
    );
  };

  interpolate = () => {
    const { prices, interpolation } = this.state;
    const results = [];
    const highAmountPrice = parseFloat(this.state.highAmountPrice.toString()); // type confusion, should be a number, but isnt..
    const lowAmountPrice = parseFloat(this.state.lowAmountPrice.toString());
    const maxAmount = parseFloat(this.state.maxAmount.toString());
    const minAmount = parseFloat(this.state.minAmount.toString());
    const diff = lowAmountPrice - highAmountPrice;

    let nextPrice = lowAmountPrice;
    let nextAmount = minAmount;
    for (let i = 0; i < prices; i++) {
      results.push({ amount: Math.round(nextAmount * 100) / 100, price: Math.round(nextPrice * 100) / 100 });
      nextAmount = minAmount + (maxAmount / prices) * (i + 1);
      if (interpolation == 1) {
        nextPrice = lowAmountPrice - (diff / (prices - 1)) * (i + 1);
      } else if (interpolation == 2) {
        nextPrice = highAmountPrice + Math.pow(0.9, i + 1) * diff;
      } else if (interpolation == 3) {
        nextPrice = highAmountPrice + Math.pow(0.8, i + 1) * diff;
      } else if (interpolation == 4) {
        nextPrice = highAmountPrice + Math.pow(0.7, i + 1) * diff;
      } else if (interpolation == 5) {
        nextPrice = highAmountPrice + Math.pow(0.5, i + 1) * diff;
      } else if (interpolation == 6) {
        nextPrice = highAmountPrice + Math.pow(0.3, i + 1) * diff;
      }
    }
    this.setState({ results });
  };

  render() {
    const { unit, onClose, onSave, show, incoterm, currency } = this.props;
    const { interpolation, results, minAmount, maxAmount, lowAmountPrice, highAmountPrice, prices } = this.state;
    return (
      <Modal contentClassName="bg-dark" show={show !== undefined ? show : true} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Price Graduation Tool</h1>
          </Modal.Title>
          <CloseButton variant={"white"} onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="form-group col-12 col-sm-6">
              <label className="text-white">Lowest Amount</label>
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control custom-form-control pt-0 pb-0"
                  name="minAmount"
                  value={minAmount}
                  onChange={this.handleChange}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    {unit}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group col-12 col-sm-6">
              <label className="text-white">Highest Amount</label>
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control custom-form-control pt-0 pb-0"
                  name="maxAmount"
                  value={maxAmount}
                  onChange={this.handleChange}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    {unit}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12 col-sm-6">
              <label className="text-white">Price for Lowest Amount</label>
              <div className="input-group">
                <div className="input-group-prepend rounded-end">
                  <span className="input-group-text form-control custom-form-control py-0">
                    {incoterm ?? Incoterm.FOB}
                  </span>
                </div>
                <Input
                  type="number"
                  name="lowAmountPrice"
                  className="form-control custom-form-control py-0"
                  value={lowAmountPrice}
                  onChange={this.handleChange}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    {currency ?? BASE_CURRENCY}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group col-12 col-sm-6">
              <label className="text-white">Price for Highest Amount</label>
              <div className="input-group">
                <div className="input-group-prepend ">
                  <span className="input-group-text form-control custom-form-control py-0">
                    {incoterm ?? Incoterm.FOB}
                  </span>
                </div>
                <Input
                  type="number"
                  name="highAmountPrice"
                  className="form-control custom-form-control py-0"
                  value={highAmountPrice}
                  onChange={this.handleChange}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    {currency ?? BASE_CURRENCY}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12 col-sm-6">
              <label className="text-white">Prices</label>
              <div className="input-group">
                <Input
                  type="number"
                  name="prices"
                  className="form-control custom-form-control py-0"
                  value={prices}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="form-group col-12 col-sm-6">
              <label className="text-white">Interpolation Model</label>
              <div className="input-group">
                <select
                  className="form-control custom-form-control  pt-0 pb-0"
                  value={interpolation}
                  name="interpolation"
                  onChange={this.handleChange}
                >
                  <option key={1} value={1}>
                    Linear
                  </option>
                  <option key={2} value={2}>
                    Exponential 1
                  </option>
                  <option key={3} value={3}>
                    Exponential 2
                  </option>
                  <option key={4} value={4}>
                    Exponential 3
                  </option>
                  <option key={5} value={5}>
                    Exponential 4
                  </option>
                  <option key={6} value={6}>
                    Exponential 5
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-3"></div>
          {results.length > 0 && (
            <>
              <div className="row mt-5">
                {this.state.results[results.length - 1].price > lowAmountPrice * 1.01 && (
                  <div className="alert bg-light alert-elevate mb-5">
                    <div className="alert-text">
                      The selected interpolation model requires more price levels to achieve the desired price for the
                      highest order quantity. The currently calculated price is {results[results.length - 1].price}
                      {BASE_CURRENCY}. Please choose another interpolation model, or have a higher number of prices
                      calculated to reach the desired price.
                    </div>
                  </div>
                )}
                <div
                  className="col-12"
                  style={{ overflowX: "scroll", overflowWrap: "normal", width: "100%", height: "110px" }}
                >
                  {results.map((result, idx) => (
                    <div key={idx} className="progress progress-bar-vertical">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ height: `${(result.price / lowAmountPrice) * 100}%` }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  <div className="dataTables_wrapper">
                    <div className="table-responsive" style={{ overflowY: "auto", maxHeight: "240px" }}>
                      <table className="table text-white fw-bolder gy-1">
                        <thead className="border-bottom-dark-gray">
                          <tr>
                            <th>Amount</th>
                            <th>Price</th>
                            <th className="text-right">Percentage</th>
                          </tr>
                        </thead>
                        <tbody className={"table-stripes"}>
                          {results.map((item, idx) => (
                            <tr key={idx}>
                              <td className="text-muted">
                                {item.amount} {unit}
                              </td>
                              <td className="text-muted">{formatCurrency(item.price, BASE_CURRENCY)}</td>
                              <td className="text-success text-right">
                                {(lowAmountPrice ? (item.price / lowAmountPrice) * 100 : 0).toFixed(2)}%
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-sm btn-text-light"} onClick={onClose}>
            Close
          </button>
          <button
            className={"btn btn-sm btn-outline btn-outline-light"}
            onClick={() => {
              onSave(results);
              onClose();
            }}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PriceGraduationModal;
