import React, { PureComponent } from "react";
import { Input } from "../../../common/Input";
import { toAbsoluteUrl } from "../../../../utils/baseUtils";
import { PositionType } from "../../../../utils/controllingDashboardUtils";
import {
  resolveFilePath,
  uploadAndGetArticleFileObject,
  uploadAndGetOrderFileObject,
} from "../../../../utils/fileUtils";
import { reduceUploadedFile } from "../../../../utils/dataTransformationUtils";
import { SO_ADJUSTED_CALCULATION } from "../../../../utils/supplierOrderUtils";
import { OrderFile } from "../../../../model/commonTypes";

interface PositionProps {
  data: PositionType;
  disabled: boolean;
  onRemove: (data: PositionType) => void;
  onFinalize: (updatedPosition: PositionType) => void;
}

interface PositionState {
  description: string;
  value: number;
  file?: OrderFile;
  isEditing: boolean;
}

class Position extends PureComponent<PositionProps, PositionState> {
  fileInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: PositionProps) {
    super(props);
    this.state = {
      description: props.data.description,
      value: props.data.value,
      file: props.data.document,
      isEditing: !props.data.description || !props.data.value,
    };
    this.fileInputRef = React.createRef();
  }

  handleFileClick = () => {
    const { file } = this.state;
    if (file) {
      window.open(resolveFilePath(file.path), "_blank");
    } else {
      if (this.fileInputRef.current) {
        this.fileInputRef.current.click();
      }
    }
  };

  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const uploadedFile = uploadAndGetOrderFileObject(e.target.files[0], SO_ADJUSTED_CALCULATION);
    this.setState({ file: uploadedFile });
  };

  handleRemovePosition = () => {
    this.props.onRemove(this.props.data);
  };

  handleSave = () => {
    const { data, onFinalize } = this.props;
    const { description, value, file, isEditing } = this.state;
    this.setState({ isEditing: false });
    onFinalize({ ...data, description, value, document: file, isEditing });
  };

  render() {
    const { disabled } = this.props;
    const { description, value, file, isEditing } = this.state;

    return (
      <div className="position-relative">
        <div className="row my-2">
          <div className="col-12 col-sm-6">
            {isEditing ? (
              <Input
                type="text"
                className="form-control custom-form-control"
                placeholder="Description"
                disabled={disabled}
                value={description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            ) : (
              <span className="text-white font-weight-bold">{description}</span>
            )}
          </div>
          <div className="col-12 col-sm-6 d-flex align-items-center">
            {isEditing ? (
              <div className="input-group">
                <Input
                  type="number"
                  className="form-control custom-form-control"
                  disabled={disabled}
                  value={value}
                  onChange={(e) => this.setState({ value: parseFloat(e.target.value) })}
                />
                <div className="input-group-append rounded-end bg-custom-light-gray" style={{ height: "100%" }}>
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    €
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-white">{value.toFixed(2)}€</span>
            )}
            <div className="d-flex ml-auto position-relative">
              <img
                alt="PDF"
                src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                style={{ height: 16, width: 16, cursor: "pointer" }}
                className={"mx-2 cursor-pointer " + (!file && "opacity-25")}
                onClick={disabled ? undefined : this.handleFileClick}
              />
              {isEditing ? (
                <i
                  className={
                    "mx-2 my-auto pr-0 cursor-pointer flaticon2-check-mark " +
                    (!description || !value || disabled ? "opacity-25 " : "color-success ")
                  }
                  style={{ height: 16, width: 16 }}
                  onClick={description && value && !disabled ? this.handleSave : undefined}
                />
              ) : (
                <i
                  className={
                    "ml-2 my-auto pr-0 cursor-pointer flaticon2-cross color-danger " + (disabled && "disabled")
                  }
                  style={{ height: 16, width: 16 }}
                  onClick={disabled ? undefined : this.handleRemovePosition}
                />
              )}
              <input type="file" ref={this.fileInputRef} style={{ display: "none" }} onChange={this.handleFileChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Position;
