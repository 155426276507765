import React, { PureComponent } from "react";
import CommodityPageSummary from "../../commodities/supplier/CommodityPageSummary";
import OverviewTabPanel from "./OverviewContent/OverviewTabPanel";
import QuickActions from "./OverviewContent/QuickActions";
import { DataContextSupplierType } from "../../../context/dataContext";
import { SupplierArticleExtended } from "../../../utils/productArticleUtils";

interface OverviewProps {
  context: DataContextSupplierType;
}

interface OverviewState {
  selectedArticle?: SupplierArticleExtended;
}

class Overview extends PureComponent<OverviewProps, OverviewState> {
  constructor(props: OverviewProps) {
    super(props);
    this.state = {};
  }

  handleSelectArticle = (selectedArticle: SupplierArticleExtended) => this.setState({ selectedArticle });

  render() {
    const { context } = this.props;
    const { selectedArticle } = this.state;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px">
                <CommodityPageSummary article={selectedArticle} context={context} withLink={true} />
                <QuickActions />
              </div>
              <OverviewTabPanel context={context} onSelectArticle={this.handleSelectArticle} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Overview;
