import React, { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BatchExtended, BatchState } from "../../model/batch.types";
import {
  getBatchAmount,
  getBatchTimelineEntry,
  getStockLocations,
  getStockPackageType,
  ShipmentBatchExtended,
  T_BATCHDISABLE,
  T_BATCHENABLE,
  updateBatch,
} from "../../utils/batchUtils";
import { formatCurrency, formatDate, toAbsoluteUrl } from "../../utils/baseUtils";
import { getDaysBetween, getDaysUntil } from "../../utils/dateUtils";
import { resolveFilePath } from "../../utils/fileUtils";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import BatchTimelineListing from "./BatchTimelineListing";
import { DataContextInternal } from "../../context/dataContext";
import DisableBatchDialog from "./modals/DisableBatchDialog";
import CreateBatchModal from "../commodities/internal/modals/CreateBatchModal";
import Tooltip from "../common/Tooltip";
import { getOrderNumber } from "../../utils/orderUtils";
import { formatArticleUnit, isAnyFinishedProduct } from "../../utils/productArticleUtils";

import { extendSupplierOrder } from "../../utils/dataTransformationUtils";

interface CommodityStockEntryProps {
  commodityColumn?: boolean;
  mergedDateColumn?: boolean;
  single?: boolean;
  checkboxDisabled?: boolean;
  checkboxSelected?: boolean;
  batch: BatchExtended | ShipmentBatchExtended;
  onEditBatch: () => void;
  onBatchState: (type: "release" | "block") => void;
  onSelectBatch?: () => void;
  onDeselectBatch?: () => void;
}

export const BatchEntry: React.FunctionComponent<CommodityStockEntryProps> = ({
  commodityColumn,
  mergedDateColumn,
  single,
  batch,
  checkboxDisabled,
  checkboxSelected,
  onEditBatch,
  onBatchState,
  onSelectBatch,
  onDeselectBatch,
}) => {
  const context = useContext(DataContextInternal);

  const [show, setShow] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [note, setNote] = useState("");

  const handleShowDisable = () => {
    setShowDisableModal(true);
    setNote("");
  };
  const handleHideDisable = () => setShowDisableModal(false);
  const handleDisableBatch = async (disable: boolean) => {
    if (single) return;
    if (!batch) return;
    const timeline = batch.timeline.slice();
    timeline.push(getBatchTimelineEntry(disable ? T_BATCHDISABLE : T_BATCHENABLE, note));
    const result = await updateBatch({ disabled: disable, timeline }, batch._id);
    if (result && result.modifiedCount > 0) {
      toast.success("Batch " + (disable ? "disabled" : "enabled") + " successfully");
    } else {
      toast.error("Error " + (disable ? "disabling" : "enabling") + " batch");
    }
  };
  const handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value);

  const timeline = single
    ? batch.timeline.filter(
        (t) =>
          (t.pre &&
            t.pre.packages &&
            t.pre.packages.some((p) => p._id.toString() === batch.packages[0]._id.toString())) ||
          (t.post &&
            t.post.packages &&
            t.post.packages.some((p) => p._id.toString() === batch.packages[0]._id.toString()))
      )
    : batch.timeline;

  const singleNoEdit = single && !!batch.packages[0].usedOrders;
  const daysLeft = getDaysUntil(batch.expiry, true);
  const daysAgo = getDaysBetween(new Date(), batch.stockedDate, true);
  const stockLocations = getStockLocations(batch);
  const isPlaceholder = "shipment" in batch && batch.shipment;
  const isShipmentBatch = (batch: BatchExtended | ShipmentBatchExtended): batch is ShipmentBatchExtended =>
    !!isPlaceholder;
  const batchAmount = getBatchAmount(batch);
  const disabled = batch.disabled || batchAmount === 0;

  return (
    <>
      <SimpleConfirmationModal.SimpleConfirmationModal
        size={"md"}
        show={showDisableModal}
        onConfirm={() => handleDisableBatch(!batch.disabled)}
        onClose={handleHideDisable}
        confirmButtonText={batch.disabled ? "Enable Batch" : "Disable Batch"}
        cancelButtonText={"Cancel"}
        modalDescription={
          <DisableBatchDialog batch={batch as BatchExtended} note={note} onChangeNote={handleChangeNote} />
        }
        modalTitle={`${batch.disabled ? "Enable" : "Disable"} Batch RB${batch.identifier}`}
      />
      <tr onClick={(e) => e.stopPropagation()}>
        {single && (
          <td className="align-middle" onClick={(e) => e.stopPropagation()}>
            <div className="form-check form-check-sm form-check-custom form-check-solid">
              <input
                className={"form-check-input " + ((checkboxDisabled || singleNoEdit) && "disabled")}
                disabled={checkboxDisabled || singleNoEdit}
                type="checkbox"
                checked={checkboxSelected}
                onChange={
                  checkboxDisabled || singleNoEdit
                    ? undefined
                    : () => {
                        if (onDeselectBatch && onSelectBatch) checkboxSelected ? onDeselectBatch() : onSelectBatch();
                      }
                }
              />
            </div>
          </td>
        )}
        {commodityColumn ? (
          <td className="align-middle" style={isPlaceholder ? { opacity: 0.5 } : {}}>
            <Link
              className={"text-white fs-6 custom-link " + (disabled ? "text-muted" : "")}
              to={`/${
                isAnyFinishedProduct(batch.commodity) ? "finishedProduct" : "commodity"
              }/${batch.commodity._id.toString()}`}
              onClick={(e) => e.stopPropagation()}
            >
              {batch.commodity.title.en}
              {batch.commodity && batch.commodity.organic && (
                <i title={"organic"} className="fas fa-leaf text-success ml-1" />
              )}
            </Link>
            <div>
              <Link className="text-muted fs-8 custom-link" to={`/supplier/${batch.supplier._id.toString()}`}>
                {batch.supplier.name}
              </Link>
              {batch.supplierOrder && (
                <>
                  <span className="mx-2">-</span>
                  <Link
                    className="text-muted fs-8 custom-link"
                    to={`/supplierOrder/${batch.supplierOrder._id.toString()}`}
                  >
                    {getOrderNumber(batch.supplierOrder)}
                  </Link>
                </>
              )}
            </div>
          </td>
        ) : (
          <td className="align-middle" style={isPlaceholder ? { opacity: 0.5 } : {}}>
            <Link className="text-white fs-6 custom-link" to={`/supplier/${batch.supplier._id.toString()}`}>
              {batch.supplier.name}
            </Link>
            {batch.supplierOrder && (
              <div>
                <Link
                  className="text-muted fs-8 custom-link"
                  to={`/supplierOrder/${batch.supplierOrder._id.toString()}`}
                >
                  {getOrderNumber(batch.supplierOrder)}
                </Link>
              </div>
            )}
          </td>
        )}
        <td className="align-middle" style={isPlaceholder ? { opacity: 0.5 } : {}}>
          <Tooltip
            tooltipText={
              <div className="text-white">
                {stockLocations.map(
                  ([location, types]: [location: string, types: Array<[t: string, amount: number]>], idx) => (
                    <div key={location} className="row" style={{ minWidth: "300px" }}>
                      <div className="col-12 col-sm-6 pl-2 pr-0 my-auto text-left fw-bolder fs-7">{location}</div>
                      <div className="col-12 col-sm-6 pr-2 pl-0 text-left fs-7">
                        {types.map((type) => (
                          <div key={type[0]} style={{ whiteSpace: "nowrap" }}>
                            {type[1]} x {type[0]}
                          </div>
                        ))}
                      </div>
                      {idx < stockLocations.length - 1 && <div className="border-bottom-dark-gray my-2" />}
                    </div>
                  )
                )}
              </div>
            }
          >
            <div>
              <div className="fs-6">
                <div className={disabled ? "text-muted" : ""}>{`${batchAmount} ${formatArticleUnit(batch.unit)}`}</div>
                {batchAmount > 0 && (
                  <div className="text-muted">
                    {formatCurrency(batch.price, batch.priceCurrency)}/{batch.unit}
                  </div>
                )}
              </div>
              {
                <div className="fs-8 text-muted">
                  {isPlaceholder ? "TBD" : getStockPackageType(batch as BatchExtended)}
                </div>
              }
            </div>
          </Tooltip>
        </td>
        <td className="align-middle" style={isPlaceholder ? { opacity: 0.5 } : {}}>
          {isPlaceholder ? (
            <div className="fs-6">
              <span className="w-100 badge badge-pill badge-warning text-uppercase">Incoming</span>
            </div>
          ) : (
            <div className="fs-6">
              <span
                className={
                  "w-100 badge badge-pill text-uppercase " +
                  (disabled
                    ? "badge-light no-hover"
                    : batch.state === BatchState.BLOCKED
                    ? "badge-danger"
                    : batch.state === BatchState.INCOMING
                    ? "badge-warning"
                    : "badge-success")
                }
              >
                {batch.disabled ? "disabled" : batchAmount === 0 ? "empty" : batch.state}
              </span>
            </div>
          )}
          {batch.commissionData && (
            <div className="fs-6 mt-1">
              <span className="w-100 badge badge-pill badge-light-dark text-uppercase">Commission</span>
            </div>
          )}
        </td>
        <td className="align-middle" style={isPlaceholder ? { opacity: 0.5 } : {}}>
          {isPlaceholder ? (
            <div>
              <div className="fs-6">
                <div>TBD</div>
              </div>
              {batch.supplierOrder && (
                <div className="fs-8 text-muted">
                  Order:{" "}
                  <Link className="custom-link text-muted" to={`/supplierOrder/${batch.supplierOrder._id.toString()}`}>
                    RB-{batch.supplierOrder.orderNo}
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <div className="fs-6">
              <span className={disabled ? "text-muted" : ""}>RB{batch.identifier}</span>
              {single && `-${batch.packages[0].number}`}
              {batch.notes.trim() && (
                <Tooltip tooltipText={<span className="text-white">{batch.notes}</span>}>
                  <span className="ml-1">
                    <i className="fa fa-info-circle text-white" />
                  </span>
                </Tooltip>
              )}
              <div className="text-muted fs-8">Ext.: {batch.lot}</div>
            </div>
          )}
        </td>
        {mergedDateColumn ? (
          <td className="align-middle" style={isPlaceholder ? { opacity: 0.5 } : {}}>
            <div className={disabled ? "text-muted" : "text-white"}>{formatDate(batch.stockedDate)}</div>
            <div className="text-muted fs-8">
              {daysAgo} {daysAgo === 1 ? "day" : "days"} ago
            </div>
            <span className={"fs-8 " + (daysLeft > 365 ? "text-success" : "text-danger")}>{daysLeft}d left</span>
          </td>
        ) : (
          <>
            <td className="align-middle" style={isPlaceholder ? { opacity: 0.5 } : {}}>
              <div className={disabled ? "text-muted" : "text-white"}>{formatDate(batch.stockedDate)}</div>
              {isPlaceholder || daysAgo === 0 ? (
                <div className="text-muted fs-8">Now</div>
              ) : (
                <div className="text-muted fs-8">
                  {daysAgo} {daysAgo === 1 ? "day" : "days"} ago
                </div>
              )}
            </td>
            <td className="align-middle " style={isPlaceholder ? { opacity: 0.5 } : {}}>
              <div className={disabled ? "text-muted" : "text-white"}>{formatDate(batch.expiry)}</div>
              <span className={"fs-8 " + (daysLeft > 365 ? "text-success" : "text-danger")}>{daysLeft}d left</span>
            </td>
          </>
        )}
        <td className="align-middle" style={isPlaceholder ? { opacity: 0.5 } : {}}>
          {isPlaceholder ? (
            <>
              <img
                alt="Supplier CoA"
                src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                className="image-gray"
                style={{ height: 24 }}
              />
              <img
                alt="Rawbids CoA"
                src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                className="image-gray ml-2"
                style={{ height: 24 }}
              />
            </>
          ) : (
            <>
              <Tooltip tooltipText={"Supplier CoA"}>
                <a
                  href={resolveFilePath((batch as BatchExtended).supplierCoA?.path)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    alt="Supplier CoA"
                    src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                    style={{ height: 24 }}
                  />
                </a>
              </Tooltip>
              {!isShipmentBatch(batch) && batch.ownCoA ? (
                <Tooltip tooltipText={"Rawbids CoA"}>
                  <a
                    className="ml-2"
                    href={resolveFilePath(batch.ownCoA.path)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img
                      alt="Rawbids CoA"
                      src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                      style={{ height: 24 }}
                    />
                  </a>
                </Tooltip>
              ) : (
                <Tooltip tooltipText={"Rawbids CoA missing"}>
                  <img
                    className="ml-2 image-gray"
                    alt="Rawbids CoA"
                    src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                    style={{ height: 24 }}
                    onClick={() => onBatchState("release")}
                  />
                </Tooltip>
              )}
            </>
          )}
        </td>
        <td className="align-middle text-end" onClick={(e) => e.stopPropagation()}>
          {isShipmentBatch(batch) ? (
            <CreateBatchModal
              context={context}
              buttonType="batchEntry"
              article={batch.commodity}
              shipment={batch.shipment}
              supplierOrder={batch.supplierOrder ? extendSupplierOrder(batch.supplierOrder, context) : undefined}
            />
          ) : (
            <Dropdown>
              <Dropdown.Toggle
                className={"btn btn-sm btn-light btn-active-light " + (checkboxDisabled && "disabled")}
                disabled={checkboxDisabled}
              >
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-dropdown">
                <Dropdown.Item
                  className={"text-white " + (singleNoEdit && "text-muted")}
                  disabled={singleNoEdit}
                  onClick={singleNoEdit ? undefined : onEditBatch}
                >
                  Edit
                </Dropdown.Item>
                {!single && batch.state !== BatchState.RELEASED && !batch.disabled && (
                  <Dropdown.Item className="text-success" onClick={() => onBatchState("release")}>
                    Release
                  </Dropdown.Item>
                )}
                {!single && batch.state !== BatchState.BLOCKED && !batch.disabled && (
                  <Dropdown.Item className="text-danger" onClick={() => onBatchState("block")}>
                    Block
                  </Dropdown.Item>
                )}
                <Dropdown.Item className="text-white" onClick={() => setShow(!show)}>
                  {show ? "Hide History" : "Show History"}
                </Dropdown.Item>
                {!single && (
                  <Dropdown.Item
                    className={batch.disabled ? "text-success" : "text-danger"}
                    onClick={handleShowDisable}
                  >
                    {batch.disabled ? "Enable" : "Disable"}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </td>
      </tr>
      {show && (
        <BatchTimelineListing batch={batch as BatchExtended} single={single} timeline={timeline} context={context} />
      )}
    </>
  );
};
