import { BSON } from "realm-web";
import { CO_TIMELINETYPE, CustomerOrder, CustomerOrderService } from "./customerOrder.types";
import { SO_TIMELINETYPE } from "./supplierOrder.types";
import { SAMO_TIMELINETYPE } from "./sampleOrder.types";
import { Commodity, Duty, TransportPrices, UploadedFile } from "./commodity.types";
import { CustomerCommodity } from "./customer/customerCommodity.types";
import { PriceDevelopment } from "./statistics/commodityStatistics.types";
import { COR_TIMELINETYPE } from "./commodityOfferRequest.types";
import { SelectOption } from "../components/common/CustomSelect";
import { FinishedProduct, TransportConditions } from "./finishedProduct.types";
import { CustomerFinishedProduct } from "./customer/customerFinishedProduct.types";
import { ArticleGraduationType } from "./configuration/graduationConfiguration.types";
import { CustomerContractTimelineType } from "./customerContract.types";

export interface OrderFile {
  _id: BSON.ObjectId;
  date: Date;
  path: string; // Path to file
  type: string; // Types to be defined
  uploadedBy?: string;
  reference?: string; // especially for forwarding order files
  additionalValues?: AdditionalFileValues;
}

export interface AdditionalFileValues {
  totalPrice: number;
}

export interface Range {
  min: number | string;
  max: number | string;
}

export enum AddressType {
  A_PRIMARY = "addrPrimary",
  A_SHIPPING = "addrShipping",
  A_INVOICE = "addrInvoice",
  A_OTHER = "addrOther",
}

export const ADDRESS_TYPE_OPTIONS: Array<SelectOption> = [
  { value: AddressType.A_PRIMARY, label: "Primary" },
  { value: AddressType.A_SHIPPING, label: "Shipping" },
  { value: AddressType.A_INVOICE, label: "Invoicing" },
  { value: AddressType.A_OTHER, label: "Other" },
];

export interface Address {
  _id: BSON.ObjectId;
  /** Additional name like another company or a recipient **/
  name: string;
  /** Street name **/
  street: string;
  /** House number **/
  houseNo: string;
  /** Postal / ZIP code of the city **/
  postalCode: string;
  /** City name **/
  city: string;
  /** Type of address, currently primary, invoice and other are possible **/
  type: AddressType;
  /** Country code where the address is located **/
  country: string;
  /** Properties containing special delivery instructions for the address, e.g. required forklift **/
  properties?: Array<string>;
  /** Contact person for that address as id (string) **/
  contactPerson?: string;
  /** Opening hours of the address **/
  openingHours?: string;
}

export interface GroupedCustomerOrderService extends CustomerOrderService {
  customerOrders: Array<CustomerOrder>;
}

export interface Empty {}

export interface OrderTimeline {
  _id: BSON.ObjectId;
  date: Date; // When did this happen?
  type: SO_TIMELINETYPE | CO_TIMELINETYPE | SAMO_TIMELINETYPE | COR_TIMELINETYPE | CustomerContractTimelineType; // What happened?
  person: string;
  payload?: OrderTimelinePayload | null; // Additional data for certain types, tbd
}

export interface OrderTimelinePayload {
  type?: string;
  checked?: boolean;
  reference?: string;
  name?: string;
  reason?: string;
  calculation?: string;
}

export interface Note {
  _id: BSON.ObjectId;
  note: string;
  person: string;
  date: Date;
}

export interface PriceTickerData {
  article: Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct;
  price: number;
  priceCurrency: string;
  priceDevelopment?: PriceDevelopment;
  priceGraph: string;
}

export interface TreemapData {
  commodity: CustomerCommodity;
  priceDevelopment: PriceDevelopment;
}

export interface TrendData {
  category: string;
  change: number;
}

export interface SignedFile {
  originator: string; // user who created/updated the document
  approver: null | string; // null = approval required, objectId = user who approved it
  approvalDate: Date; // time of approval/upload
  signedBy: null | string;
  signatureDate: Date;
}

export interface VersionedFile {
  version: number;
}

export interface PublicFile {
  isPublic: boolean;
}

export interface CustomerPriceInfo {
  unitPrice: number;
  totalPrice: number;
  isFallback?: boolean;
  multiSource?: boolean;
}

export interface LanguageObject {
  en: string;
}

export interface GraduatedPrices {
  // Empty string is the value for no selected supplier - thus this is shown as a single case here
  sea: Array<{ supplier: "" | string; prices: Array<GraduatedPrice> }>;
  air: Array<{ supplier: "" | string; prices: Array<GraduatedPrice> }>;
}

export interface GraduatedPrice {
  amount: number;
  unitPrice: number;
  currency: string;
}

export interface Article {
  _id: BSON.ObjectId;
  title: LanguageObject; // Room for possible translations in the future
  subtitle: LanguageObject; // Room for possible translations in the future
  sellingPrices?: Array<TransportPrices>;
  graduation?: ArticleGraduationType;
  vegetarian: boolean;
  vegan: boolean;
  halal: boolean;
  kosher: boolean;
  foodGrade: boolean;
  pharmaceuticalGrade: boolean;
  feedGrade: boolean;
  cosmeticGrade: boolean;
  uspGrade: boolean;
  medicineGrade: boolean;
  industrialGrade: boolean;
  properties: Array<string>;
  shelfLife: number;
  note: string;
  articleNo: string; // Internal article number
  approved: boolean; // Articles provided by suppliers need to be approved by us
  disabled: boolean; // Needed in case of articles that are no longer for sale
  organic: boolean;
  country: { code: string; name: string };
  activeSubstances: Array<{ substance: string; percentage: number }>;
  duty: Duty;
  storageConditions: LanguageObject;
  transportConditions: Array<TransportConditions>;
  hsCode: string; // HS Code needed for customs
  btiRefNo?: string; // BTI reference, optional for customs
  graduatedPrices: GraduatedPrices;
  vatPercentage: number;
  documents: Array<UploadedFile>;
  commissionStocks?: Array<CommissionStock>;
  hiddenSupplier?: boolean;
  lastUpdate: Date;
}

export interface CommissionStock {
  _id: BSON.ObjectId;
  supplier: string;
  totalAmount: number;
  remainingAmount: number; // Amount that is not already sold to customers
  price: {
    _id: BSON.ObjectId;
    price: number; // Purchase price of the commodity per Unit
    currency: string; // Currency of the price
    date: Date; // Creation / Last update
    externalArticleNo: string;
  };
  ordered: boolean; // Indicates that the ware were ordered to be sent to our warehouse
}

export interface CommissionStockNew extends CommissionStock {
  new: true;
}
