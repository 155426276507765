import React, { useMemo } from "react";
import { Input } from "../common/Input";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import DateInput from "../common/DateInput";
import { PackagingDimension, Supplier } from "../../model/supplier.types";
import {
  CO_TRANSPORT,
  T_AIRFREIGHT,
  T_EUSTOCK,
  T_RAILFREIGHT,
  T_ROADFREIGHT,
  T_SEAFREIGHT,
} from "../../model/customerOrder.types";
import SupplierPriceGraphPopover from "./SupplierPriceGraphPopover";
import { DataContextInternalType } from "../../context/dataContext";
import { Seaport } from "../../model/seaport.types";
import { getDestinationSeaports, getSeaportName, getStartingSeaports } from "../../utils/seaportUtils";
import { Airport } from "../../model/airport.types";
import { getAirportName, getDestinationAirports, getStartingAirports } from "../../utils/airportUtils";
import { Textarea } from "../common/Textarea";
import { getOrderNumber, O_TRANSPORTTYPES_FOR_STOCK } from "../../utils/orderUtils";
import Tooltip from "../common/Tooltip";
import { Address } from "../../model/commonTypes";
import { AddressSelectOption, formatAddress } from "../../utils/addressUtils";
import SupplierOrderTerms from "../common/SupplierOrderTerms";
import { SupplierTermOptions } from "../../utils/supplierOrderUtils";
import { SUPPORTED_CURRENCIES_OPTIONS } from "../../utils/currencyUtils";
import SupplierEUWidget from "../common/SupplierEUWidget";
import { Notify } from "../../model/notify.types";
import { formatArticleUnit, InternalArticle, isCommodity } from "../../utils/productArticleUtils";
import { SupplierOrder } from "../../model/supplierOrder.types";
import { INTERNAL } from "../../utils/userUtils";
import { extendCommodity, extendFinishedProduct } from "../../utils/dataTransformationUtils";
import { Incoterm } from "../../utils/commodityUtils";
import { SupplierPriceWithBetter } from "../common/CustomTypes";

interface CreateSupplierOrderSettingsProps {
  context: DataContextInternalType;
  article: InternalArticle;
  supplier: SupplierPriceWithBetter | null;
  supplierPalette: SelectOption<PackagingDimension>;
  targetDate: Date;
  etd: Date;
  totalAmount: number;
  transportMethod: CO_TRANSPORT;
  seaport?: Seaport;
  airport?: Airport;
  euWarehouse?: Address;
  shippingInstruction: string;
  forStock?: boolean;
  fromCommissionStock?: boolean;
  preferredSupplier: string | null | undefined;
  supplierTermOptions: SupplierTermOptions;
  followUpCostEUStock: number;
  currency: SelectOption;
  existingSupplierOrder?: SupplierOrder;
  onChangeSeaport: (e: SelectOption) => void;
  onChangeTotalAmount?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTransportMethod?: (e: SelectOption) => void;
  onChangeAirport: (e: SelectOption) => void;
  onChangeSupplier: (e: SelectOption) => void;
  onChangeTargetDate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeShippingInstruction: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeNotify: (e: SelectOption<Notify>) => void;
  onChangePaymentTerms: (e: SelectOption) => void;
  onChangeCustomPaymentTerm: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCustomPaymentTermCondition: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDeliveryTerms: (e: SelectOption) => void;
  onChangeETD: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFollowUpCostEUStock: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeEUWarehouse: (e: AddressSelectOption) => void;
  onChangeCurrency: (e: SelectOption) => void;
}

const CreateSupplierOrderSettings: React.FunctionComponent<CreateSupplierOrderSettingsProps> = ({
  context,
  article: articleProps,
  supplier,
  supplierPalette,
  targetDate,
  etd,
  totalAmount,
  seaport,
  airport,
  euWarehouse,
  shippingInstruction,
  transportMethod,
  preferredSupplier,
  followUpCostEUStock,
  supplierTermOptions,
  currency,
  onChangeSeaport,
  onChangeTransportMethod,
  onChangeTotalAmount,
  onChangeAirport,
  onChangeSupplier,
  onChangeTargetDate,
  onChangeShippingInstruction,
  forStock,
  fromCommissionStock,
  existingSupplierOrder,
  onChangeNotify,
  onChangePaymentTerms,
  onChangeCustomPaymentTerm,
  onChangeCustomPaymentTermCondition,
  onChangeDeliveryTerms,
  onChangeFollowUpCostEUStock,
  onChangeETD,
  onChangeEUWarehouse,
  onChangeCurrency,
}) => {
  const article = isCommodity(articleProps, INTERNAL)
    ? extendCommodity(articleProps, context)
    : extendFinishedProduct(articleProps, context);

  const getSupplierEUStockOptions = () => {
    const supplierEUStockOptions: Array<SelectOption> = [];
    if (article.supplierEUStocks) {
      for (let i = 0; i < article.supplierEUStocks.length; i++) {
        const euStocks = article.supplierEUStocks;
        const supplier = context.supplier.find((supp) => supp._id.toString() === euStocks[i].supplier);
        if (supplier) {
          supplierEUStockOptions.push({
            label: supplier.name,
            value: supplier._id.toString(),
            isDisabled:
              supplier.disabled ||
              !euStocks[i].prices.some((p) => p.validUntil >= new Date() && p.price > 0) ||
              euStocks[i].disabled,
          });
        }
      }
    }
    return supplierEUStockOptions;
  };

  const getSupplierAddressesAsSelectOptions = (supplier?: Supplier) => {
    if (!supplier) return undefined;
    else {
      const addresses: Array<AddressSelectOption> = [];
      for (let i = 0; i < supplier.address.length; i++) {
        const a = supplier.address[i];
        addresses.push({ value: a._id.toString(), label: formatAddress(a), address: a });
      }
      return addresses;
    }
  };

  const supplierEUStockOptions = useMemo(() => getSupplierEUStockOptions(), [article]);
  const supplierEUWarehouseAddresses = useMemo(
    () => getSupplierAddressesAsSelectOptions(supplier?.supplier),
    [supplier]
  );

  const isCIF = useMemo(
    () => supplierTermOptions.deliveryTerm.value === Incoterm.CIF,
    [supplierTermOptions.deliveryTerm]
  );

  return (
    <div>
      <div className="fw-bolder text-white fs-3 my-5">Supplier Order Settings</div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Identifier</span>
        <div className="col-8">
          <Input
            className="form-control custom-form-control disabled"
            disabled={true}
            type="text"
            placeholder={"Auto Generated..."}
            value={existingSupplierOrder ? getOrderNumber(existingSupplierOrder) : undefined}
          />
          <small className="text-success mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Article</span>
        <div className="col-8">
          <div className="disabled">
            <CustomSelect
              options={[]}
              onChange={() => true}
              disabled={true}
              matchFormControl={true}
              value={{ value: article._id.toString(), label: article.title.en }}
            />
          </div>
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">
          <SupplierPriceGraphPopover
            article={article}
            amount={totalAmount}
            betterPriceMOQ={supplier && supplier.betterPrice ? supplier.betterPrice.price.minOQ : null}
          />
          {supplier?.supplier.euSupplier && <SupplierEUWidget />}
        </span>
        <div className="col-8">
          <Tooltip
            show={!preferredSupplier ? false : undefined}
            tooltipText={<div className="text-white text-left">Supplier was selected by customer.</div>}
          >
            <div>
              <CustomSelect
                options={
                  transportMethod === T_EUSTOCK && article.supplierEUStocks
                    ? article.supplierEUStocks.length !== 0
                      ? supplierEUStockOptions
                      : [{ value: "", label: "No matching suppliers", isDisabled: true }]
                    : article.suppliers.map((s) => {
                        return {
                          label: s.supplier.name,
                          value: s.supplier._id.toString(),
                          isDisabled:
                            s.supplier.disabled ||
                            !s.prices.some((p) => p.validUntil >= new Date() && p.price > 0) ||
                            s.disabled,
                        };
                      })
                }
                disabled={!!preferredSupplier || fromCommissionStock}
                value={
                  supplier ? { label: supplier.supplier.name, value: supplier.supplier._id.toString() } : undefined
                }
                placeholder={"Select Supplier..."}
                matchFormControl={true}
                onChange={!preferredSupplier && !fromCommissionStock ? onChangeSupplier : undefined}
              />
            </div>
          </Tooltip>
          <small className="text-success mt-1 float-right">
            {!fromCommissionStock && supplier && supplier.bestPrice ? "Best price" : <span>&nbsp;</span>}
          </small>
        </div>
      </div>
      {supplier && (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">
            Palette
            <Tooltip tooltipText="Palette of the supplier that matches the commodity and transport">
              <span className="ml-2">
                <i className="fa fa-info-circle text-white" />
              </span>
            </Tooltip>
          </span>
          <div className="col-8">
            <CustomSelect options={[supplierPalette]} matchFormControl={true} value={supplierPalette} disabled={true} />
            <small className="mt-1">&nbsp;</small>
          </div>
        </div>
      )}
      <SupplierOrderTerms
        supplierTermOption={supplierTermOptions}
        notifies={context.notify}
        transportMethod={transportMethod}
        onChangeNotify={onChangeNotify}
        onChangePaymentTerms={onChangePaymentTerms}
        onChangeCustomPaymentTerm={onChangeCustomPaymentTerm}
        onChangeCustomPaymentTermCondition={onChangeCustomPaymentTermCondition}
        onChangeDeliveryTerms={onChangeDeliveryTerms}
      />
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Currency</span>
        <div className="col-8">
          <CustomSelect
            options={SUPPORTED_CURRENCIES_OPTIONS}
            onChange={onChangeCurrency}
            matchFormControl={true}
            value={currency}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Total Amount</span>
        <div className="col-8">
          <div className="input-group">
            <Input
              className={"form-control custom-form-control " + ((!forStock || fromCommissionStock) && "disabled")}
              disabled={!forStock || fromCommissionStock}
              type="number"
              value={totalAmount}
              onChange={forStock && !fromCommissionStock ? onChangeTotalAmount : undefined}
            />
            <div className="input-group-append disabled" style={{ opacity: "0.7" }}>
              <span className="input-group-text form-control custom-form-control">
                {formatArticleUnit(article.unit)}
              </span>
            </div>
          </div>
          {!fromCommissionStock && supplier && supplier.price.minOQ <= totalAmount && (
            <small className="text-warning mt-1 float-right">
              {supplier && supplier.betterPrice && transportMethod !== T_EUSTOCK ? (
                `Better price at ${supplier.betterPrice.price.minOQ}${formatArticleUnit(article.unit)} ${
                  supplier.supplier._id.toString() !== supplier.betterPrice.supplier._id.toString()
                    ? `at ${supplier.betterPrice.supplier.name}`
                    : ""
                }`
              ) : (
                <span>&nbsp;</span>
              )}
            </small>
          )}
          <small className="text-danger mt-1 float-right mr-2">
            {!fromCommissionStock && supplier && supplier.price.minOQ > totalAmount ? (
              `${totalAmount}${formatArticleUnit(article.unit)} is below MOQ of ${
                supplier.price.minOQ
              }${formatArticleUnit(article.unit)}`
            ) : (
              <span>&nbsp;</span>
            )}
          </small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Transport Priority</span>
        <div className="col-8">
          <div className={!onChangeTransportMethod ? "disabled" : ""}>
            <CustomSelect
              options={!onChangeTransportMethod ? [] : O_TRANSPORTTYPES_FOR_STOCK}
              onChange={!onChangeTransportMethod ? () => true : onChangeTransportMethod}
              disabled={!onChangeTransportMethod}
              matchFormControl={true}
              value={{
                value: transportMethod,
                label: O_TRANSPORTTYPES_FOR_STOCK.find((t) => t.value === transportMethod)?.label || transportMethod,
              }}
            />
          </div>
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      {transportMethod === T_AIRFREIGHT ? (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">{isCIF ? "Destination" : "Starting"} Airport</span>
          <div className="col-8">
            <CustomSelect
              options={(isCIF ? getDestinationAirports(context.airport) : getStartingAirports(context.airport)).map(
                (a) => {
                  return { value: a._id.toString(), label: getAirportName(a) };
                }
              )}
              onChange={onChangeAirport}
              matchFormControl={true}
              placeholder={"Please select an airport"}
              value={airport ? { value: airport._id.toString(), label: getAirportName(airport) } : undefined}
            />
            <small className="mt-1">&nbsp;</small>
          </div>
        </div>
      ) : transportMethod === T_SEAFREIGHT ? (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">{isCIF ? "Destination" : "Starting"} Seaport</span>
          <div className="col-8">
            <CustomSelect
              options={(isCIF ? getDestinationSeaports(context.seaport) : getStartingSeaports(context.seaport)).map(
                (s) => {
                  return { value: s._id.toString(), label: getSeaportName(s) };
                }
              )}
              onChange={onChangeSeaport}
              matchFormControl={true}
              placeholder={"Please select a seaport"}
              value={seaport ? { value: seaport._id.toString(), label: getSeaportName(seaport) } : undefined}
            />
            <small className="mt-1">&nbsp;</small>
          </div>
        </div>
      ) : transportMethod === T_EUSTOCK ? (
        <>
          <div className="row mb-2">
            <span className="col-4 col-form-label fs-6 fw-bold">
              {supplierTermOptions.deliveryTerm.value === Incoterm.CIF ? "Destination" : "Starting"} EU Warehouse
            </span>
            <div className="col-8">
              <CustomSelect
                options={supplierEUWarehouseAddresses}
                onChange={onChangeEUWarehouse}
                matchFormControl={true}
                placeholder={"Please select a warehouse address"}
                value={
                  euWarehouse
                    ? { value: euWarehouse._id.toString(), label: formatAddress(euWarehouse), address: euWarehouse }
                    : undefined
                }
              />
              <small className="mt-1">&nbsp;</small>
            </div>
          </div>
          <div className="row mb-2">
            <span className="col-4 col-form-label fs-6 fw-bold">Transport Cost from Supplier to our Warehouse</span>
            <div className="col-8">
              <div className="input-group">
                <Input
                  className={"form-control custom-form-control"}
                  type="number"
                  value={followUpCostEUStock}
                  onChange={onChangeFollowUpCostEUStock}
                />
                <div className="input-group-append disabled" style={{ opacity: "0.7" }}>
                  <span className="input-group-text form-control custom-form-control">EUR</span>
                </div>
              </div>
              <small className="mt-1">&nbsp;</small>
            </div>
          </div>
        </>
      ) : ([T_ROADFREIGHT, T_RAILFREIGHT] as Array<CO_TRANSPORT>).includes(transportMethod) ? (
        <div className="row mb-2">
          <span className="col-4 col-form-label fs-6 fw-bold">Starting Point</span>
          <div className="col-8">
            <CustomSelect
              options={supplierEUWarehouseAddresses}
              onChange={onChangeEUWarehouse}
              matchFormControl={true}
              placeholder="Please select a warehouse address"
              value={
                euWarehouse
                  ? { value: euWarehouse._id.toString(), label: formatAddress(euWarehouse), address: euWarehouse }
                  : undefined
              }
            />
            <small className="text-success mt-1">&nbsp;</small>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">
          ETD
          <Tooltip tooltipText={"Estimated time of departure"}>
            <span className="ml-2">
              <i className="fa fa-info-circle text-white" />
            </span>
          </Tooltip>
        </span>
        <div className="col-8">
          <DateInput classes="form-control custom-form-control" value={etd} onBlur={onChangeETD} name={"etd"} />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">
          ETA
          <Tooltip tooltipText={"Estimated time of arrival"}>
            <span className="ml-2">
              <i className="fa fa-info-circle text-white" />
            </span>
          </Tooltip>
        </span>
        <div className="col-8">
          <DateInput
            classes="form-control custom-form-control"
            value={targetDate}
            onBlur={onChangeTargetDate}
            name={"targetDate"}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
      <div className="row mb-2">
        <span className="col-4 col-form-label fs-6 fw-bold">Shipping Instructions</span>
        <div className="col-8">
          <Textarea
            className="form-control custom-form-control"
            rows={9}
            value={shippingInstruction}
            placeholder="Additional shipping instructions..."
            onChange={onChangeShippingInstruction}
          />
          <small className="mt-1">&nbsp;</small>
        </div>
      </div>
    </div>
  );
};

export default CreateSupplierOrderSettings;
