import React, { PureComponent, useState } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import Search from "../Search";
import { CO_ARCHIVED, CO_CANCELED, CO_STATES } from "../../../model/customerOrder.types";
import {
  CustomerCustomerOrder,
  CustomerCustomerOrderExtended,
} from "../../../model/customer/customerCustomerOrder.types";
import {
  calculateArrivalInformation,
  getOrderNumber,
  getOrderStateDescriptions,
  O_CD_ACTIVEORDERSTYPES,
} from "../../../utils/orderUtils";
import {
  doFuseSearch,
  formatDate,
  formatUnit,
  getCountryNameForCode,
  toAbsoluteUrl,
  truncateString,
} from "../../../utils/baseUtils";
import { getDaysUntil, getTimeDiffString } from "../../../utils/dateUtils";
import CustomSelect, { SelectOption } from "../CustomSelect";
import { CC_STATE } from "../../../model/customerContract.types";
import { getContractNumber, getContractStateDescriptions } from "../../../utils/customerContractUtils";
import { CustomerCustomerContract } from "../../../model/customer/customerCustomerContract.types";
import Tooltip from "../Tooltip";
import AddReferenceModal from "../AddReferenceModal";
import HoverableLink from "../HoverableLink";

import { extendCustomerCustomerOrder } from "../../../utils/dataTransformationUtils";

interface ActiveOrdersProps {
  context: DataContextCustomerType | DataContextAnonymousType;
  small?: boolean;
}

interface ActiveOrdersState {
  search: string;
  type: SelectOption;
  orderToAddReference?: CustomerCustomerOrderExtended;
}

class ActiveOrders extends PureComponent<ActiveOrdersProps, ActiveOrdersState> {
  constructor(props: ActiveOrdersProps) {
    super(props);
    this.state = {
      search: "",
      type: O_CD_ACTIVEORDERSTYPES[0],
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleSelectType = (e: SelectOption) => this.setState({ type: e });

  handleSelectOrder = (order: CustomerCustomerOrderExtended) => this.setState({ orderToAddReference: order });

  handleCloseReferenceModal = () => this.setState({ orderToAddReference: undefined });

  render() {
    const { context, small } = this.props;
    const { search, type, orderToAddReference } = this.state;
    const activeOrders = context.customerOrder.filter(
      (o) => !([CO_CANCELED, CO_ARCHIVED] as Array<CO_STATES>).includes(o.state)
    );
    const activeContracts = context.customerContract.filter(
      (o) => ![CC_STATE.CANCELED, CC_STATE.CLOSED].includes(o.state)
    );
    let filteredOrders = activeOrders.slice();
    if (search) filteredOrders = doFuseSearch(filteredOrders, search, ["orderNo", "commodity.title.en", "amount"]);
    return (
      <>
        <AddReferenceModal
          order={orderToAddReference}
          show={!!orderToAddReference}
          onHide={this.handleCloseReferenceModal}
        />
        <div className="card bg-white" style={{ height: small ? "500px" : "100%" }}>
          <div className="card-header customer-dashboard-card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bolder fs-3 mb-1">
                Active {type.value === "orders" ? "Orders" : "Contracts"}
              </span>
              <span className="text-muted fw-bold fs-7">
                There are{" "}
                {type.value === "orders" ? activeOrders.length + " orders" : activeContracts.length + " contracts"}{" "}
                currently active
              </span>
            </h3>
            <div className="card-toolbar mb-5 mb-sm-2 w-100 w-md-50">
              <ul className="nav w-100">
                <li className="nav-item mb-5 mb-sm-2 w-100 w-md-25">
                  <CustomSelect
                    options={O_CD_ACTIVEORDERSTYPES}
                    onChange={(e: SelectOption) => this.handleSelectType(e)}
                    matchFormControl={true}
                    value={type}
                    additionalClasses="mr-3 options-muted"
                    classNamePrefix="muted"
                  />
                </li>
                <li className="nav-item w-100 w-md-75">
                  <Search value={search} onSearch={this.handleSearch} />
                </li>
              </ul>
            </div>
          </div>
          <div className="card-body pb-0 pt-0 pt-sm-5" style={{ overflowY: "auto" }}>
            {type.value === "orders" &&
              (filteredOrders.length === 0 ? (
                <div className="text-center mt-2 mb-10 text-muted fw-bolder">No orders found</div>
              ) : (
                filteredOrders
                  .slice(0, 25)
                  .map((aO) => (
                    <CustomerDashboardActiveOrder
                      key={aO._id.toString()}
                      order={aO}
                      onAddReference={this.handleSelectOrder}
                      context={context}
                      small={small}
                    />
                  ))
              ))}
            {type.value === "contracts" &&
              (activeContracts.length === 0 ? (
                <div className="text-center mt-2 mb-10 text-muted fw-bolder">No contracts found</div>
              ) : (
                activeContracts
                  .slice(0, 25)
                  .map((aC) => (
                    <CustomerDashboardActiveContract key={aC._id.toString()} contract={aC} context={context} />
                  ))
              ))}
          </div>
          <div className="card-footer border-top-0 pt-4">
            {!small && (
              <Link to="/orders">
                <button type="button" className="btn btn-light w-100">
                  Show All Orders
                </button>
              </Link>
            )}
          </div>
        </div>
      </>
    );
  }
}

interface CustomerDashboardActiveOrderProps {
  order: CustomerCustomerOrder;
  onAddReference: (order: CustomerCustomerOrderExtended) => void;
  context: DataContextCustomerType | DataContextAnonymousType;
  small?: boolean;
}

const CustomerDashboardActiveOrder: React.FunctionComponent<CustomerDashboardActiveOrderProps> = ({
  order,
  onAddReference,
  context,
  small,
}) => {
  const [hoverReference, setHoverReference] = useState(false);
  const handleHoverReference = (type: "enter" | "leave") => setHoverReference(type === "enter");

  const arrival = calculateArrivalInformation(order, order.changedETA);
  const countryName = getCountryNameForCode(order.commodity.country.code);

  return (
    <HoverableLink to={`/order/${order._id.toString()}`} hovering={hoverReference}>
      <div className={"bg-light rounded p-3 p-md-5 mb-3 " + (hoverReference ? "no-hover" : "")}>
        <div className="row">
          <div className={small ? "col-12" : "col-12 col-md-8 col-xxl-6"}>
            <div className="mr-md-2">
              <div className="d-inline-flex fw-bolder text-gray-800 flex-center flex-stack">
                <span className="fs-6 mr-2 mr-md-0">Order {getOrderNumber(order)}</span>
                <span className="text-success ml-md-3 mt-1 mt-md-0" style={{ fontSize: ".8rem" }}>
                  <div className="d-flex flex-center">
                    <div className="green-dot">&nbsp;</div>
                    <span className="ml-1">{getOrderStateDescriptions(order).title}</span>
                  </div>
                </span>
              </div>
              <span className="text-muted d-block">
                <span className="mr-md-3">
                  <b>{formatUnit(order.amount, order.unit || order.commodity.unit)}</b> {order.commodity.title.en}
                </span>
              </span>
            </div>
          </div>
          {!small && (
            <>
              <div className="d-none d-xxl-block col-2 text-left text-md-right align-self-center">
                <div className="fw-bolder text-muted py-1">
                  <small>
                    {order.customerReference ? (
                      order.customerReference.length > 20 ? (
                        <Tooltip tooltipText={order.customerReference}>
                          <span>Ref: {truncateString(order.customerReference, 20)}</span>
                        </Tooltip>
                      ) : (
                        "Ref: " + order.customerReference
                      )
                    ) : (
                      <button
                        className={"btn btn-light btn-sm element-show-hover py-1 px-3"}
                        onClick={() => onAddReference(extendCustomerCustomerOrder(order, context))}
                        onMouseEnter={() => handleHoverReference("enter")}
                        onMouseLeave={() => handleHoverReference("leave")}
                      >
                        <i className="fa fa-plus" /> Add Reference
                      </button>
                    )}
                  </small>
                </div>
              </div>
              <div className="col-4 col-md-2 text-left text-md-right align-self-center">
                <div className="fw-bolder text-muted py-1 fs-5">
                  {countryName && (
                    <>
                      <img
                        src={toAbsoluteUrl(
                          `/assets/media/icons/countries/${order.commodity.country.code.toLowerCase()}.png`
                        )}
                        className="country-icon mr-2 mb-1"
                        alt={order.commodity.country.code}
                      />
                      <small>{countryName}</small>
                    </>
                  )}
                </div>
              </div>
              <div className="col-8 col-md-2 align-self-center">
                <div className="text-right mr-2">
                  <div className="fw-bold fs-6 text-gray-400">CW {arrival.cw}</div>
                  <span className="text-muted mb-1">
                    {arrival.target ? getTimeDiffString(order.changedETA ?? order.targetDate) : "Delivered"}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </HoverableLink>
  );
};

interface CustomerDashboardActiveContractProps {
  contract: CustomerCustomerContract;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const CustomerDashboardActiveContract: React.FunctionComponent<CustomerDashboardActiveContractProps> = ({
  contract,
  context,
}) => {
  const { innerWidth } = context;
  const timeLeft = getDaysUntil(contract.validityPeriod.end);
  const { restAmount, minimumCallQuantity, totalAmount } = contract.contractInformation;
  const { unit, title } = contract.commodity;
  const options = {
    chart: {
      background: "transparent",
    },
    colors: ["#999999", "#393939"],
    sparkline: true,
    stroke: { show: false },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Called", "Open"],
    tooltip: {
      enabled: true,
      y: { formatter: (y: string | number) => `${y}%` },
    },
  };
  const percentageOpen = (restAmount / totalAmount) * 100;
  const series = [100 - percentageOpen, percentageOpen];

  return (
    <Link to={`/contract/${contract._id.toString()}`} className="cursor-pointer">
      <div className="bg-light rounded p-5 mb-3">
        <div className="row">
          <div className="col-9 col-sm-7">
            <div className="mr-2">
              <div className="d-inline-flex fw-bolder text-gray-800  flex-center flex-stack">
                <span className="fs-6">Contract {getContractNumber(contract)}</span>
                <span className="text-success ml-3" style={{ fontSize: ".8rem" }}>
                  <div className="d-flex flex-center">
                    <div className="green-dot">&nbsp;</div>
                    <span className="ml-1"> {getContractStateDescriptions(contract).title}</span>
                  </div>
                </span>
              </div>
              <span className="text-muted d-block">
                <span className="mr-3 w-100">
                  <b>{formatUnit(totalAmount, unit)}</b> {title.en}
                </span>
              </span>
            </div>
          </div>
          <div className="col-3 col-sm-1 align-self-center text-left">
            <div className="fw-bolder text-muted py-1">
              <Chart options={options} series={series} type="pie" width={80} />
            </div>
          </div>
          <div className="col-4 col-sm-2 align-self-center">
            <div className={"fw-bolder text-muted py-1 " + (innerWidth > 1800 ? "ml-1 mr-4" : "ml-2 mr-1")}>
              <div className="text-white">{formatUnit(restAmount, unit)} open</div>
              <small className="text-muted d-block">{formatUnit(minimumCallQuantity, unit)} MOQ</small>
            </div>
          </div>
          <div className="col-8 col-sm-2 align-self-center">
            <div className="text-right ml-auto mr-2">
              <span className="text-white">{formatDate(contract.validityPeriod.end)}</span>
              <div className={timeLeft > 30 ? "text-success" : timeLeft > 0 ? "text-warning" : "text-danger"}>
                {getTimeDiffString(contract.validityPeriod.end)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ActiveOrders;
