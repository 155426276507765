import { RouteComponentProps, withRouter } from "react-router-dom";
import React, { useCallback } from "react";
import { ProgressBar } from "react-bootstrap";
import { formatDate, formatUnit, round, truncateString } from "../../../utils/baseUtils";
import { CustomerCustomerContract } from "../../../model/customer/customerCustomerContract.types";
import { DataContextCustomerType } from "../../../context/dataContext";
import { CC_STATE } from "../../../model/customerContract.types";
import { getDaysUntil, getTimeDiffString } from "../../../utils/dateUtils";
import { getContractNumber } from "../../../utils/customerContractUtils";
import CallReferences from "../../contracts/common/CallReferences";
import CreateCustomerContractCallModal from "../common/CreateCustomerContractCallModal";
import { extendCustomerCustomerContract } from "../../../utils/dataTransformationUtils";

interface CustomerContractCardElementProps extends RouteComponentProps {
  contract: CustomerCustomerContract;
  context: DataContextCustomerType;
}

const CustomerContractCardElement: React.FunctionComponent<CustomerContractCardElementProps> = ({
  contract,
  context,
  history,
}) => {
  const { totalAmount, restAmount, minimumCallQuantity } = contract.contractInformation;

  const forwardContract = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      e.stopPropagation();
      history.push(`/contract/${contract._id.toString()}`);
    },
    [contract, history]
  );

  const usage = round((1 - restAmount / totalAmount) * 100, 0);
  const inactive = usage === 100 || [CC_STATE.CANCELED, CC_STATE.CLOSED].includes(contract.state);
  const timeLeft = getDaysUntil(contract.validityPeriod.end);

  return (
    <>
      <div className="col-6 text-left mb-2">
        <span
          className="text-white fs-5 d-inline-block"
          style={{ borderWidth: "20px", opacity: inactive ? 0.5 : 1 }}
          onClick={forwardContract}
        >
          <div className="text-white fs-5 mb-1 h5">{getContractNumber(contract)}</div>
        </span>
      </div>
      <div className="col-6 text-right mb-2">
        <div className="text-white fs-5 mb-1 h5">
          {formatUnit(contract.contractInformation.totalAmount, contract.commodity.unit)}
        </div>
      </div>
      <div className="col-12 text-left mb-2">
        <span className="text-white fs-5 d-inline-block">{truncateString(contract.commodity.title.en, 40)}</span>
        {contract.commodity.organic && <i title="organic" className="fas fa-leaf text-success d-inline-block ml-2" />}
        <span className="text-muted d-block" style={{ fontSize: "0.85rem" }}>
          {truncateString(contract.commodity.subtitle.en, 50)}
        </span>
      </div>
      <div className="col-12 text-muted">Call-offs</div>
      <div className="col-12 text-center mb-2">
        {contract.calls.length > 0 ? (
          <CallReferences contract={contract} link={"/order/"} />
        ) : (
          <CreateCustomerContractCallModal
            small={true}
            contract={extendCustomerCustomerContract(contract, context)}
            context={context}
          />
        )}
      </div>
      <div className="col-4 text-muted">Remaining</div>
      <div className="col-8 text-right mb-2">
        <div className={"text-white"}>{formatUnit(restAmount, contract.commodity.unit)}</div>
        <small className={"text-muted d-block"}>{formatUnit(minimumCallQuantity, contract.commodity.unit)} MOQ</small>
      </div>
      <div className="col-12 text-muted mb-2">Usage</div>
      <div className="col-12 text-center mb-2">
        <ProgressBar style={{ backgroundColor: "#2f2e2e", height: 5 }}>
          <ProgressBar min={0} max={100} now={contract.state === CC_STATE.CANCELED ? 100 : usage} variant={"white2"} />
        </ProgressBar>
        <div className="fs-7 text-nowrap text-center">{contract.state === CC_STATE.CANCELED ? 100 : usage}%</div>
      </div>
      <div className="col-4 text-muted">Valid until</div>
      <div className="col-8 text-right">
        {contract.state !== CC_STATE.CANCELED ? (
          <>
            <span className={"text-white"}>{formatDate(contract.validityPeriod.end)}</span>
            <div className={timeLeft > 30 ? "text-success" : timeLeft > 0 ? "text-warning" : "text-danger"}>
              {getTimeDiffString(contract.validityPeriod.end)}
            </div>
          </>
        ) : (
          <span className={"text-white"}>Canceled</span>
        )}
      </div>
    </>
  );
};

export default withRouter(CustomerContractCardElement);
