import React, { PureComponent } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tooltip from "../../../../common/Tooltip";
import BaseListing from "../../../../common/BaseListing";
import { CustomToggle } from "../../../../common/CustomToggle";
import { CustomerOrderExtended, UsedBatch } from "../../../../../model/customerOrder.types";
import { BatchExtended, BatchFile, BatchState } from "../../../../../model/batch.types";
import { Supplier } from "../../../../../model/supplier.types";
import { resolveFilePath } from "../../../../../utils/fileUtils";
import { formatDate, getDocFromCollection, toAbsoluteUrl } from "../../../../../utils/baseUtils";
import {
  B_PACKAGE_DICT,
  B_WAREHOUSE_OPTIONS,
  extendBatch,
  getBatchAmount,
  getStockPackageType,
  getUsedBatchAmount,
} from "../../../../../utils/batchUtils";
import { getLinkForOrderOrContract } from "../../../../../utils/contractAndOrderUtils";
import { DataContextInternalType } from "../../../../../context/dataContext";
import { formatArticleUnit } from "../../../../../utils/productArticleUtils";

interface CustomerOrderUsedBatchesProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

interface CustomerOrderUsedBatchesState {
  expandedUsedBatches: Array<string>;
}

class CustomerOrderUsedBatches extends PureComponent<CustomerOrderUsedBatchesProps, CustomerOrderUsedBatchesState> {
  constructor(props: CustomerOrderUsedBatchesProps) {
    super(props);
    this.state = {
      expandedUsedBatches: [],
    };
  }

  getUsedBatchSupplier = (usedBatch: UsedBatch): Supplier | undefined => {
    const { context } = this.props;
    return context.supplier.find((s) => s._id.toString() === usedBatch.supplier);
  };

  getOriginalBatch = (usedBatch: UsedBatch): BatchExtended | undefined => {
    const { context } = this.props;
    const batch = getDocFromCollection(context.batch, usedBatch.batchId);
    if (batch) return extendBatch(batch, context);
  };

  toggleUsedBatch = (batchId: string) => {
    this.setState((prevState) => {
      let updatedExpandedUsedBatch = [...prevState.expandedUsedBatches];
      if (updatedExpandedUsedBatch.includes(batchId)) {
        updatedExpandedUsedBatch = updatedExpandedUsedBatch.filter((id) => id !== batchId);
      } else {
        updatedExpandedUsedBatch.push(batchId);
      }
      return { expandedUsedBatches: updatedExpandedUsedBatch };
    });
  };

  render() {
    const { order } = this.props;
    // only display batches where amount was really used
    const usedBatchesFiltered = order.usedBatches?.filter((usedBatch) => usedBatch.totalAmountUsed > 0);
    const { expandedUsedBatches } = this.state;
    const headerDefinition = [
      { title: "Lot", style: { width: "30%" } },
      { title: "Amount used", style: { width: "20%" } },
      { title: "Type", style: { width: "15%" } },
      { title: "Warehouse", style: { width: "20%" } },
      { title: "Location", style: { width: "15%" } },
    ];

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Batches used by RB-{order.orderNo}</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          {usedBatchesFiltered && usedBatchesFiltered.length > 0 ? (
            <>
              <Accordion defaultActiveKey={undefined} alwaysOpen={true}>
                <div className="bg-light2 rounded p-5 mb-7">
                  {usedBatchesFiltered.map((usedBatch) => {
                    const originalBatch = this.getOriginalBatch(usedBatch);
                    const usedBatchSupplier = this.getUsedBatchSupplier(usedBatch);
                    return (
                      <React.Fragment key={usedBatch._id.toString()}>
                        <CustomToggle
                          eventKey={usedBatch._id.toString()}
                          callback={() => this.toggleUsedBatch(usedBatch._id.toString())}
                        >
                          <div className="row p-5">
                            <div className="col-auto my-auto px-0">
                              <h5 className="mb-0 cursor-pointer">
                                <i
                                  className={`custom-accordion-toggle fa fa-chevron-up mr-2 ${
                                    expandedUsedBatches.includes(usedBatch._id.toString()) ? "show" : ""
                                  }`}
                                />
                              </h5>
                            </div>
                            <UsedBatchDetails
                              usedBatch={usedBatch}
                              batchSupplier={usedBatchSupplier}
                              originalBatch={originalBatch}
                            />
                          </div>
                        </CustomToggle>
                        <Accordion.Collapse eventKey={usedBatch._id.toString()}>
                          <BaseListing
                            headerDefinition={headerDefinition}
                            bodyContent={
                              <>
                                {usedBatch.packages.map((p) => (
                                  <tr key={p._id.toString()} className="py-5 mb-1">
                                    <td className="mt-2">
                                      <span className="text-muted fw-bold">
                                        RB{usedBatch.identifier}-{p.number}
                                      </span>
                                    </td>
                                    <td className="mt-2">
                                      <span className="text-muted fw-bold">
                                        {p.amountUsed}
                                        {formatArticleUnit(usedBatch.unit)}
                                      </span>
                                    </td>
                                    <td className="mt-2">
                                      <span className="text-muted fw-bold">{B_PACKAGE_DICT[p.packageType]}</span>
                                    </td>
                                    <td className="mt-2">
                                      <span className="text-muted fw-bold">
                                        {B_WAREHOUSE_OPTIONS.find((wo) => wo.value === p.warehouse)?.label ?? "Unknown"}
                                      </span>
                                    </td>
                                    <td className="mt-2">
                                      <span className="text-muted fw-bold">{p.warehouseLocation}</span>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            }
                          />
                        </Accordion.Collapse>
                      </React.Fragment>
                    );
                  })}
                </div>
              </Accordion>
            </>
          ) : (
            <div className="col-12 mt-5 text-center fs-5">Commissioning not done yet</div>
          )}
        </div>
      </div>
    );
  }
}

interface UsedBatchCoAFileProps {
  coa: BatchFile | null;
  fallbackCoA?: BatchFile | null;
  tooltipText: string;
  alt: string;
}

const UsedBatchCoAFile: React.FunctionComponent<UsedBatchCoAFileProps> = ({ coa, fallbackCoA, tooltipText, alt }) => {
  // using CoA from original batch as fallback since CoA in usedBatch does not always seem to be set
  const coaToDisplay = coa || fallbackCoA;
  return coaToDisplay ? (
    <Tooltip tooltipText={tooltipText}>
      <a
        href={resolveFilePath(coaToDisplay.path)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <img alt={alt} className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
      </a>
    </Tooltip>
  ) : (
    <Tooltip tooltipText={tooltipText + " missing"}>
      <img
        alt={alt}
        className="w-30px mr-2 image-gray"
        src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
        onClick={(e) => e.stopPropagation()}
      />
    </Tooltip>
  );
};

interface UsedBatchAmountProps {
  usedBatch: UsedBatch;
  originalBatch: BatchExtended | undefined;
}

const UsedBatchAmount: React.FunctionComponent<UsedBatchAmountProps> = ({ usedBatch, originalBatch }) => {
  return originalBatch ? (
    <Tooltip
      tooltipText={
        <div className="text-white">
          <div className="row">
            <div className="col-12 col-sm-6 my-auto text-left fs-7">Remaining in original batch:</div>
            <div className="col-12 col-sm-6 my-auto text-right fs-7">
              <div className="text-white fs-7">
                {getBatchAmount(originalBatch)}
                {formatArticleUnit(originalBatch.unit)}
              </div>
              <div className="text-muted fs-7">{getStockPackageType(originalBatch, true)}</div>
            </div>
          </div>
        </div>
      }
    >
      <div className="col-auto align-self-center">
        <div className="text-white fs-6 fw-bolder">
          {getUsedBatchAmount(usedBatch)}
          {formatArticleUnit(usedBatch.unit)}
        </div>
        <div className="text-muted fs-7">{getStockPackageType(usedBatch)}</div>
      </div>
    </Tooltip>
  ) : (
    <div className="col-auto align-self-center">
      <div className="text-white fs-6 fw-bolder">
        {getUsedBatchAmount(usedBatch)}
        {formatArticleUnit(usedBatch.unit)}
      </div>
      <div className="text-muted fs-7">{getStockPackageType(usedBatch)}</div>
    </div>
  );
};

interface UsedBatchDetailsProps {
  usedBatch: UsedBatch;
  batchSupplier: Supplier | undefined;
  originalBatch: BatchExtended | undefined;
}

const UsedBatchDetails: React.FunctionComponent<UsedBatchDetailsProps> = ({
  usedBatch,
  batchSupplier,
  originalBatch,
}) => {
  return (
    <>
      <div className="col-auto my-auto">
        <h5 className="mb-0 cursor-pointer">Batch {`RB${usedBatch.identifier}`}</h5>
        {batchSupplier && (
          <Link className="text-muted fs-7 custom-link" to={`/supplier/${batchSupplier._id.toString()}`}>
            {batchSupplier.name}
          </Link>
        )}
      </div>
      {usedBatch.state && (
        <div className="col my-auto">
          <div
            className={
              "btn-sm py-1 px-2 text-uppercase btn btn-primary " +
              (usedBatch.state === BatchState.BLOCKED
                ? "btn-danger"
                : usedBatch.state === BatchState.INCOMING
                ? "btn-warning"
                : "btn-success")
            }
          >
            {usedBatch.state}
          </div>
        </div>
      )}
      {originalBatch && originalBatch.supplierOrder && (
        <div className="col my-auto">
          <div className="text-white fs-6 fw-bolder">Supplier Order</div>
          <Link className="text-white fs-6 custom-link" to={getLinkForOrderOrContract(originalBatch.supplierOrder)}>
            RB-{originalBatch.supplierOrder.orderNo}
          </Link>
        </div>
      )}
      <div className="col-auto my-auto">
        <UsedBatchCoAFile
          coa={usedBatch.supplierCoA}
          fallbackCoA={originalBatch?.supplierCoA}
          tooltipText={"Supplier CoA"}
          alt={"Supplier CoA"}
        />
        <UsedBatchCoAFile
          coa={usedBatch.ownCoA}
          fallbackCoA={originalBatch?.ownCoA}
          tooltipText={"Rawbids CoA"}
          alt={"Rawbids CoA"}
        />
      </div>
      <UsedBatchAmount usedBatch={usedBatch} originalBatch={originalBatch} />
      {usedBatch.expiry && (
        <div className="col-auto my-auto align-self-center">
          <div className="text-white fs-6 fw-bolder">{formatDate(usedBatch.expiry)}</div>
          <div className="text-muted fs-7">Expiry Date</div>
        </div>
      )}
    </>
  );
};

export default CustomerOrderUsedBatches;
