import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  ArticleCommissionStockRowDisplayMode,
  getDefaultCommissionStock,
  InternalArticleExtended,
} from "../../../utils/productArticleUtils";
import AddCommissionStockModal from "./modals/AddCommissionStockModal";
import { CommissionStock, CommissionStockNew } from "../../../model/commonTypes";
import { SupplierExtended } from "../../../model/supplier.types";
import ArticleCommissionStockRow from "./ArticleCommissionStockRow";

interface ArticleCommissionStockProps {
  article: InternalArticleExtended;
}

const ArticleCommissionStock: React.FC<ArticleCommissionStockProps> = ({ article }) => {
  const [commissionStocks, setCommissionStocks] = useState<Array<CommissionStock | CommissionStockNew>>([]);

  const handleAddCommissionStock = useCallback(
    (supplier: SupplierExtended) => {
      const cS = _.cloneDeep(commissionStocks);
      cS.push({ ...getDefaultCommissionStock(supplier._id.toString()), new: true });
      setCommissionStocks(cS);
    },
    [commissionStocks]
  );

  const handleRemoveCommissionStock = useCallback(
    (id: string) => {
      const cS = _.cloneDeep(commissionStocks);
      setCommissionStocks(cS.filter((c) => c._id.toString() !== id));
    },
    [commissionStocks]
  );

  const handleResetCommissionStock = useCallback(
    () => setCommissionStocks(_.cloneDeep(article.commissionStocks) || []),
    [article.commissionStocks]
  );

  const handleChangeInput = useCallback(
    (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const cS = _.cloneDeep(commissionStocks);
      const relCS = cS.find((c) => c._id.toString() === id);
      if (!relCS) return;
      const { name, value, type } = e.target;
      _.set(relCS, name, type === "number" ? Number(value) : value);
      setCommissionStocks(cS);
    },
    [commissionStocks]
  );

  const handleChangeCurrency = useCallback(
    (id: string, e: React.ChangeEvent<HTMLSelectElement>) => {
      const cS = _.cloneDeep(commissionStocks);
      const relCS = cS.find((c) => c._id.toString() === id);
      if (!relCS) return;
      relCS.price.currency = e.target.value;
      setCommissionStocks(cS);
    },
    [commissionStocks]
  );

  useEffect(() => setCommissionStocks(_.cloneDeep(article.commissionStocks) || []), [article.commissionStocks]);

  return (
    <div id="commissionStocks" className="card bg-white mt-5">
      <div className="card-header border-0 mt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">Commission Stocks</span>
          <span className="text-muted fw-bold fs-7">{commissionStocks.length} Entries</span>
        </h3>
      </div>
      <div className="card-body p-4 pt-4">
        {commissionStocks.map((cS) => {
          return (
            <div key={cS._id.toString()}>
              <ArticleCommissionStockRow
                article={article}
                commissionStock={cS}
                displayMode={ArticleCommissionStockRowDisplayMode.SUPPLIER}
                onRemoveCommissionStock={handleRemoveCommissionStock}
                onChangeInput={handleChangeInput}
                onChangeCurrency={handleChangeCurrency}
              />
            </div>
          );
        })}
        <div className="pt-2">
          <AddCommissionStockModal article={article} onAddCommissionStock={handleAddCommissionStock} />
          <button type="button" className="btn btn-light btn-sm float-right mr-2" onClick={handleResetCommissionStock}>
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArticleCommissionStock;
