import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import userService from "../../services/userService";
import { getDefaultSlackChannel, sendMessage } from "../../services/slackService";
import { getUserName } from "../../utils/userUtils";

interface ErrorBoundaryProps extends RouteComponentProps {
  children: JSX.Element | JSX.Element[];
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // Sending error message to slack channel.
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (process.env.NODE_ENV === "production") {
      const { history } = this.props;
      const userData = userService.getUserData();
      const errorMessage = `*${error ? error.message : "unknown"}*\n
      LOCATION: *${history.location.pathname.replaceAll("/", "\\")}*\n
      USER: *${userService.getUserId()} (${getUserName(userData)})*\n
      TYPE: *${userService.getUserData().type}*\n
      ENVIRONMENT: *${process.env.NODE_ENV}*\n
      BACKEND: *${process.env.REACT_APP_NAME || "unknown"}*\n
      VERSION: *${process.env.REACT_APP_VERSION || "unknown"} (${process.env.REACT_APP_VERSION_VILLAGE || "unknown"})*\n
      *TRACE:* \`\`\` ${errorInfo ? errorInfo.componentStack : "unknown"} \`\`\`\n`.replace(/  +/g, "");
      sendMessage(getDefaultSlackChannel(true), errorMessage);
      this.setState({ hasError: true });
    }
  }

  handleGoBack = () => {
    window.location.href = "/dashboard";
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    return hasError ? <FallbackUI onClick={this.handleGoBack} /> : children;
  }
}

interface FallbackUIProps {
  onClick: () => void;
}

export const FallbackUI: React.FunctionComponent<FallbackUIProps> = ({ onClick }) => {
  return (
    <div className="d-flex flex-column flex-center flex-column-fluid">
      <div className="d-flex flex-column flex-center text-center p-10">
        <div className="card error-card card-flush w-lg-650px py-5">
          <div className="card-body py-15 py-lg-20">
            <div className="mb-11 d-flex justify-content-center align-items-center">
              <div className="fallback-logo w-100" />
            </div>
            <h1 className="fw-bolder fs-2qx text-white mb-4">Oops!</h1>
            <div className="fw-semibold fs-6 text-white-50 mb-7">Something went wrong!</div>
            <div className="mb-0">
              <button
                type="button"
                className="btn btn-light w-25"
                onClick={onClick}
                style={{ borderRadius: "5px", marginTop: "1px" }}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ErrorBoundary);
