import React, { PureComponent } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import userService from "../../services/userService";
import { ADMIN, ANONYMOUS, CUSTOMER, INTERNAL, SCM, SUPPLIER } from "../../utils/userUtils";
import { DataContextType, isAnonymousContext, isInternalContext, isSupplierContext } from "../../context/dataContext";
import CreateCustomerAccountModal from "../userData/anonymous/CreateCustomerAccountModal";
import PriceTicker from "./headerContent/PriceTicker";
import SearchBar from "./SearchBar";
import AsideDropdown from "../common/AsideDropdown";

interface HeaderProps extends RouteComponentProps {
  context: DataContextType;
  view: typeof INTERNAL | typeof CUSTOMER | typeof SUPPLIER | typeof ANONYMOUS;
  onChangeToggleView?: () => void;
}

interface HeaderState {
  seconds: number;
  isMobileAsideActive: boolean;
}

class Header extends PureComponent<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      seconds: 3,
      isMobileAsideActive: false,
    };
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (this.state.seconds > 0) this.setState({ seconds: this.state.seconds - 1 });
      else clearInterval(interval);
    }, 1000);
  }

  render() {
    const { history, context, onChangeToggleView, view } = this.props;
    const { seconds } = this.state;
    const roles = userService.getRoles();
    const isSCMOrAdmin = roles.includes(ADMIN) || roles.includes(SCM);
    const disabled = !userService.getUserData()?.onboardingDone || userService.getUserType() === SUPPLIER;
    const isSC = isSupplierContext(context);
    return (
      <>
        <div className="indeterminate-progress-bar" style={{ opacity: seconds ? 1 : 0 }}>
          {seconds && <div className="indeterminate-progress-bar__progress" />}
        </div>
        <div className="header price-ticker align-items-stretch" style={{ height: isSC ? 0 : 40 }}>
          {!isSC && <PriceTicker context={context} />}
        </div>
        <div
          className="header"
          style={{ animationDuration: "0.3s", height: 75, boxShadow: "none", marginTop: isSC ? 0 : 40 }}
        >
          <div className="container header-container">
            <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 py-3 py-lg-0 me-3 d-none d-lg-block" />
            <div className="d-flex align-items-center justify-content-between flex-wrap mobile-general-header">
              <AsideDropdown view={view} />
              <Link to={isInternalContext(context) && context.currentView === SCM ? "/scm" : "/dashboard"}>
                <div className="logo h-40px h-lg-40px w-150px w-lg-150px" />
              </Link>
              <SearchBar isForMobileUse={false} />
              <button
                className={"btn btn-outline btn-outline-light d-none d-lg-block " + (disabled ? " disabled" : "")}
                disabled={disabled}
                onClick={disabled ? undefined : () => history.push("/orders")}
              >
                Orders
              </button>
              {isAnonymousContext(context) && <CreateCustomerAccountModal context={context} />}
              {isSCMOrAdmin && (
                <div className="d-flex d-none d-lg-block" style={{ minWidth: "120px" }}>
                  <label className="form-check form-switch form-check-custom form-check-solid">
                    <input
                      className="form-check-input position-static"
                      type="checkbox"
                      checked={isInternalContext(context) && context.currentView === INTERNAL}
                      onChange={onChangeToggleView}
                    />
                    <span className="form-check-label fw-bold text-muted">
                      {isInternalContext(context) && context.currentView === INTERNAL ? "Internal" : "SCM"}
                    </span>
                  </label>
                </div>
              )}
            </div>
            <SearchBar isForMobileUse={true} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
