import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Textarea } from "../../../../common/Textarea";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { CO_T_REFERENCECHANGED, CustomerOrderExtended } from "../../../../../model/customerOrder.types";
import { Input } from "../../../../common/Input";
import { getCustomerOrderTimelineEntry } from "../../../../../utils/customerOrderUtils";
import { CUSTOMERCONTRACT, CUSTOMERORDER, transaction } from "../../../../../services/dbService";
import userService from "../../../../../services/userService";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../../services/slackService";
import { CustomerContractExtended, CustomerContractTimelineType } from "../../../../../model/customerContract.types";
import { getOrderNumber, isCustomerOrder } from "../../../../../utils/orderUtils";
import { getContractNumber, getCustomerContractTimelineEntry } from "../../../../../utils/customerContractUtils";

interface ChangeCustomerReferenceModalProps {
  order: CustomerOrderExtended | CustomerContractExtended;
}

const ChangeCustomerReferenceModal: React.FunctionComponent<ChangeCustomerReferenceModalProps> = ({ order }) => {
  const [{ show, saving, customerRef, note }, setModalState] = useState({
    show: false,
    saving: false,
    customerRef: order.customerReference,
    note: "",
  });

  const handleShow = () =>
    setModalState((prevState) => {
      return { ...prevState, show: true };
    });
  const handleHide = () =>
    setModalState((prevState) => {
      return { ...prevState, show: false };
    });

  const handleChangeCustomerRef = (e: React.ChangeEvent<HTMLInputElement>) =>
    setModalState((prevState) => {
      return { ...prevState, customerRef: e.currentTarget.value };
    });

  const handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setModalState((prevState) => {
      return { ...prevState, note: e.currentTarget.value };
    });

  const handleSaveCustomerRef = async () => {
    try {
      setModalState((prevState) => {
        return { ...prevState, saving: true };
      });
      const timelineEntryText = `${note} - Changed from ${
        order.customerReference.trim() !== "" ? order.customerReference : "none"
      } to ${customerRef}`;
      const timelineEntry = isCustomerOrder(order)
        ? getCustomerOrderTimelineEntry(CO_T_REFERENCECHANGED, { reason: timelineEntryText })
        : getCustomerContractTimelineEntry(CustomerContractTimelineType.REFERENCE_CHANGED, {
            reason: timelineEntryText,
          });
      const action = {
        collection: isCustomerOrder(order) ? CUSTOMERORDER : CUSTOMERCONTRACT,
        filter: { _id: order._id },
        update: {
          customerReference: customerRef,
        },
        push: { timeline: timelineEntry },
      };

      const res = await transaction([action]);

      if (res) {
        toast.success("Customer reference successfully changed");
        const user = userService.getUserData();

        await sendMessage(
          getDefaultSlackChannel(false, NotificationType.ORDER),
          `Customer reference of ${isCustomerOrder(order) ? "order" : "contract"} ${
            isCustomerOrder(order) ? getOrderNumber(order) : getContractNumber(order)
          } (https://${process.env.REACT_APP_BASE_URL || ""}/${
            isCustomerOrder(order) ? "customerOrder" : "customerContract"
          }/${order._id.toString()}) was changed from ${
            order.customerReference.trim() !== "" ? order.customerReference : "none"
          } to ${customerRef} by ${user.prename} ${user.surname}.`
        );
        setModalState((prevState) => {
          return { ...prevState, show: false };
        });
      } else {
        toast.error("Error changing customer reference");
      }
    } finally {
      setModalState((prevState) => {
        return { ...prevState, saving: false, note: "" };
      });
    }
  };

  const validateData = () => {
    const errors = [];
    if (note.trim() === "") errors.push("Please add a note");
    if (customerRef.trim() === order.customerReference.trim()) errors.push("Customer Reference did not change");
    return errors;
  };

  return (
    <>
      <button className="btn btn-icon btn-sm p-0  h-auto w-auto align-middle mb-1 ml-1" onClick={handleShow}>
        <i className="fa fa-edit text-gray-300 text-hover-white p-0" />
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} size={"lg"} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Change Customer Reference</h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <label className="fs-5 fw-bold mb-2">New Customer Reference</label>
            <Input
              className="fs-6 form-control custom-form-control w-100"
              placeholder="Customer Reference"
              value={customerRef}
              onChange={handleChangeCustomerRef}
            />
          </div>
          <div className="mb-2">
            <label className="fs-5 fw-bold mb-2">Note</label>
            <Textarea
              className="fs-6 form-control custom-form-control"
              placeholder="Add a reason why Customer Reference is changed"
              value={note}
              onChange={handleChangeNote}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline btn-outline-light float-right" onClick={handleHide}>
            Close
          </button>
          <ErrorOverlayButton
            errors={validateData()}
            saving={saving}
            className={"btn btn-sm btn-outline btn-outline-light"}
            buttonText={"Save"}
            onClick={handleSaveCustomerRef}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangeCustomerReferenceModal;
