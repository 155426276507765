import _ from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DataContextInternal } from "../../../../context/dataContext";
import { SupplierExtended } from "../../../../model/supplier.types";
import {
  ArticleCommissionStockRowDisplayMode,
  formatArticleUnit,
  getDefaultCommissionStock,
  InternalArticle,
} from "../../../../utils/productArticleUtils";
import { formatCurrency } from "../../../../utils/baseUtils";
import AddCommissionStockModal from "../modals/AddCommissionStockModal";
import { CommissionStock, CommissionStockNew } from "../../../../model/commonTypes";
import ArticleCommissionStockRow from "../../../commodities/internal/ArticleCommissionStockRow";
import { Commodity } from "../../../../model/commodity.types";
import { FinishedProduct } from "../../../../model/finishedProduct.types";
import { BatchState } from "../../../../model/batch.types";
import { convertCurrency } from "../../../../utils/currencyUtils";

interface SupplierPageCommissionStockProps {
  supplier: SupplierExtended;
}

const SupplierPageCommissionStock: React.FC<SupplierPageCommissionStockProps> = ({ supplier }) => {
  return (
    <>
      <div className="card bg-white mb-5">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 ">Commission Stock</span>
          </h3>
        </div>
        <div className="card-body">
          <div className="card bg-white border-none">
            <div className="row">
              <SupplierCommissionStockSummary supplier={supplier} />
            </div>
          </div>
        </div>
      </div>
      <SupplierCommissionStockList supplier={supplier} />
    </>
  );
};

interface SupplierCommissionStockSummaryProps {
  supplier: SupplierExtended;
}

const SupplierCommissionStockSummary: React.FC<SupplierCommissionStockSummaryProps> = ({ supplier }) => {
  const context = useContext(DataContextInternal);
  const { commodity, finishedProduct, batch, currencies } = context;

  const { available, ordered } = useMemo(() => {
    const commissionStockMapAvailable: { [key: string]: number } = {};
    const commissionStockMapOrdered: { [key: string]: number } = {};

    const generateAvailableAndOrderedAmount = (collection: Array<Commodity> | Array<FinishedProduct>) => {
      for (let i = 0; i < collection.length; i++) {
        const c = collection[i];
        if (!c.commissionStocks || c.commissionStocks.length === 0) continue;
        for (let j = 0; j < c.commissionStocks.length; j++) {
          const cS = c.commissionStocks[j];
          if (cS.supplier !== supplier._id.toString()) continue;
          if (c.unit in commissionStockMapAvailable) commissionStockMapAvailable[c.unit] += cS.remainingAmount;
          else commissionStockMapAvailable[c.unit] = cS.remainingAmount;
          if (cS.ordered) {
            if (c.unit in commissionStockMapOrdered)
              commissionStockMapOrdered[c.unit] += cS.totalAmount - cS.remainingAmount;
            else commissionStockMapOrdered[c.unit] = cS.totalAmount - cS.remainingAmount;
          }
        }
      }
    };

    generateAvailableAndOrderedAmount(commodity);
    generateAvailableAndOrderedAmount(finishedProduct);

    return {
      available: Object.keys(commissionStockMapAvailable).map((e) => ({
        unit: e,
        amount: commissionStockMapAvailable[e],
      })),
      ordered: Object.keys(commissionStockMapOrdered).map((e) => ({ unit: e, amount: commissionStockMapOrdered[e] })),
    };
  }, [commodity, finishedProduct, supplier]);

  const relevantBatches = useMemo(() => {
    return batch.filter(
      (b) =>
        b.commissionData &&
        b.supplier === supplier._id.toString() &&
        b.stockedDate < new Date() &&
        b.packages.some((p) => p.amountEach > 0)
    );
  }, [batch, supplier._id]);

  const interest = useMemo(() => {
    let interest = 0;
    for (let i = 0; i < relevantBatches.length; i++) {
      const b = relevantBatches[i];
      if (b.commissionData) {
        for (let j = 0; j < b.commissionData.interest.length; j++) {
          const cD = b.commissionData.interest[j];
          interest += convertCurrency(cD.interest, b.commissionData.price.currency, supplier.currency, currencies);
        }
      }
    }
    return interest;
  }, [relevantBatches, supplier.currency, currencies]);

  return (
    <div className="card border-0 bg-custom-medium-gray w-100 p-5 mt-0 mb-10">
      <div className="row">
        <div className="col-6 col-md-4">
          <div className="text-white fs-6 text-center mb-2">Available</div>
          <div className="badge badge-lg bg-color-10 d-block fs-6">
            {available.length > 0
              ? available.map((aCS, idx) => (
                  <div key={idx} className="mb-1">
                    {aCS.amount} {formatArticleUnit(aCS.unit)}
                  </div>
                ))
              : "0 kg"}
          </div>
        </div>
        <div className="col-6 col-md-4">
          <div className="text-white fs-6 text-center mb-2">Ordered</div>
          <div className="badge badge-lg bg-color-10 d-block fs-6">
            {ordered.length > 0
              ? ordered.map((oCS, idx) => (
                  <div key={idx}>
                    {oCS.amount} {formatArticleUnit(oCS.unit)}
                  </div>
                ))
              : "0 kg"}
          </div>
        </div>
        <div className="col-6 col-md-4">
          <div className="text-white fs-6 text-center mb-2">Interest</div>
          <div className="badge badge-lg bg-color-10 d-block fs-6">{formatCurrency(interest, supplier.currency)}</div>
        </div>
      </div>
    </div>
  );
};

interface SupplierCommissionStockListProps {
  supplier: SupplierExtended;
}

const SupplierCommissionStockList: React.FC<SupplierCommissionStockListProps> = ({ supplier }) => {
  const context = useContext(DataContextInternal);
  const { commodity, finishedProduct } = context;

  const [commissionStocks, setCommissionStocks] = useState<
    Array<{ article: InternalArticle; commissionStock: CommissionStock | CommissionStockNew }>
  >([]);

  const handleAddCommissionStock = useCallback(
    (article: InternalArticle) => {
      const aCS = _.cloneDeep(commissionStocks);
      aCS.unshift({ article, commissionStock: { ...getDefaultCommissionStock(supplier._id.toString()), new: true } });
      setCommissionStocks(aCS);
    },
    [supplier, commissionStocks]
  );

  const handleChangeInput = useCallback(
    (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name, type } = e.target;
      const comStock = _.cloneDeep(commissionStocks);
      const idx = comStock.findIndex((c) => c.commissionStock._id.toString() === id);
      if (idx === -1) return;
      _.set(comStock[idx].commissionStock, name, type === "number" ? Number(value) : value);
      setCommissionStocks(comStock);
    },
    [commissionStocks]
  );

  const handleChangeCurrency = useCallback(
    (id: string, e: React.ChangeEvent<HTMLSelectElement>) => {
      const comStock = _.cloneDeep(commissionStocks);
      const idx = comStock.findIndex((c) => c.commissionStock._id.toString() === id);
      if (idx === -1) return;
      _.set(comStock[idx].commissionStock, "price.currency", e.target.value);
      setCommissionStocks(comStock);
    },
    [commissionStocks]
  );

  const handleRemoveCommissionStock = useCallback(
    (id: string) => {
      const aCS = _.cloneDeep(commissionStocks);
      setCommissionStocks(aCS.filter((a) => a.commissionStock._id.toString() !== id));
    },
    [commissionStocks]
  );

  useEffect(() => {
    const articleCommissionStock: Array<{
      article: InternalArticle;
      commissionStock: CommissionStock | CommissionStockNew;
    }> = [];
    for (let i = 0; i < commodity.length; i++) {
      const c = commodity[i];
      if (c.commissionStocks && c.commissionStocks.length > 0) {
        for (let j = 0; j < c.commissionStocks.length; j++) {
          const cS = c.commissionStocks[j];
          if (cS.supplier !== supplier._id.toString()) continue;
          articleCommissionStock.push({ article: c, commissionStock: cS });
        }
      }
    }
    for (let i = 0; i < finishedProduct.length; i++) {
      const fp = finishedProduct[i];
      if (fp.commissionStocks && fp.commissionStocks.length > 0) {
        for (let j = 0; j < fp.commissionStocks.length; j++) {
          const cS = fp.commissionStocks[j];
          if (cS.supplier !== supplier._id.toString()) continue;
          articleCommissionStock.push({ article: fp, commissionStock: cS });
        }
      }
    }
    setCommissionStocks(articleCommissionStock);
  }, [commodity, finishedProduct, supplier]);

  const { active, completed } = useMemo(() => {
    const active: Array<{ article: InternalArticle; commissionStock: CommissionStock }> = [];
    const completed: Array<{ article: InternalArticle; commissionStock: CommissionStock }> = [];
    for (let i = 0; i < commissionStocks.length; i++) {
      const cS = commissionStocks[i];
      if ("new" in cS.commissionStock || cS.commissionStock.remainingAmount > 0) active.push(cS);
      else completed.push(cS);
    }
    return { active, completed };
  }, [commissionStocks]);

  return (
    <>
      <div className="card bg-white mb-5">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Active Commission Stocks</span>
            <span className="text-muted fw-bold fs-7">{active.length} Entries</span>
          </h3>
        </div>
        <div className="card-body p-4 pt-4">
          {active.map((aCS) => (
            <ArticleCommissionStockRow
              key={aCS.commissionStock._id.toString()}
              article={aCS.article}
              displayMode={ArticleCommissionStockRowDisplayMode.ARTICLE}
              commissionStock={aCS.commissionStock}
              onChangeInput={handleChangeInput}
              onChangeCurrency={handleChangeCurrency}
              onRemoveCommissionStock={handleRemoveCommissionStock}
            />
          ))}
          <div className="pt-2">
            <AddCommissionStockModal onAddCommissionStock={handleAddCommissionStock} />
          </div>
        </div>
      </div>
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Completed Commission Stocks</span>
            <span className="text-muted fw-bold fs-7">{completed.length} Entries</span>
          </h3>
        </div>
        <div className="card-body p-4 pt-4">
          {completed.map((cCS) => (
            <ArticleCommissionStockRow
              key={cCS.commissionStock._id.toString()}
              article={cCS.article}
              displayMode={ArticleCommissionStockRowDisplayMode.ARTICLE}
              commissionStock={cCS.commissionStock}
              readonly={true}
              onChangeInput={handleChangeInput}
              onChangeCurrency={handleChangeCurrency}
              onRemoveCommissionStock={handleRemoveCommissionStock}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SupplierPageCommissionStock;
