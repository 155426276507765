import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import CustomerOrderSummary from "../../common/CustomerOrderSummary";
import CustomerOrderSupplierOrder from "./CustomerOrderSupplierOrder";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import { CustomerOrder, CustomerOrderExtended } from "../../../../model/customerOrder.types";
import { SupplierOrder } from "../../../../model/supplierOrder.types";
import CustomerOrderTabPanel from "./CustomerOrderTabPanel";
import { CUSTOMERORDER, getDocumentDB } from "../../../../services/dbService";
import CustomerOrderContract from "./CustomerOrderContract";
import { CustomerContract } from "../../../../model/customerContract.types";
import { DataContextInternalType } from "../../../../context/dataContext";

import { extendCustomerOrder } from "../../../../utils/dataTransformationUtils";
import OrderSummary from "../../customer/customerOrder/OrderSummary";

interface CustomerOrderParams {
  id: string;
}

interface CustomerOrderPageProps extends RouteComponentProps<CustomerOrderParams> {
  context: DataContextInternalType;
}

interface CustomerOrderPageState {
  order?: CustomerOrderExtended;
  supplierOrder?: SupplierOrder;
  contract?: CustomerContract;
}

class CustomerOrderPage extends PureComponent<CustomerOrderPageProps, CustomerOrderPageState> {
  _isMounted = false;
  constructor(props: CustomerOrderPageProps) {
    super(props);
    const order = this.getCustomerOrder(props);
    this.state = {
      order,
      supplierOrder: this.getRelatedSupplierOrder(order),
      contract: this.getRelatedContract(order),
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.order) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/orders");
      return;
    }
    this._isMounted = true;
    const order = await getDocumentDB<CustomerOrder>(CUSTOMERORDER, id);
    if (!order) {
      console.error("No order could be loaded for id", id);
      toast.error("The requested order could not be loaded");
      history.push("/orders");
      return;
    }
    context.addDocuments(CUSTOMERORDER, [order]);
    const orderExtended = extendCustomerOrder(order, context);
    if (this._isMounted) this.setState({ order: orderExtended });
  };

  componentDidUpdate(prevProps: Readonly<CustomerOrderPageProps>, prevState: Readonly<CustomerOrderPageState>) {
    const { match, context } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const order = this.getCustomerOrder(this.props);
      if (order && !_.isEqual(order, this.state.order)) {
        this.setState({
          order,
          supplierOrder: this.getRelatedSupplierOrder(order),
          contract: this.getRelatedContract(order),
        });
      } else if (
        !_.isEqual(prevState.order, this.state.order) ||
        !_.isEqual(context.supplierOrder, prevProps.context.supplierOrder) ||
        !_.isEqual(context.customerContract, prevProps.context.customerContract)
      ) {
        this.setState({
          supplierOrder: this.getRelatedSupplierOrder(this.state.order),
          contract: this.getRelatedContract(this.state.order),
        });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Retrieve the supplier order related to the customer order.
   * @param order Order that should be checked
   * @returns {SupplierOrder | undefined} Related supplier order if found
   */
  getRelatedSupplierOrder = (order?: CustomerOrderExtended): SupplierOrder | undefined => {
    const { context } = this.props;
    return order
      ? context.supplierOrder.find((sO) => sO.customerOrders.some((cO) => cO === order._id.toString()))
      : undefined;
  };

  /**
   * Retrieve the customer contract related to the customer order.
   * @param order Order that should be checked
   * @returns {CustomerContract | undefined} Related customer contract if found
   */
  getRelatedContract = (order?: CustomerOrderExtended): CustomerContract | undefined => {
    const { context } = this.props;
    return order && "contractInformation" in order
      ? context.customerContract.find((cC) => cC._id.toString() === order.contractInformation?._id.toString())
      : undefined;
  };

  /**
   * Get the initial company data
   * @param props the components properties
   * @returns {CustomerOrderExtended | undefined} a company object or undefined
   */
  getCustomerOrder = (props: CustomerOrderPageProps): CustomerOrderExtended | undefined => {
    const order = getDocFromCollection(props.context.customerOrder, props.match.params.id);
    if (order) return extendCustomerOrder(order, props.context);
  };

  render() {
    const { context } = this.props;
    const { order, supplierOrder, contract } = this.state;
    if (!order) return <div />;

    return (
      <div>
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="d-flex flex-column flex-xl-row">
                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10 order-1 order-xl-0">
                  <CustomerOrderSummary
                    order={order}
                    supplierOrder={supplierOrder}
                    contract={contract}
                    context={context}
                  />
                  <CustomerOrderContract context={context} contract={contract} />
                  <CustomerOrderSupplierOrder supplierOrder={supplierOrder} context={context} />
                  <OrderSummary order={order} context={context} />
                </div>
                <CustomerOrderTabPanel order={order} context={context} supplierOrder={supplierOrder} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerOrderPage;
