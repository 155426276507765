import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import countryList from "i18n-iso-countries";
import CustomerView from "./views/CustomerView";
import InternalView from "./views/InternalView";
import SupplierView from "./views/SupplierView";
import MobileView from "./views/MobileView";
import authService, { DEFAULT_SESSION_KEY_NAME } from "../services/authService";
import userService from "../services/userService";
import MissingRoleView from "./views/MissingRoleView";
import ErrorBoundary from "../components/common/ErrorBoundary";
import AnonymousView from "./views/AnonymousView";
import { ADMIN, ANONYMOUS, BOM, CUSTOMER, INTERNAL, SUPPLIER } from "../utils/userUtils";

countryList.registerLocale(require("i18n-iso-countries/langs/en.json"));

interface BasePageProps {
  cookies: Cookies;
}

interface BasePageState {
  innerWidth: number;
}

class BasePage extends Component<BasePageProps, BasePageState> {
  constructor(props: BasePageProps) {
    super(props);
    this.state = { innerWidth: window.innerWidth };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    // Check for api key cookie and create new one if old one got deleted
    this.listenCookieChange(1000);
  }

  handleResize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  /**
   * Periodically check if the api key cookie exists
   * @param interval time in ms to wait between callback invocation
   */
  listenCookieChange = (interval = 1000) => {
    const { cookies } = this.props;
    setInterval(async () => {
      if (userService.getUserData()?.onboardingDone) {
        const cookie = cookies?.get(DEFAULT_SESSION_KEY_NAME);
        if (!cookie) {
          console.error("Authentication cookie was deleted. Attempting to restore.");
          await authService.createAndSetApiKey(cookies, DEFAULT_SESSION_KEY_NAME);
        }
      }
    }, interval);
  };

  renderView = (viewCase: string) => {
    switch (viewCase) {
      case CUSTOMER:
        return <CustomerView />;
      case INTERNAL:
        return <InternalView />;
      case SUPPLIER:
        return <SupplierView />;
      case ANONYMOUS:
        return <AnonymousView />;
      default:
        return <MissingRoleView />;
    }
  };

  render() {
    const { innerWidth } = this.state;
    const roles = userService.getRoles();
    const canAccessMobileView =
      roles.includes(ADMIN) || roles.includes(BOM) || [CUSTOMER, ANONYMOUS].includes(userService.getUserType());
    if (innerWidth < 1024 && !canAccessMobileView) {
      return <MobileView />;
    } else {
      const userType = userService.getUserType(); // "customer" || "internal" || "supplier" || "anonymous"
      return <ErrorBoundary>{this.renderView(userType)}</ErrorBoundary>;
    }
  }
}

export default withCookies(BasePage);
