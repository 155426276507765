import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  CC_STATE,
  CustomerContractExtended,
  CustomerContractTimelineType,
} from "../../../../model/customerContract.types";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import {
  getContractNumber,
  getCustomerContractTimelineEntry,
  updateCustomerContract,
} from "../../../../utils/customerContractUtils";

interface CustomerContractReadyModalProps {
  contract: CustomerContractExtended;
}

interface CustomerContractReadyModalState {
  show: boolean;
  saving: boolean;
}

class CustomerContractReadyModal extends PureComponent<
  CustomerContractReadyModalProps,
  CustomerContractReadyModalState
> {
  constructor(props: CustomerContractReadyModalProps) {
    super(props);
    this.state = {
      show: false,
      saving: false,
    };
  }

  handleShow = () => this.setState({ show: true });
  handleHide = () => this.setState({ show: false });

  handleReadyContract = async () => {
    const { contract } = this.props;
    if (contract.state !== CC_STATE.OPEN) return;
    this.setState({ saving: true });
    try {
      const update = { state: CC_STATE.READY, arrivalDate: new Date() };
      const timelineEntry = getCustomerContractTimelineEntry(CustomerContractTimelineType.READY);
      const result = await updateCustomerContract(update, contract._id, timelineEntry);
      if (result && result.res && result.res.modifiedCount) {
        toast.success("Contract is now ready");
        this.setState({ show: false });
      } else {
        toast.error("Contract could not be updated. Please try again later.");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { contract } = this.props;
    const { show, saving } = this.state;
    const disabled = contract.state !== CC_STATE.OPEN;
    return (
      <>
        <li className="nav-item ml-2">
          <button
            className={"btn btn-light " + (disabled ? "disabled" : "")}
            disabled={disabled}
            title={disabled ? "Contract is already in progress" : undefined}
            onClick={disabled ? undefined : this.handleShow}
          >
            Ready
          </button>
        </li>
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} centered size="lg">
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                Mark {getContractNumber(contract)} As Ready
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="text-white fs-5 mt-10 mb-5">
              <div>
                Are you sure you want to mark the contract for{" "}
                <em>
                  {contract.contractInformation.totalAmount}
                  {contract.commodity.unit}
                </em>{" "}
                of <em>{contract.commodity.title.en}</em> as ready?
              </div>
              <div>
                Afterwards <em>{contract.company.name}</em> may create a call-off at any time.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-text-white btn-sm" onClick={this.handleHide}>
              Close
            </button>
            <ErrorOverlayButton
              errors={[]}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Confirm"
              saving={saving}
              onClick={this.handleReadyContract}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default CustomerContractReadyModal;
