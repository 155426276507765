import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { CustomerOrder } from "../../../model/customerOrder.types";
import { getOrderNumber, getOrderStateDescriptions } from "../../../utils/orderUtils";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import { getAmountForOrderOrContract, getLinkForOrderOrContract } from "../../../utils/contractAndOrderUtils";
import CreateCustomerContractCallModal from "../common/CreateCustomerContractCallModal";
import { CustomerContractExtended } from "../../../model/customerContract.types";
import { CustomerCustomerContractExtended } from "../../../model/customer/customerCustomerContract.types";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
} from "../../../context/dataContext";
import { getWeeksBetween } from "../../../utils/dateUtils";

interface CustomerContractCallsProps {
  calls?: Array<CustomerOrder | CustomerCustomerOrder>;
  contract: CustomerContractExtended | CustomerCustomerContractExtended;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface CustomerContractCallsState {}

class CustomerContractCalls extends PureComponent<CustomerContractCallsProps, CustomerContractCallsState> {
  render() {
    const { calls, contract, context } = this.props;

    return (
      <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
        <div className="card-body">
          <span className="fs-2 text-gray-800 fw-bolder">Call-Offs</span>
          <div className="card bg-light p-4 my-8">
            <div className="d-flex justify-content-around">
              <div className="text-center">
                <div className="fw-bold fs-6 text-gray-400">
                  {contract.contractInformation.restAmount.toLocaleString()}kg
                </div>
                <span className="text-muted mb-1">remaining</span>
              </div>
              <div className="text-center">
                <div className="fw-bold fs-6 text-gray-400">
                  {contract.contractInformation.minimumCallQuantity.toLocaleString()}kg
                </div>
                <span className="text-muted mb-1">MOQ</span>
              </div>
              <div className="text-center">
                <div className="fw-bold fs-6 text-gray-400">
                  {Math.round(getWeeksBetween(contract.validityPeriod.start, contract.validityPeriod.end) / 4) +
                    " Months"}
                </div>
                <span className="text-muted mb-1">remaining</span>
              </div>
            </div>
          </div>
          {calls &&
            (calls.length > 0 ? (
              calls.map((c) => (
                <Link key={c._id.toString()} to={getLinkForOrderOrContract(c)}>
                  <div key={c._id.toString()} className="fs-6 card bg-light my-4 p-4">
                    <div className="d-flex justify-content-between">
                      <div className="fw-bolder">{getOrderNumber(c)}</div>
                      <div className="text-muted">
                        <small>{c.createdAt.toLocaleDateString()}</small>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="text-success">
                        <small>{getOrderStateDescriptions(c).title}</small>
                      </div>
                      <div className="fw-bolder">{getAmountForOrderOrContract(c)}</div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="pb-5 fs-6 text-center">
                <div className=" mt-10 text-muted">No call-offs yet</div>
              </div>
            ))}
          <CreateCustomerContractCallModal contract={contract} context={context} />
        </div>
      </div>
    );
  }
}

export default CustomerContractCalls;
