import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import BaseListing, { BaseListingHeaderDefinition } from "../../common/BaseListing";
import { DataContextInternal } from "../../../context/dataContext";
import { PaginationState } from "../../common/Pagination";
import AddNotify from "./modals/AddNotify";
import { formatAddress, getAddressProperty } from "../../../utils/addressUtils";
import { PropertyType } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import Tooltip from "../../common/Tooltip";

interface NotifySettingProps {}

interface NotifySettingState extends PaginationState {}

class NotifySetting extends PureComponent<NotifySettingProps, NotifySettingState> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;

  constructor(props: NotifySettingProps) {
    super(props);
    this.state = { pageSize: 25, currentPage: 1 };
  }

  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });

  render() {
    const headerDefinition: Array<BaseListingHeaderDefinition> = [
      { title: "Company name", style: { width: "20%" } },
      { title: "Contact Person", style: { width: "10%" } },
      { title: "Address", style: { width: "30%" } },
      { title: "Telephone", style: { width: "15%" } },
      { title: "Mail", style: { width: "10%" } },
      { title: "Edit", style: { width: "15%" }, className: "text-right" },
    ];

    const { pageSize, currentPage } = this.state;

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Notifies</span>
                  <div className="float-right">
                    <Link to="/settings">
                      <button className="btn btn-text text-white">Back</button>
                    </Link>
                    <AddNotify edit={false} context={this.context} />
                  </div>
                </h3>
                <BaseListing
                  headerDefinition={headerDefinition}
                  bodyContent={
                    <>
                      {this.context.notify.map((n) => {
                        const addressProps = getAddressProperty(
                          n.address,
                          PropertyType.DELIVERYINSTRUCTIONS,
                          this.context,
                          true
                        ) as Array<Property>;
                        return (
                          <tr key={n._id.toString()}>
                            <td className="text-white align-middle">{n.companyName}</td>
                            <td className="text-white align-middle">{n.contactPerson}</td>
                            <td className="text-white align-middle">
                              {addressProps && addressProps.length > 0 ? (
                                <Tooltip
                                  tooltipText={
                                    <div className="text-white text-left">
                                      <div className="fw-bolder fs-7">Delivery instructions:</div>
                                      {addressProps.map((prop) => (
                                        <div key={prop._id.toString()}>{prop.name.en}</div>
                                      ))}
                                    </div>
                                  }
                                >
                                  <span> {formatAddress(n.address, { withoutCountry: true })}</span>
                                </Tooltip>
                              ) : (
                                formatAddress(n.address, { withoutCountry: true })
                              )}
                            </td>
                            <td className="text-white align-middle">{n.telephone}</td>
                            <td className="text-white align-middle">{n.email}</td>
                            <td className="text-white align-middle text-right">
                              <AddNotify edit={true} notify={n} context={this.context} />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  }
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotifySetting;
