import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import {
  formatCurrency,
  formatDate,
  getDocFromCollection,
  toAbsoluteUrl,
  truncateString,
} from "../../../utils/baseUtils";
import { DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import { getDaysUntil } from "../../../utils/dateUtils";
import { I_CREDITNOTECUSTOMER, I_CUSTOMERINVOICE, I_STATE, Invoice } from "../../../model/invoice.types";
import CreateCustomerAccountModal from "../../userData/anonymous/CreateCustomerAccountModal";
import AnonymousBlurText from "../../common/anonymous/AnonymousBlurText";
import { EURO } from "../../../utils/currencyUtils";
import { Input } from "../../common/Input";
import { getOrderNumber } from "../../../utils/orderUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { I_REMINDERDESCRIPTION } from "../../../utils/invoiceUtils";

interface InvoiceCardElementProps extends RouteComponentProps {
  onClickCheckbox?: (_id: string) => void;
  checked?: boolean;
  invoice: Invoice;
  isCreditNote: boolean;
  context: DataContextCustomerType;
}

const InvoiceCardElement: React.FunctionComponent<InvoiceCardElementProps> = ({
  onClickCheckbox,
  checked,
  invoice,
  isCreditNote,
  context,
}) => {
  const handleClickFileAnonymous = () => {
    (context.refMap["CreateCustomerAccountModal"] as CreateCustomerAccountModal).handleShow();
  };

  const due = new Date(invoice.invoiceDate);
  due.setDate(due.getDate() + invoice.paymentTarget);
  const untilPayment = Math.ceil(getDaysUntil(due));
  const inAdvance = invoice.paymentTarget === -1;
  const dueDate = new Date(invoice.invoiceDate);
  dueDate.setDate(dueDate.getDate() + invoice.paymentTarget);
  const relatedOrder = invoice.relatedOrder
    ? [I_CUSTOMERINVOICE, I_CREDITNOTECUSTOMER].includes(invoice.type)
      ? getDocFromCollection(context.customerOrder, invoice.relatedOrder)
      : getDocFromCollection(context.sampleOrder, invoice.relatedOrder)
    : undefined;

  const isAV = isAnonymousContext(context);

  return (
    <>
      <div className="col-12 d-flex flex-row justify-content-between align-items-center mb-2">
        {checked !== undefined && onClickCheckbox && (
          <div className="d-flex align-items-center">
            <div className="form-check form-check-sm form-check-custom form-check-solid">
              <Input
                type="checkbox"
                className="form-check-input"
                checked={checked}
                onClick={() => onClickCheckbox(invoice._id.toString())}
              />
            </div>
            <div className="text-left ml-5">
              <div className="text-white mb-0 fs-5 h5">
                {isCreditNote ? "C" : `INV-${inAdvance ? "A" : ""}`}
                {invoice.invoiceNumber}
              </div>
            </div>
          </div>
        )}
        <div className="text-right">
          <div className="text-white mb-0 fs-5 h5">
            {isAV ? (
              <AnonymousBlurText>{formatCurrency(1234.56, EURO)}</AnonymousBlurText>
            ) : (
              formatCurrency(invoice.total, invoice.currency)
            )}
          </div>
        </div>
      </div>
      <div className="col-12 text-left mb-2">
        {relatedOrder && (
          <Link className="custom-link text-white" to={"/commodity/" + relatedOrder.commodity._id.toString()}>
            <span className="text-white fs-5 d-inline-block">
              {truncateString(relatedOrder.commodity.title.en, 40)}
            </span>
          </Link>
        )}
      </div>
      <div className="col-4 text-left">Reference</div>
      <div className="col-8 text-right mb-2">
        {relatedOrder && (
          <Link className="custom-link text-white" to={"/order/" + relatedOrder._id.toString()}>
            {getOrderNumber(relatedOrder)}
          </Link>
        )}
      </div>
      {!isCreditNote && (
        <>
          <div className="col-4 text-left">Date</div>
          <div className="col-8 text-right mb-2">{formatDate(invoice.invoiceDate)}</div>
          <div className="col-4 text-left">Payment</div>
          <div className="col-8 text-right mb-2">{inAdvance ? "In Advance" : invoice.paymentTarget + " days"}</div>
          <div className="col-4 text-left">Remaining</div>
          <div
            className={
              "col-8 text-right mb-2 " +
              (inAdvance || [I_STATE.PAID, I_STATE.CANCELED].includes(invoice.state)
                ? "text-muted "
                : untilPayment > 0
                ? "text-success"
                : "text-danger")
            }
          >
            {inAdvance || [I_STATE.PAID, I_STATE.CANCELED].includes(invoice.state) ? "-" : untilPayment + " day(s)"}
          </div>
          <div className="col-4 text-left">File</div>
          <div className="col-8 text-right mb-2">
            {isAV ? (
              <span className="pointer" onClick={handleClickFileAnonymous}>
                <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
              </span>
            ) : (
              <a href={resolveFilePath(invoice.file)} target="_blank" rel="noopener noreferrer">
                <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
              </a>
            )}
          </div>
          <div className="col-4 text-left">Status</div>
          <div
            className={
              "col-8 text-right mb-2 " +
              (invoice.state === I_STATE.CANCELED
                ? "text-danger"
                : invoice.state === I_STATE.PAID
                ? "text-white"
                : "text-warning")
            }
          >
            {invoice.state === I_STATE.CANCELED ? "Canceled" : invoice.state === I_STATE.PAID ? "Paid" : "Open"}
          </div>
        </>
      )}
      {!isCreditNote && (
        <>
          {invoice.reminders.map((r) => {
            const due = new Date(r.date);
            due.setDate(due.getDate() + 14);
            const remaining = Math.ceil(getDaysUntil(due));
            return (
              <>
                <hr />
                <div className="col-12 mb-2">
                  <div className="text-white fs-5 h5">
                    {I_REMINDERDESCRIPTION.find((rd) => rd.value === r.type)?.label}
                  </div>
                </div>
                <div className="col-4 text-left">Amount</div>
                <div className="col-8 text-right mb-2">
                  {r.dunningFee ? formatCurrency(r.dunningFee, invoice.currency) : ""}
                </div>
                <div className="col-4 text-left">Date</div>
                <div className="col-8 text-right mb-2">{formatDate(r.date)}</div>
                <div className="col-4 text-left">Payment</div>
                <div className="col-8 text-right mb-2">14 days</div>
                <div className="col-4 text-left">Remaining</div>
                <div className={"col-8 text-right mb-2 " + (remaining >= 0 ? "text-success" : "text-danger")}>
                  {remaining} day(s)
                </div>
                <div className="col-4 text-left">File</div>
                <div className="col-8 text-right mb-2">
                  <a href={resolveFilePath(r.reminderFile)} target="_blank" rel="noopener noreferrer">
                    <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
                  </a>
                </div>
              </>
            );
          })}
          {invoice.payments.length > 0 && (
            <>
              <hr />
              <div className="col-12 mb-2">
                <div className="text-white fs-5 h5">Payments</div>
              </div>
            </>
          )}
          {invoice.payments.map((p) => (
            <>
              <div className="col-4 text-left">Amount</div>
              <div className="col-8 text-right mb-2">{formatCurrency(p.amount, invoice.currency)}</div>
              <div className="col-4 text-right">Date</div>
              <div className="col-8 text-right mb-2">{formatDate(p.date)}</div>
              <div className="col-4 text-left">File</div>
              <div className="col-8 text-right mb-2">
                <img
                  src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                  style={{ height: 24 }}
                  className="image-gray"
                  alt="PDF"
                />
              </div>
            </>
          ))}
          {invoice.cancelation && (
            <>
              <hr />
              <div className="col-12 mb-2">
                <div className="text-white fs-5 h5">Cancellation</div>
              </div>
              <div className="col-4 text-right">Date</div>
              <div className="col-8 text-right mb-2">{formatDate(invoice.cancelation.date)}</div>
              <div className="col-4 text-left">File</div>
              <div className="col-8 text-right mb-2">
                <a
                  href={resolveFilePath(invoice.cancelation.cancelationFile)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} style={{ height: 24 }} alt="PDF" />
                </a>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(InvoiceCardElement);
