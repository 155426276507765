import React, { useCallback, useMemo, useState } from "react";
import { ConfigurationRow } from "./GraduationSettings";
import Pagination, { paginate } from "../../../common/Pagination";
import Search from "../../../common/Search";
import { doFuseSearch } from "../../../../utils/baseUtils";
import GraduationCard, { GraduationReference } from "./GraduationCard";
import { CALC_AIRFREIGHT, CALC_SEAFREIGHT } from "../../../../model/supplierOrder.types";

interface CategoryGraduationConfigurationProps {
  defaultGraduation: Array<number>;
  onDeleteGraduationEntry: (idx: number, reference?: GraduationReference) => void;
  onAddGraduation: (reference?: GraduationReference) => void;
  onChangeGraduation: (e: React.ChangeEvent<HTMLInputElement>, idx: number, reference?: GraduationReference) => void;
  onSaveChanges: (reference: GraduationReference, idx?: number) => Promise<void>;
  onDeleteGraduation: (reference: GraduationReference) => Promise<void>;
  rows: Array<ConfigurationRow>;
  tab: typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT;
  saving: boolean;
}

interface CategoryGraduationConfigurationState {
  currentPage: number;
  pageSize: number;
  rowCols: number;
  search: string;
}

const CategoryGraduationConfiguration: React.FunctionComponent<CategoryGraduationConfigurationProps> = ({
  defaultGraduation,
  onDeleteGraduationEntry,
  onDeleteGraduation,
  onAddGraduation,
  onChangeGraduation,
  onSaveChanges,
  rows,
  tab,
  saving,
}) => {
  const [{ currentPage, pageSize, rowCols, search }, setState] = useState<CategoryGraduationConfigurationState>({
    currentPage: 1,
    pageSize: 15,
    rowCols: 4,
    search: "",
  });

  const handleChangeCurrentPage = useCallback((page: number) => {
    setState((prevState) => {
      return {
        ...prevState,
        currentPage: page,
      };
    });
  }, []);

  const handleChangePageSize = useCallback((pageSize: number) => {
    setState((prevState) => {
      return {
        ...prevState,
        currentPage: 1,
        pageSize: pageSize,
      };
    });
  }, []);

  const handleChangeRowSize = useCallback((rowSize: number) => {
    setState((prevState) => {
      return {
        ...prevState,
        currentPage: 1,
        rowCols: rowSize,
      };
    });
  }, []);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.trim();
    setState((prevState) => {
      return {
        ...prevState,
        search: searchValue,
        currentPage: 1,
      };
    });
  }, []);

  const filteredRows = useMemo(() => {
    return search ? doFuseSearch(rows, search, ["relatedProperty.name.en", "relatedProperty.description.en"]) : rows;
  }, [search, rows, tab]);

  const tabReference: GraduationReference = useMemo(() => {
    return {
      type: "default",
      tab: tab,
    };
  }, [tab]);

  return (
    <>
      <div className="row">
        <div className="col-xl-3">
          <GraduationCard
            title="Default"
            graduations={defaultGraduation}
            reference={tabReference}
            onChangeGraduation={onChangeGraduation}
            onAddGraduation={onAddGraduation}
            onDeleteGraduation={onDeleteGraduationEntry}
          >
            <button
              className={"btn btn-text-success btn-sm float-right " + (saving && "disabled")}
              onClick={() => onSaveChanges(tabReference)}
              disabled={saving}
            >
              Save
            </button>
          </GraduationCard>
        </div>
      </div>
      <hr style={{ color: "white" }} />
      <div className="card mb-2 mt-5 bg-white border-none bg-custom-medium-gray p-2">
        <div className="row g-8 mb-4">
          <div className="col-12 col-sm-6">
            <label className="fs-6 form-label fw-bolder text-dark">Search Query</label>
            <Search value={search} onSearch={handleSearch} placeholder={"Search for categories..."} />
          </div>
        </div>
      </div>
      <div className={"row row-cols-" + rowCols}>
        {paginate(filteredRows, currentPage, pageSize).map((category) => {
          const graduationReference: GraduationReference = {
            type: "property",
            tab: tab,
            id: category.id,
          };
          return (
            <div key={category.id} className="mt-2">
              <GraduationCard
                title={category.relatedProperty.name.en}
                description={category.relatedProperty.description.en}
                reference={graduationReference}
                graduations={category.graduation}
                onChangeGraduation={onChangeGraduation}
                onAddGraduation={onAddGraduation}
                onDeleteGraduation={onDeleteGraduationEntry}
                isFullyDeletable
              >
                <>
                  <button
                    className={"btn btn-text-success btn-sm float-right " + (saving && "disabled")}
                    onClick={() => onSaveChanges(graduationReference)}
                    disabled={saving}
                  >
                    Save
                  </button>
                  <button
                    className={"btn btn-text-danger btn-sm float-right " + (saving && "disabled")}
                    onClick={() => onDeleteGraduation(graduationReference)}
                    disabled={saving}
                  >
                    Delete
                  </button>
                </>
              </GraduationCard>
            </div>
          );
        })}
      </div>
      <Pagination
        itemsCount={rows.length}
        pageSize={pageSize}
        baseSize={15}
        onPageChange={handleChangeCurrentPage}
        currentPage={currentPage}
        onPageSizeChange={handleChangePageSize}
        additionalSize={{
          onAdditionalSizeChange: handleChangeRowSize,
          baseSize: 4,
          size: rowCols,
          sizeOptions: [3],
          sizeDescription: "Columns",
        }}
      />
    </>
  );
};

export default CategoryGraduationConfiguration;
