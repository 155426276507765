import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import validator from "validator";
import { CompanyExtended } from "../../../../model/company.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import CustomerPageGeneralInformationBlock from "../CustomerPageGeneralInformationBlock";
import { CompanyMails, LabelDesign, updateCompany } from "../../../../utils/companyUtils";
import { SelectOption } from "../../../common/CustomSelect";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import ContactsCard from "../../../common/ContactsCard";
import { getDefaultAddress, isAddressTooShort } from "../../../../utils/addressUtils";
import { I_PAYMENTTARGETS } from "../../../../utils/invoiceUtils";
import { AddressType } from "../../../../model/commonTypes";
import { CUSTOMERORDERMARGIN, getGeneralValue } from "../../../../utils/generalUtils";
import CreatePropertyModal from "../../../properties/internal/modals/CreatePropertyModal";
import { ADDRESSPROPERTYOPTIONS, PropertyType } from "../../../../utils/propertyUtils";
import userService from "../../../../services/userService";
import { reduceCompany } from "../../../../utils/dataTransformationUtils";

interface CustomerPageGeneralProps {
  company: CompanyExtended;
  context: DataContextInternalType;
}

interface CustomerPageGeneralState {
  edit: boolean;
  companyEdit: CompanyExtended;
  showPropertyCreationModal: boolean;
  customPaymentTarget: boolean;
}

class CustomerPageGeneral extends PureComponent<CustomerPageGeneralProps, CustomerPageGeneralState> {
  constructor(props: CustomerPageGeneralProps) {
    super(props);
    const isCustom = !I_PAYMENTTARGETS.some((pt) =>
      props.company.paymentTerms
        ? pt.label === props.company.paymentTerms?.paymentTarget
        : pt.value === props.company.paymentTarget?.toString()
    );
    const companyEdit = _.cloneDeep(props.company);
    if (!companyEdit.mails) companyEdit.mails = { invoice: "" };
    this.state = {
      edit: false,
      companyEdit,
      showPropertyCreationModal: false,
      customPaymentTarget: isCustom,
    };
  }

  handleEditToggle = () => {
    const companyEdit = _.cloneDeep(this.props.company);
    if (!companyEdit.mails) companyEdit.mails = { invoice: "" };
    this.setState({ edit: !this.state.edit, companyEdit });
  };

  handleAddAddress = () => {
    const company = _.cloneDeep(this.state.companyEdit);
    company.address.push(getDefaultAddress());
    this.setState({ companyEdit: company });
  };

  handleDeleteAddress = (id: BSON.ObjectId) => {
    const company = _.cloneDeep(this.state.companyEdit);
    company.address = company.address.filter((a) => a._id.toString() !== id.toString());
    this.setState({ companyEdit: company });
  };

  handleChangeOpeningHours = (e: React.ChangeEvent<HTMLTextAreaElement>, idx: number) => {
    const company = _.cloneDeep(this.state.companyEdit);
    if (!company || !company.address[idx]) return;
    company.address[idx].openingHours = e.target.value;
    this.setState({ companyEdit: company });
  };

  handleEditCompanySelectCountry = (e: SelectOption, idx: number) => {
    const company = _.cloneDeep(this.state.companyEdit);
    company.address[idx].country = e.value;
    this.setState({ companyEdit: company });
  };

  handleEditCompanySelectContact = (e: SelectOption, idx: number) => {
    const company = _.cloneDeep(this.state.companyEdit);
    company.address[idx].contactPerson = e.value;
    this.setState({ companyEdit: company });
  };

  handleEditCompanySelectProperty = (e: Array<SelectOption>, idx: number) => {
    const company = _.cloneDeep(this.state.companyEdit);
    if (e.some((option) => option.value === "new")) {
      this.setState({ showPropertyCreationModal: true });
    } else {
      company.address[idx].properties = e.map((option) => option.value);
      this.setState({ companyEdit: company });
    }
  };

  handleEditCompanySelect = (name: string, e: SelectOption) => {
    const company = _.cloneDeep(this.state.companyEdit);
    if (name === "paymentTarget") {
      if (e.value !== "custom") {
        // if value is custom do not set it for company.paymentTarget, since this is type number and a deprecated value
        // also preloading it somewhere with a custom value could result in errors
        _.set(company, name, +e.value);
      }
      company.paymentTerms = {
        paymentTarget: e.value !== "custom" ? e.label : "",
        paymentTargetConditions: company.paymentTerms?.paymentTargetConditions || "",
      };
      this.setState({ companyEdit: company, customPaymentTarget: e.value === "custom" });
    } else if (name === "cleanLabel") {
      company.cleanLabel = e.value === LabelDesign.CLEAN;
      this.setState({ companyEdit: company });
    } else {
      _.set(company, name, e.value);
      this.setState({ companyEdit: company, customPaymentTarget: false });
    }
  };

  handleEditCustomPaymentTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
    const company = _.cloneDeep(this.state.companyEdit);
    // change value of paymentTarget too, might not be preloaded correctly for example in invoice
    company.paymentTarget = +e.currentTarget.value;
    company.paymentTerms = {
      ...company.paymentTerms,
      paymentTarget: e.target.value,
    };
    this.setState({ companyEdit: company });
  };

  handleEditCustomPaymentTargetConditions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const company = _.cloneDeep(this.state.companyEdit);
    company.paymentTerms = {
      paymentTarget: company.paymentTerms?.paymentTarget ? company.paymentTerms?.paymentTarget : "",
      paymentTargetConditions: e.target.value,
    };
    this.setState({ companyEdit: company });
  };

  handleEditRating = (rating: number) => {
    const company = _.cloneDeep(this.state.companyEdit);
    company.rating = rating;
    this.setState({ companyEdit: company });
  };

  handleEditCompanyInput = (e: React.ChangeEvent<HTMLInputElement>, field?: string) => {
    const company = _.cloneDeep(this.state.companyEdit);
    const targetName = field ?? e.target.name;
    _.set(company, targetName, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ companyEdit: company });
  };

  handleEditCompanyMails = (e: React.ChangeEvent<HTMLInputElement>, key: CompanyMails.INVOICE) => {
    const company = _.cloneDeep(this.state.companyEdit);
    if (!company.mails) company.mails = { invoice: "" };
    company.mails[key] = e.target.value;
    this.setState({ companyEdit: company });
  };

  handleActiveCustomer = async () => {
    const { company } = this.props;
    const result = await updateCompany({ activated: true, internalContact: userService.getUserId() }, company._id);
    if (result && result.modifiedCount > 0) {
      toast.success("Customer activated successfully");
    } else {
      toast.error("Error activating customer");
    }
  };

  handleCompanyDisabledFlag = async (disable: boolean) => {
    const { company } = this.props;
    const result = await updateCompany({ disabled: disable }, company._id);
    if (result && result.modifiedCount > 0) {
      toast.success("Customer " + (disable ? "disabled" : "enabled") + " successfully");
    } else {
      toast.error("Error " + (disable ? "disabling" : "enabling") + " customer");
    }
  };

  handleSaveCompany = async () => {
    const { context } = this.props;
    const { companyEdit } = this.state;
    if (!companyEdit) return;
    if (companyEdit.paymentTerms) {
      companyEdit.paymentTerms.paymentTarget =
        companyEdit.paymentTerms.paymentTarget.includes("days") ||
        companyEdit.paymentTerms.paymentTarget === I_PAYMENTTARGETS[0].label ||
        companyEdit.paymentTerms.paymentTarget === I_PAYMENTTARGETS[1].label
          ? companyEdit.paymentTerms.paymentTarget
          : companyEdit.paymentTerms.paymentTarget + " days";
    }

    // If a discount higher that the margin was entered we reduce it to that level
    if (companyEdit.discount) {
      const customerOrderMargin = Number(getGeneralValue(context.general, CUSTOMERORDERMARGIN) || 10);
      if (companyEdit.discount > customerOrderMargin) companyEdit.discount = customerOrderMargin;
    }

    // If no address is primary designate the first one as primary
    if (!companyEdit.address.find((a) => a.type === AddressType.A_PRIMARY)) {
      companyEdit.address[0].type = AddressType.A_PRIMARY;
    }

    const result = await updateCompany(reduceCompany(companyEdit));
    if (result && result.modifiedCount > 0) {
      toast.success("Customer data updated successfully");
      this.setState({ edit: false });
    } else {
      toast.error("Error updating customer");
    }
  };

  validateCompanyData = () => {
    const { companyEdit } = this.state;
    const { name, mail, phone, vat, address } = companyEdit;
    const errors: Array<string> = [];
    if (name.trim().length < 3) errors.push("Company name too short");
    if (isAddressTooShort(address[0])) errors.push("Address too short");
    if (!vat) errors.push("VAT not set");
    if (!address[0].country) errors.push("No country selected");
    if (phone.trim().length < 3) errors.push("Phone number too short");
    if (!validator.isEmail(mail)) errors.push("Email is not valid");
    return errors;
  };

  render() {
    const { company, context } = this.props;
    const { edit, companyEdit, showPropertyCreationModal, customPaymentTarget } = this.state;
    const errors = this.validateCompanyData();
    return (
      <>
        <div className="card bg-white">
          {edit && (
            <CreatePropertyModal
              onlyModal={true}
              show={showPropertyCreationModal}
              onHide={() => this.setState({ showPropertyCreationModal: false })}
              type={
                ADDRESSPROPERTYOPTIONS.find((po) => po.value === PropertyType.DELIVERYINSTRUCTIONS) as {
                  value: PropertyType;
                  label: string;
                }
              }
            />
          )}
          <div className="card-header border-0 mt-5">
            <div className="card-title flex-column">
              <h2 className="mb-1">General Information</h2>
              <div className="fs-6 fw-bold text-muted">{company.name}</div>
            </div>
            <div className="pt-3">
              <div className="d-flex pt-3 align-items-center w-100">
                <>
                  {edit && (
                    <button
                      className={"btn btn-outline btn-outline-light btn-sm float-right"}
                      onClick={this.handleEditToggle}
                    >
                      Cancel
                    </button>
                  )}
                  {edit ? (
                    <ErrorOverlayButton
                      className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                      errors={errors}
                      buttonText={"Save Customer"}
                      onClick={this.handleSaveCompany}
                    />
                  ) : (
                    <button
                      className={"btn btn-outline btn-outline-light btn-sm float-right ml-2"}
                      onClick={this.handleEditToggle}
                    >
                      Edit Customer
                    </button>
                  )}
                </>
              </div>
            </div>
          </div>
          <div className="card-body p-9 pt-4">
            <div className="pt-0">
              <CustomerPageGeneralInformationBlock
                company={edit ? companyEdit : company}
                edit={edit}
                customPaymentTarget={customPaymentTarget}
                onEditCompanyInput={this.handleEditCompanyInput}
                onEditCompanyMails={this.handleEditCompanyMails}
                onEditCompanySelect={this.handleEditCompanySelect}
                onEditRating={this.handleEditRating}
                onHandleChangeOpeningHours={this.handleChangeOpeningHours}
                onAddAddress={this.handleAddAddress}
                onDeleteAddress={this.handleDeleteAddress}
                onEditCompanySelectCountry={this.handleEditCompanySelectCountry}
                onEditCompanySelectContact={this.handleEditCompanySelectContact}
                onEditCompanySelectProperty={this.handleEditCompanySelectProperty}
                onEditCustomPaymentTarget={this.handleEditCustomPaymentTarget}
                onEditCustomPaymentTargetConditions={this.handleEditCustomPaymentTargetConditions}
                context={context}
              />
              <div className="border-bottom-dark-gray" />
              <div className="pt-3">
                <div className="d-flex pt-3 align-items-center w-100">
                  {!company.activated && (
                    <button className="btn btn-text btn-text-success btn-sm" onClick={this.handleActiveCustomer}>
                      Activate Customer
                    </button>
                  )}
                  {company.disabled ? (
                    <button
                      className="btn btn-text btn-text-success btn-sm ml-auto"
                      onClick={() => this.handleCompanyDisabledFlag(false)}
                    >
                      Enable Customer
                    </button>
                  ) : (
                    <button
                      className="btn btn-text btn-text-danger btn-sm ml-auto"
                      onClick={() => this.handleCompanyDisabledFlag(true)}
                    >
                      Disable Customer
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <ContactsCard company={company} type={"company"} />
        </div>
      </>
    );
  }
}

export default CustomerPageGeneral;
