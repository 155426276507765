import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { SupplierOrder } from "../../../model/supplierOrder.types";
import {
  calculateArrivalInformation,
  getLastStateUpdate,
  getOrderNumber,
  getOrderProgress,
  getOrderStateDescriptions,
  getOrderType,
  INTERNAL_ORDER_TYPES,
  isCustomerOrder,
  isOrderCanceled,
  isSampleOrder,
  isSupplierOrder,
} from "../../../utils/orderUtils";
import { DataContextInternal } from "../../../context/dataContext";
import { getDaysBetween, getTimeDiffString } from "../../../utils/dateUtils";
import HoverPopover from "../../common/HoverPopover";
import { getDocFromCollection, pluralize } from "../../../utils/baseUtils";
import { formatArticleUnit } from "../../../utils/productArticleUtils";
import { CustomerOrder } from "../../../model/customerOrder.types";

interface OrderRowProps extends RouteComponentProps {
  order: INTERNAL_ORDER_TYPES;
}

const OrderRow: React.FC<OrderRowProps> = ({ order, history }) => {
  const context = useContext(DataContextInternal);
  const { supplierOrder, supplier, company } = context;

  const [supplierOrders, setSupplierOrders] = useState<Array<SupplierOrder>>([]);

  useEffect(() => {
    setSupplierOrders(supplierOrder.filter((sO) => sO.customerOrders.some((cO) => cO === order._id.toString())));
  }, [supplierOrder, order._id]);

  const forwardOrder = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      e.stopPropagation();
      history.push(
        `/${
          isCustomerOrder(order) ? "customerOrder" : isSampleOrder(order) ? "sampleOrder" : "supplierOrder"
        }/${order._id.toString()}`
      );
    },
    [order, history]
  );

  // No useMemo by intention since compiler is not realizing that this defined the type of order when useMemo is used
  const sampleOrder = isSampleOrder(order);
  const stateDescription = useMemo(() => getOrderStateDescriptions(order), [order]);
  const arrival = useMemo(() => calculateArrivalInformation(order), [order]);
  const limitTimeLeft = useMemo(
    () =>
      isCustomerOrder(order) && order.request && order.limitTimeFrame
        ? getDaysBetween(
            new Date(order.createdAt.getTime() + order.limitTimeFrame * 24 * 60 * 60 * 1000),
            new Date(),
            true
          )
        : null,
    [order]
  );
  const orderProgress = useMemo(() => getOrderProgress(order), [order]);
  const companyDocument = useMemo(
    () =>
      isSupplierOrder(order)
        ? getDocFromCollection(supplier, order.supplier)
        : getDocFromCollection(company, order.company),
    [order, supplier, company]
  );

  return (
    <tr className="cursor-pointer-row" onClick={forwardOrder}>
      <td className="align-middle">
        <div className="text-white fs-5 text-ellipsis" style={{ maxWidth: "25rem" }}>
          {order.amount}
          {formatArticleUnit(order.unit, order.commodity)} {order.commodity.title.en}
        </div>
        <div className="text-muted fs-7">
          {(isCustomerOrder(order) || isSampleOrder(order)) && "customerReference" in order && order.customerReference
            ? order.customerReference + " - " + companyDocument?.name
            : companyDocument?.name}
        </div>
        <span className="text-white fs-7">{getOrderNumber(order)}</span>
      </td>
      <td className="text-muted align-middle">
        <OrdersReferences order={order} supplierOrders={supplierOrders} />
      </td>
      <td className="text-light align-middle">
        <div className="text-warning">{stateDescription.title}</div>
        <div className="text-muted">{getTimeDiffString(getLastStateUpdate(order)?.date)}</div>
      </td>
      <td className="text-light align-middle">
        <ProgressBar style={{ backgroundColor: "#232323", height: 5 }}>
          <ProgressBar min={0} max={100} now={orderProgress[0]} variant={orderProgress[1]} />
        </ProgressBar>
        <div className="fs-7 text-nowrap text-center">{orderProgress[0]}%</div>
      </td>
      <td className="text-light align-middle">{sampleOrder ? "Sample" : getOrderType(order)}</td>
      <td className="align-middle">
        {!isOrderCanceled(order) &&
          (sampleOrder ? (
            <span className="text-muted">
              CW {arrival.cw}-{arrival.year}
            </span>
          ) : "request" in order && order.request && limitTimeLeft ? (
            <>
              <div className="text-muted">
                {limitTimeLeft > 0 ? limitTimeLeft : 0} {limitTimeLeft === 1 ? "day" : "days"} left
              </div>
              <div className="text-warning">requested</div>
            </>
          ) : (
            <>
              <div className="text-muted">
                CW {arrival.cw}-{arrival.year}
              </div>
              {arrival.target ? (
                <span className={"text-nowrap " + (arrival.late ? "text-danger" : "text-success")}>
                  {pluralize(arrival.weekDiff, "week")} {arrival.late ? "overdue" : "remaining"}
                </span>
              ) : (
                <span className="text-success">delivered</span>
              )}
            </>
          ))}
      </td>
    </tr>
  );
};

interface OrdersReferencesProps {
  order: INTERNAL_ORDER_TYPES;
  supplierOrders: Array<SupplierOrder>;
}

const OrdersReferences: React.FC<OrdersReferencesProps> = ({ order, supplierOrders }) => {
  const context = useContext(DataContextInternal);
  const { customerOrder } = context;

  const link = useMemo(() => (isCustomerOrder(order) ? "/supplierOrder/" : "/customerOrder/"), [order]);
  // There might be canceled orders that are referenced inside supplier orders. Those are not relevant, thus filtered
  const orders = useMemo(
    () =>
      isSupplierOrder(order)
        ? (order.customerOrders
            .map((cO) => getDocFromCollection(customerOrder, cO))
            .filter((o) => Boolean(o)) as Array<CustomerOrder>)
        : supplierOrders,
    [customerOrder, order, supplierOrders]
  );

  return orders.length === 0 ? (
    <span>-</span>
  ) : orders.length > 2 ? (
    <HoverPopover
      popoverStyle={{ maxWidth: "95vw", border: "none" }}
      content={
        <div className="card overflow-auto" style={{ background: "#3f3f3f" }}>
          <div className="card-body">
            <div className="text-white">
              {orders.map((o, idx) => {
                return (
                  <div key={o._id.toString()} className="row" style={{ minWidth: "300px" }}>
                    <div className="col-7 px-2 my-auto fw-bolder fs-7 text-left">
                      <Link
                        className="custom-link text-white"
                        to={`${link}${o._id.toString()}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        Order {getOrderNumber(o)}
                      </Link>
                    </div>
                    <div className="col-5 pr-2 pl-0 my-auto fs-7 text-left">
                      {o.amount + formatArticleUnit(o.unit, o.commodity)}
                    </div>
                    {idx < orders.length - 1 && <div className="border-bottom-dark-gray my-2" />}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }
    >
      <div>
        {orders.slice(0, 2).map((o) => (
          <Link
            key={o._id.toString()}
            className="text-white fs-5 custom-link"
            to={`${link}${o._id.toString()}`}
            onClick={(e) => e.stopPropagation()}
          >
            <span>{getOrderNumber(o)}</span>
          </Link>
        ))}
        <div className="text-muted fs-5">and {orders.length - 2} more</div>
      </div>
    </HoverPopover>
  ) : (
    <div>
      {orders.slice(0, 2).map((o) => (
        <Link
          key={o._id.toString()}
          className="text-white fs-5 custom-link"
          to={`${link}${o._id.toString()}`}
          onClick={(e) => e.stopPropagation()}
        >
          <span>{getOrderNumber(o)}</span>
        </Link>
      ))}
    </div>
  );
};

export default withRouter(OrderRow);
