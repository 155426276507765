import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { COMMODITY, FINISHEDPRODUCT } from "../../services/dbService";
import CreateSupplierOrder from "./CreateSupplierOrder";
import { DataContextInternal } from "../../context/dataContext";

interface CreateCommissionStockSupplierOrderWrapperParams {
  id: string;
  type: typeof COMMODITY | typeof FINISHEDPRODUCT;
}

interface CreateCommissionStockSupplierOrderWrapperProps
  extends RouteComponentProps<CreateCommissionStockSupplierOrderWrapperParams> {}

const CreateCommissionStockSupplierOrderWrapper: React.FunctionComponent<
  CreateCommissionStockSupplierOrderWrapperProps
> = (props) => {
  const context = useContext(DataContextInternal);

  return <CreateSupplierOrder {...props} context={context} fromCommissionStock={true} />;
};

export default withRouter(CreateCommissionStockSupplierOrderWrapper);
