import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";
import { formatDate, formatUnit, truncateString } from "../../../../utils/baseUtils";
import {
  CustomerRequest,
  CustomerRequestState,
  CustomerRequestTimelineTypes,
  CustomerRequestType,
} from "../../../../model/customerRequest.types";
import {
  CR_STATEDESCRIPTION,
  getCustomerRequestTimelineEntry,
  getRequestNumber,
  updateCustomerRequest,
} from "../../../../utils/customerRequestUtils";
import { isFinishedProduct } from "../../../../utils/finishedProductUtils";
import SimpleConfirmationModal from "../../../common/SimpleConfirmationModal";

interface CustomerRequestCardElementProps extends RouteComponentProps {
  customerRequest: CustomerRequest;
}

const CustomerRequestCardElement: React.FunctionComponent<CustomerRequestCardElementProps> = ({ customerRequest }) => {
  const [saving, setSaving] = useState<boolean>(false);

  const handleProcessRequest = useCallback(async () => {
    setSaving(true);
    try {
      const timelineEntry = getCustomerRequestTimelineEntry(CustomerRequestTimelineTypes.CLOSED);
      const res = await updateCustomerRequest(
        { state: CustomerRequestState.CLOSED },
        customerRequest._id,
        timelineEntry
      );
      if (res && res.res.modifiedCount > 0) {
        toast.success("Customer request successfully processed");
      } else {
        toast.error("Error processing customer request");
      }
    } catch (e) {
      console.error(e);
      toast.error("Error processing customer request");
    } finally {
      setSaving(false);
    }
  }, [customerRequest]);

  const type = useMemo(
    () => (customerRequest.type === CustomerRequestType.CONTRACTREQUEST ? "Contract" : "Order"),
    [customerRequest]
  );

  const message = useMemo(
    () =>
      customerRequest.message +
      (customerRequest.customerReference ? ` - Ref: ${customerRequest.customerReference}` : ""),
    [customerRequest]
  );

  return (
    <>
      <div className="col-12 text-left mb-2">
        <div className="text-white fs-5 mb-1 h5">{getRequestNumber(customerRequest)}</div>
      </div>
      <div className="col-4 text-muted mb-2">Type</div>
      <div className="col-8 text-right mb-2">{type}</div>
      <div className="col-4 text-muted">Quantity</div>
      <div className="col-8 text-right mb-2">
        {customerRequest.amount} {formatArticleUnit(customerRequest.commodity.unit, customerRequest.commodity)}
      </div>
      <div className="col-12 text-muted">Article</div>
      <div className="col-12 text-right mb-2">
        <div className="fs-5 text-ellipsis" style={{ maxWidth: "25rem" }}>
          <Link className="custom-link text-white" to={`/customer/${customerRequest.company._id.toString()}`}>
            {customerRequest.company.name}
          </Link>
        </div>
        <div className="fs-7">
          <Link
            className="custom-link text-muted"
            to={`/${
              isFinishedProduct(customerRequest.commodity) ? "finishedProduct" : "commodity"
            }/${customerRequest.commodity._id.toString()}`}
          >
            {customerRequest.commodity.title.en}
          </Link>
        </div>
      </div>
      <div className="col-12 text-muted">Message</div>
      <div className="col-12 text-right mb-2">
        {message.length > 120 ? (
          <small className="text-muted ">{truncateString(message, 120)}</small>
        ) : (
          <small className="text-muted">{message}</small>
        )}
      </div>
      <div className="col-4 text-muted">Status</div>
      <div className="col-8 text-right mb-2">
        <div
          className={
            customerRequest.state === CustomerRequestState.OPEN
              ? "text-warning"
              : customerRequest.state === CustomerRequestState.CLOSED
              ? "text-success"
              : "text-danger"
          }
        >
          {CR_STATEDESCRIPTION[customerRequest.state]}
        </div>
        <small className="text-muted">
          {formatDate(customerRequest.timeline.slice().reverse().at(0)?.date || customerRequest.requestedOn)}
        </small>
      </div>
      <div className="col-4 text-muted">Schedule</div>
      <div className="col-8 text-right">
        <SimpleConfirmationModal.SimpleConfirmationModalButton
          size="md"
          disabled={saving || customerRequest.state !== CustomerRequestState.OPEN}
          modalTitle="Mark Request as Processed"
          onConfirm={handleProcessRequest}
          confirmButtonText="Confirm"
          buttonText={
            <i
              className={
                "fa fa-check pr-0 " +
                (customerRequest.state === CustomerRequestState.OPEN ? "text-success" : "text-muted")
              }
            />
          }
          buttonTooltipText="Process Request"
          buttonClasses="btn btn-text p-0 text-hover-light "
          cancelButtonText="Close"
          modalDescription={
            <span className="text-white fs-6">
              <div>
                Do you really want to mark {getRequestNumber(customerRequest)}:{" "}
                {formatUnit(customerRequest.amount, customerRequest.commodity.unit)} of{" "}
                {customerRequest.commodity.title.en} as processed?
              </div>
            </span>
          }
        />
      </div>
    </>
  );
};

export default withRouter(CustomerRequestCardElement);
