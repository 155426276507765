import { BSON } from "realm-web";
import { CustomerCommodityExtended } from "../model/customer/customerCommodity.types";
import {
  CO_ARCHIVED,
  CO_ARRIVEDATSTARTINGPORT,
  CO_HANDLEDATWAREHOUSE,
  CO_ORDEREDATSUPPLIER,
  CO_ORDEREDBYCUSTOMER,
  CO_SHIPPEDFROMSUPPLIER,
  CO_SHIPPEDTOCUSTOMER,
  CO_SHIPPEDTOWAREHOUSE,
  CO_STATES,
  CO_T_ARCHIVED,
  CO_T_ARRIVEDATSTARTINGPORT,
  CO_T_CREATED,
  CO_T_CUSTOMS,
  CO_T_ORDERED,
  CO_T_SHIPPED,
  CO_T_SHIPPEDCUSTOMER,
  CO_T_SHIPPEDWAREHOUSE,
  CO_T_WAREHOUSE,
  CO_TRANSPORT,
  T_SEAFREIGHT,
} from "../model/customerOrder.types";
import { CustomerCustomerOrder } from "../model/customer/customerCustomerOrder.types";
import { getDefaultAddress } from "./addressUtils";
import { Company } from "../model/company.types";
import userService from "../services/userService";
import { UserData } from "../model/userData.types";
import { INTERNAL } from "./userUtils";
import { getCustomerOrderTimelineEntry, getOrderStateRanking } from "./customerOrderUtils";
import { CustomerSupplierOrder } from "../model/customer/customerSupplierOrder.types";
import { SO_ARRIVEDATSTARTINGPORT } from "../model/supplierOrder.types";
import { Seaport } from "../model/seaport.types";
import { Airport } from "../model/airport.types";
import { CustomerFinishedProductExtended } from "../model/customer/customerFinishedProduct.types";
import { getArticleSnapshot } from "./productArticleUtils";
import { callFunction } from "../services/dbService";
import { AnonymousConfiguration } from "../model/configuration/anonymousConfiguration.types";
import { CustomerSampleOrder } from "../model/customer/customerSampleOrder.types";
import {
  SAMO_STATE,
  SAMO_T_ARCHIVED,
  SAMO_T_CANCELED,
  SAMO_T_CONFIRMED,
  SAMO_T_CREATED,
  SAMO_T_REJECTED,
  SAMO_T_SHIPPED,
} from "../model/sampleOrder.types";
import { CommoditySnapshot } from "../model/commodity.types";
import { EURO } from "./currencyUtils";
import { getSampleOrderTimelineEntry } from "./sampleOrderUtils";
import { Address } from "../model/commonTypes";
import { I_CUSTOMERINVOICE, I_STATE, Invoice } from "../model/invoice.types";
import { CustomerCustomerContract } from "../model/customer/customerCustomerContract.types";
import { CC_STATE, CustomerContractTimelineType } from "../model/customerContract.types";
import { getCustomerContractTimelineEntry } from "./customerContractUtils";
import { DateType, getOrderNumber } from "./orderUtils";
import { CustomerRequest, CustomerRequestState, CustomerRequestType } from "../model/customerRequest.types";
import { DAY_IN_MS } from "./dateUtils";
import { getCompanySnapshot } from "./companyUtils";

// function names
const GENERATEANDSENDEMAILCONFIRMATION = "generateAndSendEmailConfirmation";
const CHECKCONFIRMATIONCODEANDCREATECOMPANY = "checkConfirmationCodeAndCreateCompany";

export const cOStatesForDemoData: Array<CO_STATES> = [
  CO_ORDEREDBYCUSTOMER,
  CO_ORDEREDATSUPPLIER,
  CO_ARRIVEDATSTARTINGPORT,
  CO_SHIPPEDFROMSUPPLIER,
  CO_SHIPPEDTOWAREHOUSE,
  CO_HANDLEDATWAREHOUSE,
  CO_SHIPPEDTOCUSTOMER,
  CO_ARCHIVED,
];

// Will only work on PROD - in case you need this locally insert the data of your application
// Since access should be publicly available we can justify exposing this credentials
export const ANONYMOUS_EMAIL = "anonymous@rawbids.com";
export const ANONYMOUS_PASSWORD = "Rawbids_Anonymous_Access";

/**
 * Generate the generic address of the anonymous user
 * @returns {Address} Generic address
 */
function getGenericAddress(): Address {
  const address = getDefaultAddress("DE");
  address.street = "Generic Street";
  address.houseNo = "42";
  address.postalCode = "89098";
  address.city = "Anonymoucity";
  return address;
}

/**
 * Generate a customer order for the given article with the given orderNo, transport mode and state.
 * @param article Article that should be ordered
 * @param orderNo Number of the order
 * @param transport Transport mode of the order
 * @param state State the order should be set to
 * @param amount Optional, amount the order should have - needed for contract calls
 * @returns {{co: CustomerCustomerOrder, so: CustomerSupplierOrder | undefined}} Generated customer order with related
 *  supplier order if the states requires a supplier order
 */
export function generateCustomerOrder(
  article: CustomerCommodityExtended | CustomerFinishedProductExtended,
  orderNo: number,
  transport: CO_TRANSPORT,
  state: CO_STATES,
  amount?: number
): { co: CustomerCustomerOrder; so: CustomerSupplierOrder | undefined; inv: Invoice | undefined } {
  const snapshot = getArticleSnapshot(article);
  const orderNoString = orderNo.toString().padStart(6, "0");
  const orderOid = orderNo.toString().padStart(24, "0");
  const destination = getGenericAddress();
  const cco: CustomerCustomerOrder = {
    _id: new BSON.ObjectId(orderOid),
    orderNo: "1" + orderNoString,
    createdAt: new Date(),
    targetDate: new Date(),
    destination,
    noteCustomer: "",
    customerReference:
      "Ord No. " +
      Math.ceil(Math.random() * 10000)
        .toString()
        .padEnd(4, "0"),
    state,
    company: getAnonymousCompany()._id.toString(),
    person: userService.getUserId(),
    commodity: snapshot,
    amount: amount ?? Math.ceil(Math.random() * 20) * 25,
    unit: "kg",
    priceCommodities: 0,
    services: [],
    priceServices: 0,
    totalPrice: 0,
    currency: "EUR",
    timeline: [],
    files: [],
    transport,
    terms: {
      paymentTerms: "30 days",
      paymentTermConditions: "after delivery",
      deliveryTerms: "DDP",
      deliveryCity: "Berlin",
    },
  };
  const ranking = getOrderStateRanking(cco);
  const createdAt = new Date();
  createdAt.setDate(
    createdAt.getDate() -
      (transport === T_SEAFREIGHT ? Math.random() * 20 + 7 * ranking : Math.random() * 10 + 2 * ranking)
  );
  cco.createdAt = createdAt;
  const targetDate = new Date();
  targetDate.setDate(
    targetDate.getDate() +
      7 +
      (7 - ranking) * (transport === T_SEAFREIGHT ? 14 : 3) +
      Math.floor(Math.random() * (transport === T_SEAFREIGHT ? 4 : 2)) * 7
  );
  cco.targetDate = targetDate;

  const tCreated = getCustomerOrderTimelineEntry(CO_T_CREATED);
  tCreated.date = new Date(createdAt);
  cco.timeline.push(tCreated);
  let so;
  let inv;

  if (ranking >= 0.5) {
    const t = getCustomerOrderTimelineEntry(CO_T_ORDERED);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + 1);
    cco.timeline.push(t);
  }
  if (ranking >= 1.5) {
    const t = getCustomerOrderTimelineEntry(CO_T_ARRIVEDATSTARTINGPORT);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + 3);
    cco.timeline.push(t);
    so = generateSupplierOrder(cco._id.toString(), cco.transport, cco.amount);
    const destinationPort: Seaport | Airport =
      transport === T_SEAFREIGHT
        ? { _id: new BSON.ObjectId(), name: "Hamburg", locode: "DEHAM", country: "DE", disabled: false }
        : { _id: new BSON.ObjectId(), name: "Frankfurt", iata: "FRA", icao: "EDDF", country: "DE", disabled: false };
    so.terms = { notify: "000000000000000000000000" };
    so.shipment[0].shipping.destination = destinationPort;
  }
  if (ranking >= 2) {
    const t = getCustomerOrderTimelineEntry(CO_T_SHIPPED);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + 5);
    cco.timeline.push(t);
  }
  if (ranking >= 3) {
    const t = getCustomerOrderTimelineEntry(CO_T_CUSTOMS);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5));
    cco.timeline.push(t);
  }
  if (ranking >= 4) {
    const t = getCustomerOrderTimelineEntry(CO_T_SHIPPEDWAREHOUSE);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5) + 2);
    cco.timeline.push(t);
  }
  if (ranking >= 5) {
    const t = getCustomerOrderTimelineEntry(CO_T_WAREHOUSE);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5) + 3);
    cco.timeline.push(t);
  }
  if (ranking >= 7) {
    const t = getCustomerOrderTimelineEntry(CO_T_SHIPPEDCUSTOMER);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5) + 4);
    cco.timeline.push(t);
    inv = generateInvoice(cco);
  }
  if (ranking >= 8) {
    const t = getCustomerOrderTimelineEntry(CO_T_ARCHIVED);
    t.date = new Date(createdAt);
    t.date.setDate(t.date.getDate() + (transport === T_SEAFREIGHT ? 42 : 5) + 5);
    cco.timeline.push(t);
  }

  return { co: cco, so, inv };
}

/**
 * Generates a minimal supplier order that is needed for properly showing data inside the customer view
 * @param customerOrder Related customer order
 * @param transport Transport mode
 * @param amount Amount that is ordered
 * @returns {CustomerSupplierOrder} Prepared supplier order
 */
function generateSupplierOrder(customerOrder: string, transport: CO_TRANSPORT, amount: number): CustomerSupplierOrder {
  const startingPort: Seaport | Airport =
    transport === T_SEAFREIGHT
      ? { _id: new BSON.ObjectId(), name: "Shanghai", locode: "CNSHA", country: "CN", disabled: false }
      : { _id: new BSON.ObjectId(), name: "Beijing", iata: "BJS", icao: "ZBAA", country: "CN", disabled: false };
  return {
    _id: new BSON.ObjectId(),
    customerOrders: [customerOrder],
    transport,
    shipment: [
      {
        _id: new BSON.ObjectId(),
        amount,
        state: SO_ARRIVEDATSTARTINGPORT,
        timeline: [],
        shipping: { trackingNumber: "", startingPoint: startingPort },
      },
    ],
  };
}

/**
 * Generates a customer contract with the given information
 * @param article Article that should be used for the contract
 * @param contractNo Contract number
 * @param state State the contract should be in
 * @returns { {cc: CustomerCustomerContract; co: Array<CustomerCustomerOrder>} } Contract and Calls
 */
export function generateCustomerContract(
  article: CustomerCommodityExtended | CustomerFinishedProductExtended,
  contractNo: number,
  state: CC_STATE
): { cc: CustomerCustomerContract; co: Array<CustomerCustomerOrder> } {
  // Add some variety to the validity period
  const variety = Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 28);
  const cc: CustomerCustomerContract = {
    _id: new BSON.ObjectId(),
    noteCustomer: "",
    customerReference: `Order ${new Date().getTime().toString().slice(-4)}`,
    contractNo: "4" + contractNo.toString().padStart(4, "0"),
    state,
    company: getAnonymousCompany()._id.toString(),
    commodity: getArticleSnapshot(article),
    calls: [],
    contractInformation: { minimumCallQuantity: 42, restAmount: 500, totalAmount: 500 },
    createdAt: new Date(),
    files: [],
    timeline: [],
    person: userService.getUserId(),
    priceInformation: { totalPrice: 0, currency: EURO, purchasePriceCommodities: 0 },
    terms: { deliveryTerms: "", paymentTerms: "" },
    validityPeriod: {
      start: new Date(new Date().getTime() - variety),
      end: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 - variety),
    },
  };

  const now = new Date().getTime();

  const cCreated = getCustomerContractTimelineEntry(CustomerContractTimelineType.CREATED);
  cCreated.date = new Date(now - DAY_IN_MS * (7 + Math.random() * 14));
  cc.timeline.push(cCreated);

  const co: Array<CustomerCustomerOrder> = [];
  switch (state) {
    case CC_STATE.ORDERED: {
      cc.contractInformation.restAmount = cc.contractInformation.restAmount / 2;
      cc.targetDate = new Date(now + DAY_IN_MS * 60);
      cc.timeline[0].date = new Date(now - DAY_IN_MS * (35 + Math.random() * 21));
      const cO = generateCustomerOrder(
        article,
        Number(`101${contractNo}${co.length + 1}`),
        T_SEAFREIGHT,
        CO_ORDEREDATSUPPLIER,
        cc.contractInformation.totalAmount / 2
      ).co;
      cO.contractInformation = { _id: cc._id, contractNo: cc.contractNo };
      co.push(cO);
      cc.calls.push({
        _id: cO._id,
        amount: cO.amount,
        unit: cO.unit,
        createdAt: cO.createdAt,
        customerReference: cO.customerReference,
        orderNo: cO.orderNo,
        transport: cO.transport,
      });
      cc.timeline.push(getCustomerContractTimelineEntry(CustomerContractTimelineType.ORDERED));
      cc.timeline.push(
        getCustomerContractTimelineEntry(CustomerContractTimelineType.CALLED, { name: getOrderNumber(cO) })
      );
      break;
    }
    case CC_STATE.CLOSED: {
      cc.contractInformation.restAmount = 0;
      cc.targetDate = new Date(now + DAY_IN_MS * 60);
      cc.arrivalDate = new Date(now + DAY_IN_MS * 60);
      cc.timeline[0].date = new Date(now - DAY_IN_MS * (70 + Math.random() * 21));
      const cO1 = generateCustomerOrder(
        article,
        Number(`101${contractNo}${co.length + 1}`),
        T_SEAFREIGHT,
        CO_ORDEREDATSUPPLIER,
        cc.contractInformation.totalAmount / 2
      ).co;
      cO1.contractInformation = { _id: cc._id, contractNo: cc.contractNo };
      co.push(cO1);
      cc.calls.push({
        _id: cO1._id,
        amount: cO1.amount,
        unit: cO1.unit,
        createdAt: cO1.createdAt,
        customerReference: cO1.customerReference,
        orderNo: cO1.orderNo,
        transport: cO1.transport,
      });
      const cO2 = generateCustomerOrder(
        article,
        Number(`101${contractNo}${co.length + 1}`),
        T_SEAFREIGHT,
        CO_ORDEREDATSUPPLIER,
        cc.contractInformation.totalAmount / 2
      ).co;
      cO2.contractInformation = { _id: cc._id, contractNo: cc.contractNo };
      co.push(cO2);
      cc.calls.push({
        _id: cO2._id,
        amount: cO2.amount,
        unit: cO2.unit,
        createdAt: cO2.createdAt,
        customerReference: cO2.customerReference,
        orderNo: cO2.orderNo,
        transport: cO2.transport,
      });
      const calledCo1 = getCustomerContractTimelineEntry(CustomerContractTimelineType.CALLED, {
        name: getOrderNumber(cO1),
      });
      calledCo1.date = new Date(cO1.timeline[0].date.getTime() - 1);
      cc.timeline.push(calledCo1);
      const calledCo2 = getCustomerContractTimelineEntry(CustomerContractTimelineType.CALLED, {
        name: getOrderNumber(cO2),
      });
      calledCo2.date = new Date(cO2.timeline[0].date.getTime() - 1);
      cc.timeline.push(calledCo2);
      cc.timeline.push(getCustomerContractTimelineEntry(CustomerContractTimelineType.CLOSED));
      break;
    }
  }
  return { cc, co };
}

/**
 * Generate a demo invoice based upon the given customer order
 * @param customerOrder Customer Order which should be base for the invoice
 * @returns {Invoice} Demo Invoice
 */
function generateInvoice(customerOrder: CustomerCustomerOrder): Invoice {
  return {
    _id: new BSON.ObjectId(),
    company: getCompanySnapshot(getAnonymousCompany()),
    type: I_CUSTOMERINVOICE,
    relatedOrder: customerOrder._id.toString(),
    currency: customerOrder.currency,
    invoiceDate: new Date(customerOrder.createdAt.getTime() + 1000 * 60 * 60 * 24 * 14),
    invoiceNumber:
      "100" +
      Math.floor(Math.random() * 100)
        .toString()
        .padEnd(3, "0"),
    state: I_STATE.OPEN,
    subtotal: customerOrder.totalPrice,
    file: "",
    payments: [],
    reverseCharge: false,
    reminders: [],
    total: customerOrder.totalPrice * 1.19,
    nonEU: false,
    paymentTarget: 30,
    positions: [
      {
        _id: new BSON.ObjectId(),
        price: customerOrder.totalPrice / customerOrder.amount,
        total: customerOrder.totalPrice,
        vatPercentage: 19,
        unit: customerOrder.commodity.unit,
        quantity: customerOrder.amount,
        title: customerOrder.commodity.title.en,
        description: customerOrder.commodity.subtitle.en,
      },
    ],
  };
}

/**
 * Generates the company for the anonymous user.
 * @returns {Company} Anonymous Company
 */
export function getAnonymousCompany(): Company {
  return {
    _id: new BSON.ObjectId("000000000000000000000000"),
    name: "Anonymous Company",
    activated: true,
    vat: "",
    mail: "anonymous@example.com",
    address: [],
    creditLimit: 0,
    disabled: false,
    notes: "",
    phone: "",
    rating: 5,
    primaryPerson: userService.getUserId(),
    internalContact: getAnonymousInternalContact()._id.toString(),
    persons: [userService.getUserId()],
  };
}

/**
 * Generate a demo sample order based upon the given data
 * @param article Articles for which a sample order should be generated
 * @param orderNo Suffix for the sample order number
 * @param state State the sample order should have
 * @returns {CustomerSampleOrder} Demo Sample Order
 */
export function generateSampleOrder(
  article: CustomerCommodityExtended,
  orderNo: number,
  state: SAMO_STATE
): CustomerSampleOrder {
  const samo: CustomerSampleOrder = {
    _id: new BSON.ObjectId(),
    orderNo: "2" + orderNo,
    state,
    createdAt: new Date(new Date().getTime() - (DAY_IN_MS + Math.random() * DAY_IN_MS * 3)),
    company: getAnonymousCompany()._id.toString(),
    person: userService.getUserId(),
    amount:
      article.sampleSize && article.sampleSize.length > 0
        ? article.sampleSize[Math.floor(Math.random() * article.sampleSize.length)].amount
        : 25,
    unit: article.unit,
    commodity: getArticleSnapshot(article) as CommoditySnapshot,
    currency: EURO,
    files: [],
    destination: getGenericAddress(),
    timeline: [getSampleOrderTimelineEntry(SAMO_T_CREATED)],
    totalPrice: 0,
    targetDate: new Date(new Date().getTime() + DAY_IN_MS * 7),
    noteCustomer: "",
    customerReference: `Order ${new Date().getTime().toString().slice(-4)}`,
  };
  switch (samo.state) {
    case SAMO_STATE.CONFIRMED:
      samo.timeline.push(getSampleOrderTimelineEntry(SAMO_T_CONFIRMED));
      break;
    case SAMO_STATE.REJECTED:
      samo.timeline.push(getSampleOrderTimelineEntry(SAMO_T_REJECTED));
      break;
    case SAMO_STATE.SHIPPED:
      samo.timeline.push(getSampleOrderTimelineEntry(SAMO_T_CONFIRMED));
      samo.timeline.push(getSampleOrderTimelineEntry(SAMO_T_SHIPPED));
      break;
    case SAMO_STATE.CANCELED:
      samo.timeline.push(getSampleOrderTimelineEntry(SAMO_T_CANCELED));
      break;
    case SAMO_STATE.ARCHIVED:
      samo.timeline.push(getSampleOrderTimelineEntry(SAMO_T_CONFIRMED));
      samo.timeline.push(getSampleOrderTimelineEntry(SAMO_T_SHIPPED));
      samo.timeline.push(getSampleOrderTimelineEntry(SAMO_T_ARCHIVED));
      break;
  }
  return samo;
}

/**
 * Generates a customer request with the given information
 * @param article Article that should be used for the customer request
 * @param requestNo Request number
 * @param state State the customer request should have
 * @returns {CustomerRequest} Demo Customer Request
 */
export function generateCustomerRequest(
  article: CustomerCommodityExtended | CustomerFinishedProductExtended,
  requestNo: number,
  state: CustomerRequestState
): CustomerRequest {
  const anonCompany = getAnonymousCompany();
  const variety = Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 28);
  return {
    _id: new BSON.ObjectId(),
    amount: 25 + Math.floor(Math.random() * 10) * 25,
    commodity: getArticleSnapshot(article),
    company: { _id: anonCompany._id, name: anonCompany.name, snapshotDate: new Date() },
    customerReference: "",
    requestNo: "2" + requestNo,
    requestedOn: new Date(new Date().getTime() - variety),
    state,
    attachments: [], // Irrelevant since never shown in CV
    message: `Order ${new Date().getTime().toString().slice(-4)}`,
    timeline: [], // Irrelevant since never shown in CV
    type: CustomerRequestType.STANDARDREQUEST,
    requestData: {
      targetDate: new Date(new Date().getTime() + variety),
      targetDateType: DateType.CW,
      destination: getGenericAddress(),
    },
  };
}

/**
 * Generate the default internal contact for the anonymous view.
 * @returns {UserData} Internal Contact
 */
export function getAnonymousInternalContact(): UserData {
  return {
    _id: new BSON.ObjectId(),
    files: [],
    company: "internal",
    emails: [{ value: "ic@rawbids.com", description: "" }],
    image: "",
    type: INTERNAL,
    notifications: { language: "DE", settings: [] },
    phones: [],
    onboardingDone: true,
    position: "Anonymous Greeter",
    prename: "Anony",
    surname: "Mous",
    roles: [],
    userId: "",
  };
}

/**
 * Calls generateAndSendEmailConfirmation function in backend with the given user.
 * @param user User for which the email should be confirmed
 * @param resend Optional, if set the mail should be sent again
 * @returns {Promise<boolean>}
 */
export async function generateAndSendEmailConfirmation(user: UserData, resend?: boolean): Promise<boolean> {
  return callFunction(GENERATEANDSENDEMAILCONFIRMATION, [user, resend]);
}

/**
 * Calls checkConfirmationCodeAndCreateCompany function in backend and returns the result of the operation
 * @param user User for which confirmation should be run
 * @param company Company of the user
 * @param code Confirmation code user entered
 * @returns {Promise<{ result: boolean; error: string }>} Contains the result status and an error if there was one
 */
export async function checkConfirmationCodeAndCreateCompany(
  user: UserData,
  company: Company,
  code: string
): Promise<{ result: boolean; error: string }> {
  return callFunction(CHECKCONFIRMATIONCODEANDCREATECOMPANY, [user, company, code]);
}

/**
 * Checks if the given article should have prices in the anonymous view
 * @param article ID of the article
 * @param configuration Anonymous configuration
 * @returns {Boolean} Indicating whether the article has prices or not
 */
export function hasPricesInAnonymousView(article: string, configuration: AnonymousConfiguration): boolean {
  return configuration.values.finishedProducts.includes(article) || configuration.values.commodities.includes(article);
}
