import _ from "lodash";
import React from "react";
import CustomSelect, { SelectOption } from "../common/CustomSelect";
import Search from "../common/Search";
import { B_LISTING_VIEWS, B_STATE_OPTIONS, B_WAREHOUSE_OPTIONS } from "../../utils/batchUtils";
import { C_PACKAGE_OPTIONS } from "../../utils/commodityUtils";

interface WarehouseListingFilterProps {
  search: string;
  packageType?: SelectOption;
  state?: SelectOption;
  view: SelectOption;
  warehouse?: SelectOption;
  group: "all" | "organic" | "conventional";
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectOptionChange: (name: string, e: SelectOption | undefined) => void;
  onViewChange: (e: SelectOption) => void;
  onGroupChange: (group: "all" | "organic" | "conventional") => void;
}

const WarehouseListingFilter: React.FunctionComponent<WarehouseListingFilterProps> = ({
  search,
  packageType,
  state,
  view,
  warehouse,
  group,
  onSearch,
  onSelectOptionChange,
  onViewChange,
  onGroupChange,
}) => {
  const stateOptions = _.cloneDeep(B_STATE_OPTIONS);
  if (view && view.value === "single") stateOptions[1].isDisabled = true;
  return (
    <div className="card mb-10 bg-white border-none">
      <div className="row g-8 mb-4">
        <div className="col-12 col-lg-6">
          <Search
            value={search}
            onSearch={onSearch}
            placeholder="Search for commodities, suppliers, batches, places..."
          />
        </div>
        <div className="col-lg-3" />
        <div className="col-lg-3">
          <CustomSelect options={B_LISTING_VIEWS} value={view} onChange={onViewChange} />
        </div>
      </div>
      <div className="row g-8 mb-4 ">
        <div className="col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Warehouse</label>
          <CustomSelect
            options={B_WAREHOUSE_OPTIONS}
            placeholder={"All Locations"}
            value={warehouse}
            isClearable={true}
            onChange={(e: SelectOption | undefined) => onSelectOptionChange("warehouse", e)}
          />
        </div>
        <div className="col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">States</label>
          <CustomSelect
            options={stateOptions}
            placeholder={"All States"}
            value={state}
            isClearable={true}
            onChange={(e: SelectOption | undefined) => onSelectOptionChange("state", e)}
          />
        </div>
        <div className="col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Package Type</label>
          <CustomSelect
            options={C_PACKAGE_OPTIONS}
            value={packageType}
            placeholder={"All Packaging"}
            isClearable={true}
            onChange={(e: SelectOption | undefined) => onSelectOptionChange("packageType", e)}
          />
        </div>
        <div className="col-lg-3">
          <label className="fs-6 form-label fw-bolder text-dark">Commodity Group</label>
          <div className="nav-group nav-group-fluid custom-form-control">
            <button
              onClick={() => onGroupChange("all")}
              className={
                "btn btn-sm btn-color-muted fw-bolder px-4 custom-form-control " +
                (group === "all" ? "custom-form-control-active" : "")
              }
            >
              All
            </button>
            <button
              onClick={() => onGroupChange("organic")}
              className={
                "btn btn-sm fw-bolder px-4 custom-form-control " +
                (group === "organic" ? "custom-form-control-active" : "")
              }
            >
              Organic
            </button>
            <button
              onClick={() => onGroupChange("conventional")}
              className={
                "btn btn-sm fw-bolder px-4 custom-form-control " +
                (group === "conventional" ? "custom-form-control-active" : "")
              }
            >
              Conventional
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseListingFilter;
