import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getCustomerOrderTimelineEntry, updateCustomerOrder } from "../../utils/customerOrderUtils";
import { CO_T_REFERENCEADDED, CustomerOrderTimelineEntry } from "../../model/customerOrder.types";
import { ContractTimelineEntry, CustomerContractTimelineType } from "../../model/customerContract.types";
import { Input } from "./Input";
import ErrorOverlayButton from "./ErrorOverlayButton";
import { getOrderNumber, isSampleOrder } from "../../utils/orderUtils";
import {
  CustomerCustomerContract,
  CustomerCustomerContractExtended,
} from "../../model/customer/customerCustomerContract.types";
import {
  getContractNumber,
  getCustomerContractTimelineEntry,
  isCustomerCustomerContract,
  updateCustomerContract,
} from "../../utils/customerContractUtils";
import { getSampleOrderTimelineEntry, updateSampleOrder } from "../../utils/sampleOrderUtils";
import { SAMO_T_REFERENCEADDED, SampleOrderTimelineEntry } from "../../model/sampleOrder.types";
import { CustomerCustomerOrder, CustomerCustomerOrderExtended } from "../../model/customer/customerCustomerOrder.types";
import { CustomerSampleOrder, CustomerSampleOrderExtended } from "../../model/customer/customerSampleOrder.types";

interface AddReferenceModalProps {
  order?:
    | CustomerCustomerOrder
    | CustomerCustomerOrderExtended
    | CustomerCustomerContract
    | CustomerCustomerContractExtended
    | CustomerSampleOrder
    | CustomerSampleOrderExtended;
  show?: boolean;
  alwaysShowButton?: boolean;
  onHide?: () => void;
}

const AddReferenceModal: React.FunctionComponent<AddReferenceModalProps> = ({
  order,
  show: showProps,
  onHide,
  alwaysShowButton,
}) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [reference, setReference] = useState("");

  const handleShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(true);
  };
  const handleHide = () => {
    setReference("");
    onHide ? onHide() : setShow(false);
  };
  const handleChangeReference = (e: React.ChangeEvent<HTMLInputElement>) => setReference(e.target.value);

  const handleAddReference = async () => {
    if (!order) return;
    const isContract = isCustomerCustomerContract(order);
    const isSAMO = isSampleOrder(order);
    const timelineEntry = isContract
      ? getCustomerContractTimelineEntry(CustomerContractTimelineType.REFERENCE_ADDED)
      : isSAMO
      ? getSampleOrderTimelineEntry(SAMO_T_REFERENCEADDED)
      : getCustomerOrderTimelineEntry(CO_T_REFERENCEADDED);
    const update = { customerReference: reference };
    try {
      setSaving(true);
      let result;
      if (isContract) {
        result = await updateCustomerContract(update, order._id, timelineEntry as ContractTimelineEntry);
      } else if (isSAMO) {
        result = await updateSampleOrder(update, order._id, timelineEntry as SampleOrderTimelineEntry);
      } else {
        result = await updateCustomerOrder(update, order._id, timelineEntry as CustomerOrderTimelineEntry);
      }
      if (result && result.res && result.res.modifiedCount > 0) {
        toast.success("Reference added successfully");
        handleHide();
      } else toast.error("Reference could not be added");
    } catch (e) {
      toast.error("Error adding reference: " + e);
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const getOrderOrContractNumber = () => {
    if (!order) return "";
    if (isCustomerCustomerContract(order)) return getContractNumber(order);
    return getOrderNumber(order);
  };

  return (
    <>
      {showProps === undefined && (
        <button
          className={"btn btn-light btn-sm py-1 px-3 " + (!alwaysShowButton && "element-show-hover")}
          onClick={handleShow}
        >
          <i className="fa fa-plus" /> Add Reference
        </button>
      )}
      <Modal contentClassName="bg-dark" show={showProps ?? show} onHide={handleHide} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">
              Add Reference {order ? `to ${getOrderOrContractNumber()}` : ""}
            </h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className=" fs-6 mb-5">Add your own reference to make it easier to identify your orders</div>
            </div>
            <div className="col-12 mt-5">
              <label className="fs-5 fw-bold mb-2">Your Reference</label>
              <Input
                className="form-control custom-form-control"
                placeholder="e.g. AT-12345"
                value={reference}
                onChange={handleChangeReference}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-light" onClick={handleHide}>
            Close
          </button>
          <ErrorOverlayButton
            errors={[]}
            saving={saving}
            className={"btn btn-sm btn-light"}
            buttonText={"Add Reference"}
            onClick={handleAddReference}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddReferenceModal;
