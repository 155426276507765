import React from "react";
import { toAbsoluteUrl } from "../../utils/baseUtils";

const LoginInformation: React.FC = () => (
  <div className="d-flex flex-stack px-lg-10 login-information-container">
    <div className="mb-3">
      <button className="btn btn-flex btn-link fs-base disabled">
        <img
          data-kt-element="current-lang-flag"
          className="w-20px h-20px rounded me-3"
          src={toAbsoluteUrl("assets/media/flags/united-states.svg")}
          alt=""
        />
        <span data-kt-element="current-lang-name" className="me-1 link-dark">
          English
        </span>
      </button>
    </div>
    <div className="d-flex text-primary fs-base gap-5 login-information justify-content-center">
      <a
        href="https://rawbids.com/files/terms_of_purchase.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="link-dark"
      >
        Terms of Purchase
      </a>
      <a
        href="https://rawbids.com/files/terms_of_sale.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="link-dark"
      >
        Terms of Sale
      </a>
      <a href="mailto:info@rawbids.com" className="link-dark">
        Contact Us
      </a>
    </div>
  </div>
);

export default LoginInformation;
