import React from "react";
import { CompanyExtended } from "../../../model/company.types";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import PersonSummaryWidget from "../../common/PersonSummaryWidget";
import CreateContactModal from "../../common/internal/modals/CreateContactModal";
import { getCustomerOrders, getCustomerOrderDeliveriesInTime } from "../../../utils/companyUtils";
import { DataContextInternalType } from "../../../context/dataContext";
import { formatCurrency, pluralize, round } from "../../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";

interface CustomerPageSummaryProps {
  company: CompanyExtended;
  context: DataContextInternalType;
}

const CustomerPageSummary: React.FunctionComponent<CustomerPageSummaryProps> = React.memo<CustomerPageSummaryProps>(
  ({ company, context }) => {
    const [customerOrders, inTimeOrders, lateOrders] = getCustomerOrders(company._id, context.customerOrder, true);
    const orderVolume = customerOrders.reduce((sum, o) => sum + o.totalPrice, 0);
    const deliveriesInTime = getCustomerOrderDeliveriesInTime(company._id, context.customerOrder);

    return (
      <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
        <div className="card-body">
          <div className="d-flex flex-center flex-column py-5">
            <span className="fs-2 text-gray-800 fw-bolder mb-1 text-center">
              {company.disabled && <span className="text-danger mr-2">[DISABLED]</span>}
              {!company.activated && !company.disabled && <span className="text-warning mr-2">[PENDING]</span>}
              {company.name}
            </span>
            <div className="d-flex flex-center flex-column py-1">
              <CompanyCountryWidget company={company} wrapperClasses={"fs-6 fw-bold text-muted mb-6"} />
            </div>
          </div>
          <div className="card card-body bg-light no-hover">
            <div className="d-flex justify-content-between flex-row">
              <div className="fw-bolder text-white">{pluralize(customerOrders.length, "Open Order")}</div>
              <div>
                <span className="text-success">{inTimeOrders.length} in time</span>&nbsp;&nbsp;
                <span className="text-danger">{lateOrders.length} late</span>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-row mt-5">
              <div className="fw-bolder text-white">Order Volume</div>
              <div className="text-muted">{formatCurrency(orderVolume, CUSTOMER_BASE_CURRENCY)}</div>
            </div>
            <div className="d-flex justify-content-between flex-row mt-5">
              <div className="fw-bolder text-white">Deliveries in time</div>
              <div
                className={
                  deliveriesInTime > 0.9
                    ? "text-success"
                    : deliveriesInTime > 0.5
                    ? "text-warning"
                    : deliveriesInTime >= 0
                    ? "text-danger"
                    : "text-muted"
                }
              >
                {deliveriesInTime === -1 ? "-" : round(deliveriesInTime * 100, 0) + "%"}
              </div>
            </div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3 mt-10">
            <div className="fw-bolder">Contact</div>
          </div>
          <div className="border-bottom-dark-gray" />
          <PersonSummaryWidget
            company={company}
            type={"company"}
            person={company.primaryPerson}
            description={"Primary Contact"}
          />
          {company.persons.map((person, idx) => (
            <PersonSummaryWidget
              company={company}
              type={"company"}
              isAsPrimaryAssignable
              isRemovable
              key={person._id.toString()}
              description={"Contact #" + (idx + 1)}
              person={person}
            />
          ))}
          <CreateContactModal company={company} type="company" />
        </div>
      </div>
    );
  }
);

export default CustomerPageSummary;
