import { BSON } from "realm-web";
import {
  CC_STATE,
  ContractTimelineEntry,
  ContractTimelineEntryPayload,
  CustomerContract,
  CustomerContractExtended,
  CustomerContractTimelineType,
} from "../model/customerContract.types";
import { callFunction } from "../services/dbService";
import userService from "../services/userService";
import { SelectOption } from "../components/common/CustomSelect";
import { EXTENDED_ORDER_TYPES, ORDER_TYPES } from "./orderUtils";
import { getCustomerOrderTimelineEntry } from "./customerOrderUtils";
import {
  CustomerCustomerContract,
  CustomerCustomerContractExtended,
} from "../model/customer/customerCustomerContract.types";
import { CONTRACT_TYPES, EXTENDED_CONTRACT_TYPES } from "./contractUtils";
import {
  CO_PROCESSINGATWAREHOUSE,
  CO_T_CREATEDFROMCONTRACT,
  CustomerOrder,
  CustomerOrderExtended,
  T_WAREHOUSE,
} from "../model/customerOrder.types";
import { Address } from "../model/commonTypes";

export interface ContractRequestData {
  period: SelectOption;
  minimumCallQuantity: number;
}

export enum ContractPeriods {
  OneMonth = "oneMonth",
  TwoMonths = "twoMonths",
  SixMonths = "sixMonths",
  OneYear = "oneYear",
  TwoYears = "twoYears",
}

export const CC_DEFAULT_PERIOD_OPTION = { value: ContractPeriods.OneYear, label: "1 Year" };
export const CC_PERIOD_OPTIONS: Array<SelectOption> = [
  { value: ContractPeriods.OneMonth, label: "1 Month" },
  { value: ContractPeriods.TwoMonths, label: "2 Months" },
  { value: ContractPeriods.SixMonths, label: "6 Months" },
  CC_DEFAULT_PERIOD_OPTION,
  { value: ContractPeriods.TwoYears, label: "2 Years" },
];

// File types
export const CC_CONTRACTCONFIRMATION = "contractConfirmation";
export const CC_OTHER = "contractOther";

export const CC_FILETYPES: Array<SelectOption> = [
  { value: CC_CONTRACTCONFIRMATION, label: "Contract Confirmation" },
  { value: CC_OTHER, label: "Other" },
];

export const CC_SORTOPTIONS = [
  { value: "contractNo", label: "Contract Number" },
  { value: "createdAt", label: "Creation Date" },
  { value: "contractInformation.totalAmount", label: "Volume" },
  { value: "contractInformation.restAmount", label: "Volume Left" },
  { value: "validityPeriod.end", label: "Valid until" },
  { value: "commodity.title.en", label: "Commodity Name" },
];

export const CC_TAB_OPEN = "Open";
export const CC_TAB_ORDERED = "Ordered";
export const CC_TAB_PARTIAL = "Partially Fulfilled";
export const CC_TAB_READY = "Ready";
export const CC_TAB_ARCHIVED = "Archived";

export const CC_PREPARATION = "preparation";
export const CC_EXPIRINGSOON = "expiringSoon";
export const CC_NOCALLS = "noCalls";
export const CC_LESSTHANHALF = "lessThanHalf";
export const CC_ALMOSTCOMPLETE = "almostComplete";

export const CC_STATUSOPTIONS = [
  { value: CC_STATE.OPEN, label: "Open" },
  { value: CC_PREPARATION, label: "In Preparation" },
  { value: CC_STATE.READY, label: "Ready" },
  { value: CC_EXPIRINGSOON, label: "Expiring Soon" },
  { value: CC_STATE.CLOSED, label: "Closed" },
];

export const CC_CALLOFFOPTIONS = [
  { value: CC_NOCALLS, label: "No Call-Off" },
  { value: CC_LESSTHANHALF, label: "Less than 50% Called" },
  { value: CC_ALMOSTCOMPLETE, label: "Almost Completely Called" },
  { value: CC_STATE.CLOSED, label: "Completely Called" },
];

// Functions
export const UPSERTCUSTOMERCONTRACT = "upsertCustomerContract";
export const PLACECUSTOMERCONTRACTCALL = "placeCustomerContractCall";

/**
 * Inserts a customer contract into the database.
 * @param customerContract Contract that should be inserted into the database
 * @returns {Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>, contractNumber: string } | false>} Result of the insert
 */
export async function insertCustomerContract(
  customerContract: CustomerContract
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; contractNumber: string } | false> {
  return (await callUpsertCustomerContract(customerContract, true)) as
    | { res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; contractNumber: string }
    | false;
}

/**
 * Updates a customer contract inside the database.
 * @param customerContract Contract that should be updated - partial contracts are allowed
 * @param customerContractId Optional id of customer contract if it is not contained in supplier object
 * @param timelineEntry Optional timeline entry
 * @returns {Promise<{res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>, contractNumber: string } | false>} Result of the update
 */
export async function updateCustomerContract(
  customerContract: Partial<CustomerContract>,
  customerContractId?: BSON.ObjectId,
  timelineEntry?: ContractTimelineEntry
): Promise<{ res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; contractNumber: string } | false> {
  if (customerContractId) customerContract._id = customerContractId;
  return (await callUpsertCustomerContract(customerContract, false, timelineEntry)) as
    | { res: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>; contractNumber: string }
    | false;
}

/**
 * Calls the upsert customer contract function in the backend.
 * @param customerContract Contract that should be upserted
 * @param insert Indicates whether the insert or the update case should be called
 * @param timelineEntry Optional timeline entry
 * @returns {Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>, contractNumber: string } | false>} Result of the upsert
 */
async function callUpsertCustomerContract(
  customerContract: Partial<CustomerContract>,
  insert: boolean,
  timelineEntry?: ContractTimelineEntry
): Promise<
  | {
      res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>;
      contractNumber: string;
    }
  | false
> {
  return callFunction(UPSERTCUSTOMERCONTRACT, [customerContract, insert, timelineEntry]);
}

/**
 * Places a customer contract call and update the contract
 * @param customerOrder Order that should be inserted
 * @param customerContract Customer contract to be updated
 * @returns {Promise<{error: string}|Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>>} Error or result of the insert
 */
export async function placeCustomerContractCall(
  customerOrder: CustomerOrder,
  customerContract: CustomerContract | CustomerCustomerContract
): Promise<{ res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>; orderNumber: string; error?: string }> {
  return await callFunction<{
    res: Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>;
    orderNumber: string;
    error?: string;
  }>(PLACECUSTOMERCONTRACTCALL, [customerOrder, customerContract]);
}

/**
 * Get contract number including prefix
 * @param contract any contract document
 * @returns {string} contract number including prefix
 */
export function getContractNumber(contract: CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES): string {
  return `RBC-${contract.contractNo}`;
}

/**
 * Generates a timeline entry for a customer contract
 * @param type Type of the entry
 * @param payload Optional payload of the entry
 * @returns {ContractTimelineEntry} Timeline entry object
 */
export function getCustomerContractTimelineEntry(
  type: CustomerContractTimelineType,
  payload?: ContractTimelineEntryPayload
): ContractTimelineEntry {
  return {
    _id: new BSON.ObjectId(),
    date: new Date(),
    type: type,
    person: userService.getUserId(),
    payload: payload ?? null,
  };
}

/**
 * Get the description for the contract state in which the given contract is.
 * @param contract Contract whose state should be checked
 * @returns {{ title: string, subtitle: string }} Description for the state
 */
export function getContractStateDescriptions(contract: CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES): {
  title: string;
  subtitle: string;
} {
  switch (contract.state) {
    case CC_STATE.CLOSED:
      return { title: "Archived", subtitle: "Contract is done" };
    case CC_STATE.CANCELED:
      return { title: "Canceled", subtitle: "Contract was canceled" };
    case CC_STATE.OPEN:
      return { title: "Open", subtitle: "Contract is open" };
    case CC_STATE.ORDERED:
      return { title: "Ordered", subtitle: "Article was ordered for contract" };
    case CC_STATE.READY:
      return { title: "Ready", subtitle: "Contract is ready" };
    default:
      return { title: "Unknown", subtitle: "" };
  }
}

/**
 * Determines if the given document is a customer contract.
 * @param doc Order or contract document that should be checked
 * @returns {boolean} Returns true if the order has type CustomerContract
 */
export function isCustomerContract(
  doc: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): doc is CustomerContract | CustomerContractExtended {
  return "contractNo" in doc && "noteInternal" in doc;
}

/**
 * Determines if the given document is a customer contract.
 * @param doc Order or contract document that should be checked
 * @returns {boolean} Returns true if the order has type CustomerContract
 */
export function isAnyCustomerContract(
  doc: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): doc is CustomerContract | CustomerCustomerContract {
  return "contractNo" in doc && "company" in doc;
}

/**
 * Determines if the given document is a customer contract.
 * @param doc Order or contract document that should be checked
 * @returns {boolean} Returns true if the order has type CustomerCustomerContract
 */
export function isCustomerCustomerContract(
  doc: ORDER_TYPES | EXTENDED_ORDER_TYPES | CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES
): doc is CustomerCustomerContract | CustomerCustomerContractExtended {
  return "contractNo" in doc && !("noteInternal" in doc);
}

/**
 * Get a customer order for a contract and given data
 * @param contract a contract document
 * @param amount the called amount
 * @param reference customer reference for call
 * @param note customer note
 * @param targetDate target delivery date
 * @param address shipping destination
 * @returns {CustomerOrder} a customer order object
 */
export function getCustomerContractCall(
  contract: EXTENDED_CONTRACT_TYPES,
  amount: number,
  reference: string,
  note: string,
  targetDate: Date,
  address: Address | string // string for backwards compatibility
): CustomerOrderExtended {
  const { priceInformation, commodity, person, company, terms, contractInformation } = contract;
  const pricePerUnit = priceInformation.totalPrice / contractInformation.totalAmount;
  const totalPrice = pricePerUnit * amount;
  return {
    _id: new BSON.ObjectId(),
    orderNo: "-1",
    transport: T_WAREHOUSE,
    commodity,
    amount,
    unit: commodity.unit as "kg" | "ltr",
    currency: priceInformation.currency,
    person,
    company,
    terms,
    customerReference: reference,
    noteCustomer: note,
    targetDate,
    destination: address,
    services: [],
    files: [],
    timeline: [getCustomerOrderTimelineEntry(CO_T_CREATEDFROMCONTRACT, { name: getContractNumber(contract) })],
    createdAt: new Date(),
    priceCommodities: totalPrice,
    priceServices: 0,
    totalPrice,
    state: CO_PROCESSINGATWAREHOUSE,
    noteInternal: [],
    contractInformation: { _id: contract._id, contractNo: contract.contractNo },
    discount: contract.priceInformation.discount,
  };
}

/**
 * Check if a contract is inactive, i.e. archived or canceled
 * @param contract any contract document
 * @returns {boolean} true if contract is inactive, else false
 */
export function isContractInactive(contract: CONTRACT_TYPES | EXTENDED_CONTRACT_TYPES): boolean {
  return [CC_STATE.CLOSED, CC_STATE.CANCELED].includes(contract.state);
}
