import { BSON } from "realm-web";
import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Cookies, withCookies } from "react-cookie";
import ErrorOverlayButton from "./ErrorOverlayButton";
import { Input } from "./Input";
import PersonWidget from "./PersonWidget";
import { DataContextSupplier } from "../../context/dataContext";
import { UserData } from "../../model/userData.types";
import authService, { DEFAULT_SESSION_KEY_NAME } from "../../services/authService";
import userService from "../../services/userService";
import { getDocFromCollection } from "../../utils/baseUtils";
import { SUPPLIER, updateUserData } from "../../utils/userUtils";
import { PackagingDimension } from "../../model/supplier.types";
import { getStandardPackagingDimension } from "../../utils/packagingDimensionUtils";
import { Action, transaction, USERDATA } from "../../services/dbService";

import { extendSupplierSupplier } from "../../utils/dataTransformationUtils";

interface OnboardingProps extends RouteComponentProps {
  cookies: Cookies;
}

interface OnboardingState {
  step: "welcome" | "palette" | "password";
  user: UserData;
  saving: boolean;
  newPassword: string;
  newPasswordRepeat: string;
  palette: PackagingDimension;
}

class Onboarding extends PureComponent<OnboardingProps, OnboardingState> {
  static contextType = DataContextSupplier;
  context!: React.ContextType<typeof DataContextSupplier>;

  constructor(props: OnboardingProps) {
    super(props);
    this.state = {
      step: "welcome",
      user: _.cloneDeep(userService.getUserData()),
      saving: false,
      newPassword: "",
      newPasswordRepeat: "",
      palette: getStandardPackagingDimension(),
    };
  }

  handlePersonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const user = _.cloneDeep(this.state.user);
    const name = e.target.name;
    const value = e.target.value;
    if (name === "phone") {
      if (user.phones.length === 0) user.phones = [{ value: "", description: "" }];
      user.phones[0].value = value;
    } else {
      // @ts-ignore
      user[name] = value;
    }
    this.setState({ user });
  };

  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleBack = () => {
    const { user, step } = this.state;
    const company = getDocFromCollection(this.context.supplier, user.company)!;
    this.setState({
      step:
        step === "password" &&
        user.type === SUPPLIER &&
        (!company.packagingDimensions || company.packagingDimensions.length === 0)
          ? "palette"
          : "welcome",
    });
  };

  handleContinue = () => this.setState({ step: "password" });

  handleSaveUserData = async () => {
    const { user } = this.state;
    const company = getDocFromCollection(this.context.supplier, user.company)!;
    if (!_.isEqual(user, userService.getUserData())) {
      this.setState({ saving: true });
      const res = await updateUserData(user, { prename: user.prename, surname: user.surname, phones: user.phones });
      if (res && res.modifiedCount) {
        toast.success("User data successfully updated");
      }
    }
    this.setState({
      step:
        user.type === SUPPLIER && (!company.packagingDimensions || company.packagingDimensions.length === 0)
          ? "palette"
          : "password",
      saving: false,
    });
  };

  handleChangePackagingDimension = (e: React.ChangeEvent<HTMLInputElement>) => {
    const palette = _.cloneDeep(this.state.palette);
    const { type, name, value } = e.target;
    // @ts-ignore
    palette[name] = type === "number" ? Number(value) : value;
    this.setState({ palette });
  };

  handleChangePasswordAndCompleteOnboarding = async () => {
    const { history } = this.props;
    const { user, palette, newPassword } = this.state;
    const company = getDocFromCollection(this.context.supplier, user.company)!;
    this.setState({ saving: true });
    const email = userService.getUserMail();
    try {
      await authService.changePassword(email, newPassword);
      await authService.logout();
      await authService.login(email, newPassword);
      const apiKeys = await userService.getUser()?.apiKeys.fetchAll();
      if (apiKeys) {
        try {
          for (const key of apiKeys) {
            await userService.getUser()?.apiKeys.delete(key._id);
          }
        } finally {
          // Make sure session key is always deleted
          this.props.cookies.remove(DEFAULT_SESSION_KEY_NAME);
        }
      }
      if (!company.packagingDimensions || company.packagingDimensions.length === 0) {
        const actions: Array<Action> = [
          {
            collection: USERDATA,
            filter: { _id: new BSON.ObjectId(user._id.toString()) },
            update: { onboardingDone: true },
          },
          { collection: SUPPLIER, filter: { _id: company._id }, update: { packagingDimensions: [palette] } },
        ];
        await transaction(actions);
      } else {
        await updateUserData(user, { onboardingDone: true });
      }
      await authService.getUser()?.refreshCustomData();
      history.push("/dashboard");
    } catch (e) {
      toast.error("Changing password failed");
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { user } = this.state;
    const errors: Array<string> = [];
    if (!user.prename.trim()) errors.push("Prename has to be set");
    if (!user.surname.trim()) errors.push("Surname has to be set");
    return errors;
  };

  validatePassword = () => {
    const { newPassword, newPasswordRepeat } = this.state;
    const errors = [];
    if (newPassword.length < 10) errors.push("Password must be at least 10 characters long");
    if (newPassword !== newPasswordRepeat) errors.push("Passwords do not match");
    return errors;
  };

  render() {
    const { user, step, palette, newPassword, newPasswordRepeat, saving } = this.state;
    const errors = this.validateData();
    const passwordErrors = this.validatePassword();
    const company = extendSupplierSupplier(getDocFromCollection(this.context.supplier, user.company)!, this.context);

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div
            className="container-xxl responsive-aside-container"
            style={{ maxWidth: step === "password" ? "700px" : undefined }}
          >
            <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">
                    {step === "welcome" ? "Onboarding" : step === "palette" ? "Palette Data" : "Password"}
                  </span>
                </h3>
                {step === "welcome" ? (
                  <>
                    <div className="row my-8">
                      <div className="col-12 col-sm-6">
                        <div className="row">
                          <div className="col-12 h4 mt-5 mb-6 text-white">
                            <div className="d-flex ">
                              <i className="fa fa-user fs-3rem align-self-center opacity-50" />
                              <div className="flex-column ml-4">
                                <h3>Personal Information</h3>
                                <span className="text-muted fw-normal">
                                  This is the information we have on record about you. <br />
                                  Please check if they are correct so far.
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <label className="required fs-5 fw-bold mb-2">Prename</label>
                            <Input
                              type="text"
                              className="form-control custom-form-control"
                              name="prename"
                              autoComplete="off"
                              value={user.prename}
                              onChange={this.handlePersonChange}
                            />
                          </div>
                          <div className="col-12 mt-3 ">
                            <label className="required fs-5 fw-bold mb-2">Surname</label>
                            <Input
                              type="text"
                              className="form-control custom-form-control"
                              name="surname"
                              autoComplete="off"
                              value={user.surname}
                              onChange={this.handlePersonChange}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <label className="fs-5 fw-bold mb-2">Account Email</label>
                            <Input
                              type="text"
                              className="form-control custom-form-control disabled"
                              disabled={true}
                              value={userService.getUserMail()}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <label className="fs-5 fw-bold mb-2">Phone</label>
                            <Input
                              type="text"
                              className="form-control custom-form-control"
                              name="phone"
                              autoComplete="off"
                              value={user.phones[0]?.value}
                              onChange={this.handlePersonChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="row">
                          <div className="col-12 h4 mt-5 mb-6 text-white">
                            <div className="d-flex ">
                              <i className="fa fa-building fs-3rem align-self-center opacity-50" />
                              <div className="flex-column ml-4">
                                <h3>Company Information</h3>
                                <span className="text-muted fw-normal">
                                  This is the information we have on record about your company. <br />
                                  Please check if they are correct so far.
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mt-3">
                            <label className="fs-5 fw-bold mb-2">Name</label>
                            <Input
                              type="text"
                              className="form-control custom-form-control"
                              value={company.name}
                              disabled={true}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <label className="fs-5 fw-bold mb-2">General Mail</label>
                            <Input
                              type="text"
                              className="form-control custom-form-control"
                              value={company.mail}
                              disabled={true}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <label className="fs-5 fw-bold mb-2">General Phone</label>
                            <Input
                              type="text"
                              className="form-control custom-form-control"
                              value={company.phone}
                              disabled={true}
                            />
                          </div>
                          <div className="col-12 mt-3">
                            <span className="text-muted fw-normal">
                              Is this information not correct? Please contact your contact person to change it.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="bg-light" />
                    <div className="row">
                      <div className="col-12 mt-3">
                        <h3 className="mb-5">Your Contact Person</h3>
                        <div className="card p-3 px-5 bg-light no-hover" style={{ width: "fit-content" }}>
                          <PersonWidget person={company.internalContact} />
                        </div>
                      </div>
                    </div>
                  </>
                ) : step === "palette" ? (
                  <>
                    <div className="row my-8">
                      <div className="col-12 h4 mt-5 mb-6 text-white">
                        <div className="d-flex ">
                          <i className="fas fa-pallet fs-3rem align-self-center opacity-50" />
                          <div className="flex-column ml-4">
                            <h3>Palette Data</h3>
                            <span className="text-muted fw-normal">
                              Please give us information about the palette you use most of the time.
                              <br />
                              The system will assume that this palette is used for all ingredients you provide. You can
                              add and configure other palettes later.
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 mt-sm-3">
                        <label className="fs-5 fw-bold mb-2">Description</label>
                        <Input
                          type="text"
                          value={palette.description}
                          className="form-control custom-form-control"
                          name="description"
                          onBlur={this.handleChangePackagingDimension}
                        />
                      </div>
                      <div className="col-3 mt-3">
                        <label className="required fs-5 fw-bold mb-2">Ingredient amount per palette</label>
                        <div className="input-group">
                          <Input
                            type="number"
                            value={palette.netWeight}
                            name="netWeight"
                            className="form-control custom-form-control"
                            onBlur={this.handleChangePackagingDimension}
                          />
                          <div className="input-group-append">
                            <div className="custom-form-control rounded-end" style={{ padding: ".375rem .75rem" }}>
                              kg
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <label className="required fs-5 fw-bold mb-2">Total palette weight</label>
                        <div className="input-group">
                          <Input
                            type="number"
                            value={palette.grossWeight}
                            name="grossWeight"
                            className="form-control custom-form-control"
                            onBlur={this.handleChangePackagingDimension}
                          />
                          <div className="input-group-append">
                            <div className="custom-form-control rounded-end" style={{ padding: ".375rem .75rem" }}>
                              kg
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <label className="required fs-5 fw-bold mb-2">Length</label>
                        <div className="input-group">
                          <Input
                            type="number"
                            value={palette.length}
                            name="length"
                            className="form-control custom-form-control"
                            onBlur={this.handleChangePackagingDimension}
                          />
                          <div className="input-group-append">
                            <div className="custom-form-control rounded-end" style={{ padding: ".375rem .75rem" }}>
                              cm
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <label className="required fs-5 fw-bold mb-2">Width</label>
                        <div className="input-group">
                          <Input
                            type="number"
                            value={palette.width}
                            name="width"
                            className="form-control custom-form-control"
                            onBlur={this.handleChangePackagingDimension}
                          />
                          <div className="input-group-append">
                            <div className="custom-form-control rounded-end" style={{ padding: ".375rem .75rem" }}>
                              cm
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <label className="required fs-5 fw-bold mb-2">Height</label>
                        <div className="input-group">
                          <Input
                            type="number"
                            value={palette.height}
                            name="height"
                            className="form-control custom-form-control"
                            onBlur={this.handleChangePackagingDimension}
                          />
                          <div className="input-group-append">
                            <div className="custom-form-control rounded-end" style={{ padding: ".375rem .75rem" }}>
                              cm
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 mt-3">
                        <label className="fs-5 fw-bold mb-2">CBM (calculated)</label>
                        <div className="input-group">
                          <input
                            type="text"
                            value={palette.cbm}
                            disabled={true}
                            className="form-control custom-form-control"
                          />
                          <div className="input-group-append" style={{ opacity: 0.7 }}>
                            <div className="custom-form-control rounded-end" style={{ padding: ".375rem .75rem" }}>
                              m³
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="bg-light" />
                    <div className="row">
                      <div className="col-12 mt-3">
                        <h3 className="mb-5">Your Contact Person</h3>
                        <div className="card p-3 px-5 bg-light no-hover" style={{ width: "fit-content" }}>
                          <PersonWidget person={company.internalContact} />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row my-8">
                      <div className="col-12 h4 mb-6 text-white">
                        <div className="d-flex ">
                          <div className="flex-column ">
                            <span className="text-muted fw-normal">
                              Please set a password now. The password has to be at least 10 characters long. We
                              recommend a combination of letters, numbers and special characters.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row my-8">
                      <label className="col-4 col-form-label required fw-bold fs-6">New Password</label>
                      <div className="col">
                        <input
                          className="form-control custom-form-control"
                          type="password"
                          name="newPassword"
                          value={newPassword}
                          onChange={this.handlePasswordChange}
                        />
                      </div>
                    </div>
                    <div className="row my-8">
                      <label className="col-4 col-form-label required fw-bold fs-6">Repeat New Password</label>
                      <div className="col">
                        <input
                          className="form-control custom-form-control"
                          type="password"
                          name="newPasswordRepeat"
                          value={newPasswordRepeat}
                          onChange={this.handlePasswordChange}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="card-footer border-0 text-right">
                {step !== "welcome" && (
                  <button className="btn btn-sm btn-outline btn-outline-light mr-3" onClick={this.handleBack}>
                    Back
                  </button>
                )}
                <ErrorOverlayButton
                  errors={step === "welcome" ? errors : step === "password" ? passwordErrors : []}
                  className="btn btn-sm btn-outline btn-outline-light"
                  buttonText={["welcome", "palette"].includes(step) ? "Continue" : "Complete"}
                  saving={saving}
                  onClick={
                    step === "welcome"
                      ? this.handleSaveUserData
                      : step === "palette"
                      ? this.handleContinue
                      : this.handleChangePasswordAndCompleteOnboarding
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Onboarding));
