import React, { PureComponent, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { CO_T_CALCULATIONUPDATED, CustomerOrderExtended } from "../../../../../model/customerOrder.types";
import { ceil, countDecimals, formatCurrency, round } from "../../../../../utils/baseUtils";
import { DataContextInternalType } from "../../../../../context/dataContext";
import { isFinishedProduct } from "../../../../../utils/finishedProductUtils";
import { formatArticleUnit, isAnyFinishedProduct } from "../../../../../utils/productArticleUtils";
import { Input } from "../../../../common/Input";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { getCustomerOrderTimelineEntry, getOrderStateRanking } from "../../../../../utils/customerOrderUtils";
import { CUSTOMERORDER, SUPPLIERORDER, transaction, UpdateAction } from "../../../../../services/dbService";
import { getSupplierOrderTimelineEntry } from "../../../../../utils/supplierOrderUtils";
import { SO_T_COCALCULATIONUPDATED } from "../../../../../model/supplierOrder.types";

interface CustomerOrderCalculationProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
}

interface CustomerOrderCalculationState {
  edit: boolean;
}

class CustomerOrderCalculation extends PureComponent<CustomerOrderCalculationProps, CustomerOrderCalculationState> {
  constructor(props: CustomerOrderCalculationProps) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  handleToggleEdit = () => this.setState({ edit: !this.state.edit });

  render() {
    const { order, context } = this.props;
    const { edit } = this.state;
    const { priceCommodities, currency, commodity, amount, priceServices, totalPrice, discount } = order;

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">Price Composition</span>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="table-responsive mt-5 pt-2 bg-light2">
            <table className="table fw-bold gy-1 ">
              <tbody>
                <tr>
                  <td className="text-white w-50">
                    {isAnyFinishedProduct(commodity) ? "Finished Products" : "Commodities"}
                  </td>
                  <td className="text-muted">{formatCurrency(priceCommodities, currency)}</td>
                </tr>
                <tr>
                  <td className="text-white w-50">Price per {commodity.unit}</td>
                  <td className="text-muted">{formatCurrency(priceCommodities / amount, currency)}</td>
                </tr>
                {discount && discount !== 0 && (
                  <tr>
                    <td className="text-white w-50">Discount</td>
                    <td className="text-muted">{discount}%</td>
                  </tr>
                )}
                <tr>
                  <td className="text-white w-50">Services</td>
                  <td className="text-muted">{formatCurrency(priceServices, currency)}</td>
                </tr>
                <tr>
                  <td className="text-white w-50">Total</td>
                  <td className="text-muted">{formatCurrency(totalPrice * (1 - (discount || 0) / 100), currency)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          {edit ? (
            <CustomerOrderRecalculation order={order} context={context} onToggleEdit={this.handleToggleEdit} />
          ) : (
            <div className="mt-5 text-right">
              <button className="btn btn-sm btn-light" onClick={this.handleToggleEdit}>
                Edit
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CustomerOrderCalculation;

interface CustomerOrderRecalculationProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
  onToggleEdit: () => void;
}

interface CustomerOrderRecalculationState {
  amount: number;
  costPerUnit: number;
  totalCommodityCost: number;
  saving: boolean;
}

const CustomerOrderRecalculation: React.FunctionComponent<CustomerOrderRecalculationProps> = ({
  order,
  context,
  onToggleEdit,
}) => {
  const [state, setState] = useState<CustomerOrderRecalculationState>({
    amount: order.amount,
    costPerUnit: order.priceCommodities / order.amount,
    totalCommodityCost: order.priceCommodities,
    saving: false,
  });

  const article = order.commodity;
  const isFP = isFinishedProduct(article);

  /**
   * Effect that updates the state in case the order is updated from outside
   */
  useEffect(() => {
    setState({
      ...state,
      amount: order.amount,
      costPerUnit: order.priceCommodities / order.amount,
      totalCommodityCost: order.priceCommodities,
    });
  }, [order]);

  /**
   * Handles changes of the numeric values in the supplier calculation.
   * @param e Event that triggered the update
   */
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let numVal = Number(value);
    switch (name) {
      case "amount":
        setState((prevState) => {
          // In case the amount is adjusted we need to recalculate the total cost
          return { ...prevState, amount: numVal, totalCommodityCost: prevState.costPerUnit * numVal };
        });
        break;
      case "costPerUnit":
        setState((prevState) => {
          if (countDecimals(numVal) > 2) numVal = ceil(numVal, 2);
          // In case the cost per unit is adjusted we need to recalculate the total cost
          return { ...prevState, costPerUnit: numVal, totalCommodityCost: numVal * prevState.amount };
        });
        break;
      case "totalCommodityCost":
        setState((prevState) => {
          let costPerUnit = numVal / prevState.amount;
          if (countDecimals(costPerUnit) > 2) {
            costPerUnit = ceil(costPerUnit, 2);
            numVal = costPerUnit * prevState.amount;
          }
          // In case the total cost is adjusted we need to recalculate the cost per unit (makes more sense than adjusting amount)
          return { ...prevState, costPerUnit, totalCommodityCost: numVal };
        });
        break;
    }
  };

  /**
   * Handles updating the customer order calculation.
   */
  const handleUpdateCustomerOrderCalculation = async () => {
    try {
      setState({ ...state, saving: true });
      const actions: Array<UpdateAction> = [];
      actions.push({
        collection: CUSTOMERORDER,
        filter: { _id: order._id },
        update: {
          amount: state.amount,
          priceCommodities: state.totalCommodityCost,
          totalPrice: state.totalCommodityCost + order.priceServices,
        },
        push: {
          timeline: getCustomerOrderTimelineEntry(CO_T_CALCULATIONUPDATED, { calculation: getTimelineDiffString() }),
        },
      });
      const relatedSO = context.supplierOrder.find((sO) => sO.customerOrders.some((cO) => cO === order._id.toString()));
      if (relatedSO) {
        const totalTurnoverDiff = state.totalCommodityCost - order.priceCommodities;
        const warehouseAmountDiff = order.amount - state.amount;
        const sOUnitPrice = relatedSO.priceCommodities / relatedSO.amount;
        const oldMargin = order.priceCommodities - sOUnitPrice * order.amount;
        const newMargin = state.totalCommodityCost - sOUnitPrice * state.amount;
        actions.push({
          collection: SUPPLIERORDER,
          filter: { _id: relatedSO._id },
          inc: {
            totalTurnover: totalTurnoverDiff,
            warehouseAmount: warehouseAmountDiff,
            totalWarehouse: sOUnitPrice * warehouseAmountDiff,
            totalMargin: newMargin - oldMargin,
          },
          push: { timeline: getSupplierOrderTimelineEntry(SO_T_COCALCULATIONUPDATED) },
        });
      }
      const res = await transaction(actions);
      if (res) {
        toast.success("Customer Order updated successfully");
        onToggleEdit();
      } else {
        toast.error("Error updating Customer Order");
      }
    } catch (e) {
      console.error("ERROR:", e);
      toast.error("Error updating Customer Order");
    } finally {
      setState({ ...state, saving: false });
    }
  };

  /**
   * Validates the user input. Total commodity price is not checked since amount or cost per unit checks already include
   * this.
   * @returns {Array<string>} List of errors
   */
  const validateData = useMemo(() => {
    const errors: Array<string> = [];
    const { amount, costPerUnit } = state;
    if (amount <= 0) {
      errors.push("Amount has to be positive");
    } else if (amount !== order.amount) {
      const relatedSO = context.supplierOrder.find((sO) => sO.customerOrders.some((cO) => cO === order._id.toString()));
      // If there is no SO yet we can always change the amount, else check if the SO can provide the needed amount
      if (
        relatedSO &&
        ((relatedSO.warehouseAmount && amount - order.amount > relatedSO.warehouseAmount) ||
          (amount > order.amount && !relatedSO?.warehouseAmount))
      ) {
        errors.push(
          `Related Supplier Order does not provide enough ${
            isFP ? "Finished Products" : "Commodities"
          } to fulfill the new amount`
        );
      }
    }
    if (costPerUnit <= 0) {
      errors.push("Cost per unit has to be positive");
    }
    return errors;
  }, [state.amount, state.costPerUnit]);

  /**
   * Builds the diff string for the timeline which is used to properly show the changes in timeline.
   * @returns {string} Timeline diff string
   */
  const getTimelineDiffString = (): string => {
    const { amount, costPerUnit, totalCommodityCost } = state;
    const unitPrice = order.priceCommodities / order.amount;
    const unit = formatArticleUnit(order.unit, order.commodity);
    let diffString = "";
    if (amount !== order.amount && costPerUnit !== unitPrice) {
      diffString += `Amount and Cost per Unit where changed\n${
        order.amount
      }${unit} to ${amount}${unit}\n${formatCurrency(unitPrice, order.currency)} to ${formatCurrency(
        costPerUnit,
        order.currency
      )}`;
    } else if (amount !== order.amount) {
      diffString += `Amount was changed\n${order.amount}${unit} to ${amount}${unit}`;
    } else if (costPerUnit !== unitPrice) {
      diffString += `Cost per Unit was changed\n${formatCurrency(unitPrice, order.currency)} to ${formatCurrency(
        costPerUnit,
        order.currency
      )}`;
    }
    // If amount or cost were changed the total price was also changed due to this
    if (amount !== order.amount || costPerUnit !== unitPrice) {
      diffString += `\nTotal Commodities Price changed\n${formatCurrency(
        order.priceCommodities,
        order.currency
      )} to ${formatCurrency(totalCommodityCost, order.currency)}`;
    }
    return diffString;
  };

  return (
    <>
      <div className="row mt-5">
        <div className="col-12 mb-5">
          <h2 className="mb-1">Adjusted Values</h2>
        </div>
        <div className="col-12 col-sm-6 mb-2 align-content-center">
          <span className="text-white">Amount</span>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <div className="input-group">
            <Input
              type="number"
              name="amount"
              value={state.amount}
              min={0}
              disabled={
                getOrderStateRanking(order) > 5 || context.batch.some((b) => b.supplierOrder === order._id.toString())
              }
              onChange={handleChangeValue}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {formatArticleUnit(order.unit, order.commodity)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-2 align-content-center">
          <span className="text-white">Cost per Unit</span>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <div className="input-group">
            <Input type="number" name="costPerUnit" value={state.costPerUnit} min={0} onChange={handleChangeValue} />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {order.currency}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-2 align-content-center">
          <span className="text-white">Total {isFP ? "Finished Product" : "Commodity"} Cost</span>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <div className="input-group">
            <Input
              type="number"
              name="totalCommodityCost"
              value={round(state.totalCommodityCost, 4)}
              min={0}
              onChange={handleChangeValue}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {order.currency}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 text-right">
        <ErrorOverlayButton className="btn btn-sm btn-light mr-2" saving={state.saving} onClick={onToggleEdit}>
          Cancel
        </ErrorOverlayButton>
        <ErrorOverlayButton
          className="btn btn-sm btn-light"
          errors={validateData}
          saving={state.saving}
          onClick={handleUpdateCustomerOrderCalculation}
        >
          Save
        </ErrorOverlayButton>
      </div>
    </>
  );
};
