import _ from "lodash";
import { toast } from "react-toastify";
import React, { useMemo, useState } from "react";
import { getCustomerOrderTimelineEntry, getRelatedInvoices } from "../../../../../utils/customerOrderUtils";
import { getSupplierOrderTimelineEntry } from "../../../../../utils/supplierOrderUtils";
import { getCustomerContractTimelineEntry } from "../../../../../utils/customerContractUtils";
import { getOrderNumber } from "../../../../../utils/orderUtils";
import { getAllListDifferences } from "../../../../../utils/diffUtils";
import { getBatchTimelineEntry, T_BATCHBOOKIN } from "../../../../../utils/batchUtils";
import { BASE_CURRENCY, convertCurrency } from "../../../../../utils/currencyUtils";
import {
  CO_CANCELABLE_STATES,
  CO_CANCELED,
  CO_T_CANCELED,
  CustomerOrderExtended,
} from "../../../../../model/customerOrder.types";
import { CustomerContract, CustomerContractTimelineType } from "../../../../../model/customerContract.types";
import { SO_T_CO_CANCELED, SupplierOrder } from "../../../../../model/supplierOrder.types";
import {
  Action,
  BATCH,
  CUSTOMERCONTRACT,
  CUSTOMERORDER,
  FORWARDINGORDER,
  SUPPLIERORDER,
  transaction,
} from "../../../../../services/dbService";
import { DataContextInternalType } from "../../../../../context/dataContext";
import SimpleConfirmationModal from "../../../../common/SimpleConfirmationModal";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { FWO_STATES, FWO_TIMELINE } from "../../../../../model/forwardingOrder.types";
import { getForwardingOrderTimelineEntry } from "../../../../../utils/forwardingOrderUtils";
import userService from "../../../../../services/userService";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../../services/slackService";

interface CancelCustomerOrderModalProps {
  order: CustomerOrderExtended;
  context: DataContextInternalType;
  supplierOrder: SupplierOrder | undefined;
  contract: CustomerContract | undefined;
}

const CancelCustomerOrderModal: React.FunctionComponent<CancelCustomerOrderModalProps> = ({
  order,
  context,
  supplierOrder,
  contract,
}) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  const relatedFWOs = useMemo(
    () =>
      context.forwardingOrder.filter(
        (fwo) =>
          fwo.orderInformation.some((oi) => oi.orderId.toString() === order._id.toString()) &&
          ![FWO_STATES.FWO_CANCELED, FWO_STATES.FWO_ARCHIVED].includes(fwo.state)
      ),
    [context.forwardingOrder, order]
  );

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleCancelCustomerOrder = async () => {
    const timelineEntryCO = getCustomerOrderTimelineEntry(CO_T_CANCELED);
    const timelineEntrySO = getSupplierOrderTimelineEntry(SO_T_CO_CANCELED, { name: getOrderNumber(order) });
    const timelineEntryCC = getCustomerContractTimelineEntry(CustomerContractTimelineType.CALL_CANCELED, {
      name: getOrderNumber(order),
    });
    const actions = [];
    try {
      setSaving(true);
      // cancel CO
      const actionCO: Action = {
        collection: CUSTOMERORDER,
        filter: { _id: order._id },
        update: {
          state: CO_CANCELED,
          previousState: order.state,
          previousSupplierOrder: supplierOrder?._id.toString(),
        },
        push: { timeline: timelineEntryCO },
      };
      actions.push(actionCO);
      // if SO exists, update warehouse amount of supplier, decrease totalTurnover by turnover of CO
      // increase totalWarehouse by warehousePrice and recalculate totalMargin
      // and remove CO from SO
      if (supplierOrder) {
        const turnoverCO = supplierOrder.exchangeRates
          ? convertCurrency(order.totalPrice, order.currency, BASE_CURRENCY, supplierOrder.exchangeRates)
          : 0;
        const warehousePrice = order.amount * (supplierOrder.priceCommodities / supplierOrder.amount);
        const totalTurnover = supplierOrder.totalTurnover - turnoverCO;
        const totalWarehouse = (supplierOrder.totalWarehouse ?? 0) + warehousePrice;
        const totalMargin = totalTurnover - supplierOrder.totalPrice + totalWarehouse;

        const actionSO: Action = {
          collection: SUPPLIERORDER,
          filter: { _id: supplierOrder._id },
          inc: { warehouseAmount: order.amount },
          update: { totalTurnover: totalTurnover, totalWarehouse: totalWarehouse, totalMargin: totalMargin },
          pull: { customerOrders: order._id.toString() },
          push: { timeline: timelineEntrySO },
        };
        actions.push(actionSO);
      }
      // if contract exists, update amount left. CO will still be listed as "canceled"
      if (contract) {
        const actionContract: Action = {
          collection: CUSTOMERCONTRACT,
          filter: { _id: contract._id },
          inc: { "contractInformation.restAmount": order.amount },
          push: { timeline: timelineEntryCC },
        };
        actions.push(actionContract);
      }
      // if batches were booked in commissioning, reset the used amount
      order.usedBatches?.forEach((usedBatch) => {
        // find original batch to update packages there
        const batch = context.batch.find((b) => b._id.toString() === usedBatch.batchId.toString());
        if (batch) {
          // update amount in packages of original batch by value from packages of usedBatch
          const updatedPackages = batch.packages.map((pkg) => {
            const usedBatchPackage = _.find(usedBatch.packages, ["packageId", pkg._id.toString()]);
            if (usedBatchPackage) {
              // for safety reasons if batch was not loaded properly or did not update, to avoid wrong amounts
              if (pkg.amountEach > 0)
                throw new Error(
                  `Batch RB${batch.identifier} has ${pkg.amountEach} but should be 0 since it was used in ${usedBatchPackage.number}, aborting...`
                );
              return {
                ...pkg,
                amountEach: pkg.amountEach + usedBatchPackage.amountUsed,
                usedOrders: pkg.usedOrders?.filter((orderId) => orderId !== order._id.toString()),
              };
            } else {
              return pkg;
            }
          });
          const [differentPre, differentPost] = getAllListDifferences(batch.packages, updatedPackages);
          const timelineEntryBatch = getBatchTimelineEntry(T_BATCHBOOKIN);
          if (differentPre.length > 0) timelineEntryBatch.pre = { packages: differentPre };
          if (differentPost.length > 0) timelineEntryBatch.post = { packages: differentPost };
          const actionBatch: Action = {
            collection: BATCH,
            filter: { _id: batch._id },
            update: { packages: updatedPackages },
            pull: { customerOrders: order._id.toString() },
            push: { timeline: timelineEntryBatch },
          };
          const actionCO: Action = {
            collection: CUSTOMERORDER,
            filter: { _id: order._id },
            pull: { usedBatches: { batchId: usedBatch.batchId } },
          };
          actions.push(actionBatch, actionCO);
        }
      });
      // Cancel related forwarding order if order is the only one and remove order from forwarding order
      const fwosWillBeCanceled = [];
      const fwosNotCanceled = [];
      if (relatedFWOs.length > 0) {
        for (let i = 0; i < relatedFWOs.length; i++) {
          const fwo = relatedFWOs[i];
          actions.push({
            collection: FORWARDINGORDER,
            filter: { _id: fwo._id },
            pull: { orderInformation: { orderId: order._id.toString() } },
            push: {
              timeline: {
                $each: [getForwardingOrderTimelineEntry(FWO_TIMELINE.FWO_T_REMOVEDORDER)],
              },
            },
          });
          if (fwo.orderInformation.length === 1) {
            actions.push({
              collection: FORWARDINGORDER,
              filter: { _id: fwo._id },
              update: {
                state: FWO_STATES.FWO_CANCELED,
              },
              push: {
                timeline: {
                  $each: [getForwardingOrderTimelineEntry(FWO_TIMELINE.FWO_T_CANCELED)],
                },
              },
            });
            fwosWillBeCanceled.push(fwo);
          } else fwosNotCanceled.push(fwo);
        }
      }
      const result = await transaction(actions);
      if (result) {
        toast.success("Customer Order canceled");
        const user = userService.getUserData();
        if (relatedFWOs.length > 0) {
          let message = "";
          if (fwosWillBeCanceled.length > 0) {
            // slack message that forwarding order was canceled
            message = `Customer order <https://${
              process.env.REACT_APP_BASE_URL || ""
            }/customerOrder/${order._id.toString()}|*${getOrderNumber(order)}*> was canceled by ${user.prename} ${
              user.surname
            } and part of forwarding ${fwosWillBeCanceled.length > 1 ? "orders" : "order"} ${fwosWillBeCanceled
              .map((fwo) => {
                return `FW-${fwo.forwardingOrderNo}`;
              })
              .join(", ")}. The forwarding ${
              fwosWillBeCanceled.length > 1 ? "orders are" : "order is"
            } canceled as well since this was the only active order in ${
              fwosWillBeCanceled.length > 1 ? "these forwarding orders" : "this forwarding order"
            }.`;
          } else if (fwosNotCanceled.length > 0) {
            // slack message even though forwarding order is not canceled
            message = `Customer order <https://${
              process.env.REACT_APP_BASE_URL || ""
            }/customerOrder/${order._id.toString()}|*${getOrderNumber(order)}*> was canceled by ${user.prename} ${
              user.surname
            } and part of forwarding ${fwosNotCanceled.length > 1 ? "orders" : "order"} ${fwosNotCanceled
              .map((fwo) => {
                return `FW-${fwo.forwardingOrderNo}`;
              })
              .join(", ")}. The forwarding ${
              fwosNotCanceled.length > 1 ? "orders are" : "order is"
            } not canceled since there are other orders in ${
              fwosNotCanceled.length > 1 ? "these forwarding orders" : "this forwarding order"
            }.`;
          }
          await sendMessage(getDefaultSlackChannel(false, NotificationType.SCM), message);
        }
      } else {
        toast.error("Error cancelling Customer Order");
      }
    } catch (e) {
      console.error("Error cancelling order: ", e);
      toast.error("Error cancelling customer order, please try again later");
    } finally {
      setSaving(false);
    }
  };

  const validateData = () => {
    const errors: string[] = [];
    // order can only be canceled if state is allowed to cancel and if no invoice, no deliveryNote or tracking information is available
    if (!_.includes(CO_CANCELABLE_STATES, order.state))
      errors.push("Canceling is not possible in this state of the order");
    const invoices = getRelatedInvoices(order._id, context);
    if (invoices.length > 0) errors.push("An invoice is already present");
    if (order.trackingInformation !== undefined) errors.push("Tracking information was already set");
    const deliveryNote = order.files.some((f) => f.type === "deliveryNote");
    if (deliveryNote) errors.push("Delivery note was already generated");
    return errors;
  };

  return (
    <>
      <ErrorOverlayButton
        errors={validateData()}
        className={"btn btn-light btn-sm mt-5"}
        buttonText={"Cancel Order"}
        onClick={handleShow}
      />
      <SimpleConfirmationModal.SimpleConfirmationModal
        show={show}
        modalTitle="Cancel Customer Order"
        size="md"
        saving={saving}
        cancelButtonText="Close"
        confirmButtonText="Confirm"
        modalDescription={
          <span className="text-white">
            <h4 className="fw-bolder text-white text-left">Do you really want to cancel {getOrderNumber(order)}?</h4>
            <div className="text-white text-left mt-5">
              After being canceled the order will be archived and cannot be processed further.
            </div>
            <h4 className="fw-bolder text-white text-left mt-5">This operation is permanent and cannot be revoked!</h4>
          </span>
        }
        onClose={handleHide}
        onConfirm={handleCancelCustomerOrder}
      />
    </>
  );
};

export default CancelCustomerOrderModal;
