import React from "react";
import { formatCurrency, round } from "../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";

interface CreateCustomerOrderContractCalculationProps {
  totalAmount: number;
  pricePerUnit: number;
  purchasePrice: number;
  discount: number;
}

const CreateCustomerOrderContractCalculation: React.FunctionComponent<CreateCustomerOrderContractCalculationProps> = ({
  totalAmount,
  purchasePrice,
  pricePerUnit,
  discount,
}) => {
  const totalTurnover = totalAmount * pricePerUnit;
  const discountAmount = totalTurnover - totalTurnover * (1 - discount / 100);
  const totalMargin = totalAmount * (pricePerUnit - purchasePrice) - discountAmount;
  const percentageMargin = round((totalMargin / totalTurnover) * 100, 2);

  const renderAmount = (amount: number, negative?: boolean) => (
    <span className="text-white fw-bold">
      {negative ? "-" : "+"}
      {formatCurrency(amount, BASE_CURRENCY)}
    </span>
  );

  return (
    <div className="col-12 col-sm-6">
      <div className="fw-bolder text-white fs-3 my-5">Calculation</div>
      <div className="row my-1">
        <div className="col-12 col-sm-6 text-left">
          <span className="text-muted">Total Turnover</span>
        </div>
        <div className="col-12 col-sm-6 text-right">{renderAmount(round(totalTurnover, 2))}</div>
      </div>
      {discount !== 0 && (
        <div className="row my-1">
          <div className="col-12 col-sm-6 text-left">
            <span className="text-muted">Discount</span>
          </div>
          <div className="col-12 col-sm-6 text-right">{renderAmount(round(discountAmount, 2), true)}</div>
        </div>
      )}
      <div className="row my-1">
        <div className="col-12 col-sm-6 text-left">
          <span className="text-muted">Total Purchase Price</span>
        </div>
        <div className="col-12 col-sm-6 text-right">{renderAmount(round(totalAmount * purchasePrice, 2), true)}</div>
      </div>
      <div className="row my-1">
        <div className="col-12 col-sm-6 text-left">
          <span className="text-muted">Total Margin</span>
        </div>
        <div className="col-12 col-sm-6 text-right">
          {renderAmount(round(totalAmount * (pricePerUnit - purchasePrice) - discountAmount, 2))}
        </div>
      </div>
      <div className="row my-1">
        <div className="col-12 col-sm-6 text-left">
          <span className="text-muted">Margin</span>
        </div>
        <div className="col-12 col-sm-6 text-right">
          <span className={"fw-bold " + (totalMargin >= 0 ? "text-success" : "text-danger")}>
            {totalMargin >= 0 ? "+" : ""}
            {formatCurrency(totalMargin, BASE_CURRENCY)}
          </span>
        </div>
      </div>
      <div className="row my-1">
        <div className="col-12 col-sm-6 text-sm-left">
          <span className="text-muted">Margin %</span>
        </div>
        <div className="col-12 col-sm-6 text-sm-right">
          <span
            className={
              "fw-bold " +
              (percentageMargin >= 25 ? "text-success" : percentageMargin >= 10 ? "text-warning" : "text-danger")
            }
          >
            {isNaN(percentageMargin) ? "-" : `${percentageMargin.toFixed(2)}%`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomerOrderContractCalculation;
