import React, { PureComponent } from "react";
// @ts-ignore
import Tags from "@yaireo/tagify/dist/react.tagify"; // React-wrapper file
import { TagifySettings } from "@yaireo/tagify";
import Search from "../../common/Search";
import { DataContextType } from "../../../context/dataContext";
import { PropertyType } from "../../../utils/propertyUtils";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { C_COMMODITYGROUP_OPTIONS, C_STATUS_OPTIONS, C_STATUS_OPTIONS_INTERNAL } from "../../../utils/commodityUtils";
import { INTERNAL, SUPPLIER } from "../../../utils/userUtils";
import { COMMODITY, FINISHEDPRODUCT } from "../../../services/dbService";

interface CommodityListingFilterProps {
  context: DataContextType;
  search: string;
  category: SelectOption | undefined;
  composition: SelectOption | undefined;
  status: SelectOption | undefined;
  commodityGroup: SelectOption | undefined;
  listingType: typeof COMMODITY | typeof FINISHEDPRODUCT;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCategoryChange: (category: SelectOption | undefined) => void;
  onCompositionChange: (composition: SelectOption | undefined) => void;
  onStatusChange: (status: SelectOption | undefined) => void;
  onCommodityGroupChange: (status: SelectOption | undefined) => void;
  onActiveSubstanceChange: (e: CustomEvent<Tagify.ChangeEventData<{ value: string; id: string }>>) => void;
  onXLSXExport: () => void;
}

interface CommodityListingFilterState {}

class CommodityListingFilter extends PureComponent<CommodityListingFilterProps, CommodityListingFilterState> {
  render() {
    const {
      context,
      search,
      composition,
      category,
      status,
      commodityGroup,
      listingType,
      onSearch,
      onCategoryChange,
      onCompositionChange,
      onActiveSubstanceChange,
      onStatusChange,
      onCommodityGroupChange,
      onXLSXExport,
    } = this.props;
    const { property } = context;
    const categories = property.filter((p) => p.type === PropertyType.CATEGORY);
    const compositions = property.filter((p) => p.type === PropertyType.COMPOSITION);
    const activeSubstances = context.activeSubstance.filter((aS) => !aS.disabled);

    const settingsActiveSubstances: TagifySettings<{ value: string; id: string }> = {
      placeholder: "Search for active substances...",
      enforceWhitelist: true,
      duplicates: false,
      trim: true,
      pasteAsTags: false,
      editTags: false,
      dropdown: {
        classname: "custom-tagify-dropdown",
        enabled: 0,
        fuzzySearch: true,
        maxItems: 500, // Since it can't be set to uncapped
      },
    };
    return (
      <div className="card mb-10 bg-white border-none bg-custom-medium-gray p-5 ">
        <div className="row g-8 mb-4">
          <div className="col-lg-6 col-md-8 col-12">
            <label className="fs-6 form-label fw-bolder text-dark">Search Query</label>
            <Search value={search} onSearch={onSearch} placeholder="Search for articles..." />
          </div>
          <div className="col-lg-6 col-md-8 col-12">
            <label className="fs-6 form-label fw-bolder text-dark">Active Substances</label>
            <Tags
              className="custom-form-control form-control-solid"
              settings={settingsActiveSubstances}
              onChange={onActiveSubstanceChange}
              whitelist={activeSubstances.map((aS) => {
                return { value: aS.name.en, id: aS._id.toString() };
              })}
            />
          </div>
        </div>
        <>
          <div className="row g-8">
            <div className="col-lg-3 col-md-8 col-12 z-index-2">
              <label className="fs-6 form-label fw-bolder text-dark">Category</label>
              <CustomSelect
                options={categories.map((p) => {
                  return { value: p._id.toString(), label: p.name.en };
                })}
                matchFormControl={true}
                value={category}
                isClearable={true}
                placeholder={"All Categories"}
                onChange={onCategoryChange}
              />
            </div>
            <div className="col-lg-3 col-md-8 col-12 ">
              <label className="fs-6 form-label fw-bolder text-dark">Composition</label>
              <CustomSelect
                options={compositions.map((p) => {
                  return { value: p._id.toString(), label: p.name.en };
                })}
                matchFormControl={true}
                value={composition}
                isClearable={true}
                placeholder={"All Compositions"}
                onChange={onCompositionChange}
              />
            </div>
            {context.type !== SUPPLIER && (
              <div className="col-lg-3 col-md-8 col-12 ">
                <label className="fs-6 form-label fw-bolder text-dark">Status</label>
                <CustomSelect
                  options={context.type === INTERNAL ? C_STATUS_OPTIONS_INTERNAL : C_STATUS_OPTIONS}
                  matchFormControl={true}
                  value={status}
                  isClearable={true}
                  placeholder={"All States"}
                  onChange={onStatusChange}
                />
              </div>
            )}
            <div className="col-lg-3 col-md-8 col-12 ">
              <label className="fs-6 form-label fw-bolder text-dark">Commodity Group</label>
              <CustomSelect
                options={C_COMMODITYGROUP_OPTIONS}
                matchFormControl={true}
                value={commodityGroup}
                isClearable={true}
                placeholder={"All Commodity Groups"}
                onChange={onCommodityGroupChange}
              />
            </div>
          </div>
          {context.type === INTERNAL && listingType === COMMODITY && (
            <div className="row g-8">
              <div className="col">
                <button onClick={onXLSXExport} className="btn btn-light float-right mt-6">
                  Export XLSX
                </button>
              </div>
            </div>
          )}
        </>
      </div>
    );
  }
}

export default CommodityListingFilter;
