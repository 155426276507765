import React, { PureComponent, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { DataContextInternal, DataContextInternalType } from "../../../../../context/dataContext";
import {
  AdjustedCalculationEntry,
  SO_T_CALCULATIONUPDATED,
  SupplierOrderExtended,
} from "../../../../../model/supplierOrder.types";
import {
  convertAndFormatCurrency,
  convertCurrency,
  Currencies,
  DEFAULT_SO_CURRENCY_OPTION,
  EXCHANGERATE_BASE,
  SUPPORTED_CURRENCIES_OPTIONS,
} from "../../../../../utils/currencyUtils";
import { T_AIRFREIGHT, T_EUSTOCK } from "../../../../../model/customerOrder.types";
import CustomSelect, { SelectOption } from "../../../../common/CustomSelect";
import {
  AirFreightCalculationValues,
  calculateAirFreightPrice,
  calculateEUStockPrice,
  calculateSeaFreightPrice,
  EUStockCalculationValues,
  getDefaultAirFreightCalculationsValues,
  getDefaultEUStockCalculationsValues,
  getDefaultSeaFreightCalculationsValues,
  getSupplierOrderCalculation,
  isAirFreightValues,
  isEUStockValues,
  isSeaFreightValues,
  SeaFreightCalculationValues,
} from "../../../../../utils/priceCalculationUtils";
import { isFinishedProduct } from "../../../../../utils/finishedProductUtils";
import { formatArticleUnit, isAnyFinishedProduct } from "../../../../../utils/productArticleUtils";
import { Input } from "../../../../common/Input";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import { SUPPLIERORDER, transaction, UpdateAction } from "../../../../../services/dbService";
import { getCalculationDetails } from "../../../../../utils/orderCalculationUtils";
import {
  ADJUSTABLE_CALCULATION_VALUES,
  getAdjustableFieldDescription,
  getOrderStateRanking,
  getSupplierOrderTimelineEntry,
  getValueForAdjustableField,
} from "../../../../../utils/supplierOrderUtils";
import { ceil, countDecimals, formatCurrency, toAbsoluteUrl } from "../../../../../utils/baseUtils";
import { Seaport } from "../../../../../model/seaport.types";
import { Airport } from "../../../../../model/airport.types";
import { resolveFilePath } from "../../../../../utils/fileUtils";

interface SupplierOrderCalculationProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

interface SupplierOrderCalculationState {
  currency: SelectOption;
  edit: boolean;
}

class SupplierOrderCalculation extends PureComponent<SupplierOrderCalculationProps, SupplierOrderCalculationState> {
  constructor(props: SupplierOrderCalculationProps) {
    super(props);
    this.state = {
      currency:
        SUPPORTED_CURRENCIES_OPTIONS.find((sco) => sco.value === props.order.currency) || DEFAULT_SO_CURRENCY_OPTION,
      edit: false,
    };
  }

  handleChanceCurrency = (currency: SelectOption) => this.setState({ currency });

  handleToggleEdit = () => {
    const currency = { ...this.state.currency };
    if (!this.state.edit) {
      currency.value = this.props.order.currency;
      currency.label = this.props.order.currency;
    }
    this.setState({ edit: !this.state.edit, currency });
  };

  render() {
    const { order, context } = this.props;
    const { currency, edit } = this.state;
    const currencies = order.exchangeRates || context.currencies;

    // On edit, we stay in the order currency
    const selectedCurrency = edit ? order.currency : currency.value;
    const isFP = isAnyFinishedProduct(order.commodity);

    let transportAdjusted, customsAdjusted, warehouseAdjusted, insuranceAdjusted, followUpAdjusted;
    if (order.calculationDetails?.adjustedCalculation) {
      transportAdjusted = getValueForAdjustableField(order, ADJUSTABLE_CALCULATION_VALUES.TRANSPORT)?.reduce(
        (sum, p) => sum + p.value,
        0
      );
      customsAdjusted = getValueForAdjustableField(order, ADJUSTABLE_CALCULATION_VALUES.CUSTOMS)?.reduce(
        (sum, p) => sum + p.value,
        0
      );
      warehouseAdjusted = getValueForAdjustableField(order, ADJUSTABLE_CALCULATION_VALUES.WAREHOUSE)?.reduce(
        (sum, p) => sum + p.value,
        0
      );
      insuranceAdjusted = getValueForAdjustableField(order, ADJUSTABLE_CALCULATION_VALUES.INSURANCE)?.reduce(
        (sum, p) => sum + p.value,
        0
      );
      followUpAdjusted = getValueForAdjustableField(order, ADJUSTABLE_CALCULATION_VALUES.FOLLOWUP)?.reduce(
        (sum, p) => sum + p.value,
        0
      );
    }

    return (
      <>
        <div className="card bg-white">
          <div className="card-header border-0 mt-5">
            <div className="card-title flex-column">
              <h2 className="mb-1">Calculation</h2>
            </div>
            <div className="card-toolbar w-25">
              <div className="w-100">
                <CustomSelect
                  options={SUPPORTED_CURRENCIES_OPTIONS}
                  value={currency}
                  matchFormControl={true}
                  disabled={edit}
                  onChange={this.handleChanceCurrency}
                />
              </div>
            </div>
          </div>
          <div className="card-body p-9 pt-0">
            <div className="table-responsive mt-5 pt-2 bg-light2">
              <table className="table fw-bold gy-1">
                <tbody>
                  <tr>
                    <td className="text-white w-50">Order Currency</td>
                    <td className="text-muted">{order.currency}</td>
                  </tr>
                  <tr>
                    <td className="text-white w-50">{isFP ? "Finished Products" : "Commodities"}</td>
                    <td className="text-muted">
                      {convertAndFormatCurrency(order.priceCommodities, order.currency, selectedCurrency, currencies)} (
                      {convertAndFormatCurrency(
                        order.priceCommodities / order.amount,
                        order.currency,
                        selectedCurrency,
                        currencies
                      )}{" "}
                      per {order.unit})
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-50">Transport</td>
                    <td className="text-muted">
                      {transportAdjusted !== undefined ? (
                        <span>
                          <s>
                            {convertAndFormatCurrency(
                              order.priceTransport -
                                (order.calculationDetails?.details.finalValues.totalB2bFollowUpCost ?? 0),
                              order.currency,
                              selectedCurrency,
                              currencies
                            )}
                          </s>
                          <i className="fas fa-arrow-right mx-2" />
                          {convertAndFormatCurrency(transportAdjusted, order.currency, selectedCurrency, currencies)}
                        </span>
                      ) : (
                        convertAndFormatCurrency(
                          order.priceTransport -
                            (order.calculationDetails?.details.finalValues.totalB2bFollowUpCost ?? 0),
                          order.currency,
                          selectedCurrency,
                          currencies
                        )
                      )}
                    </td>
                  </tr>
                  {order.calculationDetails?.details.finalValues.totalB2bFollowUpCost && (
                    <tr>
                      <td className="text-white w-50">B2B Follow Up Cost</td>
                      <td className="text-muted">
                        {followUpAdjusted !== undefined ? (
                          <span>
                            <s>
                              {convertAndFormatCurrency(
                                order.calculationDetails.details.finalValues.totalB2bFollowUpCost,
                                order.currency,
                                selectedCurrency,
                                currencies
                              )}
                            </s>
                            <i className="fas fa-arrow-right mx-2" />
                            {convertAndFormatCurrency(followUpAdjusted, order.currency, selectedCurrency, currencies)}
                          </span>
                        ) : (
                          convertAndFormatCurrency(
                            order.calculationDetails.details.finalValues.totalB2bFollowUpCost,
                            order.currency,
                            selectedCurrency,
                            currencies
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-white w-50">Customs</td>
                    <td className="text-muted">
                      {customsAdjusted !== undefined ? (
                        <span>
                          <s>
                            {convertAndFormatCurrency(order.priceCustoms, order.currency, selectedCurrency, currencies)}
                          </s>
                          <i className="fas fa-arrow-right mx-2" />
                          {convertAndFormatCurrency(customsAdjusted, order.currency, selectedCurrency, currencies)}
                        </span>
                      ) : (
                        convertAndFormatCurrency(order.priceCustoms, order.currency, selectedCurrency, currencies)
                      )}
                    </td>
                  </tr>
                  {order.calculationDetails?.details.finalValues.totalWarehouseHandlingCost && (
                    <tr>
                      <td className="text-white w-50">Total Warehouse Handling Cost</td>
                      <td className="text-muted">
                        {warehouseAdjusted !== undefined ? (
                          <span>
                            <s>
                              {convertAndFormatCurrency(
                                order.calculationDetails?.details.finalValues.totalWarehouseHandlingCost,
                                order.currency,
                                selectedCurrency,
                                currencies
                              )}
                            </s>
                            <i className="fas fa-arrow-right mx-2" />
                            {convertAndFormatCurrency(warehouseAdjusted, order.currency, selectedCurrency, currencies)}
                          </span>
                        ) : (
                          convertAndFormatCurrency(
                            order.calculationDetails?.details.finalValues.totalWarehouseHandlingCost,
                            order.currency,
                            selectedCurrency,
                            currencies
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  {order.calculationDetails &&
                    "totalInsuranceCost" in order.calculationDetails.details.finalValues &&
                    order.calculationDetails.details.finalValues.totalInsuranceCost && (
                      <tr>
                        <td className="text-white w-50">Insurance</td>
                        <td className="text-muted">
                          {insuranceAdjusted !== undefined ? (
                            <span>
                              <s>
                                {convertAndFormatCurrency(
                                  order.calculationDetails.details.finalValues.totalInsuranceCost,
                                  order.currency,
                                  selectedCurrency,
                                  currencies
                                )}{" "}
                                {"insurance" in order.calculationDetails.details.baseValues && (
                                  <span>
                                    (
                                    {order.calculationDetails.details.baseValues.insurance.insuranceValue.toLocaleString()}{" "}
                                    % of article {order.transport === T_AIRFREIGHT ? "and freight " : ""}cost
                                    {"marginQuote" in order.calculationDetails.details.baseValues.insurance &&
                                      ` + ${order.calculationDetails.details.baseValues.insurance.marginQuote} % margin quote`}
                                    )
                                  </span>
                                )}
                              </s>
                              <i className="fas fa-arrow-right mx-2" />
                              {convertAndFormatCurrency(
                                insuranceAdjusted,
                                order.currency,
                                selectedCurrency,
                                currencies
                              )}
                            </span>
                          ) : (
                            <span>
                              {convertAndFormatCurrency(
                                order.calculationDetails.details.finalValues.totalInsuranceCost,
                                order.currency,
                                selectedCurrency,
                                currencies
                              )}{" "}
                              {"insurance" in order.calculationDetails.details.baseValues && (
                                <span>
                                  (
                                  {order.calculationDetails.details.baseValues.insurance.insuranceValue.toLocaleString()}{" "}
                                  % of article {order.transport === T_AIRFREIGHT ? "and freight " : ""}cost
                                  {"marginQuote" in order.calculationDetails.details.baseValues.insurance &&
                                    ` + ${order.calculationDetails.details.baseValues.insurance.marginQuote} % margin quote`}
                                  )
                                </span>
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                    )}
                  {order.totalBuffer > 0 && (
                    <tr>
                      <td className="text-white w-50">Buffer</td>
                      <td className="text-muted">
                        {convertAndFormatCurrency(order.totalBuffer, order.currency, selectedCurrency, currencies)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-white w-50">Total Cost</td>
                    <td className="text-muted">
                      {convertAndFormatCurrency(order.totalPrice, order.currency, selectedCurrency, currencies)} (
                      {convertAndFormatCurrency(
                        order.totalPrice / order.amount,
                        order.currency,
                        selectedCurrency,
                        currencies
                      )}{" "}
                      per {formatArticleUnit(order.unit, order.commodity)})
                    </td>
                  </tr>
                  {order.totalWarehouse !== undefined && order.totalWarehouse > 0 && (
                    <tr>
                      <td className="text-white w-50">Total Stock Value</td>
                      <td className="text-white">
                        {convertAndFormatCurrency(order.totalWarehouse, order.currency, selectedCurrency, currencies)}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="text-white w-50">Total Turnover</td>
                    <td className={order.totalTurnover <= 0 ? "text-danger" : "text-success"}>
                      {convertAndFormatCurrency(order.totalTurnover, order.currency, selectedCurrency, currencies)}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-50">Total Margin</td>
                    <td className={order.totalMargin <= 0 ? "text-danger" : "text-success"}>
                      {convertAndFormatCurrency(order.totalMargin, order.currency, selectedCurrency, currencies)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {edit ? (
              <SupplierOrderRecalculation order={order} context={context} onToggleEdit={this.handleToggleEdit} />
            ) : (
              <div className="mt-5 text-right">
                <ErrorOverlayButton
                  errors={
                    order.calculationDetails?.adjustedCalculation
                      ? ["Calculation was adjusted. To make values editable remove the adjusted calculation first."]
                      : []
                  }
                  className="btn btn-sm btn-light"
                  onClick={this.handleToggleEdit}
                >
                  Edit
                </ErrorOverlayButton>
              </div>
            )}
            <AdjustedCalculation order={order} currency={currency.value} />
            {order.exchangeRates && (
              <>
                <div className="card-title flex-column mt-5">
                  <h2 className="mb-1">Exchange Rates</h2>
                </div>
                <div className="table-responsive mt-5 pt-2 bg-light2">
                  <table className="table fw-bold gy-1 ">
                    <tbody>
                      {Object.entries(order.exchangeRates)
                        .filter((e) => e[0] !== EXCHANGERATE_BASE)
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td className="text-white">
                              1 {EXCHANGERATE_BASE} = {value} {key}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

interface SupplierOrderRecalculationProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
  onToggleEdit: () => void;
}

interface SupplierOrderRecalculationState {
  amount: number;
  costPerUnit: number;
  totalCommodityCost: number;
  saving: boolean;
}

const SupplierOrderRecalculation: React.FC<SupplierOrderRecalculationProps> = ({ order, context, onToggleEdit }) => {
  const [state, setState] = useState<SupplierOrderRecalculationState>({
    amount: order.amount,
    costPerUnit: order.priceCommodities / order.amount,
    totalCommodityCost: order.priceCommodities,
    saving: false,
  });

  const article = order.commodity;
  const isFP = isFinishedProduct(article);

  /**
   * Effect that updates the state in case the order is updated from outside
   */
  useEffect(() => {
    setState({
      ...state,
      amount: order.amount,
      costPerUnit: order.priceCommodities / order.amount,
      totalCommodityCost: order.priceCommodities,
    });
  }, [order]);

  /**
   * Handles changes of the numeric values in the supplier calculation.
   * @param e Event that triggered the update
   */
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let numVal = Number(value);
    switch (name) {
      case "amount":
        setState((prevState) => {
          // In case the amount is adjusted we need to recalculate the total cost
          return { ...prevState, amount: numVal, totalCommodityCost: prevState.costPerUnit * numVal };
        });
        break;
      case "costPerUnit":
        setState((prevState) => {
          if (countDecimals(numVal) > 2) numVal = ceil(numVal, 2);
          // In case the cost per unit is adjusted we need to recalculate the total cost
          return { ...prevState, costPerUnit: numVal, totalCommodityCost: numVal * prevState.amount };
        });
        break;
      case "totalCommodityCost":
        setState((prevState) => {
          let costPerUnit = numVal / prevState.amount;
          if (countDecimals(costPerUnit) > 2) {
            costPerUnit = ceil(costPerUnit, 2);
            numVal = costPerUnit * prevState.amount;
          }
          // In case the total cost is adjusted we need to recalculate the cost per unit (makes more sense than adjusting amount)
          return { ...prevState, costPerUnit, totalCommodityCost: numVal };
        });
        break;
    }
  };

  /**
   * Handles updating the supplier order calculation.
   */
  const handleUpdateSupplierOrderCalculation = async () => {
    const { amount, costPerUnit } = state;
    const { currencies } = context;
    const followUpCostEUStock = order.calculationDetails?.details.baseValues.b2bFollowUpCost;
    setState({ ...state, saving: true });
    try {
      const calculationValues: EUStockCalculationValues | AirFreightCalculationValues | SeaFreightCalculationValues =
        order.transport === T_EUSTOCK
          ? await getDefaultEUStockCalculationsValues(amount, isFP ? article.weightPerUnit : undefined)
          : order.transport === T_AIRFREIGHT
          ? await getDefaultAirFreightCalculationsValues(
              article,
              amount,
              isFP ? article.weightPerUnit : undefined,
              order.shipment.length > 0 ? (order.shipment[0].shipping.startingPoint as Airport) : undefined
            )
          : await getDefaultSeaFreightCalculationsValues(
              context,
              article,
              amount,
              isFP ? article.weightPerUnit : undefined,
              order.shipment.length > 0 ? (order.shipment[0].shipping.startingPoint as Seaport) : undefined
            ); // default if no order exists
      let calculation;
      if (isSeaFreightValues(calculationValues))
        calculation = await calculateSeaFreightPrice(
          amount,
          0,
          { price: costPerUnit, currency: order.currency },
          calculationValues,
          order.exchangeRates ?? context.currencies,
          order.currency
        );
      else if (isAirFreightValues(calculationValues)) {
        calculation = await calculateAirFreightPrice(
          amount,
          0,
          { price: costPerUnit, currency: order.currency },
          calculationValues,
          order.exchangeRates ?? context.currencies,
          order.currency
        );
      } else if (isEUStockValues(calculationValues) && followUpCostEUStock !== undefined) {
        calculation = await calculateEUStockPrice(
          amount,
          0,
          { price: costPerUnit, currency: order.currency },
          calculationValues,
          order.exchangeRates ?? context.currencies,
          followUpCostEUStock,
          order.currency
        );
      }
      if (!calculation) return;
      const actions: Array<UpdateAction> = [];
      const calculationDetails = getCalculationDetails(calculation, calculationValues);
      const warehouseAmount = order.warehouseAmount - (order.amount - amount);
      const calc = getSupplierOrderCalculation(
        order.customerOrders.reduce(
          (sum, c) =>
            sum +
            convertCurrency(
              c.totalPrice * (1 - (c.discount || 0) / 100),
              c.currency,
              order.currency,
              order.exchangeRates ?? currencies
            ),
          0
        ) +
          (order.customerContracts
            ? order.customerContracts.reduce(
                (sum, c) =>
                  sum +
                  convertCurrency(
                    c.priceInformation.totalPrice * (1 - (c.priceInformation.discount || 0) / 100),
                    c.priceInformation.currency,
                    order.currency,
                    order.exchangeRates ?? currencies
                  ),
                0
              )
            : 0),
        calculation
      );
      const totalWarehouse = (calc.commodityCost / amount) * warehouseAmount;
      actions.push({
        collection: SUPPLIERORDER,
        filter: { _id: order._id },
        update: {
          amount,
          calculationDetails,
          warehouseAmount,
          totalTurnover: calc.totalTurnover,
          priceCommodities: calc.commodityCost,
          priceTransport: calc.transportCost,
          priceCustoms: calc.customsCost ?? 0,
          totalWarehouse,
          totalPrice: calc.totalPrice,
          totalMargin: calc.totalMargin + totalWarehouse,
        },
        push: {
          timeline: getSupplierOrderTimelineEntry(SO_T_CALCULATIONUPDATED, { calculation: getTimelineDiffString() }),
        },
      });
      const res = await transaction(actions);
      if (res) {
        toast.success("Supplier Order updated successfully");
        onToggleEdit();
      } else {
        toast.error("Error updating Supplier Order");
      }
      return calculation;
    } catch (e) {
      console.error("ERROR:", e);
      toast.error("Error updating Supplier Order");
    } finally {
      setState({ ...state, saving: false });
    }
  };

  /**
   * Validates the user input. Total commodity price is not checked since amount or cost per unit checks already include
   * this.
   * @returns {Array<string>} List of errors
   */
  const validateData = useMemo(() => {
    const errors: Array<string> = [];
    const { amount, costPerUnit } = state;
    if (amount <= 0) {
      errors.push("Amount has to be positive");
    } else if (state.amount < order.customerOrders.reduce((sum, cO) => sum + cO.amount, 0)) {
      errors.push("Amount too low to fulfill related customer orders");
    }
    if (costPerUnit <= 0) {
      errors.push("Cost per unit has to be positive");
    }
    return errors;
  }, [state.amount, state.costPerUnit]);

  /**
   * Builds the diff string for the timeline which is used to properly show the changes in timeline.
   * @returns {string} Timeline diff string
   */
  const getTimelineDiffString = (): string => {
    const { amount, costPerUnit, totalCommodityCost } = state;
    const unitPrice = order.priceCommodities / order.amount;
    const unit = formatArticleUnit(order.unit, order.commodity);
    let diffString = "";
    if (amount !== order.amount && costPerUnit !== unitPrice) {
      diffString += `Amount and Cost per Unit where changed\n${
        order.amount
      }${unit} to ${amount}${unit}\n${formatCurrency(unitPrice, order.currency)} to ${formatCurrency(
        costPerUnit,
        order.currency
      )}`;
    } else if (amount !== order.amount) {
      diffString += `Amount was changed\n${order.amount}${unit} to ${amount}${unit}`;
    } else if (costPerUnit !== unitPrice) {
      diffString += `Cost per Unit was changed\n${formatCurrency(unitPrice, order.currency)} to ${formatCurrency(
        costPerUnit,
        order.currency
      )}`;
    }
    // If amount or cost were changed the total price was also changed due to this
    if (amount !== order.amount || costPerUnit !== unitPrice) {
      diffString += `\nTotal Commodities Price changed\n${formatCurrency(
        order.priceCommodities,
        order.currency
      )} to ${formatCurrency(totalCommodityCost, order.currency)}`;
    }
    return diffString;
  };

  return (
    <>
      <div className="row mt-5">
        <div className="col-12 mb-5">
          <h2 className="mb-1">Adjusted Values</h2>
        </div>
        <div className="col-12 col-sm-6 mb-2 align-content-center">
          <span className="text-white">Amount</span>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <div className="input-group">
            <Input
              type="number"
              name="amount"
              value={state.amount}
              min={0}
              disabled={
                getOrderStateRanking(order) > 4 || context.batch.some((b) => b.supplierOrder === order._id.toString())
              }
              onChange={handleChangeValue}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {formatArticleUnit(order.unit, order.commodity)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-2 align-content-center">
          <span className="text-white">Cost per Unit</span>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <div className="input-group">
            <Input type="number" name="costPerUnit" value={state.costPerUnit} min={0} onChange={handleChangeValue} />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {order.currency}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-2 align-content-center">
          <span className="text-white">Total {isFP ? "Finished Product" : "Commodity"} Cost</span>
        </div>
        <div className="col-12 col-sm-6 mb-2">
          <div className="input-group">
            <Input
              type="number"
              name="totalCommodityCost"
              value={state.totalCommodityCost}
              min={0}
              onChange={handleChangeValue}
            />
            <div className="input-group-append rounded-end bg-custom-light-gray">
              <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                {order.currency}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 text-right">
        <ErrorOverlayButton className="btn btn-sm btn-light mr-2" saving={state.saving} onClick={onToggleEdit}>
          Cancel
        </ErrorOverlayButton>
        <ErrorOverlayButton
          className="btn btn-sm btn-light"
          errors={validateData}
          saving={state.saving}
          onClick={handleUpdateSupplierOrderCalculation}
        >
          Save
        </ErrorOverlayButton>
      </div>
    </>
  );
};

interface AdjustedCalculationProps {
  order: SupplierOrderExtended;
  currency: string;
}

const AdjustedCalculation: React.FC<AdjustedCalculationProps> = ({ order, currency }) => {
  const context = useContext(DataContextInternal);

  if (!order.calculationDetails?.adjustedCalculation) return null;

  return (
    <>
      <div className="card-title flex-column mt-5">
        <h2 className="mb-1">Adjusted Calculation</h2>
      </div>
      <div className="table-responsive mt-5 pt-2 bg-light2">
        <table className="table fw-bold gy-1">
          <tbody>
            {Object.values(ADJUSTABLE_CALCULATION_VALUES).map((aCV) => {
              const val = getValueForAdjustableField(order, aCV);
              if (val) {
                return (
                  <tr key={aCV}>
                    <td className="text-white w-25">{getAdjustableFieldDescription(aCV)}</td>
                    <td className="text-muted">
                      <AdjustedCalculationListing
                        entries={val}
                        orderCurrency={order.currency}
                        targetCurrency={currency}
                        currencies={order.exchangeRates ?? context.currencies}
                      />
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

interface AdjustedCalculationListingProps {
  entries: Array<AdjustedCalculationEntry>;
  orderCurrency: string;
  targetCurrency: string;
  currencies: Currencies;
}

const AdjustedCalculationListing: React.FC<AdjustedCalculationListingProps> = ({
  entries,
  orderCurrency,
  targetCurrency,
  currencies,
}) => {
  return (
    <>
      <div className="row">
        {entries.map((e, idx) => (
          <div key={idx} className="row mt-1">
            <div className="col-12 col-sm-6">{e.description}</div>
            <div className="col-2">
              {e.document ? (
                <a href={resolveFilePath(e.document.path)} target="_blank" rel="noopener noreferrer">
                  <img
                    alt="Rawbids CoA"
                    src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                    style={{ height: 16 }}
                  />
                </a>
              ) : (
                <img
                  alt="Rawbids CoA"
                  src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                  style={{ height: 16, opacity: 0.25 }}
                />
              )}
            </div>
            <div className="col-4 text-right">
              {convertAndFormatCurrency(e.value, orderCurrency, targetCurrency, currencies)}
            </div>
          </div>
        ))}
        <div className="row mt-1">
          <div className="col-12 col-sm-6">Total</div>
          <div className="col-12 col-sm-6 text-right text-white fw-bolder">
            {convertAndFormatCurrency(
              entries.reduce((sum, p) => sum + p.value, 0),
              orderCurrency,
              targetCurrency,
              currencies
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierOrderCalculation;
