import React, { Component } from "react";

interface SplashScreenProps {
  description: string;
  withoutBorder?: boolean;
}

interface SplashScreenState {}

class SplashScreen extends Component<SplashScreenProps, SplashScreenState> {
  render() {
    const { description, withoutBorder } = this.props;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container ">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-lg-row-fluid ">
                <div className={"card bg-white " + (withoutBorder && "border-none")} style={{ minHeight: "75vh" }}>
                  <div className="flex-grow-1 d-flex justify-content-center  align-items-center">
                    <div className="">
                      <div className="splash-screen-relative">
                        <svg
                          className="splash-spinner"
                          viewBox="0 0 100 100"
                          style={{ height: "100px", width: "100px" }}
                        >
                          <circle className="path" cx="50" cy="50" r="25" fill="none" strokeWidth="4" />
                        </svg>
                      </div>
                      <h6 className="text-center text-white mt-1">{description}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SplashScreen;
