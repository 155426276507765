import React from "react";
import { ForwarderExtended } from "../../../model/forwarder.types";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import PersonSummaryWidget from "../../common/PersonSummaryWidget";
import CreateContactModal from "../../common/internal/modals/CreateContactModal";

interface ForwarderPageSummaryProps {
  forwarder: ForwarderExtended;
}

const ForwarderPageSummary: React.FunctionComponent<ForwarderPageSummaryProps> = ({ forwarder }) => {
  return (
    <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
      <div className="card-body">
        <div className="d-flex flex-center flex-column py-5">
          <span className="fs-2 text-gray-800 fw-bolder mb-1 text-center">
            {forwarder.disabled && <span className="text-danger mr-2">[DISABLED]</span>}
            {forwarder.name}
          </span>
          <div className="d-flex flex-center flex-column py-1">
            <CompanyCountryWidget company={forwarder} wrapperClasses={"fs-6 fw-bold text-muted mb-6"} />
          </div>
        </div>
        <div className="d-flex flex-stack fs-4 py-3 mt-10">
          <div className="fw-bolder">Contact</div>
        </div>
        <div className="border-bottom-dark-gray" />
        <PersonSummaryWidget
          company={forwarder}
          type={"forwarder"}
          person={forwarder.primaryPerson}
          description={"Primary Contact"}
        />
        {forwarder.persons.map((person, idx) => (
          <PersonSummaryWidget
            company={forwarder}
            type={"forwarder"}
            isAsPrimaryAssignable
            isRemovable
            key={person._id.toString()}
            description={"Contact #" + (idx + 1)}
            person={person}
          />
        ))}
        <CreateContactModal company={forwarder} type={"forwarder"} />
      </div>
    </div>
  );
};

export default ForwarderPageSummary;
