import _ from "lodash";
import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { CUSTOMERCONTRACT, getDocumentDB } from "../../../services/dbService";
import {
  CustomerCustomerContract,
  CustomerCustomerContractExtended,
} from "../../../model/customer/customerCustomerContract.types";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import CustomerContractTabPanel from "../customer/CustomerContractTabPanel";
import CustomerContractCalls from "../customer/CustomerContractCalls";

import { extendCustomerCustomerContract } from "../../../utils/dataTransformationUtils";

interface CustomerContractParams {
  id: string;
}

interface CustomerContractPageProps extends RouteComponentProps<CustomerContractParams> {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CustomerContractPageState {
  contract?: CustomerCustomerContractExtended;
  calls?: Array<CustomerCustomerOrder>;
}

class CustomerContractPage extends Component<CustomerContractPageProps, CustomerContractPageState> {
  _isMounted = false;
  constructor(props: CustomerContractPageProps) {
    super(props);
    const contract = getDocFromCollection(props.context.customerContract, props.match.params.id);
    this.state = {
      contract: contract ? extendCustomerCustomerContract(contract, props.context) : undefined,
      calls: this.getRelatedCustomerOrders(contract),
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.contract) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/contracts");
      return;
    }
    this._isMounted = true;
    const contract = await getDocumentDB<CustomerCustomerContract>(CUSTOMERCONTRACT, id);
    if (!contract) {
      console.error("No contract could be loaded for id", id);
      toast.error("The requested contract could not be loaded");
      history.push("/contracts");
      return;
    }
    context.addDocuments(CUSTOMERCONTRACT, [contract]);
    if (this._isMounted)
      this.setState({
        contract: extendCustomerCustomerContract(contract, context),
        calls: this.getRelatedCustomerOrders(contract),
      });
  };

  componentDidUpdate = (
    prevProps: Readonly<CustomerContractPageProps>,
    prevState: Readonly<CustomerContractPageState>
  ) => {
    const { match, context } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const contractDB = getDocFromCollection(this.props.context.customerContract, this.props.match.params.id);
      const contract = contractDB ? extendCustomerCustomerContract(contractDB, context) : undefined;
      if (!_.isEqual(contract, this.state.contract)) {
        this.setState({
          contract,
          calls: this.getRelatedCustomerOrders(contractDB),
        });
      } else if (
        !_.isEqual(prevState.contract, this.state.contract) ||
        !_.isEqual(context.customerOrder, prevProps.context.customerOrder)
      ) {
        this.setState({
          calls: this.getRelatedCustomerOrders(contractDB),
        });
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Retrieve the customer orders related to the customer contract.
   * @param contract Optional, contract to get calls for
   * @returns { Array<CustomerCustomerOrder> | undefined } List of related calls
   */
  getRelatedCustomerOrders = (contract?: CustomerCustomerContract): Array<CustomerCustomerOrder> | undefined => {
    const { context } = this.props;
    return contract
      ? context.customerOrder.filter((cO) => contract.calls.some((c) => c._id.toString() === cO._id.toString())).slice()
      : undefined;
  };

  render() {
    const { context } = this.props;
    const { contract, calls } = this.state;
    if (!contract) return <div />;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10 order-1 order-xl-0">
                <CustomerContractCalls contract={contract} context={context} calls={calls} />
              </div>
              <CustomerContractTabPanel contract={contract} context={context} calls={calls} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerContractPage;
