import React, { useEffect, useState } from "react";
import { getNumericValue } from "../../utils/baseUtils";

interface InputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  integerOnly?: boolean;
  allowNegative?: boolean;
}

export const Input: React.FunctionComponent<InputProps> = ({
  type,
  value,
  onChange,
  onBlur,
  integerOnly,
  allowNegative,
  ...props
}) => {
  const [internalVal, setValue] = useState(value);
  if (onBlur && onChange) console.error("onBlur and onChange given. onChange will not be utilized");
  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <input
      {...props}
      className={(props.className ?? "form-control custom-form-control") + (type === "number" ? " hide-arrows" : "")}
      type={type}
      value={internalVal}
      onChange={(e) =>
        setValue(
          type === "number"
            ? integerOnly
              ? Number(parseInt(e.target.value) || "0").toString()
              : getNumericValue(e, allowNegative)
            : e.target.value
        )
      }
      onBlur={onBlur ? onBlur : onChange}
    />
  );
};
