import React, { PureComponent } from "react";
import Search from "../../common/Search";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { C_GENERALFILTEROPTIONS, C_SORTOPTIONS } from "../../../utils/companyUtils";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";

interface CustomerListingFilterProps {
  search: string;
  generalFilter: SelectOption | undefined;
  sortBy: SelectOption;
  sortOrder: SelectOption;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGeneralFilterChange: (generalFilter: SelectOption | undefined) => void;
  onSortByChange: (sortBy: SelectOption) => void;
  onSortOrderChange: (sortOrder: SelectOption) => void;
}

interface CustomerListingFilterState {}

class CustomerListingFilter extends PureComponent<CustomerListingFilterProps, CustomerListingFilterState> {
  render() {
    const {
      search,
      generalFilter,
      sortBy,
      sortOrder,
      onSearch,
      onGeneralFilterChange,
      onSortByChange,
      onSortOrderChange,
    } = this.props;
    const sortOptions = C_SORTOPTIONS.slice();
    if (search.trim()) sortOptions.unshift({ value: "", label: "Best Match" });
    return (
      <div className="card mb-10 bg-white border-none">
        <div className="row g-8 mb-4">
          <div className="col-12 col-lg-6">
            <Search value={search} onSearch={onSearch} placeholder={"Search for customers..."} />
          </div>
        </div>
        <div className="row g-8">
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">General Filter</label>
            <CustomSelect
              options={C_GENERALFILTEROPTIONS}
              value={generalFilter}
              placeholder={"All Customer"}
              isClearable={true}
              onChange={(e: SelectOption) => onGeneralFilterChange(e)}
            />
          </div>
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Sort By</label>
            <CustomSelect options={sortOptions} value={sortBy} onChange={(e: SelectOption) => onSortByChange(e)} />
          </div>
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Sort Order</label>
            <CustomSelect
              options={SORTORDEROPTIONS}
              value={sortOrder}
              onChange={(e: SelectOption) => onSortOrderChange(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerListingFilter;
