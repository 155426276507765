import _ from "lodash";
import { toast } from "react-toastify";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { DataContextInternalType } from "../../../../context/dataContext";
import { Commodity, CommodityExtended } from "../../../../model/commodity.types";
import { Company } from "../../../../model/company.types";
import { Supplier } from "../../../../model/supplier.types";
import {
  CustomerRequest,
  CustomerRequestState,
  CustomerRequestTimelineTypes,
  CustomerRequestType,
  StandardRequestData,
} from "../../../../model/customerRequest.types";
import CustomerTerms, { CustomerTermOptions } from "../../../common/CustomerTerms";
import { callPushToArray, ceil, countDecimals, getDocFromCollection, round } from "../../../../utils/baseUtils";
import {
  CO_ORDERCONFIRMATION,
  CO_STANDARDINCOTERM,
  getCustomerOrderTimelineEntry,
  getCustomerTermOptionFromCompany,
  insertCustomerOrder,
  sendSlackNotification,
} from "../../../../utils/customerOrderUtils";
import { getCustomerRequestTimelineEntry } from "../../../../utils/customerRequestUtils";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { STANDARDTEXTSHORT } from "../../../../utils/pdf/templateUtils";
import { CO_FOOTER_HTML, createPDF } from "../../../../utils/pdfUtils";
import { resolveFilePath } from "../../../../utils/fileUtils";
import { CUSTOMERORDER, CUSTOMERREQUEST, transaction, UpdateAction } from "../../../../services/dbService";
import {
  DateType,
  getFormattedCOTerms,
  O_TRANSPORTTYPES,
  O_TRANSPORTTYPES_INTERNAL,
} from "../../../../utils/orderUtils";
import {
  CO_ORDEREDBYCUSTOMER,
  CO_T_CREATED,
  CO_TRANSPORT,
  CustomerOrder,
  T_SEAFREIGHT,
} from "../../../../model/customerOrder.types";
import { CUSTOMER_BASE_CURRENCY } from "../../../../utils/currencyUtils";
import { I_PAYMENTTARGETS } from "../../../../utils/invoiceUtils";
import { Input } from "../../../common/Input";
import { Textarea } from "../../../common/Textarea";
import CalendarWeekSelector from "../../../common/CalendarWeekSelector";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import userService from "../../../../services/userService";
import { createOrderConfirmationHTML } from "../../../../utils/pdf/orderConfirmationGenerationUtils";
import OwnAddressSelector from "../../../common/OwnAddressSelector";
import CustomerRequestAdditionalInformation from "../../../common/internal/CustomerRequestAdditionalInformation";
import CreateCustomerOrderContractGeneralSettings from "../../../common/internal/CreateCustomerOrderContractGeneralSettings";
import CreateCustomerOrderContractCalculation from "../../../common/internal/CreateCustomerOrderContractCalculation";
import { AddressSelectOption, formatAddress, getAddressByType } from "../../../../utils/addressUtils";
import { AddressType } from "../../../../model/commonTypes";
import DateInput from "../../../common/DateInput";
import { ArticleExtended, getArticleSnapshot, isCommodity } from "../../../../utils/productArticleUtils";
import { FinishedProduct, FinishedProductExtended } from "../../../../model/finishedProduct.types";
import { INTERNAL } from "../../../../utils/userUtils";
import { extendCommodity, extendCustomerOrder, extendFinishedProduct } from "../../../../utils/dataTransformationUtils";
import { LabelDesign } from "../../../../utils/companyUtils";

export interface CreateCustomerOrderParams {
  id?: string;
  type?: string;
}

interface CreateCustomerOrderProps extends RouteComponentProps<CreateCustomerOrderParams> {
  context: DataContextInternalType;
}

interface CreateCustomerOrderState {
  saving: "draft" | "order" | false;
  targetDate: Date;
  targetDateType?: DateType;
  selectedArticle?: ArticleExtended;
  customer?: Company;
  preferredSupplier?: Supplier;
  method: CO_TRANSPORT;
  totalAmount: number;
  purchasePrice: number;
  pricePerUnit: number;
  discount: number;
  noteInternal: string;
  customerReference: string;
  shippingAddress?: AddressSelectOption;
  request?: CustomerRequest; // id of request
  terms: CustomerTermOptions;
}

class CreateCustomerOrder extends PureComponent<CreateCustomerOrderProps, CreateCustomerOrderState> {
  constructor(props: CreateCustomerOrderProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount = () => {
    const { match, context } = this.props;
    const { terms } = this.state;
    const { type, id } = match.params;
    if (!type || !id || !BSON.ObjectId.isValid(id)) {
      console.info("No type or id given");
      return;
    }
    if (type === "request") {
      const request = getDocFromCollection(context.customerRequest, id);
      if (!request) {
        toast.error("Request could not be loaded. This may indicate that the request was already handled.");
        return;
      }
      if (request.type !== CustomerRequestType.STANDARDREQUEST) {
        toast.error("Request is not a standard order request. Aborting");
        return;
      }
      const { commodity, company, amount, customerReference } = request;
      const requestData = request.requestData as StandardRequestData;
      let fullArticle: CommodityExtended | FinishedProductExtended | undefined;
      const com = getDocFromCollection(context.commodity, commodity._id);
      if (com) fullArticle = extendCommodity(com, context);
      else {
        const fp = getDocFromCollection(context.finishedProduct, commodity._id);
        if (fp) fullArticle = extendFinishedProduct(fp, context);
      }
      const fullCompany = getDocFromCollection(context.company, company._id);
      if (!fullArticle || !fullCompany)
        toast.warn(`Article or customer could not be found. Please make sure to select the correct one manually.`);
      const newTerms = fullCompany
        ? getCustomerTermOptionFromCompany(
            fullCompany,
            terms.title || `Order for ${fullCompany.name}`,
            terms.id.toString()
          )
        : terms;
      this.setState({
        selectedArticle: fullArticle,
        customer: fullCompany,
        totalAmount: amount,
        customerReference,
        request,
        terms: newTerms,
        targetDate: requestData.targetDate,
      });
    } else if (type === "customer") {
      const customer = getDocFromCollection(context.company, id);
      if (!customer) {
        toast.error("Customer could not be loaded. Please reload and try again.");
        return;
      }

      const shippingAddress = getAddressByType(customer.address, AddressType.A_SHIPPING);
      const newTerms = getCustomerTermOptionFromCompany(
        customer,
        terms.title || `Order for ${customer.name}`,
        terms.id.toString()
      );
      this.setState({
        customer,
        terms: newTerms,
        shippingAddress: shippingAddress
          ? {
              label: formatAddress(shippingAddress, { withoutBreak: true }),
              value: formatAddress(shippingAddress),
              address: shippingAddress,
            }
          : undefined,
      });
    } else {
      console.error(`Type ${type} is not available`);
    }
  };

  handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ noteInternal: e.target.value });

  handleChangeTargetDateType = (newType: DateType) => {
    const { targetDateType } = this.state;
    this.setState({ targetDateType: targetDateType === newType ? undefined : newType });
  };

  handleChangeDate = (e: React.ChangeEvent<HTMLInputElement> | Date) => {
    let targetDate;
    if (e instanceof Date) {
      targetDate = e;
    } else {
      targetDate = new Date(e.target.value);
    }
    if (!targetDate) return;
    this.setState({ targetDate });
  };

  handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value } = e.target;
    let val;
    if (["pricePerUnit", "purchasePrice"].includes(name) && countDecimals(+value) > 2) {
      val = ceil(+value, 2);
    } else {
      val = type === "number" ? +value : value;
    }
    // @ts-ignore
    this.setState({ [e.target.name]: val });
  };

  handleSelectArticle = (e: SelectOption<Commodity | FinishedProduct>) => {
    const { context } = this.props;
    const obj = e.object;
    const selectedArticle = obj
      ? isCommodity(obj, INTERNAL)
        ? extendCommodity(obj, context)
        : extendFinishedProduct(obj, context)
      : undefined;
    this.setState({ selectedArticle });
  };
  handleSelectSupplier = (e: SelectOption<Supplier>) =>
    this.setState({ preferredSupplier: e.object, method: O_TRANSPORTTYPES_INTERNAL[0].value as CO_TRANSPORT });

  handleSelectCustomer = (e: SelectOption<Company>) => {
    const { terms } = this.state;
    const customer = e.object;
    if (!customer) return; // Should never happen
    const shippingAddress = getAddressByType(customer.address, AddressType.A_SHIPPING);
    const newTerms = getCustomerTermOptionFromCompany(
      customer,
      terms.title || `Order for ${customer.name}`,
      terms.id.toString()
    );
    this.setState({
      customer,
      terms: newTerms,
      discount: customer.discount ?? 0,
      shippingAddress: shippingAddress
        ? {
            label: formatAddress(shippingAddress, { withoutBreak: true }),
            value: formatAddress(shippingAddress),
            address: shippingAddress,
          }
        : undefined,
    });
  };

  handleSelectAddress = (shippingAddress: AddressSelectOption) => {
    const { terms, customer } = this.state;
    if (customer) {
      const newTerms = _.cloneDeep(terms);
      newTerms.deliveryCity = shippingAddress.address.city;
      this.setState({ shippingAddress, terms: newTerms });
    } else {
      this.setState({ shippingAddress });
    }
  };

  handleChangeCustomerNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.note = e.currentTarget.value;
    this.setState({ terms });
  };

  handleChangeLabelDesign = (e: SelectOption) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.cleanLabel = e.value === LabelDesign.CLEAN;
    this.setState({ terms });
  };

  handleChangeCustomerPaymentTerms = (e: SelectOption) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.paymentTerm = e;
    this.setState({ terms });
  };

  handleChangeCustomerCustomPaymentTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    const terms = _.cloneDeep(this.state.terms);

    terms.customPaymentTerm = e.currentTarget.value;
    this.setState({ terms });
  };

  handleChangeCustomerCustomPaymentTermConditions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.customPaymentTermCondition = e.currentTarget.value;
    this.setState({ terms });
  };

  handleChangeCustomerDeliveryTerms = (e: SelectOption) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.deliveryTerm = e;
    this.setState({ terms });
  };

  handleChangeCustomerDeliveryCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.deliveryCity = e.currentTarget.value;
    this.setState({ terms });
  };

  handleChangeMethod = (e: SelectOption) => {
    const { targetDateType } = this.state;
    this.setState({
      method: e.value as CO_TRANSPORT,
      targetDateType: e.value === T_SEAFREIGHT && targetDateType === DateType.FIX ? undefined : targetDateType,
    });
  };

  handleCreateOrder = async () => {
    const { history, context } = this.props;
    const { request } = this.state;
    const order = this.getCustomerOrder();
    if (!order) return;
    this.setState({ saving: "order" });
    try {
      const result = await insertCustomerOrder(order);
      if (result && result.res && result.res.insertedId) {
        const orderExtended = extendCustomerOrder(order, context);
        orderExtended.orderNo = result.orderNumber;
        const path = await createPDF(
          createOrderConfirmationHTML(orderExtended, STANDARDTEXTSHORT, context.currencies),
          `Order-Confirmation-${result.orderNumber}`,
          orderExtended.company._id.toString(),
          {
            marginLeft: "2cm",
            marginBottom: "4.2cm",
            footerHtml: CO_FOOTER_HTML,
          }
        );
        if (path) {
          window.open(resolveFilePath(path));
          await callPushToArray(CUSTOMERORDER, result.res.insertedId, "files", {
            _id: new BSON.ObjectId(),
            date: new Date(),
            path,
            type: CO_ORDERCONFIRMATION,
          });
        }
        if (request) {
          const requestAction: UpdateAction = {
            collection: CUSTOMERREQUEST,
            filter: { _id: request._id },
            update: { state: CustomerRequestState.CLOSED },
            push: { timeline: getCustomerRequestTimelineEntry(CustomerRequestTimelineTypes.CLOSED) },
          };
          const res = await transaction([requestAction]);
          if (!res) toast.info("Request could not be closed. Please close it manually");
        }

        toast.success("Order successfully created.");
        sendSlackNotification(orderExtended, order._id.toString(), orderExtended.company);
        history.push("/customerOrder/" + result.res.insertedId.toString());
      } else {
        toast.error("Order could not be created. Please try again later");
      }
    } catch (e) {
      toast.error("Order could not be created: " + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { pricePerUnit, totalAmount, purchasePrice, selectedArticle, customer, targetDate, shippingAddress } =
      this.state;
    const errors: Array<string> = [];
    if (!customer) {
      errors.push("Select a customer");
      return errors;
    }
    if (!selectedArticle) {
      errors.push("Select an article");
      return errors;
    }
    if (!targetDate) errors.push("Please select a target date");
    if (!shippingAddress) errors.push("Please select a destination");
    if (pricePerUnit <= 0) errors.push("Please enter a valid price per unit");
    if (purchasePrice <= 0) errors.push("Please enter a valid purchase price");
    if (totalAmount <= 0) errors.push("Please enter a valid article amount");

    return errors;
  };

  getCustomerOrder = () => {
    const {
      selectedArticle,
      customer,
      preferredSupplier,
      customerReference,
      totalAmount,
      purchasePrice,
      pricePerUnit,
      discount,
      noteInternal,
      terms,
      targetDate,
      targetDateType,
      request,
      method,
      shippingAddress,
    } = this.state;
    if (!selectedArticle || !customer || !targetDate || !shippingAddress) return;

    const totalPrice = round(totalAmount * pricePerUnit, 2);
    const totalPurchasePrice = round(totalAmount * purchasePrice, 2);
    const termsDB = getFormattedCOTerms(terms);
    const order: CustomerOrder = {
      amount: totalAmount,
      currency: CUSTOMER_BASE_CURRENCY,
      destination: shippingAddress.address,
      services: [],
      transport: method,
      unit: selectedArticle.unit || "kg",
      _id: new BSON.ObjectId(),
      orderNo: "-1",
      createdAt: new Date(),
      targetDate,
      targetDateType,
      noteCustomer: "",
      customerReference,
      noteInternal: noteInternal.trim()
        ? [{ _id: new BSON.ObjectId(), note: noteInternal, person: userService.getUserId(), date: new Date() }]
        : [],
      state: CO_ORDEREDBYCUSTOMER,
      company: customer._id.toString(),
      person: customer.primaryPerson,
      commodity: getArticleSnapshot(selectedArticle),
      timeline: [getCustomerOrderTimelineEntry(CO_T_CREATED)],
      files: request?.attachments || [],
      priceCommodities: totalPrice,
      priceServices: 0,
      purchasePriceCommodities: totalPurchasePrice,
      totalPrice,
      terms: termsDB,
      discount,
    };
    if (preferredSupplier) order.supplier = preferredSupplier._id.toString();
    if (request) order.requestId = request._id.toString();
    return order;
  };

  getDefaultState = (): CreateCustomerOrderState => {
    return {
      saving: false,
      targetDate: new Date(),
      totalAmount: 1000,
      purchasePrice: 0,
      pricePerUnit: 0,
      discount: 0,
      noteInternal: "",
      method: T_SEAFREIGHT,
      terms: {
        id: new BSON.ObjectId().toString(),
        paymentTerm: I_PAYMENTTARGETS[4],
        customPaymentTerm: "",
        customPaymentTermCondition: "",
        note: "",
        deliveryTerm: CO_STANDARDINCOTERM,
        deliveryCity: "",
        cleanLabel: false,
      },
      customerReference: "",
    };
  };

  render() {
    const { context } = this.props;
    const {
      saving,
      targetDate,
      targetDateType,
      selectedArticle,
      customer,
      totalAmount,
      purchasePrice,
      pricePerUnit,
      discount,
      noteInternal,
      terms,
      method,
      customerReference,
      preferredSupplier,
      request,
      shippingAddress,
    } = this.state;

    const errors: Array<string> = this.validateData();
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="container-xxl responsive-aside-container">
          <div className="ms-lg-15">
            <div className="card bg-white">
              <div className="card-header mt-6 border-none">
                <div className="card-title flex-column">
                  <h2 className="mb-1">Create Customer Order {request ? `for Request RB-${request.requestNo}` : ""}</h2>
                </div>
              </div>
              <div className="card-body p-9 pt-0">
                <div className="border-bottom-dark-gray pt-5" />
                <div>
                  {request && <CustomerRequestAdditionalInformation request={request} />}
                  <CreateCustomerOrderContractGeneralSettings
                    totalAmount={totalAmount}
                    pricePerUnit={pricePerUnit}
                    discount={discount}
                    purchasePrice={purchasePrice}
                    customer={customer}
                    selectedArticle={selectedArticle}
                    preferredSupplier={preferredSupplier}
                    context={context}
                    onSelectCustomer={this.handleSelectCustomer}
                    onSelectArticle={this.handleSelectArticle}
                    onSelectSupplier={this.handleSelectSupplier}
                    onChangeValue={this.handleChangeValue}
                  />
                </div>
                <div className="border-bottom-dark-gray pt-5" />
                <div>
                  <div className="fw-bolder text-white fs-3 my-5">Order Settings</div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold">Customer Reference</span>
                    <div className="col-8">
                      <Input
                        className="form-control custom-form-control"
                        type="text"
                        name={"customerReference"}
                        placeholder={"Customer's internal reference"}
                        value={customerReference}
                        onBlur={this.handleChangeValue}
                      />
                      <small className="text-success mt-1">&nbsp;</small>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold">Target Date</span>
                    <div className="col-8">
                      {targetDateType === DateType.CW ? (
                        <CalendarWeekSelector
                          value={targetDate}
                          onSelectCalendarWeek={this.handleChangeDate}
                          allowPast={true}
                        />
                      ) : (
                        <DateInput
                          classes="form-control custom-form-control"
                          name="delivery"
                          value={targetDate}
                          onBlur={this.handleChangeDate}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold" />
                    <div className="col-8 d-flex flex-row">
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input position-static"
                          checked={targetDateType === DateType.CW}
                          type="checkbox"
                          onChange={() => this.handleChangeTargetDateType(DateType.CW)}
                        />
                        <label className="form-check-label fs-6">Calendar Week</label>
                      </div>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input position-static"
                          checked={targetDateType === DateType.FIX}
                          type="checkbox"
                          disabled={method === T_SEAFREIGHT}
                          onChange={() => this.handleChangeTargetDateType(DateType.FIX)}
                        />
                        <label className={`form-check-label fs-6 ${method === T_SEAFREIGHT ? "text-muted" : ""}`}>
                          Fixed Date
                        </label>
                      </div>
                    </div>
                    <small className="mt-1">&nbsp;</small>
                  </div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold">Transport Mode</span>
                    <div className="col-8">
                      <CustomSelect
                        options={O_TRANSPORTTYPES}
                        value={O_TRANSPORTTYPES.find((t) => t.value === method)}
                        onChange={(e: SelectOption) => this.handleChangeMethod(e)}
                      />
                      <small className="mt-1">&nbsp;</small>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold">Destination Address</span>
                    <div className="col-8">
                      <OwnAddressSelector
                        context={context}
                        onChange={this.handleSelectAddress}
                        value={shippingAddress}
                        placeholder="Shipping Address..."
                        company={customer}
                      />
                      <small className="text-success mt-1">&nbsp;</small>
                    </div>
                  </div>
                </div>
                <div className="border-bottom-dark-gray pt-5" />
                <CustomerTerms
                  customerTerm={terms}
                  heading={"Payment & Delivery Terms"}
                  onChangePaymentTerms={this.handleChangeCustomerPaymentTerms}
                  onChangeCustomPaymentTerm={this.handleChangeCustomerCustomPaymentTerms}
                  onChangeCustomPaymentTermCondition={this.handleChangeCustomerCustomPaymentTermConditions}
                  onChangeDeliveryTerms={this.handleChangeCustomerDeliveryTerms}
                  onChangeDeliveryCity={this.handleChangeCustomerDeliveryCity}
                  onChangeComment={this.handleChangeCustomerNote}
                  onChangeLabelDesign={this.handleChangeLabelDesign}
                />
                <div className="border-bottom-dark-gray pt-5" />
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="fw-bolder text-white fs-3 my-5">Additional Information</div>
                    <div className="row">
                      <div className="col-12">
                        <Textarea
                          className="form-control custom-form-control"
                          rows={5}
                          value={noteInternal}
                          placeholder={"Additional Notes..."}
                          onChange={this.handleChangeNote}
                        />
                      </div>
                    </div>
                  </div>
                  <CreateCustomerOrderContractCalculation
                    totalAmount={totalAmount}
                    pricePerUnit={pricePerUnit}
                    discount={discount}
                    purchasePrice={purchasePrice}
                  />
                </div>
              </div>
              <div className="card-footer pt-0 border-0">
                <div className="border-bottom-dark-gray pt-5" />
                <div className="pt-3">
                  <div className="d-flex pt-3 align-items-center w-100">
                    <ErrorOverlayButton
                      errors={errors}
                      className={"btn btn-outline btn-outline-white btn-sm ml-auto"}
                      buttonText={saving === "order" ? "Creating Order" : "Create Order"}
                      saving={!!saving}
                      onClick={this.handleCreateOrder}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateCustomerOrder;
