import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Input } from "../../../common/Input";
import { Commodity, CommodityExtended } from "../../../../model/commodity.types";
import { DataContextInternalType } from "../../../../context/dataContext";
import DateInput from "../../../common/DateInput";
import {
  B_WAREHOUSE_OPTIONS,
  convertCreationPackages,
  CreationBatch,
  getBatchAmount,
  getBatchTimelineEntry,
  getDefaultCreationBatch,
  getDefaultCreationBatchPackage,
  getExtendedSupplierOrdersForCommodity,
  insertBatch,
  insertBatchAndUpdateShipment,
  T_BATCHCREATED,
} from "../../../../utils/batchUtils";
import { C_PACKAGE_OPTIONS, C_UNITS, getCommodityTimelineEntry } from "../../../../utils/commodityUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import CustomSelect, { SelectOption } from "../../../common/CustomSelect";
import { Supplier, SupplierExtended } from "../../../../model/supplier.types";
import { Batch, BatchState } from "../../../../model/batch.types";
import { uploadAndGetBatchFileObject } from "../../../../utils/fileUtils";
import { callPushToArray, doFuseSearch } from "../../../../utils/baseUtils";
import { paginate, PaginationState } from "../../../common/Pagination";
import BaseListing from "../../../common/BaseListing";
import PropertiesWidget from "../../../common/PropertiesWidget";
import Search from "../../../common/Search";
import { SO_T_BATCHCREATED, SupplierOrderExtended, SupplierOrderShipment } from "../../../../model/supplierOrder.types";
import { COMMODITY, FINISHEDPRODUCT } from "../../../../services/dbService";
import { getSupplierOrderTimelineEntry } from "../../../../utils/supplierOrderUtils";
import { SUPPORTED_CURRENCIES } from "../../../../utils/currencyUtils";
import { FinishedProduct, FinishedProductExtended } from "../../../../model/finishedProduct.types";
import {
  ArticleSnapshot,
  formatArticleUnit,
  getArticleSnapshot,
  InternalArticleExtended,
  isAnyFinishedProduct,
} from "../../../../utils/productArticleUtils";
import { getFinishedProductTimelineEntry, T_FINISHEDPRODUCTBATCHCREATED } from "../../../../utils/finishedProductUtils";
import { extendCommodity, extendFinishedProduct } from "../../../../utils/dataTransformationUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../services/slackService";
import { getOrderNumber } from "../../../../utils/orderUtils";
import { COMMISSION_STOCK_INTEREST_RATE, getGeneralValue } from "../../../../utils/generalUtils";

interface CreateBatchModalProps {
  article?: InternalArticleExtended | ArticleSnapshot;
  context: DataContextInternalType;
  supplierOrder?: SupplierOrderExtended;
  shipment?: SupplierOrderShipment;
  buttonClasses?: string;
  buttonText?: string;
  disabled?: boolean;
  buttonType?: "batchEntry";
}

interface CreateBatchModalState {
  article?: InternalArticleExtended;
  saving: boolean;
  show: boolean;
  batch: CreationBatch | null;
}

class CreateBatchModal extends PureComponent<CreateBatchModalProps, CreateBatchModalState> {
  selectFileRef: React.RefObject<HTMLInputElement>;
  constructor(props: CreateBatchModalProps) {
    super(props);
    this.selectFileRef = React.createRef();
    this.state = {
      saving: false,
      show: false,
      batch: props.article ? getDefaultCreationBatch(props.article, props.supplierOrder) : null,
    };
  }

  componentDidUpdate(prevProps: Readonly<CreateBatchModalProps>) {
    if (this.props.article && prevProps.supplierOrder !== this.props.supplierOrder) {
      this.setState({
        batch: getDefaultCreationBatch(this.props.article, this.props.supplierOrder),
      });
    }
  }

  handleShow = () =>
    this.setState({
      show: true,
      batch: this.props.article ? getDefaultCreationBatch(this.props.article, this.props.supplierOrder) : null,
      article: undefined,
    });
  handleHide = () => !this.state.saving && this.setState({ show: false });

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    if (!this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    _.set(batch, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ batch });
  };

  handleSupplierChange = (e: SelectOption<Supplier>) => {
    if (!this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    batch.supplier = e;
    this.setState({ batch });
  };

  handleSupplierOrderChange = (e: SelectOption<SupplierOrderExtended> | undefined) => {
    if (!this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    batch.supplierOrder = e;
    const sup = e?.object?.supplier;
    if (sup) batch.supplier = { value: sup._id.toString(), label: sup.name, object: sup };
    this.setState({ batch });
  };

  handleSelectCOA = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    batch.supplierCoA = e.target.files[0];
    this.setState({ batch });
  };

  handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    const val = new Date(e.target.value);
    if (isNaN(val.getTime())) return;
    _.set(batch, e.target.name, val);
    this.setState({ batch });
  };

  handleChangePackage = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index: number) => {
    if (!this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    const batchPackage = batch.packages[index];
    _.set(batchPackage, e.target.name, e.target.type === "number" ? +e.target.value : e.target.value);
    this.setState({ batch });
  };

  handleChangeCommissionStock = () => {
    if (!this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    if (batch.commissionData) delete batch.commissionData;
    else {
      const interestRate = getGeneralValue(this.props.context.general, COMMISSION_STOCK_INTEREST_RATE);
      if (!interestRate || typeof interestRate !== "number") {
        toast.error("Could not resolve current interest rate. Please contact the development team.");
        return;
      }
      batch.commissionData = {
        totalAmount: getBatchAmount(batch),
        price: { value: batch.price, currency: batch.priceCurrency },
        interestRate,
        interest: [],
      };
    }
    this.setState({ batch });
  };

  handleAddPackage = () => {
    if (!this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    batch.packages.push(getDefaultCreationBatchPackage());
    this.setState({ batch });
  };

  handleRemovePackage = (index: number) => {
    if (!this.state.batch) return;
    const batch = _.cloneDeep(this.state.batch);
    batch.packages.splice(index, 1);
    this.setState({ batch });
  };

  handleSelectArticle = (article: InternalArticleExtended) => {
    this.setState({ article, batch: getDefaultCreationBatch(article) });
  };

  handleCreateBatch = async () => {
    if (!this.state.batch) return;
    const article = this.props.article ?? this.state.article;
    if (!article) return;
    const { shipment, supplierOrder } = this.props;
    const { batch } = this.state;
    const {
      _id,
      lot,
      supplierCoA,
      price,
      priceCurrency,
      expiry,
      stockedDate,
      packages,
      notes,
      unit,
      supplier,
      commissionData,
    } = batch;
    if (!supplier || !supplier.object || !supplierCoA) return;
    this.setState({ saving: true });
    const uploadedFile = uploadAndGetBatchFileObject(supplierCoA, supplierCoA.name, supplier.object._id.toString());
    if (!uploadedFile) {
      this.setState({ saving: false });
      return;
    }

    const finalBatch: Batch = {
      _id,
      lot,
      identifier: "-1",
      state: BatchState.INCOMING,
      stockedDate,
      expiry,
      commodity: getArticleSnapshot(article, supplier.object._id),
      packages: convertCreationPackages(packages),
      notes,
      supplier: supplier.object._id.toString(),
      customerOrders: supplierOrder?.customerOrders.map((cO) => cO._id.toString()) ?? [],
      customerContracts: supplierOrder?.customerContracts?.map((cC) => cC._id.toString()) ?? [],
      unit,
      price,
      priceCurrency,
      supplierCoA: uploadedFile,
      ownCoA: null,
      timeline: [getBatchTimelineEntry(T_BATCHCREATED)],
      disabled: false,
      commissionData,
    };
    if (batch.supplierOrder) {
      if (batch.supplierOrder.object) batch.supplierOrder.object.supplier.timeline = [];
      finalBatch.supplierOrder = batch.supplierOrder.object?._id.toString();
    }
    try {
      let result;
      // If we need to update a shipment call a different function that updates both document in a transaction
      if (supplierOrder && shipment) {
        result = await insertBatchAndUpdateShipment(
          finalBatch,
          supplierOrder._id,
          shipment._id,
          getSupplierOrderTimelineEntry(SO_T_BATCHCREATED, {})
        );
      } else {
        result = await insertBatch(finalBatch);
      }
      if (result) {
        toast.success("Batch created successfully");
        const isFP = isAnyFinishedProduct(article);
        const articleTimelineEntry = isFP
          ? getFinishedProductTimelineEntry(T_FINISHEDPRODUCTBATCHCREATED, undefined, batch._id.toString())
          : getCommodityTimelineEntry(T_BATCHCREATED, undefined, batch._id.toString());
        const resC = await callPushToArray(
          isFP ? FINISHEDPRODUCT : COMMODITY,
          article._id.toString(),
          "timeline",
          articleTimelineEntry
        );
        if (!(resC && resC.modifiedCount))
          toast.error(`${isFP ? "Finished product" : "Commodity"} timeline could not be updated`);

        // informing qm about batch creation
        const message = `:information_source: A batch (LOT: ${finalBatch.lot}${
          shipment && supplierOrder
            ? "; Quantity: " + shipment.amount + formatArticleUnit(supplierOrder.unit, supplierOrder.commodity)
            : ""
        })${
          supplierOrder
            ? ` for the order <https://${process.env.REACT_APP_BASE_URL || ""}/supplierOrder/${
                finalBatch.supplierOrder
              }|*${getOrderNumber(supplierOrder!)}*>`
            : ""
        } (<https://${process.env.REACT_APP_BASE_URL || ""}/${
          isFP ? `finishedProduct` : `commodity`
        }/${article._id.toString()}|*${article.title.en}*>) has been created.`;
        sendMessage(getDefaultSlackChannel(false, NotificationType.BATCH_HANDLING), message);

        this.setState({ show: false });
      } else {
        toast.error("Error creating batch");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  generateSuppliersList = () => {
    const { article: propArticle, context } = this.props;
    const { article: stateArticle } = this.state;
    const article = propArticle || stateArticle;
    if (!article) return [];
    // Gather all suppliers that can supply the article
    const suppliers: Array<SelectOption<Supplier | SupplierExtended>> = article.suppliers.map((s) => {
      return { label: s.supplier.name, value: s._id.toString(), object: s.supplier };
    });
    // Add all remaining suppliers to the list
    const supContext: Array<SelectOption<Supplier>> = [];
    for (let i = 0; i < context.supplier.length; i++) {
      const s = context.supplier[i];
      if (s.disabled || !s.activated || suppliers.find((sup) => sup.object?._id.toString() === s._id.toString()))
        continue;
      supContext.push({ label: s.name, value: s._id.toString(), object: s });
    }
    return suppliers.concat(supContext);
  };

  generateSupplierOrdersList = (): Array<SelectOption<SupplierOrderExtended>> => {
    const { article: propArticle, context } = this.props;
    const { article: stateArticle } = this.state;
    const article = propArticle || stateArticle;
    return getExtendedSupplierOrdersForCommodity(article, context);
  };

  validateBatchData = () => {
    if (!this.state.batch) return ["No batch found"];
    const { batch } = this.state;
    const { lot, supplierCoA, price, packages, supplier } = batch;
    const errors: Array<string> = [];
    if (!lot.trim()) errors.push("LOT number required");
    if (!supplierCoA) errors.push("Supplier Certificate of Analysis required");
    if (price <= 0) errors.push("Invalid price");
    if (!supplier) errors.push("Supplier required");
    if (packages.reduce((a, b) => a + b.amountPackages * b.amountEach, 0) <= 0)
      errors.push("Invalid packages: invalid amount");
    if (packages.some((p) => !p.warehouseLocation.trim())) errors.push("Invalid packages: warehouse location missing");
    return errors;
  };

  render() {
    const {
      article: propArticle,
      context,
      buttonClasses,
      supplierOrder,
      disabled,
      buttonText,
      buttonType,
      shipment,
    } = this.props;
    const { show, batch, saving, article: stateArticle } = this.state;
    if (propArticle && (!batch || ("disabled" in propArticle && propArticle.disabled))) {
      return (
        <ErrorOverlayButton
          errors={!batch ? ["No suppliers available"] : ["Article disabled"]}
          className={"btn btn-light w-100 "}
          buttonText={"Create Batch"}
          onClick={this.handleShow}
        />
      );
    }
    const article = propArticle || stateArticle;
    const errors = this.validateBatchData();
    return (
      <>
        {buttonType === "batchEntry" ? (
          <button
            type="button"
            className={"btn " + (buttonClasses ?? "btn-sm btn-light btn-active-light") + (disabled ? " disabled" : "")}
            disabled={disabled}
            onClick={disabled ? undefined : this.handleShow}
          >
            Book Now
          </button>
        ) : propArticle ? (
          <button
            type="button"
            className={"btn " + (buttonClasses ?? "btn-light w-100 mt-7") + (disabled ? " disabled" : "")}
            disabled={disabled}
            onClick={disabled ? undefined : this.handleShow}
          >
            {buttonText ?? "Create Batch"}
          </button>
        ) : (
          <button
            className={
              "btn " + (buttonClasses ?? "btn-outline btn-outline-light float-right") + (disabled ? " disabled" : "")
            }
            disabled={disabled}
            onClick={disabled ? undefined : this.handleShow}
          >
            {buttonText ?? "New Batch"}
          </button>
        )}
        <Modal contentClassName="bg-dark" show={show} size={article ? "lg" : "xl"} onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                {article ? "Create Batch" : "Select Article"}
              </h1>
            </Modal.Title>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            {!article ? (
              <CommoditySelection context={context} onSelectArticle={this.handleSelectArticle} />
            ) : !batch ? (
              <div />
            ) : (
              <div className="row mb-5 ">
                {supplierOrder && shipment ? (
                  <>
                    <div className="col-md-6 mt-5">
                      <label className="fs-5 fw-bold mb-2">Article</label>
                      <Input
                        type="text"
                        className="form-control custom-form-control"
                        name="commodity"
                        value={supplierOrder.commodity.title.en}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-6 mt-5">
                      <label className="fs-5 fw-bold mb-2">Ordered Amount</label>
                      <Input
                        type="text"
                        className="form-control custom-form-control"
                        name="amount"
                        value={shipment.amount + formatArticleUnit(supplierOrder.unit, supplierOrder.commodity)}
                        disabled={true}
                      />
                    </div>
                  </>
                ) : (
                  article && (
                    <>
                      <div className="col-md-6 mt-5">
                        <label className="fs-5 fw-bold mb-2">Article</label>
                        <Input
                          type="text"
                          className="form-control custom-form-control"
                          name="commodity"
                          value={article.title.en}
                          disabled={true}
                        />
                      </div>
                      <div className="col-md-6 mt-5">
                        <label className="fs-5 fw-bold mb-2">Supplier Order</label>
                        <CustomSelect
                          options={this.generateSupplierOrdersList()}
                          value={batch.supplierOrder}
                          onChange={this.handleSupplierOrderChange}
                          disabled={!!supplierOrder}
                          matchFormControl={true}
                          isClearable={true}
                        />
                      </div>
                    </>
                  )
                )}
                <div className="col-md-6 mt-5">
                  <label className="required fs-5 fw-bold mb-2">LOT-Number</label>
                  <Input
                    type="text"
                    className="form-control custom-form-control"
                    name="lot"
                    placeholder={"LOT number"}
                    autoComplete="off"
                    value={batch.lot}
                    onBlur={this.handleChange}
                  />
                </div>
                <div className="col-md-6 mt-5">
                  <label className="fs-5 fw-bold mb-2">Stored on</label>
                  <DateInput
                    classes="form-control custom-form-control"
                    value={batch.stockedDate}
                    onBlur={this.handleDateChange}
                    name={"stockedDate"}
                  />
                </div>
                <div className="col-md-6 mt-5">
                  <label className="required fs-5 fw-bold mb-2">Price (net)</label>
                  <div className="input-group">
                    <Input
                      type="number"
                      min={0}
                      className="form-control custom-form-control pt-0 pb-0"
                      name={"price"}
                      value={batch.price}
                      onBlur={this.handleChange}
                    />
                    <div className="input-group-append bg-custom-light-gray select-wrapper">
                      <select
                        className="form-control custom-form-control pt-0 pb-0"
                        style={{ minWidth: "70px" }}
                        name={"priceCurrency"}
                        value={batch.priceCurrency}
                        disabled={!!supplierOrder}
                        onChange={this.handleChange}
                      >
                        {SUPPORTED_CURRENCIES.map((c) => (
                          <option key={c} value={c}>
                            {c}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input-group-append bg-custom-light-gray">
                      <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                        /
                      </div>
                    </div>
                    <div className="input-group-append bg-custom-light-gray rounded-end">
                      {isAnyFinishedProduct(article) ? (
                        <div className="my-auto mr-2">1000 pcs</div>
                      ) : (
                        <div className="select-wrapper">
                          <select
                            className="form-control custom-form-control pt-0 pb-0"
                            style={{ minWidth: "60px" }}
                            value={batch.unit}
                            onChange={this.handleChange}
                            disabled={Boolean(supplierOrder || batch.supplierOrder)}
                            name={"unit"}
                          >
                            {C_UNITS.map((u) => (
                              <option key={u} value={u}>
                                {u}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-5">
                  <label className="required fs-5 fw-bold mb-2">Supplier</label>
                  <CustomSelect
                    options={this.generateSuppliersList()}
                    value={batch.supplier}
                    onChange={this.handleSupplierChange}
                    disabled={Boolean(supplierOrder || batch.supplierOrder)}
                    matchFormControl={true}
                  />
                </div>
                <div className="col-md-3 mt-5">
                  <label className="required fs-5 fw-bold mb-2">Supplier CoA</label>
                  <div>
                    <button
                      className="btn btn-light btn-sm"
                      type="button"
                      onClick={() => this.selectFileRef.current?.click()}
                    >
                      Select File
                    </button>
                    <input
                      type="file"
                      ref={this.selectFileRef}
                      accept="*"
                      style={{ display: "none" }}
                      onChange={this.handleSelectCOA}
                    />
                    {batch.supplierCoA && (
                      <span className={"text-white fw-bold ml-3"}>
                        {batch.supplierCoA.name}
                        {"size" in batch.supplierCoA && (
                          <small className="text-muted ml-2">{Math.round(batch.supplierCoA.size / 1024)} KB</small>
                        )}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3 mt-5">
                  <label className="fs-5 fw-bold mb-2">Commission Stock</label>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className={"form-check-input position-static " + (supplierOrder ? "disabled" : "")}
                      checked={Boolean(batch.commissionData)}
                      type="checkbox"
                      disabled={Boolean(supplierOrder)}
                      onChange={supplierOrder ? undefined : this.handleChangeCommissionStock}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-5">
                  <label className="required fs-5 fw-bold mb-2">Best before</label>
                  <DateInput
                    classes="form-control custom-form-control"
                    value={batch.expiry}
                    onBlur={this.handleDateChange}
                    name={"expiry"}
                  />
                </div>
                <div className="col-md-12 mt-5">
                  <label className="required fs-5 fw-bold mb-2">Packages</label>
                  {batch.packages.map((p, idx) => (
                    <div key={p._id.toString()} className="row my-4">
                      <div className="col-3">
                        <div className="input-group">
                          <Input
                            type="number"
                            integerOnly={true}
                            min={0}
                            className="form-control custom-form-control"
                            name="amountPackages"
                            value={p.amountPackages}
                            onBlur={(e) => this.handleChangePackage(e, idx)}
                          />
                          <div className="input-group-append rounded-end bg-custom-light-gray select-wrapper">
                            <select
                              className="form-control custom-form-control py-0"
                              name="packageType"
                              value={p.packageType}
                              onChange={(e) => this.handleChangePackage(e, idx)}
                            >
                              {C_PACKAGE_OPTIONS.map((o) => (
                                <option key={o.value} value={o.value}>
                                  {o.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-white fs-5 fw-bolder text-center my-auto">of</div>
                      <div className="col-3">
                        <div className="input-group">
                          <Input
                            type="number"
                            min={0}
                            value={p.amountEach.toString()}
                            name="amountEach"
                            onBlur={(e) => this.handleChangePackage(e, idx)}
                            className="form-control custom-form-control"
                          />
                          <div className="input-group-append rounded-end bg-custom-light-gray">
                            <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                              {formatArticleUnit(batch.unit)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto text-white fs-5 fw-bolder text-center my-auto">at</div>
                      <div className="col">
                        <div className="input-group">
                          <div className="input-group-prepend rounded-start bg-custom-light-gray select-wrapper">
                            <select
                              className="form-control custom-form-control py-0"
                              style={{ minWidth: "90px" }}
                              name="warehouse"
                              placeholder="Warehouse"
                              value={p.warehouse}
                              onChange={(e) => this.handleChangePackage(e, idx)}
                            >
                              {B_WAREHOUSE_OPTIONS.map((o) => (
                                <option key={o.value} value={o.value}>
                                  {o.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <Input
                            type="text"
                            placeholder={"Location, e.g. A73"}
                            value={p.warehouseLocation}
                            name="warehouseLocation"
                            onBlur={(e) => this.handleChangePackage(e, idx)}
                            className="form-control custom-form-control"
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <button
                          className={"btn btn-light btn-sm float-right " + (batch.packages.length === 1 && "disabled")}
                          disabled={batch.packages.length === 1}
                          onClick={batch.packages.length === 1 ? undefined : () => this.handleRemovePackage(idx)}
                        >
                          <i className="fa fa-trash text-white p-0" />
                        </button>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col-12">
                      <button className="btn btn-light btn-sm float-right" onClick={this.handleAddPackage}>
                        <i className="fa fa-plus text-white p-0" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                  <label className="fs-5 fw-bold mb-2">Additional Notes</label>
                  <textarea
                    className="form-control custom-form-control"
                    rows={3}
                    name={"notes"}
                    value={batch.notes}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            {!propArticle && stateArticle && (
              <button
                className="btn btn-sm btn-outline btn-outline-light"
                onClick={() => this.setState({ article: undefined })}
              >
                Back
              </button>
            )}
            {(propArticle || stateArticle) && (
              <ErrorOverlayButton
                errors={errors}
                saving={saving}
                className={"btn btn-sm btn-outline btn-outline-light "}
                buttonText={"Create Batch"}
                onClick={this.handleCreateBatch}
              />
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface CommoditySelectionProps {
  onSelectArticle: (article: InternalArticleExtended) => void;
  context: DataContextInternalType;
}

interface CommoditySelectionState extends PaginationState {
  search: string;
  articleType: typeof COMMODITY | typeof FINISHEDPRODUCT;
}

class CommoditySelection extends PureComponent<CommoditySelectionProps, CommoditySelectionState> {
  constructor(props: CommoditySelectionProps) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 15,
      search: "",
      articleType: COMMODITY,
    };
  }

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value, currentPage: 1 });
  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeType = (type: typeof COMMODITY | typeof FINISHEDPRODUCT) => this.setState({ articleType: type });

  filterCommodities = () => {
    const { context } = this.props;
    const { commodity } = context;
    const { search } = this.state;
    let result: Array<Commodity>;
    if (!search.trim()) result = _.orderBy(commodity, "title.en");
    else result = doFuseSearch(commodity, search, ["title.en", "subtitle.en"]);
    return result.map((c) => extendCommodity(c, context));
  };

  filterFinishedProducts = () => {
    const { context } = this.props;
    const { search } = this.state;
    const { finishedProduct } = context;
    let result: Array<FinishedProduct>;
    if (!search.trim()) result = _.orderBy(finishedProduct, "title.en");
    else result = doFuseSearch(finishedProduct, search, ["title.en", "subtitle.en"]);
    return result.map((fp) => extendFinishedProduct(fp, context));
  };

  render() {
    const { onSelectArticle } = this.props;
    const { currentPage, pageSize, search, articleType } = this.state;
    const filteredArticles = articleType === COMMODITY ? this.filterCommodities() : this.filterFinishedProducts();

    return (
      <div style={{ maxHeight: "75vh", overflowY: "auto", overflowX: "hidden" }}>
        <div className="row my-4">
          <div className="col-6 ">
            <Search
              value={search}
              onSearch={this.handleSearch}
              placeholder={`Search ${articleType === COMMODITY ? "commodities" : "finished products"}...`}
            />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-12">
            <div className="btn-group mb-4 w-100">
              <div
                className={"w-50 btn btn-light " + (articleType === COMMODITY && "active")}
                onClick={() => this.handleChangeType(COMMODITY)}
              >
                Commodity
              </div>
              <div
                className={"w-50 btn btn-light " + (articleType === FINISHEDPRODUCT && "active")}
                onClick={() => this.handleChangeType(FINISHEDPRODUCT)}
              >
                Finished Product
              </div>
            </div>
          </div>
        </div>
        {articleType === COMMODITY ? (
          <BaseListing
            headerDefinition={[
              { title: "Commodity", style: { width: "40%" } },
              { title: "Properties" },
              { title: "Suppliers" },
              { title: "Action" },
            ]}
            documents={filteredArticles as Array<CommodityExtended>}
            bodyContent={
              <>
                {paginate(filteredArticles as Array<CommodityExtended>, currentPage, pageSize).map((c) => (
                  <ArticleSelectionRow article={c} key={c._id.toString()} onSelectArticle={() => onSelectArticle(c)} />
                ))}
              </>
            }
            baseSize={15}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
          />
        ) : (
          <BaseListing
            headerDefinition={[
              { title: "Finished Product", style: { width: "40%" } },
              { title: "Properties" },
              { title: "Suppliers" },
              { title: "Action" },
            ]}
            documents={filteredArticles as Array<FinishedProductExtended>}
            bodyContent={
              <>
                {paginate(filteredArticles as Array<FinishedProductExtended>, currentPage, pageSize).map((c) => (
                  <ArticleSelectionRow article={c} key={c._id.toString()} onSelectArticle={() => onSelectArticle(c)} />
                ))}
              </>
            }
            baseSize={15}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
          />
        )}
      </div>
    );
  }
}

interface ArticleSelectionRowProps {
  article: InternalArticleExtended;
  onSelectArticle: () => void;
}

const ArticleSelectionRow: React.FunctionComponent<ArticleSelectionRowProps> = ({ article, onSelectArticle }) => {
  return (
    <tr onClick={onSelectArticle}>
      <td className="align-middle">
        <Link
          className="text-white fs-5 mb-1 custom-link"
          to={`/${isAnyFinishedProduct(article) ? "finishedProduct" : "commodity"}/${article._id.toString()}`}
        >
          {article.title.en}
          {article && article.organic && <i title={"organic"} className="fas fa-leaf text-success ml-1" />}
        </Link>
        <div className="text-muted fs-6">{article.subtitle.en}</div>
      </td>
      <td className="align-middle">
        <PropertiesWidget properties={article ? article?.properties : []} types={[]} />
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div className="">{article ? article.suppliers.length : "0"} suppliers</div>
        </div>
      </td>
      <td className="align-middle">
        <button className="btn btn-sm btn-light btn-active-light">Select</button>
      </td>
    </tr>
  );
};

export default CreateBatchModal;
