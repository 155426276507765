import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextInternal } from "../../../context/dataContext";
import userService from "../../../services/userService";
import InternalDashboardOrderKanban from "./InternalDashboardOrderKanban";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { O_ORDERTYPES } from "../../../utils/orderUtils";
import Search from "../../common/Search";
import DashboardStatWidget from "./DashboardStatWidget";
import { DASHBOARDSTATS, getGeneralValue } from "../../../utils/generalUtils";

const InternalDashboard: React.FunctionComponent<RouteComponentProps> = (props) => {
  const context = useContext(DataContextInternal);
  const userData = userService.getUserData();
  const [search, setSearch] = useState("");
  const [type, setType] = useState<SelectOption>(O_ORDERTYPES[1]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
  const handleChangeOrderType = (type: SelectOption) => setType(type);

  return (
    <div className="d-flex flex-column flex-column-fluid pt-sm-10">
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid dashboard-container">
          <div className="row">
            <div className="col-12 ">
              <div className="d-flex align-items-center rounded bg-white">
                <div className="mx-5">
                  <h1 className="my-auto">Welcome, {userData.prename}!</h1>
                </div>
                <div className="d-flex ml-auto">
                  <div className="ml-5" style={{ width: "275px" }}>
                    <CustomSelect
                      options={O_ORDERTYPES.slice(0, 2)}
                      matchFormControl={true}
                      onChange={handleChangeOrderType}
                      value={type}
                    />
                  </div>
                  <div className="mx-5" style={{ width: "275px" }}>
                    <Search value={search} onSearch={handleSearch} />
                  </div>
                </div>
                <div className="d-flex">
                  <InternalDashboardStats />
                </div>
              </div>
            </div>
            <div className="col-12 mt-5 px-2 table-responsive">
              <InternalDashboardOrderKanban search={search} type={type} context={context} {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface DashboardStats {
  commoditiesDeliverable?: number;
  priceValidity?: number;
  inTimeDeliveries?: number;
}

const InternalDashboardStats = React.memo(() => {
  const context = useContext(DataContextInternal);
  const [state, setState] = useState<DashboardStats>({});

  useEffect(() => {
    const dashboardStats = getGeneralValue(context.general, DASHBOARDSTATS) as DashboardStats | undefined;
    if (dashboardStats) {
      setState(dashboardStats);
    }
  }, [context.general]);

  const { commoditiesDeliverable, priceValidity, inTimeDeliveries } = state;

  return (
    <>
      <DashboardStatWidget title={"Deliverable Items"} value={commoditiesDeliverable} />
      <DashboardStatWidget title={"Price Validity"} value={priceValidity} />
      <DashboardStatWidget title={"Deliveries In Time"} value={inTimeDeliveries} />
    </>
  );
});

export default InternalDashboard;
