import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { Modal } from "react-bootstrap";
import { F_SIGNATURE, getUserName, INTERNAL, SelectedSpecificationUserData } from "../../utils/userUtils";
import { SelectOption } from "../common/CustomSelect";
import { uploadPublicFile } from "../../utils/fileUtils";
import { UserFile } from "../../model/userData.types";
import userService from "../../services/userService";
import { Action, COMMODITY, FINISHEDPRODUCT, transaction, USERDATA } from "../../services/dbService";
import { createPDF, FOOTER_HTML } from "../../utils/pdfUtils";
import { createMasterSpecificationHTML } from "../../utils/pdf/masterSpecificationGenerationUtils";
import { Commodity, UploadedFile } from "../../model/commodity.types";
import { D_MASTERSPECIFICATION, getCommodityTimelineEntry, T_MASTERSPECCREATED } from "../../utils/commodityUtils";
import { callPushToArray } from "../../utils/baseUtils";
import { DataContextInternal } from "../../context/dataContext";
import DocumentApprovalUser from "../common/internal/DocumentApprovalUser";
import ErrorOverlayButton from "../common/ErrorOverlayButton";
import { getDefaultSlackChannel, sendMessage } from "../../services/slackService";
import { extendCommodity, extendFinishedProduct } from "../../utils/dataTransformationUtils";
import { getFinishedProductTimelineEntry, isFinishedProduct } from "../../utils/finishedProductUtils";
import { FinishedProduct } from "../../model/finishedProduct.types";

interface MasterSpecificationGenerationToolProps {}

interface MasterSpecificationGenerationToolState {
  generating: boolean;
  originator?: SelectedSpecificationUserData;
  approver?: SelectedSpecificationUserData;
  successCountCommodity: number;
  handledCountCommodity: number;
  successCountFP: number;
  handledCountFP: number;
}

class MasterSpecificationGenerationTool extends PureComponent<
  MasterSpecificationGenerationToolProps,
  MasterSpecificationGenerationToolState
> {
  static contextType = DataContextInternal;
  context!: React.ContextType<typeof DataContextInternal>;
  constructor(props: MasterSpecificationGenerationToolProps) {
    super(props);
    this.state = {
      generating: false,
      successCountCommodity: 0,
      handledCountCommodity: 0,
      successCountFP: 0,
      handledCountFP: 0,
    };
  }

  handleSelectUser = (type: "approver" | "originator", e: SelectOption<SelectedSpecificationUserData>) => {
    const userData = _.cloneDeep(e.object);
    if (!userData) return; // Should not happen
    userData.signature = userData.files?.find((f) => f.type === F_SIGNATURE);
    userData.date = new Date();
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleChangePosition = (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    userData.position = e.target.value;
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleChangeDate = (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    const targetDate = new Date(e.target.value);
    if (!targetDate) return;
    userData.date = targetDate;
    // @ts-ignore
    this.setState({ [type]: userData });
  };

  handleUploadSignature = async (type: "approver" | "originator", e: React.ChangeEvent<HTMLInputElement>) => {
    const userData = { ..._.get(this.state, type) };
    const file = e.target.files ? e.target.files[0] : null;
    if (!file || !userData) return;
    const alias = uploadPublicFile(file, `Signature-${userData.prename || ""}${userData.surname || ""}`);
    if (!alias) {
      toast.error("File upload failed.");
      return;
    }
    const userFile: UserFile = {
      _id: new BSON.ObjectId(),
      path: alias,
      type: F_SIGNATURE,
      date: new Date(),
      uploadedBy: userService.getUserId(),
      isPublic: true,
    };
    const action: Action = {
      collection: USERDATA,
      filter: { _id: userData._id },
      push: { files: userFile },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Signature uploaded successfully");
      userData.signature = userFile;
      // @ts-ignore
      this.setState({ [type]: userData });
    } else {
      toast.error("Error uploading signature image");
    }
  };

  handleRemoveSignature = async (type: "approver" | "originator") => {
    const userData = { ..._.get(this.state, type) };
    const action: Action = {
      collection: USERDATA,
      filter: { _id: userData._id },
      pull: { files: userData.signature },
    };
    const result = await transaction([action]);
    if (result) {
      toast.success("Signature deleted successfully");
      userData.signature = undefined;
      // @ts-ignore
      this.setState({ [type]: userData });
    } else {
      toast.error("Error deleting signature");
    }
  };

  handleCreateMasterSpecifications = async () => {
    const { commodity: commodityList, finishedProduct: finishedProductList } = this.context;
    const { originator, approver } = this.state;
    if (!originator || !approver) return;
    this.setState({
      generating: true,
      handledCountCommodity: 0,
      successCountCommodity: 0,
      handledCountFP: 0,
      successCountFP: 0,
    });
    let successCountCommodity = 0;
    let successCountFP = 0;
    let countCommodity = 0;
    let countFP = 0;
    try {
      for (let i = 0; i < commodityList.length; i++) {
        const commodity = commodityList[i];
        const res = await this.regenerateArticleSpec(commodity);
        if (res) successCountCommodity++;
        countCommodity++;
        this.setState({ handledCountCommodity: countCommodity });
      }
      // regenerate finished products
      for (let i = 0; i < finishedProductList.length; i++) {
        const finishedProduct = finishedProductList[i];
        const res = await this.regenerateArticleSpec(finishedProduct);
        if (res) successCountFP++;
        countFP++;
        this.setState({ handledCountFP: countFP });
      }
    } finally {
      toast.success(
        `Generation finished. ${successCountCommodity}/${commodityList.length} commodities successfully generated. ${successCountFP}/${finishedProductList.length} finished products successfully generated.`
      );
      sendMessage(
        getDefaultSlackChannel(),
        `Master spec generation finished. ${successCountCommodity}/${commodityList.length} commodities successfully generated. ${successCountFP}/${finishedProductList.length} finished products successfully generated.`
      );
      this.setState({
        generating: false,
        successCountCommodity: successCountCommodity,
        successCountFP: successCountFP,
      });
    }
  };

  regenerateArticleSpec = async (article: Commodity | FinishedProduct): Promise<boolean> => {
    const { originator, approver } = this.state;
    if (!originator || !approver) return false;
    const finishedProduct = isFinishedProduct(article);
    const masterSpecification = article.documents.find((d) => d.type === D_MASTERSPECIFICATION);
    try {
      const version = masterSpecification && masterSpecification.version ? masterSpecification.version + 1 : 1;
      const path = await createPDF(
        createMasterSpecificationHTML(
          finishedProduct
            ? { type: FINISHEDPRODUCT, finishedProduct: extendFinishedProduct(article, this.context) }
            : { type: COMMODITY, commodity: extendCommodity(article, this.context) },
          originator,
          approver,
          version
        ),
        `MasterSpecification-${article.articleNo}`,
        undefined,
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: FOOTER_HTML,
          footerSpacing: 5,
        }
      );
      let res;
      if (path) {
        const file: UploadedFile = {
          _id: new BSON.ObjectId(),
          name: "Master Specification",
          path,
          type: D_MASTERSPECIFICATION,
          date: new Date(),
          originator: originator._id.toString(),
          approver: approver._id.toString(),
          approvalDate: new Date(),
          signedBy: null,
          version,
        };
        const timelineEntry = finishedProduct
          ? getFinishedProductTimelineEntry(T_MASTERSPECCREATED, D_MASTERSPECIFICATION, path)
          : getCommodityTimelineEntry(T_MASTERSPECCREATED, D_MASTERSPECIFICATION, path);
        if (masterSpecification) {
          const action: Action = {
            collection: finishedProduct ? FINISHEDPRODUCT : COMMODITY,
            filter: { _id: article._id },
            update: { "documents.$[filter]": file }, // replace existing master spec
            arrayFilters: [{ "filter._id": masterSpecification._id }],
            push: { timeline: timelineEntry },
          };
          res = await transaction([action]);
        } else {
          res = await callPushToArray(
            finishedProduct ? FINISHEDPRODUCT : COMMODITY,
            article._id,
            ["documents", "timeline"],
            [file, timelineEntry]
          );
        }
      }
      if (res && (!(typeof res === "object" && "modifiedCount" in res) || res.modifiedCount)) {
        return true;
      } else {
        const errorMessage = `Master specification for ${finishedProduct ? "finished product" : "commodity"} <https://${
          process.env.REACT_APP_BASE_URL || ""
        }/${finishedProduct ? "finishedProduct" : "commodity"}/${article._id.toString()}|*${
          article.title.en
        }*>(${article._id.toString()}) could not be generated`;
        console.error(errorMessage);
        sendMessage(getDefaultSlackChannel(), errorMessage);
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  validateSpecificationData = () => {
    const { originator, approver } = this.state;
    const errors: Array<string> = [];
    if (originator) {
      if (!originator.signature) errors.push("Signature of originator missing");
    } else errors.push("No originator selected. Please select a user as originator/creator of the commodity");
    if (approver) {
      if (!approver.signature) errors.push("Signature of approver missing");
    } else errors.push("No approver selected. Please select a user that approved the commodity");
    if (originator && approver && originator._id.toString() === approver._id.toString())
      errors.push(
        "Originator and approver cannot be the same person. Please select a different originator or approver"
      );
    return errors;
  };

  render() {
    const { originator, approver, generating, handledCountCommodity, handledCountFP } = this.state;
    const internalUsers = this.context.userData
      .filter((uD) => uD.type === INTERNAL)
      .map((uD) => {
        return { value: uD._id.toString(), label: getUserName(uD), object: uD as SelectedSpecificationUserData };
      });
    const errors = this.validateSpecificationData();
    if (!userService.isAdmin())
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Master Specification Tool</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    return (
      <>
        <Modal contentClassName="bg-dark" show={generating} size={"lg"} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">Generating Master Specification</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="my-10">
              <div className="text-white text-center ">
                <div className="splash-screen-relative">
                  <svg className="splash-spinner" viewBox="0 0 50 50" style={{ height: "50px", width: "50px" }}>
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
                  </svg>
                  <div className="ml-3 h3 text-white">
                    Generating. Commodities: {handledCountCommodity}/{this.context.commodity.length} done. Finished
                    products: {handledCountFP}/{this.context.finishedProduct.length} done.
                  </div>
                </div>
              </div>
              <div className="text-center mt-2">
                <small className="text-white">This may take a while. Please wait</small>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="card bg-white">
                <div className="card-body">
                  <h3 className="card-title align-items-start flex-column mb-15">
                    <span className="card-label fw-bolder mb-3 fs-3rem">Master Specification Tool</span>
                  </h3>
                  <div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="text-white text-center h6">Originator</div>
                        <DocumentApprovalUser
                          type="originator"
                          disabled={generating}
                          userData={originator}
                          internalUsers={internalUsers}
                          onSelectUser={this.handleSelectUser}
                          onChangePosition={this.handleChangePosition}
                          onChangeDate={this.handleChangeDate}
                          onUploadSignature={this.handleUploadSignature}
                          onRemoveSignature={this.handleRemoveSignature}
                        />
                      </div>
                      <div className="col-xl-6">
                        <div className="text-white text-center h6">Approver</div>
                        <DocumentApprovalUser
                          type="approver"
                          disabled={generating}
                          userData={approver}
                          internalUsers={internalUsers}
                          onSelectUser={this.handleSelectUser}
                          onChangePosition={this.handleChangePosition}
                          onChangeDate={this.handleChangeDate}
                          onUploadSignature={this.handleUploadSignature}
                          onRemoveSignature={this.handleRemoveSignature}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer border-0">
                  <ErrorOverlayButton
                    errors={errors}
                    saving={generating}
                    className="btn btn-sm btn-outline btn-outline-light float-right"
                    buttonText="Generate Specifications"
                    onClick={this.handleCreateMasterSpecifications}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MasterSpecificationGenerationTool;
