import React from "react";

const Privacy = () => {
  return (
    <div className="content d-flex flex-column  flex-column-fluid">
      <div className="post d-flex  flex-column-fluid">
        <div className="container-xxl responsive-aside-container ">
          <div className=" card bg-white">
            <div className="card-body text-center text-gray-400">
              <h1 className={"text-center mt-10 mb-15"} style={{ fontSize: "250%" }}>
                Privacy Statement
              </h1>
              <h2 className="mb-10">1. Data privacy at a glance</h2>
              <h3 className="mb-10">General information</h3>
              <p className={"text.left"}>
                The &#160;following information provides a simple &#160;overview of what happens to your personal data
                when you &#160; visit this website. <br />
                Personal &#160;data is any data by which &#160;you can be personally &#160;identified. For detailed
                &#160;information on data protection, &#160;please <br />
                refer to our privacy statement listed below this text.
              </p>
              <h3 className="mb-8">Data collection on this website</h3>{" "}
              <h4>who is responsible for data collection on this website?</h4>{" "}
              <p>
                Data processing on this website is carried out by the website operator.
                <br />
                You&#160; can find his contact details &#160;in the section &#160; „Information on the responsible
                party“ &#160;in this data &#160; protection statement.
              </p>{" "}
              <h4>How do we collect your data?</h4>{" "}
              <p>
                Your data is collected on the one hand by you providing it to us.&#160; This may be, for example, data
                that you enter in a contact <br />
                form.
              </p>{" "}
              <p>
                Other data is collected automatically or after &#160;your consent when you visit the website by our IT
                systems. These are mainly
                <br /> technical &#160; data (e. &#160; B. &#160; Internet browser, &#160; operating system &#160; or
                time of page view). The collection of this data takes place <br /> automatically as soon as you enter
                this website.
              </p>{" "}
              <h4>What do we use your data for?</h4>{" "}
              <p>
                Some of the data is collected &#160;to ensure error-free &#160;provision of the &#160;website. Other
                data may be &#160;used to analyze your user <br />
                behavior.
              </p>{" "}
              <h4>What rights do you have regarding your data?</h4>{" "}
              <p>
                You &#160; have the &#160;right to &#160;obtain &#160;information&#160; free&#160; of&#160; charge&#160;
                at any &#160;time &#160;about the&#160; s origin, recipient and&#160; purpose of your <br /> stored
                personal&#160; data. You also&#160; have the&#160; right torequest &#160;the correction or&#160;
                deletion of this &#160; data. &#160; If you have &#160; given your <br />
                consent &#160;to data&#160; processing, &#160;you may revoke this &#160;consent &#160;at &#160;any time
                &#160;for the future. &#160;In addition, &#160;you have&#160; the right&#160; to <br />
                request the &#160;restriction of the&#160; processing of your &#160;personal data&#160; under
                &#160;certain circumstances.&#160; Furthermore, you have the <br />
                right to lodge a complaint with the competent supervisory authority.
              </p>{" "}
              <p>You can contact us at any time about this and other questions on the subject of data protection.</p>
              <h2 className="mb-10">2. Hosting </h2>
              <h3 className="mb-8">Amazon Web Services (AWS)</h3>{" "}
              <p>
                We &#160;host&#160; our&#160; website&#160; with &#160;AWS. &#160;The &#160;provider &#160;is
                &#160;Amazon &#160;Web &#160;Services &#160;EMEA&#160; SARL, &#160;38 &#160;Avenue &#160;John&#160; F.
                &#160;Kennedy, <br /> 1855 Luxembourg (hereinafter: AWS).
              </p>{" "}
              <p>
                When you visit our website, your personal data is processed on the servers of AWS. In the process,{" "}
                personal data may also <br /> be &#160;transmitted&#160; to the parent &#160;company &#160;of AWS
                &#160;in the &#160;USA. &#160;The data &#160;transfer to &#160;the USA &#160;is based &#160;on the EU
                &#160;standard <br />
                contractual clauses. Details can be found here:
                <br />
                https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.
              </p>{" "}
              <p>
                For&#160; more &#160;information,&#160; please &#160;refer&#160; to &#160;the &#160;privacy&#160;
                statement &#160;of &#160;AWS:&#160; &#160;https://aws.amazon.com/de/privacy/?nc1=f_pr&#160;.
              </p>{" "}
              <p>
                The &#160;use of AWS is&#160; based on Art. 6&#160; para. 1&#160; lit. f DSGVO.&#160; We have a
                &#160;legitimate&#160; interest in &#160;the most &#160;reliable&#160; presentation <br />
                of our website. &#160;If a corresponding &#160;consent has been requested,&#160; the processing is
                &#160;carried out exclusively &#160;on the basis of <br />
                Art. 6 para. 1 lit. a DSGVO; the consent can be revoked at any time.
              </p>{" "}
              <h2 className="mb-10">3. General notes and mandatoryinformation</h2>
              <h3 className="mb-8">Data protection</h3>{" "}
              <p>
                The&#160; operators&#160; of &#160;these &#160;pages&#160; take &#160;the&#160; protection &#160;of
                &#160;your&#160; personal &#160;data&#160; very&#160; seriously.&#160; We treat your personal data{" "}
                <br />
                confidentially and in accordance with the legal data protection regulations and this data protection
                declaration.
              </p>{" "}
              <p>
                When you&#160; use this website,&#160; various personal&#160; data are collected.&#160; Personal data is
                data with which&#160; you can &#160;be personally <br /> identified.&#160; This privacy&#160;
                &#160;statement explains&#160; what data&#160; we collect&#160; and how&#160; we use&#160; it. &#160;It
                also&#160; explains how &#160;and for what
                <br /> purpose this is done.
              </p>{" "}
              <p>
                We point out that the data transmission on the Internet (eg communication by e-mail) security gaps.
                &#160;A gapless protection <br />
                of data from access by third parties is not possible.
              </p>
              <h3 className="mb-8">Note on the responsible body</h3>{" "}
              <p>The responsible body for data processing on this website is:</p>{" "}
              <p>
                <b>RAWBIDS GmbH</b>
                <br />
                Willy-Brandt-Str. 23,
                <br />
                20457 Hamburg
              </p>
              <p>
                Phone: +40 (0)221 88231618
                <br />
                E-Mail: info@rawbids.com
              </p>
              <p>
                Responsible body is the natural or legal person who alone or jointly with others decides on the purposes
                and means of the <br />
                processing of personal data (eg names, e-mail addresses etc).
              </p>
              <h3 className="mb-8">Storage period</h3>{" "}
              <p>
                Storage&#160; period Unless&#160; a more specific &#160;storage period&#160; has been&#160; mentioned
                within this&#160; privacy statement,&#160; your personal <br />
                data will&#160; remain with us until &#160;the purpose for processing the&#160; data no longer applies.
                &#160;If you assert a legitimate&#160; request for <br />
                deletion&#160; or revoke &#160;your consent &#160;to data&#160; processing,&#160; your data &#160;will
                be&#160; deleted unless&#160; we have other&#160; legally permissible
                <br /> reasons for storing your personal data &#160;(e.g. retention periods under tax or commercial
                law);&#160; in the latter case, deletion will <br /> take place after these reasons no longer apply.
              </p>
              <h3 className="mb-8">Note on data transfer to the USA and other third countries</h3>{" "}
              <p>
                We use,&#160; among other things,&#160; tools from &#160;companies&#160; based in the&#160; USA or
                other&#160; third countries that are not &#160;secure under <br /> data protection law. If these tools
                are active, your personal data may be transferred to these third countries and processed <br />
                there. &#160;We would like to point out that no &#160;level of data protection comparable to &#160;that
                in the EU can be guaranteed in these
                <br /> countries.&#160; For example,&#160; US companies &#160;are obliged to hand over &#160;personal
                data&#160; to security authorities&#160; without you as the <br /> data &#160;subject&#160; being&#160;
                able&#160; to &#160;take &#160;legal&#160; action&#160; against&#160; this.&#160;&#160;&#160;
                Therefore,&#160; it&#160; cannot &#160;be&#160; ruled&#160; out&#160;&#160; that&#160;&#160; U.S.
                &#160;authorities <br />
                (e.g. intelligence services) may process, evaluate and permanently store your data on U.S. servers for
                monitoring purposes.
                <br />
                We have no influence on these processing activities.
              </p>
              <h3 className="mb-8">Revocation of your consent to data processing</h3>{" "}
              <p>
                Many data processing &#160;operations are only possible&#160; with your express consent.&#160; You can
                revoke your consent &#160;at any time.
                <br /> The legality of the data processing carried out until the revocation remains unaffected by the
                revocation.
              </p>
              <h3 className="mb-8">
                Right of objection to data collection in special cases and to direct marketing (Art. 21 DSGVO)
              </h3>{" "}
              <p>
                If DATA PROCESSING IS BASED ON ART.&#160; 6 ABS. 1 &#160;LIT. E OR F GDPR, YOU HAVE THE RIGHT AT ANY
                TIME TO OBJECT TO <br />
                THE&#160; &#160;PROCESSING&#160;&#160; OF&#160; &#160;YOUR &#160;&#160;PERSONAL&#160; DATA&#160; FOR
                &#160;REASONS &#160;ARISING&#160; OUT&#160;&#160; OF&#160; &#160;YOUR&#160;PARTICULAR &#160;SITUATION;
                <br /> THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE ANY LEGAL BASIS ON WHICH ANY
                PROCESSING <br />
                IS BASED, &#160;YOU EXCEPT&#160; FROM &#160;THIS PRIVACY STATEMENT.&#160; IF YOU &#160;DISAGREE,&#160;
                WE WILL NO &#160;LONGER PROCESS &#160;YOUR
                <br />
                PERSONAL DATA CONCERNED,&#160; UNLESS WE CAN PROVIDE REASONABLE PRIVACY REASONS;&#160; R THE PROCESSING
                THAT
                <br />
                AFFECT&#160; &#160;YOUR&#160; INTERESTS,&#160;&#160;&#160; RIGHTS&#160; AND &#160;FREEDOMS&#160;
                &#160;OR &#160;THAT&#160; &#160;THE &#160;PROCESSING &#160;&#160;IS FOR &#160;&#160;THE&#160;
                &#160;ENFORCEMENT,
                <br />
                EXPRESSION OR DEFENSE OF RIGHTS CLAIMS (COMPLAINT IN ACCORDANCE WITH ART. 21 PARA 1 DSGVO).
              </p>{" "}
              <p>
                IF&#160; YOUR&#160; PERSONAL&#160; DATA IS&#160;PROCESSED&#160; FOR &#160;THE &#160;PURPOSE &#160;OF
                &#160;DIRECT&#160; ADVERTISING,&#160; YOU &#160;HAVE&#160; THE RIGHT TO <br />
                OBJECT&#160; AT ANY TIME &#160;TO THE &#160;PROCESSING OF &#160;YOUR PERSONAL &#160;DATA FOR &#160;THE
                PURPOSE &#160;OF SUCH &#160;ADVERTISING; <br /> THIS &#160;ALSO &#160;APPLIES&#160; TO PROFILING,&#160;
                TO THE &#160;EXTENT&#160; THAT IT &#160;IS RELATED&#160; TO&#160; SUCH&#160; DIRECT&#160; ADVERTISING.
                &#160;IF YOU <br /> DISAGREE, &#160;YOUR&#160; PERSONAL&#160; DATA&#160; WILL &#160;NOT &#160;BE&#160;
                USED &#160;ANYMORE &#160;FOR &#160;&#160;THE &#160;PURPOSE &#160;OF&#160; DIRECT&#160; &#160;ADVERTISING
                <br /> (DISAGREE IN ACCORDANCE WITH ART. 21 ABS. 2 DSGVO).
              </p>
              <h3 className="mb-8">Right of appeal to the competent supervisory authority</h3>{" "}
              <p>
                In the &#160;event of a breach of the GDPR,&#160; data subjects shall have a &#160;right of appeal to a
                supervisory authority,&#160; in particular in <br />
                the Member State of their habitual residence, their place of work or the place of the suspected breach.
                The right of appeal
                <br /> is without prejudice to any other administrative or judicial remedy.
              </p>
              <h3 className="mb-8">Right to data transfer</h3>{" "}
              <p>
                You have the right to have data that we process automatically on the basis of your consent or in
                performance of a contract <br /> transferred to you or to a &#160;third party in a valid,
                &#160;machine-readable format. &#160;If you request the direct&#160; transfer of the data to
                <br /> another controller, this will only be done insofar as it is technically feasible.
              </p>
              <h3 className="mb-8">SSL or TLS encryption</h3>{" "}
              <p>
                This site uses &#160;SSL or TLS encryption for&#160; security reasons and to protect&#160; the
                transmission of confidential content, such as <br />
                orders or&#160; requests that you send to us as&#160; site operator. &#160;You can recognize an&#160;
                encrypted &#160;connection by the&#160; fact that the
                <br /> address line of the browser changes from „http://“ to „https://“ and by the lock symbol in your
                browser line.
              </p>{" "}
              <p>
                If SSL or TLS encryption is activated, the data that you send to us cannot be read by third parties.
              </p>
              <h3 className="mb-8">Information, deletion and correction</h3>{" "}
              <p>
                You have, within the framework of the applicable legal provisions at any time the right to free
                information about your stored
                <br /> personal data,&#160; their origin and recipient and the purpose &#160;of data processing and, if
                necessary, &#160;a right to correct or delete <br /> this data. For this purpose, as well as for further
                questions on the subject of personal data, you can contact us at any time.
              </p>
              <h3 className="mb-8">Right to restrict processing</h3>{" "}
              <p>
                You have the right &#160;to request the restriction of the processing of &#160;your personal data. For
                this purpose, &#160;you can contact us <br /> at any time. The right to restriction of processing exists
                in the following cases:
              </p>{" "}
              <ul className="list-unstyled">
                {" "}
                <li>
                  If you dispute the accuracy of your personal data stored by us,&#160; we usually need time to review
                  this. For the duration of the <br />
                  review, you have the right to request the restriction of the processing of your personal data.
                </li>
                <li>
                  If the &#160;processing of&#160; your personal&#160; data has&#160; happened/is &#160;happening
                  &#160;unlawfully, &#160;you can request the&#160; restriction &#160;of data
                  <br /> processing instead of the deletion.
                </li>
                <li>
                  If we no longer need your personal data, &#160;but you need it to exercise,&#160; defend or enforce
                  legal claims, &#160;you have the right to
                  <br /> request the restriction of the processing of your personal data instead of the deletion.
                </li>{" "}
                <li>
                  If&#160; you have lodged&#160; an objection&#160; under Art. &#160;21 (1) DSGVO,&#160; a balancing
                  &#160;must be&#160; made between&#160; your interests and ours.
                  <br /> As&#160; long &#160;as &#160;it is&#160; not&#160; yet determined &#160;whose
                  interests&#160;predominate,&#160; you&#160; have &#160;the right to request&#160; the
                  &#160;restriction&#160; of the <br />
                  processing of your personal data.
                </li>{" "}
              </ul>{" "}
              <p>
                If you have&#160; restricted the processing&#160; of your personal&#160; data,&#160; these data may
                &#160;– &#160;apart from their storage &#160;– &#160;only with your <br /> consent &#160;or for the
                assertion.&#160; The data &#160;may only&#160; be processed &#160;with your &#160;consent or&#160; for
                the &#160;establishment, &#160;exercise&#160; or
                <br /> defenseof legal claims or to protect the rights of another natural or legal person or for reasons
                of substantial public interest
                <br /> of the European Union or a Member State.
              </p>
              <h2 className="mb-10">4. Data collection on this website</h2>
              <h3 className="mb-8">Server-Log-Files</h3>{" "}
              <p>
                The&#160; provider&#160; of the pages automatically&#160; collects and stores information&#160; in
                so-called server log files,&#160; which your browser <br /> automatically transmits to us. These are:
              </p>{" "}
              <ul className="list-unstyled">
                <li>Browsertyp and Browserversion</li>
                <li>Used operating system</li>
                <li>Referrer URL</li>
                <li>Host name of the accessing computer</li>
                <li>Time of the server request</li>
                <li>IP-Address</li>{" "}
              </ul>{" "}
              <p>These data are not merged with other data sources.</p>{" "}
              <p>
                The &#160;collection of&#160; this data &#160;is based&#160; on Art. 6&#160; Abs. 1 lit.&#160; f
                DSGVO.&#160; The website&#160; operator&#160; has a legitimate interest&#160; in the
                <br /> technical &#160;error-free presentation &#160;and optimization&#160; of its website &#160;for
                this purpose,&#160; the server log files&#160; must be collected.
              </p>
              <h3 className="mb-8">Request by e-mail, phone or fax</h3>{" "}
              <p>
                If you contact us by e-mail, telephone or fax, your inquiry including all resulting personal data (name,
                inquiry) will be stored <br />
                and processed by us for the purpose of processing your and processed by us for the purpose of dealing
                with your request. <br />
                We do not pass on this data without your without your consent.
              </p>{" "}
              <p>
                The processing of this data is based on Art. 6 (1) lit. b DSGVO, if your request is related to the
                performance of a contract or <br />
                is&#160; necessary &#160;for the&#160; implementation of&#160;pre-contractual &#160;measures.&#160; In
                all &#160;other&#160; cases,&#160; the&#160; processing&#160; is &#160;based &#160;on &#160;our <br />
                legitimate&#160; interest in the effective&#160; processing of the &#160;requests addressed to us&#160;
                (Art. 6&#160; (1) (f)&#160; DSGVO) or on your consent <br />
                (Art. 6 (1) (a) DSGVO) if this has been requested.
              </p>{" "}
              <p>
                The data you send us via contact requests will remain with us until you request us to request deletion,
                revoke your consent
                <br />
                to storage or the purpose for data storage no longer applies (e.g. after completion of processing your
                request). Mandatory
                <br /> statutory provisions, in particular statutory retention periods, remain unaffected.
              </p>
              <h2 className="mb-10">5. Plugins und Tools</h2>
              <h3 className="mb-8">Font Awesome (local hosting)</h3>{" "}
              <p>
                This site uses Font Awesome for consistent font rendering. Font Awesome is installed locally.
                <br /> There is no connection to servers of Fonticons, Inc.
              </p>{" "}
              <p>
                For more information about Font Awesome, please see the Font Awesome Privacy Policy at:{" "}
                <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener noreferrer">
                  <br />
                  https://fontawesome.com/privacy
                </a>
                .
              </p>
              <h2 className="mb-10">6. Data Privacy Officer</h2>
              <p>
                You can reach our data protection officer at the email info@rawdbids.com or our postal address with the
                <br /> addition of "the data protection officer".
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
