import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { DEFAULTMAILTEXTACCESSGRANTED, DEFAULTMAILTEXTACCESSREVOKED } from "../../../../utils/whitelistUtils";
import { Textarea } from "../../../common/Textarea";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { PriceRequestWhitelist, PriceRequestWhitelistStatus } from "../../../../model/priceRequestWhitelist.types";

interface WhitelistActionModalProps {
  whitelistObject: PriceRequestWhitelist;
  onWhitelistActionConfirmed: (
    requester: PriceRequestWhitelist,
    newStatus: PriceRequestWhitelistStatus,
    useMailNotification: boolean,
    mailText: string
  ) => Promise<boolean>;
  grantAccess: boolean;
}

const WhitelistActionModal: React.FunctionComponent<WhitelistActionModalProps> = ({
  whitelistObject,
  onWhitelistActionConfirmed,
  grantAccess,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [useMailNotification, setUseMailNotification] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [mailText, setMailText] = useState<string>("");

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);

  useEffect(() => {
    if (show) {
      setMailText(grantAccess ? DEFAULTMAILTEXTACCESSGRANTED : DEFAULTMAILTEXTACCESSREVOKED);
    }
  }, [show, grantAccess]);

  const handleToggleMailNotification = useCallback(() => {
    setUseMailNotification((prevState) => !prevState);
  }, []);

  const handleChangeMailText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => setMailText(e.target.value),
    []
  );

  const handleConfirm = async () => {
    setSaving(true);
    try {
      const result = await onWhitelistActionConfirmed(
        whitelistObject,
        grantAccess ? PriceRequestWhitelistStatus.GRANTED : PriceRequestWhitelistStatus.DENIED,
        useMailNotification,
        mailText
      );
      if (result) {
        setShow(false);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const errors = useMemo(() => {
    const result: Array<string> = [];
    if (useMailNotification && mailText.trim() === "") {
      result.push("Please provide a proper mail!");
    }
    return result;
  }, [useMailNotification, mailText]);

  return (
    <>
      <button
        type="button"
        className={"pr-0 btn btn-text " + (grantAccess ? "btn-text-success" : "btn-text-danger")}
        onClick={handleShow}
      >
        {grantAccess ? "Grant" : "Deny"}
      </button>
      <Modal contentClassName="bg-dark" show={show} size={"lg"} onHide={handleHide} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">
              {grantAccess ? "Grant Access" : "Deny Access"}
            </h1>
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <span className="text-white fw-bolder text-white text-left mt-10 mb-15">
            Do you really want to {grantAccess ? "grant" : "deny"} access for {whitelistObject.mail} to send price
            requests to the RAWBIDS-BOT?
          </span>
          <div className="form-check form-switch form-check-custom form-check-solid mt-5 mb-5">
            <input
              className="form-check-input position-static "
              checked={useMailNotification}
              onChange={handleToggleMailNotification}
              type="checkbox"
            />
            <label className="form-check-label text-muted">Notify User</label>
          </div>
          {useMailNotification && (
            <div>
              <label className="fs-7 text-white fw-bold my-auto">Notification Mail</label>
              <Textarea
                className="form-control custom-form-control mb-5"
                rows={12}
                value={mailText}
                placeholder="Mail text that will be sent to the customer ..."
                onChange={handleChangeMailText}
              />
            </div>
          )}
          {!whitelistObject.manuallyAdded && (
            <div className="text-warning fw-bolder text-left">
              <i className="icon-lg fas fa-info-circle text-warning mr-1" />
              Entry was added by RAWBIDS-BOT. Sending a response is highly recommended.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline btn-text-danger" onClick={handleHide}>
            Cancel
          </button>
          <ErrorOverlayButton
            errors={errors}
            saving={saving}
            className="btn btn-sm btn-outline btn-outline-light"
            buttonText="Confirm"
            onClick={handleConfirm}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WhitelistActionModal;
