import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getContractNumber, getCustomerContractTimelineEntry } from "../../../../utils/customerContractUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import {
  CustomerContractExtended,
  CustomerContractTimelineType,
  ValidityPeriod,
} from "../../../../model/customerContract.types";
import DateInput from "../../../common/DateInput";
import { CUSTOMERCONTRACT, transaction, UpdateAction } from "../../../../services/dbService";

interface CustomerContractAdjustValidityPeriodModalProps {
  contract: CustomerContractExtended;
}

const CustomerContractAdjustValidityPeriodModal: React.FC<CustomerContractAdjustValidityPeriodModalProps> = ({
  contract,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [validityPeriod, setValidityPeriod] = useState<ValidityPeriod>({ ...contract.validityPeriod });

  useEffect(() => {
    if (show) setValidityPeriod({ ...contract.validityPeriod });
  }, [show]);

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);

  const handleChangeValidityDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { valueAsDate, name } = e.target;
    if (valueAsDate) {
      setValidityPeriod((prevState) => ({ ...prevState, [name]: valueAsDate }));
    }
  }, []);

  const handleSetValidityPeriod = useCallback(async () => {
    try {
      setSaving(true);
      const action: UpdateAction = {
        collection: CUSTOMERCONTRACT,
        filter: { _id: contract._id },
        update: { validityPeriod },
        push: { timeline: getCustomerContractTimelineEntry(CustomerContractTimelineType.VALIDITY_PERIOD_CHANGED) },
      };
      const res = await transaction([action]);
      if (res) {
        toast.success("Validity period updated successfully.");
        handleHide();
      } else {
        toast.error("Error updating validity period");
      }
    } catch (e) {
      console.error(e);
      toast.error("Error updating validity period");
    } finally {
      setSaving(false);
    }
  }, [contract, validityPeriod]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (validityPeriod.end <= validityPeriod.start) errors.push("End of period can't be before the start");
    return errors;
  }, [validityPeriod]);

  return (
    <>
      <button className="btn btn-icon btn-sm p-0  h-auto w-auto align-middle mb-1 ml-1" onClick={handleShow}>
        <i className="fa fa-edit text-gray-300 text-hover-white p-0" />
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} centered size="lg">
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">
              Adjust Validity Period of {getContractNumber(contract)}
            </h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="text-white fs-5 mt-10 mb-5">
            <div className="row mb-2">
              <span className="col-3 pt-1 col-form-label fs-6 fw-bold">Validity Period</span>
              <div className="col">
                <DateInput
                  classes="form-control custom-form-control"
                  value={validityPeriod.start}
                  onBlur={handleChangeValidityDate}
                  name="start"
                />
              </div>
              <div className="col-auto text-white fw-bolder align-middle fs-2">-</div>
              <div className="col">
                <DateInput
                  classes="form-control custom-form-control"
                  value={validityPeriod.end}
                  onBlur={handleChangeValidityDate}
                  name="end"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-text-white btn-sm" onClick={handleHide}>
            Close
          </button>
          <ErrorOverlayButton
            errors={errors}
            className="btn btn-sm btn-outline btn-outline-light"
            buttonText="Confirm"
            saving={saving}
            onClick={handleSetValidityPeriod}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerContractAdjustValidityPeriodModal;
