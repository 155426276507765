import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { DataContextSupplierType } from "../../../context/dataContext";
import { TabDefinition } from "../../common/CustomTypes";
import CommodityPageGeneral from "./tabPanels/CommodityPageGeneral";
import OverviewOffers from "../../dashboard/supplier/OverviewContent/tabPanels/OverviewOffers";
import userService from "../../../services/userService";
import CommodityPageEUStock from "./tabPanels/CommodityPageEUStock";
import { isAnyFinishedProduct, SupplierArticleExtended } from "../../../utils/productArticleUtils";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { reduceCommodity, reduceFinishedProduct } from "../../../utils/dataTransformationUtils";

interface CommodityPageTabPanelProps {
  article: SupplierArticleExtended;
  context: DataContextSupplierType;
}

interface CommodityPageTabPanelState {
  currentTab: string;
}

class CommodityPageTabPanel extends PureComponent<CommodityPageTabPanelProps, CommodityPageTabPanelState> {
  constructor(props: CommodityPageTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[0].name };
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { article, context } = this.props;
    const canProvide = article.suppliers.some((s) => s.supplier._id.toString() === userService.getCompany());
    return [
      {
        name: "general",
        tabContent: "General Information",
        component: <CommodityPageGeneral article={article} context={context} onHandleTab={this.handleTab} />,
      },
      {
        name: "prices",
        tabContent: "Prices",
        component: (
          <OverviewOffers
            type="all"
            articles={[
              isAnyFinishedProduct(article)
                ? (reduceFinishedProduct(article) as FinishedProduct)
                : reduceCommodity(article),
            ]}
            single={true}
            context={context}
          />
        ),
        condition: () => canProvide,
      },
      {
        name: "euStock",
        tabContent: "EU Stock",
        component: <CommodityPageEUStock article={article} />,
        condition: () => canProvide,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { article } = this.props;
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();
    return (
      <Tab.Container id="commodityTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15 order-0 order-xl-0 mt-2 mt-xl-0">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(article)) && (
                  <li key={t.name + "_link"} className="nav-item cursor-pointer">
                    <Nav.Link
                      role="tab"
                      id={t.name}
                      eventKey={t.name}
                      active={currentTab === t.name}
                      className="text-active-white pb-4 "
                    >
                      {t.tabContent}
                    </Nav.Link>
                  </li>
                )
            )}
          </ul>
          <Tab.Content>
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(article)) && (
                  <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                    {t.component}
                  </Tab.Pane>
                )
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}
export default CommodityPageTabPanel;
