import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { Batch, BatchFile, BatchState } from "../../../../model/batch.types";
import { resolveFilePath, shortenAlias, uploadAndGetBatchFileObject } from "../../../../utils/fileUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import {
  extendBatch,
  getBatchTimelineEntry,
  T_BATCHBLOCKED,
  T_BATCHCOACREATED,
  T_BATCHRELEASED,
} from "../../../../utils/batchUtils";
import { Action, BATCH, transaction } from "../../../../services/dbService";
import { Textarea } from "../../../common/Textarea";
import CreateRawbidsCoA from "../../../batch/CreateRawbidsCoA";
import { createPDF } from "../../../../utils/pdfUtils";
import userService from "../../../../services/userService";
import { DataContextInternal } from "../../../../context/dataContext";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../services/slackService";
import { getOrderNumber } from "../../../../utils/orderUtils";
import { isAnyFinishedProduct } from "../../../../utils/productArticleUtils";

interface UpdateBatchStateModalProps {
  batchToHandle?: { batch: Batch; type: "release" | "block" };
  onClose: () => void;
}

const UpdateBatchStateModal: React.FunctionComponent<UpdateBatchStateModalProps> = ({ batchToHandle, onClose }) => {
  const context = useContext(DataContextInternal);

  let createRawbidsCoARef: CreateRawbidsCoA;
  const setRef = (ref: CreateRawbidsCoA | null) => {
    if (ref) createRawbidsCoARef = ref;
  };

  const batch = batchToHandle?.batch;
  const type = batchToHandle?.type;
  const selectFileRef = React.useRef<HTMLInputElement>(null);

  const [ownCoA, setOwnCoA] = useState<File | null | BatchFile>(batch?.ownCoA || null);
  const [saving, setSaving] = useState(false);
  const [reason, setReason] = useState("");
  const [showCoAForm, setShowCoAForm] = useState(false);
  const [freshCoA, setFreshCoA] = useState(false);

  useEffect(() => {
    setOwnCoA(batchToHandle?.batch.ownCoA || null);
    setReason("");
  }, [batchToHandle]);

  const handleSelectOwnCOA = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOwnCoA(e.target.files ? e.target.files[0] : null);
    setFreshCoA(true);
  };

  const handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  const handleShowForm = () => {
    setShowCoAForm(true);
  };

  const handleBack = () => {
    setShowCoAForm(false);
  };

  const handleReleaseBatch = async () => {
    if (!batch) {
      toast.error("Error releasing batch, batch not found.");
      return;
    }
    if (!ownCoA) {
      toast.error("Error releasing batch, CoA is missing!");
      return;
    }
    setSaving(true);
    try {
      const update: { state: string } = { state: BatchState.RELEASED };

      const action: Action = {
        collection: BATCH,
        filter: { _id: batch._id },
        update,
        push: { timeline: getBatchTimelineEntry(T_BATCHRELEASED) },
      };
      const result = await transaction([action]);
      if (result) {
        toast.success("Batch released successfully");
        // informing qm about batch release
        const extendedBatch = extendBatch(batch, context);
        const isFP = isAnyFinishedProduct(extendedBatch.commodity);
        const message = `:ballot_box_with_check: A batch RB-${batch.identifier}${
          extendedBatch.supplierOrder
            ? ` for the order <https://${process.env.REACT_APP_BASE_URL || ""}/supplierOrder/${
                batch.supplierOrder
              }|*${getOrderNumber(extendedBatch.supplierOrder)}*>`
            : ""
        } (<https://${process.env.REACT_APP_BASE_URL || ""}/${
          isFP ? "finishedProduct" : "commodity"
        }/${extendedBatch.commodity._id.toString()}|*${extendedBatch.commodity.title.en}*>) has been released.`;
        sendMessage(getDefaultSlackChannel(false, NotificationType.BATCH_HANDLING), message);
        onClose();
      } else {
        toast.error("Error releasing batch");
      }
    } finally {
      setFreshCoA(false);
      setSaving(false);
    }
  };

  const handleUploadCoA = async () => {
    if (freshCoA) {
      if (!batch) {
        toast.error("Error releasing batch, batch not found.");
        return;
      }
      setSaving(true);
      try {
        if (!ownCoA) {
          toast.error("Error releasing batch, CoA is missing!");
          return;
        }
        const update: { ownCoA?: BatchFile } = {};

        if (ownCoA instanceof File) {
          const uploadedOwnCoA = uploadAndGetBatchFileObject(ownCoA, ownCoA.name, batch.supplier);
          if (!uploadedOwnCoA) return;
          update.ownCoA = uploadedOwnCoA;
        }
        const action: Action = {
          collection: BATCH,
          filter: { _id: batch._id },
          update,
          push: { timeline: getBatchTimelineEntry(T_BATCHCOACREATED) },
        };
        const result = await transaction([action]);
        if (result) {
          toast.success("Uploaded CoA successfully");
        } else {
          toast.error("Error uploading CoA");
        }
      } finally {
        setFreshCoA(false);
        setSaving(false);
      }
    }
  };

  const handleCreateCoA = async () => {
    if (!batch) {
      toast.error("Error creating CoA.");
      return;
    }
    setSaving(true);
    try {
      if (createRawbidsCoARef) {
        const htmlCoA = createRawbidsCoARef.handleCreateCoA();
        if (htmlCoA) {
          const path = await createPDF(htmlCoA, `Certificate_of_Analysis-RB${batch.identifier}`, undefined, {
            marginLeft: "2cm",
            marginBottom: "4.2cm",
            footerHtml:
              process.env.NODE_ENV === "development"
                ? "https://demohub.rawbids.com/storage/specificationFooter.html" // Only for dev since localhost is not accessible. Update file via 'scp specificationFooter.html mediahub-rawbids-demo:~/work/storage/specificationFooter.html'
                : process.env.REACT_APP_BASE_URL + "/specificationFooter.html", // load specification footer from mediahub
            footerSpacing: 5,
          });
          if (path) {
            const file: BatchFile = {
              _id: new BSON.ObjectId(),
              name: "Certificate of Analysis",
              path,
              date: new Date(),
              uploadedBy: userService.getUserId(),
            };
            const timelineEntry = getBatchTimelineEntry(T_BATCHCOACREATED);
            const action: Action = {
              collection: BATCH,
              filter: { _id: batch._id },
              update: { ownCoA: file }, // replace existing CoA
              push: { timeline: timelineEntry },
            };
            const res = await transaction([action]);
            if (res) {
              toast.success("CoA created successfully");
              window.open(resolveFilePath(path));
            } else {
              toast.error("Error creating CoA");
            }
          }
        }
      }
    } finally {
      setSaving(false);
      setShowCoAForm(false);
    }
  };

  const handleBlockBatch = async () => {
    if (!batch || batch.state === BatchState.BLOCKED) return;
    setSaving(true);
    try {
      const action: Action = {
        collection: BATCH,
        filter: { _id: batch._id },
        update: { state: BatchState.BLOCKED },
        push: { timeline: getBatchTimelineEntry(T_BATCHBLOCKED, reason) },
      };
      const result = await transaction([action]);
      if (result) {
        toast.success("Batch blocked successfully");
        onClose();
      } else {
        toast.error("Error blocking batch");
      }
    } finally {
      setSaving(false);
    }
  };

  const validateData = () => {
    const errors = [];
    if (type === "release" && !ownCoA) errors.push("Rawbids CoA missing for release");
    if (type === "release" && batch && batch.state === BatchState.RELEASED) errors.push("Batch is already released");
    if (type === "block" && !reason.trim()) errors.push("Please add a reason why this batch is blocked");
    if (type === "block" && batch && batch.state === BatchState.BLOCKED) errors.push("Batch is already blocked");
    return errors;
  };

  const validateDataCoA = () => {
    // insert validation here!
    const errors: Array<string> = [];

    return errors;
  };

  return (
    <Modal contentClassName="bg-dark" show={!!batch} size={"xl"} onHide={onClose} centered>
      <Modal.Header className="border-0 pb-0">
        <Modal.Title>
          <h1 className="fw-bolder d-flex align-items-center text-white">
            {_.upperFirst(type)} Batch RB{batch?.identifier}
            <small className="ml-2 mt-1">({batch?.lot})</small>
          </h1>
        </Modal.Title>
        <CloseButton variant={"white"} onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <h5 className="mt-10">
          {type === "release"
            ? ownCoA
              ? "Batch has a CoA and could be released. You can upload a new one if you need to."
              : "Fill out the Rawbids CoA to release the batch or upload a manually created one. After releasing the batch it can be sent to customers."
            : "Block a batch if it is faulty and should not be delivered to customers."}
        </h5>
        {type === "block" && (
          <div className="mt-10">
            <label className="fs-5 fw-bold mb-2">Block Reason</label>
            <div>
              <Textarea
                className="form-control custom-form-control"
                name="blockReason"
                placeholder="Enter the reason why this batch is blocked"
                value={reason}
                onChange={handleChangeReason}
              />
            </div>
          </div>
        )}
        {type === "release" && (
          <div className="mt-10">
            {showCoAForm ? (
              <div className="mt-10">{batch && <CreateRawbidsCoA batch={batch} ref={setRef} context={context} />}</div>
            ) : (
              <>
                <button className="btn btn-light btn-sm mt-10" type="button" onClick={handleShowForm}>
                  Create New CoA
                </button>
                <br />
                <label className="fs-5 fw-bold mb-2 mt-10">CoA manual file Upload</label>
                <div>
                  <button className="btn btn-light btn-sm" type="button" onClick={() => selectFileRef.current?.click()}>
                    Select File
                  </button>
                  <input
                    type="file"
                    ref={selectFileRef}
                    accept="*"
                    style={{ display: "none" }}
                    onChange={handleSelectOwnCOA}
                  />
                  {ownCoA && (
                    <span className={"text-white fw-bold ml-3"}>
                      {"_id" in ownCoA ? shortenAlias(ownCoA.path) : ownCoA.name}
                      <small className="text-muted ml-2">
                        {"_id" in ownCoA ? "(uploaded)" : Math.round(ownCoA.size / 1024) + " KB"}
                      </small>
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {showCoAForm && type !== "block" ? (
          <>
            <button className={"btn btn-sm btn-light"} onClick={handleBack}>
              Back
            </button>
            <ErrorOverlayButton
              errors={validateDataCoA()}
              saving={saving}
              className={"btn btn-sm btn-light"}
              buttonText={"Create CoA"}
              onClick={handleCreateCoA}
            />
          </>
        ) : (
          <>
            <button className={"btn btn-sm btn-light"} onClick={onClose}>
              Close
            </button>
            {freshCoA ? (
              <ErrorOverlayButton
                errors={validateData()}
                saving={saving}
                className={"btn btn-sm btn-light"}
                buttonText={"Upload CoA"}
                onClick={handleUploadCoA}
              />
            ) : (
              <ErrorOverlayButton
                errors={validateData()}
                saving={saving}
                className={"btn btn-sm btn-light"}
                buttonText={type === "release" ? "Release Batch" : "Block Batch"}
                onClick={type === "release" ? handleReleaseBatch : handleBlockBatch}
              />
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateBatchStateModal;
