import React, { useCallback, useContext, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import Search from "../../../common/Search";
import BaseListing from "../../../common/BaseListing";
import { paginate, PaginationState } from "../../../common/Pagination";
import { DataContextInternal } from "../../../../context/dataContext";
import { InternalArticle, InternalArticleExtended } from "../../../../utils/productArticleUtils";
import { getArticleProperty } from "../../../../utils/commodityUtils";
import { PropertyType } from "../../../../utils/propertyUtils";
import { Property } from "../../../../model/property.types";
import { doFuseSearch } from "../../../../utils/baseUtils";
import {
  extendCommodity,
  extendFinishedProduct,
  reduceCommodity,
  reduceFinishedProduct,
} from "../../../../utils/dataTransformationUtils";
import { isFinishedProduct } from "../../../../utils/finishedProductUtils";
import { FinishedProduct } from "../../../../model/finishedProduct.types";

interface AddCommissionStockModalProps {
  onAddCommissionStock: (article: InternalArticle) => void;
}

const AddCommissionStockModal: React.FC<AddCommissionStockModalProps> = ({ onAddCommissionStock }) => {
  const context = useContext(DataContextInternal);
  const { commodity, finishedProduct } = context;

  const [show, setShow] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [{ currentPage, pageSize }, setPaginationState] = useState<PaginationState>({ currentPage: 1, pageSize: 15 });

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);
  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPaginationState((prevState) => ({ ...prevState, currentPage: 1 }));
  }, []);
  const handlePageChange = useCallback(
    (currentPage: number) => setPaginationState((prevState) => ({ ...prevState, currentPage })),
    []
  );
  const handlePageSizeChange = useCallback(
    (pageSize: number) => setPaginationState((prevState) => ({ ...prevState, pageSize, currentPage: 1 })),
    []
  );

  const handleAddCommissionStock = useCallback(
    (article: InternalArticleExtended) => {
      onAddCommissionStock(
        isFinishedProduct(article) ? (reduceFinishedProduct(article) as FinishedProduct) : reduceCommodity(article)
      );
      handleHide();
    },
    [onAddCommissionStock]
  );

  const headers = useMemo(
    () => [
      { title: "Article Title" },
      { title: "Category" },
      { title: "Composition" },
      { title: "Organic" },
      { title: "" },
    ],
    []
  );

  const articlesFiltered = useMemo(() => {
    const articles: Array<InternalArticleExtended> = [];
    if (!search.trim()) {
      for (let i = 0; i < commodity.length; i++) {
        const c = commodity[i];
        if (!c.approved || c.disabled) continue;
        articles.push(extendCommodity(c, context));
      }
      for (let i = 0; i < finishedProduct.length; i++) {
        const fp = finishedProduct[i];
        if (!fp.approved || fp.disabled) continue;
        articles.push(extendFinishedProduct(fp, context));
      }
    } else {
      const commoditiesFiltered = doFuseSearch(commodity, search, ["title.en", "subtitle.en"]);
      for (let i = 0; i < commoditiesFiltered.length; i++) {
        const c = commoditiesFiltered[i];
        if (!c.approved || c.disabled) continue;
        articles.push(extendCommodity(c, context));
      }
      const finishedProductsFiltered = doFuseSearch(finishedProduct, search, ["title.en", "subtitle.en"]);
      for (let i = 0; i < finishedProductsFiltered.length; i++) {
        const fp = finishedProductsFiltered[i];
        if (!fp.approved || fp.disabled) continue;
        articles.push(extendFinishedProduct(fp, context));
      }
    }
    return articles;
  }, [search, commodity, finishedProduct]);

  const articlesPaginated = useMemo(
    () => paginate(articlesFiltered, currentPage, pageSize),
    [articlesFiltered, currentPage, pageSize]
  );

  return (
    <>
      <button type="button" className="btn btn-light btn-sm float-right" onClick={handleShow}>
        Add Commission Stock
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} centered size="xl">
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Add Commission Stock</h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="w-50 float-right m-15 mt-0">
            <Search onSearch={handleSearch} value={search} />
          </div>
          <div className="my-15">
            <BaseListing
              headerDefinition={headers}
              bodyContent={
                <>
                  {articlesPaginated.map((aP) => (
                    <ArticleRow key={aP._id.toString()} article={aP} onAddCommissionStock={handleAddCommissionStock} />
                  ))}
                </>
              }
              documents={articlesFiltered}
              baseSize={15}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline btn-text-white" onClick={handleHide}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface ArticleRowProps {
  article: InternalArticleExtended;
  onAddCommissionStock: (article: InternalArticleExtended) => void;
}

const ArticleRow: React.FC<ArticleRowProps> = ({ article, onAddCommissionStock }) => {
  const handleAddCommissionStock = useCallback(() => onAddCommissionStock(article), [article, onAddCommissionStock]);

  const properties = article.properties;

  return (
    <tr className="text-white fs-6">
      <td className="align-middle">
        {article.title.en}
        <br />
        {article.subtitle.en}
      </td>
      <td className="align-middle">
        {(getArticleProperty(properties, PropertyType.CATEGORY) as Property)?.description.en || "-"}
      </td>
      <td className="align-middle">
        {(getArticleProperty(properties, PropertyType.COMPOSITION) as Property)?.description.en || "-"}
      </td>
      <td className="align-middle">{article.organic ? "Yes" : "No"}</td>
      <td className="align-middle">
        <button type="button" className="btn btn-light btn-sm float-right mr-2" onClick={handleAddCommissionStock}>
          Add
        </button>
      </td>
    </tr>
  );
};

export default AddCommissionStockModal;
