import _ from "lodash";
import Lottie from "lottie-react";
import React, { PureComponent } from "react";
import { Image } from "react-bootstrap";
import { Cookies, withCookies } from "react-cookie";
import ImageUploader from "react-images-upload";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { UserData } from "../../../model/userData.types";
import { getUserName, updateUserData } from "../../../utils/userUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { toAbsoluteUrl } from "../../../utils/baseUtils";
import animation from "../../../assets/animation2.json";
import userService from "../../../services/userService";
import authService, { DEFAULT_SESSION_KEY_NAME } from "../../../services/authService";
import { Input } from "../../common/Input";
import { resolveFilePath, uploadFile } from "../../../utils/fileUtils";
import { Company } from "../../../model/company.types";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../services/slackService";
import { updateCompany } from "../../../utils/companyUtils";
import { getDefaultAddress } from "../../../utils/addressUtils";

interface OnboardingProps extends RouteComponentProps {
  user: UserData;
  companies: Array<Company>;
  cookies: Cookies;
}

interface OnboardingState {
  password: string;
  passwordRepeat: string;
  user: UserData;
  company: Company;
  saving: boolean;
  view: number;
}

class Onboarding extends PureComponent<OnboardingProps, OnboardingState> {
  redirectInterval?: NodeJS.Timer;

  constructor(props: OnboardingProps) {
    super(props);
    const user = _.cloneDeep(props.user);
    const company = props.companies.find((c) => c._id.toString() === user.company)!;
    if (company.address.length === 0) company.address = [getDefaultAddress()];
    if (!user.phones || user.phones.length === 0) user.phones = [{ value: "", description: "" }];
    this.state = {
      password: "",
      passwordRepeat: "",
      saving: false,
      user,
      company,
      view: 1,
    };
  }

  componentDidUpdate(_: Readonly<OnboardingProps>, prevState: Readonly<OnboardingState>) {
    if (prevState.view !== 7 && this.state.view === 7) {
      this.redirectInterval = setInterval(async () => {
        await authService.getUser()?.refreshCustomData();
        this.props.history.push("/dashboard");
      }, 5000);
    }
  }

  componentWillUnmount() {
    if (this.redirectInterval) clearInterval(this.redirectInterval);
  }

  handleNextStep = () => this.setState({ view: this.state.view + 1 });

  handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUserDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const user = _.cloneDeep(this.state.user);
    const { name, value } = e.target;
    if (name.includes("phones")) {
      user.phones[0].value = value;
    } else {
      // @ts-ignore
      user[e.target.name] = e.target.value;
    }
    this.setState({ user });
  };

  /**
   * Handle profile image Upload, public only for internal
   * @param files the selected file(s)
   */
  handleUpload = (files: File[]) => {
    const user = _.cloneDeep(this.state.user);
    // Only one file is allowed, upload in mediahub
    if (files.length === 1) {
      const file = files.pop();
      if (!file) return;
      // Set saving flag so that buttons are disabled until actions were performed
      this.setState({ saving: true });
      // File name consisting of the userdata id, current timestamp and the given local file name
      const userId = userService.getUserId();
      const fileName = `${userId}_${Date.now()}_${file.name}`;
      let resp: string | boolean;
      try {
        resp = uploadFile(file, fileName, userService.getCompany(), true);
        if (!resp) {
          toast.error("File upload failed. Please try again later.");
          return;
        }
        user.image = resolveFilePath(resp.toString(), true);
      } catch (ex) {
        toast.error("File upload failed. Please try again later.");
        console.error(ex);
      } finally {
        // Unset saving flag so that buttons can be clicked again
        this.setState({ user, saving: false });
      }
    }
  };

  handleCompanyDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const company = _.cloneDeep(this.state.company);
    // @ts-ignore
    company[e.target.name] = e.target.value;
    this.setState({ company });
  };

  handleFinishOnboarding = async () => {
    const { user, company } = this.state;
    this.setState({ saving: true });
    try {
      const resUser = await updateUserData(user, {
        prename: user.prename,
        surname: user.surname,
        position: user.position,
        phones: user.phones,
        image: user.image,
        onboardingDone: true,
      });
      let resCompany;
      if (resUser && resUser.modifiedCount > 0)
        resCompany = await updateCompany({ address: company.address, vat: company.vat }, company._id);
      if (resCompany && resCompany.modifiedCount > 0) {
        await userService.getUser()?.refreshCustomData();
        toast.success("Account successfully activated");
        sendMessage(
          getDefaultSlackChannel(false, NotificationType.ONBOARDING),
          `${getUserName(user)} of ${company.name} successfully completed the onboarding!`
        );
        this.setState({ view: 7 });
      } else {
        toast.error("Error activating account. Please contact our team");
        sendMessage(
          getDefaultSlackChannel(true, NotificationType.ONBOARDING),
          `${getUserName(user)} of ${
            company.name
          } had issues completing the onboarding! The process could not be completed.`
        );
      }
    } catch (e) {
      toast.error("Error activating account. Please contact our team");
      sendMessage(
        getDefaultSlackChannel(true, NotificationType.ONBOARDING),
        `${getUserName(user)} of ${
          company.name
        } had issues completing the onboarding! The process could not be completed.`
      );
    } finally {
      this.setState({ saving: false });
    }
  };

  handleClickResetImg = () => {
    const user = _.cloneDeep(this.state.user);
    user.image = "";
    this.setState({ user });
  };

  handleSavePasswordAndAuthenticateUser = async () => {
    const { cookies } = this.props;
    const { password, view } = this.state;
    this.setState({ saving: true });
    const email = userService.getUserMail();
    try {
      await authService.changePassword(email, password);
      await authService.logout();
      await authService.login(email, password);
      const apiKeys = await userService.getUser()?.apiKeys.fetchAll();
      if (apiKeys) {
        try {
          for (const key of apiKeys) {
            await userService.getUser()?.apiKeys.delete(key._id);
          }
        } finally {
          // Make sure session key is always deleted
          cookies.remove(DEFAULT_SESSION_KEY_NAME);
        }
      }
      const cookie = cookies?.get(DEFAULT_SESSION_KEY_NAME);
      if (!cookie) {
        await authService.createAndSetApiKey(cookies, DEFAULT_SESSION_KEY_NAME);
      }
    } catch (e) {
      toast.error("Error changing password - please contact our team to resolve this issue!");
    } finally {
      this.setState({ saving: false, view: view + 1 });
    }
  };

  validatePassword = () => {
    const { password, passwordRepeat } = this.state;
    const errors = [];
    if (password.length < 10) errors.push("Password must be at least 10 characters long");
    if (password !== passwordRepeat) errors.push("Passwords do not match");
    return errors;
  };

  render() {
    const { password, passwordRepeat, user, company, saving, view } = this.state;
    const passwordErrors = this.validatePassword();
    const image = user.image ? user.image : toAbsoluteUrl("/assets/media/avatars/blank_dark.png");
    return (
      <div
        className="d-flex flex-column"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/assets/media/misc/bg-1.jpg")})`,
          height: "100%",
          backgroundSize: "cover",
          justifyContent: "center",
        }}
      >
        <div className="d-flex flex-column flex-column flex-lg-row">
          <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
            <div className="d-flex flex-center flex-lg-start flex-column">
              <img alt="Logo" src={toAbsoluteUrl("/assets/media/logo.png")} className="mb-7 h-80px h-80px" />
              <h2 className="text-white fw-bold m-0">The biggest virtual marketplace for premium ingredients.</h2>
            </div>
          </div>
          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
            <div
              className="bg-body d-flex flex-column align-items-stretch flex-center w-md-600px p-20 border-none"
              style={{ borderRadius: "1.5rem" }}
            >
              {view === 1 ? (
                <>
                  <h1 className={"text-dark text-center mt-15"}>Welcome, {getUserName(user)}!</h1>
                  <span className={"text-center mt-5 mb-5"}>
                    Welcome to the first step of your journey with Rawbids!
                    <br />
                    Let's get started by setting up your account.
                  </span>
                  <Lottie animationData={animation} loop={true} className="w-50 mx-auto my-10" />
                  <div className="d-grid my-10">
                    <button className="btn btn-dark" onClick={this.handleNextStep}>
                      Start Onboarding
                    </button>
                  </div>
                </>
              ) : view === 2 ? (
                <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-5">
                  <div className="form w-100 text-center">
                    <>
                      <span className="mb-2 d-block">Step 1 / 6</span>
                      <div className="progress h-7px" style={{ backgroundColor: "#eeeeee" }}>
                        <div
                          className="progress-bar bg-black"
                          role="progressbar"
                          style={{ width: "14%" }}
                          aria-valuenow={14}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                      <h1 className={"text-dark text-center my-10"}>Set your password</h1>
                      <div className="mb-8">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="password"
                          name="password"
                          value={password}
                          placeholder="Password"
                          autoComplete="off"
                          onChange={this.handlePasswordChange}
                        />
                      </div>
                      <div className="mb-3">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="password"
                          name="passwordRepeat"
                          value={passwordRepeat}
                          placeholder="Repeat Password"
                          autoComplete="off"
                          onChange={this.handlePasswordChange}
                        />
                      </div>
                      <span className="d-block text-center text-muted">
                        Password must be at least 10 characters long.
                      </span>
                    </>
                  </div>
                  <div className="d-grid my-10 w-100">
                    <ErrorOverlayButton
                      errors={passwordErrors}
                      className="btn btn-dark"
                      buttonText="Set Password"
                      saving={saving}
                      onClick={this.handleSavePasswordAndAuthenticateUser}
                    />
                  </div>
                </div>
              ) : view === 3 ? (
                <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-5">
                  <div className="form w-100 text-center">
                    <span className="mb-2 d-block">Step 2 / 6</span>
                    <div className="progress h-7px" style={{ backgroundColor: "#eeeeee" }}>
                      <div
                        className="progress-bar bg-black"
                        role="progressbar"
                        style={{ width: "28%" }}
                        aria-valuenow={28}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <h1 className={"text-dark text-center my-10"}>Check your personal information</h1>
                    <div className="mb-5">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <Input
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            name="prename"
                            value={user.prename}
                            placeholder="Prename"
                            autoComplete="off"
                            onChange={this.handleUserDataChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <Input
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            name="surname"
                            value={user.surname}
                            placeholder="Surname"
                            autoComplete="off"
                            onChange={this.handleUserDataChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-5">
                      <Input
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        name="position"
                        value={user.position}
                        placeholder="Position"
                        autoComplete="off"
                        onChange={this.handleUserDataChange}
                      />
                    </div>
                    <div className="mb-5">
                      <Input
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        name="phones[0].value"
                        value={user.phones[0].value}
                        placeholder="Phone"
                        autoComplete="off"
                        onChange={this.handleUserDataChange}
                      />
                      <span className="d-block text-center text-muted mt-2">
                        All details can be changed later in settings.
                      </span>
                    </div>
                    <div className="d-grid my-10 w-100">
                      <button className="btn btn-dark" onClick={this.handleNextStep}>
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              ) : view === 4 ? (
                <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-5">
                  <div className="form w-100 text-center">
                    <span className="mb-2 d-block">Step 3 / 6</span>
                    <div className="progress h-7px" style={{ backgroundColor: "#eeeeee" }}>
                      <div
                        className="progress-bar bg-black"
                        role="progressbar"
                        style={{ width: "43%" }}
                        aria-valuenow={43}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <h1 className={"text-dark text-center mt-10"}>Upload a profile picture</h1>
                    <span className="d-block text-center text-muted mb-10">Optional</span>
                    <div className="mb-8">
                      <div className="text-center">
                        <Image src={image} className="mw-100px mb-0 rounded" style={{ background: "#fafafa" }} />
                        <button
                          type="button"
                          className="badge badge-circle reset-image-button-relative border-0 reset-image-button-danger"
                          aria-label="Close"
                          onClick={this.handleClickResetImg}
                        >
                          <i className="fas fa-times" />
                        </button>
                      </div>
                      <ImageUploader
                        buttonClassName={" light " + (saving ? "disabled" : "")}
                        withIcon={false}
                        withLabel={false}
                        buttonText={"Upload Picture"}
                        imgExtension={["jpeg", ".jpg", ".png"]}
                        accept=".png, .jpg, .jpeg"
                        maxFileSize={10 * 1024 * 1024}
                        singleImage
                        errorClass="px-3"
                        fileSizeError={"is too big. Max. 10MB"}
                        fileTypeError={"is not the right file type. Choose .jpg or .png"}
                        onChange={saving ? undefined : this.handleUpload}
                      />
                    </div>
                  </div>
                  <div className="d-grid my-10 w-100">
                    <button className="btn btn-dark" onClick={this.handleNextStep}>
                      Continue
                    </button>
                  </div>
                </div>
              ) : view === 5 ? (
                <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-5">
                  <div className="form w-100 text-center">
                    <span className="mb-2 d-block">Step 4 / 6</span>
                    <div className="progress h-7px" style={{ backgroundColor: "#eeeeee" }}>
                      <div
                        className="progress-bar bg-black"
                        role="progressbar"
                        style={{ width: "66%" }}
                        aria-valuenow={66}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <h1 className={"text-dark text-center my-10"}>Check company information</h1>
                    <div className="mb-5">
                      <Input
                        className="form-control form-control-lg form-control-solid disabled"
                        type="text"
                        defaultValue={company.name}
                        disabled={true}
                      />
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <div className="mb-5">
                          <Input
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            name="address[0].street"
                            value={company.address[0].street}
                            placeholder="Street"
                            autoComplete="off"
                            onChange={this.handleCompanyDataChange}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-5">
                          <Input
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            name="address[0].houseNo"
                            value={company.address[0].houseNo}
                            placeholder="House No."
                            autoComplete="off"
                            onChange={this.handleCompanyDataChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-5">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <input
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            name="address[0].postalCode"
                            value={company.address[0].postalCode}
                            placeholder="ZIP"
                            autoComplete="off"
                            onChange={this.handleCompanyDataChange}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <input
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            name="address[0].city"
                            value={company.address[0].city}
                            placeholder="City"
                            autoComplete="off"
                            onChange={this.handleCompanyDataChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-5">
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        name="vat"
                        value={company.vat}
                        placeholder="VAT-ID"
                        autoComplete="off"
                        onChange={this.handleCompanyDataChange}
                      />
                    </div>
                    <span className="d-block text-center text-muted mt-2">
                      All details can be changed later in settings.
                    </span>
                  </div>
                  <div className="d-grid my-10 w-100">
                    <button className="btn btn-dark" onClick={this.handleNextStep}>
                      Continue
                    </button>
                  </div>
                </div>
              ) : view === 6 ? (
                <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-5">
                  <div className="form w-100 text-center">
                    <span className="mb-2 d-block">Step 5 / 6</span>
                    <div className="progress h-7px" style={{ backgroundColor: "#eeeeee" }}>
                      <div
                        className="progress-bar bg-black"
                        role="progressbar"
                        style={{ width: "83%" }}
                        aria-valuenow={83}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <h1 className={"text-dark text-center my-10"}>Early Access Group</h1>
                    <div className="mb-5">
                      <span className="text-center d-block mx-5">
                        As part of our <b>Early Access group</b>, you're among the first to try our new features. Your
                        insights and suggestions are highly valued, so don't hesitate to reach out.
                      </span>
                    </div>
                    <span className="text-center d-block mx-5">Please report problems or suggestions to</span>
                    <span className="text-center d-block mx-5 font-weight-bold mt-2">developer@rawbids.com</span>
                  </div>
                  <div className="d-grid my-10 w-100">
                    <ErrorOverlayButton
                      errors={[]}
                      className="btn btn-dark"
                      buttonText="Finish"
                      onClick={this.handleFinishOnboarding}
                      saving={saving}
                    />
                  </div>
                </div>
              ) : (
                view === 7 && (
                  <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-5">
                    <div className="form w-100">
                      <h1 className={"text-dark text-center my-20"}>Finished</h1>
                      <div className="text-center">
                        <div className="text-white">
                          <svg className={"checkmark_success"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className={"checkmark__circle_success"} cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                          </svg>
                          <span className="fs-1 fw-bolder">"Onboarding done"</span>
                          <br />
                          <span className="text-muted mt-2 d-block mb-10">
                            You will be redirected in {5} seconds...
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="d-flex flex-stack px-lg-10">
                <div className="me-0">
                  <button className="btn btn-flex btn-link fs-base disabled">
                    <img
                      data-kt-element="current-lang-flag"
                      className="w-20px h-20px rounded me-3"
                      src={toAbsoluteUrl("assets/media/flags/united-states.svg")}
                      alt=""
                    />
                    <span data-kt-element="current-lang-name" className="me-1 link-dark">
                      English
                    </span>
                  </button>
                </div>
                <div className="d-flex text-primary fs-base gap-5">
                  <a
                    href="https://rawbids.com/files/terms_of_purchase.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-dark"
                  >
                    Terms of Purchase
                  </a>
                  <a
                    href="https://rawbids.com/files/terms_of_sale.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-dark"
                  >
                    Terms of Sale
                  </a>
                  <a href="mailto:info@rawbids.com" className="link-dark">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(Onboarding));
