import React, { useEffect } from "react";
import { Cookies, withCookies } from "react-cookie";
import authService, { DEFAULT_SESSION_KEY_NAME } from "../../services/authService";
import { ANONYMOUS_EMAIL, ANONYMOUS_PASSWORD } from "../../utils/anonymousViewUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../services/slackService";

interface DemoLoginProps {
  cookies: Cookies;
}

const DemoLogin: React.FC<DemoLoginProps> = ({ cookies }) => {
  useEffect(() => {
    authService
      .login(ANONYMOUS_EMAIL, ANONYMOUS_PASSWORD)
      .then(() => authService.createAndSetApiKey(cookies, DEFAULT_SESSION_KEY_NAME))
      .then(() => {
        sendMessage(
          getDefaultSlackChannel(false, NotificationType.ANONYMOUS),
          `New anonymous User login:\nBrowser/OS: ${navigator.userAgent}\nLanguage: ${navigator.language}\nScreenwidth: ${window.innerWidth}px\n`
        );
        window.location.href = "/";
      });
  }, []);

  return <></>;
};

export default withCookies(DemoLogin);
