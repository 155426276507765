import React, { PureComponent } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../utils/baseUtils";

interface SearchProps {
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  placeholder?: string;
  prependClasses?: string;
  inputClasses?: string;
  buttonClasses?: string;
  idSuffix?: string;
  value?: string;
  hideButton?: boolean;
}

interface SearchState {
  value: string;
}

class Search extends PureComponent<SearchProps, SearchState> {
  searchRef: React.RefObject<HTMLInputElement>;
  constructor(props: SearchProps) {
    super(props);
    this.searchRef = React.createRef();
    this.state = { value: props.value ?? "" };
  }

  componentDidUpdate(prevProps: Readonly<SearchProps>) {
    if (prevProps.value !== this.props.value) this.setState({ value: this.props.value || "" });
  }

  handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: e.target.value });
  };

  handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.target.blur();
      e.target.focus();
    }
  };

  render() {
    const {
      prependClasses,
      buttonClasses,
      inputClasses,
      onSearch,
      onChange,
      onClick,
      placeholder,
      idSuffix,
      hideButton,
    } = this.props;
    const { value } = this.state;
    return (
      <div className="input-group">
        <div
          className={"input-group-prepend custom-form-control rounded-start " + prependClasses}
          style={{ height: "calc(1.5em + 0.75rem + 2px)", fontSize: "1.1rem", cursor: "text" }}
          onClick={() => this.searchRef && this.searchRef.current?.focus()}
        >
          <span className="svg-icon svg-icon-1 my-auto ml-2">
            <SVG src={toAbsoluteUrl("/assets/media/svg/custom/search.svg")} />
          </span>
        </div>
        <input
          type="text"
          className={"form-control custom-form-control " + inputClasses}
          placeholder={placeholder ?? "Search..."}
          id={"searchInput" + (idSuffix ? idSuffix : "")}
          name={"search" + (idSuffix ? idSuffix : "")}
          ref={this.searchRef}
          value={value}
          onBlur={onSearch}
          autoComplete={"off"}
          onChange={onChange ?? this.handleValueChange}
          onKeyDown={this.handleKeyDown}
          onClick={onClick}
        />
        {!hideButton && (
          <div className="input-group-append">
            <button
              style={{ zIndex: 5 }} // on focus input field gets zIndex 3 which results in missing left border of button
              className={
                buttonClasses ? buttonClasses : "btn btn-sm btn-outline btn-outline-light responsive-focus-button"
              }
            >
              Search
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Search;
