import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { CustomerCustomerOrderExtended } from "../../../../model/customer/customerCustomerOrder.types";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../../context/dataContext";
import { TabDefinition } from "../../../common/CustomTypes";
import OrderDocuments from "../../common/OrderDocuments";
import OrderInvoices from "../../common/OrderInvoices";
import OrderHistory from "../../common/OrderHistory";
import OrderPageOrderDetails from "./tabPanels/OrderPageOrderDetails";
import OrderCreditNotes from "../../common/OrderCreditNotes";
import MobileDropdown from "../../../common/MobileDropdown";

interface OrderPageTabPanelProps {
  order: CustomerCustomerOrderExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface OrderPageTabPanelState {
  currentTab: string;
  showDropdown: boolean;
}

class OrderPageTabPanel extends PureComponent<OrderPageTabPanelProps, OrderPageTabPanelState> {
  constructor(props: OrderPageTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[0].name, showDropdown: false };
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { order, context } = this.props;
    return [
      {
        name: "details",
        tabContent: "Order Details",
        component: <OrderPageOrderDetails order={order} context={context} />,
      },
      {
        name: "documents",
        tabContent: "Documents",
        component: <OrderDocuments order={order} context={context} />,
        disabled: isAnonymousContext(context),
      },
      {
        name: "invoices",
        tabContent: "Invoices",
        component: (
          <div>
            <OrderInvoices order={order} context={context} />
            <OrderCreditNotes order={order} context={context} />
          </div>
        ),
        disabled: isAnonymousContext(context),
      },
      {
        name: "history",
        tabContent: "History",
        component: <OrderHistory order={order} context={context} />,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTabSelect = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  handleToggleDropdown = () => {
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  };

  render() {
    const { currentTab, showDropdown } = this.state;
    const tabs = this.getTabDefinitions();

    return (
      <Tab.Container id="customerOrderTabs" activeKey={currentTab} onSelect={this.handleTabSelect}>
        <div className="flex-lg-row-fluid ms-lg-15 order-0 order-xl-0 mt-2 mt-xl-0">
          <MobileDropdown
            showDropdown={showDropdown}
            onTabSelect={this.handleTabSelect}
            onToggle={this.handleToggleDropdown}
            currentTab={currentTab}
            tabs={tabs}
          />
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-4 d-none d-md-flex">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="nav-item cursor-pointer">
                <Nav.Link
                  role="tab"
                  id={t.name}
                  eventKey={t.name}
                  active={currentTab === t.name}
                  className="text-active-white pb-4 "
                  style={{ opacity: t.disabled ? 0.5 : 1 }}
                  disabled={t.disabled}
                >
                  {t.tabContent}
                </Nav.Link>
              </li>
            ))}
          </ul>
          <Tab.Content>
            {tabs.map((t) => (
              <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                {t.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default OrderPageTabPanel;
