import React, { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CustomerCommodity } from "../../../model/customer/customerCommodity.types";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import { PropertyType, resolveProperties } from "../../../utils/propertyUtils";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { Property } from "../../../model/property.types";
import { hasPricesInAnonymousView } from "../../../utils/anonymousViewUtils";
import Tooltip from "../../common/Tooltip";
import FavoriteStar from "../../common/FavoriteStar";
import CountryWidget from "../../common/CountryWidget";
import AnonymousBlurText from "../../common/anonymous/AnonymousBlurText";
import { formatCurrency } from "../../../utils/baseUtils";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import { Commodity } from "../../../model/commodity.types";

interface CustomerCommodityCardElementProps extends RouteComponentProps {
  commodity: Commodity | CustomerCommodity;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const CustomerCommodityCardElement: React.FunctionComponent<CustomerCommodityCardElementProps> = ({
  commodity,
  context,
  history,
}) => {
  const properties = useMemo(() => {
    return resolveProperties(commodity.properties, context.property);
  }, [commodity.properties, context.property]);
  const category = getArticleProperty(properties, PropertyType.CATEGORY) as Property | null;
  const forwardCommodity = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(`/commodity/${commodity._id.toString()}`);
  };

  const isAC = isAnonymousContext(context);
  const blurPrice = isAC && !hasPricesInAnonymousView(commodity._id.toString(), context.configuration);

  return (
    <>
      <div className="col-12 text-left mb-2">
        {!isAC && (
          <Tooltip tooltipText="Mark as Favorite" delay={{ show: 500, hide: 0 }}>
            <span>
              <FavoriteStar favorite={commodity._id.toString()} context={context} />
            </span>
          </Tooltip>
        )}
      </div>
      <div className="col-12 text-left mb-2">
        <span className="text-white fs-5 d-inline-block" onClick={forwardCommodity}>
          {commodity.title.en}
        </span>
        {commodity.organic && <i title="organic" className="fas fa-leaf text-success d-inline-block ml-2" />}
        <span className="text-muted d-block" style={{ fontSize: "0.85rem" }}>
          {commodity.subtitle.en}
        </span>
      </div>
      <div className="col-4 text-muted">Category</div>
      <div className="col-8 text-right mb-2">
        {category && <span className="badge badge-gray fw-bolder px-2 py-2 text-white">{category.name.en}</span>}
      </div>
      <div className="col-6 text-muted">CAS Number</div>
      <div className="col-6 text-right mb-2">
        {commodity.casNumber.some((c) => c.trim().length > 0) ? commodity.casNumber : "Not Applicable"}
      </div>
      <div className="col-4 text-muted">Origin</div>
      <div className="col-8 text-right mb-2">
        <CountryWidget countryCode={commodity.country.code} />
      </div>
      <div className="col-12 text-muted mb-2">Price from</div>
      <div className="col-12">
        <div className="d-flex flex-stack justify-content-between">
          <div>
            <span className="fs-5 text-white" onClick={blurPrice ? (e) => e.stopPropagation() : undefined}>
              {blurPrice ? (
                <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
              ) : commodity.fromPrice?.seafreight.price && commodity.fromPrice?.outdated ? (
                <Tooltip tooltipText="Price outdated">
                  <span className="text-muted">
                    {formatCurrency(commodity.fromPrice?.seafreight.price, commodity.fromPrice?.seafreight.currency)}
                  </span>
                </Tooltip>
              ) : commodity.fromPrice?.seafreight.price ? (
                formatCurrency(commodity.fromPrice?.seafreight.price, commodity.fromPrice?.seafreight.currency)
              ) : (
                <p className="text-muted fs-7 mb-0">On Request</p>
              )}
            </span>
            <small className="text-muted d-block">Seafreight</small>
          </div>
          <div>
            <span className="fs-5 text-white" onClick={blurPrice ? (e) => e.stopPropagation() : undefined}>
              {blurPrice ? (
                <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
              ) : commodity.fromPrice?.airfreight.price && commodity.fromPrice?.outdated ? (
                <Tooltip tooltipText="Price outdated">
                  <span className="text-muted">
                    {formatCurrency(commodity.fromPrice?.airfreight.price, commodity.fromPrice?.airfreight.currency)}
                  </span>
                </Tooltip>
              ) : commodity.fromPrice?.airfreight.price ? (
                formatCurrency(commodity.fromPrice?.airfreight.price, commodity.fromPrice?.airfreight.currency)
              ) : (
                <p className="text-muted fs-7 mb-0">On Request</p>
              )}
            </span>
            <small className="text-muted d-block">Airfreight</small>
          </div>
          <div>
            <span className="fs-5 text-white" onClick={blurPrice ? (e) => e.stopPropagation() : undefined}>
              {blurPrice ? (
                <AnonymousBlurText>{formatCurrency(1.23, CUSTOMER_BASE_CURRENCY)}</AnonymousBlurText>
              ) : commodity.fromPrice?.warehouse.price && commodity.fromPrice?.outdated ? (
                <Tooltip tooltipText="Price outdated">
                  <span className="text-muted">
                    {formatCurrency(commodity.fromPrice?.warehouse.price, commodity.fromPrice?.warehouse.currency)}
                  </span>
                </Tooltip>
              ) : commodity.fromPrice?.warehouse.price ? (
                formatCurrency(commodity.fromPrice?.warehouse.price, commodity.fromPrice?.warehouse.currency)
              ) : (
                <p className="text-muted fs-7 mb-0">Not Available</p>
              )}
            </span>
            <small className="text-muted d-block">Warehouse</small>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(CustomerCommodityCardElement);
