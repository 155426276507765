import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { CommoditySnapshot } from "../../../../../../model/commodity.types";
import { SO_T_BATCHATTACHED, SupplierOrderExtended } from "../../../../../../model/supplierOrder.types";
import ErrorOverlayButton from "../../../../../common/ErrorOverlayButton";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { getOrderNumber } from "../../../../../../utils/orderUtils";
import CustomSelect, { SelectOption } from "../../../../../common/CustomSelect";
import { Input } from "../../../../../common/Input";
import { getBatchAmount } from "../../../../../../utils/batchUtils";
import { Batch } from "../../../../../../model/batch.types";
import DateInput from "../../../../../common/DateInput";
import { formatCurrency } from "../../../../../../utils/baseUtils";
import { resolveFilePath, shortenAlias } from "../../../../../../utils/fileUtils";
import { Action, BATCH, SUPPLIERORDER, transaction, UPDATE } from "../../../../../../services/dbService";
import { getSupplierOrderTimelineEntry } from "../../../../../../utils/supplierOrderUtils";
import { FinishedProductSnapshot } from "../../../../../../model/finishedProduct.types";
import { formatArticleUnit } from "../../../../../../utils/productArticleUtils";

interface LinkExistingBatchModalProps {
  commodity: CommoditySnapshot | FinishedProductSnapshot;
  supplierOrder: SupplierOrderExtended;
  disabled: boolean;
  context: DataContextInternalType;
}

interface LinkExistingBatchModalState {
  selectedBatch?: SelectOption<Batch>;
  saving: boolean;
  show: boolean;
}

class LinkExistingBatchModal extends PureComponent<LinkExistingBatchModalProps, LinkExistingBatchModalState> {
  constructor(props: LinkExistingBatchModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState({ show: false });

  handleSelectBatch = (selectedBatch: SelectOption<Batch>) => this.setState({ selectedBatch });

  handleLinkBatch = async () => {
    const { disabled, supplierOrder } = this.props;
    const { selectedBatch } = this.state;
    if (disabled || !selectedBatch?.object) return;
    this.setState({ saving: true });
    try {
      const actions: Array<Action> = [];
      actions.push({
        collection: BATCH,
        action: UPDATE,
        filter: { _id: selectedBatch.object._id },
        update: { supplierOrder: supplierOrder._id.toString() },
      });
      actions.push({
        collection: SUPPLIERORDER,
        action: UPDATE,
        filter: { _id: supplierOrder._id },
        push: { timeline: getSupplierOrderTimelineEntry(SO_T_BATCHATTACHED, {}) },
      });
      const res = await transaction(actions);
      if (res) {
        toast.success("Batch successfully attached to Supplier Order");
        this.setState({ show: false });
      } else {
        toast.error("Error attaching Batch to Supplier Order");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  getDefaultState = (show: boolean): LinkExistingBatchModalState => {
    return { show, saving: false, selectedBatch: undefined };
  };

  getBatchesForSupplierOrder = (): Array<SelectOption<Batch>> => {
    const { context, commodity, supplierOrder } = this.props;
    const validBatches = context.batch.filter(
      (b) =>
        !b.supplierOrder &&
        b.supplier === supplierOrder.supplier._id.toString() &&
        b.commodity._id.toString() === commodity._id.toString()
    );
    return validBatches.map((vB) => {
      return { value: vB._id.toString(), label: `RB${vB.identifier} - Lot: ${vB.lot}`, object: vB };
    });
  };

  validateData = () => {
    const { selectedBatch } = this.state;
    const errors: Array<string> = [];
    if (!selectedBatch) errors.push("Batch must be selected");
    return errors;
  };

  render() {
    const { disabled, supplierOrder } = this.props;
    const { selectedBatch, show, saving } = this.state;
    const validBatches = this.getBatchesForSupplierOrder();
    const errors = this.validateData();
    const batch = selectedBatch?.object;

    return (
      <>
        <ErrorOverlayButton
          errors={validBatches.length === 0 ? ["No batch without supplier order available"] : []}
          className={"btn btn-success btn-sm mr-2 " + (validBatches.length !== 0 && "btn-active-light")}
          disabled={disabled}
          buttonText="Link Batch"
          onClick={this.handleShow}
        />
        <Modal contentClassName="bg-dark" show={show} size="lg" onHide={this.handleHide} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                Select Batch to Link for {getOrderNumber(supplierOrder)}
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 mt-5">
                <label className="fs-5 fw-bold mb-2">Batch</label>
                <CustomSelect
                  options={this.getBatchesForSupplierOrder()}
                  value={selectedBatch}
                  onChange={this.handleSelectBatch}
                  matchFormControl={true}
                />
              </div>
            </div>
            {batch && (
              <div className="row">
                <div className="col-12 col-sm-6 col-xl-4 mt-5">
                  <label className="fs-5 fw-bold mb-2">LOT-Number</label>
                  <Input type="text" value={batch.lot} className="form-control custom-form-control" disabled={true} />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mt-5">
                  <label className="fs-5 fw-bold mb-2">Stored On</label>
                  <DateInput
                    value={batch.stockedDate}
                    classes="form-control custom-form-control"
                    name="stockedDate"
                    disabled={true}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mt-5">
                  <label className="fs-5 fw-bold mb-2">Total Amount</label>
                  <Input
                    type="text"
                    value={`${getBatchAmount(batch)} ${formatArticleUnit(batch.unit)}`}
                    className="form-control custom-form-control"
                    disabled={true}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mt-5">
                  <label className="fs-5 fw-bold mb-2">Price (net)</label>
                  <Input
                    type="text"
                    value={`${formatCurrency(batch.price, batch.priceCurrency)} / ${batch.unit}`}
                    className="form-control custom-form-control"
                    disabled={true}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mt-5">
                  <label className="fs-5 fw-bold mb-2">Best Before</label>
                  <DateInput
                    value={batch.expiry}
                    classes="form-control custom-form-control"
                    name="expiry"
                    disabled={true}
                  />
                </div>
                <div className="col-12 col-sm-6 col-xl-4 mt-5">
                  <label className="fs-5 fw-bold mb-2">CoA</label>
                  <div>
                    {batch.supplierCoA && (
                      <a href={resolveFilePath(batch.supplierCoA.path)} target="_blank" rel="noopener noreferrer">
                        <span className="text-white fw-bold">{shortenAlias(batch.supplierCoA.path)}</span>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-outline btn-text-danger" onClick={this.handleHide}>
              Cancel
            </button>
            <ErrorOverlayButton
              errors={errors}
              saving={saving}
              className="btn btn-sm btn-outline btn-outline-light"
              buttonText="Link Batch"
              onClick={this.handleLinkBatch}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default LinkExistingBatchModal;
