import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { getContractNumber } from "../../../../utils/customerContractUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import { CustomerContractExtended } from "../../../../model/customerContract.types";
import { Input } from "../../../common/Input";
import { CUSTOMERCONTRACT, transaction, UpdateAction } from "../../../../services/dbService";
import { formatArticleUnit } from "../../../../utils/productArticleUtils";

interface CustomerContractAdjustCallQuantityModalProps {
  contract: CustomerContractExtended;
}

const CustomerContractAdjustCallQuantityModal: React.FC<CustomerContractAdjustCallQuantityModalProps> = ({
  contract,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [minimumCallQuantity, setMinimumCallQuantity] = useState<number>(
    contract.contractInformation.minimumCallQuantity
  );

  useEffect(() => {
    if (show) setMinimumCallQuantity(contract.contractInformation.minimumCallQuantity);
  }, [show]);

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);

  const handleChangeCallQuantity = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMinimumCallQuantity(Number(e.target.value));
  }, []);

  const handleSaveCallOffQuantity = useCallback(async () => {
    try {
      setSaving(true);
      const action: UpdateAction = {
        collection: CUSTOMERCONTRACT,
        filter: { _id: contract._id },
        update: { "contractInformation.minimumCallQuantity": minimumCallQuantity },
      };
      const res = await transaction(action);
      if (res) {
        toast.success("Successfully updated minimum call-off quantity");
        handleHide();
      } else {
        toast.error("Error updating minimum call-off quantity");
      }
    } catch (e) {
      console.error(e);
      toast.error("Error updating minimum call-off quantity");
    } finally {
      setSaving(false);
    }
  }, [contract._id, minimumCallQuantity]);

  const unit = useMemo(() => contract.commodity.unit, [contract.commodity.unit]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (minimumCallQuantity < 0) errors.push("Minimum Call-Off Quantity can't be negative");
    if (minimumCallQuantity > contract.contractInformation.restAmount)
      errors.push("Minimum Call-Off Quantity can't exceed remaining Amount");
    return errors;
  }, [minimumCallQuantity, contract.contractInformation.restAmount]);

  return (
    <>
      <button className="btn btn-icon btn-sm p-0  h-auto w-auto align-middle mb-1 ml-1" onClick={handleShow}>
        <i className="fa fa-edit text-gray-300 text-hover-white p-0" />
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} centered size="lg">
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">
              Adjust Minimum Call-Off Quantity of {getContractNumber(contract)}
            </h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="text-white fs-5 mt-10 mb-5">
            <div className="row mb-2">
              <span className="col-4 col-form-label fs-6 fw-bold">Minimum Call-Off Quantity</span>
              <div className="col-8">
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type="number"
                    integerOnly={true}
                    value={minimumCallQuantity}
                    onChange={handleChangeCallQuantity}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text form-control custom-form-control">
                      {formatArticleUnit(unit, contract.commodity)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-text-white btn-sm" onClick={handleHide}>
            Close
          </button>
          <ErrorOverlayButton
            errors={errors}
            className="btn btn-sm btn-outline btn-outline-light"
            buttonText="Confirm"
            saving={saving}
            onClick={handleSaveCallOffQuantity}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerContractAdjustCallQuantityModal;
