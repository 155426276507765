import _ from "lodash";
import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { DataContextInternalType } from "../../../context/dataContext";
import Search from "../../common/Search";
import OrderFilter from "./OrderFilter";
import {
  getCustomerOrdersWithoutSupplierOrder,
  getOrdersForTab,
  groupOpenOrders,
  INTERNAL_ORDER_TYPES,
  isCustomerOrder,
  isSupplierOrder,
  O_SORTOPTIONS,
  O_TAB_ORDERREQUIRED,
} from "../../../utils/orderUtils";
import BaseListing from "../../common/BaseListing";
import { SortOption, SORTORDEROPTIONS } from "../../../utils/filterUtils";
import { paginate, PaginationState } from "../../common/Pagination";
import OrderRow from "./OrderRow";
import { doFuseSearch, getComponentState } from "../../../utils/baseUtils";
import OpenOrderRow from "./OpenOrderRow";
import { SelectOption } from "../../common/CustomSelect";
import { T_WAREHOUSE } from "../../../model/customerOrder.types";

interface OrderListingProps {
  context: DataContextInternalType;
}

interface OrdersListingState extends PaginationState {
  orderFilter: SelectOption;
  sortBy: SelectOption;
  sortOrder: SortOption;
  filterBy?: SelectOption;
  search: string;
  activeTab: string;
}

const COMPONENT_NAME = "OrdersListing";

class OrdersListing extends PureComponent<OrderListingProps, OrdersListingState> {
  constructor(props: OrderListingProps) {
    super(props);
    const storedOrderChoice = localStorage.getItem("ordersShown");
    this.state = {
      orderFilter:
        storedOrderChoice === "customer"
          ? { value: "customer", label: "Customer Orders" }
          : { value: "supplier", label: "Supplier Orders" },
      activeTab: "All",
      currentPage: 1,
      pageSize: 25,
      sortBy: O_SORTOPTIONS[0],
      sortOrder: SORTORDEROPTIONS[0],
      search: "",
    };
  }

  componentDidMount() {
    const state = getComponentState(this.props.context, COMPONENT_NAME);
    if (state) this.setState({ ...state });
  }

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, this.state);
  }

  handleChangeOrderFilter = (orderFilter: SelectOption) => {
    this.setState({ orderFilter, currentPage: 1, activeTab: "All" });
    localStorage.setItem("ordersShown", orderFilter.value);
  };
  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    // Special handling for sortBy when searching
    if (!val.trim())
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortBy: O_SORTOPTIONS[0],
      });
    else
      this.setState({
        search: e.target.value,
        currentPage: 1,
        sortBy: { value: "", label: "Best Match" },
        sortOrder: SORTORDEROPTIONS[0],
      });
  };
  handleChangeSortBy = (sortBy: SelectOption) => this.setState({ sortBy, currentPage: 1 });
  handleChangeSortOrder = (sortOrder: SortOption) => this.setState({ sortOrder, currentPage: 1 });
  handleChangeFilterBy = (filterBy?: SelectOption) => this.setState({ filterBy, currentPage: 1 });
  handleChangeActiveTab = (activeTab: string) => this.setState({ activeTab, currentPage: 1 });
  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });

  /**
   * Retrieve the correct orders for the current view.
   * @returns { Array<INTERNAL_ORDER_TYPES> } List of orders
   */
  getOrders = (): Array<INTERNAL_ORDER_TYPES> => {
    const { context } = this.props;
    const { orderFilter } = this.state;
    let orders: Array<INTERNAL_ORDER_TYPES>;
    if (orderFilter.value === "customer") orders = context.customerOrder;
    else if (orderFilter.value === "supplier")
      orders = (context.supplierOrder as Array<INTERNAL_ORDER_TYPES>).concat(
        context.customerOrder.filter((cO) => cO.transport === T_WAREHOUSE)
      );
    else orders = context.sampleOrder;
    return orders;
  };

  /**
   * Filter and sort the orders.
   * @returns { Array<INTERNAL_ORDER_TYPES> } Filtered and sorted orders
   */
  getFilteredOrders = (orders: Array<INTERNAL_ORDER_TYPES>): Array<INTERNAL_ORDER_TYPES> => {
    let ordersFiltered = orders;
    const { filterBy, search, sortBy, sortOrder } = this.state;
    if (filterBy) {
      switch (filterBy.value) {
        case "singleCustomer":
          ordersFiltered = ordersFiltered.filter(
            (o) => (isSupplierOrder(o) && o.customerOrders.length === 1) || isCustomerOrder(o)
          );
          break;
        case "multipleCustomers":
          ordersFiltered = ordersFiltered.filter((o) => isSupplierOrder(o) && o.customerOrders.length > 1);
          break;
        case "volumeLow":
          ordersFiltered = ordersFiltered.filter((o) => o.amount < 10000);
          break;
        case "volumeHigh":
          ordersFiltered = ordersFiltered.filter((o) => o.amount >= 10000);
          break;
        case "inTime":
          ordersFiltered = ordersFiltered.filter(
            (o) =>
              o.targetDate.getTime() < new Date().getTime() &&
              (!o.deliveryDate || o.deliveryDate.getTime() <= o.targetDate.getTime())
          );
          break;
        case "overdue":
          ordersFiltered = ordersFiltered.filter(
            (o) =>
              o.targetDate.getTime() > new Date().getTime() ||
              !o.deliveryDate ||
              o.deliveryDate.getTime() > o.targetDate.getTime()
          );
          break;
        case "etdNotConfirmed":
          ordersFiltered = ordersFiltered.filter(
            (o) =>
              isSupplierOrder(o) && ((o.shipment.length > 0 && !o.shipment[0].readyToShip) || o.shipment.length === 0)
          );
          break;
      }
    }

    if (search.trim()) {
      ordersFiltered = doFuseSearch(ordersFiltered, search, [
        "orderNo",
        "commodity.title.en",
        "customerReference",
        "state",
        "supplier.name",
        "company.name",
      ]);
    }

    // Handle sorting for search
    if (!sortBy.value && search.trim()) {
      if (sortOrder.value === "desc") return ordersFiltered.reverse();
      return ordersFiltered;
    }

    // If ETD is selected for sorting, remove orders without etd
    if (sortBy.value === "etd") ordersFiltered = ordersFiltered.filter((o) => isSupplierOrder(o) && o.etd);

    ordersFiltered = _.orderBy(ordersFiltered, sortBy.value, sortOrder.value);
    return ordersFiltered;
  };

  render() {
    const { context } = this.props;
    const { activeTab, orderFilter, currentPage, filterBy, pageSize, sortBy, sortOrder, search } = this.state;
    const filteredOrders = this.getFilteredOrders(this.getOrders());
    const tabOrders = getOrdersForTab(filteredOrders, orderFilter.value, activeTab, context);
    const paginatedOrders = paginate(tabOrders, currentPage, pageSize);
    // For the "Create Order" view of the supplier orders listing we need customer orders. Thus, we need to handle this
    // case a bit differently
    let groupedOpenOrders;
    if (activeTab === O_TAB_ORDERREQUIRED && orderFilter.value === "supplier") {
      groupedOpenOrders = groupOpenOrders(this.getFilteredOrders(getCustomerOrdersWithoutSupplierOrder(context)));
    }

    const headerDefinition = [
      { title: "Order", style: { width: "30%" } },
      {
        title:
          orderFilter.value === "supplier"
            ? "Customer Orders"
            : orderFilter.value === "customer"
            ? "Supplier Orders"
            : "References",
        style: { width: "12%", whiteSpace: "nowrap" },
      },
      { title: "Status", style: { width: "20%" } },
      { title: "Progress", style: { width: "13%" } },
      { title: "Type", style: { width: "12%" } },
      { title: "Schedule", style: { width: "13%" } },
    ];

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white" style={{ minHeight: "100%" }}>
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Orders</span>
                  <div className="float-right">
                    <Link className="btn btn-outline btn-outline-light" to="/createCustomerOrder">
                      New Order
                    </Link>
                  </div>
                </h3>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <Search placeholder="Search for orders..." onSearch={this.handleChangeSearch} value={search} />
                  </div>
                  <div className="col-12 col-md-6 text-right">
                    <Link className="btn btn-sm btn-light" to="/canceledOrders">
                      Canceled Orders
                    </Link>
                  </div>
                </div>
                <OrderFilter
                  search={search}
                  context={context}
                  type={orderFilter}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  filterBy={filterBy}
                  activeTab={activeTab}
                  onChangeSortBy={this.handleChangeSortBy}
                  onChangeSortOrder={this.handleChangeSortOrder}
                  onChangeFilterBy={this.handleChangeFilterBy}
                  onChangeOrderType={this.handleChangeOrderFilter}
                  onChangeActiveTab={this.handleChangeActiveTab}
                  filteredOrders={filteredOrders}
                />
                {!groupedOpenOrders || groupedOpenOrders.length === 0 ? (
                  <BaseListing
                    headerDefinition={headerDefinition}
                    bodyContent={
                      <>
                        {paginatedOrders.length > 0 ? (
                          paginatedOrders.map((pO) => <OrderRow key={pO._id.toString()} order={pO} />)
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              No orders found
                            </td>
                          </tr>
                        )}
                      </>
                    }
                    documents={tabOrders}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    baseSize={25}
                    onPageChange={this.handleChangeCurrentPage}
                    onPageSizeChange={this.handleChangePageSize}
                  />
                ) : (
                  <BaseListing
                    headerDefinition={headerDefinition}
                    bodyContent={
                      <>
                        {groupedOpenOrders.length > 0 ? (
                          groupedOpenOrders.map((pO) => <OpenOrderRowRouter key={pO[0]._id.toString()} orders={pO} />)
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={8}>
                              No orders found
                            </td>
                          </tr>
                        )}
                      </>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const OpenOrderRowRouter = withRouter(OpenOrderRow);

export default OrdersListing;
