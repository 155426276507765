import React from "react";
import { toast } from "react-toastify";
import { UserData } from "../../model/userData.types";
import { ADMIN, getUserName, INTERNAL, SCM } from "../../utils/userUtils";
import SimpleConfirmationModal from "./SimpleConfirmationModal";
import AccessAdjustmentModal from "./internal/modals/AccessAdjustmentModal";
import userService from "../../services/userService";
import Tooltip from "./Tooltip";
import { Action, COMPANY, FORWARDER, SUPPLIER, transaction, USERDATA } from "../../services/dbService";
import { Company, CompanyExtended } from "../../model/company.types";
import { Supplier, SupplierExtended } from "../../model/supplier.types";
import { SupplierSupplier, SupplierSupplierExtended } from "../../model/supplier/supplierSupplier.types";
import { Forwarder, ForwarderExtended } from "../../model/forwarder.types";
import EditContactModal from "./internal/modals/EditContactModal";
import { DataWarningType, ReferenceType, sendInconsistentDataWarning } from "../../utils/dataIssueUtils";

interface PersonSummaryWidgetProps {
  description: string;
  person: UserData;
  company:
    | Company
    | CompanyExtended
    | Supplier
    | SupplierExtended
    | SupplierSupplier
    | SupplierSupplierExtended
    | Forwarder
    | ForwarderExtended;
  type: "company" | "supplier" | "forwarder";
  isRemovable?: true;
  isAsPrimaryAssignable?: true;
}

const COMPONENT_NAME = "PersonSummaryWidget";

const PersonSummaryWidget: React.FunctionComponent<PersonSummaryWidgetProps> = ({
  description,
  person,
  company,
  type,
  isRemovable,
  isAsPrimaryAssignable,
}) => {
  // Failsafe in case of faulty persons in companies
  if (!person) {
    // Log inconsistencies
    sendInconsistentDataWarning(DataWarningType.MISSING_USERDATA, COMPONENT_NAME, {
      id: company._id.toString(),
      type:
        type === "company"
          ? ReferenceType.COMPANY
          : type === "supplier"
          ? ReferenceType.SUPPLIER
          : ReferenceType.FORWARDER,
    });
    return null;
  }

  const gotAccess = !!person.userId;
  const isInternal = userService.getUserType() === INTERNAL;
  const roles = userService.getRoles();
  const isSCMOrAdmin = roles.includes(ADMIN) || roles.includes(SCM);

  const handleRemoveContact = async () => {
    const collection = type === "supplier" ? SUPPLIER : type === "company" ? COMPANY : FORWARDER;
    const actions: Array<Action> = [
      {
        collection: collection,
        filter: { _id: company._id },
        pull: { persons: person._id.toString() },
      },
      {
        collection: USERDATA,
        filter: { _id: person._id },
        update: { userId: "", company: "" },
      },
    ];
    const result = await transaction(actions);
    if (result) toast.success("Contact successfully removed");
    else toast.error("Contact could not be removed. Please try again later");
  };

  const handleAssignAsPrimary = async () => {
    const collection = type === "supplier" ? SUPPLIER : type === "company" ? COMPANY : FORWARDER;
    const primaryPersonId =
      typeof company.primaryPerson === "string" ? company.primaryPerson : company.primaryPerson._id.toString();
    const newPrimaryPersonId = person._id.toString();
    if (!newPrimaryPersonId || !primaryPersonId) return;
    const actions: Array<Action> = [
      {
        collection: collection,
        filter: { _id: company._id },
        push: { persons: primaryPersonId },
        update: { primaryPerson: newPrimaryPersonId },
      },
      {
        // push/pull needs to be separated for conflict free mongoDB resolutions
        collection: collection,
        filter: { _id: company._id },
        pull: { persons: newPrimaryPersonId },
      },
    ];
    const result = await transaction(actions);
    if (result) toast.success("Contact information successfully updated");
    else toast.error("Contact information could not be updated. Please try again later");
  };

  return (
    <div className="pb-5 fs-6">
      <div className="card card-body bg-light mt-5 no-hover">
        <div className="fw-bolder">
          {description}
          {isInternal && isRemovable && !gotAccess && (
            <SimpleConfirmationModal.SimpleConfirmationModalButton
              size="md"
              modalTitle="Remove Contact"
              onConfirm={handleRemoveContact}
              confirmButtonText="Confirm"
              buttonText={<i className="fa fa-trash fs-7 text-muted text-hover-danger p-0" />}
              buttonClasses="btn btn-text btn-sm px-2 py-0 float-right"
              cancelButtonText="Close"
              modalDescription={
                <span className="text-white">
                  <div>Do you really want to remove</div>
                  <div className=" card card-body bg-light no-hover my-2">
                    <div className="text-white">{getUserName(person)}</div>
                    <div className="text-muted">{person.emails[0] ? person.emails[0].value : "-"}</div>
                    <div className="text-muted">{person.phones[0] ? person.phones[0].value : "-"}</div>
                  </div>
                  <div>as a contact?</div>
                </span>
              }
            />
          )}
          {isInternal && isAsPrimaryAssignable && (
            <SimpleConfirmationModal.SimpleConfirmationModalButton
              size="md"
              modalTitle="Assign as Primary"
              onConfirm={handleAssignAsPrimary}
              confirmButtonText="Confirm"
              buttonText={
                <Tooltip
                  delay={{ show: 500, hide: 0 }}
                  tooltipText={<span className="text-white p-5">Assign person as primary contact</span>}
                >
                  <div>
                    <i className="fa fa-arrow-alt-circle-up fs-7 text-muted text-hover-light p-0" />
                  </div>
                </Tooltip>
              }
              buttonClasses="btn btn-text btn-sm px-2 py-0 float-right"
              cancelButtonText="Close"
              modalDescription={
                <span className="text-white">
                  <div>Are you certain you wish to designate the individual below as the primary contact?</div>
                  <div className=" card card-body bg-light no-hover my-2">
                    <div className="text-white">{getUserName(person)}</div>
                    <div className="text-muted">{person.position}</div>
                    <div className="text-muted">{person.emails[0] ? person.emails[0].value : "-"}</div>
                    <div className="text-muted">{person.phones[0] ? person.phones[0].value : "-"}</div>
                  </div>
                  <div>Attention, this action converts the former primary contact into an ordinary contact.</div>
                </span>
              }
            />
          )}
        </div>
        <div className="text-white">{getUserName(person)}</div>
        <div className="text-muted">{person.position}</div>
        <div className="text-muted">{person.emails[0] ? person.emails[0].value : "-"}</div>
        <div className="text-muted">{person.phones[0] ? person.phones[0].value : "-"}</div>
        {isInternal && person.type !== "forwarder" && (
          <div className={"row " + (gotAccess ? "text-success" : "text-warning")}>
            <div className="col align-self-center">
              {gotAccess ? "Has" : "No"} Access {gotAccess && !person.onboardingDone ? "(Step 1/2)" : ""}
            </div>
            <div className="col-auto align-self-center">
              <AccessAdjustmentModal user={person} />
            </div>
          </div>
        )}
        {isInternal && isSCMOrAdmin && person.type === "forwarder" && (
          <div className="row">
            <div className="col">
              <EditContactModal contact={person} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonSummaryWidget;
