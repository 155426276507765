import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import CommodityOrdersWidget from "./CommodityOrdersWidget";
import CommodityPageDocuments from "./CommodityPageDocuments";
import CommodityPageTabPanel from "./CommodityPageTabPanel";
import CommodityPageSummary from "../customer/CommodityPageSummary";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { CustomerCommodity, CustomerCommodityExtended } from "../../../model/customer/customerCommodity.types";
import { COMMODITY, getDocumentDB } from "../../../services/dbService";
import CommodityPageSamplesWidget from "./CommoditySamplesWidget";
import CommodityPageSummaryAnonymous from "../anonymous/CommodityPageSummaryAnonymous";
import { hasPricesInAnonymousView } from "../../../utils/anonymousViewUtils";
import { CUSTOMER } from "../../../utils/userUtils";
import { isCustomerCommodity } from "../../../utils/commodityUtils";
import { CustomerArticleExtended } from "../../../utils/productArticleUtils";
import { extendCustomerCommodity, extendCustomerFinishedProduct } from "../../../utils/dataTransformationUtils";

interface CommodityPageParams {
  id: string;
}

interface CommodityPageProps extends RouteComponentProps<CommodityPageParams> {
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CommodityPageState {
  article?: CustomerArticleExtended;
}

class CommodityPage extends PureComponent<CommodityPageProps, CommodityPageState> {
  _isMounted = false;
  constructor(props: CommodityPageProps) {
    super(props);
    this.state = { article: this.getCommodity(props) };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.article) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/articles");
      return;
    }
    this._isMounted = true;
    const commodity: CustomerCommodity | undefined = await getDocumentDB(COMMODITY, id);
    if (!commodity) {
      console.error("No commodity could be loaded for id", id);
      toast.error("The requested commodity could not be loaded");
      history.push("/articles");
      return;
    }
    context.addDocuments(COMMODITY, [commodity]);
    const commodityExtended = extendCustomerCommodity(commodity, context) as CustomerCommodityExtended | undefined;
    if (this._isMounted) this.setState({ article: commodityExtended });
  };

  componentDidUpdate(prevProps: Readonly<CommodityPageProps>) {
    const { match } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const commodity = this.getCommodity(this.props);
      if (!_.isEqual(commodity, this.state.article)) {
        this.setState({
          article: commodity,
        });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Get the initial commodity data
   * @param props Commodity properties
   * @returns {CustomerArticleExtended | undefined} a commodity object or undefined
   */
  getCommodity = (props: CommodityPageProps): CustomerArticleExtended | undefined => {
    const commodity = getDocFromCollection(props.context.commodity, props.match.params.id);
    if (commodity) {
      return extendCustomerCommodity(commodity, props.context);
    }
    const finishedProduct = getDocFromCollection(props.context.finishedProduct, props.match.params.id);
    if (finishedProduct) {
      return extendCustomerFinishedProduct(finishedProduct, props.context);
    }
  };

  render() {
    const { history, context } = this.props;
    const { article } = this.state;
    if (!article) {
      history.push("/articles");
    } else {
      return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div className="post d-flex flex-column-fluid" id="kt_post">
            <div id="kt_content_container" className="container-xxl responsive-aside-container">
              <div className="d-flex flex-column flex-xl-row">
                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10 order-1 order-xl-0">
                  {isAnonymousContext(context) &&
                  !hasPricesInAnonymousView(article._id.toString(), context.configuration) ? (
                    <CommodityPageSummaryAnonymous article={article} context={context} />
                  ) : (
                    <CommodityPageSummary article={article} context={context} />
                  )}
                  <CommodityPageDocuments article={article} isAnonymousView={isAnonymousContext(context)} />
                  <CommodityOrdersWidget article={article} context={context} />
                  {isCustomerCommodity(article, CUSTOMER) && (
                    <CommodityPageSamplesWidget commodity={article} context={context} />
                  )}
                </div>
                <CommodityPageTabPanel article={article} context={context} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CommodityPage;
