import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import { PropertyType, resolveProperties } from "../../../utils/propertyUtils";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { Property } from "../../../model/property.types";
import { hasPricesInAnonymousView } from "../../../utils/anonymousViewUtils";
import Tooltip from "../../common/Tooltip";
import FavoriteStar from "../../common/FavoriteStar";
import CountryWidget from "../../common/CountryWidget";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../../../model/customer/customerFinishedProduct.types";

interface CustomerFinishedProductCardElementProps extends RouteComponentProps {
  finishedProduct: FinishedProduct | CustomerFinishedProduct;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const CustomerFinishedProductCardElement: React.FunctionComponent<CustomerFinishedProductCardElementProps> = ({
  finishedProduct,
  context,
  history,
}) => {
  const properties = resolveProperties(finishedProduct.properties, context.property);
  const category = getArticleProperty(properties, PropertyType.CATEGORY) as Property | null;
  const composition = getArticleProperty(properties, PropertyType.COMPOSITION) as Property | null;
  const forwardFinishedProduct = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(`finishedProduct/${finishedProduct._id.toString()}`);
  };
  const isAC = isAnonymousContext(context);
  const blurPrice = isAC && !hasPricesInAnonymousView(finishedProduct._id.toString(), context.configuration);

  return (
    <>
      <div className="col-12 text-left mb-2">
        {!isAC && (
          <Tooltip tooltipText="Mark as Favorite" delay={{ show: 500, hide: 0 }}>
            <span>
              <FavoriteStar favorite={finishedProduct._id.toString()} context={context} />
            </span>
          </Tooltip>
        )}
      </div>
      <div className="col-12 text-left mb-2">
        <span className="text-white fs-5 d-inline-block" onClick={forwardFinishedProduct}>
          {finishedProduct.title.en}
        </span>
        {finishedProduct.organic && <i title="organic" className="fas fa-leaf text-success d-inline-block ml-2" />}
        <span className="text-muted d-block" style={{ fontSize: "0.85rem" }}>
          {finishedProduct.subtitle.en}
        </span>
      </div>
      <div className="col-4 text-muted">Category</div>
      <div className="col-8 text-right mb-2">
        {category && <span className="badge badge-gray fw-bolder px-2 py-2 text-white">{category.name.en}</span>}
      </div>
      <div className="col-6 text-muted">Composition</div>
      <div className="col-6 text-right mb-2">{composition?.name.en ?? "Not specified"}</div>
      <div className="col-4 text-muted">Origin</div>
      <div className="col-8 text-right mb-2">
        <CountryWidget countryCode={finishedProduct.country.code} />
      </div>
      <div className="col-4 text-muted mb-2">Price</div>
      <div className="col-8 text-right">
        <span className="text-muted d-block text-nowrap" onClick={blurPrice ? (e) => e.stopPropagation() : undefined}>
          Price on request
        </span>
      </div>
    </>
  );
};

export default withRouter(CustomerFinishedProductCardElement);
