import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import ReactStarsWrapper from "../../../common/ReactStarsWrapper";
import { Textarea } from "../../../common/Textarea";
import { CustomerSampleOrderExtended } from "../../../../model/customer/customerSampleOrder.types";
import { getSampleOrderTimelineEntry, updateSampleOrder } from "../../../../utils/sampleOrderUtils";
import { SAMO_STATE, SAMO_T_RATED } from "../../../../model/sampleOrder.types";
import { formatDate } from "../../../../utils/baseUtils";

interface SampleRatingProps {
  order: CustomerSampleOrderExtended;
}

interface SampleRatingState {
  ratingText: string;
  rating: number;
  saving: boolean;
}

class SampleRating extends PureComponent<SampleRatingProps, SampleRatingState> {
  constructor(props: SampleRatingProps) {
    super(props);
    const { order } = this.props;
    this.state = {
      ratingText: order.sampleRating?.ratingText ?? "",
      rating: order.sampleRating?.rating ?? 5,
      saving: false,
    };
  }

  handleChangeRating = (rating: number) => this.setState({ rating });
  handleChangeRatingText = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ ratingText: e.target.value });

  handleSaveRating = async () => {
    const { order } = this.props;
    const { ratingText, rating } = this.state;
    this.setState({ saving: true });
    try {
      const timelineEntry = getSampleOrderTimelineEntry(SAMO_T_RATED);
      const res = await updateSampleOrder(
        { sampleRating: { ratingText, rating, ratingDate: new Date() } },
        order._id,
        timelineEntry
      );
      if (res && res.res.modifiedCount > 0) {
        toast.success("Sample rating added successfully");
      } else {
        toast.error("Error adding sample rating");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { order } = this.props;
    const { ratingText } = this.state;
    const errors = [];
    if (ratingText.trim().length < 3) errors.push("Please enter a feedback text");
    if (order.state === SAMO_STATE.CANCELED) errors.push("Canceled sample orders can't be rated");
    if (!([SAMO_STATE.SHIPPED, SAMO_STATE.ARCHIVED] as Array<SAMO_STATE>).includes(order.state))
      errors.push("Can't rate order before it was shipped to you");
    return errors;
  };

  render() {
    const { order } = this.props;
    const { ratingText, rating, saving } = this.state;
    const errors = this.validateData();

    return (
      <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
        <div className="card-body">
          <span className="fs-2 text-gray-800 fw-bolder d-block mb-8">Sample Rating</span>
          <Textarea
            className="form-control custom-form-control mb-2"
            rows={5}
            placeholder="Note down everything you've observed and wish to remember for future reference."
            value={ratingText}
            disabled={Boolean(order.sampleRating)}
            onChange={order.sampleRating ? undefined : this.handleChangeRatingText}
          />
          <div className="d-flex align-items-center justify-content-between" style={{ minHeight: "24px" }}>
            <span className="text-muted">Overall Rating:</span>
            <ReactStarsWrapper
              value={rating}
              count={5}
              size={16}
              edit={!order.sampleRating}
              onChange={order.sampleRating ? undefined : this.handleChangeRating}
            />
          </div>
          {order.sampleRating ? (
            <div className="text-muted text-center mt-5">
              Feedback provided on: {formatDate(order.sampleRating.ratingDate)}
            </div>
          ) : (
            <>
              <ErrorOverlayButton
                errors={errors}
                className="btn btn-light mt-5 w-100"
                buttonText="Save"
                onClick={this.handleSaveRating}
                saving={saving}
              />
              <span className="text-muted d-block mt-2">
                <small>
                  The feedback you provide in your sample order will also be visible for you on the commodity page.
                </small>
              </span>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SampleRating;
