import React, { PureComponent } from "react";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import Search from "../../common/Search";
import { S_COMMODITYOPTIONS, S_GENERALFILTEROPTIONS, S_SORTOPTIONS } from "../../../utils/supplierUtils";
import { SORTORDEROPTIONS } from "../../../utils/filterUtils";

interface SupplierListingFilterProps {
  search: string;
  generalFilter: SelectOption | undefined;
  sortBy: SelectOption;
  sortOrder: SelectOption;
  offeringStatus: SelectOption | undefined;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onGeneralFilterChange: (generalFilter: SelectOption | undefined) => void;
  onSortByChange: (sortBy: SelectOption) => void;
  onSortOrderChange: (sortOrder: SelectOption) => void;
  onOfferingStatusChange: (offeringStatus: SelectOption) => void;
}

interface SupplierListingFilterState {}

class SupplierListingFilter extends PureComponent<SupplierListingFilterProps, SupplierListingFilterState> {
  render() {
    const {
      search,
      generalFilter,
      sortBy,
      sortOrder,
      offeringStatus,
      onSearch,
      onGeneralFilterChange,
      onSortByChange,
      onSortOrderChange,
      onOfferingStatusChange,
    } = this.props;
    const sortOptions = S_SORTOPTIONS.slice();
    if (search.trim()) sortOptions.unshift({ value: "", label: "Best Match" });
    return (
      <div className="card mb-10 bg-white border-none">
        <div className="row g-8 mb-4">
          <div className="col-12 col-lg-6">
            <Search value={search} onSearch={onSearch} placeholder={"Search for suppliers..."} />
          </div>
        </div>
        <div className="row g-8">
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">General Filter</label>
            <CustomSelect
              options={S_GENERALFILTEROPTIONS}
              value={generalFilter}
              isClearable={true}
              placeholder={"All Suppliers"}
              onChange={onGeneralFilterChange}
            />
          </div>
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Sort By</label>
            <CustomSelect options={sortOptions} value={sortBy} onChange={onSortByChange} />
          </div>
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Sort Order</label>
            <CustomSelect options={SORTORDEROPTIONS} value={sortOrder} onChange={onSortOrderChange} />
          </div>
          <div className="col-lg-3">
            <label className="fs-6 form-label fw-bolder text-dark">Commodity Offering</label>
            <CustomSelect
              options={S_COMMODITYOPTIONS}
              value={offeringStatus}
              isClearable={true}
              placeholder="All"
              onChange={(e: SelectOption) => onOfferingStatusChange(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SupplierListingFilter;
