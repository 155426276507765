import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CALC_AIRFREIGHT, CALC_SEAFREIGHT } from "../../../../model/supplierOrder.types";
import {
  CalculationConfiguration,
  ContainerData,
  SingleContainerData,
} from "../../../../model/configuration/calculationConfiguration.types";
import { CONFIG, getConfiguration } from "../../../../utils/configurationUtils";
import SimpleSplashScreen from "../../../common/SimpleSplashScreen";
import GeneralValuesConfiguration from "./GeneralValuesConfiguration";
import SeaFreightValuesConfiguration from "./SeaFreightValuesConfiguration";
import AirFreightValuesConfiguration from "./AirFreightValuesConfiguration";
import { P_CALC_WAREHOUSE, recalculatePaletteOnChange } from "../../../../utils/priceCalculationUtils";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import userService from "../../../../services/userService";
import { Action, CONFIGURATION, receiveStreamIterator, transaction } from "../../../../services/dbService";
import WarehouseValuesConfiguration from "./WarehouseValuesConfiguration";
import { REQUIRED_ROLES_CALCULATION_CONFIGURATION } from "../../../../utils/userUtils";

const GENERAL = "generalValues";

interface CalculationValuesConfigurationProps {}

interface CalculationValuesConfigurationState {
  tab: typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT | typeof GENERAL | typeof P_CALC_WAREHOUSE;
  loading: boolean;
  saving: boolean;
  updatedCalculation: boolean;
  configuration?: CalculationConfiguration;
}

class CalculationValuesConfiguration extends PureComponent<
  CalculationValuesConfigurationProps,
  CalculationValuesConfigurationState
> {
  _isMounted = false;
  _changeStream: AsyncGenerator<Realm.Services.MongoDB.ChangeEvent<CalculationConfiguration>> | undefined;
  _originConfig: CalculationConfiguration | undefined;
  constructor(props: CalculationValuesConfigurationProps) {
    super(props);
    this.state = {
      tab: GENERAL,
      loading: true,
      saving: false,
      updatedCalculation: false,
    };
  }

  componentDidMount = async () => {
    if (!userService.getRoles().some((r) => REQUIRED_ROLES_CALCULATION_CONFIGURATION.includes(r))) return;
    this._isMounted = true;
    try {
      const config = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
      if (config && this._isMounted) {
        this._originConfig = _.cloneDeep(config);
        this.setState({ configuration: config });
        this._changeStream = receiveStreamIterator(CONFIGURATION, {
          key: config.key,
        });
        this.updateConfiguration();
      }
    } catch (e) {
      toast.error("Configuration could not be loaded");
      console.error("Configuration could not be loaded", e);
    } finally {
      this.setState({ loading: false });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleReload = async () => {
    if (!userService.getRoles().some((r) => REQUIRED_ROLES_CALCULATION_CONFIGURATION.includes(r))) return;
    this._isMounted = true;
    try {
      const config = await getConfiguration<CalculationConfiguration>(CONFIG.CALCULATION);
      if (config && this._isMounted) {
        this._originConfig = _.cloneDeep(config);
        this.setState({ configuration: config, updatedCalculation: false });
      }
    } catch (e) {
      toast.error("Configuration could not be loaded");
      console.error("Configuration could not be loaded", e);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeTab = (
    tab: typeof CALC_AIRFREIGHT | typeof CALC_SEAFREIGHT | typeof GENERAL | typeof P_CALC_WAREHOUSE
  ) => {
    this.setState({ tab });
  };

  handleChangeGeneralValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.configuration) return;
    const configuration = _.cloneDeep(this.state.configuration);
    const path = e.target.name;
    _.set(
      configuration,
      `values.generalValues.${path}`,
      e.target.type === "number"
        ? path === "customsFeeAgency"
          ? +e.target.value / 100
          : +e.target.value
        : e.target.value
    );
    this.setState({ configuration });
  };

  handleChangeSeaFreightValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.configuration) return;
    const configuration = _.cloneDeep(this.state.configuration);
    const path = e.target.name;
    _.set(
      configuration,
      `values.seaFreightValues.${path}`,
      e.target.type === "number"
        ? path === "customsAirFreightCoefficient"
          ? +e.target.value / 100
          : +e.target.value
        : e.target.value
    );
    this.setState({ configuration });
  };

  handleChangeAirFreightValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.configuration) return;
    const configuration = _.cloneDeep(this.state.configuration);
    const path = e.target.name;
    _.set(
      configuration,
      `values.airFreightValues.${path}`,
      e.target.type === "number" ? +e.target.value : e.target.value
    );
    this.setState({ configuration });
  };

  handleChangeWarehouseValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.configuration) return;
    const configuration = _.cloneDeep(this.state.configuration);
    const path = e.target.name;
    _.set(
      configuration,
      `values.warehouseValues.${path}`,
      e.target.type === "number" ? +e.target.value : e.target.value
    );
    this.setState({ configuration });
  };

  handleChangePaletteData = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.configuration) return;
    const configuration = _.cloneDeep(this.state.configuration);
    recalculatePaletteOnChange(configuration.values.generalValues.defaultPalette, e);
    this.setState({ configuration });
  };

  handleToggleStackable = () => {
    if (!this.state.configuration) return;
    const configuration = _.cloneDeep(this.state.configuration);
    configuration.values.generalValues.defaultPalette.stackable =
      !configuration.values.generalValues.defaultPalette.stackable;
    this.setState({ configuration });
  };

  handleChangeContainerData = (e: React.ChangeEvent<HTMLInputElement>, container: keyof ContainerData) => {
    if (!this.state.configuration) return;
    const configuration = _.cloneDeep(this.state.configuration);
    const name = e.target.name as keyof SingleContainerData;
    configuration.values.generalValues.containerData[container][name] = +e.target.value;
    this.setState({ configuration });
  };

  handleReset = () => {
    if (this._originConfig) this.setState({ configuration: this._originConfig });
  };

  handleSave = async () => {
    const { configuration } = this.state;
    if (!configuration) return;
    this.setState({ saving: true });
    try {
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserId(),
        pre: this._originConfig?.values,
        post: configuration.values,
      };
      const action: Action = {
        collection: CONFIGURATION,
        filter: { key: configuration.key },
        update: { values: configuration.values, lastUpdate: new Date() },
        push: { timeline: timelineEntry },
      };
      const result = await transaction([action]);
      if (result) {
        this._originConfig = _.cloneDeep(configuration);
        toast.success("Calculation values updated successfully.");
      } else {
        toast.error("Calculation configuration could not be updated. Please try again later.");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  updateConfiguration = async () => {
    if (!this._changeStream) return;
    for await (const change of this._changeStream) {
      if (!this._isMounted) break;
      if (change.operationType === "update") {
        if (!_.isEqual(this._originConfig?.values, change.fullDocument?.values))
          this.setState({ updatedCalculation: true });
      }
    }
  };

  render() {
    const { tab, configuration, loading, saving, updatedCalculation } = this.state;
    if (!userService.getRoles().some((r) => REQUIRED_ROLES_CALCULATION_CONFIGURATION.includes(r)))
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Calculation Values</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">You do not have access to this section</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    if (loading) return <SimpleSplashScreen description={"Loading configuration..."} />;
    if (!configuration)
      return (
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="card bg-white min-h-100">
                <div className="card-body">
                  <h3 className="card-title ">
                    <span className="card-label fw-bolder fs-3rem">Calculation Values</span>
                  </h3>
                  <h5 className="mt-20 text-center">
                    <span className="text-muted">No values currently available</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    const { generalValues, airFreightValues, seaFreightValues, warehouseValues } = configuration.values;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title ">
                  <span className="card-label fw-bolder fs-3rem">Calculation Values</span>
                </h3>
                {updatedCalculation && (
                  <div className="card bg-warning mt-10 w-100">
                    <div className="card-body fs-6 row">
                      <div className="text-black fw-bold col-10">
                        Calculation values were updated in the background. You may overwrite changes made by others.
                        Please reload and repeat your changes to avoid overwriting existing changes.
                      </div>
                      <div className="col-2 text-right my-auto">
                        <button className="btn btn-sm btn-outline btn-outline-dark " onClick={this.handleReload}>
                          Reload
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex flex-column">
                  <div className="btn-group btn-group-md rounded-0 mt-10 mb-5">
                    <button
                      className={
                        "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " + (tab === GENERAL && "active")
                      }
                      onClick={() => this.handleChangeTab(GENERAL)}
                    >
                      General Values
                    </button>
                    <button
                      className={
                        "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " +
                        (tab === CALC_SEAFREIGHT && "active")
                      }
                      onClick={() => this.handleChangeTab(CALC_SEAFREIGHT)}
                    >
                      Sea Freight
                    </button>
                    <button
                      className={
                        "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " +
                        (tab === CALC_AIRFREIGHT && "active")
                      }
                      onClick={() => this.handleChangeTab(CALC_AIRFREIGHT)}
                    >
                      Air Freight
                    </button>
                    <button
                      className={
                        "btn btn-outline btn-outline-light btn-sm w-50 rounded-0 " +
                        (tab === P_CALC_WAREHOUSE && "active")
                      }
                      onClick={() => this.handleChangeTab(P_CALC_WAREHOUSE)}
                    >
                      Warehouse
                    </button>
                  </div>
                </div>
                <div>
                  {tab === GENERAL && (
                    <GeneralValuesConfiguration
                      generalValues={generalValues}
                      onChangePaletteData={this.handleChangePaletteData}
                      onToggleStackable={this.handleToggleStackable}
                      onChangeContainerData={this.handleChangeContainerData}
                      onChangeGeneralValues={this.handleChangeGeneralValues}
                    />
                  )}
                  {tab === CALC_SEAFREIGHT && (
                    <SeaFreightValuesConfiguration
                      seaFreightValues={seaFreightValues}
                      onChangeSeaFreightValues={this.handleChangeSeaFreightValues}
                    />
                  )}
                  {tab === CALC_AIRFREIGHT && (
                    <AirFreightValuesConfiguration
                      airFreightValues={airFreightValues}
                      onChangeAirFreightValues={this.handleChangeAirFreightValues}
                    />
                  )}
                  {tab === P_CALC_WAREHOUSE && (
                    <WarehouseValuesConfiguration
                      warehouseValues={warehouseValues}
                      onChangeWarehouseValues={this.handleChangeWarehouseValues}
                    />
                  )}
                </div>
              </div>
              <div className="card-footer border-0 text-right">
                <button
                  className={"btn btn-sm btn-outline btn-outline-light mr-2" + (saving ? " disabled" : "")}
                  disabled={saving}
                  onClick={saving ? undefined : this.handleReset}
                >
                  Reset
                </button>
                <ErrorOverlayButton
                  errors={[]}
                  className={"btn btn-sm btn-outline btn-outline-light"}
                  buttonText="Save"
                  saving={saving}
                  onClick={saving ? undefined : this.handleSave}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CalculationValuesConfiguration;
