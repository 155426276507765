import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Price, SupplierEUStockPrices } from "../../../model/commodity.types";
import { Input } from "../../common/Input";
import { SUPPORTED_CURRENCIES } from "../../../utils/currencyUtils";
import DateInput from "../../common/DateInput";
import { getDaysUntil } from "../../../utils/dateUtils";
import { getDefaultPrice, Incoterm, SUPPORTED_INCOTERMS } from "../../../utils/commodityUtils";
import { ArticlePriceRenewAllModal, SetLeadTimeModal } from "../../common/ArticlePriceTable";
import { Action, COMMODITY, FINISHEDPRODUCT, transaction } from "../../../services/dbService";
import { SupplierExtended } from "../../../model/supplier.types";
import ContactPersonWidget from "../../common/ContactPersonWidget";
import SimpleConfirmationModal from "../../common/SimpleConfirmationModal";
import userService from "../../../services/userService";
import { INTERNAL } from "../../../utils/userUtils";
import Tooltip from "../../common/Tooltip";
import { isFinishedProduct } from "../../../utils/finishedProductUtils";
import {
  formatArticleUnit,
  getFullArticleName,
  InternalArticleExtended,
  SupplierArticleExtended,
} from "../../../utils/productArticleUtils";

interface ArticleEUStockEntryProps {
  article: InternalArticleExtended | SupplierArticleExtended;
  euStockPrice: SupplierEUStockPrices;
  supplier?: SupplierExtended;
}

interface ArticleEUStockEntryState {
  euStockPrice: SupplierEUStockPrices;
  priceToRenew?: Price;
  renewAll: boolean;
  setLeadTime: boolean;
  saving: boolean;
}

class ArticleEUStockEntry extends PureComponent<ArticleEUStockEntryProps, ArticleEUStockEntryState> {
  constructor(props: ArticleEUStockEntryProps) {
    super(props);
    this.state = {
      euStockPrice: _.cloneDeep(props.euStockPrice),
      renewAll: false,
      setLeadTime: false,
      saving: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<ArticleEUStockEntryProps>) {
    if (!_.isEqual(prevProps.euStockPrice, this.props.euStockPrice)) {
      this.setState({ euStockPrice: _.cloneDeep(this.props.euStockPrice) });
    }
  }

  handleUpdateData = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, id: BSON.ObjectId) => {
    const euStockPrice = _.cloneDeep(this.state.euStockPrice);
    const { name, value } = e.target;
    const price = euStockPrice.prices.find((p) => p._id.toString() === id.toString());
    if (price) {
      if (name === "minOQ") {
        price.minOQ = +value;
      } else if (name === "price") {
        price.price = +value;
      } else if (name === "currency") {
        price.currency = value;
      } else if (name === "leadTime") {
        price.leadTime = +value;
      } else if (name === "incoterm") {
        price.incoterm = value as Incoterm;
      }
      this.setState({ euStockPrice });
    }
  };

  handleUpdateAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const euStockPrice = _.cloneDeep(this.state.euStockPrice);
    euStockPrice.amount = e.target.valueAsNumber;
    this.setState({ euStockPrice });
  };

  handleAddPrice = () => {
    const { supplier } = this.props;
    const euStockPrice = _.cloneDeep(this.state.euStockPrice);
    euStockPrice.prices.push(getDefaultPrice(undefined, euStockPrice.leadTime || supplier?.transport.preparationTime));
    this.setState({ euStockPrice });
  };

  handleRemovePrice = (id: BSON.ObjectId) => {
    const euStockPrice = _.cloneDeep(this.state.euStockPrice);
    euStockPrice.prices = euStockPrice.prices.filter((p) => p._id.toString() !== id.toString());
    this.setState({ euStockPrice });
  };

  handleRenewPrice = (entryId: BSON.ObjectId | string, date: Date, priceId?: BSON.ObjectId | string) => {
    const euStockPrice = _.cloneDeep(this.state.euStockPrice);
    if (entryId.toString() !== euStockPrice._id.toString()) return; // Something went really wrong if that happened
    if (priceId) {
      const price = euStockPrice.prices.find((p) => p._id.toString() === priceId?.toString());
      if (price) {
        // Should not happen, but a little sanity is fine
        price.validUntil = date;
      }
    } else {
      for (let i = 0; i < euStockPrice.prices.length; i++) {
        const p = euStockPrice.prices[i];
        p.validUntil = date;
      }
    }
    this.setState({ euStockPrice });
  };

  handleSetLeadTime = (entryId: BSON.ObjectId | string, value: string) => {
    const euStockPrice = _.cloneDeep(this.state.euStockPrice);
    if (entryId.toString() !== euStockPrice._id.toString()) return; // Something went really wrong if that happened
    const val = parseFloat(value);
    euStockPrice.leadTime = val; // Set lead time globally for offered commodity or finished product
    euStockPrice.prices = euStockPrice.prices.map((price) => ({ ...price, leadTime: val })); // Overwrite individual lead times if global lead time is set
    this.setState({ euStockPrice });
  };

  handlePriceToRenewClose = () => this.setState({ renewAll: false, priceToRenew: undefined });

  handleSetLeadTimeClose = () => this.setState({ setLeadTime: false });

  handleResetData = () => this.setState({ euStockPrice: _.cloneDeep(this.props.euStockPrice) });

  handleDisable = async (disabled: boolean) => {
    const { article } = this.props;
    const { euStockPrice } = this.state;
    try {
      this.setState({ saving: true });
      const actions: Array<Action> = [
        {
          collection: isFinishedProduct(article) ? FINISHEDPRODUCT : COMMODITY,
          filter: { _id: article._id },
          update: {
            "supplierEUStocks.$[ses].disabled": disabled,
          },
          arrayFilters: [{ "ses._id": euStockPrice._id }],
        },
      ];
      const res = await transaction(actions);
      if (res) {
        toast.success("EU Stock successfully saved");
      } else {
        toast.error("Error saving EU Stock");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleDelete = async () => {
    const { article } = this.props;
    const { euStockPrice } = this.state;
    try {
      this.setState({ saving: true });
      const actions: Array<Action> = [
        {
          collection: isFinishedProduct(article) ? FINISHEDPRODUCT : COMMODITY,
          filter: { _id: article._id },
          pull: { supplierEUStocks: { _id: euStockPrice._id } },
        },
      ];
      const res = await transaction(actions);
      if (res) {
        toast.success("EU Stock successfully deleted");
      } else {
        toast.error("Error deleting EU Stock");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleSaveEUStock = async () => {
    const { article } = this.props;
    const { euStockPrice } = this.state;
    this.setState({ saving: true });
    try {
      const actions: Array<Action> = [];
      const update = article.supplierEUStocks?.some((ses) => ses._id.toString() === euStockPrice._id.toString());
      euStockPrice.prices.sort((p1, p2) => p1.minOQ - p2.minOQ);
      if (update) {
        actions.push({
          collection: isFinishedProduct(article) ? FINISHEDPRODUCT : COMMODITY,
          filter: { _id: article._id },
          update: {
            "supplierEUStocks.$[ses].prices": euStockPrice.prices,
            "supplierEUStocks.$[ses].amount": euStockPrice.amount,
          },
          arrayFilters: [{ "ses._id": euStockPrice._id }],
        });
      } else {
        actions.push({
          collection: isFinishedProduct(article) ? FINISHEDPRODUCT : COMMODITY,
          filter: { _id: article._id },
          push: { supplierEUStocks: euStockPrice },
        });
      }
      const res = await transaction(actions);
      if (res) {
        toast.success("EU Stock successfully saved");
      } else {
        toast.error("Error saving EU Stock");
      }
    } catch (e) {
      toast.error("Error saving EU Stock");
      console.error("ERROR SAVING EU STOCK:", e);
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { article, supplier } = this.props;
    const { renewAll, setLeadTime, euStockPrice, priceToRenew, saving } = this.state;
    const expIn = (p: Price) => getDaysUntil(p.validUntil, true);
    const finishedProduct = isFinishedProduct(article);

    return (
      <div className="bg-light2 rounded p-5 mb-7">
        <div className="d-flex align-items-center ">
          <div className="flex-grow-1 me-2">
            {supplier ? (
              <>
                <Link
                  to={`/supplier/${supplier._id.toString()}`}
                  onClick={(e) => e.stopPropagation()}
                  className={"text-white fs-5 custom-link " + (euStockPrice.disabled ? "opacity-50" : "")}
                >
                  {supplier.name}
                </Link>
                <ContactPersonWidget person={supplier.primaryPerson} spanClasses="text-muted fw-bold d-block" />
              </>
            ) : (
              <>
                <Link
                  to={`/${finishedProduct ? "finishedProduct" : "commodity"}/${article._id.toString()}`}
                  onClick={(e) => e.stopPropagation()}
                  className={"text-white fs-5 custom-link " + (euStockPrice.disabled ? "opacity-50" : "")}
                >
                  {article.title.en}
                </Link>
                <br />
                <span className={"text-muted " + (euStockPrice.disabled ? "opacity-50" : "")}>
                  {article.subtitle.en}
                </span>
              </>
            )}
          </div>
          <div style={{ textAlign: "right", marginRight: 10, width: 80 }}>
            <div className="d-flex">
              {euStockPrice.disabled ? (
                <SimpleConfirmationModal.SimpleConfirmationModalButton
                  modalTitle="Enable EU Stock"
                  modalDescription={
                    <span className="text-white">
                      <div>Do you really want to enable the EU Stock for {getFullArticleName(article)}?</div>
                    </span>
                  }
                  buttonText="Enable"
                  buttonClasses="btn btn-warning btn-sm w-100 mr-2"
                  cancelButtonText="Cancel"
                  confirmButtonText="Confirm"
                  onConfirm={() => this.handleDisable(false)}
                />
              ) : (
                <ErrorOverlayButton
                  errors={[]}
                  className={"btn btn-success btn-sm " + (euStockPrice.disabled ? "w-50" : "w-100")}
                  buttonText="Save"
                  saving={saving}
                  onClick={this.handleSaveEUStock}
                />
              )}
            </div>
          </div>
        </div>
        {!euStockPrice.disabled && (
          <>
            <div className="table-responsive mt-5">
              <table className="table align-middle gs-0 gy-1 ">
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th className="border-bottom-0">Amount on Stock</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                          <div className="input-group">
                            <Input
                              type="number"
                              value={euStockPrice.amount}
                              className="form-control custom-form-control pt-0 pb-0 bg-dark"
                              name="amount"
                              onBlur={this.handleUpdateAmount}
                            />
                            <div className="input-group-append rounded-end">
                              <div
                                className="form-control custom-form-control bg-dark"
                                style={{ padding: ".375rem .75rem" }}
                              >
                                {formatArticleUnit(article.unit)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive mt-5">
              <table className="table align-middle gs-0 gy-1 ">
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th className="border-bottom-0">MOQ</th>
                    <th className="border-bottom-0">Price</th>
                    <th className="border-bottom-0">
                      Preparation Time
                      <Tooltip
                        tooltipText={
                          <span className="text-white">
                            Preparation time can be set individually for different quantities or for the whole
                            <span>{finishedProduct ? "finished product" : "commodity"}</span>
                            by using 'Set Preparation Time'.
                          </span>
                        }
                      >
                        <i className="fa fa-question-circle text-muted opacity-25 ml-2" />
                      </Tooltip>
                    </th>
                    <th className="border-bottom-0">Exp. in</th>
                    <th className="border-bottom-0">Expiration</th>
                    <th className="border-bottom-0 text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {euStockPrice.prices.map((sp) => (
                    <tr key={sp._id.toString()}>
                      <td className="align-middle">
                        <div className="input-group">
                          <Input
                            type="number"
                            value={sp.minOQ}
                            className="form-control custom-form-control pt-0 pb-0 bg-dark"
                            name="minOQ"
                            onBlur={(e) => this.handleUpdateData(e, sp._id)}
                          />
                          <div className="input-group-append rounded-end">
                            <div
                              className="form-control custom-form-control bg-dark"
                              style={{ padding: ".375rem .75rem" }}
                            >
                              {formatArticleUnit(article.unit)}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <div className="input-group">
                          <div className="input-group-prepend rounded-end">
                            <select
                              className="form-control custom-form-control bg-dark pr-1 py-0"
                              name="incoterm"
                              value={sp.incoterm}
                              onChange={(e) => this.handleUpdateData(e, sp._id)}
                            >
                              {SUPPORTED_INCOTERMS.map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </select>
                          </div>
                          <Input
                            type="number"
                            value={sp.price}
                            name="price"
                            className="form-control custom-form-control py-0 bg-dark"
                            onBlur={(e) => this.handleUpdateData(e, sp._id)}
                          />
                          <div className="input-group-append rounded-end">
                            <select
                              className={
                                "form-control custom-form-control bg-dark pl-1 py-0 " + (article.disabled && "disabled")
                              }
                              name={"currency"}
                              value={sp.currency}
                              disabled={article.disabled}
                              onChange={article.disabled ? undefined : (e) => this.handleUpdateData(e, sp._id)}
                            >
                              {SUPPORTED_CURRENCIES.map((c) => (
                                <option key={c} value={c}>
                                  {c}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <div className="input-group">
                          <Input
                            type="number"
                            value={sp.leadTime}
                            name="leadTime"
                            className="form-control custom-form-control py-0 bg-dark"
                            onBlur={article.disabled ? undefined : (e) => this.handleUpdateData(e, sp._id)}
                          />
                          <div className="input-group-append rounded-end">
                            <div className="input-group-text form-control custom-form-control bg-dark">days</div>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">
                        <span className="text-white text-nowrap">
                          {expIn(sp) > 0 ? expIn(sp) + " days" : expIn(sp) === 0 ? "today" : "expired"}
                        </span>
                      </td>
                      <td className="align-middle">
                        <DateInput
                          value={sp.validUntil}
                          onBlur={() => true}
                          name="validUntil"
                          onClick={(e) => {
                            e.preventDefault(); // prevent default date selection
                            this.setState({ priceToRenew: sp });
                          }}
                          tabIndex={-1} // prevent focus via tab
                          classes="form-control custom-form-control bg-dark"
                        />
                      </td>
                      <td className="align-middle ">
                        <button
                          className="btn btn-text-danger btn-sm align-middle px-0"
                          onClick={() => this.handleRemovePrice(sp._id)}
                        >
                          <i className="fa fa-trash text-danger align-middle" />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr className="mt-5">
                    <td className="align-middle" colSpan={3}></td>
                    <td className="align-middle text-right" colSpan={2}>
                      <div className="mt-5">
                        <button
                          className="btn btn-text btn-sm p-0 text-success font-weight-bold"
                          onClick={this.handleAddPrice}
                        >
                          Add Price
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="col-12 align-self-center">
                <button className="btn btn-text btn-text-muted btn-sm float-right pr-0" onClick={this.handleResetData}>
                  Reset
                </button>
                <button
                  className="btn btn-text btn-text-muted btn-sm float-right"
                  onClick={() => this.setState({ renewAll: true })}
                >
                  Renew All
                </button>
                <button
                  className="btn btn-text btn-text-muted btn-sm float-right"
                  onClick={() => this.setState({ setLeadTime: true })}
                >
                  Set Preparation Time
                </button>
                <SimpleConfirmationModal.SimpleConfirmationModalButton
                  modalTitle="Disable EU Stock"
                  modalDescription={
                    <span className="text-white">
                      <div>Do you really want to disable the EU Stock for {getFullArticleName(article)}?</div>
                    </span>
                  }
                  buttonText="Disable"
                  buttonClasses="btn btn-text btn-text-muted btn-sm float-right"
                  cancelButtonText="Cancel"
                  confirmButtonText="Confirm"
                  onConfirm={() => this.handleDisable(true)}
                />
                {userService.getUserType() === INTERNAL && (
                  <SimpleConfirmationModal.SimpleConfirmationModalButton
                    modalTitle="Delete EU Stock"
                    modalDescription={
                      <span className="text-white">
                        <div>Do you really want to delete the EU Stock for {getFullArticleName(article)}?</div>
                      </span>
                    }
                    buttonText="Delete"
                    buttonClasses="btn btn-text btn-text-muted btn-sm float-right"
                    cancelButtonText="Cancel"
                    confirmButtonText="Confirm"
                    onConfirm={this.handleDelete}
                  />
                )}
              </div>
              <ArticlePriceRenewAllModal
                eventKey={euStockPrice._id.toString()}
                onRenewPrices={this.handleRenewPrice}
                price={priceToRenew}
                onClose={this.handlePriceToRenewClose}
                show={Boolean(renewAll || priceToRenew)}
              />
              <SetLeadTimeModal
                eventKey={euStockPrice._id.toString()}
                article={article}
                supplier={supplier}
                onSetLeadTime={this.handleSetLeadTime}
                onClose={this.handleSetLeadTimeClose}
                show={setLeadTime}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ArticleEUStockEntry;
