import React, { useCallback, useContext, useMemo } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import { CustomerOrder } from "../../../model/customerOrder.types";
import {
  calculateArrivalInformation,
  getOrderNumber,
  getOrderStateDescriptions,
  getOrderType,
  ORDER_TYPES,
} from "../../../utils/orderUtils";
import { getTimeDiffString } from "../../../utils/dateUtils";
import HoverPopover from "../../common/HoverPopover";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { DataContextInternal } from "../../../context/dataContext";

interface OpenOrderRowProps extends RouteComponentProps {
  orders: Array<CustomerCustomerOrder | CustomerOrder>;
}

const OpenOrderRow: React.FC<OpenOrderRowProps> = ({ orders, history }) => {
  const context = useContext(DataContextInternal);

  const forwardCreateOrder = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      history.push("/createSupplierOrder/" + orders[0]._id.toString());
    },
    [history, orders[0]]
  );

  const stateDescription = useMemo(() => getOrderStateDescriptions(orders[0]), [orders[0]]);
  const arrival = useMemo(() => calculateArrivalInformation(orders[0]), [orders[0]]);

  const oldestOrder = useMemo(
    () => orders.reduce((a: undefined | ORDER_TYPES, b) => (!a || b.createdAt < a.createdAt ? b : a), undefined),
    [orders]
  );
  const amount = useMemo(() => orders.reduce((a, b) => a + b.amount, 0), [orders]);
  const customers = useMemo(() => {
    const cus = Array.from(new Set(orders.map((o) => o.company)));
    // If there is more than one customer we only for "for x customers" thus resolving would be wasting resources
    if (cus.length > 1) return cus;
    const customerDocument = getDocFromCollection(context.company, cus[0]);
    return [customerDocument?.name ?? "Unknown"];
  }, [orders, context.company]);

  return (
    <tr className="cursor-pointer-row" onClick={forwardCreateOrder}>
      <td className=" align-middle">
        <div className="text-white fs-5 text-ellipsis" style={{ maxWidth: "25rem" }}>
          {amount}
          {orders[0].unit} {orders[0].commodity.title.en}
        </div>
        {customers.length === 1 ? (
          <div className="text-muted text-ellipsis fs-7">{customers[0]}</div>
        ) : (
          <div className="text-muted text-ellipsis fs-7">for {customers.length} customers</div>
        )}
      </td>
      <td className="text-muted align-middle">
        {orders.length > 2 ? (
          <HoverPopover
            popoverStyle={{ maxWidth: "95vw", border: "none" }}
            content={
              <div className="card overflow-auto" style={{ background: "#3f3f3f" }}>
                <div className="card-body">
                  <div className="text-white">
                    {orders.map((cO, idx) => (
                      <div key={cO._id.toString()} className="row" style={{ minWidth: "300px" }}>
                        <div className="col-7 px-2 my-auto fw-bolder fs-7 text-left">
                          <Link
                            className="custom-link text-white"
                            to={`/customerOrder/${cO._id.toString()}`}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Order {getOrderNumber(cO)}
                          </Link>
                        </div>
                        <div className="col-5 pr-2 pl-0 my-auto fs-7 text-left">{cO.amount + cO.unit}</div>
                        {idx < orders.length - 1 && <div className="border-bottom-dark-gray my-2" />}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          >
            <div>
              {orders.slice(0, 2).map((o) => (
                <Link
                  key={o._id.toString()}
                  className="text-white fs-5 custom-link"
                  to={"/customerOrder/" + o._id.toString()}
                  onClick={(e) => e.stopPropagation()}
                >
                  <span>{getOrderNumber(o)}</span>
                </Link>
              ))}
              <div className="text-muted fs-5">and {orders.length - 2} more</div>
            </div>
          </HoverPopover>
        ) : (
          orders.map((o) => (
            <Link
              key={o._id.toString()}
              className="text-white fs-5 mb-1 custom-link"
              to={"/customerOrder/" + o._id.toString()}
              onClick={(e) => e.stopPropagation()}
            >
              <span>{getOrderNumber(o)}</span>
            </Link>
          ))
        )}
      </td>
      <td className="text-light align-middle">
        <div className="text-warning">{stateDescription.title}</div>
        <div className="text-muted">{getTimeDiffString(oldestOrder?.createdAt)}</div>
      </td>
      <td className="text-light align-middle">
        <ProgressBar style={{ backgroundColor: "#232323", height: 5 }} />
        <div className="fs-7 text-nowrap text-center">0%</div>
      </td>
      <td className="text-light align-middle">{getOrderType(orders[0])}</td>
      <td className="align-middle">
        <span className="text-muted">
          Week {arrival.cw}-{arrival.year}
        </span>
      </td>
    </tr>
  );
};

export default OpenOrderRow;
