import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { ForwarderExtended } from "../../../model/forwarder.types";
import { TabDefinition } from "../../common/CustomTypes";
import ForwarderPageGeneral from "./tabPanels/ForwarderPageGeneral";
import { DataContextInternalType } from "../../../context/dataContext";

interface ForwarderPageTabPanelProps {
  context: DataContextInternalType;
  forwarder: ForwarderExtended;
}

interface ForwarderPageTabPanelState {
  currentTab: string;
}

class ForwarderPageTabPanel extends PureComponent<ForwarderPageTabPanelProps, ForwarderPageTabPanelState> {
  constructor(props: ForwarderPageTabPanelProps) {
    super(props);
    this.state = { currentTab: "general" };
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { context, forwarder } = this.props;
    return [
      {
        name: "general",
        tabContent: "General Settings",
        component: <ForwarderPageGeneral context={context} forwarder={forwarder} />,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { forwarder } = this.props;
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();
    return (
      <Tab.Container id="customerTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15 order-0 order-xl-0 mt-2 mt-xl-0">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(forwarder)) && (
                  <li key={t.name + "_link"} className="nav-item cursor-pointer">
                    <Nav.Link
                      role="tab"
                      id={t.name}
                      eventKey={t.name}
                      active={currentTab === t.name}
                      className="text-active-white pb-4 "
                    >
                      {t.tabContent}
                    </Nav.Link>
                  </li>
                )
            )}
          </ul>
          <Tab.Content>
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(forwarder)) && (
                  <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                    {t.component}
                  </Tab.Pane>
                )
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default ForwarderPageTabPanel;
