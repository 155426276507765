import _ from "lodash";
import React, { PureComponent } from "react";
import Fuse from "fuse.js";
import { paginate, PaginationState } from "../../common/Pagination";
import BaseListing from "../../common/BaseListing";
import CreateCommodityModal from "../internal/modals/CreateCommodityModal";
import {
  DataContextType,
  isAnonymousContext,
  isCustomerContext,
  isInternalContext,
  isSupplierContext,
} from "../../../context/dataContext";
import { CARD_LISTING_BREAKPOINT, doFuseSearch, formatDate, getComponentState } from "../../../utils/baseUtils";
import {
  C_STATUS_DELIVERABLE,
  C_STATUS_FREESTOCK,
  C_STATUS_NOTDELIVERABLE,
  C_STATUS_ONSTOCK,
  C_STATUS_PARTIALLYINVALID,
  checkForValidFromPrice,
  getOutdatedPricesCount,
  getValidPricesCount,
  isAnonymousCommodity,
  isArticleInactive,
  isCustomerCommodity,
  isSupplierCommodity,
} from "../../../utils/commodityUtils";
import CommodityListingFilter from "./CommodityListingFilter";
import { PropertyType, resolveProperties } from "../../../utils/propertyUtils";
import CommodityListingRow from "../internal/CommodityListingRow";
import userService from "../../../services/userService";
import { Commodity } from "../../../model/commodity.types";
import { ANONYMOUS, CUSTOMER, INTERNAL, SUPPLIER } from "../../../utils/userUtils";
import CustomerCommodityListingRow from "../customer/CustomerCommodityListingRow";
import { CustomerCommodity } from "../../../model/customer/customerCommodity.types";
import { SelectOption } from "../../common/CustomSelect";
import SupplierCommodityListingRow from "../supplier/SupplierCommodityListingRow";
import { BatchState } from "../../../model/batch.types";
import RequestCommodityModal from "../customer/modals/RequestCommodityModal";
import { generateCommoditiesCSVString } from "../../../utils/csvUtils";
import { COMMODITY, FINISHEDPRODUCT } from "../../../services/dbService";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { CustomerFinishedProduct } from "../../../model/customer/customerFinishedProduct.types";
import {
  isAnonymousFinishedProduct,
  isCustomerFinishedProduct,
  isFinishedProduct,
  isInternalFinishedProduct,
  isSupplierFinishedProduct,
} from "../../../utils/finishedProductUtils";
import { isCommodity } from "../../../utils/productArticleUtils";
import CustomerFinishedProductListingRow from "../../finishedProducts/customer/CustomerFinishedProductListingRow";
import SupplierFinishedProductListingRow from "../../finishedProducts/supplier/SupplierFinishedProductListingRow";
import { exportDatasheet } from "../../../utils/excelUtils";
import { CardListingWrapperComponent } from "../../common/CardListing";
import CardListing from "../../common/CardListing";

interface CommodityListingProps {
  context: DataContextType;
  category?: string;
}

interface CommodityListingState extends PaginationState {
  search: string;
  category?: SelectOption;
  commodityGroup?: SelectOption;
  composition?: SelectOption;
  status?: SelectOption;
  activeSubstances: Array<string>; // list of object ids;
  tags: Array<string>; // list of object ids
  sortColumn?: { column: string; order: "asc" | "desc" };
  sortedProductType: Array<Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct>;
  listingType: typeof COMMODITY | typeof FINISHEDPRODUCT;
}

const COMPONENT_NAME = "CommodityListing";

class CommodityListing extends PureComponent<CommodityListingProps, CommodityListingState> {
  constructor(props: CommodityListingProps) {
    super(props);
    this.state = {
      pageSize: 25,
      currentPage: 1,
      search: "",
      tags: [],
      activeSubstances: [],
      sortColumn: { column: "commodity", order: "asc" },
      sortedProductType: this.getSortedArticleType(props.context.commodity),
      listingType: COMMODITY,
    };
  }

  componentDidMount() {
    const { category, context } = this.props;
    const state = getComponentState(context, COMPONENT_NAME);
    let cat;
    if (category) {
      cat = context.property.find((c) => c._id.toString() === category);
    }
    if (state) {
      if (cat) {
        // @ts-ignore
        state["category"] = { value: cat._id.toString(), label: cat.name.en };
      }
      this.setState({ ...state });
    } else if (cat) {
      this.setState({ category: { value: cat._id.toString(), label: cat.name.en } });
    }
  }

  componentDidUpdate = (prevProps: CommodityListingProps, prevState: CommodityListingState) => {
    const { context } = this.props;
    if (
      !_.isEqual(prevState.sortColumn, this.state.sortColumn) ||
      !_.isEqual(context.commodity, prevProps.context.commodity) ||
      !_.isEqual(context.finishedProduct, prevProps.context.finishedProduct)
    ) {
      this.setState({
        sortedProductType: this.getSortedArticleType(
          this.state.listingType === COMMODITY ? context.commodity : context.finishedProduct
        ),
      });
    }
  };

  componentWillUnmount() {
    this.props.context.saveComponentState(COMPONENT_NAME, _.omit(this.state, "sortedCommodities"));
  }

  handlePageChange = (page: number) => this.setState({ currentPage: page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  // Important to reset currentPage and sortColumn on search
  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({
      search: value,
      currentPage: 1,
      sortColumn: value.trim()
        ? undefined
        : { column: this.state.listingType === FINISHEDPRODUCT ? "finishedProduct" : "commodity", order: "asc" },
    });
  };
  handleCategoryChange = (category: SelectOption | undefined) => this.setState({ category, currentPage: 1 });
  handleCompositionChange = (composition: SelectOption | undefined) => this.setState({ composition, currentPage: 1 });
  handleStatusChange = (status: SelectOption | undefined) => this.setState({ status, currentPage: 1 });

  handleCommodityGroup = (commodityGroup: SelectOption | undefined) => {
    this.setState({ commodityGroup: commodityGroup, currentPage: 1 });
  };

  handleActiveSubstanceChange = (e: CustomEvent<Tagify.ChangeEventData<{ value: string; id: string }>>) => {
    if (!e.detail.value) this.setState({ activeSubstances: [] });
    else {
      const activeSubstances = JSON.parse(e.detail.value); // value is a JSON string containing an array of currently selected tags
      this.setState({
        activeSubstances: activeSubstances.map((t: { value: string; id: string }) => t.id),
        currentPage: 1,
      });
    }
  };

  handleSort = (column: string) => {
    const { sortColumn } = this.state;
    let newOrder: "asc" | "desc" = "desc";
    if (sortColumn && column === sortColumn.column) newOrder = sortColumn.order === "asc" ? "desc" : "asc";
    this.setState({
      sortColumn: { column, order: newOrder },
    });
  };

  handleXLSXExport = () => {
    const { context } = this.props;
    const commodity = this.getSortedArticleType(this.getFilteredArticles()) as Array<Commodity>;
    const csv = generateCommoditiesCSVString(commodity, context);
    const csvWithFilterInformation = this.addFilterInformationToCSV(csv);
    exportDatasheet(csvWithFilterInformation, `Commodities_${formatDate(new Date())}`);
  };

  handleChangeListingType = (e: typeof COMMODITY | typeof FINISHEDPRODUCT) => {
    this.setState({
      listingType: e,
      sortColumn: this.state.search.trim()
        ? undefined
        : { column: e === COMMODITY ? "commodity" : "finishedProduct", order: "asc" },
      sortedProductType:
        e === COMMODITY
          ? this.getSortedArticleType(this.props.context.commodity)
          : this.getSortedArticleType(this.props.context.finishedProduct),
      currentPage: 1,
    });
  };

  addFilterInformationToCSV = (csv: string): string => {
    const { search, activeSubstances, category, composition, commodityGroup, status } = this.state;
    const { context } = this.props;
    let updatedCSV = "Filter Options: " + "\n";
    if (search) {
      updatedCSV += `Search Query;${search}\n`;
    }
    if (activeSubstances.length > 0) {
      const activeSubstanceLabel = activeSubstances.map((substance) => {
        const matchingSubstance = context.activeSubstance.find((aS) => aS._id.toString() === substance);
        return matchingSubstance?.name.en || "";
      });
      updatedCSV += `Active Substances;${activeSubstanceLabel.join(";")}\n`;
    }
    if (category) {
      updatedCSV += `Category;${category.label}\n`;
    }
    if (composition) {
      updatedCSV += `Composition;${composition.label}\n`;
    }
    if (status) {
      updatedCSV += `Status;${status.label}\n`;
    }
    if (commodityGroup) {
      updatedCSV += `Commodity Group;${commodityGroup.label}\n`;
    }
    updatedCSV += `\n${csv}`;
    return updatedCSV;
  };

  getFilteredArticles = () => {
    const { context } = this.props;
    const { search, category, composition, activeSubstances, commodityGroup, tags, status, sortedProductType } =
      this.state;
    const view = userService.getUserType() as string;
    let filteredCommodities = sortedProductType.slice();

    const finishedProducts = filteredCommodities.every((c) => isFinishedProduct(c));

    // Filter disabled and not approved commodities for customer view
    if (view !== INTERNAL) {
      filteredCommodities = filteredCommodities.filter((c) => c.approved && !c.disabled);
    }
    const searchKeys = ["title.en", "subtitle.en", "articleNo", "suppliers.supplier.name"];
    if (!finishedProducts) {
      searchKeys.push("casNumber");
    }
    if (search && view === CUSTOMER) {
      filteredCommodities = doFuseSearch(filteredCommodities, search, searchKeys, {
        getFn: (obj: Commodity | FinishedProduct | CustomerCommodity | CustomerFinishedProduct, path) => {
          if (!(Array.isArray(path) && path.join(".") === "suppliers.supplier.name"))
            return Fuse.config.getFn(obj, path);
          return Fuse.config.getFn(
            {
              suppliers: obj.suppliers.filter((sup) => {
                const disabled = sup.disabled;
                const atLeastOneValidPrice = sup.prices.some(
                  (price) => price.validUntil.getTime() >= new Date().getTime()
                );
                const supplierUploadedSpecification = obj.documents.some((doc) => {
                  return (
                    doc.type === "supplierSpecification" &&
                    doc.supplier === sup.supplier &&
                    (!doc.validUntil || (doc.validUntil && doc.validUntil.getTime() >= new Date().getTime()))
                  );
                });
                return !disabled && atLeastOneValidPrice && supplierUploadedSpecification;
              }),
            },
            path
          );
        },
      });
    } else if (search) {
      filteredCommodities = doFuseSearch(filteredCommodities, search, searchKeys);
    }

    if (category)
      filteredCommodities = filteredCommodities.filter((c) => c.properties.some((p) => p === category.value));
    if (composition)
      filteredCommodities = filteredCommodities.filter((c) => c.properties.some((p) => p === composition.value));
    if (activeSubstances.length > 0) {
      filteredCommodities = filteredCommodities.filter((c) => {
        const aSIDs = c.activeSubstances.map((cAS) => cAS.substance);
        return activeSubstances.every((aS) => aSIDs.includes(aS));
      });
    }
    if (status) {
      switch (status.value) {
        case C_STATUS_DELIVERABLE:
          if (!isCustomerContext(context) && !isAnonymousContext(context)) {
            filteredCommodities = filteredCommodities.filter((c) => getValidPricesCount(c) > 0);
          } else {
            filteredCommodities = filteredCommodities.filter((c) => checkForValidFromPrice(c));
          }
          break;
        case C_STATUS_NOTDELIVERABLE:
          if (!isCustomerContext(context) && !isAnonymousContext(context)) {
            filteredCommodities = filteredCommodities.filter((c) => getValidPricesCount(c) === 0);
          } else {
            filteredCommodities = filteredCommodities.filter((c) => !checkForValidFromPrice(c));
          }
          break;
        case C_STATUS_PARTIALLYINVALID:
          filteredCommodities = filteredCommodities.filter(
            (c) => getValidPricesCount(c) > 0 && getOutdatedPricesCount(c) > 0
          );
          break;
        case C_STATUS_ONSTOCK:
          if (isInternalContext(context)) {
            const onStock = context.batch
              .filter((b) => b.state === BatchState.RELEASED && !b.disabled && b.packages.some((p) => p.amountEach > 0))
              .map((b) => b.commodity._id.toString());
            filteredCommodities = filteredCommodities.filter((c) => onStock.includes(c._id.toString()));
          }
          break;
        case C_STATUS_FREESTOCK:
          if (isInternalContext(context)) {
            const onStock = context.batch
              .filter(
                (b) =>
                  b.state === BatchState.RELEASED &&
                  !b.disabled &&
                  b.packages.some((p) => !p.usedOrders?.length && p.amountEach > 0)
              )
              .map((b) => b.commodity._id.toString());
            filteredCommodities = filteredCommodities.filter((c) => onStock.includes(c._id.toString()));
          } else if (isCustomerContext(context) || isAnonymousContext(context)) {
            filteredCommodities = filteredCommodities.filter(
              (c) => c.fromPrice?.warehouse.price && c.fromPrice?.warehouse.price > 0
            );
          }
          break;
      }
    }
    if (commodityGroup) {
      switch (commodityGroup.value) {
        case "organic":
          filteredCommodities = filteredCommodities.filter((c) => c.organic);
          break;
        case "conventional":
          filteredCommodities = filteredCommodities.filter((c) => !c.organic);
          break;
        case "halal":
          filteredCommodities = filteredCommodities.filter((c) => c.halal);
          break;
        case "kosher":
          filteredCommodities = filteredCommodities.filter((c) => c.kosher);
          break;
      }
    }

    if (tags.length > 0)
      filteredCommodities = filteredCommodities.filter((c) => {
        const propsResolved = resolveProperties(c.properties, context.property);
        return propsResolved.some((p) => p.type === PropertyType.TAG && tags.includes(p._id.toString()));
      });
    return filteredCommodities;
  };

  getSortedArticleType = (
    articles: Array<Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct>
  ) => {
    const sortColumn = this.state?.sortColumn;
    // Inactive orders always at the end
    let sortedArticles: Array<Commodity | CustomerCommodity | FinishedProduct | CustomerFinishedProduct> =
      articles.slice();

    if (!sortColumn) {
      sortedArticles = _.orderBy(
        sortedArticles,
        [(c) => isArticleInactive(c, userService.getUserType()), (c) => c.title.en.toLowerCase()],
        "asc"
      );
    } else {
      switch (sortColumn.column) {
        case "commodity" || "finishedProduct":
          sortedArticles = _.orderBy(
            sortedArticles,
            [(c) => isArticleInactive(c, userService.getUserType()), (c) => c.title.en.toLowerCase()],
            ["asc", sortColumn.order]
          );
          break;
      }
    }

    return sortedArticles;
  };

  render() {
    const { context } = this.props;
    const { currentPage, pageSize, search, category, composition, listingType, commodityGroup, sortColumn, status } =
      this.state;
    const article = this.getFilteredArticles();
    const view = userService.getUserType() as string;
    const commodityHeader = [
      { title: "Commodity", style: { width: "25%", minWidth: "200px" }, sortColumn: "commodity" },
      { title: "Suppliers", style: { width: "12%" } },
      { title: "Orders", style: { width: "8%" } },
      { title: "Prices", style: { width: "8%" } },
      { title: "Price Range", className: "text-nowrap", style: { width: "15%" } },
      { title: "Stock", style: { width: "10%" } },
      { title: "Flow", style: { width: "12%" } },
      { title: "YTY Quantity", className: "text-nowrap text-right", style: { width: "10%" } },
    ];
    const finishedProductHeader = [
      { title: "Finished Product", style: { width: "25%", minWidth: "200px" }, sortColumn: "commodity" },
      { title: "Suppliers", style: { width: "12%" } },
      { title: "Orders", style: { width: "8%" } },
      { title: "Prices", style: { width: "8%" } },
      { title: "Price Range", className: "text-nowrap", style: { width: "15%" } },
      { title: "Stock", style: { width: "10%" } },
      { title: "Flow", style: { width: "12%" } },
      { title: "YTY Quantity", className: "text-nowrap text-right", style: { width: "10%" } },
    ];
    const customerCommodityHeader = [
      { title: "" },
      { title: "Commodity", style: { width: "50%" }, sortColumn: "commodity" },
      { title: "Category", style: { width: "10%" } },
      { title: "CAS Number", style: { width: "15%" } },
      { title: "Origin", className: "text-center", style: { width: "15%" } },
      { title: "Price from", className: "text-center", colSpan: 3 },
    ];
    const customerFinishedProductHeader = [
      { title: "" },
      { title: "Finished Product", style: { width: "50%" }, sortColumn: "commodity" },
      { title: "Category", style: { width: "10%" } },
      { title: "Type", style: { width: "15%" } },
      { title: "Origin", className: "text-center", style: { width: "15%" } },
      { title: "Price", className: "text-center", colSpan: 3 },
    ];
    const supplierCommodityHeader = [
      { title: "Commodity", style: { width: "34%" }, sortColumn: "commodity" },
      { title: "HS-Code", style: { width: "11%" } },
      { title: "CAS Number", style: { width: "11%" } },
      { title: "Competition", style: { width: "14%" } },
      { title: "Popularity", style: { width: "14%" } },
      { title: "Request", style: { width: "10%" } },
      { title: "Spec.", style: { width: "6%" } },
    ];
    const supplierFinishedProductHeader = [
      { title: "Finished Product", style: { width: "34%" }, sortColumn: "commodity" },
      { title: "HS-Code", style: { width: "11%" } },
      { title: "Category", style: { width: "11%" } },
      { title: "Competition", style: { width: "14%" } },
      { title: "Popularity", style: { width: "14%" } },
      { title: "Request", style: { width: "10%" } },
      { title: "Spec.", style: { width: "6%" } },
    ];
    const paginated = paginate(article, currentPage, pageSize);
    const isCommodityListing = listingType === COMMODITY;

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white min-h-100">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder mb-3 fs-2rem fs-sm-3rem">
                    {isCommodityListing ? "Commodities" : "Finished Products"}
                  </span>
                  {view === INTERNAL && <CreateCommodityModal finishedProduct={!isCommodityListing} />}
                  {view === CUSTOMER && <RequestCommodityModal />}
                </h3>
                <p className="mb-lg-15">
                  <span className="mb-3 fs-5 text-muted">
                    Showing {paginated.length} of {article.length} matching{" "}
                    <span>{isCommodityListing ? "commodities" : "finished products"}</span>
                  </span>
                </p>

                <div className="row col-12 w-100 mb-5 d-lg-none"></div>
                <div className="row">
                  <div className="col-12">
                    <div className="btn-group mb-4 w-100">
                      <div
                        className={"w-50 btn btn-light " + (listingType === COMMODITY && "active")}
                        onClick={() => this.handleChangeListingType(COMMODITY)}
                      >
                        Commodity
                      </div>
                      <div
                        className={"w-50 btn btn-light " + (listingType === FINISHEDPRODUCT && "active")}
                        onClick={() => this.handleChangeListingType(FINISHEDPRODUCT)}
                      >
                        Finished Product
                      </div>
                    </div>
                  </div>
                </div>
                <CommodityListingFilter
                  context={context}
                  search={search}
                  category={category}
                  composition={composition}
                  commodityGroup={commodityGroup}
                  status={status}
                  listingType={listingType}
                  onSearch={this.handleSearch}
                  onCategoryChange={this.handleCategoryChange}
                  onCompositionChange={this.handleCompositionChange}
                  onStatusChange={this.handleStatusChange}
                  onActiveSubstanceChange={this.handleActiveSubstanceChange}
                  onCommodityGroupChange={this.handleCommodityGroup}
                  onXLSXExport={this.handleXLSXExport}
                />
                {article.length > 0 ? (
                  isCommodityListing ? (
                    [CUSTOMER, ANONYMOUS].includes(view) && window.innerWidth <= CARD_LISTING_BREAKPOINT ? (
                      <CardListing
                        paginatedDocuments={paginated}
                        wrapperComponent={CardListingWrapperComponent.CustomerCommodityCard}
                        context={context}
                        documents={article}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        baseSize={25}
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                      />
                    ) : (
                      <BaseListing
                        headerDefinition={
                          [CUSTOMER, ANONYMOUS].includes(view)
                            ? customerCommodityHeader
                            : view === SUPPLIER
                            ? supplierCommodityHeader
                            : commodityHeader
                        }
                        documents={article}
                        bodyContent={
                          <>
                            {paginated.map((c) => {
                              if (isCommodity(c, view) && isInternalContext(context))
                                return (
                                  <CommodityListingRow
                                    article={c}
                                    key={c._id.toString()}
                                    articles={article as Array<Commodity>}
                                    orders={context.supplierOrder}
                                    currencies={context.currencies}
                                  />
                                );
                              else if (
                                (isCustomerCommodity(c, view) && isCustomerContext(context)) ||
                                (isAnonymousCommodity(c, view) && isAnonymousContext(context))
                              )
                                return (
                                  <CustomerCommodityListingRow key={c._id.toString()} commodity={c} context={context} />
                                );
                              else if (isSupplierCommodity(c, view) && isSupplierContext(context))
                                return (
                                  <SupplierCommodityListingRow key={c._id.toString()} commodity={c} context={context} />
                                );
                            })}
                          </>
                        }
                        currentPage={currentPage}
                        pageSize={pageSize}
                        sortColumn={sortColumn}
                        baseSize={25}
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        onSort={this.handleSort}
                      />
                    )
                  ) : [CUSTOMER, ANONYMOUS].includes(view) && window.innerWidth <= CARD_LISTING_BREAKPOINT ? (
                    <CardListing
                      paginatedDocuments={paginated}
                      wrapperComponent={CardListingWrapperComponent.CustomerFinishedProductCard}
                      context={context}
                      documents={article}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      baseSize={25}
                      onPageChange={this.handlePageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                    />
                  ) : (
                    <BaseListing
                      headerDefinition={
                        [CUSTOMER, ANONYMOUS].includes(view)
                          ? customerFinishedProductHeader
                          : view === SUPPLIER
                          ? supplierFinishedProductHeader
                          : finishedProductHeader
                      }
                      documents={article}
                      bodyContent={
                        <>
                          {paginated.map((fp) => {
                            if (isInternalFinishedProduct(fp, view) && isInternalContext(context))
                              return (
                                <CommodityListingRow
                                  article={fp}
                                  key={fp._id.toString()}
                                  articles={article as Array<FinishedProduct>}
                                  orders={context.supplierOrder}
                                  currencies={context.currencies}
                                />
                              );
                            else if (
                              (isCustomerFinishedProduct(fp, view) && isCustomerContext(context)) ||
                              (isAnonymousFinishedProduct(fp, view) && isAnonymousContext(context))
                            )
                              return (
                                <CustomerFinishedProductListingRow
                                  key={fp._id.toString()}
                                  finishedProduct={fp}
                                  context={context}
                                />
                              );
                            else if (isSupplierFinishedProduct(fp, view) && isSupplierContext(context))
                              return (
                                <SupplierFinishedProductListingRow
                                  key={fp._id.toString()}
                                  finishedProduct={fp}
                                  context={context}
                                />
                              );
                          })}
                        </>
                      }
                      currentPage={currentPage}
                      pageSize={pageSize}
                      sortColumn={sortColumn}
                      baseSize={25}
                      onPageChange={this.handlePageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                      onSort={this.handleSort}
                    />
                  )
                ) : (
                  <p className="text-white text-center mt-20">No results found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommodityListing;
