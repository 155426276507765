import React, { useCallback, useContext, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import Search from "../../../common/Search";
import BaseListing from "../../../common/BaseListing";
import { paginate, PaginationState } from "../../../common/Pagination";
import ContactPersonWidget from "../../../common/ContactPersonWidget";
import ReactStarsWrapper from "../../../common/ReactStarsWrapper";
import { SupplierExtended } from "../../../../model/supplier.types";
import { DataContextInternal } from "../../../../context/dataContext";
import { doFuseSearch } from "../../../../utils/baseUtils";
import { InternalArticleExtended } from "../../../../utils/productArticleUtils";
import { extendSupplier } from "../../../../utils/dataTransformationUtils";

interface AddCommissionStockModalProps {
  article: InternalArticleExtended;
  onAddCommissionStock: (supplier: SupplierExtended) => void;
}

const AddCommissionStockModal: React.FC<AddCommissionStockModalProps> = ({ article, onAddCommissionStock }) => {
  const context = useContext(DataContextInternal);

  const [show, setShow] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [{ currentPage, pageSize }, setPaginationState] = useState<PaginationState>({ currentPage: 1, pageSize: 15 });

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);
  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPaginationState((prevState) => ({ ...prevState, currentPage: 1 }));
  }, []);

  const handlePageChange = useCallback(
    (currentPage: number) => setPaginationState((prevState) => ({ ...prevState, currentPage })),
    []
  );

  const handlePageSizeChange = useCallback((pageSize: number) => setPaginationState({ currentPage: 1, pageSize }), []);

  const handleAddCommissionStock = useCallback(
    (supplier: SupplierExtended) => {
      onAddCommissionStock(supplier);
      handleHide();
    },
    [onAddCommissionStock]
  );

  const suppliersFiltered = useMemo(() => {
    const suppliersWithCommissionStock = article.commissionStocks?.map((cS) => cS.supplier);
    const filteredSuppliers = context.supplier.filter(
      (s) => !s.disabled && s.activated && !suppliersWithCommissionStock?.includes(s._id.toString())
    );
    if (!search.trim()) return filteredSuppliers.map((fS) => extendSupplier(fS, context));
    return doFuseSearch(filteredSuppliers, search, ["name"]).map((fS) => extendSupplier(fS, context));
  }, [context.supplier, article.commissionStocks, search]);

  const headers = useMemo(() => {
    return [{ title: "Supplier Name" }, { title: "Rating" }, { title: "Mail" }, { title: "Phone" }, { title: "" }];
  }, []);

  return (
    <>
      <button type="button" className="btn btn-light btn-sm float-right" onClick={handleShow}>
        Add Commission Stock
      </button>
      <Modal contentClassName="bg-dark" show={show} onHide={handleHide} centered size="xl">
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Add Commission Stock</h1>
          </Modal.Title>
          <CloseButton variant="white" onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="w-50 float-right my-15 mt-0">
            <Search onSearch={handleSearch} value={search} />
          </div>
          <div className="mt-15">
            <BaseListing
              headerDefinition={headers}
              bodyContent={
                <>
                  {paginate(suppliersFiltered, currentPage, pageSize).map((s) => {
                    return (
                      <tr key={s._id.toString()} className="text-white fs-6">
                        <td className="align-middle">
                          {s.name}
                          <ContactPersonWidget person={s.primaryPerson} spanClasses="text-muted fw-bold d-block" />
                        </td>
                        <td className="align-middle">
                          <ReactStarsWrapper classNames="mx-auto" value={s.rating} count={5} size={14} edit={false} />
                        </td>
                        <td className="align-middle">{s.phone}</td>
                        <td className="align-middle">{s.mail}</td>
                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-light btn-sm float-right mr-2"
                            onClick={() => handleAddCommissionStock(s)}
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
              documents={suppliersFiltered}
              baseSize={15}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline btn-text-white" onClick={handleHide}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddCommissionStockModal;
