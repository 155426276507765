import React, { useCallback, useMemo, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import validator from "validator";
import { INSERT, InsertAction, transaction, PRICEREQUESTWHITELIST } from "../../../../services/dbService";
import { PriceRequestWhitelist, PriceRequestWhitelistStatus } from "../../../../model/priceRequestWhitelist.types";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";

const AddWhitelistEntryModal: React.FunctionComponent = () => {
  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [mail, setMail] = useState<string>("");

  const handleShow = useCallback(() => setShow(true), []);
  const handleHide = useCallback(() => setShow(false), []);

  const handleTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
  }, []);

  const handleAddWhitelistEntry = useCallback(async () => {
    setSaving(true);
    try {
      const actions: Array<InsertAction> = [];
      const toInsert: PriceRequestWhitelist = {
        _id: new BSON.ObjectId(),
        status: PriceRequestWhitelistStatus.PENDING,
        mail: mail,
        manuallyAdded: true,
      };

      actions.push({
        collection: PRICEREQUESTWHITELIST,
        action: INSERT,
        object: toInsert,
      });
      const res = await transaction(actions);
      if (res) {
        toast.success("Whitelist entry successfully created");
        setShow(false);
      } else {
        toast.error("Error creating whitelist entry");
      }
    } catch (e) {
      toast.error("An error occurred");
      console.error(e);
    } finally {
      setSaving(false);
    }
  }, [mail]);

  const errors = useMemo(() => {
    const result: Array<string> = [];
    if (!validator.isEmail(mail)) {
      result.push("Email is not valid");
    }
    return result;
  }, [mail]);

  return (
    <>
      <button className="btn btn-sm btn-outline btn-outline-light" onClick={handleShow}>
        Add Mail
      </button>
      <Modal contentClassName="bg-dark" show={show} size={"lg"} onHide={saving ? undefined : handleHide} centered>
        <Modal.Header className="border-0 pb-0">
          <Modal.Title>
            <h1 className="fw-bolder d-flex align-items-center text-white">Add Mail to RAWBIDS-BOT Whitelist</h1>
          </Modal.Title>
          <CloseButton variant={"white"} disabled={saving} onClick={saving ? undefined : handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="required fs-5 fw-bold mb-2">Mail</label>
            <input
              type="text"
              className={"form-control custom-form-control "}
              value={mail}
              onChange={handleTextChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ErrorOverlayButton
            className={"btn btn-light btn-sm"}
            onClick={handleHide}
            saving={saving}
            buttonText="Close"
          />
          <ErrorOverlayButton
            saving={saving}
            errors={errors}
            className="btn btn-sm btn-outline btn-outline-light"
            buttonText="Add"
            onClick={handleAddWhitelistEntry}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddWhitelistEntryModal;
