import _ from "lodash";
import React, { PureComponent } from "react";
import AddNewVersionModal from "./modals/AddNewVersionModal";
import { VersionHistory } from "../../../model/versionHistory.types";
import { formatDate } from "../../../utils/baseUtils";
import { paginate, PaginationState } from "../../common/Pagination";
import BaseListing, { BaseListingHeaderDefinition } from "../../common/BaseListing";

interface VersionHistorySettingProps {
  versionHistory: Array<VersionHistory>;
}

interface VersionHistorySettingState extends PaginationState {}

class VersionHistorySetting extends PureComponent<VersionHistorySettingProps, VersionHistorySettingState> {
  constructor(props: VersionHistorySettingProps) {
    super(props);
    this.state = { pageSize: 25, currentPage: 1 };
  }

  handleChangePageSize = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });
  handleChangeCurrentPage = (currentPage: number) => this.setState({ currentPage });

  render() {
    const { versionHistory } = this.props;
    const { currentPage, pageSize } = this.state;

    const headerDefinition: Array<BaseListingHeaderDefinition> = [
      { title: "Version", style: { width: "27%" } },
      { title: "Date", style: { width: "27%" } },
      { title: "Changes", style: { width: "26%" } },
      { title: "Edit", style: { width: "20%" }, className: "text-right" },
    ];

    const vHistorySorted = _.orderBy(versionHistory, "date", "desc");
    const vHistoryPaginated = paginate(vHistorySorted, currentPage, pageSize);

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white">
              <div className="card-body">
                <h3 className="card-title align-items-start flex-column mb-15">
                  <span className="card-label fw-bolder mb-3 fs-3rem">Version History</span>
                </h3>
                <BaseListing
                  headerDefinition={headerDefinition}
                  bodyContent={
                    <>
                      {vHistoryPaginated.map((v) => (
                        <tr key={v._id.toString()}>
                          <td className="text-white align-middle">{v.version}</td>
                          <td className="text-white align-middle">{formatDate(v.date)}</td>
                          <td className="text-white align-middle">{v.content.length} Entries</td>
                          <td className="text-white align-middle text-right">
                            <AddNewVersionModal versionHistory={v} />
                          </td>
                        </tr>
                      ))}
                    </>
                  }
                  documents={vHistorySorted}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  baseSize={25}
                  onPageChange={this.handleChangeCurrentPage}
                  onPageSizeChange={this.handleChangePageSize}
                />
              </div>
              <div className="card-footer text-right border-0">
                <AddNewVersionModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VersionHistorySetting;
