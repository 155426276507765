import React, { PureComponent } from "react";
import { toAbsoluteUrl } from "../../../utils/baseUtils";
import {
  D_OTHER,
  D_SUPPLIERANALYSIS,
  D_SUPPLIERSPECIFICATION,
  D_TYPEOPTIONS,
  getSupplierDocuments,
} from "../../../utils/commodityUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import { CustomerSupplier } from "../../../model/customer/customerSupplier.types";
import { CustomerArticleExtended } from "../../../utils/productArticleUtils";
import { UploadedFileExtended } from "../../../model/commodity.types";

interface SupplierFiles {
  supplier: CustomerSupplier;
  files: Array<UploadedFileExtended>;
}

interface CommodityPageDocumentsProps {
  article: CustomerArticleExtended;
  isAnonymousView?: boolean;
}

interface CommodityPageDocumentsState {
  selectedSupplier: SelectOption<CustomerSupplier>;
  supplierFiles: Array<SupplierFiles>;
}

class CommodityPageDocuments extends PureComponent<CommodityPageDocumentsProps, CommodityPageDocumentsState> {
  constructor(props: CommodityPageDocumentsProps) {
    super(props);
    this.state = {
      selectedSupplier: {
        value: "",
        label: "All",
      },
      supplierFiles: this.getAllRelevantSupplierDocuments(),
    };
  }

  handleChangeSupplierSelect = (e: SelectOption<CustomerSupplier>) => {
    let supplierFiles: Array<SupplierFiles>;
    if (e.label === "All") {
      supplierFiles = this.getAllRelevantSupplierDocuments();
    } else {
      const supplierSpecific = this.getAllRelevantSupplierDocuments().find(
        (sf) => sf.supplier._id.toString() === e.value
      );
      if (supplierSpecific) {
        supplierFiles = [supplierSpecific];
      } else {
        supplierFiles = this.getAllRelevantSupplierDocuments();
      }
    }
    this.setState({ selectedSupplier: e, supplierFiles });
  };

  resolveDocumentType = (file: UploadedFileExtended) => {
    if (file.type === D_OTHER) {
      return file.customType || D_TYPEOPTIONS.find((t) => t.value === D_OTHER)?.label;
    }
    return D_TYPEOPTIONS.find((t) => t.value === file.type)?.label ?? "Unknown";
  };

  getAllRelevantSuppliers = () => {
    const { article } = this.props;
    const suppliers: Array<CustomerSupplier> = [];
    for (const supplier of article.suppliers) {
      if (supplier.disabled || supplier.supplier.disabled || !supplier.prices.some((p) => p.validUntil > new Date()))
        continue;
      suppliers.push(supplier.supplier);
    }
    return suppliers;
  };

  getSupplierSelectOptions = () => {
    const { article } = this.props;
    const files = getSupplierDocuments(article);
    const allSuppliers = this.getAllRelevantSuppliers();
    // get only suppliers from which commodity can be ordered
    const filteredSuppliers = allSuppliers.filter((s) =>
      files.documents.some((f) => s._id.toString() === f.supplier?._id.toString())
    );
    const suppliers: Array<SelectOption<CustomerSupplier>> = [
      {
        value: "",
        label: "All",
      },
    ];
    filteredSuppliers.map((s) => {
      suppliers.push({ value: s._id.toString(), label: s.name, object: s });
    });
    return suppliers;
  };

  getAllRelevantSupplierDocuments = () => {
    const { article } = this.props;
    const files = getSupplierDocuments(article);
    const suppliers: Array<CustomerSupplier> = this.getAllRelevantSuppliers();
    // get only files from suppliers from which commodity can be ordered
    const filteredDocs = files.documents.filter((f) =>
      suppliers.some((s) => s._id.toString() === f.supplier?._id.toString())
    );
    // return filteredDocs;
    const supplierFiles: Array<SupplierFiles> = [];
    for (const doc of filteredDocs) {
      if (doc.supplier) {
        if (supplierFiles.some((s) => s.supplier._id.toString() === doc.supplier?._id.toString())) {
          const specificSelect = supplierFiles.find((s) => s.supplier._id.toString() === doc.supplier?._id.toString());
          if (specificSelect) {
            specificSelect.files.push(doc);
          }
        } else {
          supplierFiles.push({ supplier: doc.supplier, files: [doc] });
        }
      }
    }
    // sort the newest files on top
    for (let i = 0; i < supplierFiles.length; i++) {
      supplierFiles[i].files.sort((a: UploadedFileExtended, b: UploadedFileExtended) => {
        return b.date.getTime() - a.date.getTime();
      });
    }
    return supplierFiles;
  };

  getGeneralDocuments = () => {
    const { article } = this.props;
    const files = getSupplierDocuments(article);
    return files.documents.filter((f) => !f.supplier);
  };

  render() {
    const { isAnonymousView, article } = this.props;
    const { selectedSupplier, supplierFiles } = this.state;
    const generalFiles = this.getGeneralDocuments();
    const supplierSelect = this.getSupplierSelectOptions();

    return (
      <div className="card responsive-content-card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
        <div className="card-header border-0">
          <div className="card-title">
            <h3 className="fw-bolder m-0">Documents</h3>
          </div>
        </div>
        <div className="card-body overflow-auto pt-0 mb-5" style={{ maxHeight: "500px" }}>
          {supplierFiles.length > 0 || generalFiles.length > 0 ? (
            <>
              <span className="text-gray-800 fs-5 fw-bolder">General Documents</span>
              {generalFiles.map((d) => {
                if (!(article.hiddenSupplier && d.type === D_SUPPLIERSPECIFICATION))
                  return (
                    <div key={d._id.toString()} className="d-flex flex-stack my-3">
                      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div className="flex-grow-1 me-2">
                          <span className="text-gray-800 fs-6 fw-bolder">{this.resolveDocumentType(d)}</span>
                          <span className="text-muted fw-bold d-block fs-7">Rawbids</span>
                        </div>
                      </div>
                      <a href={resolveFilePath(d.path)} target="_blank" rel="noopener noreferrer">
                        <img alt="pdf" className="w-30px mr-2" src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")} />
                      </a>
                    </div>
                  );
              })}
              {!isAnonymousView && !article.hiddenSupplier && (
                <>
                  <div className="border-bottom-dark-gray py-1 my-10" />
                  <div className="text-gray-800 fs-5 fw-bolder mb-2">Supplier Documents</div>
                  {supplierFiles.length > 0 ? (
                    <>
                      <CustomSelect
                        options={supplierSelect}
                        value={selectedSupplier}
                        onChange={(e: SelectOption<CustomerSupplier>) => this.handleChangeSupplierSelect(e)}
                        menuPlacement="top"
                      />
                      {supplierFiles.map((sf) => (
                        <div key={sf.supplier._id.toString()}>
                          {sf.files.map((f) => (
                            <div key={f._id.toString()} className="d-flex flex-stack my-3">
                              <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div className="flex-grow-1 me-2">
                                  <span className="text-gray-800 fs-6 fw-bolder">{this.resolveDocumentType(f)}</span>
                                  <span className="text-muted fw-bold d-block fs-7">{sf.supplier.name}</span>
                                </div>
                              </div>
                              <a href={resolveFilePath(f.path)} target="_blank" rel="noopener noreferrer">
                                <img
                                  alt="pdf"
                                  className="w-30px mr-2"
                                  src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                                />
                              </a>
                            </div>
                          ))}
                          <div className="border-bottom-dark-gray py-1" />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <span className="text-gray-800 fs-6 text-muted fw-bolder">No Supplier Documents yet</span>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <span className="text-gray-800 fs-6 text-muted fw-bolder">No Documents yet</span>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default CommodityPageDocuments;
