import _ from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../utils/baseUtils";
import { DataContextType, isAnonymousContext } from "../../../context/dataContext";
import { UserData } from "../../../model/userData.types";
import userService from "../../../services/userService";
import { getUserName } from "../../../utils/userUtils";

interface AsideUserPictureProps {
  context: DataContextType;
}

interface AsideUserPictureState {
  userData: UserData;
}

class AsideUserPicture extends PureComponent<AsideUserPictureProps, AsideUserPictureState> {
  constructor(props: AsideUserPictureProps) {
    super(props);
    this.state = { userData: userService.getUserData() };
  }

  componentDidUpdate(prevProps: Readonly<AsideUserPictureProps>) {
    if (!_.isEqual(prevProps.context.userData, this.props.context.userData)) {
      const userData = this.props.context.userData.find((u) => u._id.toString() === userService.getUserId());
      if (userData) this.setState({ userData });
    }
  }

  render() {
    const { context } = this.props;
    const { userData } = this.state;
    if (isAnonymousContext(context)) {
      return (
        <div className="menu-item py-2">
          <span className="menu-link menu-center profile">
            <span className=" me-0 py-2">
              <div
                style={{
                  backgroundImage: `url(${toAbsoluteUrl("/assets/media/avatars/blank_dark.png")})`,
                  width: "50%",
                  minWidth: "50px",
                  minHeight: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                  backgroundColor: "#1f1f1f",
                  borderRadius: 10,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </span>
            <span className="menu-text text-center">Anonymous User</span>
          </span>
        </div>
      );
    }
    return (
      <Link className="menu-item py-2" to="/profile">
        <div className="menu-item py-2 d-none d-lg-block">
          <span className="menu-link menu-center profile cursor-pointer">
            <span className=" me-0 py-2">
              <div
                style={{
                  backgroundImage: `url(${
                    userData.image ? userData.image : toAbsoluteUrl("/assets/media/avatars/blank_dark.png")
                  })`,
                  width: "50%",
                  minWidth: "50px",
                  minHeight: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                  backgroundColor: "#1f1f1f",
                  borderRadius: 10,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
                className="text-center hover-show-text"
              >
                <div className="hover-text m-0" style={{ height: "50px", width: "50px", borderRadius: 10 }}>
                  <small>Profile Settings</small>
                </div>
              </div>
            </span>
            <span className="menu-text text-center">{userData ? getUserName(userData) : "Anonymous User"}</span>
          </span>
        </div>
      </Link>
    );
  }
}

export default AsideUserPicture;
