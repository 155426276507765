import React from "react";
import { toast } from "react-toastify";
import { DataContextAnonymousType, DataContextCustomerType } from "../../context/dataContext";
import userService from "../../services/userService";
import { upsertFavorites } from "../../utils/favoritesUtils";

interface FavoriteStarProps {
  favorite?: string;
  selected?: boolean;
  wrapperClasses?: string;
  iconClasses?: string;
  context?: DataContextCustomerType | DataContextAnonymousType;
  showOnlyIfSelected?: boolean;
  onHoverStar?: (type: "enter" | "leave") => void;
  disabled?: boolean;
}

const FavoriteStar: React.FunctionComponent<FavoriteStarProps> = ({
  wrapperClasses,
  iconClasses,
  favorite,
  selected,
  context,
  showOnlyIfSelected,
  onHoverStar,
  disabled,
}) => {
  const checkIsSelected = (context: DataContextCustomerType | DataContextAnonymousType) => {
    if (!favorite) return false;
    const companyFavorites = context.favorites.find((f) => f.company === userService.getCompany());
    if (!companyFavorites) return false;
    else return companyFavorites.favorites.includes(favorite);
  };
  const isSelected = selected !== undefined ? selected : context ? checkIsSelected(context) : false;
  if (!isSelected && showOnlyIfSelected) return <div />;
  const handleToggleFavorite = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!favorite) return;
    const res = await upsertFavorites(favorite, isSelected);
    if (!(res && (res.modifiedCount > 0 || res.upsertedId))) {
      toast.error("Updating favorites failed");
    }
  };

  return (
    <div className={wrapperClasses ?? ""}>
      <i
        className={"fa fa-star cursor-pointer " + (isSelected ? "star-lit " : "star-hover ") + (iconClasses ?? "")}
        onClick={favorite && !disabled ? handleToggleFavorite : undefined}
        onMouseEnter={onHoverStar ? () => onHoverStar("enter") : undefined}
        onMouseLeave={onHoverStar ? () => onHoverStar("leave") : undefined}
      />
    </div>
  );
};

export default FavoriteStar;
