import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CompanyExtended } from "../../../model/company.types";
import { DataContextInternalType } from "../../../context/dataContext";
import { TabDefinition } from "../../common/CustomTypes";
import CustomerPageGeneral from "./tabPanels/CustomerPageGeneral";
import CustomerPageInvoices from "./tabPanels/CustomerPageInvoices";
import CustomerPageOrders from "./tabPanels/CustomerPageOrders";
import CustomerPageHistory from "./tabPanels/CustomerPageHistory";

interface CustomerPageTabPanelProps {
  company: CompanyExtended;
  context: DataContextInternalType;
}

interface CustomerPageTabPanelState {
  currentTab: string;
}

class CustomerPageTabPanel extends PureComponent<CustomerPageTabPanelProps, CustomerPageTabPanelState> {
  constructor(props: CustomerPageTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getDefaultTab() };
  }

  getDefaultTab = () => {
    const { company, context } = this.props;
    const hasOrders = context.customerOrder.some((o) => o.company === company._id.toString());
    if (!hasOrders) return "general";
    return this.getTabDefinitions()[0].name;
  };

  getTabDefinitions = (): Array<TabDefinition> => {
    const { company, context } = this.props;
    return [
      {
        name: "orders",
        tabContent: "Orders",
        component: <CustomerPageOrders company={company} context={context} />,
      },
      {
        name: "general",
        tabContent: "General Settings",
        component: <CustomerPageGeneral company={company} context={context} />,
      },
      {
        name: "invoices",
        tabContent: "Invoices",
        component: <CustomerPageInvoices company={company} context={context} />,
      },
      {
        name: "history",
        tabContent: "History",
        component: <CustomerPageHistory company={company} context={context} />,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTab = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  render() {
    const { company } = this.props;
    const { currentTab } = this.state;
    const tabs = this.getTabDefinitions();
    return (
      <Tab.Container id="customerTabs" activeKey={currentTab} onSelect={this.handleTab}>
        <div className="flex-lg-row-fluid ms-lg-15 order-0 order-xl-0 mt-2 mt-xl-0">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(company)) && (
                  <li key={t.name + "_link"} className="nav-item cursor-pointer">
                    <Nav.Link
                      role="tab"
                      id={t.name}
                      eventKey={t.name}
                      active={currentTab === t.name}
                      className="text-active-white pb-4 "
                    >
                      {t.tabContent}
                    </Nav.Link>
                  </li>
                )
            )}
            {
              <li className="nav-item ms-auto">
                <Link
                  className={"btn btn-outline btn-outline-light "}
                  to={`/createCustomerOrder/customer/${company._id.toString()}`}
                >
                  New Order
                </Link>
              </li>
            }
          </ul>
          <Tab.Content>
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(company)) && (
                  <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                    {t.component}
                  </Tab.Pane>
                )
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}
export default CustomerPageTabPanel;
