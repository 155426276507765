import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType, isAnonymousContext } from "../../../context/dataContext";
import { getArticleProperty } from "../../../utils/commodityUtils";
import { PropertyType, resolveProperties } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import CountryWidget from "../../common/CountryWidget";
import FavoriteStar from "../../common/FavoriteStar";
import Tooltip from "../../common/Tooltip";
import { hasPricesInAnonymousView } from "../../../utils/anonymousViewUtils";
import { CustomerFinishedProduct } from "../../../model/customer/customerFinishedProduct.types";

interface CustomerFinishedProductListingRowProps extends RouteComponentProps {
  finishedProduct: CustomerFinishedProduct;
  context: DataContextCustomerType | DataContextAnonymousType;
}

const CustomerFinishedProductListingRow: React.FunctionComponent<CustomerFinishedProductListingRowProps> = ({
  finishedProduct,
  context,
  history,
}) => {
  const properties = resolveProperties(finishedProduct.properties, context.property);
  const category = getArticleProperty(properties, PropertyType.CATEGORY) as Property | null;
  const composition = getArticleProperty(properties, PropertyType.COMPOSITION) as Property | null;
  const forwardFinishedProduct = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(`finishedProduct/${finishedProduct._id.toString()}`);
  };

  const isAC = isAnonymousContext(context);
  const blurPrice = isAC && !hasPricesInAnonymousView(finishedProduct._id.toString(), context.configuration);

  return (
    <tr
      onClick={forwardFinishedProduct}
      className="cursor-pointer-row border-bottom-dark-gray"
      style={{
        borderWidth: "20px",
        opacity: finishedProduct.fromPrice ? 1 : 0.5,
      }}
    >
      <td className="align-middle">
        {!isAC && (
          <Tooltip tooltipText="Mark as Favorite" delay={{ show: 500, hide: 0 }}>
            <span>
              <FavoriteStar favorite={finishedProduct._id.toString()} context={context} />
            </span>
          </Tooltip>
        )}
      </td>
      <td className="align-middle">
        <span className="text-white fs-5 d-inline-block">{finishedProduct.title.en}</span>
        {finishedProduct.organic && <i title="organic" className="fas fa-leaf text-success d-inline-block ml-2" />}
        <span className="text-muted d-block" style={{ fontSize: "0.85rem" }}>
          {finishedProduct.subtitle.en}
        </span>
      </td>
      <td className="align-middle">
        {category && <span className="badge badge-gray fw-bolder px-2 py-2 text-white">{category.name.en}</span>}
      </td>
      <td className="align-middle text-white">{composition?.name.en ?? "Not specified"}</td>
      <td className="align-middle text-white text-center">
        <CountryWidget countryCode={finishedProduct.country.code} />
      </td>
      <td className="align-middle text-center" colSpan={3}>
        <span className="text-muted d-block text-nowrap" onClick={blurPrice ? (e) => e.stopPropagation() : undefined}>
          Price on request
        </span>
      </td>
    </tr>
  );
};

export default withRouter(CustomerFinishedProductListingRow);
