import { BSON } from "realm-web";
import { doFuseSearch, round } from "./baseUtils";
import { Company, CompanyExtended, CompanySnapshot } from "../model/company.types";
import userService from "../services/userService";
import { UserData } from "../model/userData.types";
import { callFunction, CUSTOMERSTATISTICS, getDb } from "../services/dbService";
import { Address } from "../model/commonTypes";
import { CO_ARCHIVED, CO_CANCELED, CO_STATES, CustomerOrder } from "../model/customerOrder.types";
import { FIRSTDUNNING, I_STATE, Invoice, REMINDER, SECONDDUNNING } from "../model/invoice.types";
import { convertCurrency, Currencies } from "./currencyUtils";
import { CustomerStatistics } from "../model/statistics/customerStatistics.types";
import { I_PAYMENTTARGETS } from "./invoiceUtils";
import { Forwarder, ForwarderExtended } from "../model/forwarder.types";
import { SupplierType } from "./supplierUtils";
import { SelectOption } from "../components/common/CustomSelect";

export const C_GENERALFILTEROPTIONS = [
  { value: "openOrders", label: "Open Orders", isDisabled: true },
  { value: "overdueOrders", label: "Overdue Orders", isDisabled: true },
  { value: "openInvoices", label: "Open Invoices", isDisabled: true },
  { value: "overdueInvoices", label: "Overdue Invoices", isDisabled: true },
  { value: "activeWithin3M", label: "Active within 3M", isDisabled: true },
  { value: "inactiveSince3M", label: "Inactive since 3M", isDisabled: true },
  { value: "goodRating", label: "Good Rating" },
  { value: "badRating", label: "Bad Rating" },
];

export const C_SORTOPTIONS = [
  { value: "name", label: "Name" },
  { value: "openOrdersVolume", label: "Open Orders Volume", isDisabled: true },
  { value: "openInvoiceVolume", label: "Open Invoice Volume", isDisabled: true },
  { value: "rating", label: "Rating" },
  { value: "activity", label: "Activity", isDisabled: true },
  { value: "creationDate", label: "Creation Date", isDisabled: true },
];

export enum LabelDesign {
  RAWBIDS = "Rawbids",
  CLEAN = "Clean",
}

export enum CompanyMails {
  INVOICE = "invoice",
}

export const LABEL_CLEAN_OPTION = { value: LabelDesign.CLEAN, label: "Clean" };
export const LABEL_RAWBIDS_OPTION = { value: LabelDesign.RAWBIDS, label: "Rawbids" };

export const C_LABEL_DESIGN_OPTIONS: Array<SelectOption> = [LABEL_RAWBIDS_OPTION, LABEL_CLEAN_OPTION];

// Backend functions relating to company
const UPSERTCOMPANY = "upsertCompany";
const INSERTMANYCUSTOMERSANDRELATEDDOCUMENTS = "insertManyCustomersAndRelatedDocuments";

/**
 * Inserts a new company into the database.
 * @param company Company with users that should be inserted into the database
 * @param newPersons UserData of newly added persons
 * @returns { Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> } Result of the function
 */
export async function insertCompany(
  company: Company,
  newPersons: Array<UserData>
): Promise<Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | false> {
  return (await callUpsertCompany(company, true, newPersons)) as
    | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId>
    | false;
}

/**
 * Updates an existing company inside the database.
 * @param company Company that should be updated inside the database
 * @param companyId Optional id of company if it is not contained in company object
 * @returns { Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> } Result of the function
 */
export async function updateCompany(
  company: Partial<Company>,
  companyId?: BSON.ObjectId
): Promise<Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false> {
  if (companyId) company._id = companyId;
  return (await callUpsertCompany(company, false)) as Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false;
}

/**
 * Calls the upsert company function in backend.
 * @param company Company that should be upsert
 * @param insert True for insert, else update
 * @param newPersons Optional, list of users that should be added
 * @returns { Promise<false | Realm.Services.MongoDB.InsertOneResult | Realm.Services.MongoDB.UpdateResult> } Result of the function
 */
async function callUpsertCompany(
  company: Partial<Company | CompanyExtended>,
  insert: boolean,
  newPersons?: Array<UserData>
): Promise<
  false | Realm.Services.MongoDB.InsertOneResult<BSON.ObjectId> | Realm.Services.MongoDB.UpdateResult<BSON.ObjectId>
> {
  return callFunction(UPSERTCOMPANY, [company, insert, newPersons]);
}

/**
 * Get all similar companies
 * @param company the company to get similar ones for
 * @param companies list of all companies
 * @returns {Array<Company>} list of similar companies
 */
export const getSimilarCompanies = (company: Company, companies: Array<Company>): Array<Company> => {
  // Threshold 0.2 is always subject to change
  const threshold = 0.2;
  let similarCompanies = doFuseSearch(companies, company.name, ["name"], {
    threshold,
  });
  // only exact match here
  similarCompanies = similarCompanies.concat(doFuseSearch(companies, company.vat, ["vat"], { threshold: 0 }));
  // Remove company itself
  similarCompanies = similarCompanies.filter((c) => c._id.toString() !== company._id.toString());
  // Remove duplicates
  return Array.from(new Set(similarCompanies));
};

/**
 * Get the orders for the given customer company as well as the in time and late orders.
 * @param company ID of the company whose orders shall be listed
 * @param orders Orders that should be filtered
 * @param onlyOpen Optional, if set orders that are archived are ignored
 * @return { [openOrders: Array<CustomerOrder>, inTimeOrders: Array<CustomerOrder>, lateOrders: Array<CustomerOrder>] } Orders, in time and late of the company
 */
export function getCustomerOrders(
  company: BSON.ObjectId,
  orders: Array<CustomerOrder>,
  onlyOpen?: boolean
): [openOrders: Array<CustomerOrder>, inTimeOrders: Array<CustomerOrder>, lateOrders: Array<CustomerOrder>] {
  const filter = onlyOpen ? [CO_CANCELED, CO_ARCHIVED] : [CO_CANCELED];
  const customerOrders = orders.filter(
    (o) => !(filter as Array<CO_STATES>).includes(o.state) && o.company === company.toString()
  );
  const [inTimeOrders, lateOrders] = customerOrders.reduce(
    (result, cO) => {
      result[
        (cO.deliveryDate && cO.deliveryDate < cO.targetDate) || (!cO.deliveryDate && cO.targetDate > new Date()) ? 0 : 1
      ].push(cO);
      return result;
    },
    [[], []] as Array<Array<CustomerOrder>>
  );
  return [customerOrders, inTimeOrders, lateOrders];
}

/**
 * Get the ratio of customer orders that were delivered in time. For orders without a delivery date it is shown if
 * their eta is missed.
 * @param company ID of the company that should be checked
 * @param orders Orders that should be checked
 * @returns { number } Ratio of deliveries in time. If no orders exist this function returns -1.
 */
export function getCustomerOrderDeliveriesInTime(company: BSON.ObjectId, orders: Array<CustomerOrder>): number {
  const nonCanceledOrders = orders.filter((o) => o.company === company.toString() && o.state !== CO_CANCELED);
  if (nonCanceledOrders.length === 0) return -1;
  const now = new Date();
  const lateClosedOrders = nonCanceledOrders.filter(
    (cO) => (cO.deliveryDate && cO.deliveryDate > cO.targetDate) || (!cO.deliveryDate && cO.targetDate < now)
  );
  return round(1 - lateClosedOrders.length / nonCanceledOrders.length, 2);
}

/**
 * Get the open invoices of a company as well as to total open amount.
 * @param company ID of the company whose invoices shall be listed
 * @param invoices Invoices that should be filtered
 * @param currency Target currency
 * @param currencies List of currencies
 * @returns { [openInvoices: Array<Invoice>, openAmount: number] } List of open invoices and their amount
 */
export function getOpenInvoices(
  company: BSON.ObjectId,
  invoices: Array<Invoice>,
  currency: string,
  currencies: Currencies
): [openInvoices: Array<Invoice>, openAmount: number] {
  const openInvoices = invoices.filter(
    (i) => i.company._id.toString() === company.toString() && [I_STATE.OPEN, I_STATE.PARTLY_PAID].includes(i.state)
  );
  const openAmount = openInvoices.reduce(
    (sum, i) =>
      sum +
      (i.currency === currency ? i.total : convertCurrency(i.total, i.currency, currency, currencies)) -
      (i.currency === currency
        ? i.payments.reduce((pSum, p) => pSum + p.amount, 0)
        : convertCurrency(
            i.payments.reduce((pSum, p) => pSum + p.amount, 0),
            i.currency,
            currency,
            currencies
          )),
    0
  );
  return [openInvoices, openAmount];
}

/**
 * Inserts the given users and companies
 * @param users List of users
 * @param companies List of companies
 * @returns { Promise<boolean> } Result of the call
 */
export async function insertCompaniesAndRelatedDocuments(
  users: Array<UserData>,
  companies: Array<Company>
): Promise<boolean> {
  return callFunction(INSERTMANYCUSTOMERSANDRELATEDDOCUMENTS, [users, companies]);
}

/**
 * Get a default company. Note: This company has an invalid primary person!
 * @param name optional name
 * @param vat optional vat
 * @param address optional address
 * @param mail optional email address
 * @param phone optional phone number
 * @returns {Company} empty company
 */
export const getDefaultCompany = (
  name?: string,
  vat?: string,
  address?: Address,
  mail?: string,
  phone?: string
): Company => {
  return {
    _id: new BSON.ObjectId(),
    name: name || "",
    vat: vat || "",
    internalContact: userService.getUserId(),
    rating: -1, // invalid rating as default
    creditLimit: 0,
    paymentTerms: { paymentTarget: I_PAYMENTTARGETS[4].label, paymentTargetConditions: "" },
    mail: mail || "",
    phone: phone || "",
    address: address ? [address] : [],
    primaryPerson: "",
    persons: [],
    activated: true,
    disabled: false,
    notes: "",
  };
};

/**
 * Determines if the given actor is a company
 * @param company Actor that should be checked
 * @returns { boolean } Returns true if the actor has type Company
 */
export function isCompany(company: Company | SupplierType | Forwarder | ForwarderExtended): company is Company {
  return "creditLimit" in company;
}

/**
 * Calculates the reliability of the customer. Customers that got never received a dunning are the most reliable ones.
 * @param invoices Invoices of the customer
 * @returns { number } Percentage of invoices without dunning.
 */
export function calculateReliability(invoices: Array<Invoice>): number {
  // #TODO: This should be moved to backend - RB-334
  let withDunning = 0;
  for (let i = 0; i < invoices.length; i++) {
    const inv = invoices[i];
    for (let j = 0; j < inv.reminders.length; j++) {
      const r = inv.reminders[j];
      if (
        ([FIRSTDUNNING, SECONDDUNNING] as Array<typeof REMINDER | typeof FIRSTDUNNING | typeof SECONDDUNNING>).includes(
          r.type
        )
      ) {
        withDunning++;
        break;
      }
    }
  }
  return ((invoices.length - withDunning) / invoices.length) * 100;
}

/**
 * Get customer statistics
 * @param customerId id of customer to get statistics for
 * @param statistics optional, list of statistic names to get
 * @returns {Promise<Partial<CustomerStatistics> | undefined>} statistics if found for customer
 */
export async function getCustomerStatistics(
  customerId: string | BSON.ObjectId,
  statistics?: Array<string>
): Promise<Partial<CustomerStatistics>> {
  const db = getDb();
  const collection = db?.collection(CUSTOMERSTATISTICS);
  const projection: { [field: string]: number } = {};
  if (statistics) statistics.forEach((s) => (projection[s] = 1));
  return collection?.findOne({ company: customerId.toString() }, { projection: statistics ? projection : undefined });
}

/**
 * Retrieve the payment target for the given customer company
 * @param company Customer whose payment target should be retrieved
 * @returns {number|undefined} Payment target if found, else undefined
 */
export function getCustomerPaymentTarget(company: Company): number | undefined {
  // Payment target might be custom or empty - then we can't give a clear response here
  if (company.paymentTerms && ["custom", ""].includes(company.paymentTerms?.paymentTarget)) return undefined;
  // Payment terms are the source of truth, for older companies it might not exist yet. Since the target includes " days"
  // we have to split
  return Number(
    company.paymentTerms?.paymentTarget ? company.paymentTerms.paymentTarget.split(" ")[0] : company.paymentTarget
  );
}

/**
 * Generate a company snapshot from a company.
 * @param company Company whose snapshot should be generated
 * @returns {CompanySnapshot} Snapshot of the company
 */
export function getCompanySnapshot(company: Company): CompanySnapshot {
  return { _id: company._id, name: company.name, snapshotDate: new Date() };
}
