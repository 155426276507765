import React, { useEffect, useState } from "react";
import { getArticlesPriceRange, getFormattedPriceRange, getPriceRange } from "../../../utils/commodityUtils";
import { DataContextInternalType } from "../../../context/dataContext";
import PriceRangeWidget from "../../common/PriceRangeWidget";
import { StatsWidget } from "../../common/StatsWidget";
import { CommodityStatistics } from "../../../model/statistics/commodityStatistics.types";
import { formatCurrency, formatDateTime } from "../../../utils/baseUtils";
import { BASE_CURRENCY, USD } from "../../../utils/currencyUtils";
import { getDaysBetween } from "../../../utils/dateUtils";
import { getRatingColor, getStatisticText } from "../../../utils/statisticUtils";
import Tooltip from "../../common/Tooltip";
import { isFinishedProduct } from "../../../utils/finishedProductUtils";
import { ArticleExtended, getArticleStatistics } from "../../../utils/productArticleUtils";

interface CommodityPageStatsProps {
  article: ArticleExtended;
  context: DataContextInternalType;
}

const CommodityPageStats: React.FunctionComponent<CommodityPageStatsProps> = React.memo(({ article, context }) => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<Partial<CommodityStatistics> | undefined>(undefined);
  const priceRange = getPriceRange(article, context.currencies, USD);
  const formattedPriceRange = priceRange ? getFormattedPriceRange(priceRange) : null;
  const overallPriceRange = getArticlesPriceRange(context.commodity, context.currencies);

  useEffect(() => {
    getArticleStatistics(article._id, ["generalStatistics"]).then((res) => {
      if (res && "generalStatistics" in res) {
        setStatistics(res);
      }
      setLoading(false);
    });
  }, []);

  const inactive = !statistics?.generalStatistics;

  return (
    <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
      <div className="card-header border-0">
        <div className="card-title">
          <h3 className="fw-bolder m-0">Information</h3>
        </div>
      </div>
      <div className="card-body pt-2">
        <div className="py-2">
          <div className="fs-6">
            <div className="text-center">
              <span className="text-gray-800 text-hover-primary fs-6 fw-bolder mb-2">Price Range</span>
              {priceRange && overallPriceRange && formattedPriceRange ? (
                <PriceRangeWidget
                  priceRange={priceRange}
                  overallPriceRange={overallPriceRange}
                  formattedPriceRange={formattedPriceRange}
                />
              ) : (
                <div>-</div>
              )}
            </div>
          </div>
          {article && (
            <Tooltip
              show={inactive ? false : undefined}
              tooltipText={
                <div className="text-white text-left">
                  Last Update:{" "}
                  {statistics?.generalStatistics?.lastUpdate
                    ? formatDateTime(statistics?.generalStatistics?.lastUpdate)
                    : "-"}
                </div>
              }
            >
              <div>
                <StatsWidget
                  title={"Popularity"}
                  text={getStatisticText(statistics?.generalStatistics?.popularity.rating, true)}
                  subtext={`Top ${statistics?.generalStatistics?.popularity.percentage || "-"}% out of ${
                    statistics?.generalStatistics?.popularity.totalCommodities || "-"
                  } ${isFinishedProduct(article) ? "Finished products" : "Commodities"}`}
                  color={getRatingColor(statistics?.generalStatistics?.popularity.rating)}
                  loading={loading}
                  inactive={inactive}
                />
                <StatsWidget
                  title={"Turnover"}
                  text={getStatisticText(statistics?.generalStatistics?.turnover.rating, true)}
                  subtext={`Top ${statistics?.generalStatistics?.turnover.percentage || "-"}% with ${
                    statistics?.generalStatistics?.turnover.total !== undefined
                      ? formatCurrency(statistics?.generalStatistics?.turnover.total, BASE_CURRENCY)
                      : "-"
                  } total turnover`}
                  color={getRatingColor(statistics?.generalStatistics?.turnover.rating)}
                  loading={loading}
                  inactive={inactive}
                />
                <StatsWidget
                  title={"Data Quality"}
                  text={getStatisticText(statistics?.generalStatistics?.dataQuality.rating)}
                  subtext={
                    statistics?.generalStatistics?.dataQuality.rating !== undefined
                      ? statistics.generalStatistics.dataQuality.rating === 1
                        ? "Data complete"
                        : statistics.generalStatistics.dataQuality.rating === 0
                        ? "Some data is missing"
                        : "Data incomplete"
                      : "No analysis available"
                  }
                  color={getRatingColor(statistics?.generalStatistics?.dataQuality.rating)}
                  loading={loading}
                  inactive={inactive}
                />
                <StatsWidget
                  title={"Price Gradient"}
                  text={getStatisticText(statistics?.generalStatistics?.priceGradient.rating)}
                  subtext={`${statistics?.generalStatistics?.priceGradient.prices || "-"} Prices from ${
                    statistics?.generalStatistics?.priceGradient.suppliers || "-"
                  } suppliers`}
                  color={getRatingColor(statistics?.generalStatistics?.priceGradient.rating)}
                  loading={loading}
                  inactive={inactive}
                />
                <StatsWidget
                  title={"Price Updates"}
                  text={getStatisticText(statistics?.generalStatistics?.priceUpdates.rating)}
                  subtext={
                    statistics?.generalStatistics?.priceUpdates.lastPriceUpdate
                      ? `Updated ${Math.abs(
                          getDaysBetween(new Date(), statistics.generalStatistics.priceUpdates.lastPriceUpdate, true)
                        )} days ago`
                      : "No analysis available"
                  }
                  color={getRatingColor(statistics?.generalStatistics?.priceUpdates.rating)}
                  loading={loading}
                  inactive={inactive}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
});

export default CommodityPageStats;
