import React from "react";
import { HashLink } from "react-router-hash-link";
import { TableOfContentsEntry } from "./CustomTypes";

interface TableOfContentsProps {
  contents: Array<TableOfContentsEntry>;
}

const TableOfContents: React.FC<TableOfContentsProps> = ({ contents }) => {
  return (
    <div className="card bg-white mb-5">
      <div className="card-header border-0 mt-5 mb-2 d-block" style={{ minHeight: 0 }}>
        <div className="card-title flex-column">
          <h4>Table of Contents</h4>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="pt-0">
          <div className="row">
            {contents.map(({ href, label }, idx) => (
              <div key={idx} className="col-12 col-sm-6 col-md-4 col-xl-3">
                <HashLink className="text-white custom-link" to={href}>
                  {label}
                </HashLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableOfContents;
