import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import SampleOrderSummary from "./SampleOrderSummary";
import SampleOrderTabPanel from "./SampleOrderTabPanel";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isInternalContext,
} from "../../../../context/dataContext";
import { getDocFromCollection } from "../../../../utils/baseUtils";
import { getDocumentDB, SAMPLEORDER } from "../../../../services/dbService";
import { SampleOrder, SampleOrderExtended } from "../../../../model/sampleOrder.types";
import { CustomerSampleOrderExtended } from "../../../../model/customer/customerSampleOrder.types";
import SampleRating from "../../customer/sampleOrder/SampleRating";
import { extendCustomerSampleOrder, extendSampleOrder } from "../../../../utils/sampleOrderUtils";

interface SampleOrderPageParams {
  id: string;
}

interface SampleOrderPageProps extends RouteComponentProps<SampleOrderPageParams> {
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface SampleOrderPageState {
  order?: SampleOrderExtended | CustomerSampleOrderExtended;
}

class SampleOrderPage extends PureComponent<SampleOrderPageProps, SampleOrderPageState> {
  _isMounted = false;
  constructor(props: SampleOrderPageProps) {
    super(props);
    this.state = {
      order: this.getSampleOrder(props),
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.order) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/orders");
      return;
    }
    this._isMounted = true;
    const order = await getDocumentDB<SampleOrder>(SAMPLEORDER, id);
    if (!order) {
      console.error("No order could be loaded for id", id);
      toast.error("The requested order could not be loaded");
      history.push("/orders");
      return;
    }
    context.addDocuments(SAMPLEORDER, [order]);
    const orderExtended = isInternalContext(context)
      ? extendSampleOrder(order, context)
      : extendCustomerSampleOrder(order, context);
    if (this._isMounted) this.setState({ order: orderExtended });
  };

  componentDidUpdate(prevProps: Readonly<SampleOrderPageProps>) {
    const { match } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const order = this.getSampleOrder(this.props);
      if (order && !_.isEqual(order, this.state.order)) {
        this.setState({
          order,
        });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Get the initial sample order data
   * @param props the components properties
   * @returns {CustomerSampleOrderExtended | SampleOrderExtended | undefined} a sample order object or undefined
   */
  getSampleOrder = (props: SampleOrderPageProps): CustomerSampleOrderExtended | SampleOrderExtended | undefined => {
    const { context, match } = props;
    // getDocFromCollection is double here due to typing issues
    if (isInternalContext(context)) {
      const order = getDocFromCollection(context.sampleOrder, match.params.id);
      if (order) return extendSampleOrder(order, context);
    } else {
      const order = getDocFromCollection(context.sampleOrder, match.params.id);
      if (order) return extendCustomerSampleOrder(order, context);
    }
  };

  render() {
    const { context } = this.props;
    const { order } = this.state;
    if (!order) return <div />;

    return (
      <div>
        <div className="content d-flex flex-column flex-column-fluid">
          <div className="post d-flex flex-column-fluid">
            <div className="container-xxl responsive-aside-container">
              <div className="d-flex flex-column flex-xl-row">
                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10 order-1 order-xl-0">
                  {isInternalContext(context) ? <SampleOrderSummary order={order} /> : <SampleRating order={order} />}
                </div>
                <SampleOrderTabPanel order={order} context={context} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SampleOrderPage;
