import React from "react";
import { SupplierOrderTimelineEntry } from "../../../model/supplierOrder.types";
import { CustomerOrderTimelineEntry } from "../../../model/customerOrder.types";
import { EXTENDED_ORDER_TYPES, mergeAndSortOrderTimelines, ORDER_TYPES } from "../../../utils/orderUtils";
import OrderHistoryEntry from "../../orders/common/OrderHistoryEntry";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
} from "../../../context/dataContext";
import { SampleOrderTimelineEntry } from "../../../model/sampleOrder.types";

interface CommodityPageOrderUpdatesProps {
  orders: Array<ORDER_TYPES | EXTENDED_ORDER_TYPES>;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
  lessWidth?: boolean;
}

const CommodityPageOrderUpdates: React.FunctionComponent<CommodityPageOrderUpdatesProps> = ({
  orders,
  context,
  lessWidth,
}) => {
  const timeline = mergeAndSortOrderTimelines<
    ORDER_TYPES | EXTENDED_ORDER_TYPES,
    SupplierOrderTimelineEntry | CustomerOrderTimelineEntry | SampleOrderTimelineEntry
  >(orders);

  const recentlyUpdated = Array.from(
    new Set(timeline.filter((t) => t.recent).map((t) => t.order._id.toString()))
  ).length;

  return (
    <div className="card responsive-content-card bg-white mt-5" style={{ maxHeight: "40vh" }}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">Updates</span>
          <span className="text-muted fw-bold fs-7">{recentlyUpdated} Orders were updated recently</span>
        </h3>
      </div>
      <div className="card-body scroll-y pb-0 mb-4">
        {timeline.slice(0, 30).map((entry) => (
          <OrderHistoryEntry
            key={entry.order._id.toString() + entry.timelineEntry._id.toString()}
            entry={entry}
            context={context}
            showOrder={true}
            lessWidth={lessWidth}
            orderLink={true}
          />
        ))}
      </div>
    </div>
  );
};

export default CommodityPageOrderUpdates;
