import _ from "lodash";
import { toast } from "react-toastify";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { DataContextInternalType } from "../../../context/dataContext";
import { Commodity } from "../../../model/commodity.types";
import { Company } from "../../../model/company.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import CustomerTerms, { CustomerTermOptions } from "../../common/CustomerTerms";
import { I_PAYMENTTARGETS } from "../../../utils/invoiceUtils";
import { CO_STANDARDINCOTERM, getCustomerTermOptionFromCompany } from "../../../utils/customerOrderUtils";
import { SelectOption } from "../../common/CustomSelect";
import { Input } from "../../common/Input";
import DateInput from "../../common/DateInput";
import { callPushToArray, getDocFromCollection, round } from "../../../utils/baseUtils";
import { Textarea } from "../../common/Textarea";
import { CUSTOMER_BASE_CURRENCY } from "../../../utils/currencyUtils";
import {
  CC_STATE,
  CustomerContractExtended,
  CustomerContractTimelineType,
  ValidityPeriod,
} from "../../../model/customerContract.types";
import userService from "../../../services/userService";
import CalendarWeekSelector from "../../common/CalendarWeekSelector";
import {
  CC_CONTRACTCONFIRMATION,
  getCustomerContractTimelineEntry,
  insertCustomerContract,
} from "../../../utils/customerContractUtils";
import { CO_FOOTER_HTML, createPDF } from "../../../utils/pdfUtils";
import { createContractConfirmationHTML } from "../../../utils/pdf/customerContractConfirmationGenerationUtils";
import { STANDARDTEXTSHORT } from "../../../utils/pdf/templateUtils";
import { resolveFilePath } from "../../../utils/fileUtils";
import { CUSTOMERCONTRACT, CUSTOMERREQUEST, transaction, UpdateAction } from "../../../services/dbService";
import { getFormattedCCTerms } from "../../../utils/orderUtils";
import {
  ContractData,
  CustomerRequest,
  CustomerRequestState,
  CustomerRequestTimelineTypes,
  CustomerRequestType,
} from "../../../model/customerRequest.types";
import { Supplier } from "../../../model/supplier.types";
import { getCustomerRequestTimelineEntry, getValidityPeriodFromRequest } from "../../../utils/customerRequestUtils";
import CustomerRequestAdditionalInformation from "../../common/internal/CustomerRequestAdditionalInformation";
import CreateCustomerOrderContractGeneralSettings from "../../common/internal/CreateCustomerOrderContractGeneralSettings";
import CreateCustomerOrderContractCalculation from "../../common/internal/CreateCustomerOrderContractCalculation";
import { ArticleExtended, formatArticleUnit, getArticleSnapshot } from "../../../utils/productArticleUtils";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { UserData } from "../../../model/userData.types";
import { isFinishedProduct } from "../../../utils/finishedProductUtils";
import { extendCommodity, extendFinishedProduct, reduceCustomerContract } from "../../../utils/dataTransformationUtils";
import { LabelDesign } from "../../../utils/companyUtils";

export interface CreateCustomerContractParams {
  id?: string;
  type?: string;
}

interface CreateCustomerContractProps extends RouteComponentProps<CreateCustomerContractParams> {
  context: DataContextInternalType;
}

interface CreateCustomerContractState {
  saving: "draft" | "order" | false;
  targetDate: Date | null;
  selectedArticle?: ArticleExtended;
  customer?: Company;
  preferredSupplier?: Supplier;
  totalAmount: number;
  minimumCallQuantity: number;
  purchasePrice: number;
  pricePerUnit: number;
  discount: number;
  validityPeriod: ValidityPeriod;
  noteInternal: string;
  customerReference: string;
  request?: CustomerRequest; // id of request
  terms: CustomerTermOptions;
}

class CreateCustomerContract extends PureComponent<CreateCustomerContractProps, CreateCustomerContractState> {
  constructor(props: CreateCustomerContractProps) {
    super(props);
    this.state = this.getDefaultState();
  }

  componentDidMount = () => {
    const { match, context } = this.props;
    const { terms } = this.state;
    const { type, id } = match.params;
    if (!type || !id || !BSON.ObjectId.isValid(id)) {
      console.info("No type or id given");
      return;
    }
    if (type === "request") {
      const request = getDocFromCollection(context.customerRequest, id);
      if (!request) {
        toast.error("Request could not be loaded. This may indicate that the request was already handled.");
        return;
      }
      if (request.type !== CustomerRequestType.CONTRACTREQUEST) {
        toast.error("Request is not a contract request. Aborting");
        return;
      }
      const { commodity, company, amount, customerReference } = request;
      const requestData = request.requestData as ContractData;
      const fullCommodity = getDocFromCollection(context.commodity, commodity._id);
      const fullCompany = getDocFromCollection(context.company, company._id);
      const fullSupplier = requestData.supplier
        ? getDocFromCollection(context.supplier, requestData.supplier)
        : undefined;
      if (!fullCommodity || !fullCompany)
        toast.warn(`Commodity or customer could not be found. Please make sure to select the correct one manually.`);
      const newTerms = fullCompany
        ? getCustomerTermOptionFromCompany(
            fullCompany,
            terms.title || `Contract for ${fullCompany.name}`,
            terms.id.toString()
          )
        : terms;
      this.setState({
        selectedArticle: fullCommodity ? extendCommodity(fullCommodity, context) : undefined,
        customer: fullCompany,
        totalAmount: amount,
        customerReference,
        request,
        preferredSupplier: fullSupplier,
        minimumCallQuantity: requestData.minimumCallQuantity,
        validityPeriod: getValidityPeriodFromRequest(new Date(), requestData.period),
        terms: newTerms,
      });
    } else {
      console.error(`Type ${type} is not available`);
    }
  };

  handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => this.setState({ noteInternal: e.target.value });

  handleChangeDate = (date: Date) => this.setState({ targetDate: date });
  handleResetDate = () => this.setState({ targetDate: null });

  handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === "number" ? +e.target.value : e.target.value;
    // @ts-ignore
    this.setState({ [e.target.name]: value });
  };

  handleChangeValidityDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { request } = this.state;
    let validityPeriod = _.cloneDeep(this.state.validityPeriod);
    const date = new Date(e.target.value);
    if (!date) return;
    if (request && e.target.name === "start") {
      validityPeriod = getValidityPeriodFromRequest(date, (request.requestData as ContractData).period);
    } else {
      _.set(validityPeriod, e.target.name, date);
    }
    this.setState({ validityPeriod });
  };

  handleSelectArticle = (e: SelectOption<Commodity | FinishedProduct>) => {
    const { context } = this.props;
    const articleExtended = e.object
      ? isFinishedProduct(e.object)
        ? extendFinishedProduct(e.object, context)
        : extendCommodity(e.object, context)
      : undefined;
    this.setState({ selectedArticle: articleExtended });
  };

  handleSelectSupplier = (e: SelectOption<Supplier>) => this.setState({ preferredSupplier: e.object });

  handleSelectCustomer = (e: SelectOption<Company>) => {
    const { terms } = this.state;
    const customer = e.object as Company;
    const newTerms = getCustomerTermOptionFromCompany(
      customer,
      terms.title || `Contract for ${customer.name}`,
      terms.id.toString()
    );
    this.setState({ customer, terms: newTerms, discount: customer.discount ?? 0 });
  };

  handleChangeCustomerNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.note = e.currentTarget.value;
    this.setState({ terms });
  };

  handleChangeCustomerPaymentTerms = (e: SelectOption) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.paymentTerm = e;
    this.setState({ terms });
  };

  handleChangeCustomerCustomPaymentTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    const terms = _.cloneDeep(this.state.terms);

    terms.customPaymentTerm = e.currentTarget.value;
    this.setState({ terms });
  };

  handleChangeCustomerCustomPaymentTermConditions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.customPaymentTermCondition = e.currentTarget.value;
    this.setState({ terms });
  };

  handleChangeCustomerDeliveryTerms = (e: SelectOption) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.deliveryTerm = e;
    this.setState({ terms });
  };

  handleChangeCustomerDeliveryCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.deliveryCity = e.currentTarget.value;
    this.setState({ terms });
  };

  handleChangeLabelDesign = (e: SelectOption) => {
    const terms = _.cloneDeep(this.state.terms);
    terms.cleanLabel = e.value === LabelDesign.CLEAN;
    this.setState({ terms });
  };

  handleDraft = async () => {
    const contract = this.getCustomerContract();
    if (!contract) return;
    this.setState({ saving: "draft" });
    try {
      const path = await createPDF(
        createContractConfirmationHTML(contract, STANDARDTEXTSHORT, true),
        `DRAFT-Contract-Confirmation`,
        contract.company._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: CO_FOOTER_HTML,
        }
      );
      if (path) window.open(resolveFilePath(path));
      else toast.error("Error creating contract preview");
    } finally {
      this.setState({ saving: false });
    }
  };

  handleCreateContract = async () => {
    const { history } = this.props;
    const { request } = this.state;
    const contract = this.getCustomerContract();
    if (!contract) return;
    this.setState({ saving: "order" });
    try {
      const result = await insertCustomerContract(reduceCustomerContract(contract));
      if (result && result.res && result.res.insertedId) {
        contract.contractNo = result.contractNumber;
        const path = await createPDF(
          createContractConfirmationHTML(contract, STANDARDTEXTSHORT),
          `Contract-Confirmation-${result.contractNumber}`,
          contract.company._id.toString(),
          {
            marginLeft: "2cm",
            marginBottom: "4.2cm",
            footerHtml: CO_FOOTER_HTML,
          }
        );
        if (path) {
          window.open(resolveFilePath(path));
          await callPushToArray(CUSTOMERCONTRACT, result.res.insertedId, "files", {
            _id: new BSON.ObjectId(),
            date: new Date(),
            path,
            type: CC_CONTRACTCONFIRMATION,
          });
        }
        if (request) {
          const requestAction: UpdateAction = {
            collection: CUSTOMERREQUEST,
            filter: { _id: request._id },
            update: { state: CustomerRequestState.CLOSED },
            push: { timeline: getCustomerRequestTimelineEntry(CustomerRequestTimelineTypes.CLOSED) },
          };
          const res = await transaction([requestAction]);
          if (!res) toast.info("Request could not be closed. Please close it manually");
        }

        toast.success("Contract successfully created.");
        history.push("/customerContract/" + result.res.insertedId.toString());
      } else {
        toast.error("Contract could not be created. Please try again later");
      }
    } catch (e) {
      toast.error("Contract could not be created: " + e);
    } finally {
      this.setState({ saving: false });
    }
  };

  validateData = () => {
    const { pricePerUnit, totalAmount, purchasePrice, selectedArticle, customer, minimumCallQuantity } = this.state;
    const errors: Array<string> = [];
    if (!customer) {
      errors.push("Select a customer");
      return errors;
    }
    if (!selectedArticle) {
      errors.push("Select a commodity");
      return errors;
    }
    if (pricePerUnit <= 0) errors.push("Please enter a valid price per unit");
    if (purchasePrice <= 0) errors.push("Please enter a valid purchase price");
    if (totalAmount <= 0) errors.push("Please enter a valid commodity amount");
    if (minimumCallQuantity > totalAmount)
      errors.push("Minimum call-off quantity cannot be greater than the total amount");

    return errors;
  };

  getCustomerContract = () => {
    const { context } = this.props;
    const {
      selectedArticle,
      customer,
      preferredSupplier,
      customerReference,
      minimumCallQuantity,
      totalAmount,
      purchasePrice,
      pricePerUnit,
      discount,
      validityPeriod,
      noteInternal,
      terms,
      targetDate,
      request,
    } = this.state;
    if (!selectedArticle || !customer) return;

    const totalPrice = round(totalAmount * pricePerUnit, 2);
    const totalPurchasePrice = round(totalAmount * purchasePrice, 2);
    const termsDB = getFormattedCCTerms(terms);
    const primaryPerson = getDocFromCollection(context.userData, customer.primaryPerson) as UserData;
    const contract: CustomerContractExtended = {
      _id: new BSON.ObjectId(),
      contractNo: "-1",
      createdAt: new Date(),
      targetDate,
      validityPeriod,
      noteCustomer: "",
      customerReference,
      noteInternal: noteInternal.trim()
        ? [{ _id: new BSON.ObjectId(), note: noteInternal, person: userService.getUserId(), date: new Date() }]
        : [],
      state: CC_STATE.OPEN,
      company: customer,
      person: primaryPerson,
      commodity: getArticleSnapshot(selectedArticle),
      contractInformation: { minimumCallQuantity, totalAmount, restAmount: totalAmount },
      priceInformation: {
        totalPrice,
        purchasePriceCommodities: totalPurchasePrice,
        currency: CUSTOMER_BASE_CURRENCY,
        discount,
      },
      timeline: [getCustomerContractTimelineEntry(CustomerContractTimelineType.CREATED)],
      files: request?.attachments || [],
      terms: termsDB,
      calls: [],
    };
    if (preferredSupplier) contract.supplier = preferredSupplier;
    if (request) contract.requestId = request._id.toString();
    return contract;
  };

  getDefaultState = (): CreateCustomerContractState => {
    const now = new Date();
    return {
      saving: false,
      targetDate: null,
      totalAmount: 1000,
      minimumCallQuantity: 500,
      purchasePrice: 0,
      pricePerUnit: 0,
      discount: 0,
      validityPeriod: { start: now, end: new Date(new Date(now).setFullYear(now.getFullYear() + 1)) },
      noteInternal: "",
      terms: {
        id: new BSON.ObjectId().toString(),
        paymentTerm: I_PAYMENTTARGETS[4],
        customPaymentTerm: "",
        customPaymentTermCondition: "",
        note: "",
        deliveryTerm: CO_STANDARDINCOTERM,
        deliveryCity: "",
        cleanLabel: false,
      },
      customerReference: "",
    };
  };

  render() {
    const { context } = this.props;
    const {
      saving,
      targetDate,
      selectedArticle,
      customer,
      totalAmount,
      minimumCallQuantity,
      purchasePrice,
      pricePerUnit,
      discount,
      validityPeriod,
      noteInternal,
      terms,
      customerReference,
      preferredSupplier,
      request,
    } = this.state;

    const errors: Array<string> = this.validateData();
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="container-xxl responsive-aside-container">
          <div className="ms-lg-15">
            <div className="card bg-white">
              <div className="card-header mt-6 border-none">
                <div className="card-title flex-column">
                  <h2 className="mb-1">
                    Create Customer Contract {request ? `for Request RB-${request.requestNo}` : ""}
                  </h2>
                </div>
              </div>
              <div className="card-body p-9 pt-0">
                <div className="border-bottom-dark-gray pt-5" />
                <div>
                  {request && <CustomerRequestAdditionalInformation request={request} />}
                  <CreateCustomerOrderContractGeneralSettings
                    totalAmount={totalAmount}
                    pricePerUnit={pricePerUnit}
                    discount={discount}
                    purchasePrice={purchasePrice}
                    customer={customer}
                    selectedArticle={selectedArticle}
                    preferredSupplier={preferredSupplier}
                    context={context}
                    onSelectCustomer={this.handleSelectCustomer}
                    onSelectArticle={this.handleSelectArticle}
                    onSelectSupplier={this.handleSelectSupplier}
                    onChangeValue={this.handleChangeValue}
                  />
                </div>
                <div className="border-bottom-dark-gray pt-5" />
                <div>
                  <div className="fw-bolder text-white fs-3 my-5">Contract Settings</div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold">Customer Reference</span>
                    <div className="col-8">
                      <Input
                        className="form-control custom-form-control"
                        type="text"
                        name={"customerReference"}
                        placeholder={"Customer's internal reference"}
                        value={customerReference}
                        onBlur={this.handleChangeValue}
                      />
                      <small className="text-success mt-1">&nbsp;</small>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold">Estimated Availability Date</span>
                    <div className="col-8">
                      <CalendarWeekSelector
                        value={targetDate}
                        onSelectCalendarWeek={this.handleChangeDate}
                        onClear={this.handleResetDate}
                        allowPast={true}
                      />
                      <small className="mt-1">&nbsp;</small>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold">Minimum Call-Off Quantity</span>
                    <div className="col-8">
                      <div className="input-group">
                        <Input
                          className="form-control custom-form-control"
                          type="number"
                          name={"minimumCallQuantity"}
                          integerOnly={true}
                          value={minimumCallQuantity}
                          onBlur={this.handleChangeValue}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text form-control custom-form-control">
                            {selectedArticle ? formatArticleUnit(selectedArticle.unit) : "kg"}
                          </span>
                        </div>
                      </div>
                      <small className="text-success mt-1">&nbsp;</small>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <span className="col-4 col-form-label fs-6 fw-bold">Validity Period</span>
                    <div className="col">
                      <DateInput
                        classes="form-control custom-form-control"
                        value={validityPeriod.start}
                        onBlur={this.handleChangeValidityDate}
                        name={"start"}
                      />
                    </div>
                    <div className="col-auto text-white fw-bolder align-middle fs-2 ">-</div>
                    <div className="col">
                      <DateInput
                        classes="form-control custom-form-control"
                        value={validityPeriod.end}
                        onBlur={this.handleChangeValidityDate}
                        name={"end"}
                      />
                      <small className="mt-1">&nbsp;</small>
                    </div>
                  </div>
                </div>
                <div className="border-bottom-dark-gray pt-5" />
                <CustomerTerms
                  customerTerm={terms}
                  heading={"Payment & Delivery Terms"}
                  onChangePaymentTerms={this.handleChangeCustomerPaymentTerms}
                  onChangeCustomPaymentTerm={this.handleChangeCustomerCustomPaymentTerms}
                  onChangeCustomPaymentTermCondition={this.handleChangeCustomerCustomPaymentTermConditions}
                  onChangeDeliveryTerms={this.handleChangeCustomerDeliveryTerms}
                  onChangeDeliveryCity={this.handleChangeCustomerDeliveryCity}
                  onChangeComment={this.handleChangeCustomerNote}
                  onChangeLabelDesign={this.handleChangeLabelDesign}
                />
                <div className="border-bottom-dark-gray pt-5" />
                <div className="row">
                  <div className="col-6">
                    <div className="fw-bolder text-white fs-3 my-5">Additional Information</div>
                    <div className="row">
                      <div className="col-12">
                        <Textarea
                          className="form-control custom-form-control"
                          rows={5}
                          value={noteInternal}
                          placeholder={"Additional Notes..."}
                          onChange={this.handleChangeNote}
                        />
                      </div>
                    </div>
                  </div>
                  <CreateCustomerOrderContractCalculation
                    totalAmount={totalAmount}
                    pricePerUnit={pricePerUnit}
                    discount={discount}
                    purchasePrice={purchasePrice}
                  />
                </div>
              </div>
              <div className="card-footer pt-0 border-0">
                <div className="border-bottom-dark-gray pt-5" />
                <div className="pt-3">
                  <div className="d-flex pt-3 align-items-center w-100">
                    <ErrorOverlayButton
                      errors={errors}
                      className="btn btn-link btn-sm btn-text-white ml-auto"
                      saving={!!saving}
                      buttonText={saving === "draft" ? "Generating" : "Preview"}
                      onClick={this.handleDraft}
                    />
                    <ErrorOverlayButton
                      errors={errors}
                      className={"btn btn-outline btn-outline-white btn-sm ml-4"}
                      buttonText={saving === "order" ? "Creating Contract" : "Create Contract"}
                      saving={!!saving}
                      onClick={this.handleCreateContract}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateCustomerContract;
