import _ from "lodash";
import React, { PureComponent } from "react";
import Search from "../../common/Search";
import { DataContextSupplierType } from "../../../context/dataContext";
import { doFuseSearch } from "../../../utils/baseUtils";
import { SupplierSupplierExtended } from "../../../model/supplier/supplierSupplier.types";
import CreatePackagingDimensionModal from "../common/modals/CreatePackagingDimensionModal";
import PackagingDimensionEntry from "../common/PackagingDimensionEntry";

interface PackagingDimensionListProps {
  context: DataContextSupplierType;
  supplier: SupplierSupplierExtended;
}

interface PackagingDimensionListState {
  packagingDimensionUpdating?: string;
  search: string;
}

class PackagingDimensionList extends PureComponent<PackagingDimensionListProps, PackagingDimensionListState> {
  constructor(props: PackagingDimensionListProps) {
    super(props);
    this.state = { search: "" };
  }

  handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleSetPackagingDimensionUpdating = (packagingDimensionUpdating?: string) =>
    this.setState({ packagingDimensionUpdating });

  filterPackagingDimensions = () => {
    const { supplier } = this.props;
    let packagingDimensions = _.cloneDeep(supplier.packagingDimensions);
    const { search } = this.state;
    if (packagingDimensions && search.trim()) {
      packagingDimensions = doFuseSearch(packagingDimensions, search, [
        "description",
        "length",
        "width",
        "height",
        "netWeight",
        "grossWeight",
      ]);
    }
    return packagingDimensions || [];
  };

  render() {
    const { context, supplier } = this.props;
    const { search, packagingDimensionUpdating } = this.state;

    const packagingDimensions = this.filterPackagingDimensions();

    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="card bg-white" style={{ minHeight: "100%" }}>
              <div className="card-header border-0 mt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bolder fs-3 mb-1">Palette Data</span>
                  <span className="text-muted fw-bold fs-7">Data for {packagingDimensions.length} palettes</span>
                </h3>
                <div className="card-toolbar" style={{ minWidth: "30%" }}>
                  <Search
                    placeholder="Search for length, height etc..."
                    onSearch={this.handleChangeSearch}
                    value={search}
                  />
                </div>
              </div>
              <div className="card-body p-4 mx-4">
                {supplier && (
                  <>
                    {packagingDimensions.map((pd) => (
                      <PackagingDimensionEntry
                        key={pd._id.toString()}
                        context={context}
                        packagingDimension={pd}
                        supplier={supplier}
                        packagingDimensionUpdating={packagingDimensionUpdating}
                        onSetPackagingDimensionUpdating={this.handleSetPackagingDimensionUpdating}
                      />
                    ))}
                    <div className="pt-2">
                      <CreatePackagingDimensionModal supplier={supplier} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackagingDimensionList;
