import React from "react";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import PersonSummaryWidget from "../../common/PersonSummaryWidget";
import { getSupplierCommodityStats } from "../../../utils/supplierUtils";
import { DataContextSupplierType } from "../../../context/dataContext";
import { SupplierSupplierExtended } from "../../../model/supplier/supplierSupplier.types";

interface SupplierPageSummaryProps {
  supplier: SupplierSupplierExtended;
  context: DataContextSupplierType;
}

const SupplierPageSummary: React.FunctionComponent<SupplierPageSummaryProps> = React.memo(({ supplier, context }) => {
  const [amount] = getSupplierCommodityStats(supplier._id.toString(), context.commodity);

  return (
    <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
      <div className="card-body">
        <div className="d-flex flex-center flex-column py-5">
          <span className="fs-2 text-gray-800 fw-bolder mb-1 text-center">{supplier.name}</span>
          <div className="d-flex flex-center flex-column py-1">
            <CompanyCountryWidget company={supplier} wrapperClasses={"fs-6 fw-bold text-muted mb-6"} />
          </div>
        </div>
        <div className="card card-body bg-light no-hover">
          {/*TODO stats RB-188*/}
          <div className="d-flex justify-content-between flex-row">
            <div className="fw-bolder text-white">
              {amount} {amount === 1 ? "Commodity" : "Commodities"}
            </div>
            <div className="text-warning">0 expired</div>
          </div>
          <div className="d-flex justify-content-between flex-row mt-5">
            <div className="fw-bolder text-white">0 Documents</div>
            <div className="text-warning">0 expired</div>
          </div>
        </div>
        <div className="d-flex flex-stack fs-4 py-3 mt-10">
          <div className="fw-bolder ">Contacts</div>
        </div>
        <div className="border-bottom-dark-gray" />
        <PersonSummaryWidget
          company={supplier}
          type={"supplier"}
          person={supplier.primaryPerson}
          description={"Primary Contact"}
        />
        {supplier.persons.map((person, idx) => (
          <PersonSummaryWidget
            company={supplier}
            type={"supplier"}
            isAsPrimaryAssignable
            isRemovable
            key={person._id.toString()}
            description={"Contact #" + (idx + 1)}
            person={person}
          />
        ))}
      </div>
    </div>
  );
});

export default SupplierPageSummary;
