import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Commodity } from "../../../model/commodity.types";
import { SupplierOrder } from "../../../model/supplierOrder.types";
import {
  getAveragePriceAge,
  getArticlesPriceRange,
  getCommodityOpenOrders,
  getFormattedPriceRange,
  getOutdatedPricesCount,
  getPriceRange,
  getValidPricesCount,
} from "../../../utils/commodityUtils";
import PropertiesWidget from "../../common/PropertiesWidget";
import PriceRangeWidget from "../../common/PriceRangeWidget";
import { PropertyType, resolveProperties } from "../../../utils/propertyUtils";
import { CommodityStatistics } from "../../../model/statistics/commodityStatistics.types";
import { formatDate, formatDateTime, pluralize } from "../../../utils/baseUtils";
import { Currencies, USD } from "../../../utils/currencyUtils";
import Tooltip from "../../common/Tooltip";
import { FinishedProduct } from "../../../model/finishedProduct.types";
import { isFinishedProduct } from "../../../utils/finishedProductUtils";
import { formatArticleUnit, getArticleStatistics } from "../../../utils/productArticleUtils";
import { DataContextInternal } from "../../../context/dataContext";

interface CommodityListingRowProps extends RouteComponentProps {
  article: Commodity | FinishedProduct;
  articles: Array<Commodity | FinishedProduct>;
  orders: Array<SupplierOrder>;
  currencies: Currencies;
}

const CommodityListingRow: React.FunctionComponent<CommodityListingRowProps> = ({
  article,
  articles,
  orders,
  currencies,
  history,
}) => {
  const context = useContext(DataContextInternal);

  const [statistics, setStatistics] = useState<Partial<CommodityStatistics> | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const validPrices = getValidPricesCount(article);
  const outdatedPrices = getOutdatedPricesCount(article);
  const priceRange = getPriceRange(article, currencies, USD);
  const formattedPriceRange = priceRange ? getFormattedPriceRange(priceRange) : null;
  const overallPriceRange = getArticlesPriceRange(articles, currencies, USD);
  const priceAge = getAveragePriceAge(article);

  const [, inTimeOrders, lateOrders] = getCommodityOpenOrders(article._id, orders);
  const forwardCommodity = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    history.push(`/${isFinishedProduct(article) ? "finishedProduct" : "commodity"}/${article._id.toString()}`);
  };

  useEffect(() => {
    getArticleStatistics(article._id, ["stockData", "flowData", "ytyQuantity"]).then((res) => {
      setStatistics(res);
      setLoading(false);
    });
  }, [article]);

  const properties = useMemo(() => {
    return resolveProperties(article.properties, context.property);
  }, [article]);

  return (
    <tr className="cursor-pointer-row" onClick={forwardCommodity}>
      <td className="align-middle">
        <Link
          className="text-white fs-5 mb-1 custom-link"
          onClick={(e) => e.stopPropagation()}
          to={`/${isFinishedProduct(article) ? "finishedProduct" : "commodity"}/${article._id.toString()}`}
        >
          {article.disabled && <span className="text-danger mr-2">[DISABLED]</span>}
          {!article.approved && !article.disabled && <span className="text-warning mr-2">[PENDING]</span>}
          {article.title.en}
          {article && article.organic && <i title={"organic"} className="fas fa-leaf text-success ml-1" />}
        </Link>
        <div>
          {article && <div className="text-muted">{article.subtitle.en}</div>}
          <PropertiesWidget properties={properties} types={[PropertyType.CATEGORY, PropertyType.COMPOSITION]} />
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div>{article ? article.suppliers.length : "0"} suppliers</div>
          {priceAge ? (
            <small className="text-muted">
              <span className="fs-5">
                <span className="text-muted">⌀</span>
              </span>
              {Math.round(priceAge)} days old
            </small>
          ) : (
            <span className="text-muted">-</span>
          )}
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div className={"text-nowrap " + (inTimeOrders.length > 0 ? "text-success" : "text-muted")}>
            {inTimeOrders.length} in time
          </div>
          <div className={"text-nowrap " + (lateOrders.length > 0 ? "text-danger" : "text-muted")}>
            {lateOrders.length} late
          </div>
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div className={"text-nowrap " + (validPrices > 0 ? "text-success" : "text-muted")}>{validPrices} valid</div>
          <div className={"text-nowrap " + (outdatedPrices > 0 ? "text-danger" : "text-muted")}>
            {outdatedPrices} expired
          </div>
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          <div>
            {priceRange && overallPriceRange && formattedPriceRange ? (
              <PriceRangeWidget
                priceRange={priceRange}
                overallPriceRange={overallPriceRange}
                formattedPriceRange={formattedPriceRange}
              />
            ) : (
              <span>-</span>
            )}
          </div>
        </div>
      </td>
      <td className="align-middle">
        <div className="fs-6">
          {loading ? (
            <div className="text-muted">Loading...</div>
          ) : statistics && statistics.stockData ? (
            <Tooltip
              tooltipText={
                <div className="text-white text-left">
                  Last Update: {formatDateTime(statistics.stockData.lastUpdate)}
                </div>
              }
            >
              <div>
                <div className={"text-white text-nowrap"}>
                  {`${statistics.stockData.onStock.toLocaleString()}${formatArticleUnit(article.unit)}`} on stock
                </div>
                <div className="text-muted text-nowrap">
                  {`${statistics.stockData.reservedStock.toLocaleString()}${formatArticleUnit(article.unit)}`} reserved
                </div>
              </div>
            </Tooltip>
          ) : (
            <div className="text-muted">N/A</div>
          )}
        </div>
      </td>
      <td className="align-middle">
        {loading ? (
          <div className="text-muted">Loading...</div>
        ) : statistics && statistics.flowData ? (
          <Tooltip
            tooltipText={
              <div className="text-white text-left">Last Update: {formatDateTime(statistics.flowData.lastUpdate)}</div>
            }
          >
            <div>
              <div className="text-white text-nowrap">
                {`${statistics.flowData.inFlow.toLocaleString()}${formatArticleUnit(article.unit)}`} inflow
              </div>
              <div className="text-muted text-nowrap">
                {`${statistics.flowData.outFlow.toLocaleString()}${formatArticleUnit(article.unit)}`} outflow
              </div>
            </div>
          </Tooltip>
        ) : (
          <div className="text-muted">N/A</div>
        )}
      </td>
      <td className="align-middle text-right">
        <div className="fs-6">
          {loading ? (
            <div className="text-muted">Loading...</div>
          ) : statistics && statistics.ytyQuantity ? (
            <Tooltip
              tooltipText={
                <>
                  <div className="text-white text-left">
                    Period:{" "}
                    {`${formatDate(statistics.ytyQuantity.startDate)} - ${formatDate(statistics.ytyQuantity.endDate)}`}
                  </div>
                  <div className="text-white text-left">
                    Last Update: {formatDateTime(statistics.ytyQuantity.lastUpdate)}
                  </div>
                </>
              }
            >
              <div>
                <div className="text-white text-nowrap">{`${statistics.ytyQuantity.quantity.toLocaleString()}${formatArticleUnit(
                  article.unit
                )}`}</div>
                <div className="text-muted fs-7 text-nowrap">
                  {pluralize(statistics.ytyQuantity.orderCount, "order")}
                </div>
              </div>
            </Tooltip>
          ) : (
            <div className="text-muted">N/A</div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default withRouter(CommodityListingRow);
