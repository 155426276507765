import React from "react";
import { Dropdown } from "react-bootstrap";
import { TabDefinition } from "./CustomTypes";

interface MobileDropdownProps {
  showDropdown: boolean;
  onTabSelect: (tabName: string) => void;
  onToggle: () => void;
  currentTab: string;
  tabs: Array<TabDefinition>;
  document?: unknown;
}

const MobileDropdown: React.FunctionComponent<MobileDropdownProps> = ({
  showDropdown,
  onTabSelect,
  currentTab,
  tabs,
  document,
  onToggle,
}) => {
  return (
    <Dropdown
      className="d-md-none mb-2 mr-1"
      onClick={(e) => e.stopPropagation()}
      show={showDropdown}
      onToggle={onToggle}
    >
      <Dropdown.Toggle
        className="btn btn-light text-uppercase show-arrow w-100"
        disabled={tabs.find((t) => t.name === currentTab)?.disabled || false}
      >
        {tabs.find((t) => t.name === currentTab)?.tabContent || "Menu"}
      </Dropdown.Toggle>
      <Dropdown.Menu className="custom-dropdown w-100">
        {tabs.map(
          (t) =>
            (!t.condition || t.condition(document)) &&
            !t.disabled && (
              <Dropdown.Item
                key={t.name + "_link"}
                eventKey={t.name}
                className="text-white"
                active={currentTab === t.name}
                onClick={() => onTabSelect(t.name)}
              >
                {t.tabContent}
              </Dropdown.Item>
            )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MobileDropdown;
