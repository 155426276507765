import React, { PureComponent } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { getOrderStateRanking as getSupplierOrderStateRanking } from "../../../../../../utils/supplierOrderUtils";
import { SupplierOrderExtended } from "../../../../../../model/supplierOrder.types";
import ErrorOverlayButton from "../../../../../common/ErrorOverlayButton";
import CustomSelect, { SelectOption } from "../../../../../common/CustomSelect";
import { getFullArticleName } from "../../../../../../utils/productArticleUtils";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import { Company } from "../../../../../../model/company.types";
import { Input } from "../../../../../common/Input";
import {
  CO_REQUESTCONFIRMATION,
  getCustomerOrderCalculation,
  getCustomerOrderTimelineEntry,
  getOrderStateRanking as getCustomerOrderStateRanking,
  placeCustomerOrder,
  sendSlackNotification,
  validateAmount,
} from "../../../../../../utils/customerOrderUtils";
import { Address, CustomerPriceInfo } from "../../../../../../model/commonTypes";
import { callPushToArray, formatCurrency, formatUnit } from "../../../../../../utils/baseUtils";
import { convertCurrency, CUSTOMER_BASE_CURRENCY } from "../../../../../../utils/currencyUtils";
import {
  CO_ORDEREDATSUPPLIER,
  CO_T_CREATEDFORCUSTOMER,
  CO_T_ORDERED,
  CustomerOrder,
  CustomerOrderExtended,
} from "../../../../../../model/customerOrder.types";
import { CO_FOOTER_HTML, createPDF } from "../../../../../../utils/pdfUtils";
import { createRequestConfirmationHTML } from "../../../../../../utils/pdf/requestConfirmationGenerationUtils";
import { REQUESTTEXTSHORT } from "../../../../../../utils/pdf/templateUtils";
import { resolveFilePath } from "../../../../../../utils/fileUtils";
import { Action, CUSTOMERORDER, SUPPLIERORDER, transaction, UPDATE } from "../../../../../../services/dbService";
import { getOrderNumber } from "../../../../../../utils/orderUtils";
import { AddressSelectOption, formatAddress } from "../../../../../../utils/addressUtils";
import { CUSTOMERORDERMARGIN } from "../../../../../../utils/generalUtils";
import { getArticleSnapshot } from "../../../../../../utils/productArticleUtils";
import { extendCustomerOrder } from "../../../../../../utils/dataTransformationUtils";

interface AttachOrderModalProps extends RouteComponentProps {
  context: DataContextInternalType;
  order: SupplierOrderExtended;
}

interface AttachOrderModalState {
  customer?: Company;
  customerOrder?: SelectOption<CustomerOrderExtended>;
  amount: number;
  priceInfo?: CustomerPriceInfo;
  fixedPrice: number;
  authorizationGuarantee: boolean;
  shippingAddress?: AddressSelectOption;
  step: number;
  saving: boolean;
  show: boolean;
}

class AttachOrderModal extends PureComponent<AttachOrderModalProps, AttachOrderModalState> {
  constructor(props: AttachOrderModalProps) {
    super(props);
    this.state = this.getDefaultState(false);
  }

  handleShow = () => this.setState(this.getDefaultState(true));
  handleHide = () => this.setState(this.getDefaultState(false));

  handleBack = () => this.setState({ step: 0 });
  handleContinue = () => this.setState({ step: 1 });

  handleChangeCustomer = (e: SelectOption<Company> | undefined) =>
    this.setState({ customer: e?.object, shippingAddress: undefined });

  handleChangeCustomerOrder = (e: SelectOption<CustomerOrderExtended> | undefined) => {
    if (e?.object) {
      const cO = e.object;
      const address =
        typeof cO.destination === "string" ? cO.destination : formatAddress(cO.destination, { withoutBreak: true });
      this.setState({
        customerOrder: e,
        customer: cO.company,
        amount: cO.amount,
        shippingAddress: {
          label: address,
          value: address,
          address: typeof cO.destination === "string" ? ({} as Address) : cO.destination,
        },
      });
    } else {
      this.setState({ customerOrder: undefined, customer: undefined, amount: 0, shippingAddress: undefined });
    }
  };

  handleChangeShippingAddress = (e: AddressSelectOption) => this.setState({ shippingAddress: e });

  handleChangeAmount = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { context, order } = this.props;
    const { commodity: article, transport } = order;
    const val = e.target.value;
    const amount = +val;
    let priceInfo: CustomerPriceInfo | null = await getCustomerOrderCalculation(
      article,
      amount,
      transport,
      context.currencies
    );
    // If we found no price for the given amount we try to find a price for the whole warehouse amount
    if (!priceInfo || priceInfo.unitPrice === 0) {
      priceInfo = await getCustomerOrderCalculation(article, order.warehouseAmount, transport, context.currencies);
    }
    // If we still got no amount we take the SO price and add the margin to it
    if (!priceInfo || priceInfo.unitPrice === 0) {
      const unitPrice = convertCurrency(
        order.totalPrice / order.amount,
        order.currency,
        CUSTOMER_BASE_CURRENCY,
        context.currencies
      );
      const margin = Number(context.general.find((g) => g.key === CUSTOMERORDERMARGIN)?.value || 10);
      priceInfo = { unitPrice: unitPrice * (1 + margin / 100), totalPrice: unitPrice * amount * (1 + margin / 100) };
    }
    this.setState({
      amount: Number(val),
      priceInfo: priceInfo || undefined,
      fixedPrice: priceInfo?.unitPrice ?? this.state.fixedPrice,
    });
  };

  handleToggleAuthorizationGuarantee = () =>
    this.setState({ authorizationGuarantee: !this.state.authorizationGuarantee });

  handleConfirm = async () => {
    const { order, context, history } = this.props;
    const { amount, customer, priceInfo, customerOrder, shippingAddress } = this.state;
    const { commodity: article, transport, targetDate, changedETA: changeETAPO } = order;
    if ((!priceInfo && !customerOrder) || !customer || !shippingAddress) return;
    try {
      this.setState({ saving: true });
      if (customerOrder?.object) {
        const cO = customerOrder.object; // Customer order must be truthy here, else we would not be here
        const actions: Array<Action> = [];
        const newTotalTurnover =
          order.totalTurnover + convertCurrency(cO.totalPrice, cO.currency, order.currency, context.currencies);
        const newTotalMargin = newTotalTurnover - order.totalPrice;
        actions.push({
          action: UPDATE,
          collection: SUPPLIERORDER,
          filter: { _id: order._id },
          push: { customerOrders: cO._id.toString() },
          inc: {
            warehouseAmount: -cO.amount,
          },
          update: { totalTurnover: newTotalTurnover, totalMargin: newTotalMargin },
        });
        const cOTimelineEntry = getCustomerOrderTimelineEntry(CO_T_ORDERED);
        // CO target date should be at least one week apart from SO
        const cOTargetDate = cO.changedETA
          ? new Date(cO.changedETA.getTime() + 1000 * 60 * 60 * 24 * 7)
          : new Date(cO.targetDate.getTime() + 1000 * 60 * 60 * 24 * 7);
        const sOTargetDate = changeETAPO ? changeETAPO : targetDate;
        const action: Action = {
          action: UPDATE,
          collection: CUSTOMERORDER,
          filter: { _id: cO._id },
          push: { timeline: cOTimelineEntry },
          update: { state: CO_ORDEREDATSUPPLIER },
        };
        // only set changedETA if CO would arrive before SO
        if (cOTargetDate < sOTargetDate)
          action.update = { ...action.update, changedETA: new Date(sOTargetDate.getTime() + 1000 * 60 * 60 * 24 * 7) };
        actions.push(action);
        const res = await transaction(actions);
        if (res) {
          toast.success("Order successfully attached");
        } else {
          toast.error("Error attaching order");
        }
      } else {
        const snapshot = getArticleSnapshot(article);
        const priceCommodities = priceInfo!.totalPrice; // Price info must be truthy here, else we would not be here
        // Create a wrapper object with the customer order information
        const customerOrder: CustomerOrder = {
          _id: new BSON.ObjectId(),
          commodity: snapshot,
          amount,
          unit: article.unit as "kg" | "ltr" | "1000 pcs",
          priceCommodities,
          currency: CUSTOMER_BASE_CURRENCY,
          targetDate: new Date(order.targetDate.getTime() + 1000 * 60 * 60 * 24 * 7),
          destination:
            Object.keys(shippingAddress.address).length === 0 ? shippingAddress.value : shippingAddress.address,
          customerReference: "",
          transport,
          company: customer._id.toString(),
          person: customer.primaryPerson,
          timeline: [getCustomerOrderTimelineEntry(CO_T_CREATEDFORCUSTOMER)],
          services: [],
          priceServices: 0,
          files: [],
          orderNo: "-1", // Will be replaced in backend
          noteCustomer: "",
          state: CO_ORDEREDATSUPPLIER,
          totalPrice: priceCommodities,
          createdAt: new Date(),
          supplier: order.supplier._id.toString(),
          noteInternal: [],
        };
        const res = await placeCustomerOrder(customerOrder, order._id.toString(), context.currencies);
        if (res && res.res && res.res.insertedId) {
          customerOrder.orderNo = res.orderNumber;
          const path = await createPDF(
            createRequestConfirmationHTML(customerOrder, REQUESTTEXTSHORT, context),
            "Request-Confirmation-" + res.orderNumber,
            customer._id.toString(),
            {
              marginLeft: "2cm",
              marginBottom: "4.2cm",
              footerHtml: CO_FOOTER_HTML,
            }
          );
          if (path) {
            window.open(resolveFilePath(path));
            await callPushToArray(CUSTOMERORDER, res.res.insertedId, "files", {
              _id: new BSON.ObjectId(),
              date: new Date(),
              path,
              type: CO_REQUESTCONFIRMATION,
            });
          }
          sendSlackNotification(customerOrder, res.res.insertedId.toString(), customer);
          toast.success("Placed new order!");
          history.push("/customerOrder/" + res.res.insertedId.toString());
        } else {
          toast.error(`Order could not be placed! ${res && res.error ? res.error : "Unknown error"}`);
          if (res && res.error) console.error(res.error);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState(this.getDefaultState(false));
    }
  };

  getDefaultState = (show: boolean): AttachOrderModalState => {
    return {
      show,
      amount: 0,
      fixedPrice: 0,
      authorizationGuarantee: false,
      saving: false,
      step: 0,
      customerOrder: undefined,
      customer: undefined,
      shippingAddress: undefined,
      priceInfo: undefined,
    };
  };

  validateOrder = () => {
    const { order } = this.props;
    const errors: Array<string> = [];
    if (getSupplierOrderStateRanking(order) > 4)
      errors.push("Can't attach customer orders to an already processed supplier order");
    if (order.warehouseAmount <= 0) errors.push("Supplier order got no warehouse amount left");
    return errors;
  };

  generateValidCustomerOrders = (): Array<SelectOption<CustomerOrderExtended>> => {
    const { context, order } = this.props;
    const { commodity: article, warehouseAmount, transport } = order;
    const relevantOrders = context.customerOrder.filter(
      (cO) =>
        getCustomerOrderStateRanking(cO) < 1 &&
        cO.commodity._id.toString() === article._id.toString() &&
        cO.transport === transport &&
        cO.amount <= warehouseAmount
    );
    return relevantOrders.map((rO) => {
      const rOEx = extendCustomerOrder(rO, context);
      return {
        label: getOrderNumber(rOEx) + " - " + rOEx.company.name + " - " + formatUnit(rOEx.amount, article.unit),
        value: rOEx._id.toString(),
        object: rOEx,
      };
    });
  };

  generatePrices = () => {
    const { order, context } = this.props;
    const { amount, priceInfo, customerOrder } = this.state;
    const { currencies } = context;
    let customerCost, unitPriceCustomer;
    const articleCost = convertCurrency(
      (order.totalPrice / order.amount) * amount,
      order.currency,
      CUSTOMER_BASE_CURRENCY,
      currencies
    );
    if (priceInfo) {
      customerCost = priceInfo.unitPrice * amount;
      unitPriceCustomer = priceInfo.unitPrice;
    } else if (customerOrder?.object) {
      const cO = customerOrder.object;
      customerCost = cO.priceCommodities;
      unitPriceCustomer = cO.priceCommodities / cO.amount;
    }
    return { customerCost, commodityCost: articleCost, unitPriceCustomer };
  };

  validateData = () => {
    const { order } = this.props;
    const { amount, customer, priceInfo, shippingAddress, customerOrder, authorizationGuarantee, step } = this.state;
    const errors: Array<string> = [];
    if (!customer) errors.push("Select a customer");
    if (!shippingAddress) errors.push("Select a shipping address");
    if (amount <= 0) errors.push("Amount has to be a positive amount");
    if (amount > order.warehouseAmount)
      errors.push("Amount can't exceed the remaining warehouse amount of the supplier order");
    if (!customerOrder && (!priceInfo || priceInfo.totalPrice <= 0)) errors.push("No valid price available");
    if (step === 1 && !authorizationGuarantee) errors.push("Authorization has to be guaranteed");
    return errors;
  };

  render() {
    const { order, context } = this.props;
    const { amount, customer, shippingAddress, customerOrder, authorizationGuarantee, step, saving, show } = this.state;
    const { currencies } = context;
    const { commodity: article, supplier, transport } = order;
    const articleAsSelection = {
      label: getFullArticleName(article),
      value: article._id.toString(),
    };
    const shippingAddresses = customer
      ? customer.address.map((a) => {
          return { label: formatAddress(a, { withoutBreak: true }), value: formatAddress(a), address: a };
        })
      : [];
    const supplierAsSelectOption = { label: supplier.name, value: supplier._id.toString() };
    const suitableAmount = validateAmount(article, amount, transport);
    const validCustomerOrders = this.generateValidCustomerOrders();
    const { customerCost, unitPriceCustomer, commodityCost } = this.generatePrices();

    return (
      <>
        <ErrorOverlayButton
          errors={this.validateOrder()}
          className="btn btn-sm btn-light"
          onClick={this.handleShow}
          buttonText="Attach Order"
        />
        <Modal contentClassName="bg-dark" show={show} onHide={this.handleHide} size={"lg"} centered>
          <Modal.Header className="border-0 pb-0">
            <Modal.Title>
              <h1 className="fw-bolder d-flex align-items-center text-white">
                Attach Customer Order to Supplier Order
              </h1>
            </Modal.Title>
            <CloseButton variant="white" onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body>
            <div className="card card-xl-stretch border-0 bg-white mb-4">
              <div className="mb-4">
                <label className="align-items-center fs-6 fw-bold mb-2">
                  <span className={step === 0 ? "required" : ""}>Customer Order</span>
                </label>
                <CustomSelect
                  options={validCustomerOrders}
                  disabled={step !== 0}
                  onChange={step === 0 ? this.handleChangeCustomerOrder : undefined}
                  value={customerOrder}
                  placeholder="New Order"
                  isClearable={true}
                  matchFormControl={true}
                />
              </div>
              <div className="mb-4">
                <label className="align-items-center fs-6 fw-bold mb-2">Article</label>
                <CustomSelect
                  options={[articleAsSelection]}
                  disabled={true}
                  value={articleAsSelection}
                  matchFormControl={true}
                />
              </div>
              <div className="mb-4">
                <label className="align-items-center fs-6 fw-bold mb-2">Supplier</label>
                <CustomSelect
                  options={[supplierAsSelectOption]}
                  disabled={true}
                  value={supplierAsSelectOption}
                  matchFormControl={true}
                />
              </div>
              <div className="mb-4">
                <label className="align-items-center fs-6 fw-bold mb-2">
                  <span className={step === 0 && !customerOrder ? "required" : ""}>Customer</span>
                </label>
                <CustomSelect
                  options={context.company
                    .filter((com) => com.activated && !com.disabled)
                    .map((c) => {
                      return { label: c.name, value: c._id.toString(), object: c };
                    })}
                  disabled={step !== 0 || Boolean(customerOrder)}
                  onChange={step === 0 || Boolean(customerOrder) ? this.handleChangeCustomer : undefined}
                  value={
                    customer
                      ? {
                          label: customer.name,
                          value: customer._id.toString(),
                        }
                      : undefined
                  }
                  isClearable={true}
                  matchFormControl={true}
                />
              </div>
              <div className="mb-4">
                <label className="align-items-center fs-6 fw-bold mb-2">
                  <span className={step === 0 ? "required" : ""}>Shipping Address</span>
                </label>
                <CustomSelect
                  options={shippingAddresses}
                  disabled={step !== 0 || !customer || Boolean(customerOrder)}
                  onChange={step === 0 && customer && !customerOrder ? this.handleChangeShippingAddress : undefined}
                  value={shippingAddress}
                  matchFormControl={true}
                />
              </div>
              <div className="mb-2">
                <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                  <span className={step === 0 && !customerOrder ? "required" : ""}>Amount</span>
                </label>
                <div className="input-group">
                  <Input
                    className="form-control custom-form-control"
                    type={step === 0 ? "number" : "text"}
                    name="amount"
                    value={amount}
                    min={0}
                    integerOnly={true}
                    disabled={step !== 0 || Boolean(customerOrder)}
                    onBlur={step === 0 || Boolean(customerOrder) ? this.handleChangeAmount : undefined}
                  />
                  <div
                    className="input-group-append rounded-end bg-custom-light-gray"
                    style={{ opacity: step === 0 ? 1 : 0.7 }}
                  >
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      {article.unit}
                    </div>
                  </div>
                </div>
                {suitableAmount && !customerOrder && (
                  <div className="text-danger mt-1 ">
                    Amount cannot be ordered. Next possible amounts would be {suitableAmount.lower} {article.unit} or{" "}
                    {suitableAmount.upper} {article.unit}
                  </div>
                )}
              </div>
              {customerCost !== undefined && commodityCost !== 0 && unitPriceCustomer !== undefined && (
                <div
                  className="d-flex flex-column text-center mt-2 p-2 background-secondary"
                  style={{ borderRadius: 10 }}
                >
                  {customerCost > 0 ? (
                    <>
                      <span className="text-muted fw-bold mt-0">
                        Turnover:{" "}
                        <span className="text-bold text-white fs-5">
                          {formatCurrency(customerCost, CUSTOMER_BASE_CURRENCY)}
                        </span>
                        <span className="text-bold text-white fs-5 ml-1">
                          ({formatCurrency(unitPriceCustomer, CUSTOMER_BASE_CURRENCY)}/{article.unit})
                        </span>
                      </span>
                      <span className="text-muted fw-bold mt-0">
                        Article Cost:{" "}
                        <span className="text-bold text-white fs-5">
                          {formatCurrency(commodityCost, CUSTOMER_BASE_CURRENCY)}
                        </span>
                        <span className="text-bold text-white fs-5 ml-1">
                          (
                          {formatCurrency(
                            convertCurrency(
                              order.totalPrice / order.amount,
                              order.currency,
                              CUSTOMER_BASE_CURRENCY,
                              currencies
                            ),
                            CUSTOMER_BASE_CURRENCY
                          )}
                          /{article.unit})
                        </span>
                      </span>
                      <span className="text-muted fw-bold mt-0">
                        Margin:{" "}
                        <span className="text-bold fs-5">
                          <span className={customerCost - commodityCost <= 0 ? "text-danger" : "text-success"}>
                            {formatCurrency(customerCost - commodityCost, CUSTOMER_BASE_CURRENCY)}
                          </span>
                        </span>
                      </span>
                    </>
                  ) : (
                    <span className="fw-bolder fs-1">
                      <span className="text-danger">Amount is below MOQ</span>
                    </span>
                  )}
                </div>
              )}
            </div>
            {step === 1 && (
              <div>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                  <input
                    className="form-check-input position-static"
                    checked={authorizationGuarantee}
                    type="checkbox"
                    onChange={this.handleToggleAuthorizationGuarantee}
                  />
                  <label className="form-check-label fs-5" onClick={this.handleToggleAuthorizationGuarantee}>
                    {customerOrder ? (
                      <span>
                        I guarantee that the information above is correct. I understand that the order will follow the
                        workflow as if the customer order was always attached to the supplier order.
                      </span>
                    ) : (
                      <span>
                        I guarantee that the customer has authorised me to create this order. I understand that
                        unauthorized orders can be harmful to business and may result in disciplinary consequences.
                      </span>
                    )}
                  </label>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-outline btn-outline-light btn-sm " + (step === 0 && "disabled")}
              disabled={step === 0}
              onClick={this.handleBack}
            >
              Back
            </button>
            <ErrorOverlayButton
              errors={this.validateData()}
              saving={saving}
              className="btn btn-success btn-sm"
              buttonText={step === 0 ? "Continue" : "Confirm"}
              onClick={step === 0 ? this.handleContinue : this.handleConfirm}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(AttachOrderModal);
