import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorOverlayButton from "../../../../../common/ErrorOverlayButton";
import { DataContextInternalType } from "../../../../../../context/dataContext";
import {
  SO_ARCHIVED,
  SO_CANCELED,
  SO_T_ARCHIVED,
  SupplierOrderExtended,
} from "../../../../../../model/supplierOrder.types";
import { getDaysBetween } from "../../../../../../utils/dateUtils";
import { getSupplierOrderTimelineEntry, updateSupplierOrder } from "../../../../../../utils/supplierOrderUtils";
import { getOrderNumber } from "../../../../../../utils/orderUtils";
import { CO_ARCHIVED, CustomerOrder } from "../../../../../../model/customerOrder.types";
import { getCustomerOrderTimelineEntry, updateCustomerOrder } from "../../../../../../utils/customerOrderUtils";

interface WorkflowCompletedCardProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

interface WorkflowCompletedCardState {
  saving: boolean;
}

class WorkflowCompletedCard extends PureComponent<WorkflowCompletedCardProps, WorkflowCompletedCardState> {
  constructor(props: WorkflowCompletedCardProps) {
    super(props);
    this.state = { saving: false };
  }

  handleCloseCustomerOrder = async (order: CustomerOrder) => {
    const update = { state: SO_ARCHIVED };
    try {
      this.setState({ saving: true });
      const cOTimeline = getCustomerOrderTimelineEntry(SO_T_ARCHIVED);
      const res = await updateCustomerOrder(update, order._id, cOTimeline);
      if (res && res.res && res.res.modifiedCount > 0) {
        toast.success("Order closed successfully");
      } else {
        toast.error("Error closing order");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleCloseOrder = async () => {
    const { order } = this.props;
    this.setState({ saving: true });
    try {
      const res = await updateSupplierOrder(
        { state: SO_ARCHIVED },
        order._id,
        getSupplierOrderTimelineEntry(SO_T_ARCHIVED)
      );
      if (res && res.modifiedCount > 0) {
        toast.success("Order closed successfully");
      } else {
        toast.error("Error closing order");
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { order, context } = this.props;
    const { saving } = this.state;
    const archivedDate = order.timeline.find((t) => t.type === SO_T_ARCHIVED)?.date;
    const orderDuration = archivedDate ? getDaysBetween(archivedDate, order.createdAt, true) : null;
    const isLate = archivedDate && archivedDate > order.targetDate;
    const cOs = order.customerOrders.map((cO) => cO._id.toString());
    const invoices = context.invoice.filter((i) => i.relatedOrder && cOs.includes(i.relatedOrder));
    const allCOsDone = order.customerOrders.every((cO) => cO.state === CO_ARCHIVED);
    const archived = order.state === SO_ARCHIVED;
    const canceled = order.state === SO_CANCELED;

    return (
      <>
        {archived && (
          <>
            <div className="text-center">
              <i className="mt-4 h1 fas fa-check-circle text-success" />
              <div className="fw-bolder fs-3">Order Completed</div>
              <div className="fs-5">
                <span className="text-muted">
                  within{" "}
                  <span className={isLate ? "text-danger" : "text-success"}>
                    {orderDuration !== null ? orderDuration : "unknown"} days
                  </span>
                </span>
              </div>
            </div>
            <div className="border-bottom-dark-gray pt-5" />
          </>
        )}
        <div className="opacity-100-hover" style={{ opacity: archived || canceled ? 0.3 : 1 }}>
          <div className="fw-bolder text-white fs-3 my-5">
            Complete Order{" "}
            {archived ? (
              <i className="h2 fas fa-check-circle text-success" />
            ) : canceled ? (
              <i className="h2 fas fa-exclamation-circle text-danger" />
            ) : (
              <span className="text-warning">[Current Task]</span>
            )}
          </div>
          <div className="py-2">
            <div className="bg-light2 rounded p-5 mb-7">
              <div className="row">
                <div className="col-3 align-self-center">
                  <span className="fw-bolder text-white">Customer Orders: </span>
                </div>
                <div className="col-9">
                  {order.customerOrders.length > 0 ? (
                    order.customerOrders.map((cO) => {
                      const relatedInvoice = invoices.find((i) => i.relatedOrder === cO._id.toString());
                      const archived = cO.state === CO_ARCHIVED;
                      return (
                        <div key={cO._id.toString()} className="row">
                          <div className="col-3 align-self-center">
                            <Link className="text-muted custom-link" to={"/customerOrder/" + cO._id.toString()}>
                              {getOrderNumber(cO)}
                            </Link>
                          </div>
                          <div
                            className={
                              "col-3 align-self-center " +
                              (archived || relatedInvoice ? "text-success" : "text-warning")
                            }
                          >
                            {archived ? "Archived" : relatedInvoice ? "Invoice Created" : "Invoice Missing"}
                          </div>
                          <div className="col-12 col-sm-6 align-self-center text-right">
                            {archived ? (
                              <i className="h2 fas fa-check-circle text-success my-auto" />
                            ) : relatedInvoice ? (
                              <button
                                className="btn btn-light custom-form-control-user btn-sm py-1 px-2 my-1"
                                onClick={() => this.handleCloseCustomerOrder(cO)}
                              >
                                Archive
                              </button>
                            ) : (
                              <Link
                                className="btn btn-light custom-form-control-user btn-sm py-1 px-2 my-1"
                                to={"/createInvoice/" + cO._id.toString()}
                              >
                                Create Invoice
                              </Link>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="row">
                      <div className="col-12 align-self-center">
                        <span className="text-white">No related customer orders</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {!archived && !canceled && (
            <div className="mt-auto text-right">
              <ErrorOverlayButton
                errors={allCOsDone ? [] : ["All customer orders need to be archived"]}
                className="btn btn-sm btn-outline btn-outline-light"
                buttonText="Close Order"
                saving={saving}
                onClick={this.handleCloseOrder}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default WorkflowCompletedCard;
