import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { TabDefinition } from "../../common/CustomTypes";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import OrderDocuments from "../../orders/common/OrderDocuments";
import OrderInvoices from "../../orders/common/OrderInvoices";
import OrderHistory from "../../orders/common/OrderHistory";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import { CustomerCustomerContractExtended } from "../../../model/customer/customerCustomerContract.types";
import CustomerContractDetails from "./tabPanels/CustomerContractDetails";
import MobileDropdown from "../../common/MobileDropdown";

interface CustomerCustomerContractTabPanelProps {
  contract: CustomerCustomerContractExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
  calls?: Array<CustomerCustomerOrder>;
}

interface CustomerCustomerContractTabPanelState {
  currentTab: string;
  showDropdown: boolean;
}

class CustomerCustomerContractTabPanel extends PureComponent<
  CustomerCustomerContractTabPanelProps,
  CustomerCustomerContractTabPanelState
> {
  constructor(props: CustomerCustomerContractTabPanelProps) {
    super(props);
    this.state = { currentTab: this.getTabDefinitions()[0].name, showDropdown: false };
  }

  componentDidUpdate(prevProps: Readonly<CustomerCustomerContractTabPanelProps>) {
    if (prevProps.contract._id.toString() !== this.props.contract._id.toString()) {
      this.setState({ currentTab: this.getTabDefinitions()[0].name });
    }
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { contract, context, calls } = this.props;

    return [
      {
        name: "details",
        tabContent: "Contract Details",
        component: <CustomerContractDetails contract={contract} calls={calls} context={context} />,
      },
      {
        name: "documents",
        tabContent: "Documents",
        component: <OrderDocuments order={contract} context={context} />,
      },
      {
        name: "invoices",
        tabContent: "Invoices",
        component: <OrderInvoices order={contract} context={context} />,
      },
      {
        name: "history",
        tabContent: "History",
        component: <OrderHistory order={contract} context={context} />,
      },
    ];
  };

  /**
   * Handle tab changes
   * @param eventKey new tab
   */
  handleTabSelect = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
  };

  handleToggleDropdown = () => {
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  };

  render() {
    const { currentTab, showDropdown } = this.state;
    const tabs = this.getTabDefinitions();

    return (
      <Tab.Container id="CustomerCustomerContractTabs" activeKey={currentTab} onSelect={this.handleTabSelect}>
        <div className="flex-lg-row-fluid ms-lg-15 order-0 order-xl-0 mt-2 mt-xl-0">
          <MobileDropdown
            showDropdown={showDropdown}
            onTabSelect={this.handleTabSelect}
            onToggle={this.handleToggleDropdown}
            currentTab={currentTab}
            tabs={tabs}
          />
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-4 d-none d-lg-flex">
            {tabs.map((t) => (
              <li key={t.name + "_link"} className="nav-item cursor-pointer">
                <Nav.Link
                  role="tab"
                  id={t.name}
                  eventKey={t.name}
                  active={currentTab === t.name}
                  className="text-active-white pb-4 "
                >
                  {t.tabContent}
                </Nav.Link>
              </li>
            ))}
          </ul>
          <Tab.Content>
            {tabs.map((t) => (
              <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                {t.component}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default CustomerCustomerContractTabPanel;
