import _ from "lodash";
import { BSON } from "realm-web";
import React, { useEffect, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { SupplierPricesExtended } from "../../model/commodity.types";
import { PackagingDimension, SupplierExtended } from "../../model/supplier.types";
import { PD_TRANSPORT_TYPES } from "../../utils/packagingDimensionUtils";
import CustomSelect, { SelectOption } from "./CustomSelect";
import { CommoditySupplierSettingsUpdate } from "./CustomTypes";
import ErrorOverlayButton from "./ErrorOverlayButton";
import { isFinishedProduct } from "../../utils/finishedProductUtils";
import { formatArticleUnit, InternalArticleExtended } from "../../utils/productArticleUtils";

interface ArticleSupplierSettingsModalProps {
  article: InternalArticleExtended;
  supplierPrice: SupplierPricesExtended;
  onSaveSettings: (
    commodityId: BSON.ObjectId | string,
    supplierId: BSON.ObjectId | string,
    settings: CommoditySupplierSettingsUpdate,
    finishedProduct: boolean
  ) => Promise<void>;
}

const ArticleSupplierSettingsModal: React.FunctionComponent<ArticleSupplierSettingsModalProps> = ({
  article,
  supplierPrice,
  onSaveSettings,
}) => {
  const supplier = supplierPrice.supplier;
  const [show, setShow] = useState(false);
  const [contingent, setContingent] = useState(supplierPrice.contingent || Infinity);
  const [palettes, setPalettes] = useState<Array<PackagingDimension>>([]);

  useEffect(() => {
    setPalettes(
      supplier.packagingDimensions?.filter((pD) =>
        isFinishedProduct(article)
          ? pD.finishedProducts === "all" || pD.finishedProducts?.includes(article._id.toString())
          : pD.commodities === "all" || pD.commodities.includes(article._id.toString())
      ) || []
    );
  }, [supplier.packagingDimensions]);

  useEffect(() => {
    setContingent(supplierPrice.contingent || Infinity);
  }, [supplierPrice.contingent]);

  const handleShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShow(true);
  };
  const handleHide = () => setShow(false);
  const handleContingent = (e: React.ChangeEvent<HTMLInputElement>) => setContingent(+e.target.value || Infinity);
  const handleAddSelectedPalette = (palette?: SelectOption<PackagingDimension>) => {
    if (!palette?.object) return;
    setPalettes(_.cloneDeep(palettes).concat(palette.object));
  };
  const handleRemovePalette = (id: string | BSON.ObjectId) => {
    setPalettes(_.cloneDeep(palettes).filter((p) => p._id.toString() !== id.toString()));
  };
  const handleSaveSettings = async () => {
    const settings: CommoditySupplierSettingsUpdate = {};
    const finishedProduct = isFinishedProduct(article);
    if (contingent !== supplierPrice.contingent) settings["contingent"] = contingent;
    const oldPalettes =
      supplier.packagingDimensions?.filter((pD) =>
        finishedProduct
          ? pD.finishedProducts === "all" || pD.finishedProducts?.includes(article._id.toString())
          : pD.commodities === "all" || pD.commodities?.includes(article._id.toString())
      ) || [];
    const oldIds = oldPalettes.map((pD) => pD._id.toString()).sort();
    const newIds = palettes.map((pD) => pD._id.toString()).sort();
    if (!_.isEqual(oldIds, newIds)) {
      const added = newIds.filter((id) => !oldIds.includes(id));
      const removed = oldIds.filter((id) => !newIds.includes(id));
      settings["packagingDimension"] = { added, removed };
    }
    await onSaveSettings(article._id, supplier._id, settings, finishedProduct);
    handleHide();
  };

  return (
    <>
      <button
        type="button"
        className={
          "btn btn-text-light btn-sm w-100 " + ((article.disabled || supplierPrice.supplier.disabled) && "disabled")
        }
        disabled={article.disabled || supplierPrice.supplier.disabled}
        onClick={article.disabled || supplierPrice.supplier.disabled ? undefined : handleShow}
      >
        <i className="icon-lg fas fa-cog align-middle" />
      </button>
      <Modal contentClassName="bg-dark" show={show} size={"lg"} onHide={handleHide} centered>
        <Modal.Header className="border-0 pb-0">
          <CloseButton variant={"white"} onClick={handleHide} />
        </Modal.Header>
        <Modal.Body>
          <div className="pb-5">
            <h1 className="fw-bolder d-flex align-items-center text-white">Supplier Settings</h1>
            <div className="text-muted fw-bold fs-6">
              Adjust some commodity and supplier specific settings such as the available contingent or assigned palettes
            </div>
          </div>
          <div>
            <div className="row mt-3">
              <div className="col-md-4 my-auto">
                <label className="fs-5 fw-bold my-auto">Contingent</label>
              </div>
              <div className="col-md-8">
                <div className="input-group">
                  <input
                    type="number"
                    value={contingent === Infinity ? "" : contingent}
                    placeholder={contingent === Infinity ? "Unlimited" : undefined}
                    name="contingent"
                    onChange={handleContingent}
                    min={0}
                    className="form-control custom-form-control"
                  />
                  <div className="input-group-append rounded-end bg-custom-light-gray">
                    <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                      {formatArticleUnit(article.unit)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-4 my-auto">
                <label className="fs-5 fw-bold my-auto">Palettes</label>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <SupplierPalettes
                  supplier={supplierPrice.supplier}
                  palettes={palettes}
                  finishedProduct={isFinishedProduct(article)}
                  onAddPalette={handleAddSelectedPalette}
                  onRemovePalette={handleRemovePalette}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline btn-text-danger" onClick={handleHide}>
            Cancel
          </button>
          <button className={"btn btn-sm btn-outline btn-outline-light "} onClick={handleSaveSettings}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface SupplierPalettesProps {
  supplier: SupplierExtended;
  palettes: Array<PackagingDimension>;
  finishedProduct: boolean;
  onAddPalette: (e?: SelectOption<PackagingDimension>) => void;
  onRemovePalette: (id: BSON.ObjectId | string) => void;
}

const SupplierPalettes: React.FunctionComponent<SupplierPalettesProps> = ({
  supplier,
  palettes,
  finishedProduct,
  onAddPalette,
  onRemovePalette,
}) => {
  const [selectedPalette, setSelectedPalette] = useState<SelectOption<PackagingDimension> | null>(null);

  const availablePalettes =
    !supplier.packagingDimensions ||
    PD_TRANSPORT_TYPES.every((t) => palettes.some((p) => p.transportTypes?.includes(t)))
      ? []
      : supplier.packagingDimensions.filter((pD) =>
          pD.transportTypes?.every((t) => !palettes.some((p) => p.transportTypes?.includes(t)))
        );

  const handleSelectPalette = (e: SelectOption<PackagingDimension>) => {
    setSelectedPalette(e);
  };
  const handleAddSelectedPalette = () => {
    if (!selectedPalette) return;
    onAddPalette(selectedPalette);
    setSelectedPalette(null);
  };

  return (
    <div>
      <div className="row">
        <div className="col-3 mb-3">
          <div className="text-ellipsis me-2 text-muted fs-6 fw-bold">Description</div>
        </div>
        <div className="col-3 mb-3">
          <div className="text-ellipsis me-2 text-muted fs-6 fw-bold">Dimensions</div>
        </div>
        <div className="col-2 mb-3">
          <div className="text-ellipsis me-2 text-muted fs-6 fw-bold">Weight</div>
        </div>
        <div className="col-3 mb-3">
          <div className="text-ellipsis me-2 text-muted fs-6 fw-bold">Used For</div>
        </div>
        {palettes.map((pd) => (
          <React.Fragment key={pd._id.toString()}>
            <div className="col-3 mb-3">
              <div className="text-ellipsis me-2 text-white fs-5" title={pd.description}>
                {pd.description}
              </div>
            </div>
            <div className="col-3 mb-3">
              <div className="text-ellipsis me-2 text-white fs-5">
                {pd.length} x {pd.width} x {pd.height}cm ({pd.cbm}m³)
              </div>
            </div>
            <div className="col-2 mb-3">
              <div className="text-ellipsis me-2 text-white fs-5">
                {pd.netWeight}kg/{pd.grossWeight}kg
              </div>
            </div>
            <div className="col-3 mb-3">
              <div className="me-2 text-white fs-5">
                {pd.transportTypes
                  ? pd.transportTypes.map((t) => (
                      <span key={t} className="badge badge-gray mx-1">
                        {t}
                      </span>
                    ))
                  : ""}
              </div>
            </div>
            <div className="col-1 mb-32 text-right">
              <ErrorOverlayButton
                errors={
                  (finishedProduct ? pd.finishedProducts === "all" : pd.commodities === "all")
                    ? [
                        `Palette is set to be used for all ${
                          finishedProduct ? "finished products" : "commodities"
                        }. Please adjust this in supplier general settings, if necessary`,
                      ]
                    : []
                }
                className="btn btn-text p-0 ml-auto "
                buttonText={<i className="fa fa-trash text-white p-0" />}
                onClick={() => onRemovePalette(pd._id)}
              />
            </div>
          </React.Fragment>
        ))}
        {availablePalettes.length > 0 && (
          <>
            <div className="col-11 mt-1">
              <CustomSelect
                matchFormControl={true}
                options={availablePalettes.map((aP) => {
                  return {
                    value: aP._id.toString(),
                    label: `${aP.description} ${aP.length} x ${aP.width} x ${aP.height}cm - ${aP.netWeight}kg ${
                      aP.transportTypes ? ` - ${aP.transportTypes.join(", ")}` : ""
                    }`,
                    object: aP,
                  };
                })}
                value={selectedPalette}
                isClearable={true}
                onChange={handleSelectPalette}
              />
            </div>
            <div className="col-1 text-right mt-1 ">
              <button className={"btn btn-text btn-sm px-0"}>
                <i className="fa fa-plus text-success p-0" onClick={handleAddSelectedPalette} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ArticleSupplierSettingsModal;
