import React, { useState } from "react";
import CreateNewsModal from "./modal/CreateArticleModal";
import { DataContextInternal } from "../../../context/dataContext";
import NewsEntry from "../../common/NewsEntry";
import { Article } from "../../../model/article.types";

interface InternalNewsProps {
  context: React.ContextType<typeof DataContextInternal>;
}

/**
 * Function component to represents the news in a specific page (internal view).
 */
const NewsListing: React.FunctionComponent<InternalNewsProps> = ({ context }) => {
  // state definition
  const [article, setArticle] = useState<Article | undefined>(undefined);

  const handleCardSelected = (article: Article) => {
    setArticle(article);
  };

  const handleUnsetNews = () => {
    setArticle(undefined);
  };

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl responsive-aside-container">
          <div className="card bg-white">
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">Articles</span>
                <CreateNewsModal article={article} onClose={handleUnsetNews} />
              </h3>
              <div className="row">
                {context.news
                  .sort((a, b) => {
                    return b.creationTime.getTime() - a.creationTime.getTime();
                  })
                  .map((article) => (
                    <NewsEntry
                      key={article._id.toString()}
                      article={article}
                      context={context}
                      onClick={() => handleCardSelected(article)}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsListing;
