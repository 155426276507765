import _ from "lodash";
import React, { useEffect, useState, FunctionComponent } from "react";
import { toast } from "react-toastify";
import { PriceRequestWhitelist } from "../../../../model/priceRequestWhitelist.types";
import { getCollectionDB, PRICEREQUESTWHITELIST, receiveStreamIterator } from "../../../../services/dbService";
import WhitelistOverview from "./WhitelistOverview";

const PriceRequestWhitelistWrapper: FunctionComponent = () => {
  const [priceRequestWhitelistCollection, setPriceRequestWhitelistCollection] = useState<Array<PriceRequestWhitelist>>(
    []
  );
  const [streamIterator, setStreamIterator] = useState<
    AsyncGenerator<Realm.Services.MongoDB.ChangeEvent<any>> | undefined
  >(undefined);

  useEffect(() => {
    const fetchPriceRequestWhitelist = async () => {
      try {
        const collection: Array<PriceRequestWhitelist> = await getCollectionDB(PRICEREQUESTWHITELIST);
        setPriceRequestWhitelistCollection(collection);
        const streamIterator = receiveStreamIterator(PRICEREQUESTWHITELIST);
        setStreamIterator(streamIterator);
      } catch (e) {
        console.error("Error fetching collection priceRequestWhitelist: " + e);
        toast.error("Collection could not be fetched");
      }
    };
    fetchPriceRequestWhitelist();
  }, []);

  useEffect(() => {
    if (!streamIterator) return;
    const listen = async () => {
      for await (const change of streamIterator) {
        const collectionCopy = _.cloneDeep(priceRequestWhitelistCollection);
        if (change.operationType === "update") {
          const relatedEntityIndex = priceRequestWhitelistCollection.findIndex(
            (pr) => pr._id.toString() === change.fullDocument?._id.toString()
          );
          if (relatedEntityIndex !== -1 && change.fullDocument) {
            collectionCopy.splice(relatedEntityIndex, 1, change.fullDocument);
          }
        }
        if (change.operationType === "insert") {
          if (change.fullDocument) {
            collectionCopy.push(change.fullDocument);
          }
        }
        setPriceRequestWhitelistCollection(collectionCopy);
      }
    };
    listen();
  }, [streamIterator]);

  return <WhitelistOverview priceRequestWhitelistCollection={priceRequestWhitelistCollection} />;
};

export default PriceRequestWhitelistWrapper;
