import React, { useEffect, useState } from "react";
import { CompanyExtended } from "../../../model/company.types";
import { StatsWidget } from "../../common/StatsWidget";
import { CustomerStatistics } from "../../../model/statistics/customerStatistics.types";
import { getCustomerStatistics } from "../../../utils/companyUtils";
import { getRatingColor, getStatisticText } from "../../../utils/statisticUtils";
import { formatCurrency, formatDateTime } from "../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";
import Tooltip from "../../common/Tooltip";

interface CustomerPageStatsProps {
  company: CompanyExtended;
}

const CustomerPageStats: React.FunctionComponent<CustomerPageStatsProps> = React.memo(({ company }) => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<Pick<CustomerStatistics, "generalStatistics"> | undefined>(undefined);

  useEffect(() => {
    getCustomerStatistics(company._id, ["generalStatistics"]).then((res) => {
      if (res && "generalStatistics" in res) {
        setStatistics(res);
      }
      setLoading(false);
    });
  }, []);

  const inactive = !statistics?.generalStatistics;

  return (
    <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
      <div className="card-body pt-0">
        <div className="d-flex flex-stack fs-4 py-3 mt-5">
          <div className="fw-bolder rotate collapsible">Evaluation</div>
        </div>
        <div className="border-bottom-dark-gray" />
        <div className="py-2">
          <Tooltip
            show={inactive ? false : undefined}
            tooltipText={
              <div className="text-white text-left">
                Last Update:{" "}
                {statistics?.generalStatistics?.lastUpdate
                  ? formatDateTime(statistics?.generalStatistics?.lastUpdate)
                  : "-"}
              </div>
            }
          >
            <div>
              <StatsWidget
                title={"Activity"}
                text={getStatisticText(statistics?.generalStatistics?.activity.rating, true)}
                subtext={`Top ${statistics?.generalStatistics?.activity.percentage || "-"}% out of ${
                  statistics?.generalStatistics?.activity.totalCustomers || "-"
                } Customers`}
                color={getRatingColor(statistics?.generalStatistics?.activity.rating)}
                loading={loading}
                inactive={inactive}
              />
              <StatsWidget
                title={"Turnover"}
                text={getStatisticText(statistics?.generalStatistics?.turnover.rating, true)}
                subtext={`Top ${statistics?.generalStatistics?.turnover.percentage || "-"}% with ${
                  statistics?.generalStatistics?.turnover.total !== undefined
                    ? formatCurrency(statistics?.generalStatistics?.turnover.total, BASE_CURRENCY)
                    : "-"
                } total turnover`}
                color={getRatingColor(statistics?.generalStatistics?.turnover.rating)}
                loading={loading}
                inactive={inactive}
              />
              <StatsWidget
                title={"Payments"}
                text={getStatisticText(statistics?.generalStatistics?.payments.rating)}
                subtext={
                  statistics?.generalStatistics?.payments.rating !== undefined
                    ? statistics.generalStatistics.payments.rating === 1
                      ? "Very reliable"
                      : statistics.generalStatistics.payments.rating === 0
                      ? "Mostly in time"
                      : "Late payments and reminders"
                    : "No analysis available"
                }
                color={getRatingColor(statistics?.generalStatistics?.payments.rating)}
                loading={loading}
                inactive={inactive}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
});

export default CustomerPageStats;
