import React, { PureComponent, useCallback, useContext, useMemo } from "react";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { isEqual, cloneDeep } from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CloseButton, Modal } from "react-bootstrap";
import { Input } from "../../../common/Input";
import { Commodity, CommodityTimelineEntry, SupplierPricesExtended } from "../../../../model/commodity.types";
import {
  getArticleProperty,
  getCommodityTimelineEntry,
  T_PRICEUPDATED,
  UPDATECOMMODITYSUPPLIERPRICES,
} from "../../../../utils/commodityUtils";
import { DataContextInternal, DataContextInternalType } from "../../../../context/dataContext";
import BaseListing from "../../../common/BaseListing";
import {
  SupplierExtended,
  T_S_ADDCOMMODITYTOSUPPLIER,
  T_S_ADDFINISHEDPRODUCTSUPPLIER,
} from "../../../../model/supplier.types";
import { callFunction, SUPPLIER, transaction } from "../../../../services/dbService";
import Search from "../../../common/Search";
import { doFuseSearch } from "../../../../utils/baseUtils";
import { paginate, PaginationState } from "../../../common/Pagination";
import { PropertyType } from "../../../../utils/propertyUtils";
import { Property } from "../../../../model/property.types";
import { getSupplierTimelineEntry } from "../../../../utils/supplierUtils";
import { FinishedProduct, FinishedProductTimelineEntry } from "../../../../model/finishedProduct.types";
import { isFinishedProduct } from "../../../../utils/finishedProductUtils";
import { extendCommodity, extendFinishedProduct } from "../../../../utils/dataTransformationUtils";
import { InternalArticle } from "../../../../utils/productArticleUtils";

interface SelectArticlesModalProps extends RouteComponentProps {
  supplier: SupplierExtended;
  context: DataContextInternalType;
}

interface SelectArticlesModalState extends PaginationState {
  show: boolean;
  selectedArticles: Array<Commodity | FinishedProduct>;
  availableArticles: Array<Commodity | FinishedProduct>;
  search: string;
  view: number;
  saving: boolean;
}

class SelectArticlesModal extends PureComponent<SelectArticlesModalProps, SelectArticlesModalState> {
  constructor(props: SelectArticlesModalProps) {
    super(props);

    this.state = {
      show: false,
      selectedArticles: [],
      availableArticles: [],
      search: "",
      currentPage: 1,
      pageSize: 15,
      view: 0,
      saving: false,
    };
  }

  componentDidMount() {
    this.getAvailableArticles();
  }

  componentDidUpdate(prevProps: Readonly<SelectArticlesModalProps>) {
    // check if new suppliers were added to commodities to delete them from availableCommodities
    if (
      !isEqual(prevProps.context.commodity, this.props.context.commodity) ||
      !isEqual(prevProps.context.finishedProduct, this.props.context.finishedProduct)
    ) {
      this.getAvailableArticles();
    }
  }

  handleShow = () => this.setState({ show: true, view: 0, selectedArticles: [], search: "" });
  handleHide = () => this.setState({ show: false });

  handleNext = () => {
    const { view } = this.state;
    if (view < 1) this.setState({ view: view + 1 });
  };

  handleBack = () => {
    const { view } = this.state;
    if (view > 0) this.setState({ view: view - 1 });
  };

  handleAddSelectedArticles = async () => {
    const { supplier } = this.props;
    const { selectedArticles } = this.state;
    const commodityTimelineEntry: CommodityTimelineEntry | FinishedProductTimelineEntry = getCommodityTimelineEntry(
      T_PRICEUPDATED,
      undefined,
      supplier._id.toString()
    );

    let errorCount = 0;
    let successCount = 0;
    this.setState({ saving: true });
    try {
      for (const commodity of selectedArticles) {
        const isFP = isFinishedProduct(commodity);
        const newSupplierPrice: SupplierPricesExtended = {
          _id: new BSON.ObjectId(),
          prices: [],
          supplier,
          contingent: Infinity,
        };
        // New supplier
        commodityTimelineEntry.post = {
          suppliers: [{ ...newSupplierPrice, supplier: supplier ? supplier._id : undefined }],
        };
        const result: Realm.Services.MongoDB.UpdateResult<BSON.ObjectId> | false = await callFunction(
          UPDATECOMMODITYSUPPLIERPRICES,
          [
            commodity._id.toString(),
            { id: this.props.supplier._id, supplier: newSupplierPrice },
            true,
            commodityTimelineEntry,
            isFP,
          ]
        );
        if (result && result.modifiedCount > 0) {
          successCount++;
        } else {
          errorCount++;
        }
      }
      const selectedCommoditiesNames = [];
      const selectedFinishedProductNames = [];
      for (let i = 0; i < selectedArticles.length; i++) {
        const sA = selectedArticles[i];
        if (isFinishedProduct(sA)) selectedFinishedProductNames.push(sA.title.en);
        else selectedCommoditiesNames.push(sA.title.en);
      }

      const timelineEntries = [];
      if (selectedCommoditiesNames.length > 0) {
        timelineEntries.push(
          getSupplierTimelineEntry(T_S_ADDCOMMODITYTOSUPPLIER, {
            name: selectedCommoditiesNames.join("; "),
          })
        );
      }
      if (selectedFinishedProductNames.length > 0) {
        timelineEntries.push(
          getSupplierTimelineEntry(T_S_ADDFINISHEDPRODUCTSUPPLIER, {
            name: selectedFinishedProductNames.join("; "),
          })
        );
      }

      const resSupplier = await transaction([
        {
          collection: SUPPLIER,
          filter: { _id: supplier._id },
          push: { timeline: { $each: timelineEntries } },
        },
      ]);
      if (resSupplier) {
        successCount++;
      } else {
        errorCount++;
      }
    } finally {
      if (successCount > 0 && errorCount === 0) {
        toast.success("Article prices updated successfully");
      } else if (errorCount > 0 && successCount === 0) {
        toast.error("Error updating article prices");
      } else if (errorCount > 0 && successCount > 0) {
        toast.error("Error updating some article prices");
      }
      this.setState({ saving: false });
    }
    this.handleHide();
  };

  handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ search: e.target.value });

  handleClickCheckbox = (id: string) => {
    const { availableArticles } = this.state;
    let selectedArticles = cloneDeep(this.state.selectedArticles);
    const checkedArticles = availableArticles.filter((c) => c._id.toString() === id);

    // if checkedArticles is already in selectedArticles then commodity is deleted from selectedArticles
    if (selectedArticles.some((sC) => sC._id.toString() === id) && selectedArticles.length > 0) {
      selectedArticles = selectedArticles.filter((sC) => sC._id.toString() !== id);
    }
    // else push checkedArticle to selectedArticles
    else {
      selectedArticles.push(checkedArticles[0]);
    }
    this.setState({ selectedArticles });
  };

  handlePageChange = (page: number) => this.setState({ currentPage: page });

  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize, currentPage: 1 });

  getAvailableArticles = () => {
    const { commodity, finishedProduct } = this.props.context;
    const articles: Array<InternalArticle> = [];
    articles.push(...commodity);
    articles.push(...finishedProduct);
    // Filter disabled, not approved articles and articles supplier already has listed
    const availableArticles = articles.filter(
      (c) => c.approved && !c.disabled && !c.suppliers.some((s) => s.supplier === this.props.supplier._id.toString())
    );
    this.setState({ availableArticles });
  };

  render() {
    const { show, availableArticles, selectedArticles, search, currentPage, pageSize, view, saving } = this.state;
    const headers = [
      { title: "" },
      { title: "Article Title" },
      { title: "Category" },
      { title: "Composition" },
      { title: "Organic" },
    ];
    const filteredArticles = search.trim()
      ? doFuseSearch(availableArticles, search, ["title.en", "subtitle.en"])
      : availableArticles;

    return (
      <>
        <button className="btn btn-outline btn-outline-light btn-sm" onClick={this.handleShow}>
          Add Articles
        </button>
        <Modal contentClassName={"bg-dark"} size={"xl"} show={show} onHide={this.handleHide} centered={true}>
          <Modal.Header className="border-0 pb-0 pt-2">
            <h1 className="m-5">Add Articles to Supplier</h1>
            <CloseButton variant={"white"} onClick={this.handleHide} />
          </Modal.Header>
          <Modal.Body className="mx-5 py-0">
            {view === 0 ? (
              <>
                {availableArticles.length > 0 && (
                  <div className="w-50 align-self-end ml-auto my-0">
                    <Search onSearch={this.handleSearch} value={search} />
                  </div>
                )}
                <div className="my-15">
                  {filteredArticles.length > 0 ? (
                    <BaseListing
                      headerDefinition={headers}
                      bodyContent={
                        <>
                          {paginate(filteredArticles, currentPage, pageSize).map((a) => (
                            <FilteredArticleRow
                              key={a._id.toString()}
                              article={a}
                              selectedArticles={selectedArticles}
                              onClickCheckbox={this.handleClickCheckbox}
                            />
                          ))}
                        </>
                      }
                      documents={filteredArticles}
                      baseSize={15}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                      onPageSizeChange={this.handlePageSizeChange}
                    />
                  ) : (
                    <div className="text-white fs-6">
                      No approved and enabled article left to add to supplier or search not found.
                    </div>
                  )}
                </div>
              </>
            ) : (
              <BaseListing
                headerDefinition={headers.filter((h) => h !== headers[0])}
                bodyContent={
                  <>
                    {selectedArticles.map((a) => (
                      <SelectedArticleRow key={a._id.toString()} article={a} />
                    ))}
                  </>
                }
              />
            )}
          </Modal.Body>
          <Modal.Footer className="mx-5">
            {view === 1 ? (
              <>
                <button className="btn  btn-text-white btn-sm " onClick={this.handleBack}>
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-outline btn-outline-light btn-sm "
                  onClick={this.handleAddSelectedArticles}
                  disabled={saving}
                >
                  Add Selected Articles
                </button>
              </>
            ) : (
              <>
                <button className="btn  btn-text-white btn-sm" onClick={this.handleHide}>
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-outline btn-outline-light btn-sm "
                  onClick={this.handleNext}
                  disabled={selectedArticles.length === 0}
                >
                  Next
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

interface FilteredArticleRowProps {
  selectedArticles: Array<InternalArticle>;
  article: InternalArticle;
  onClickCheckbox: (id: string) => void;
}

const FilteredArticleRow: React.FC<FilteredArticleRowProps> = ({ selectedArticles, article, onClickCheckbox }) => {
  const context = useContext(DataContextInternal);

  const handleClickCheckbox = useCallback(() => onClickCheckbox(article._id.toString()), [article, onClickCheckbox]);

  const articleExtended = useMemo(
    () => (isFinishedProduct(article) ? extendFinishedProduct(article, context) : extendCommodity(article, context)),
    [article.properties, context.property] // Since we only need the properties this limited dependencies are intended
  );

  const checked = useMemo(
    () => selectedArticles.some((sC) => sC._id.toString() === article._id.toString()),
    [selectedArticles, article]
  );

  const category = useMemo(
    () => (getArticleProperty(articleExtended.properties, PropertyType.CATEGORY) as Property)?.description.en || "-",
    [articleExtended.properties]
  );

  const composition = useMemo(
    () => (getArticleProperty(articleExtended.properties, PropertyType.COMPOSITION) as Property)?.description.en || "-",
    [articleExtended.properties]
  );

  return (
    <tr className="text-white fs-6">
      <td className="align-middle">
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <Input type="checkbox" className="form-check-input" checked={checked} onClick={handleClickCheckbox} />
        </div>
      </td>
      <td className="align-middle">
        {article.title.en}
        <br />
        {article.subtitle.en}
      </td>
      <td className="align-middle">{category}</td>
      <td className="align-middle">{composition}</td>
      <td className="align-middle">{article.organic ? "Yes" : "No"}</td>
    </tr>
  );
};

interface SelectedArticleRowProps {
  article: InternalArticle;
}

const SelectedArticleRow: React.FC<SelectedArticleRowProps> = ({ article }) => {
  const context = useContext(DataContextInternal);

  const articleExtended = useMemo(
    () => (isFinishedProduct(article) ? extendFinishedProduct(article, context) : extendCommodity(article, context)),
    [article.properties, context.property] // Since we only need the properties this limited dependencies are intended
  );

  const category = useMemo(
    () => (getArticleProperty(articleExtended.properties, PropertyType.CATEGORY) as Property)?.description.en || "-",
    [articleExtended.properties]
  );

  const composition = useMemo(
    () => (getArticleProperty(articleExtended.properties, PropertyType.COMPOSITION) as Property)?.description.en || "-",
    [articleExtended.properties]
  );

  return (
    <tr className="text-white fs-6">
      <td className="align-middle">
        {article.title.en}
        {article.subtitle.en && <span> - </span>}
        {article.subtitle.en}
      </td>
      <td className="align-middle">{category}</td>
      <td className="align-middle">{composition}</td>
      <td className="align-middle">{article.organic ? "Yes" : "No"}</td>
    </tr>
  );
};

export default withRouter(SelectArticlesModal);
