import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { CC_STATE, CustomerContractExtended } from "../../model/customerContract.types";
import { DataContextCustomerType, DataContextInternalType } from "../../context/dataContext";
import userService from "../../services/userService";
import { getContractNumber, getContractStateDescriptions, isCustomerContract } from "../../utils/customerContractUtils";
import OpenCommodityIcon from "../common/OpenCommodityIcon";
import { getUserName, INTERNAL } from "../../utils/userUtils";
import { formatCurrency, formatDate } from "../../utils/baseUtils";
import { CustomerCustomerContractExtended } from "../../model/customer/customerCustomerContract.types";
import { allCallsArchived } from "../../utils/contractUtils";
import UpdateCommoditySnapshotModal from "../common/internal/modals/UpdateCommoditySnapshotModal";
import ChangeCustomerReferenceModal from "../orders/internal/customerOrder/modals/ChangeCustomerReferenceModal";
import { formatArticleUnit } from "../../utils/productArticleUtils";
import CustomerContractAdjustValidityPeriodModal from "./internal/modals/CustomerContractAdjustValidityPeriodModal";
import CustomerContractAdjustCallQuantityModal from "./internal/modals/CustomerContractAdjustCallQuantityModal";

interface CustomerContractSummaryProps {
  contract: CustomerContractExtended | CustomerCustomerContractExtended;
  context: DataContextInternalType | DataContextCustomerType;
}

const CustomerContractSummary: React.FC<CustomerContractSummaryProps> = ({ contract, context }) => {
  const { totalAmount, restAmount, minimumCallQuantity } = contract.contractInformation;
  const isInternal = useMemo(() => userService.getUserType() === INTERNAL, []);
  const stateDescription = useMemo(() => getContractStateDescriptions(contract), [contract]);
  const gotOpenAmount = useMemo(
    () => contract.contractInformation.restAmount > 0,
    [contract.contractInformation.restAmount]
  );

  return (
    <div className="card mb-5 responsive-content-card mt-5 mt-xl-0 mb-xl-8 bg-white">
      <div className="card-body">
        <div className="d-flex flex-center flex-column py-5">
          <span className="fs-2 text-gray-800 fw-bolder mb-3">{getContractNumber(contract)}</span>
          {isInternal && <span className="badge badge-pill badge-success mb-3">Customer Contract</span>}
          <div className="fs-6 fw-bold text-muted ">
            {contract.commodity.title.en}
            <OpenCommodityIcon article={contract.commodity} />
          </div>
          {isInternal && <div className="fw-bold text-muted mb-3">{contract.commodity.subtitle.en}</div>}
          {contract.state === CC_STATE.CANCELED ? (
            <div className="mb-6 mt-3">
              <span className="badge fw-bolder badge-lg my-2 badge-danger">Canceled</span>
            </div>
          ) : (
            <div className="mb-6 mt-3">
              <span className="badge fw-bolder badge-lg my-2 badge-success">
                {contract.state === CC_STATE.READY && restAmount === totalAmount
                  ? "Ready"
                  : restAmount === 0 && allCallsArchived(context, contract)
                  ? "Fulfilled"
                  : restAmount < totalAmount
                  ? "Partially Fulfilled"
                  : "Open"}
              </span>
            </div>
          )}
        </div>
        <div className="d-flex flex-stack fs-4 py-3">
          <div className="fw-bolder">Contract Details</div>
        </div>
        <div className="border-bottom-dark-gray" />
        <div className="pb-5 fs-6">
          <div className="fw-bolder mt-5">Amount</div>
          <div className="text-muted">
            {totalAmount} {formatArticleUnit(contract.commodity.unit)}
          </div>
          <div className="fw-bolder mt-5">Open Amount</div>
          <div className="text-muted">
            {restAmount} {formatArticleUnit(contract.commodity.unit)}
          </div>
          <div className="fw-bolder mt-5">
            Minimum Call-Off Quantity
            {gotOpenAmount && isCustomerContract(contract) && (
              <CustomerContractAdjustCallQuantityModal contract={contract} />
            )}
          </div>
          <div className="text-muted">
            {minimumCallQuantity} {formatArticleUnit(contract.commodity.unit)}
          </div>
          <div className="fw-bolder mt-5">Status</div>
          <div className={contract.state === CC_STATE.CANCELED ? "text-danger" : "text-warning"}>
            {stateDescription.title}
          </div>
          <div className="fw-bolder mt-5">
            Validity Period
            {gotOpenAmount && isCustomerContract(contract) && (
              <CustomerContractAdjustValidityPeriodModal contract={contract} />
            )}
          </div>
          <div className="text-muted">
            {formatDate(contract.validityPeriod.start)} to {formatDate(contract.validityPeriod.end)}
          </div>
          <div className="fw-bolder mt-5">Last Update</div>
          <div className="text-muted">
            {formatDate(
              contract.timeline.length > 0 ? contract.timeline[contract.timeline.length - 1].date : contract.createdAt
            )}
          </div>
          {isInternal && (
            <>
              <div className="fw-bolder mt-5">Customer</div>
              <div>
                <Link className="text-muted custom-link" to={"/customer/" + contract.company._id.toString()}>
                  {contract.company.name}
                </Link>
              </div>
            </>
          )}
          <div className="fw-bolder mt-5">{isInternal ? "Turnover" : "Total Price"}</div>
          <div className="text-muted">
            {formatCurrency(
              contract.priceInformation.totalPrice * (1 - (contract.priceInformation.discount || 0) / 100),
              contract.priceInformation.currency
            )}
          </div>
          <div className="fw-bolder mt-5">Created by</div>
          <div className="text-muted">{getUserName(contract.person)}</div>
          <div className="fw-bolder mt-5">
            Reference Number
            {isInternal && <ChangeCustomerReferenceModal order={contract as CustomerContractExtended} />}
          </div>
          <div className="text-muted">{contract.customerReference ? contract.customerReference : "-"}</div>
          {isInternal && (
            <UpdateCommoditySnapshotModal
              order={contract as CustomerContractExtended}
              context={context as DataContextInternalType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerContractSummary;
