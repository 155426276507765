import React, { CSSProperties, PureComponent } from "react";
import Pagination from "./Pagination";

export interface BaseListingHeaderDefinition {
  title: string | JSX.Element;
  className?: string;
  style?: object;
  sortColumn?: string;
  colSpan?: number;
}

interface BaseListingProps {
  headerDefinition: Array<BaseListingHeaderDefinition>;
  documents?: Array<any>;
  bodyContent: JSX.Element;
  currentPage?: number;
  pageSize?: number;
  baseSize?: number;
  noHover?: boolean;
  wrapperStyle?: CSSProperties;
  disabledSmallPadding?: boolean;
  sortColumn?: { column: string; order: "asc" | "desc" };
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onSort?: (column: string) => void;
}

interface BaseListingState {}

class BaseListing extends PureComponent<BaseListingProps, BaseListingState> {
  resolveSortArrow(column: string) {
    const { sortColumn } = this.props;
    if (sortColumn && sortColumn.column === column)
      if (sortColumn.order === "asc") return "fas fa-sort-up";
      else return "fas fa-sort-down";
    else return "fas fa-sort";
  }

  render() {
    const {
      headerDefinition,
      documents,
      bodyContent,
      currentPage,
      pageSize,
      baseSize,
      noHover,
      wrapperStyle,
      disabledSmallPadding,
      onPageChange,
      onPageSizeChange,
      onSort,
    } = this.props;

    return (
      <div className="dataTables_wrapper">
        <div className="table-responsive overflow-visible" style={wrapperStyle || {}}>
          <table
            className={
              "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer " + (noHover ? "no-hover" : "")
            }
          >
            <thead style={{ pointerEvents: "inherit" }}>
              <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0 border-bottom-dark-gray">
                {headerDefinition.map((def) => (
                  <th
                    key={def.title.toString()}
                    className={def.className}
                    style={def.style}
                    onClick={def.sortColumn && onSort ? () => onSort(def.sortColumn!) : undefined}
                    colSpan={def.colSpan}
                  >
                    <span className={def.sortColumn ? "pointer" : ""}>
                      {def.sortColumn && onSort && (
                        <i className={"mr-2 fs-5 " + this.resolveSortArrow(def.sortColumn)} />
                      )}
                      {def.title}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={"fw-bold text-gray-600 " + (disabledSmallPadding ? "" : "table-row-small-padding")}>
              {bodyContent}
            </tbody>
          </table>
        </div>
        {documents && pageSize && documents.length > pageSize && currentPage && onPageSizeChange && onPageChange && (
          <Pagination
            itemsCount={documents.length}
            pageSize={pageSize}
            baseSize={baseSize}
            onPageChange={onPageChange}
            currentPage={currentPage}
            onPageSizeChange={onPageSizeChange}
          />
        )}
      </div>
    );
  }
}

export default BaseListing;
