import _ from "lodash";
import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import { callPushToArray, formatDate, toAbsoluteUrl } from "../../../utils/baseUtils";
import { resolveFilePath, shortenAlias } from "../../../utils/fileUtils";
import {
  getSupplierOrderTimelineEntry,
  SO_CUSTOMSCLEARANCE,
  SO_FILETYPES,
  SO_FORWARDINGORDER,
  SO_ORDERDOCUMENT,
  SO_SHIPPINGMARK,
  SO_SPECIFICATION,
  SO_STORAGEORDER,
} from "../../../utils/supplierOrderUtils";
import {
  EXTENDED_ORDER_TYPES,
  getOrderNumber,
  isCustomerOrder,
  isSampleOrder,
  isSupplierOrder,
} from "../../../utils/orderUtils";
import {
  CO_CUSTOMERFILE,
  CO_DELIVERYNOTE,
  CO_FILETYPES,
  CO_FORWARDINGORDER,
  CO_ORDERCONFIRMATION,
  CO_OTHER,
  CO_REQUESTCONFIRMATION,
  CO_TRANSPORTCOST,
  getCustomerOrderTimelineEntry,
  getDocumentVersion,
} from "../../../utils/customerOrderUtils";
import { D_MASTERSPECIFICATION, D_OTHER, D_SUPPLIERSPECIFICATION, D_TYPEOPTIONS } from "../../../utils/commodityUtils";
import WorkflowUploadDocumentModal from "../internal/supplierOrder/tabPanels/workflowTabPanels/modals/WorkflowUploadDocumentModal";
import {
  DataContextAnonymousType,
  DataContextCustomerType,
  DataContextInternalType,
  isInternalContext,
} from "../../../context/dataContext";
import { getSampleOrderTimelineEntry, SAMO_FILETYPES } from "../../../utils/sampleOrderUtils";
import { BatchFile } from "../../../model/batch.types";
import SimpleConfirmationModal from "../../common/SimpleConfirmationModal";
import {
  Action,
  CUSTOMERCONTRACT,
  CUSTOMERORDER,
  SAMPLEORDER,
  SUPPLIERORDER,
  transaction,
} from "../../../services/dbService";
import { CO_CANCELED, CO_REQUESTEDSTOCK, CO_T_DOCUMENTREMOVED, T_WAREHOUSE } from "../../../model/customerOrder.types";
import { SO_T_DOCUMENTREMOVED } from "../../../model/supplierOrder.types";
import { SAMO_T_DOCUMENTREMOVED } from "../../../model/sampleOrder.types";
import {
  CC_CONTRACTCONFIRMATION,
  CC_FILETYPES,
  getContractNumber,
  getCustomerContractTimelineEntry,
  isCustomerContract,
  isCustomerCustomerContract,
} from "../../../utils/customerContractUtils";
import { CO_FOOTER_HTML, createPDF } from "../../../utils/pdfUtils";
import { REQUESTTEXTSHORT, STANDARDTEXTSHORT } from "../../../utils/pdf/templateUtils";
import { createRequestConfirmationHTML } from "../../../utils/pdf/requestConfirmationGenerationUtils";
import { createContractConfirmationHTML } from "../../../utils/pdf/customerContractConfirmationGenerationUtils";
import { EXTENDED_CONTRACT_TYPES } from "../../../utils/contractUtils";
import ConfirmCustomerOrderModal from "../internal/customerOrder/modals/ConfirmCustomerOrderModal";
import CreateShippingMarkModal from "../internal/supplierOrder/modals/CreateShippingMarkModal";
import CreateCustomsClearanceInstructionsModal from "../internal/supplierOrder/modals/CreateCustomsClearanceInstructionsModal";
import ChangeSupplierOrderTermsModal from "../internal/supplierOrder/modals/ChangeSupplierOrderTermsModal";
import { getSupplierSpecs } from "../../../utils/productArticleUtils";
import Tooltip from "../../common/Tooltip";
import { CustomerContractTimelineType } from "../../../model/customerContract.types";

interface OrderDocumentsProps {
  order: EXTENDED_ORDER_TYPES | EXTENDED_CONTRACT_TYPES;
  context: DataContextInternalType | DataContextCustomerType | DataContextAnonymousType;
}

interface OrderDocumentsState {
  saving: boolean;
}

class OrderDocuments extends PureComponent<OrderDocumentsProps, OrderDocumentsState> {
  fileUploadRef?: WorkflowUploadDocumentModal;

  constructor(props: OrderDocumentsProps) {
    super(props);
    this.state = { saving: false };
  }

  setRef = (ref: WorkflowUploadDocumentModal | null) => {
    if (ref) this.fileUploadRef = ref;
  };

  handleClickMissingDocument = (fileType: string) => {
    const fileSelectOption = SO_FILETYPES.concat(CO_FILETYPES).find((f) => f.value === fileType);
    this.fileUploadRef?.handleShow(fileSelectOption);
  };

  handleRemoveDocument = async (_id: BSON.ObjectID, fileType: string) => {
    const { order } = this.props;
    try {
      const collection = isCustomerOrder(order)
        ? CUSTOMERORDER
        : isCustomerContract(order)
        ? CUSTOMERCONTRACT
        : isSupplierOrder(order)
        ? SUPPLIERORDER
        : SAMPLEORDER;
      const payload = { type: fileType, reference: _id.toString() };
      const timeline = isCustomerContract(order)
        ? getCustomerContractTimelineEntry(CustomerContractTimelineType.DOCUMENT_REMOVED, payload)
        : isCustomerOrder(order)
        ? getCustomerOrderTimelineEntry(CO_T_DOCUMENTREMOVED, payload)
        : isSupplierOrder(order)
        ? getSupplierOrderTimelineEntry(SO_T_DOCUMENTREMOVED, payload)
        : getSampleOrderTimelineEntry(SAMO_T_DOCUMENTREMOVED, payload);
      const actions: Array<Action> = [
        { collection, filter: { _id: order._id }, pull: { files: { _id } }, push: { timeline } },
      ];
      const res = await transaction(actions);
      if (res) {
        toast.success("Document successfully removed");
      } else {
        toast.error("Error removing document");
      }
    } catch (e) {
      console.error("ERROR:", e);
    }
  };

  handleGenerateContractConfirmation = async () => {
    const { order } = this.props;
    const isCC = isCustomerContract(order);
    if (!isCC) return;
    this.setState({ saving: true });

    try {
      const path = await createPDF(
        createContractConfirmationHTML(order, STANDARDTEXTSHORT),
        "Contract-Confirmation-" + order.contractNo,
        order.company._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: CO_FOOTER_HTML,
        }
      );
      if (path) {
        window.open(resolveFilePath(path));
        await callPushToArray(CUSTOMERCONTRACT, order._id.toString(), "files", {
          _id: new BSON.ObjectId(),
          date: new Date(),
          path,
          type: CC_CONTRACTCONFIRMATION,
        });
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  handleGenerateRequestConfirmation = async () => {
    const { order, context } = this.props;
    const isCO = isCustomerOrder(order);
    if (!isCO) return;
    this.setState({ saving: true });

    try {
      const path = await createPDF(
        createRequestConfirmationHTML(order, REQUESTTEXTSHORT, context),
        "Request-Confirmation-" + order.orderNo,
        order.company._id.toString(),
        {
          marginLeft: "2cm",
          marginBottom: "4.2cm",
          footerHtml: CO_FOOTER_HTML,
        }
      );
      if (path) {
        window.open(resolveFilePath(path));
        await callPushToArray(CUSTOMERORDER, order._id.toString(), "files", {
          _id: new BSON.ObjectId(),
          date: new Date(),
          path,
          type: CO_REQUESTCONFIRMATION,
        });
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { order, context } = this.props;
    const { saving } = this.state;
    const isCO = isCustomerOrder(order);
    const isCC = isCustomerContract(order) || isCustomerCustomerContract(order);
    const isSO = isSupplierOrder(order);
    const isSAMO = isSampleOrder(order);
    const isInternal = isInternalContext(context);
    const noCustomerFile = [CO_TRANSPORTCOST, CO_FORWARDINGORDER]; // filter customer order documents customers should not see
    const fileTypes = isCO ? CO_FILETYPES : isCC ? CC_FILETYPES : isSAMO ? SAMO_FILETYPES : SO_FILETYPES;
    const docsFilteredByView = order.files.filter(
      (orderFile) => (!isInternal && !noCustomerFile.includes(orderFile.type)) || isInternal
    );
    const documents = _.orderBy(
      docsFilteredByView.map((f) => {
        return { file: f, type: fileTypes.find((t) => t.value === f.type) };
      }),
      (f) => f.file.date,
      "desc"
    );
    let missingDocumentTypes = [CO_DELIVERYNOTE, SO_SHIPPINGMARK, CO_CUSTOMERFILE, D_OTHER];
    if (!(!isInternal && order.commodity.hiddenSupplier))
      missingDocumentTypes = missingDocumentTypes.concat([SO_SPECIFICATION]);
    if (isInternal) {
      missingDocumentTypes = missingDocumentTypes.concat(noCustomerFile);
    }
    const missingOrderDocuments = fileTypes.filter(
      (fT) => !missingDocumentTypes.includes(fT.value) && !documents.find((d) => d.type?.value === fT.value)
    );
    const batchDocuments = isCO
      ? (order.usedBatches
          ?.map((uB) => {
            return { file: uB.ownCoA, type: `Certificate of Analysis RB${uB.identifier}` };
          })
          .filter((f) => f && f.file) as Array<{ file: BatchFile; type: string }>)
      : null;
    const filteredDocuments =
      isCO && order.supplier && !(!isInternal && order.commodity.hiddenSupplier)
        ? getSupplierSpecs(order.commodity, order.supplier._id.toString())
        : order.commodity.documents.filter((d) => [D_MASTERSPECIFICATION].includes(d.type));
    const commodityDocuments = filteredDocuments.map((d) => {
      return { file: d, type: D_TYPEOPTIONS.find((t) => t.value === d.type) };
    });
    const serviceDocuments = isCO
      ? order.services.flatMap((s) =>
          s.files.map((f) => {
            return { file: f, type: { value: s.service.serviceNo, label: `Service Result - ${s.service.title.en}` } };
          })
        )
      : null;

    return (
      <div className="card bg-white">
        <div className="card-header border-0 mt-5">
          <div className="card-title flex-column">
            <h2 className="mb-1">Order Documents</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="table-responsive mt-5 pt-2 bg-light2">
            <table className="table table-row-gray-100 align-middle">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0">Type</th>
                  <th className="border-bottom-0">Title</th>
                  <th className="border-bottom-0">Uploaded</th>
                  <th className="border-bottom-0">File</th>
                  <th className="border-bottom-0 text-right" />
                </tr>
              </thead>
              <tbody>
                {documents.map((d) => (
                  <tr key={d.file._id.toString()}>
                    <td className="align-middle">
                      <span className="text-white fw-bolder">
                        {d.type?.label ?? "unknown"}{" "}
                        {
                          <span className="text-muted">
                            {!_.includes(
                              [
                                CO_OTHER,
                                CO_CUSTOMERFILE,
                                SO_SHIPPINGMARK,
                                SO_CUSTOMSCLEARANCE,
                                SO_FORWARDINGORDER,
                                CO_FORWARDINGORDER,
                                SO_STORAGEORDER,
                                CO_TRANSPORTCOST,
                              ],
                              d.file.type
                            ) && <span>{" - Version " + getDocumentVersion(order, d.file.date, d.file.type)}</span>}
                          </span>
                        }
                      </span>
                    </td>
                    <td className="align-middle">
                      <Tooltip
                        tooltipText={
                          <span className="text-white">
                            At least one customer order should be delivered with a clean label. Please check that
                            deliveries are correctly labeled
                          </span>
                        }
                        show={
                          isSO &&
                          d.file.type === SO_SHIPPINGMARK &&
                          order.customerOrders.some((cO) => cO.terms?.cleanLabel)
                            ? undefined
                            : false
                        }
                      >
                        <a href={resolveFilePath(d.file.path)} target="_blank" rel="noopener noreferrer">
                          <span className="fw-bold text-white">{shortenAlias(d.file.path)}</span>
                        </a>
                      </Tooltip>
                    </td>
                    <td className="align-middle">
                      <span className="text-success">{formatDate(d.file.date)}</span>
                    </td>
                    <td className="align-middle">
                      <Tooltip
                        tooltipText={
                          <span className="text-white">
                            At least one customer order should be delivered with a clean label. Please check that
                            deliveries are correctly labeled
                          </span>
                        }
                        show={
                          isSO &&
                          d.file.type === SO_SHIPPINGMARK &&
                          order.customerOrders.some((cO) => cO.terms?.cleanLabel)
                            ? undefined
                            : false
                        }
                      >
                        <a href={resolveFilePath(d.file.path)} target="_blank" rel="noopener noreferrer">
                          <img
                            alt="pdf"
                            className="w-30px me-3"
                            src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                          />
                        </a>
                      </Tooltip>
                    </td>
                    <td className="align-middle" style={{ textAlign: "right" }}>
                      {isInternal && order.state !== CO_CANCELED && (
                        <SimpleConfirmationModal.SimpleConfirmationModalButton
                          size="md"
                          modalTitle="Remove Document"
                          onConfirm={() => this.handleRemoveDocument(d.file._id, d.file.type)}
                          confirmButtonText="Confirm"
                          buttonText={<i className="fa fa-trash fs-7 text-muted text-hover-danger p-0" />}
                          buttonClasses="btn btn-text btn-sm px-2 py-0 float-right"
                          cancelButtonText="Close"
                          modalDescription={
                            <span className="text-muted">
                              Do you really want to remove the document
                              <span className="text-white"> {shortenAlias(d.file.path)} </span>
                              from {isCC ? `Contract ${getContractNumber(order)}` : `Order ${getOrderNumber(order)}`}?
                            </span>
                          }
                        />
                      )}
                    </td>
                  </tr>
                ))}
                {batchDocuments &&
                  batchDocuments.map((d) => (
                    <tr key={d.file._id.toString()}>
                      <td className="align-middle">
                        <span className="text-white fw-bolder">{d.type ?? "unknown"}</span>
                      </td>
                      <td className="align-middle">
                        <a href={resolveFilePath(d.file.path)} target="_blank" rel="noopener noreferrer">
                          <span className="fw-bold text-white">{shortenAlias(d.file.path)}</span>
                        </a>
                      </td>
                      <td className="align-middle">
                        <span className="text-success">{formatDate(d.file.date)}</span>
                      </td>
                      <td className="align-middle" style={{ textAlign: "right" }}>
                        <a href={resolveFilePath(d.file.path)} target="_blank" rel="noopener noreferrer">
                          <img
                            alt="pdf"
                            className="w-30px me-3"
                            src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                          />
                        </a>
                      </td>
                      <td />
                    </tr>
                  ))}
                {missingOrderDocuments.map((d, idx) => (
                  <tr key={idx}>
                    <td className="align-middle">
                      <span className="text-white fw-bolder">{d.label}</span>
                    </td>
                    <td className="align-middle">
                      <span
                        className={"fw-bold text-danger" + (isInternal ? " cursor-pointer" : "")}
                        onClick={
                          isInternal && order.state !== CO_CANCELED
                            ? () => this.handleClickMissingDocument(d.value)
                            : undefined
                        }
                      >
                        missing
                      </span>
                    </td>
                    <td />
                    <td>
                      {isInternal && order.state !== CO_CANCELED && (
                        <>
                          {d.value === SO_ORDERDOCUMENT && isSO && (
                            <ChangeSupplierOrderTermsModal generate={true} order={order} context={context} />
                          )}
                          {d.value === CO_ORDERCONFIRMATION && !isCC && !isSO && isCO && (
                            <ConfirmCustomerOrderModal
                              order={order}
                              context={context}
                              stateChangeToProcessing={
                                order.transport === T_WAREHOUSE && order.state === CO_REQUESTEDSTOCK
                              }
                              generate={true}
                            />
                          )}
                          {d.value === CO_REQUESTCONFIRMATION && !isCC && (
                            <SimpleConfirmationModal.SimpleConfirmationModalButton
                              onConfirm={this.handleGenerateRequestConfirmation}
                              confirmButtonText="Confirm"
                              cancelButtonText="Close"
                              buttonText="Generate"
                              buttonClasses="btn btn-sm btn-outline text-white p-0"
                              modalTitle="Generate Request Confirmation"
                              saving={saving}
                              modalDescription={
                                <span className="text-white">
                                  Do you really want to generate a Request Confirmation for {getOrderNumber(order)}?
                                </span>
                              }
                            />
                          )}
                          {d.value === CC_CONTRACTCONFIRMATION && isCC && (
                            <SimpleConfirmationModal.SimpleConfirmationModalButton
                              onConfirm={this.handleGenerateContractConfirmation}
                              confirmButtonText="Confirm"
                              cancelButtonText="Close"
                              buttonText="Generate"
                              buttonClasses="btn btn-sm btn-outline text-white p-0"
                              modalTitle="Generate Contract Confirmation"
                              saving={saving}
                              modalDescription={
                                <span className="text-white">
                                  Do you really want to generate a Contract Confirmation for {getContractNumber(order)}?
                                </span>
                              }
                            />
                          )}
                          {d.value === SO_CUSTOMSCLEARANCE && isSO && (
                            <CreateCustomsClearanceInstructionsModal order={order} />
                          )}
                        </>
                      )}
                    </td>
                    <td />
                  </tr>
                ))}
                {/* It is intended that it is possible to generate multiple shipping marks since a CO can be fulfilled with multiple batches */}
                {isInternal && isSO && order.state !== CO_CANCELED && (
                  <tr>
                    <td className="align-middle" colSpan={3}>
                      <span className="text-white fw-bolder">Shipping Mark</span>
                    </td>
                    <td>
                      <CreateShippingMarkModal order={order} context={context} />
                    </td>
                  </tr>
                )}
                {isInternal && order.state !== CO_CANCELED && (
                  <tr>
                    <td colSpan={5} className="align-middle text-right">
                      <WorkflowUploadDocumentModal
                        order={order}
                        fixedTypes={
                          isCO
                            ? CO_FILETYPES
                            : isCC
                            ? CC_FILETYPES
                            : isSO
                            ? SO_FILETYPES
                            : isSAMO
                            ? SAMO_FILETYPES
                            : undefined
                        }
                        context={context}
                        ref={this.setRef}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {serviceDocuments && serviceDocuments.length > 0 && (
          <>
            <div className="card-header border-0 mt-5">
              <div className="card-title flex-column">
                <h2 className="mb-1">Service Documents</h2>
              </div>
            </div>
            <div className="card-body p-9 pt-0">
              <div className="table-responsive mt-5 pt-2 bg-light2">
                <table className="table table-row-gray-100 align-middle">
                  <thead>
                    <tr className="fw-bolder text-muted">
                      <th className="border-bottom-0">Type</th>
                      <th className="border-bottom-0">Title</th>
                      <th className="border-bottom-0">Uploaded</th>
                      <th className="border-bottom-0 text-right">File</th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceDocuments.map((d) => (
                      <tr key={d.file._id.toString()}>
                        <td>
                          <span className="text-white fw-bolder">{d.type?.label ?? "unknown"}</span>
                        </td>
                        <td>
                          <a href={resolveFilePath(d.file.path)} target="_blank" rel="noopener noreferrer">
                            <span className="fw-bold text-white">{shortenAlias(d.file.path)}</span>
                          </a>
                        </td>
                        <td>
                          <span className="fw-bold text-success">{formatDate(d.file.date)}</span>
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <a href={resolveFilePath(d.file.path)} target="_blank" rel="noopener noreferrer">
                            <img
                              alt="pdf"
                              className="w-30px me-3"
                              src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                            />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        <div className="card-header border-0">
          <div className="card-title flex-column">
            <h2 className="mb-1">Documents</h2>
          </div>
        </div>
        <div className="card-body p-9 pt-0">
          <div className="table-responsive mt-5 pt-2 bg-light2">
            <table className="table table-row-gray-100 align-middle">
              <thead>
                <tr className="fw-bolder text-muted">
                  <th className="border-bottom-0">Type</th>
                  <th className="border-bottom-0">Title</th>
                  <th className="border-bottom-0">Uploaded</th>
                  <th className="border-bottom-0 text-right">File</th>
                </tr>
              </thead>
              <tbody>
                {commodityDocuments.length === 0 ? (
                  <tr>
                    <td>
                      <span className="text-white fw-bolder">
                        {isCO
                          ? D_TYPEOPTIONS.find((t) => t.value === D_SUPPLIERSPECIFICATION)?.label || ""
                          : D_TYPEOPTIONS.find((t) => t.value === D_MASTERSPECIFICATION)?.label || ""}
                      </span>
                    </td>
                    <td>
                      <span className="fw-bold text-danger">missing</span>
                    </td>
                    <td colSpan={2} />
                  </tr>
                ) : (
                  commodityDocuments.map((d) => (
                    <tr key={d.file._id.toString()}>
                      <td>
                        <span className="text-white fw-bolder">{d.type?.label ?? "unknown"}</span>
                      </td>
                      <td>
                        <a href={resolveFilePath(d.file.path)} target="_blank" rel="noopener noreferrer">
                          <span className="fw-bold text-white">{shortenAlias(d.file.path)}</span>
                        </a>
                      </td>
                      <td>
                        <span className="fw-bold text-success">{formatDate(d.file.date)}</span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <a href={resolveFilePath(d.file.path)} target="_blank" rel="noopener noreferrer">
                          <img
                            alt="pdf"
                            className="w-30px me-3"
                            src={toAbsoluteUrl("/assets/media/svg/files/pdf.svg")}
                          />
                        </a>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderDocuments;
