import React, { PureComponent } from "react";
import { Nav, Tab } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DataContextAnonymousType, DataContextCustomerType } from "../../../context/dataContext";
import { TabDefinition } from "../../common/CustomTypes";
import CommodityPageGeneral from "./tabPanels/CommodityPageGeneral";
import CommodityPageOrders from "./tabPanels/CommodityPageOrders";
import CommodityPageSimilarCommodities from "./tabPanels/CommodityPageSimilarCommodities";
import CreateSampleOrderModal from "./CreateSampleOrderModal";
import CommodityPageDocuments from "./tabPanels/CommodityPageDocuments";
import { CustomerArticleExtended, isAnyFinishedProduct } from "../../../utils/productArticleUtils";
import MobileDropdown from "../../common/MobileDropdown";

interface CommodityPageTabPanelProps extends RouteComponentProps {
  article: CustomerArticleExtended;
  context: DataContextCustomerType | DataContextAnonymousType;
}

interface CommodityPageTabPanelState {
  currentTab: string;
  showDropdown: boolean;
}

class CommodityPageTabPanel extends PureComponent<CommodityPageTabPanelProps, CommodityPageTabPanelState> {
  constructor(props: CommodityPageTabPanelProps) {
    super(props);
    this.state = {
      currentTab: this.getTabDefinitions()[0].name,
      showDropdown: false,
    };
  }

  getTabDefinitions = (): Array<TabDefinition> => {
    const { article, context } = this.props;
    return [
      {
        name: "general",
        tabContent: "Overview",
        component: <CommodityPageGeneral article={article} context={context} />,
      },
      {
        name: "documents",
        tabContent: "Documents",
        component: <CommodityPageDocuments article={article} />,
      },
      {
        name: "tradingData",
        tabContent: "Trading Data",
        component: <></>,
        disabled: true,
      },
      {
        name: "alternatives",
        tabContent: "Alternatives",
        component: <CommodityPageSimilarCommodities article={article} context={context} />,
      },
      {
        name: "orders",
        tabContent: "Orders",
        component: <CommodityPageOrders article={article} context={context} />,
      },
    ];
  };

  handleTabSelect = (eventKey: string | null) => {
    if (eventKey && eventKey !== this.state.currentTab) this.setState({ currentTab: eventKey });
    this.setState({ showDropdown: false });
  };

  handleToggleDropdown = () => {
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  };

  render() {
    const { article } = this.props;
    const { currentTab, showDropdown } = this.state;
    const tabs = this.getTabDefinitions();

    return (
      <Tab.Container id="commodityTabs" activeKey={currentTab} onSelect={this.handleTabSelect}>
        <div className="flex-lg-row-fluid ms-lg-15 order-0 order-xl-0 mt-2 mt-xl-0">
          <MobileDropdown
            showDropdown={showDropdown}
            onTabSelect={this.handleTabSelect}
            onToggle={this.handleToggleDropdown}
            currentTab={currentTab}
            tabs={tabs}
            document={article}
          />
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-4 d-none d-md-flex">
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(article)) && (
                  <li
                    key={t.name + "_link"}
                    className={"nav-item cursor-pointer " + (t.disabled ? "tab-disabled" : "")}
                  >
                    <Nav.Link
                      role="tab"
                      id={t.name}
                      eventKey={t.name}
                      active={currentTab === t.name}
                      className={"text-active-white pb-4 " + (t.disabled ? "tab-disabled" : "")}
                      onClick={(e) => {
                        e.preventDefault();
                        !t.disabled && this.handleTabSelect(t.name);
                      }}
                    >
                      {t.tabContent}
                    </Nav.Link>
                  </li>
                )
            )}
            {!isAnyFinishedProduct(article) && (
              <li className="nav-item ml-auto">
                <CreateSampleOrderModal {...this.props} commodity={article} />
              </li>
            )}
          </ul>
          <Tab.Content>
            {tabs.map(
              (t) =>
                (!t.condition || t.condition(article)) && (
                  <Tab.Pane id={t.name} key={t.name} eventKey={t.name}>
                    {t.component}
                  </Tab.Pane>
                )
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}

export default withRouter(CommodityPageTabPanel);
