import { callFunction } from "../services/dbService";

const SENDEMAIL = "sendEmail";

/**
 * Helper function to convert a text to a basic html representation.
 * @param text The text to convert
 * @returns {string} The result as html string
 */
export function convertToEmailHTML(text: string): string {
  return `<html>
            <head>
                <style>
                    p, div, span {
                        margin-top: 0 !important;
                        margin-bottom: 0 !important;
                    }
                </style>
            </head>
            <body>
                <p>${text.replace(/\n/g, "<br />")}</p>
            </body>
          </html>`;
}

/**
 * Calls sendEmail function in backend with the subject, messageText and target.
 * @param subject The subject to sent
 * @param messageHTML The message as html
 * @param messageText The mail content to sent
 * @param target The target mail
 * @returns {Promise<boolean>} Flag indicating a successful db operation
 */
export async function sendEmail(
  subject: string,
  messageHTML: string,
  messageText: string,
  target: string
): Promise<boolean> {
  return callFunction(SENDEMAIL, [subject, messageHTML, messageText, target]);
}
