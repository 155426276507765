import _ from "lodash";
import React, { PureComponent, useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import { DataContextInternal, DataContextInternalType, isInternalContext } from "../../../context/dataContext";
import {
  CO_HANDLEDATCUSTOMS,
  CO_HANDLEDATWAREHOUSE,
  CO_ORDEREDATSUPPLIER,
  CO_ORDEREDBYCUSTOMER,
  CO_PERFORMINGSERVICES,
  CO_PROCESSINGATWAREHOUSE,
  CO_REQUESTEDBYCUSTOMER,
  CO_REQUESTEDSTOCK,
  CO_SHIPPEDFROMSUPPLIER,
  CO_SHIPPEDTOCUSTOMER,
  CO_SHIPPEDTOWAREHOUSE,
  CustomerOrder,
  T_WAREHOUSE,
} from "../../../model/customerOrder.types";
import {
  calculateArrivalInformation,
  getCustomerOrdersWithoutSupplierOrder,
  getLastStateUpdate,
  getOrderNumber,
  groupOpenOrders,
  isGroupedOrder,
  isSupplierOrder,
  ORDER_TYPES,
} from "../../../utils/orderUtils";
import { getTimeDiffString } from "../../../utils/dateUtils";
import { doFuseSearch, getDocFromCollection, pluralize, toAbsoluteUrl } from "../../../utils/baseUtils";
import {
  SO_HANDLEDATCUSTOMS,
  SO_HANDLEDATWAREHOUSE,
  SO_ORDERCONFIRMED,
  SO_REQUESTED,
  SO_SHIPPEDFROMSUPPLIER,
  SO_SHIPPEDTOWAREHOUSE,
  SupplierOrder,
} from "../../../model/supplierOrder.types";
import { SelectOption } from "../../common/CustomSelect";
import { CUSTOMER, getUserName, UserSnapshot } from "../../../utils/userUtils";
import { GroupedCustomerOrder } from "../../common/CustomTypes";
import { formatArticleUnit } from "../../../utils/productArticleUtils";
import { DashboardColumn, getDashboardColumnString } from "../../../utils/internalDashboardUtils";
import { CONFIG, getConfigurationFromContext } from "../../../utils/configurationUtils";
import { ResponsibleConfiguration } from "../../../model/configuration/responsibleConfiguration.types";
import userService from "../../../services/userService";

interface InternalDashboardOrderKanbanProps extends RouteComponentProps {
  context: DataContextInternalType;
  search: string;
  type: SelectOption;
}

interface InternalDashboardOrderKanbanState {
  showAll: Array<string>; // List of tabs where all orders should be shown
}

class InternalDashboardOrderKanban extends PureComponent<
  InternalDashboardOrderKanbanProps,
  InternalDashboardOrderKanbanState
> {
  constructor(props: InternalDashboardOrderKanbanProps) {
    super(props);
    this.state = { showAll: [] };
  }

  componentDidUpdate(prevProps: Readonly<InternalDashboardOrderKanbanProps>) {
    if (prevProps.type !== this.props.type) this.setState({ showAll: [] });
  }

  handleClickShowAll = (state: string) => {
    const showAll = _.cloneDeep(this.state.showAll);
    showAll.push(state);
    this.setState({ showAll });
  };

  getOrdersForSupplierView = () => {
    const { context } = this.props;
    let orders: Array<CustomerOrder | SupplierOrder | GroupedCustomerOrder> = context.supplierOrder;
    // Add warehouse orders
    orders = orders.concat(context.customerOrder.filter((cO) => cO.transport === T_WAREHOUSE));
    // Add "grouped orders"
    const openCustomerOrders = getCustomerOrdersWithoutSupplierOrder(context);
    const groupedOrders = groupOpenOrders(openCustomerOrders).map((gO) => {
      const groupedOrder = { ...gO[0] } as GroupedCustomerOrder;
      groupedOrder.orders = gO;
      groupedOrder.totalAmount = gO.reduce((a, b) => a + b.amount, 0);
      return groupedOrder;
    });
    orders = orders.concat(groupedOrders);
    return orders;
  };

  getOrders = () => {
    const { context, search, type } = this.props;
    let filteredOrders = type.value === CUSTOMER ? context.customerOrder.slice() : this.getOrdersForSupplierView();
    if (search)
      filteredOrders = doFuseSearch<CustomerOrder | SupplierOrder | GroupedCustomerOrder>(filteredOrders, search, [
        "orderNo",
        "commodity.title.en",
        "amount",
        "orders.orderNo",
      ]) as Array<CustomerOrder> | Array<SupplierOrder | GroupedCustomerOrder | CustomerOrder>;
    const orderMap: { [state: string]: Array<CustomerOrder | SupplierOrder | GroupedCustomerOrder> } = {
      [DashboardColumn.OPEN]: [],
      [DashboardColumn.ORDERCONFIRMED]: [],
      [DashboardColumn.ORDERED]: [],
      [DashboardColumn.PROCESSING]: [],
      [DashboardColumn.SHIPPED]: [],
      [DashboardColumn.DELIVERY]: [],
    };
    for (let i = 0; i < filteredOrders.length; i++) {
      const order = filteredOrders[i];
      const state = this.getStateForOrder(order);
      if (state) {
        orderMap[state].push(order);
      }
    }
    return orderMap;
  };

  getStateForOrder = (order: CustomerOrder | SupplierOrder | GroupedCustomerOrder): DashboardColumn | undefined => {
    const { context } = this.props;
    if (isGroupedOrder(order)) return DashboardColumn.OPEN;
    else if (isSupplierOrder(order)) {
      switch (order.state) {
        case SO_REQUESTED:
          return DashboardColumn.ORDERED;
        case SO_ORDERCONFIRMED:
          return DashboardColumn.ORDERCONFIRMED;
        case SO_SHIPPEDFROMSUPPLIER:
        case SO_HANDLEDATCUSTOMS:
        case SO_SHIPPEDTOWAREHOUSE:
          return DashboardColumn.SHIPPED;
        case SO_HANDLEDATWAREHOUSE: {
          const cOs = order.customerOrders.map(
            (cO) => getDocFromCollection(context.customerOrder, cO) as CustomerOrder
          );
          if (cOs.some((cO) => cO.state === CO_SHIPPEDTOCUSTOMER)) return DashboardColumn.DELIVERY;
          return DashboardColumn.PROCESSING;
        }
        default:
          return;
      }
    } else {
      switch (order.state) {
        case CO_REQUESTEDBYCUSTOMER:
        case CO_REQUESTEDSTOCK:
        case CO_ORDEREDBYCUSTOMER:
          return DashboardColumn.OPEN;
        case CO_ORDEREDATSUPPLIER:
          if (
            context.supplierOrder.some(
              (sO) =>
                sO.state === SO_ORDERCONFIRMED &&
                sO.commodity._id.toString() === order.commodity._id.toString() &&
                sO.customerOrders.some((cO) => cO === order._id.toString())
            )
          )
            return DashboardColumn.ORDERCONFIRMED;
          return DashboardColumn.ORDERED;
        case CO_SHIPPEDFROMSUPPLIER:
        case CO_SHIPPEDTOWAREHOUSE:
        case CO_HANDLEDATCUSTOMS:
          return DashboardColumn.SHIPPED;
        case CO_HANDLEDATWAREHOUSE:
        case CO_PROCESSINGATWAREHOUSE:
        case CO_PERFORMINGSERVICES:
          return DashboardColumn.PROCESSING;
        case CO_SHIPPEDTOCUSTOMER:
          return DashboardColumn.DELIVERY;
        default:
          return;
      }
    }
  };

  render() {
    const rest = _.omit(this.props, "context");
    const { type, context } = this.props;
    const { showAll } = this.state;
    const {
      [DashboardColumn.OPEN]: open,
      [DashboardColumn.ORDERED]: ordered,
      [DashboardColumn.ORDERCONFIRMED]: confirmed,
      [DashboardColumn.SHIPPED]: shipped,
      [DashboardColumn.PROCESSING]: processing,
      [DashboardColumn.DELIVERY]: delivery,
    } = this.getOrders();
    let responsible: { [key: string]: Array<UserSnapshot> } | undefined;
    if (isInternalContext(context)) {
      responsible = getConfigurationFromContext<ResponsibleConfiguration>(CONFIG.RESPONSIBLE, context)?.values;
    }

    return (
      <div className="px-2" style={{ minWidth: "1200px" }}>
        {type.value === CUSTOMER ? (
          <div className="row">
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.OPEN}
                orders={open}
                responsible={_.get(responsible, DashboardColumn.OPEN)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.OPEN)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.ORDERED}
                orders={ordered}
                responsible={_.get(responsible, DashboardColumn.ORDERED)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.ORDERED)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.ORDERCONFIRMED}
                orders={confirmed}
                responsible={_.get(responsible, DashboardColumn.ORDERCONFIRMED)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.ORDERCONFIRMED)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.SHIPPED}
                orders={shipped}
                responsible={_.get(responsible, DashboardColumn.SHIPPED)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.SHIPPED)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.PROCESSING}
                orders={processing}
                responsible={_.get(responsible, DashboardColumn.PROCESSING)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.PROCESSING)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.DELIVERY}
                orders={delivery}
                responsible={_.get(responsible, DashboardColumn.DELIVERY)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.DELIVERY)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.OPEN}
                orders={open}
                responsible={_.get(responsible, DashboardColumn.OPEN)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.OPEN)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.ORDERED}
                orders={ordered}
                responsible={_.get(responsible, DashboardColumn.ORDERED)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.ORDERED)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.ORDERCONFIRMED}
                orders={confirmed}
                responsible={_.get(responsible, DashboardColumn.ORDERCONFIRMED)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.ORDERCONFIRMED)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.SHIPPED}
                orders={shipped}
                responsible={_.get(responsible, DashboardColumn.SHIPPED)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.SHIPPED)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.PROCESSING}
                orders={processing}
                responsible={_.get(responsible, DashboardColumn.PROCESSING)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.PROCESSING)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
            <div className="col-2 mb-0 p-2">
              <OrderKanbanColumn
                title={DashboardColumn.DELIVERY}
                orders={delivery}
                responsible={_.get(responsible, DashboardColumn.DELIVERY)}
                {...rest}
                showAll={showAll.includes(DashboardColumn.DELIVERY)}
                onClickShowAll={this.handleClickShowAll}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

interface OrderKanbanColumnProps extends RouteComponentProps {
  title: DashboardColumn;
  orders: Array<SupplierOrder | CustomerOrder | GroupedCustomerOrder>;
  showAll: boolean;
  responsible?: Array<UserSnapshot>;
  onClickShowAll: (state: string) => void;
}

const OrderKanbanColumn: React.FunctionComponent<OrderKanbanColumnProps> = ({
  title,
  orders,
  responsible,
  showAll,
  onClickShowAll,
  ...rest
}) => {
  const length = orders.reduce((a, b) => a + (isGroupedOrder(b) ? b.orders.length : 1), 0);
  const ordersSorted = _.orderBy(orders, "orderNo");
  const ordersShown = showAll ? ordersSorted : ordersSorted.slice(0, 15);
  return (
    <div
      className="card bg-white min-h-100"
      style={{
        opacity:
          userService.isAdmin() || !responsible || responsible.some((r) => r._id.toString() === userService.getUserId())
            ? 1
            : 0.7,
      }}
    >
      <div className="card-header border-0 p-0 ">
        <h6 className="card-title d-block m-5">
          {getDashboardColumnString(title)}
          {responsible?.map((r) => (
            <img
              key={r._id.toString()}
              className="ml-1"
              src={r.image ? r.image : toAbsoluteUrl("/assets/media/avatars/blank_dark.png")}
              height={20}
              width={20}
              style={{
                borderRadius: 20,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
              title={getUserName(r)}
              alt={""}
            />
          ))}
          <div className="text-muted fs-7 mt-1">{pluralize(length, "order")}</div>
        </h6>
      </div>
      <div className="card-body pt-0 pb-2 px-5 " style={{ minHeight: "65vh" }}>
        <div className="row">
          {ordersShown.length > 0 ? (
            ordersShown.map((o) => <OrderKanbanItem key={o._id.toString()} order={o} {...rest} />)
          ) : (
            <div className="col-12 text-center my-5">
              <span className="fs-6 text-muted">No orders found</span>
            </div>
          )}
        </div>
        {!showAll && length > 15 && (
          <div className="row">
            <div className="col-12 text-center">
              <button onClick={() => onClickShowAll(title)} className="btn btn-sm text-white">
                Show all {length} orders
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface OrderKanbanItemProps extends RouteComponentProps {
  order: SupplierOrder | CustomerOrder | GroupedCustomerOrder;
}

const OrderKanbanItem: React.FunctionComponent<OrderKanbanItemProps> = ({ order, history }) => {
  const context = useContext(DataContextInternal);

  const isGrouped = isGroupedOrder(order);
  const isSupplier = !isGrouped && isSupplierOrder(order);
  const customers = isGrouped
    ? Array.from(new Set(order.orders.map((o) => getDocFromCollection(context.company, o.company)!.name))).length
    : null;
  const title = `${isGrouped ? order.totalAmount : order.amount}${formatArticleUnit(order.unit)} ${
    order.commodity.title.en
  }`;
  const subtitle = isGrouped
    ? `for ${pluralize(customers!, "customer")}`
    : isSupplier
    ? getDocFromCollection(context.supplier, order.supplier)?.name
    : getDocFromCollection(context.company, order.company)?.name;
  const arrival = calculateArrivalInformation(order);
  const oldestOrder = isGrouped
    ? order.orders.reduce((a: undefined | ORDER_TYPES, b) => (!a || b.createdAt < a.createdAt ? b : a), undefined)
    : undefined;
  const timeDiff = isGrouped
    ? getTimeDiffString(oldestOrder?.createdAt)
    : getTimeDiffString(getLastStateUpdate(order)?.date);

  const forwardOrder = (e: React.MouseEvent) => {
    e.stopPropagation();
    history.push(
      `/${isGrouped ? "createSupplierOrder" : isSupplier ? "supplierOrder" : "customerOrder"}/${order._id.toString()}`
    );
  };

  return (
    <div className="kanban-card" onClick={forwardOrder}>
      <div className="fw-bolder text-gray-800 fs-6 text-ellipsis" title={title}>
        {title}
      </div>
      <div className="text-muted fw-bold text-ellipsis" title={subtitle}>
        {subtitle}
      </div>
      <div className="text-muted fw-bold d-flex mt-1">
        <span className="text-white" style={{ fontSize: "0.8rem" }}>
          {isGrouped ? `for ${pluralize(order.orders.length, "order")}` : getOrderNumber(order)}
        </span>
        <span
          className={
            "ml-auto " +
            (timeDiff === "-"
              ? "text-muted"
              : isGrouped
              ? "text-warning"
              : arrival.late && arrival.target
              ? "text-danger"
              : "text-success")
          }
          style={{ fontSize: "0.8rem" }}
        >
          {timeDiff}
        </span>
      </div>
    </div>
  );
};

export default InternalDashboardOrderKanban;
