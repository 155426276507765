import { BSON } from "realm-web";
import { CommoditySnapshot } from "./commodity.types";
import { Service } from "./service.types";
import { Address, Note, OrderFile } from "./commonTypes";
import { BatchFile, BatchPackage, CommissionData } from "./batch.types";
import { CustomerContract } from "./customerContract.types";
import { DateType } from "../utils/orderUtils";
import { FinishedProductSnapshot } from "./finishedProduct.types";
import { UserData } from "./userData.types";
import { Supplier } from "./supplier.types";
import { Company } from "./company.types";

export const CO_REQUESTEDBYCUSTOMER = "requestedByCustomer" as const; // Limit order type
export const CO_REQUESTEDSTOCK = "requestedStock" as const; // state before confirming stock order by creating order confirmation
export const CO_ORDEREDBYCUSTOMER = "orderedByCustomer" as const;
export const CO_ORDEREDATSUPPLIER = "orderedAtSupplier" as const;
export const CO_ARRIVEDATSTARTINGPORT = "customerOrderArrivedAtStartingPort" as const;
export const CO_SHIPPEDFROMSUPPLIER = "shippedFromSupplier" as const;
export const CO_HANDLEDATCUSTOMS = "handledAtCustoms" as const;
export const CO_SHIPPEDTOWAREHOUSE = "shippedToWarehouse" as const;
export const CO_HANDLEDATWAREHOUSE = "handledAtWarehouse" as const;
export const CO_PROCESSINGATWAREHOUSE = "processingAtWarehouse" as const; // warehouse order type
export const CO_PERFORMINGSERVICES = "performingServices" as const;
export const CO_SHIPPEDTOCUSTOMER = "shippedToCustomer" as const;
export const CO_ARCHIVED = "archived" as const;
export const CO_CANCELED = "canceled" as const;

export type CO_STATES =
  | typeof CO_REQUESTEDBYCUSTOMER
  | typeof CO_REQUESTEDSTOCK
  | typeof CO_ORDEREDBYCUSTOMER
  | typeof CO_ORDEREDATSUPPLIER
  | typeof CO_ARRIVEDATSTARTINGPORT
  | typeof CO_SHIPPEDFROMSUPPLIER
  | typeof CO_HANDLEDATCUSTOMS
  | typeof CO_SHIPPEDTOWAREHOUSE
  | typeof CO_HANDLEDATWAREHOUSE
  | typeof CO_PROCESSINGATWAREHOUSE
  | typeof CO_PERFORMINGSERVICES
  | typeof CO_SHIPPEDTOCUSTOMER
  | typeof CO_ARCHIVED
  | typeof CO_CANCELED;

export const CO_CANCELABLE_STATES: Array<CO_STATES> = [
  CO_REQUESTEDSTOCK,
  CO_ORDEREDBYCUSTOMER,
  CO_ORDEREDATSUPPLIER,
  CO_ARRIVEDATSTARTINGPORT,
  CO_SHIPPEDFROMSUPPLIER,
  CO_HANDLEDATWAREHOUSE,
  CO_PROCESSINGATWAREHOUSE,
];

export const T_SEAFREIGHT = "seafreight" as const;
export const T_AIRFREIGHT = "airfreight" as const;
export const T_WAREHOUSE = "warehouse" as const;
export const T_EUSTOCK = "euStock" as const;
export const T_RAILFREIGHT = "railfreight" as const;
export const T_ROADFREIGHT = "roadfreight" as const;
export const T_SEAAIRCOMBINED = "seaAirCombined" as const;
export const T_SPECIALREQUEST = "request" as const;
export const T_CONTRACT = "contract" as const;

export type CO_TYPES =
  | typeof T_SEAFREIGHT
  | typeof T_AIRFREIGHT
  | typeof T_WAREHOUSE
  | typeof T_SPECIALREQUEST
  | typeof T_EUSTOCK
  | typeof T_CONTRACT
  | typeof T_RAILFREIGHT
  | typeof T_ROADFREIGHT;
export type CO_TRANSPORT =
  | typeof T_SEAFREIGHT
  | typeof T_AIRFREIGHT
  | typeof T_WAREHOUSE
  | typeof T_EUSTOCK
  | typeof T_RAILFREIGHT
  | typeof T_ROADFREIGHT;
export type CO_TRANSPORT_FOR_STOCK =
  | typeof T_SEAFREIGHT
  | typeof T_AIRFREIGHT
  | typeof T_EUSTOCK
  | typeof T_RAILFREIGHT
  | typeof T_ROADFREIGHT;
export type FWO_TRANSPORT =
  | typeof T_SEAFREIGHT
  | typeof T_AIRFREIGHT
  | typeof T_EUSTOCK
  | typeof T_RAILFREIGHT
  | typeof T_SEAAIRCOMBINED;

export interface CustomerOrder {
  _id: BSON.ObjectId;
  orderNo: string; // Auto-incrementing number to identify the order
  createdAt: Date; // Date when order was created
  targetDate: Date; // Date the order should arrive, set when order is created
  targetDateType?: DateType; // indicates how the targetDate should be treated (CW, fix, ...)
  changedETA?: Date | null; // Date that is set when order is delayed
  changedETAType?: DateType; // indicates how the changedETA should be treated (CW, fix, ...)
  deliveryDate?: Date; // Date when the order arrived at customer
  destination: Address | string; // Customer address (formatted address string for backwards compatibility)
  noteCustomer: string; // Note left by customer (set on creation)
  customerReference: string; // the customers own reference if required
  noteInternal: Array<Note>; // Notes left by internal
  state: CO_STATES;
  previousState?: CO_STATES; // state the order was in before it was canceled, set when order is canceled
  previousSupplierOrder?: BSON.ObjectId; // related supplier order before it was canceled, set when order is canceled
  request?: boolean; // flag indicating that it is a request
  company: string;
  supplier?: string | null;
  person: string;
  commodity: CommoditySnapshot | FinishedProductSnapshot; // Commodity or finished product snapshot at moment of ordering
  amount: number; // Amount in KG
  unit: "kg" | "ltr" | "1000 pcs";
  priceCommodities: number; // Total price for the commodities
  discount?: number; // in %
  services: Array<CustomerOrderService>; // All booked services at moment of ordering
  priceServices: number; // Total price for the services
  totalPrice: number; // Total price customer has to pay
  currency: string;
  timeline: Array<CustomerOrderTimelineEntry>;
  files: Array<OrderFile>;
  transport: CO_TRANSPORT;
  paymentInAdvance?: boolean;
  shippingInformation?: CustomerOrderShippingInformation;
  trackingInformation?: CustomerOrderTrackingInformation;
  usedBatches?: Array<UsedBatch>;
  usedEUStock?: Array<UsedEUStock>;
  limitTimeFrame?: number;
  splitOrders?: Array<string>;
  terms?: CustomerOrderTerms;
  contractInformation?: Pick<CustomerContract, "_id" | "contractNo">;
  purchasePriceCommodities?: number; // estimated purchase price by sales
  requestId?: string; // id of request the order was created from
}

export interface CustomerOrderExtended extends Omit<CustomerOrder, "company" | "person" | "supplier"> {
  company: Company;
  supplier?: Supplier | null;
  person: UserData;
}

export interface CustomerOrderTerms {
  paymentTerms: string;
  paymentTermConditions?: string;
  deliveryTerms: string;
  deliveryCity?: string;
  note?: string;
  cleanLabel?: boolean;
}

export interface CustomerOrderTrackingInformation {
  trackingNumber: string;
  deliveryCompany: string;
  trackingLink: string;
}

export interface CustomerOrderService {
  service: Service;
  performed: boolean;
  files: Array<OrderFile>;
  priceOrderCurrency: number;
}

export interface CustomerOrderShippingInformation {
  shipVia: string;
  estimatedDeliveryDate: Date;
  additionalInformation: string;
  unitLoad: string; // "Ladeeinheit", e.g. pallet
  unitLoadAmount: number;
  shippingGroups: Array<CustomerOrderShippingGroup>;
  grossWeight?: number;
}

export interface CustomerOrderShippingGroup {
  _id: BSON.ObjectId;
  description: string;
  lot: string;
  supplierLot?: string;
  expiry: Date;
  packageType: string; // drum, bag, carton, ...
  packageAmount: number;
  packageNetWeight: number;
}

// State updates
export const CO_T_REQUESTED = "requested" as const;
export const CO_T_REQUESTEDSTOCK = "requestedStock" as const;
export const CO_T_CREATEDFORCUSTOMER = "createdForCustomer" as const;
export const CO_T_CREATED = "created" as const;
export const CO_T_STOCKORDERCONFIRMED = "stockOrderConfirmed" as const;
export const CO_T_USESTOCK = "convertedToStock" as const;
export const CO_T_CREATEDFROMCONTRACT = "createdFromContract" as const;
export const CO_T_ORDERED = "orderConfirmed" as const;
export const CO_T_ARRIVEDATSTARTINGPORT = "customerOrderTimelineArrivedAtStartingPort" as const;
export const CO_T_TRACKINGNOCHANGED = "trackingNoChanged" as const;
export const CO_T_SHIPPED = "orderShipped" as const;
export const CO_T_CUSTOMS = "arrivedAtCustoms" as const;
export const CO_T_SHIPPEDWAREHOUSE = "orderShippedToWarehouse" as const;
export const CO_T_WAREHOUSE = "orderArrivedAtWarehouse" as const;
export const CO_T_EUSTOCKSELECTED = "orderSupplierEUStockSelected" as const;
export const CO_T_SERVICE = "performingServices" as const;
export const CO_T_SHIPPEDCUSTOMER = "orderShippedToCustomer" as const;
export const CO_T_ARCHIVED = "orderArchived" as const;
export const CO_T_CANCELED = "orderCanceled" as const;
export const CO_T_REQUESTREJECTED = "requestRejected" as const;
export const CO_T_REQUESTAPPROVED = "requestApproved" as const;
export const CO_T_SPLIT = "customerOrderSplit" as const;
export const CO_T_CHANGEDETA = "changedETA" as const;
export const CO_T_CHANGEDTARGETWEEK = "changedTargetWeek" as const;

export const CO_TIMELINE_STATE_UPDATES: Array<CO_TIMELINETYPE> = [
  CO_T_REQUESTED,
  CO_T_REQUESTEDSTOCK,
  CO_T_CREATEDFORCUSTOMER,
  CO_T_CREATED,
  CO_T_CREATEDFROMCONTRACT,
  CO_T_STOCKORDERCONFIRMED,
  CO_T_USESTOCK,
  CO_T_SPLIT,
  CO_T_ORDERED,
  CO_T_ARRIVEDATSTARTINGPORT,
  CO_T_TRACKINGNOCHANGED,
  CO_T_SHIPPED,
  CO_T_CUSTOMS,
  CO_T_SHIPPEDWAREHOUSE,
  CO_T_WAREHOUSE,
  CO_T_SERVICE,
  CO_T_SHIPPEDCUSTOMER,
  CO_T_ARCHIVED,
  CO_T_CANCELED,
  CO_T_REQUESTREJECTED,
  CO_T_REQUESTAPPROVED,
];

// Document updates
export const CO_T_DOCUMENTUPLOADED = "documentUploaded" as const;
export const CO_T_DOCUMENTREPLACED = "documentReplaced" as const;
export const CO_T_DOCUMENTREMOVED = "documentRemoved" as const;

// Actions
export const CO_T_SERVICEADDED = "serviceAdded" as const;
export const CO_T_SERVICEPERFORMED = "servicePerformed" as const;
export const CO_T_SHIPPINGINFORMATION = "shippingInformationAdded" as const;
export const CO_T_TRACKINGINFORMATION = "trackingInformationAdded" as const;
export const CO_T_COMMODITIESBOOKED = "commoditiesBooked" as const;
export const CO_T_REFERENCEADDED = "customerReferenceAdded" as const;
export const CO_T_UPDATEDCOMMODITYSNAPSHOT = "updateCOCommoditySnapshot" as const;
export const CO_T_REFERENCECHANGED = "customerReferenceChanged" as const;
export const CO_T_DESTINATIONCHANGED = "destinationAddressChanged" as const;
export const CO_T_CALCULATIONUPDATED = "calculationUpdated" as const;

export type CO_TIMELINETYPE =
  | typeof CO_T_REQUESTED
  | typeof CO_T_REQUESTEDSTOCK
  | typeof CO_T_CREATEDFORCUSTOMER
  | typeof CO_T_CREATED
  | typeof CO_T_CREATEDFROMCONTRACT
  | typeof CO_T_STOCKORDERCONFIRMED
  | typeof CO_T_USESTOCK
  | typeof CO_T_ORDERED
  | typeof CO_T_ARRIVEDATSTARTINGPORT
  | typeof CO_T_TRACKINGNOCHANGED
  | typeof CO_T_SHIPPED
  | typeof CO_T_CUSTOMS
  | typeof CO_T_SHIPPEDWAREHOUSE
  | typeof CO_T_WAREHOUSE
  | typeof CO_T_EUSTOCKSELECTED
  | typeof CO_T_SERVICE
  | typeof CO_T_SHIPPEDCUSTOMER
  | typeof CO_T_ARCHIVED
  | typeof CO_T_CANCELED
  | typeof CO_T_DOCUMENTUPLOADED
  | typeof CO_T_DOCUMENTREPLACED
  | typeof CO_T_DOCUMENTREMOVED
  | typeof CO_T_SERVICEADDED
  | typeof CO_T_SERVICEPERFORMED
  | typeof CO_T_SHIPPINGINFORMATION
  | typeof CO_T_TRACKINGINFORMATION
  | typeof CO_T_COMMODITIESBOOKED
  | typeof CO_T_REQUESTAPPROVED
  | typeof CO_T_SPLIT
  | typeof CO_T_REQUESTREJECTED
  | typeof CO_T_CHANGEDETA
  | typeof CO_T_CHANGEDTARGETWEEK
  | typeof CO_T_REFERENCECHANGED
  | typeof CO_T_REFERENCEADDED
  | typeof CO_T_DESTINATIONCHANGED
  | typeof CO_T_UPDATEDCOMMODITYSNAPSHOT
  | typeof CO_T_CALCULATIONUPDATED;

export interface CustomerOrderTimelineEntryPayload {
  type?: string;
  checked?: boolean;
  reference?: string;
  name?: string;
  reason?: string;
  calculation?: string;
}

export interface CustomerOrderTimelineEntry {
  // Might be incomplete
  _id: BSON.ObjectId;
  date: Date; // When did this happen?
  type: CO_TIMELINETYPE; // What happened?
  person: string;
  payload: CustomerOrderTimelineEntryPayload | null; // Additional data for certain types
}

export interface UsedBatch {
  _id: BSON.ObjectId;
  batchId: string;
  identifier: string;
  supplierLot?: string;
  supplier: string;
  expiry?: Date;
  packages: Array<UsedBatchPackage>;
  price: number;
  priceCurrency: string;
  totalAmountUsed: number;
  unit: string;
  state: string;
  supplierCoA: BatchFile | null;
  ownCoA: BatchFile | null;
  commissionData: CommissionData | null;
  supplierOrder: string | null;
}

export interface UsedEUStock {
  _id: BSON.ObjectId;
  euStockId: string;
  supplier: string;
  price: number;
  priceCurrency: string;
  amountUsed: number;
}

export interface UsedBatchPackage extends Omit<BatchPackage, "usedOrders"> {
  packageId: string;
  amountUsed: number;
}
