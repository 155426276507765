import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import { CO_ARCHIVED, CO_T_ARCHIVED, CustomerOrderExtended } from "../../../../../../model/customerOrder.types";
import { getCustomerOrderTimelineEntry, updateCustomerOrder } from "../../../../../../utils/customerOrderUtils";
import { getCustomerContractTimelineEntry } from "../../../../../../utils/customerContractUtils";
import { getOrderNumber } from "../../../../../../utils/orderUtils";
import { callPushToArray } from "../../../../../../utils/baseUtils";
import { CUSTOMERCONTRACT } from "../../../../../../services/dbService";
import { CustomerContractTimelineType } from "../../../../../../model/customerContract.types";

interface WorkflowCloseOrderProps {
  order: CustomerOrderExtended;
}

interface WorkflowCloseOrderState {
  saving: boolean;
}

class WorkflowCloseOrder extends PureComponent<WorkflowCloseOrderProps, WorkflowCloseOrderState> {
  constructor(props: WorkflowCloseOrderProps) {
    super(props);
    this.state = { saving: false };
  }

  handleCompleteOrder = async () => {
    const { order } = this.props;
    try {
      this.setState({ saving: true });
      const res = await updateCustomerOrder(
        { state: CO_ARCHIVED },
        order._id,
        getCustomerOrderTimelineEntry(CO_T_ARCHIVED)
      );
      if (res && res.res.modifiedCount > 0) {
        toast.success("Customer Order successfully updated");
        if (order.contractInformation) {
          // Fire and forget update contract timeline
          const contractTimelineEntry = getCustomerContractTimelineEntry(CustomerContractTimelineType.CALL_FINISHED, {
            name: getOrderNumber(order),
          });
          callPushToArray(CUSTOMERCONTRACT, order.contractInformation._id, "timeline", contractTimelineEntry);
        }
      } else {
        toast.error("Error updating Customer Order");
      }
    } catch (e) {
      toast.error("Error updating Customer Order");
      console.error("Error updating Customer Order: ", e);
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    return (
      <div className="text-center mt-4">
        <div className="fs-5">
          <span className="text-muted">
            <button className="btn btn-outline btn-outline-light" onClick={this.handleCompleteOrder}>
              Complete Order
            </button>
          </span>
        </div>
      </div>
    );
  }
}

export default WorkflowCloseOrder;
