import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  ANONYMOUS_DEFINITION,
  CUSTOMER_DEFINITION,
  EntryDefinition,
  INTERNAL_DEFINITION,
  SUPPLIER_DEFINITION,
} from "../layout/Aside";
import { toAbsoluteUrl } from "../../utils/baseUtils";
import { ANONYMOUS, CUSTOMER, INTERNAL, SUPPLIER } from "../../utils/userUtils";

interface AsideDropdownProps extends RouteComponentProps {
  view: typeof CUSTOMER | typeof INTERNAL | typeof SUPPLIER | typeof ANONYMOUS;
}

const AsideDropdown: React.FunctionComponent<AsideDropdownProps> = ({ view, history }) => {
  const [show, setShow] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<EntryDefinition | undefined>(undefined);

  const entries = useMemo(() => {
    let result: Array<EntryDefinition> = [
      {
        path: "/profile",
        title: "Profile Settings",
        svg: <SVG src={toAbsoluteUrl("/assets/media/svg/custom/customers.svg")} />,
      },
    ];
    switch (view) {
      case CUSTOMER:
        result = result.concat(CUSTOMER_DEFINITION);
        break;
      case SUPPLIER:
        result = result.concat(SUPPLIER_DEFINITION);
        break;
      case ANONYMOUS:
        result = result.concat(ANONYMOUS_DEFINITION);
        break;
      case INTERNAL:
        result = result.concat(INTERNAL_DEFINITION);
        break;
    }
    return result;
  }, [view]);

  useEffect(() => {
    const newEntity = entries.find((entity) => history.location.pathname.includes(entity.path));
    if (newEntity) {
      setSelectedEntity(newEntity);
    }
  }, [history.location.pathname, entries]);

  const handleToggle = () => {
    setShow(!show);
  };

  const handleSelect = (path: string) => {
    setShow(false);
    history.push(path);
  };

  return (
    <Dropdown
      className="d-lg-none mb-2 d-flex justify-content-start mr-3 w-50"
      onClick={(e) => e.stopPropagation()}
      show={show}
      onToggle={handleToggle}
    >
      <Dropdown.Toggle
        className="btn btn-light text-uppercase show-arrow d-flex align-items-center"
        onClick={handleToggle}
      >
        <span className="dropdown-icon text-white mr-2 d-flex align-items-center text-white">
          {selectedEntity?.svg}
        </span>
        {selectedEntity?.title}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100 text-uppercase custom-dropdown">
        {entries.map((entry) => (
          <React.Fragment key={entry.path}>
            {!entry.disabled && (
              <Dropdown.Item eventKey={entry.path} onClick={() => handleSelect(entry.path)}>
                <span className="dropdown-icon text-white">
                  {entry.svg} {entry.title}
                </span>
              </Dropdown.Item>
            )}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withRouter(AsideDropdown);
