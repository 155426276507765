import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Tooltip from "../../common/Tooltip";
import CountryWidget from "../../common/CountryWidget";
import { CustomerCustomerOrder } from "../../../model/customer/customerCustomerOrder.types";
import { CustomerSampleOrder } from "../../../model/customer/customerSampleOrder.types";
import {
  calculateArrivalInformation,
  getLastStateUpdate,
  getOrderNumber,
  getOrderStateDescriptions,
  isCustomerOrder,
  isOrderInactive,
  isSampleOrder,
} from "../../../utils/orderUtils";
import { getLinkForOrderOrContract } from "../../../utils/contractAndOrderUtils";
import { formatArticleUnit } from "../../../utils/productArticleUtils";
import { formatDate, pluralize, truncateString } from "../../../utils/baseUtils";
import AddReferenceModal from "../../common/AddReferenceModal";
import { CO_ARCHIVED, CO_CANCELED, CO_STATES } from "../../../model/customerOrder.types";
import { SAMO_STATE } from "../../../model/sampleOrder.types";

interface OrderCardElementProps extends RouteComponentProps {
  order: CustomerCustomerOrder | CustomerSampleOrder;
}

const OrderCardElement: React.FunctionComponent<OrderCardElementProps> = ({ order, history }) => {
  const stateDescription = getOrderStateDescriptions(order);
  const isCO = isCustomerOrder(order);
  const isSAMO = isSampleOrder(order);
  const arrival = calculateArrivalInformation(order, isCO ? order.changedETA : undefined);
  const inactive = isOrderInactive(order);

  const forwardOrder = (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.stopPropagation();
    history.push(getLinkForOrderOrContract(order));
  };

  return (
    <>
      <div className="col-6 text-left mb-2" onClick={forwardOrder}>
        <div className="text-white fs-5 mb-1 h5">{getOrderNumber(order)}</div>
      </div>
      <div className="col-6 text-right mb-2">
        <div className="text-white fs-5 mb-1 h5">
          {order.amount} {`${formatArticleUnit(order.unit, order.commodity)}`}
        </div>
      </div>
      <div className="col-12 text-left mb-2">
        <span className="text-white fs-5 d-inline-block">{truncateString(order.commodity.title.en, 40)}</span>
        {order.commodity.organic && <i title="organic" className="fas fa-leaf text-success d-inline-block ml-2" />}
        <span className="text-muted d-block" style={{ fontSize: "0.85rem" }}>
          {truncateString(order.commodity.subtitle.en, 50)}
        </span>
      </div>
      <div className="col-4 text-muted">{!isSAMO ? "Reference" : "Notes"}</div>
      <div className="col-8 text-right mb-2">
        {isCO && order.customerReference && order.customerReference.length > 20 ? (
          <Tooltip tooltipText={order.customerReference}>
            <span>{truncateString(order.customerReference, 20)}</span>
          </Tooltip>
        ) : isCO && order.customerReference ? (
          order.customerReference
        ) : (
          ""
        )}
        {isCO && !order.customerReference && <AddReferenceModal order={order} />}
        {isSAMO && order.noteCustomer && order.noteCustomer.length > 20 ? (
          <Tooltip tooltipText={order.noteCustomer}>
            <span>{truncateString(order.noteCustomer, 20)}</span>
          </Tooltip>
        ) : isSAMO && order.noteCustomer ? (
          order.noteCustomer
        ) : (
          ""
        )}
      </div>
      <div className="col-4 text-muted">Origin</div>
      <div className="col-8 text-right mb-2">
        <CountryWidget countryCode={order.commodity.country.code} wrapperClasses={"text-muted"} />
      </div>
      <div className="col-4 text-muted">Status</div>
      <div className="col-8 text-right mb-2">
        {inactive ? (
          <span className="text-muted">{stateDescription.title}</span>
        ) : (
          <>
            <div className="text-success">{stateDescription.title}</div>
            <small className="text-muted">{formatDate(getLastStateUpdate(order)?.date || order.createdAt)}</small>
          </>
        )}
      </div>
      <div className="col-4 text-muted">Schedule</div>
      <div className="col-8 text-right">
        {([CO_ARCHIVED, SAMO_STATE.ARCHIVED] as Array<CO_STATES | SAMO_STATE>).includes(order.state) ? (
          <span className="text-muted">Delivered</span>
        ) : ([CO_CANCELED, SAMO_STATE.CANCELED] as Array<CO_STATES | SAMO_STATE>).includes(order.state) ? (
          <span className="text-muted">Canceled</span>
        ) : ([SAMO_STATE.REJECTED] as Array<CO_STATES | SAMO_STATE>).includes(order.state) ? (
          <span className="text-muted">Rejected</span>
        ) : (
          <>
            <div className="text-muted">CW {arrival.cw}</div>
            <span className={arrival.target ? (arrival.late ? "text-danger" : "text-success") : "text-success"}>
              {arrival.target ? (arrival.late ? "delayed" : "in " + pluralize(arrival.weekDiff, "week")) : "delivered"}
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(OrderCardElement);
