import React, { PureComponent } from "react";
import { BSON } from "realm-web";
import { CompanyExtended } from "../../../model/company.types";
import { Input } from "../../common/Input";
import CustomSelect, { SelectOption } from "../../common/CustomSelect";
import CompanyCountryWidget from "../../common/CompanyCountryWidget";
import {
  getPaymentTargetDescription,
  I_PAYMENTTARGETS,
  I_PAYMENTTARGETS_WITH_CUSTOM_OPTION,
} from "../../../utils/invoiceUtils";
import { formatCurrency } from "../../../utils/baseUtils";
import { BASE_CURRENCY } from "../../../utils/currencyUtils";
import ReactStarsWrapper from "../../common/ReactStarsWrapper";
import { formatAddress, getAddressProperty } from "../../../utils/addressUtils";
import AddressInputGroup from "../../common/AddressInputGroup";
import { ADDRESS_TYPE_OPTIONS, AddressType } from "../../../model/commonTypes";
import { DataContextInternalType } from "../../../context/dataContext";
import Tooltip from "../../common/Tooltip";
import { PropertyType } from "../../../utils/propertyUtils";
import { Property } from "../../../model/property.types";
import { CUSTOMERORDERMARGIN, getGeneralValue } from "../../../utils/generalUtils";
import {
  C_LABEL_DESIGN_OPTIONS,
  CompanyMails,
  LABEL_CLEAN_OPTION,
  LABEL_RAWBIDS_OPTION,
  LabelDesign,
} from "../../../utils/companyUtils";

interface CustomerPageGeneralInformationBlockProps {
  company: CompanyExtended;
  edit: boolean;
  customPaymentTarget: boolean;
  onEditCompanyInput: (e: React.ChangeEvent<HTMLInputElement>, field?: string) => void;
  onEditCompanyMails: (e: React.ChangeEvent<HTMLInputElement>, key: CompanyMails.INVOICE) => void;
  onEditCompanySelect: (name: string, e: SelectOption) => void;
  onAddAddress: () => void;
  onDeleteAddress: (id: BSON.ObjectId) => void;
  onEditCompanySelectCountry: (e: SelectOption, idx: number) => void;
  onEditCompanySelectContact: (e: SelectOption, idx: number) => void;
  onEditCompanySelectProperty: (e: Array<SelectOption>, idx: number) => void;
  onEditRating: (rating: number) => void;
  onHandleChangeOpeningHours: (e: React.ChangeEvent<HTMLTextAreaElement>, idx: number) => void;
  onEditCustomPaymentTarget: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditCustomPaymentTargetConditions: (e: React.ChangeEvent<HTMLInputElement>) => void;
  context: DataContextInternalType;
}

interface CustomerPageGeneralInformationBlockState {}

class CustomerPageGeneralInformationBlock extends PureComponent<
  CustomerPageGeneralInformationBlockProps,
  CustomerPageGeneralInformationBlockState
> {
  customerOrderMargin: number;

  constructor(props: CustomerPageGeneralInformationBlockProps) {
    super(props);
    this.customerOrderMargin = Number(getGeneralValue(props.context.general, CUSTOMERORDERMARGIN) || 10);
  }

  handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const field = "address[" + idx + "]." + e.target.name;
    this.props.onEditCompanyInput(e, field);
  };

  handleChangeLabelType = (e: SelectOption) => {
    this.props.onEditCompanySelect("cleanLabel", e);
  };

  getPaymentTarget = () => {
    const { company, customPaymentTarget } = this.props;
    if (customPaymentTarget) {
      return { value: "custom", label: "Custom" };
    } else if (!customPaymentTarget && company.paymentTerms) {
      return I_PAYMENTTARGETS.find((pt) => pt.label === company.paymentTerms?.paymentTarget) || I_PAYMENTTARGETS[4]; // other option should not be an option since it would be custom then
    } else if (!customPaymentTarget && company.paymentTarget !== undefined && company.paymentTarget !== null) {
      return I_PAYMENTTARGETS.find((pt) => pt.value === company.paymentTarget?.toString()) || I_PAYMENTTARGETS[4]; // other option should not be an option since it would be custom then
    } else {
      return { value: "", label: "Not specified" };
    }
  };

  render() {
    const {
      company,
      context,
      edit,
      customPaymentTarget,
      onAddAddress,
      onDeleteAddress,
      onHandleChangeOpeningHours,
      onEditCompanyInput,
      onEditCompanyMails,
      onEditCompanySelectCountry,
      onEditCompanySelectContact,
      onEditCompanySelectProperty,
      onEditCompanySelect,
      onEditRating,
      onEditCustomPaymentTarget,
      onEditCustomPaymentTargetConditions,
    } = this.props;
    const paymentTarget = this.getPaymentTarget();

    return (
      <div className="collapse show fs-6">
        {edit ? (
          <div className="row py-5">
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Name</label>
              <Input
                type="text"
                value={company.name}
                name={"name"}
                onChange={onEditCompanyInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">General Mail</label>
              <Input
                type="text"
                value={company.mail}
                name={"mail"}
                onChange={onEditCompanyInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">VAT-Number</label>
              <Input
                type="text"
                value={company.vat}
                name={"vat"}
                onChange={onEditCompanyInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">General Phone</label>
              <Input
                type="text"
                value={company.phone}
                name={"phone"}
                onChange={onEditCompanyInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Shipping Label Type</label>
              <CustomSelect
                matchFormControl={true}
                options={C_LABEL_DESIGN_OPTIONS}
                onChange={(e: SelectOption) => this.handleChangeLabelType(e)}
                value={company.cleanLabel ? LABEL_CLEAN_OPTION : LABEL_RAWBIDS_OPTION}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Invoice Mail</label>
              <Input
                type="text"
                value={company.mails?.invoice}
                name={"invoice"}
                onChange={(e) => onEditCompanyMails(e, CompanyMails.INVOICE)}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-12 mt-2">
              {company.address.map((a, idx) => (
                <React.Fragment key={a._id.toString()}>
                  <div className="mt-6 border-bottom-dark-gray" />
                  <label className="fs-6 fw-bold mt-4">Address {idx + 1}</label>
                  <AddressInputGroup
                    address={a}
                    persons={[company.primaryPerson, ...company.persons]}
                    context={context}
                    onChangeAddress={(e) => this.handleChangeAddress(e, idx)}
                    onChangeOpeningHours={(e) => onHandleChangeOpeningHours(e, idx)}
                    onChangeAddressType={(e: SelectOption) => onEditCompanySelect("address[" + idx + "].type", e)}
                    onChangeAddressCountry={(e: SelectOption) => onEditCompanySelectCountry(e, idx)}
                    onChangeAddressContact={(e: SelectOption) => onEditCompanySelectContact(e, idx)}
                    onChangeAddressProperty={(e: Array<SelectOption>) => onEditCompanySelectProperty(e, idx)}
                    onDeleteAddress={idx === 0 ? undefined : onDeleteAddress}
                  />
                </React.Fragment>
              ))}
              <button className="btn btn-sm float-right pr-0" onClick={onAddAddress}>
                <i className="fa fa-plus text-success p-0" />
              </button>
            </div>
            <div className="mt-2 border-bottom-dark-gray" />
            <div className="col-md-6 mt-6">
              <label className="fs-6 fw-bold mb-1">Payment Term</label>
              <CustomSelect
                options={I_PAYMENTTARGETS_WITH_CUSTOM_OPTION}
                value={paymentTarget}
                onChange={(e: SelectOption) => onEditCompanySelect("paymentTarget", e)}
                matchFormControl={true}
              />
              {customPaymentTarget && (
                <>
                  <label className="fs-6 fw-bold mb-1 mt-2">Payment Target</label>
                  <div className="input-group">
                    <Input
                      type="number"
                      value={
                        company.paymentTerms && company.paymentTerms.paymentTarget !== ""
                          ? Number(company.paymentTerms.paymentTarget.replace(" days", ""))
                          : ""
                      }
                      name="customPaymentTarget"
                      onChange={(e) => onEditCustomPaymentTarget(e)}
                      className="form-control custom-form-control"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text form-control custom-form-control">days</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-6 mt-6">
              <label className="fs-6 fw-bold mb-1">Additional Payment Conditions</label>
              <Input
                type="text"
                value={
                  company.paymentTerms?.paymentTargetConditions ? company.paymentTerms?.paymentTargetConditions : ""
                }
                name="customPaymentTargetConditions"
                onChange={onEditCustomPaymentTargetConditions}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Credit Limit</label>
              <Input
                type="number"
                integerOnly={true}
                value={company.creditLimit}
                min={0}
                name={"creditLimit"}
                onChange={onEditCompanyInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Rating</label>
              <ReactStarsWrapper
                classNames="mx-auto"
                value={company.rating}
                count={5}
                size={14}
                onChange={onEditRating}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">Notes</label>
              <Input
                type="text"
                value={company.notes}
                name={"notes"}
                onChange={onEditCompanyInput}
                className="form-control custom-form-control"
              />
            </div>
            <div className="col-md-6 mt-2">
              <label className="fs-6 fw-bold mb-1">
                Discount
                <Tooltip
                  tooltipText={`Grants the customer a discount on prices. The maximum value is ${this.customerOrderMargin} %. (Current standard customer order margin)`}
                >
                  <i className="fa fa-info-circle text-white ml-2" />
                </Tooltip>
              </label>
              <div className="input-group">
                <Input
                  type="number"
                  value={company.discount ?? 0}
                  max={this.customerOrderMargin}
                  name="discount"
                  onChange={onEditCompanyInput}
                  className="form-control custom-form-control"
                />
                <div className="input-group-append rounded-end bg-custom-light-gray">
                  <div className="form-control custom-form-control" style={{ padding: ".375rem .75rem" }}>
                    %
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-wrap py-5">
            <table className="table fw-bold gy-1">
              <tbody>
                <tr>
                  <td className="text-white w-25 min-w-125px">Name</td>
                  <td className="text-muted w-25">{company.name}</td>
                  <td className="text-white w-25 min-w-125px">General Mail</td>
                  <td className="text-muted w-25">{company.mail}</td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">VAT Number</td>
                  <td className="text-muted w-25">{company.vat}</td>
                  <td className="text-white w-25 min-w-125px">General Phone</td>
                  <td className="text-muted w-25">{company.phone}</td>
                </tr>
                {company.address.map((a) => {
                  const addrType = ADDRESS_TYPE_OPTIONS.find((at) => at.value === a.type)?.label;
                  const addrTypeLabel = `${addrType && a.type !== AddressType.A_OTHER ? addrType + " " : ""}Address`;
                  const contactPerson = context.userData.find((user) => user._id.toString() === a.contactPerson);
                  const addressProps = getAddressProperty(
                    a,
                    PropertyType.DELIVERYINSTRUCTIONS,
                    context,
                    true
                  ) as Array<Property>;
                  return (
                    <React.Fragment key={a._id.toString()}>
                      <tr>
                        <td className="text-white w-25 max-w-125px">{addrTypeLabel}</td>
                        <td className="text-muted w-25 display-linebreak">
                          <>
                            {addressProps && addressProps.length > 0 ? (
                              <Tooltip
                                tooltipText={
                                  <div className="text-white text-left">
                                    <div className="fw-bolder fs-7">Delivery instructions:</div>
                                    {addressProps.map((prop) => (
                                      <div key={prop._id.toString()}>{prop.name.en}</div>
                                    ))}
                                  </div>
                                }
                              >
                                <span>{formatAddress(a, { withoutCountry: true })}</span>
                              </Tooltip>
                            ) : (
                              <span>{formatAddress(a, { withoutCountry: true })}</span>
                            )}
                          </>
                        </td>
                        <td className="text-white w-25 min-w-125px">Invoice Mail</td>
                        <td className="text-muted w-25">{company.mails?.invoice}</td>
                      </tr>
                      <tr>
                        <td className="text-white w-25 max-w-125px">Contact person</td>
                        <td className="text-muted w-25 display-linebreak">
                          {contactPerson ? `${contactPerson.prename} ${contactPerson.surname}` : "-"}
                        </td>
                        <td className="text-white w-25 min-w-125px">Country</td>
                        <td className="text-muted w-25">
                          <CompanyCountryWidget company={company} address={a} />
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
                <tr>
                  <td className="text-white w-25 min-w-125px">Payment term</td>
                  <td className="text-success w-25">
                    {company.paymentTerms?.paymentTarget && company.paymentTerms.paymentTarget !== ""
                      ? company.paymentTerms.paymentTarget
                      : company.paymentTarget !== undefined && company.paymentTarget !== null
                      ? getPaymentTargetDescription(company.paymentTarget)
                      : "-"}
                  </td>
                  {company.paymentTerms?.paymentTargetConditions ? (
                    <>
                      <td className="text-white w-25 min-w-125px">Payment Conditions</td>
                      <td className="text-muted w-25">{company.paymentTerms.paymentTargetConditions}</td>
                    </>
                  ) : (
                    <td />
                  )}
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Credit Limit</td>
                  <td className="text-muted w-25">{formatCurrency(company.creditLimit, BASE_CURRENCY)}</td>
                  <td className="text-white w-25 min-w-125px">Rating</td>
                  <td className="w-25">
                    <ReactStarsWrapper value={company.rating} count={5} size={14} edit={false} />
                  </td>
                </tr>
                <tr>
                  <td className="text-white w-25 min-w-125px">Notes</td>
                  <td className="text-muted w-25">{company.notes.trim() ? company.notes : "-"}</td>
                  {company.discount !== undefined && (
                    <>
                      <td className="text-white w-25 min-w-125px">
                        Discount
                        <Tooltip
                          tooltipText={`Grants the customer a discount on prices. The maximum value is ${this.customerOrderMargin} %. (Current standard customer order margin)`}
                        >
                          <i className="fa fa-info-circle text-white ml-2" />
                        </Tooltip>
                      </td>
                      <td className="text-muted w-25">{Math.abs(company.discount)} %</td>
                    </>
                  )}
                </tr>
                {company.cleanLabel !== undefined && (
                  <tr>
                    <td className="text-white w-25 min-w-125px">Label Design</td>
                    <td className="text-muted w-25">{company.cleanLabel ? LabelDesign.CLEAN : LabelDesign.RAWBIDS}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default CustomerPageGeneralInformationBlock;
