import _ from "lodash";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { BSON } from "realm-web";
import { toast } from "react-toastify";
import { DataContextInternalType } from "../../../context/dataContext";
import { Supplier, SupplierExtended } from "../../../model/supplier.types";
import SupplierPageTabPanel from "./SupplierPageTabPanel";
import SupplierPageSummary from "./SupplierPageSummary";
import SupplierPageStats from "./SupplierPageStats";
import { getDocFromCollection } from "../../../utils/baseUtils";
import { getDocumentDB, SUPPLIER, USERDATA } from "../../../services/dbService";
import { extendSupplier } from "../../../utils/dataTransformationUtils";
import { UserData } from "../../../model/userData.types";

interface SupplierParams {
  id: string;
  cor?: string;
}

interface SupplierProps extends RouteComponentProps<SupplierParams> {
  context: DataContextInternalType;
}

interface SupplierState {
  supplier?: SupplierExtended;
}

class SupplierPage extends PureComponent<SupplierProps, SupplierState> {
  _isMounted = false;
  constructor(props: SupplierProps) {
    super(props);
    this.state = {
      supplier: _.cloneDeep(this.getSupplier(props)),
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.supplier) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/suppliers");
      return;
    }
    this._isMounted = true;
    const supplier = await getDocumentDB<Supplier>(SUPPLIER, id);
    if (!supplier) {
      console.error("No supplier could be loaded for id", id);
      toast.error("The requested supplier could not be loaded");
      history.push("/suppliers");
      return;
    }
    context.addDocuments(SUPPLIER, [supplier]);
    const supplierExtended = extendSupplier(supplier, context);
    // If supplier was just created it might happen that the primary person is not inside the context yet. We need to
    // manually add it then.
    if (!supplierExtended.primaryPerson) {
      const primaryPerson = await getDocumentDB<UserData>(USERDATA, supplier.primaryPerson);
      if (primaryPerson) {
        context.addDocuments(USERDATA, [primaryPerson]);
        supplierExtended.internalContact = primaryPerson;
      }
    }
    if (this._isMounted) this.setState({ supplier: supplierExtended });
  };

  componentDidUpdate(prevProps: Readonly<SupplierProps>) {
    const { match, context } = this.props;
    if (prevProps.match.params.id !== match.params.id || !_.isEqual(prevProps.context.supplier, context.supplier)) {
      this.setState({
        supplier: _.cloneDeep(this.getSupplier(this.props)),
      });
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Get the initial supplier data
   * @param props the components properties
   * @returns {SupplierExtended | undefined} a supplier object or undefined
   */
  getSupplier = (props: SupplierProps): SupplierExtended | undefined => {
    const supplier = getDocFromCollection(props.context.supplier, props.match.params.id);
    if (supplier) return extendSupplier(supplier, props.context);
  };

  render() {
    const { context, match } = this.props;
    const { supplier } = this.state;
    if (!supplier) return null;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10 order-1 order-xl-0">
                <SupplierPageSummary supplier={supplier} context={context} />
                <SupplierPageStats supplier={supplier} />
              </div>
              <SupplierPageTabPanel supplier={supplier} corSelected={match.params.cor} context={context} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SupplierPage;
