import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { DataContextInternal } from "../../../../context/dataContext";
import AirportListing from "./AirportListing";
import CreateAirportModal from "./CreateAirportModal";

const AirportOverview = () => {
  const context = useContext(DataContextInternal);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <div className="post d-flex flex-column-fluid">
        <div className="container-xxl responsive-aside-container">
          <div className="card bg-white min-h-100">
            <div className="card-body">
              <h3 className="card-title align-items-start flex-column mb-15">
                <span className="card-label fw-bolder mb-3 fs-3rem">Airports</span>
                <div className="float-right">
                  <Link to="/settings">
                    <button className="btn btn-text text-white">Back</button>
                  </Link>
                  <CreateAirportModal />
                </div>
              </h3>
              <div className="row">
                <div className="col-12">
                  <AirportListing context={context} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirportOverview;
