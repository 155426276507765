import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import {
  SO_CANCELED,
  SO_T_CANCELED,
  SupplierOrder,
  SupplierOrderExtended,
} from "../../../../../model/supplierOrder.types";
import ErrorOverlayButton from "../../../../common/ErrorOverlayButton";
import SimpleConfirmationModal from "../../../../common/SimpleConfirmationModal";
import { getOrderNumber } from "../../../../../utils/orderUtils";
import { DataContextInternalType } from "../../../../../context/dataContext";
import {
  COMMODITY,
  CUSTOMERCONTRACT,
  CUSTOMERORDER,
  FINISHEDPRODUCT,
  FORWARDINGORDER,
  SUPPLIERORDER,
  transaction,
  UpdateAction,
} from "../../../../../services/dbService";
import { getSupplierOrderTimelineEntry } from "../../../../../utils/supplierOrderUtils";
import {
  CO_ORDEREDBYCUSTOMER,
  CO_T_ARRIVEDATSTARTINGPORT,
  CO_T_CUSTOMS,
  CO_T_ORDERED,
  CO_T_SHIPPED,
  CO_T_SHIPPEDWAREHOUSE,
  CO_T_SHIPPINGINFORMATION,
  CO_T_TRACKINGINFORMATION,
  CO_T_TRACKINGNOCHANGED,
  CustomerOrder,
} from "../../../../../model/customerOrder.types";
import { CC_STATE, CustomerContract, CustomerContractTimelineType } from "../../../../../model/customerContract.types";
import { getDocFromCollection } from "../../../../../utils/baseUtils";
import { getIdentifierForOrderOrContract } from "../../../../../utils/contractAndOrderUtils";
import { FWO_STATES, FWO_TIMELINE } from "../../../../../model/forwardingOrder.types";
import { getForwardingOrderTimelineEntry } from "../../../../../utils/forwardingOrderUtils";
import { getDefaultSlackChannel, NotificationType, sendMessage } from "../../../../../services/slackService";
import userService from "../../../../../services/userService";
import { ARTICLETYPES } from "../../../../../utils/productArticleUtils";

interface CancelSupplierOrderModalProps {
  order: SupplierOrderExtended;
  context: DataContextInternalType;
}

const CancelSupplierOrderModal: FunctionComponent<CancelSupplierOrderModalProps> = ({ context, order }) => {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const hasRelatedCOs = useMemo(() => order.customerOrders.length > 0, [order.customerOrders]);
  // Check with boolean since customerContracts can be undefined
  const hasRelatedCCs = useMemo(() => Boolean(order.customerContracts?.length), [order.customerContracts]);
  const relatedFWOs = useMemo(
    () =>
      context.forwardingOrder.filter(
        (fwo) =>
          fwo.orderInformation.some((oi) => oi.orderId.toString() === order._id.toString()) &&
          ![FWO_STATES.FWO_CANCELED, FWO_STATES.FWO_ARCHIVED].includes(fwo.state)
      ),
    [context.forwardingOrder, order]
  );

  const handleCancelSupplierOrder = useCallback(async () => {
    setSaving(true);
    try {
      const actions: Array<UpdateAction> = [];
      // Clear CO and CC array - update state
      const updateSO: Partial<SupplierOrder> = { customerOrders: [], customerContracts: [], state: SO_CANCELED };
      const timelineSO = getSupplierOrderTimelineEntry(SO_T_CANCELED);
      actions.push({
        collection: SUPPLIERORDER,
        filter: { _id: order._id },
        update: updateSO,
        push: { timeline: timelineSO },
      });
      // Reset related customer orders
      if (hasRelatedCOs) {
        for (let i = 0; i < order.customerOrders.length; i++) {
          const cO = order.customerOrders[i];
          actions.push({
            collection: CUSTOMERORDER,
            filter: { _id: cO._id },
            update: { state: CO_ORDEREDBYCUSTOMER },
            pull: {
              timeline: {
                type: {
                  $in: [
                    CO_T_ORDERED,
                    CO_T_ARRIVEDATSTARTINGPORT,
                    CO_T_TRACKINGINFORMATION,
                    CO_T_TRACKINGNOCHANGED,
                    CO_T_SHIPPINGINFORMATION,
                    CO_T_SHIPPED,
                    CO_T_CUSTOMS,
                    CO_T_SHIPPEDWAREHOUSE,
                  ],
                },
              },
            },
          });
        }
      }
      // Reset related customer contracts
      if (order.customerContracts && hasRelatedCCs) {
        for (let i = 0; i < order.customerContracts.length; i++) {
          const cC = order.customerContracts[i];
          actions.push({
            collection: CUSTOMERCONTRACT,
            filter: { _id: cC._id },
            update: { state: CC_STATE.OPEN },
            pull: { timeline: { type: CustomerContractTimelineType.ORDERED } },
          });
        }
      }
      // Cancel related forwarding order if order is the only one  and remove order from forwarding order
      const fwosWillBeCanceled = [];
      const fwosNotCanceled = [];
      if (relatedFWOs.length > 0) {
        for (let i = 0; i < relatedFWOs.length; i++) {
          const fwo = relatedFWOs[i];
          actions.push({
            collection: FORWARDINGORDER,
            filter: { _id: fwo._id },
            pull: { orderInformation: { orderId: order._id.toString() } },
            push: {
              timeline: {
                $each: [getForwardingOrderTimelineEntry(FWO_TIMELINE.FWO_T_REMOVEDORDER)],
              },
            },
          });
          if (fwo.orderInformation.length === 1) {
            actions.push({
              collection: FORWARDINGORDER,
              filter: { _id: fwo._id },
              update: {
                state: FWO_STATES.FWO_CANCELED,
              },
              push: {
                timeline: {
                  $each: [getForwardingOrderTimelineEntry(FWO_TIMELINE.FWO_T_CANCELED)],
                },
              },
            });
            fwosWillBeCanceled.push(fwo);
          } else fwosNotCanceled.push(fwo);
        }
      }
      // Reset ordered flag of commission stock
      if (order.commissionData) {
        actions.push({
          collection: order.commodity.articleType === ARTICLETYPES.COMMODITY ? COMMODITY : FINISHEDPRODUCT,
          filter: { "commissionStocks.$[cS].ordered": false },
          arrayFilters: [{ "cS._id": new BSON.ObjectId(order.commissionData.commissionStockId) }],
        });
      }
      const res = await transaction(actions);
      if (res) {
        toast.success("Order successfully canceled");
        const user = userService.getUserData();
        if (relatedFWOs.length > 0) {
          let message = "";
          if (fwosWillBeCanceled.length > 0) {
            // slack message that forwarding order was canceled
            message = `Supplier order <https://${
              process.env.REACT_APP_BASE_URL || ""
            }/supplierOrder/${order._id.toString()}|*${getOrderNumber(order)}*> was canceled by ${user.prename} ${
              user.surname
            } and part of forwarding ${fwosWillBeCanceled.length > 1 ? "orders" : "order"} ${fwosWillBeCanceled
              .map((fwo) => {
                return `FW-${fwo.forwardingOrderNo}`;
              })
              .join(", ")}. The forwarding ${
              fwosWillBeCanceled.length > 1 ? "orders are" : "order is"
            } canceled as well since this was the only active order in ${
              fwosWillBeCanceled.length > 1 ? "these forwarding orders" : "this forwarding order"
            }.`;
          } else if (fwosNotCanceled.length > 0) {
            // slack message even though forwarding order is not canceled
            message = `Supplier order <https://${
              process.env.REACT_APP_BASE_URL || ""
            }/supplierOrder/${order._id.toString()}|*${getOrderNumber(order)}*> was canceled by ${user.prename} ${
              user.surname
            } and part of forwarding ${fwosNotCanceled.length > 1 ? "orders" : "order"} ${fwosNotCanceled
              .map((fwo) => {
                return `FW-${fwo.forwardingOrderNo}`;
              })
              .join(", ")}. The forwarding ${
              fwosNotCanceled.length > 1 ? "orders are" : "order is"
            } not canceled since there are other orders in ${
              fwosNotCanceled.length > 1 ? "these forwarding orders" : "this forwarding order"
            }.`;
          }
          await sendMessage(getDefaultSlackChannel(false, NotificationType.SCM), message);
        }
      } else {
        toast.error("Error canceling order");
      }
    } catch (e) {
      console.error("ERROR", e);
      toast.error("Error canceling order");
    } finally {
      setSaving(false);
    }
  }, [order]);

  const validateData = () => {
    const errors: Array<string> = [];
    if (context.batch.find((b) => b.supplierOrder === order._id.toString())) {
      errors.push("A batch was created for this order. Thus it can't be canceled anymore.");
    }
    return errors;
  };

  return (
    <>
      <ErrorOverlayButton
        errors={validateData()}
        className="btn btn-light btn-sm mt-5"
        buttonText="Cancel Order"
        onClick={handleShow}
      />
      <SimpleConfirmationModal.SimpleConfirmationModal
        show={show}
        modalTitle="Cancel Supplier Order"
        size="md"
        saving={saving}
        cancelButtonText="Close"
        confirmButtonText="Confirm"
        modalDescription={
          <span className="text-white">
            <h4 className="fw-bolder text-white text-left">Do you really want to cancel {getOrderNumber(order)}?</h4>
            <div className="text-white text-left mt-5">
              After being canceled the order will be archived and cannot be processed further.
              {hasRelatedCOs && (
                <div className="my-5 card bg-warning p-5 text-dark">
                  <h6 className="text-dark">The following customer orders will be reset:</h6>
                  {order.customerOrders.map((cO) => (
                    <RelatedOrderOrContract
                      key={cO._id.toString()}
                      id={cO._id.toString()}
                      context={context}
                      type="order"
                    />
                  ))}
                </div>
              )}
              {hasRelatedCCs && order.customerContracts && (
                <div className="my-5 card bg-warning p-5 text-dark">
                  <h6 className="text-dark">The following customer contracts will be reset:</h6>
                  {order.customerContracts.map((cC) => (
                    <RelatedOrderOrContract
                      key={cC._id.toString()}
                      id={cC._id.toString()}
                      context={context}
                      type="contract"
                    />
                  ))}
                </div>
              )}
            </div>
            <h4 className="fw-bolder text-white text-left mt-5">This operation is permanent and cannot be revoked!</h4>
          </span>
        }
        onClose={handleHide}
        onConfirm={handleCancelSupplierOrder}
      />
    </>
  );
};

interface RelatedOrderOrContractProps {
  id: string;
  context: DataContextInternalType;
  type: "order" | "contract";
}

const RelatedOrderOrContract: FunctionComponent<RelatedOrderOrContractProps> = ({ id, context, type }) => {
  const order = useMemo(() => {
    return getDocFromCollection<CustomerOrder | CustomerContract>(
      type === "order" ? context.customerOrder : context.customerContract,
      id
    );
  }, [id, type === "order" ? context.customerOrder : context.customerContract]);

  // Should never happen - but a little sanity is always nice
  if (!order) return null;

  const company = getDocFromCollection(context.company, order.company);

  return (
    <div>
      {getIdentifierForOrderOrContract(order)} of {company?.name || "Unknown"}
    </div>
  );
};

export default CancelSupplierOrderModal;
