import _ from "lodash";
import { BSON } from "realm-web";
import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { DataContextInternalType } from "../../../context/dataContext";
import { Forwarder, ForwarderExtended } from "../../../model/forwarder.types";
import { FORWARDER, getDocumentDB } from "../../../services/dbService";
import { getDocFromCollection } from "../../../utils/baseUtils";
import ForwarderPageSummary from "./ForwarderPageSummary";
import ForwarderPageTabPanel from "./ForwarderPageTabPanel";

import { extendForwarder } from "../../../utils/dataTransformationUtils";

interface ForwarderParams {
  id: string;
}

interface ForwarderProps extends RouteComponentProps<ForwarderParams> {
  context: DataContextInternalType;
}

interface ForwarderState {
  forwarder?: ForwarderExtended;
}

class ForwarderPage extends PureComponent<ForwarderProps, ForwarderState> {
  _isMounted = false;
  constructor(props: ForwarderProps) {
    super(props);
    this.state = {
      forwarder: this.getForwarder(props),
    };
  }

  componentDidMount = async () => {
    const { match, history, context } = this.props;
    if (this.state.forwarder) return;
    const id = match.params.id;
    if (!id || !BSON.ObjectId.isValid(id)) {
      history.push("/forwarders");
      return;
    }
    this._isMounted = true;
    const forwarder = await getDocumentDB<Forwarder>(FORWARDER, id);
    if (!forwarder) {
      console.error("No forwarder could be loaded for id", id);
      toast.error("The requested forwarder could not be loaded");
      history.push("/forwarders");
      return;
    }
    context.addDocuments(FORWARDER, [forwarder]);
    const forwarderExtended = extendForwarder(forwarder, context);
    if (this._isMounted) this.setState({ forwarder: forwarderExtended });
  };

  componentDidUpdate(prevProps: Readonly<ForwarderProps>) {
    const { match } = this.props;
    if (match.params.id || (!match.params.id && prevProps.match.params.id)) {
      const forwarder = this.getForwarder(this.props);
      if (!_.isEqual(forwarder, this.state.forwarder)) {
        this.setState({
          forwarder,
        });
      }
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   * Get the initial forwarder data
   * @param props the components properties
   * @returns {ForwarderExtended | undefined} a forwarder object or undefined
   */
  getForwarder = (props: ForwarderProps): ForwarderExtended | undefined => {
    const forwarder = getDocFromCollection(props.context.forwarder, props.match.params.id);
    if (forwarder) return extendForwarder(forwarder, props.context);
  };

  render() {
    const { context } = this.props;
    const { forwarder } = this.state;
    if (!forwarder) return null;
    return (
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="post d-flex flex-column-fluid">
          <div className="container-xxl responsive-aside-container">
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10 order-1 order-xl-0">
                <ForwarderPageSummary forwarder={forwarder} />
              </div>
              <ForwarderPageTabPanel context={context} forwarder={forwarder} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForwarderPage;
