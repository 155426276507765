import { BSON } from "realm-web";

export enum PriceRequestWhitelistStatus {
  PENDING = "pending",
  GRANTED = "granted",
  DENIED = "denied",
}

export interface PriceRequestWhitelist {
  _id: BSON.ObjectId;
  mail: string;
  status: PriceRequestWhitelistStatus;
  manuallyAdded: boolean;
}
