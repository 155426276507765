export const DEFAULTMAILTEXTACCESSGRANTED = `Dear User,

We are pleased to inform you that your request to use the RAWBIDS-BOT has been approved. You can now send price inquiries directly to our bot via the following email: request@bot.rawbids.com.

If you have any questions or need further assistance, please feel free to reach out to us.

Best regards,
The RAWBIDS Team`;
export const DEFAULTMAILTEXTACCESSREVOKED = `Dear User,

We would like to inform you that the access for sending price inquiries to the RAWBIDS-BOT cannot be granted.

If you have any questions or require further clarification, please feel free to reach out to us.

Best regards,
The RAWBIDS Team`;

export const DEFAULTMAILSUBJECTACCESSGRANTED = "Access to RAWBIDS-BOT Enabled";

export const DEFAULTMAILSUBJECTACCESSREVOKED = "Access to RAWBIDS-BOT Disabled";
